import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import VirtualizedSelect from 'react-virtualized-select'
import { autocompleteRequest } from 'actions/jobs/managers';
import { getJobNumberOptions } from 'selectors/options';
import { isJobNumberAutoCompleteLoading } from 'selectors/loading';
import LabeledField from 'components/core/LabeledField';
import HorizontalField from 'components/core/HorizontalField';
import PureRenderComponent from 'components/core/PureRenderComponent';


class JobNumberAsyncSelectField extends PureRenderComponent {

  handleInputChange = (value) => {
    value && this.props.autocompleteRequest(value);
  }

  handleChange = (value) => {
    const { input, multi } = this.props;
    let v;
    if (multi) {
      const valueList = value.split(',')
      v = valueList.includes('') ? [] : valueList
    } else {
      v = value;
    }
    input.onChange(v)
  }

  render() {

    const {
      input,
      numberOptions,
      isLoading,
      style,
      multi
    } = this.props;

    return (
      <VirtualizedSelect
        {...input}
        style={style}
        multi={multi}
        simpleValue={true}
        options={numberOptions}
        filterOption={(option, filterString) => {
          return option.label && option.label.toLowerCase().indexOf(filterString) !== -1
        }}
        isLoading={isLoading}
        onChange={this.handleChange}
        onInputChange={(value) => this.handleInputChange(value)}
        placeholder=""
        onBlur={() => input.onBlur()} />
    )
  }
}

const LabeledJobNumberAsyncSelectField = (props) => {
  return (
    <LabeledField {...props}>
      <JobNumberAsyncSelectField {...props} />
    </LabeledField>
  );
}

const HorizontalJobNumberAsyncSelectField = (props) => {
  return (
    <HorizontalField {...props}>
      <JobNumberAsyncSelectField {...props} />
    </HorizontalField>
  );
}


const mapStateToProps = (state) => {
  return {
    isLoading: isJobNumberAutoCompleteLoading(state),
    numberOptions: getJobNumberOptions(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    autocompleteRequest,
  }, disptach)
}

JobNumberAsyncSelectField = connect(
  mapStateToProps,
  mapDispatchToProps
)(JobNumberAsyncSelectField);

JobNumberAsyncSelectField.Labeled = LabeledJobNumberAsyncSelectField;
JobNumberAsyncSelectField.Horizontal = HorizontalJobNumberAsyncSelectField;

export default JobNumberAsyncSelectField;
