import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listRequest } from 'actions/users';
import { setFilter } from 'actions/filters'
import { USER_LIST } from 'misc/constants';
import { getUsers, getUserListPageSize, getUserListCurrentPage } from 'selectors/lists';
import { getUserListKeyword, getUserListGroup, getUserListService } from 'selectors/filters';
import { isUserListLoading } from 'selectors/loading';
import { getGroupOptions, getServiceOptions } from 'selectors/options';
import PureRenderComponent from 'components/core/PureRenderComponent';
import UserList from './presenter';


class UserListContainer extends PureRenderComponent {

  componentDidMount() {
    const params = {
      ...this.getListParams(),
      page: 1
    };
    this.props.listRequest(params);
  }

  getListParams = () => {
    return {
      page: this.props.currentPage,
      group: this.props.group,
      service: this.props.service,
      keyword: this.props.keyword
    }
  }

  handleKeywordChange = (keyword) => {
    this.props.setFilter(USER_LIST, 'keyword', keyword);
  }

  handleGroupChange = (group) => {
    this.props.setFilter(USER_LIST, 'group', group);
    const params = { ...this.getListParams(), group };
    this.props.listRequest(params);
  }

  handleServiceChange = (service) => {
    this.props.setFilter(USER_LIST, 'service', service);
    const params = { ...this.getListParams(), service };
    this.props.listRequest(params);
  }

  handlePageSelect = (page) => {
    const params = { ...this.getListParams(), page };
    this.props.listRequest(params);
  }

  handleSubmit = () => {
    const params = { ...this.getListParams(), page: 1 };
    this.props.listRequest(params);
  }

  render() {
    return (
      <UserList
        {...this.props}
        handleKeywordChange={this.handleKeywordChange}
        handleGroupChange={this.handleGroupChange}
        handleServiceChange={this.handleServiceChange}
        handlePageSelect={this.handlePageSelect}
        handleSubmit={this.handleSubmit} />
    );
  }
}


const mapStateToProps = (state) => {
  return {
    users: getUsers(state),
    isLoading: isUserListLoading(state),
    pageSize: getUserListPageSize(state),
    currentPage: getUserListCurrentPage(state),
    keyword: getUserListKeyword(state),
    group: getUserListGroup(state),
    service: getUserListService(state),
    groupOptions: getGroupOptions(state),
    serviceOptions: getServiceOptions(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest,
    setFilter,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListContainer);