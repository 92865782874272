import { takeLatest, call, put } from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import API from 'services/CalendarApi';
import * as actions from 'actions/calendars';
import * as schemas from 'schemas';
import * as actionTypes from 'actionTypes/calendars';
import * as entityActions from 'actions/entities';
import * as constants from 'misc/constants';
import { setLoading } from 'actions/loading';

function* calendar(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.CALENDAR, true));
  try {
    const response = yield call(API.calendar, decamelizeKeys(params));
    const { assignments, users } = response.data;
    const normalizedAssignments = normalize(camelizeKeys(assignments), [schemas.assignmentSchema]);
    yield put(entityActions.merge(normalizedAssignments.entities));
    yield put(actions.setCalendar(
      users,
      normalizedAssignments.result,
    ));

    yield put(setLoading(constants.CALENDAR, false));
  } catch (error) {
    yield put(actions.calendarFailure(error));
    yield put(setLoading(constants.CALENDAR, false));
  }
}

function* watchCalendar() {
  yield takeLatest(actionTypes.CALENDAR.REQUEST, calendar);
}



export {
  watchCalendar
}