import axios from 'axios';


const _download = (endpoint, file, onDownloadProgress) => {
  return axios({
    url: `${endpoint}/${file.id}/`,
    method: 'GET',
    responseType: 'blob',
    onDownloadProgress
  });
}

const DocumentApi = {

  // Sourcefile ================================================================
  sourcefileUpload: (files) => {
    const uploaders = files.map(file => {
      const formData = new FormData();
      formData.append('file', file);
      return axios.post(`sourcefiles/`, formData)
                  .then(response => response.data)
    });
    return axios.all(uploaders);
  },
  jobSourcefileUpload: (jobId, file, name) => {
    const formData = new FormData();
    formData.append('job', jobId);
    formData.append('file', file);
    formData.append('name', name);
    return axios.post(`sourcefiles/`, formData)
  },
  sourcefileRemove: (id) => {
    return axios.delete(`sourcefiles/${id}/`);
  },
  sourcefileDownload: (file, onDownloadProgress) => {
    return _download('sourcefiles', file, onDownloadProgress);
  },

  // Reference =================================================================
  referenceUpload: (files) => {
    const uploaders = files.map(file => {
      const formData = new FormData();
      formData.append('file', file);
      return axios.post(`references/`, formData)
                  .then(response => response.data)
    });
    return axios.all(uploaders);
  },
  jobReferenceUpload: (jobId, files) => {
    const uploaders = files.map(file => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('job', jobId);
      return axios.post(`references/`, formData)
                  .then(response => response.data)
    });
    return axios.all(uploaders);
  },
  referenceRemove: (id) => {
    return axios.delete(`references/${id}/`);
  },
  referenceDownload: (file, onDownloadProgress) => {
    return _download('references', file, onDownloadProgress);
  },

  // Job Common Reference ======================================================
  jobCommonReferenceUpload: (jobGroup, files) => {
    const uploaders = files.map(file => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('job_group', jobGroup);
      return axios.post(`references/job/`, formData)
                  .then(response => response.data)
    });
    return axios.all(uploaders);
  },
  jobCommonReferenceRemove: (id) => {
    return axios.delete(`references/job/${id}/`);
  },
  jobCommonReferenceDownload: (file, onDownloadProgress) => {
    return _download('references/job', file, onDownloadProgress);
  },

  // Client Reference ==========================================================
  clientCommonReferenceUpload: (clientId, files, jobId) => {
    const uploaders = files.map(file => {
      const formData = new FormData();
      formData.append('client', clientId);
      formData.append('file', file);
      jobId && formData.append('jobs', jobId);
      return axios.post(`references/common/`, formData)
                  .then(response => response.data)
    });
    return axios.all(uploaders); 
  },
  clientCommonReferenceUpdate: (id, name, file) => {
    const formData = new FormData();
    formData.append('name', name);
    file && formData.append('file', file);
    return axios.patch(`references/common/${id}/`, formData);
  },
  clientCommonReferenceUse: (id, values) => {
    return axios.put(`references/common/${id}/use/`, values);
  },
  clientCommonReferenceRemove: (id) => {
    return axios.delete(`references/common/${id}/`);
  },
  clientCommonReferenceDownload: (file, onDownloadProgress) => {
    return _download('references/common', file, onDownloadProgress);
  },

  // Project ===================================================================
  assignmentProjectUpload: (assignmentId, files) => {
    const uploaders = files.map(file => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('assignment', assignmentId);
      return axios.post(`projects/`, formData)
                  .then(response => response.data)
    });
    return axios.all(uploaders);
  },
  projectRemove: (id) => {
    return axios.delete(`projects/${id}/`);
  },
  projectDownload: (file, onDownloadProgress) => {
    return _download('projects', file, onDownloadProgress);
  },

  // Target File
  targetfileUpload: (sourceFileId, file, name) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    return axios.post(`quote-sourcefiles/${sourceFileId}/targetfiles/`, formData)
  },
  targetfileDownload: (file, onDownloadProgress) => {
    return axios({
      url: `targetfiles/${file.id}/manager/`,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress
    });
  },
  targetfileRemove: (id) => {
    return axios.post(`targetfiles/${id}/delete/`);
  },
  quoteSourcefileDownload: (file, onDownloadProgress) => {
    return axios({
      url: `quote-sourcefiles/${file.id}/manager/`,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress
    });
  },
  unsupportedFileDownload: (file, onDownloadProgress) => {
    return axios({
      url: `unsupported-order-source-file/${file.id}/`,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress
    });
  },
}

export default DocumentApi;
