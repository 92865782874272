import React from 'react';

const NotFound = (props) => (
    <div>Not Found</div>
)

export default NotFound;

  

