import React from "react";
import * as PropTypes from 'prop-types';
import './taTable.css';

export class TaTableIndexCell extends React.PureComponent {

  render() {
    const {
      className,
      rowIndex,
      rowCount,
      width,
      color,
      ...attributes
    } = this.props;

    let classNames = ['ta-table-cell ta-table-cell--index'];
    if (className) {
      classNames.push(className);
    }
    if (width) {
      if (typeof attributes.style === 'undefined') {
        attributes.style = {};
      }
      attributes.style.flexGrow = '0';
      attributes.style.flexShrink = '0';
      attributes.style.flexBasis = width + 'px';
    }
    if (color) {
      classNames.push('ta-table-cell--'+color);
    }

    const digits = (rowCount < 10) ? 2 : rowCount.toString().length;
    const padCount = digits - (rowIndex+1).toString().length;

    let index = '';
    while (padCount > index.length) {
      index += '0';
    }
    index += (rowIndex + 1);

    return (
      <div className={classNames.join(' ')} {...attributes}>
        <div>
          <span>{index}</span>
          {this.props.children}
        </div>
      </div>
    );
  }

}

TaTableIndexCell.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  color: PropTypes.oneOf([false, 'alert'])
};
