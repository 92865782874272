import { delay } from 'redux-saga';
import { takeLatest, call, put, fork } from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import { show, hide } from 'redux-modal';
import Notifications from 'react-notification-system-redux';
import { getNotificationOptions } from 'misc/utils';
import API from 'services/MHTApi';
import * as schemas from 'schemas/mht';
import * as actions from 'actions/mht';
import * as actionTypes from 'actionTypes/mht';
import * as entityActions from 'actions/entities';
import * as listActions from 'actions/lists';
import * as coreSagas from 'sagas/core';
import * as constants from 'misc/constants';
import { XLSX_TYPE } from 'misc/config';
import { sourcefileSchema } from 'schemas/documents';
import { setLoading } from 'actions/loading';
import { setDownloadProgress } from 'actions/core';
import { saveFile, splitExt } from 'misc/utils';
import { PREVIEW_VIEWER_URL } from 'misc/config';


function* retrieve(action) {
  const { id } = action.payload;
  try {
    const response = yield call(API.retrieve, id);
    const normalized = normalize(camelizeKeys(response.data), schemas.mhtSchema);
    yield put(entityActions.merge(normalized.entities));
  } catch (error) {
    yield put(actions.retrieveFailure(error));
  }
}

function* reCommit(action) {
  const { id } = action.payload;
  try {
    const response = yield call(API.reCommit, id);
    yield fork(
      coreSagas.checkTaskProgress,
      response.data.task_id,
      'Re-Commit in progress...',
      'Re-Commit completed'
    );
  } catch (error) {
    yield put(actions.reCommitFailure(error));
  }
}


function* updateAnalysis(action) {
  const { id } = action.payload;
  try {
    const response = yield call(API.updateAnalysis, id);
    yield fork(
      coreSagas.checkConcurrentTaskProgress,
      response.data.task_id,
      'Analysis in progress...',
      'Analysis completed'
    );
  } catch (error) {
    yield put(actions.updateAnalysisFailure(error));
  }
}

function* sourcefileUpload(action) {
  const { values } = action.payload;
  try {
    yield put(show(constants.MHT_SETUP_PROGRESS_MODAL));
    yield put(hide(constants.MHT_FILE_UPLOAD_MODAL));
    const response = yield call(API.upload, values);
    const sourcefile = camelizeKeys(response.data.sourcefile);
    const mht = camelizeKeys(response.data.mht);
    const normalizedMHT = normalize(mht, schemas.mhtSchema);
    const normalizedSourcefile = normalize(sourcefile, sourcefileSchema);
    yield put(show(constants.MHT_SETUP_PROGRESS_MODAL, {
      task_id: response.data.task_id,
      mhtId: response.data.mht.id,
      client: response.data.client,
      job: response.data.job,
      service: response.data.service,
    }));
    yield put(entityActions.merge(normalizedMHT.entities));
    yield put(entityActions.merge(normalizedSourcefile.entities));
    yield put(listActions.addNestedItem(constants.JOB_SOURCEFILE, sourcefile.job, normalizedSourcefile.result));
  } catch (error) {
    yield put(hide(constants.MHT_SETUP_PROGRESS_MODAL));
    yield put(show(constants.MHT_SETUP_ERROR_MODAL, {
      message: error.response.data.detail
    }));
  }
}

function* downloadComments(action) {
  yield put(show(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  const { id, file } = action.payload;
  try {
    const [downloadPromise, chan] = yield call(
      coreSagas.createDownloader,
      API.downloadComments,
      id
    );
    yield fork(coreSagas.watchDownloadProgress, chan);
    const response = yield call(() => downloadPromise);
    const [root, ext] = splitExt(file.name);
    const filename = `${root}-comments.xlsx`;
    yield call(saveFile, response.data, filename, file.contentType);
    yield delay(1000);
    yield put(hide(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  } catch (error) {
    yield put(actions.downloadCommentsFailure(error));
  } finally {
    yield put(setDownloadProgress(0));
  }
}

function* downloadTargetFile(action) {
  yield put(show(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  const { id, file, language, fontSizeAdjust, fontSize, fontFamilyAdjust, fontFamily } = action.payload;
  try {
    const [downloadPromise, chan] = yield call(
      coreSagas.createDownloader,
      API.downloadTargetFile,
      { id, fontSizeAdjust, fontSize, fontFamilyAdjust, fontFamily }
    );
    yield fork(coreSagas.watchDownloadProgress, chan);
    const response = yield call(() => downloadPromise);
    const [root, ext] = splitExt(file.name);
    const filename = `${root}-${language}${ext}`;
    yield call(saveFile, response.data, filename, file.contentType);
    yield delay(1000);
    yield put(hide(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  } catch (error) {
    yield put(actions.downloadTargetFileFailure(error));
  } finally {
    yield put(setDownloadProgress(0));
  }
}

function* downloadBilingualExcel(action) {
  yield put(show(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  const { id, role, file } = action.payload;
  try {
    const [downloadPromise, chan] = yield call(
      coreSagas.createDownloader,
      API.downloadBilingualExcel,
      { id, role }
    );
    yield fork(coreSagas.watchDownloadProgress, chan);
    const response = yield call(() => downloadPromise);
    const [root] = splitExt(file.name);
    yield call(saveFile, response.data, `${root}-bilingual.xlsx`, XLSX_TYPE);
    yield delay(1000);
    yield put(hide(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  } catch (error) {
    yield put(actions.downloadBilingualExcelFailure(error));
  } finally {
    yield put(setDownloadProgress(0));
  }
}

function* downloadBilingualTmx(action) {
  yield put(show(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  const { id, file } = action.payload;
  try {
    const [downloadPromise, chan] = yield call(
      coreSagas.createDownloader,
      API.downloadBilingualTmx,
      id
    );
    yield fork(coreSagas.watchDownloadProgress, chan);
    const response = yield call(() => downloadPromise);
    const [root] = splitExt(file.name);
    yield call(saveFile, response.data, `${root}-bilingual.tmx`, 'application/xml');
    yield delay(1000);
    yield put(hide(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  } catch (error) {
    yield put(actions.downloadBilingualTmxFailure(error));
  } finally {
    yield put(setDownloadProgress(0));
  }
}

function* showPreview(action) {
  const { id } = action.payload;
  let previewWin = window.open('about:blank');
  try {
    const response = yield call(API.generatePreview, id);
    previewWin.location.href = `${PREVIEW_VIEWER_URL}${response.data.url}`;
    previewWin = null;
  } catch (error) {
    previewWin.close();
    previewWin = null;
    yield put(actions.showPreviewFailure(error));
  }
}


// Analysis Report ================================================================
function* analysisReport(action) {
  yield put(setLoading(constants.ANALYSIS_REPORT, true));
  const { currentMonth } = action.payload;
  try {
    const response = yield call(API.analysisReport, decamelizeKeys({ currentMonth }));
    yield put(listActions.setAnalysisReportResult(
      constants.ANALYSIS_REPORT,
      currentMonth,
      camelizeKeys(response.data.results),
      camelizeKeys(response.data.avg_monthly)
    ));
    yield put(setLoading(constants.ANALYSIS_REPORT, false));
  } catch (error) {
    yield put(actions.analysisReportFailure(error));
    yield put(setLoading(constants.ANALYSIS_REPORT, false));
  }
}

function* watchRetrieve() {
  yield takeLatest(actionTypes.RETRIEVE.REQUEST, retrieve);
}

function* watchReCommit() {
  yield takeLatest(actionTypes.RECOMMIT.REQUEST, reCommit);
}

function* watchUpdateAnalysis() {
  yield takeLatest(actionTypes.UPDATE_ANALYSIS.REQUEST, updateAnalysis);
}

function* watchSourcefileUpload() {
  yield takeLatest(actionTypes.SOURCEFILE_UPLOAD.REQUEST, sourcefileUpload);
}

function* watchDownloadComments() {
  yield takeLatest(actionTypes.DOWNLOAD_COMMENTS.REQUEST, downloadComments);
}

function* watchDownloadTargetFile() {
  yield takeLatest(actionTypes.DOWNLOAD_TARGET_FILE.REQUEST, downloadTargetFile);
}

function* watchDownloadBilingualExcel() {
  yield takeLatest(actionTypes.DOWNLOAD_BILINGUAL_EXCEL.REQUEST, downloadBilingualExcel);
}

function* watchDownloadBilingualTmx() {
  yield takeLatest(actionTypes.DOWNLOAD_BILINGUAL_TMX.REQUEST, downloadBilingualTmx);
}

function* watchShowPreview() {
  yield takeLatest(actionTypes.SHOW_PREVIEW.REQUEST, showPreview);
}

function* watchAnalysisReport() {
  yield takeLatest(actionTypes.ANALYSIS_REPORT.REQUEST, analysisReport);
}

export {
  watchRetrieve,
  watchReCommit,
  watchUpdateAnalysis,
  watchSourcefileUpload,
  watchDownloadComments,
  watchDownloadTargetFile,
  watchDownloadBilingualExcel,
  watchDownloadBilingualTmx,
  watchShowPreview,
  watchAnalysisReport,
}