import React from 'react';
import { Box } from 'reactjs-admin-lte';
import TooltipIconButton from 'components/core/TooltipIconButton';
import DocumentTable from 'components/documents/DocumentTable';


const Sourcefile = (props) => {
  
  const {
    title,
    documents,
    showUploadModal,
    handleRemove,
    handleDownload
  } = props;
  
  return (
    <Box className="box-widget">
      <Box.Header border={true}>
        <Box.Title>{title}</Box.Title>
        <Box.Tools>
          <TooltipIconButton
            id="upload-new-source-file"
            className="btn-box-tool"
            icon="upload"
            description="Upload Source File"
            onClick={showUploadModal} />
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <DocumentTable
          documents={documents}
          removable={true}
          handleRemove={handleRemove}
          handleDownload={handleDownload} />
      </Box.Body>
    </Box>
  );
}


Sourcefile.defaultProps = {
  title: 'Source Files'
}

export default Sourcefile;
