import React from 'react';
import SelectField from './SelectField';
import LabeledField from 'components/core/LabeledField';


const LabeledSelectField = (props) => {
  return (
    <LabeledField {...props}>
      <SelectField {...props} />
    </LabeledField>
  );
}

export default LabeledSelectField;