import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector, change } from 'redux-form';
import { Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import isEqual from 'lodash.isequal';
import * as validators from 'misc/validators';
import { JOB_LEVEL_OPTIONS } from 'misc/config';
import CharField from 'components/core/CharField';
import SelectField from 'components/core/SelectField';
import DatePickerField from 'components/core/DatePickerField';
import CheckboxField from 'components/core/CheckboxField';
import TimeField from 'components/core/TimeField';
import ClientAsyncSelectField from 'components/core/ClientAsyncSelectField';
import { getClientEntities } from 'selectors/entities';
import { getAssigneeGroupOptions, getFieldOptionsByJobType } from 'selectors/options';
import JobSourceCountInputField from 'components/jobs/managers/JobSourceCountInputField';
import { ServiceSelectOptionField, IndustrySelectOptionField } from 'components/core/SelectOptionField';
import PureRenderComponent from 'components/core/PureRenderComponent';


class JobBasicInfoFields extends PureRenderComponent {

  componentDidMount() {
    this.modifyFieldValue()
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.fieldOptions, this.props.fieldOptions)) {
      this.modifyFieldValue()
    }

    if (prevProps.client !== this.props.client) {
      this.modifyIndustryValue();
    }
  }

  modifyFieldValue = () => {
    if (this.props.fieldOptions
        .filter(c => c.value)
        .map(c => c.value)
        .includes(this.props.field) === false
    ) {
      this.props.change(this.props.form, 'field', '')
    }
  }

  modifyIndustryValue = () => {
    const client = this.props.clientEntities[this.props.client];
    const industry = client ? client.industry : '';
    this.props.change(this.props.form, 'industry', industry);
  }


  render() {

    const {
      form,
      numberField,
      subjectField,
      memoField = null,
      fieldOptions,
      additionalCountRolesField,
      userField,
      memoryConfirmedParamsField = null,
      isLoading,
    } = this.props;

    return (
      <Box
        className="box-widget"
        loading={isLoading}>
        <Box.Header border={true}>
          <Box.Title>Basic Information</Box.Title>
          <Box.Tools>
            <Box.CollapsedToggleButton />
          </Box.Tools>
        </Box.Header>
        <Box.Body>
          {numberField}
          {subjectField}
          {memoField}
          <Row>
            <Col lg={6}>
              <ServiceSelectOptionField
                validate={[validators.required]}
                isRequired={true} />
            </Col>
            <Col lg={6}>
              <Field
                name="level"
                label="Level"
                validate={[validators.required]}
                isRequired={true}
                options={JOB_LEVEL_OPTIONS}
                component={SelectField.Labeled} />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Field
                name="client"
                label="Client"
                validate={[validators.required]}
                isRequired={true}
                component={ClientAsyncSelectField.Labeled} />
            </Col>
            <Col lg={6}>
              <Field
                name="receiveDate"
                label="Receive date"
                bsSize="sm"
                validate={[validators.required]}
                isRequired={true}
                component={DatePickerField.Labeled} />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Field
                name="deadlineDate"
                label="Deadline date"
                bsSize="sm"
                validate={[validators.required]}
                isRequired={true}
                component={DatePickerField.Labeled} />
            </Col>
            <Col lg={6}>
              <Field
                name="deadlineTime"
                label="Deadline time"
                validate={[validators.required]}
                isRequired={true}
                component={TimeField.Labeled} />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <IndustrySelectOptionField
                validate={[validators.required]}
                isRequired={true} />
            </Col>
            <Col lg={6}>
              <Field
                name="field"
                label="Field"
                validate={[validators.required]}
                isRequired={true}
                options={fieldOptions}
                component={SelectField.Labeled} />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Field
                name="sourceCount"
                label="Source count"
                bsSize="sm"
                formName={form}
                validate={[
                  validators.required,
                  validators.positiveNumber
                ]}
                isRequired={true}
                component={JobSourceCountInputField.Labeled} />
            </Col>
            <Col lg={6}>
              <Field
                name="targetCount"
                label="Target count"
                type="number"
                validate={[
                  validators.required,
                  validators.positiveNumber
                ]}
                isRequired={true}
                component={CharField.Labeled} />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Field
                name="additionalCount"
                label="Additional count"
                type="number"
                validate={[
                  validators.required,
                  validators.positiveNumber
                ]}
                isRequired={true}
                component={CharField.Labeled} />
            </Col>
            <Col lg={6}>
              <Field
                name="quotation"
                label="Quotation"
                type="number"
                validate={[
                  validators.required,
                  validators.positiveNumber
                ]}
                isRequired={true}
                component={CharField.Labeled} />
            </Col>
          </Row>
          {additionalCountRolesField}
          {userField}
          <Row>
            <Col lg={12}>
              <FormGroup>
                <ControlLabel>Options</ControlLabel>
                  <Field
                    className="mt-5 mb-10"
                    name="requireTotra"
                    label="Require ToTra"
                    component={CheckboxField.Labeled} />
                  <Field
                    className="mt-5 mb-10"
                    name="requireDtp"
                    label="Require DTP"
                    component={CheckboxField.Labeled} />
                  <Field
                    className="mt-5 mb-10"
                    name="strictDeadline"
                    label="Strict Deadline"
                    component={CheckboxField.Labeled} />
                  <Field
                    className="mt-5 mb-10"
                    name="futureJob"
                    label="Future Job"
                    component={CheckboxField.Labeled} />
                  <Field
                    className="mt-5 mb-10"
                    name="isUrgent"
                    label="Urgent Job"
                    component={CheckboxField.Labeled} />
                  {memoryConfirmedParamsField}
              </FormGroup>
            </Col>
          </Row>
        </Box.Body>
      </Box>
    )
  }
}

const mapStateToProps = (state, props) => {
  const formSelector = formValueSelector(props.form);
  const type = formSelector(state, 'type');
  const field = formSelector(state, 'field');
  const client = formSelector(state, 'client');
  const industry = formSelector(state, 'industry');
  return {
    type,
    field,
    client,
    industry,
    clientEntities: getClientEntities(state),
    fieldOptions: getFieldOptionsByJobType(state, type),
    assigneeGroupOptions: getAssigneeGroupOptions(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    change,
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobBasicInfoFields);
