import React from 'react';
import Logo from 'assets/images/logo.svg';


const Splash = (props) => {
  return (
    <div className="splash-screen">
      <div className="splash-loader"></div>
      <img className="splash-logo" src={Logo} alt="logo" />
    </div>
  );
}

export default Splash;
