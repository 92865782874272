import React from 'react';
import { FormControl } from 'react-bootstrap';
import LabeledField from 'components/core/LabeledField';
import HorizontalField from 'components/core/HorizontalField';


const CharField = (props) => {
  
  const {
    input,
    type,
    bsSize,
    placeholder,
    disabled
  } = props;
  
  return (
    <FormControl
      {...input}
      type={type}
      bsSize={bsSize}
      placeholder={placeholder}
      disabled={disabled} />
  );
}

CharField.defaultProps = {
  placeholder: '',
  bsSize: 'sm',
  disabled: false,
  type: 'text'
}

const LabeledCharField = (props) => {
  return (
    <LabeledField {...props}>
      <CharField {...props} />
    </LabeledField>
  );
}

const HorizontalCharField = (props) => {
  return (
    <HorizontalField {...props}>
      <CharField {...props} />
    </HorizontalField>
  )
}

CharField.Labeled = LabeledCharField;
CharField.Horizontal = HorizontalCharField;

export default CharField;
