import moment from 'moment';
import * as types from 'actionTypes/filters';
import * as constants from 'misc/constants';
import { DEFAULT_DATE_FORMAT } from 'misc/config';

const today = moment();
const currentDate = today.format('YYYY-MM-DD');
const currentMonth = today.format('YYYY-MM');
const currentYear = today.format('YYYY');

const initialState = {
  [constants.JOB_SCHEDULE]: {
    currentDate,
    deadline: 'final_editor',
    assignee: null
  },
  [constants.CALENDAR]: {
    group: 1,
    visibleRange: {
      start: today.startOf('isoWeek').format(DEFAULT_DATE_FORMAT),
      end: today.endOf('isoWeek').add(1, 'days').format(DEFAULT_DATE_FORMAT)
    },
  },
  [constants.EMPLOYEE_JOB_SCHEDULE]: {
    currentDate,
  },
  [constants.CLIENT_LIST]: {
    keyword: ''
  },
  [constants.USER_LIST]: {
    keyword: '',
    group: '',
    service: '',
  },
  [constants.PREPARING_LIST]: {
    coordinator: '',
  },
  [constants.INVOICE_LIST]: {
    currentMonth
  },
  [constants.SALES_REPORT]: {
    currentMonth
  },
  [constants.SALES_TARGET]: {
    currentYear
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_FILTER:
      return setFilter(state, action)
    default:
      return state;
  }
}

const setFilter = (state, action) => {
  const { name, key, value } = action.payload;
  return {
    ...state,
    [name]: {
      ...state[name],
      [key]: value
    }
  }
}