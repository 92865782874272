import React from 'react';
import FontAwesome from 'react-fontawesome';


const EmployeeJobFinishSuccess = (props) => {
  return (
    <div className="text-center success-message-confirm">
      <FontAwesome name="thumbs-o-up" />
      <br />
      <span>Success</span>
    </div>
  )
}

export default EmployeeJobFinishSuccess;