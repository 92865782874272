import React from 'react';
import { dateFormatter } from 'misc/cellFormatters';
import { JOB_DETAIL } from 'misc/constants';
import { deadlineComparator, getRowHeight } from 'misc/utils';
import {
  LinkFieldCellRenderer,
  ClientFieldCellRenderer,
  AssignmentFieldCellRenderer
} from 'misc/cellRenderers';
import ListTable from 'components/core/ListTable';
import PureRenderComponent from 'components/core/PureRenderComponent';


class JobPreparingListTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'No.',
          field: 'number',
          pinned: 'left',
          cellClass: 'ag-jobNumber',
          width: 140,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: JOB_DETAIL,
          },
        },
        {
          headerName: 'Client',
          field: 'client.name',
          cellRendererFramework: ClientFieldCellRenderer,
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data, nodeB.data)
          ),
          width: 150,
        },
        {
          headerName: 'Subject',
          field: 'subject',
          width: 250,
        },
        {
          headerName: 'Memo',
          field: 'memo',
          width: 250,
        },
        {
          headerName: 'Service',
          field: 'service.name',
          width: 80,
        },
        {
          headerName: 'Lv',
          field: 'level',
          width: 40,
        },
        {
          headerName: 'ToTra',
          colId: 'totra',
          field: 'totra.assignee.name',
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.totra, nodeB.data.totra)
          ),
          cellRendererFramework: AssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'totra'
          },
          width: 85,
        },
        {
          headerName: 'Tra',
          field: 'translator.assignee.name',
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.translator, nodeB.data.translator)
          ),
          cellRendererFramework: AssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'translator'
          },
          width: 85,
        },
        {
          headerName: 'Edi',
          field: 'editor.assignee.name',
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.editor, nodeB.data.editor)
          ),
          cellRendererFramework: AssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'editor'
          },
          width: 85,
        },
        {
          headerName: 'Che',
          colId: 'checker',
          field: 'checker.assignee.name',
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.checker, nodeB.data.checker)
          ),
          cellRendererFramework: AssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'checker'
          },
          width: 85,
        },
        {
          headerName: 'FE1',
          field: 'finalEditor.assignee.name',
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.finalEditor, nodeB.data.finalEditor)
          ),
          cellRendererFramework: AssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'finalEditor'
          },
          width: 85,
        },
        {
          headerName: 'FE2',
          field: 'secondFinalEditor.assignee.name',
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.secondFinalEditor, nodeB.data.secondFinalEditor)
          ),
          cellRendererFramework: AssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'secondFinalEditor'
          },
          width: 85,
        },
        {
          headerName: 'DTP1',
          field: 'dtp.assignee.name',
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.dtp, nodeB.data.dtp)
          ),
          cellRendererFramework: AssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'dtp'
          },
          width: 85,
        },
        {
          headerName: 'DTP2',
          field: 'secondDtp.assignee.name',
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.secondDtp, nodeB.data.secondDtp)
          ),
          cellRendererFramework: AssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'secondDtp'
          },
          width: 85,
        },
        {
          headerName: 'Receive Date',
          field: 'receiveDate',
          valueFormatter: dateFormatter,
          width: 130,
        },
        {
          headerName: 'Target',
          field: 'targetCount',
          width: 90,
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'AE',
          field: 'ae.name',
          width: 80,
        },
        {
          headerName: 'Coordinator',
          field: 'coordinator.name',
          filter: 'agTextColumnFilter',
          pinned: 'right',
          width: 120,
        },
      ]
    }
  }

  render() {
    return (
      <ListTable
        wrapperHeight="81vh"
        rowClass='ag-double-row'
        getRowHeight={getRowHeight}
        columnDefs={this.state.columnDefs}
        rowData={this.props.jobs} />
    )
  }
}


export default JobPreparingListTable;
