import React from 'react';
import { Field } from 'redux-form';
import SelectField from 'components/core/SelectField';


const SelectOptionField = (props) => {

  const {
    name,
    label,
    options,
    validate,
    isRequired,
    type
  } = props;
  
  const component = SelectField[type];

  return (
    <Field
      name={name}
      label={label}
      options={options}
      validate={validate}
      isRequired={isRequired}
      component={component} />
  );
}

SelectOptionField.defaultProps = {
  type: 'Labeled'
}

export default SelectOptionField;