import React from 'react';

const Callout = (props) => {
  
  const {
    invalid,
    submitFailed,
    title = 'Caution',
    message = 'Please fill all required fields with valid value.'
  } = props;
  
  return invalid && submitFailed
    ? (
      <div className="callout callout-danger">
        <h4>{title}</h4>
        <p>{message}</p>
      </div>
    ): null
}

export default Callout;
