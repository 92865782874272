import { createSelector } from 'reselect';
import orderBy from 'lodash.orderby';
import _filter from 'lodash.filter';
import { EMPLOYEE_STATUS_MAP, ASSIGNMENT_ROLE_KEY_MAP } from 'misc/config';
import { getUsersByGroup, getUserOptions } from 'misc/utils';
import { getMyGroups } from 'selectors/auth';
import { getFormName, getId, getJobType, getIdList } from 'selectors/core';
import { getPaymentSearchRole } from 'selectors/forms';
import { isCoordinator, isAE, isSalesSupport } from 'selectors/auth';
import { getJobById, getAssignmentsByJobId } from 'selectors/jobs';
import { getJobScheduleDeadline } from 'selectors/filters';
import { getClientPreferableUsersById, getClientNgUsersById } from 'selectors/lists';
import {
  getUserEntities,
  getGroupEntities,
  getCountryEntities,
  getParentIndustryEntities,
  getIndustryEntities,
  getParentCategoryEntities,
  getCategoryEntities,
  getParentFieldEntities,
  getFieldEntities,
  getServiceEntities,
  getClientEntities,
  getJobEntities,
  getWordListEntities,
  getGlossaryEntities
} from 'selectors/entities';


const getUserOptionsByGroup = (userEntities, groupEntities, groupName) => {
  const users = getUsersByGroup(userEntities, groupEntities, groupName);
  return getUserOptions(users);
}


export const getMessageReceiverOptions = createSelector(
  [getGroupEntities],
  (groupEntities) => {
    const groups = groupEntities ? Object.values(groupEntities) : [];
    const filteredGroups = groups.filter(group => (
      group.name !== 'Sales Support' &&
      group.name !== 'Accounting'
    ));
    const sortedGroups = orderBy(filteredGroups, group => group.id)
    return sortedGroups.map(group => ({
      label: group.name,
      value: group.id,
      disabled: (
        group.name === 'Coordinator' ||
        group.name === 'AE'
      )
    }));
  }
)

// AE Options ==================================================================
export const getAEOptions = createSelector(
  [getUserEntities, getGroupEntities],
  (userEntities, groupEntities) => {
    return getUserOptionsByGroup(userEntities, groupEntities, 'AE');
  }
);

// Coordinator Options =========================================================
export const getCoordinatorOptions = createSelector(
  [getUserEntities, getGroupEntities],
  (userEntities, groupEntities) => {
    return getUserOptionsByGroup(userEntities, groupEntities, 'Coordinator');
  }
);

// ToTra Options ===============================================================
export const getToTraOptions = createSelector(
  [getUserEntities, getGroupEntities],
  (userEntities, groupEntities) => {
    return getUserOptionsByGroup(userEntities, groupEntities, 'ToTra');
  }
);

// Translator Options ==========================================================
export const getTranslatorOptions = createSelector(
  [getUserEntities, getGroupEntities],
  (userEntities, groupEntities) => {
    return getUserOptionsByGroup(userEntities, groupEntities, 'Translator');
  }
);

// Editor Options ==========================================================
export const getEditorOptions = createSelector(
  [getUserEntities, getGroupEntities],
  (userEntities, groupEntities) => {
    return getUserOptionsByGroup(userEntities, groupEntities, 'Editor');
  }
);

// Checker Options ==========================================================
export const getCheckerOptions = createSelector(
  [getUserEntities, getGroupEntities],
  (userEntities, groupEntities) => {
    return getUserOptionsByGroup(userEntities, groupEntities, 'Checker');
  }
);

// Final Editor Options ========================================================
export const getFinalEditorOptions = createSelector(
  [getUserEntities, getGroupEntities],
  (userEntities, groupEntities) => {
    return getUserOptionsByGroup(userEntities, groupEntities, 'Final Editor');
  }
);

// Second Final Editor Options =================================================
export const getSecondFinalEditorOptions = createSelector(
  [getUserEntities, getGroupEntities],
  (userEntities, groupEntities) => {
    return getUserOptionsByGroup(userEntities, groupEntities, 'Second Final Editor');
  }
);

// DTP Options =================================================================
export const getDtpOptions = createSelector(
  [getUserEntities, getGroupEntities],
  (userEntities, groupEntities) => {
    return getUserOptionsByGroup(userEntities, groupEntities, 'DTP');
  }
);

// Second DTP Options ==========================================================
export const getSecondDtpOptions = createSelector(
  [getUserEntities, getGroupEntities],
  (userEntities, groupEntities) => {
    return getUserOptionsByGroup(userEntities, groupEntities, 'Second DTP');
  }
);

// Options =====================================================================
export const getMyAssignmentRoleOptions = createSelector(
  [getMyGroups],
  (myGroups) => {
    const sortedMyGroups = orderBy(myGroups, group => group.id);
    const filteredRoles = sortedMyGroups.filter(group => (
      group.name !== 'AE' &&
      group.name !== 'Coordinator' &&
      group.name !== 'Sales Support' &&
      group.name !== 'Accounting'
    ))
    return filteredRoles.map(role => ({
      label: role.name,
      value: role.id
    }));
  }
)


export const getGroupOptions = createSelector(
  [getGroupEntities],
  (groupEntities) => {
    const sortedGroups = orderBy(groupEntities, ['id']);
    return sortedGroups.map(group => ({
      label: group.name,
      value: group.id
    }));
  }
)

// Assignee Group Options ======================================================
export const getAssigneeGroupOptions = createSelector(
  [getGroupOptions],
  (groupOptions) => {
    return groupOptions.filter(group => (
      ![
        'AE',
        'Coordinator',
        'Sales Support',
        'Accounting'
      ].includes(group.label)
    ))
  }
)

// Options =====================================================================
export const getPaymentSearchRoleOptions = createSelector(
  [getGroupEntities],
  (groupEntities) => {
    const sortedGroups = orderBy(groupEntities, ['id']);
    const filteredRoles = sortedGroups.filter(group => (
      ['Translator', 'Editor', 'Checker', 'DTP'].includes(group.name)
    ))
    return filteredRoles.map(role => ({
      label: role.name,
      value: role.id
    }));
  }
)

export const getPaymentSearchUserOptions = createSelector(
  [getPaymentSearchRole, getUserEntities],
  (role, userEntities) => {
    const sortedUsers = orderBy(userEntities, ['name']);
    const filteredUsers = role
      ? sortedUsers.filter(user => user.groups.includes(role))
      : []
    return filteredUsers.map(user => ({
      label: user.username,
      value: user.id
    }));
  }
)


// Industry Options ============================================================
export const getNestedIndustryOptions = createSelector(
  [getParentIndustryEntities, getIndustryEntities],
  (parentIndustryEntities, industryEntities) => {
    const options = [];
    const sortedParentIndustries = orderBy(
      parentIndustryEntities,
      industry => industry.order
    );
    sortedParentIndustries.forEach(parent => {
      options.push({ label: parent.name, disabled: true });
      parent.children.forEach(industry => {
        const child = industryEntities[industry];
        options.push({
          label: child.name,
          value: child.id,
          parent: parent.name
        });
      });
    });
    return options;
  }
)

// Field Options ===============================================================
export const getNestedFieldOptions = createSelector(
  [getParentFieldEntities, getFieldEntities],
  (parentFieldEntities, fieldEntities) => {
    const options = [];
    const sortedParentfields = orderBy(
      parentFieldEntities,
      field => field.order
    );
    sortedParentfields.forEach(parent => {
      options.push({ label: parent.name, disabled: true });
      parent.children.forEach(field => {
        const child = fieldEntities[field];
        options.push({
          label: child.name,
          value: child.id,
          parent: parent.name
        });
      });
    });
    return options;
  }
)

export const getFieldOptionsByJobType = createSelector(
  [getJobType, getParentFieldEntities, getFieldEntities],
  (jobType, parentFieldEntities, fieldEntities) => {
    const options = [];
    const sortedParentFields = orderBy(
      parentFieldEntities,
      field => field.order
    );
    sortedParentFields.forEach(parent => {
      options.push({ label: parent.name, disabled: true });
      parent.children.forEach(field => {
        const child = fieldEntities[field]
        options.push({
          label: `${child.name} - ${parent.name}`,
          value: child.id,
          parent: parent.name
        });
      });
    });

    switch (jobType) {
      case 'MD':
        return options.filter(field => (
          field.label === 'メディカル文書' ||
          field.label === 'メディカル対応分野' ||
          field.label === '医療機器' ||
          field.parent === 'メディカル文書' ||
          field.parent === 'メディカル対応分野' ||
          field.parent === '医療機器'
        ));
      case 'IR':
        return options.filter(field => (
          field.label === 'IR' ||
          field.parent === 'IR'
        ));
      default:
        return options;
    }
  }
)


// Category Options ============================================================
export const getParentCategoryOptions = createSelector(
  [getParentCategoryEntities],
  (parentCategoryEntities) => {
    const sortedParentCategories = orderBy(parentCategoryEntities, ['order'])
    return sortedParentCategories.map(category => ({
      label: category.name,
      value: category.id
    }));
  }
)

export const getCategoryOptions = createSelector(
  [getCategoryEntities],
  (categoryEntities) => {
    const sortedCategories = orderBy(categoryEntities, ['order'])
    return sortedCategories.map(category => ({
      label: category.name,
      value: category.id
    }));
  }
)

export const getNestedCategoryOptions = createSelector(
  [getParentCategoryEntities, getCategoryEntities],
  (parentCategoryEntities, categoryEntities) => {
    const options = [];
    const sortedParentCategories = orderBy(
      parentCategoryEntities,
      field => field.order
    );
    sortedParentCategories.forEach(parent => {
      options.push({ label: parent.name, parentValue: parent.id, disabled: true });
      parent.children.forEach(field => {
        const child = categoryEntities[field];
        options.push({
          label: child.name,
          value: child.id,
          parent: parent.name,
          parentValue: parent.id
        });
      });
    });
    return options;
  }
)

const getParentCategories = (state, parentCategories) => parentCategories;
export const getNestedCategoryOptionsByParents = createSelector(
  [getParentCategories, getNestedCategoryOptions],
  (parentCategories, nestedCategoryOptions) => {
    return nestedCategoryOptions.filter(
      option => parentCategories.includes(option.parentValue)
    )
  }
)

// export const getCategoryOptions = createSelector(
//   [getCategoryEntities], (categoryEntities) => {
//     const sortedCategories = orderBy(categoryEntities, ['order'])
//     return sortedCategories.map(category => ({
//       label: category.name,
//       value: category.id
//     }));
//   }
// )

// export const getParentCategoryOptions = createSelector(
//   [getParentCategoryEntities], (parentCategoryEntities) => {
//     const sortedParentCategories = orderBy(parentCategoryEntities, ['order'])
//     return sortedParentCategories.map(category => ({
//       label: category.name,
//       value: category.id,
//     }));
//   }
// )

// export const getCategoryOptions = createSelector(
//   [getJobType, getParentCategoryEntities, getCategoryEntities],
//   (jobType, parentCategoryEntities, categoryEntities) => {
//     const options = [];
//     const sortedParentCategories = orderBy(
//       parentCategoryEntities,
//       category => category.order
//     );
//     sortedParentCategories.forEach(parent => {
//       options.push({ label: parent.name, disabled: true });
//       parent.children.forEach(category => {
//         const child = categoryEntities[category]
//         options.push({
//           label: `${child.name} - ${parent.name}`,
//           value: child.id,
//           parent: parent.name
//         });
//       });
//     });

//     switch (jobType) {
//       case 'MD':
//         return options.filter(category => (
//           category.label === 'メディカル文書' ||
//           category.label === 'メディカル対応分野' ||
//           category.label === '医療機器' ||
//           category.parent === 'メディカル文書' ||
//           category.parent === 'メディカル対応分野' ||
//           category.parent === '医療機器'
//         ));
//       case 'IR':
//         return options.filter(category => (
//           category.label === 'IR' ||
//           category.parent === 'IR'
//         ));
//       default:
//         return options;
//     }
//   }
// )

// Service Options =============================================================
export const getServiceOptions = createSelector(
  [getServiceEntities], (serviceEntities) => {
    const jobServices = _filter(serviceEntities, s => s.isJobApproved === true)
    const sortedServices = orderBy(jobServices, ['order'])
    return sortedServices.map(service => ({
      label: service.name,
      value: service.id
    }));
  }
)

export const getMHTServiceOptions = createSelector(
  [getServiceEntities], (serviceEntities) => {
    const sortedServices = orderBy(serviceEntities, ['order'])
    const services = sortedServices.filter(service => service.isMhtApproved === true)
    return services.map(service => ({
      label: service.name,
      value: service.id
    }));
  }
)

export const getMemoryServiceOptions = createSelector(
  [getServiceEntities], (serviceEntities) => {
    const sortedServices = orderBy(serviceEntities, ['order'])
    const services = sortedServices.filter(service => service.isMemoryApproved === true)
    return services.map(service => ({
      label: service.name,
      value: service.id
    }));
  }
)

// Country Options =============================================================
export const getCountryOptions = createSelector(
  [getCountryEntities], (countryEntities) => {
    const sortedCountries = orderBy(countryEntities, ['order'])
    return sortedCountries.map(country => ({
      label: country.name,
      value: country.id
    }));
  }
)

// Client Options ==============================================================
export const getClientOptions = createSelector(
  [getClientEntities], (clientEntities) => {
    const sortedClients = orderBy(clientEntities, ['name'])
    const activeClients = sortedClients.filter(client => client.isActive);
    return activeClients.map(client => ({
      label: client.name,
      value: client.id,
      fullNameEn: client.fullNameEn,
      fullNameJp: client.fullNameJp
    }));
  }
)

// Job Options =================================================================
export const getJobNumberOptions = createSelector(
  [getJobEntities], (jobEntities) => {
    const sortedJobs = orderBy(jobEntities, ['number'])
    return sortedJobs.map(job => ({
      label: job.number,
      value: job.id,
    }));
  }
)

// User Preference Options =====================================================
export const getAvailableUserPreferenceOptions = createSelector(
  [
    getClientPreferableUsersById,
    getClientNgUsersById,
    getTranslatorOptions
  ],
  (preferableUsers, ngUsers, translatorOptions) => {
    const preferableUserIds = preferableUsers
      ? preferableUsers.filter(preferable => preferable.user).map(p => p.user.id)
      : [];
    const ngUserIds = ngUsers
      ? ngUsers.filter(ng => ng.user).map(n => n.user.id)
      : [];
    return translatorOptions.filter(option => (
      !preferableUserIds.includes(option.value) &&
      !ngUserIds.includes(option.value)
    ))
  }
)

export const getViewAsOptions = createSelector(
  [isCoordinator, isAE, isSalesSupport],
  (isCoordinator, isAE, isSalesSupport) => {
    const options = [];
    if (isCoordinator) {
      options.push({
        label: 'Coordinator',
        value: 'coordinator'
      });
    }
    if (isSalesSupport) {
      options.push({
        label: 'Sales Support',
        value: 'sales_support'
      });
    }
    if (isAE) {
      options.push({
        label: 'AE',
        value: 'ae'
      });
    }
    return options;
  }
)

export const getAssigneeOptionsByDeadline = createSelector(
  [
    getTranslatorOptions, getEditorOptions,
    getCheckerOptions, getToTraOptions,
    getDtpOptions, getSecondDtpOptions,
    getJobScheduleDeadline
  ],
  (
    translatorOptions,
    editorOptions,
    checkerOptions,
    totraOptions,
    dtpOptions,
    secondDtpOptions,
    deadline
  ) => {
    switch (deadline) {
      case 'translator':
        return translatorOptions;
      case 'editor':
        return editorOptions;
      case 'checker':
        return checkerOptions;
      case 'totra':
          return totraOptions;
      case 'dtp':
          return dtpOptions;
      case 'second_dtp':
          return secondDtpOptions;
      default:
        return [];
    }
  }
)

export const getMemberOptionsByJobId = createSelector(
  [getJobById, getJobEntities],
  (job, jobEntities) => {
    if (job && job.members && job.members.length > 0) {
      const jobs = job.members.map(id => jobEntities[id]);
      return jobs.filter(job => job !== undefined && job !== null).map(
        job => ({
          label: job.number,
          value: job.id,
        }));
    }
    return [];
  }
)

export const getJobDeliveryMemberOptionsByJobId = createSelector(
  [getJobById, getJobEntities],
  (job, jobEntities) => {
    if (job && job.members && job.members.length > 0) {
      const jobs = job.members.map(id => jobEntities[id]);
      return jobs.filter(job => job !== undefined && job !== null).map(
        job => ({
          label: job.number,
          value: job.id,
          disabled: job.status !== 'Invoicing Now' || !!job.invoice === true
        }));
    }
    return [];
  }
)

export const getJobManullyProcessOptionsByJobId = createSelector(
  [getAssignmentsByJobId, isCoordinator],
  (assignments, isCoordinator) => {
    const sendJobOption = {
      label: 'Send Job(to Client)',
      value: 'Invoicing Now',
      meta: null
    };
    if (!isCoordinator) {
      return [sendJobOption];
    }
    const statusOptions = [{
      label: 'Job Created',
      value: 'Job Created',
      meta: null
    }];
    if (assignments && assignments.length > 0) {
      assignments.forEach(assignment => {
        const roleKey = ASSIGNMENT_ROLE_KEY_MAP[assignment.role.name];
        const statuses = EMPLOYEE_STATUS_MAP[roleKey];
        const sent = statuses.sent;
        const validate = statuses.validate;
        statusOptions.push({
          label: sent,
          value: sent,
          meta: roleKey
        });
        statusOptions.push({
          label: validate,
          value: validate,
          meta: roleKey
        });
      })
    }
    statusOptions.push({
      label: 'AE Validating',
      value: 'AE Validating',
      meta: null
    })
    statusOptions.push(sendJobOption);
    statusOptions.push({
      label: 'Job Canceled',
      value: 'Job Canceled',
      meta: null
    });
    return statusOptions;
  }
)

const _getAssigneeOptions = state => state.jobs.assigneeOptions;
export const getAssigneeOptions = createSelector(
  [getFormName, _getAssigneeOptions, getUserEntities],
  (form, assigneeOptions, userEntities) => {
    const options = assigneeOptions[form];
    const mapFn = (id) => ({ label: userEntities[id].username, value: id });
    const optionFn = (key) => (options && options[key] ? options[key].map(mapFn) : []);
    return {
      totraOptions: optionFn('totra'),
      translatorOptions: optionFn('translator'),
      editorOptions: optionFn('editor'),
      checkerOptions: optionFn('checker'),
      finalEditorOptions: optionFn('finalEditor'),
      secondFinalEditorOptions: optionFn('secondFinalEditor'),
      dtpOptions: optionFn('dtp'),
      secondDtpOptions: optionFn('secondDtp')
    }
  }
)

export const getWordListOptionsById = createSelector(
  [getId, getWordListEntities],
  (id, wordlistEntities) => {
    const options = [];
    _filter(wordlistEntities, entity => entity.client === id).forEach(wordlist => {
      options.push({ label: wordlist.name, value: wordlist.id });
    })
    return options;
  }
)

export const getWordListOptionsByIds = createSelector(
  [getIdList, getWordListEntities],
  (ids, wordlistEntities) => {
    const options = [];
    _filter(wordlistEntities, entity => ids.includes(entity.client)).forEach(wordlist => {
      options.push({ label: wordlist.name, value: wordlist.id });
    })
    return options;
  }
)

export const getGlossaryOptions = createSelector(
  [getGlossaryEntities],
  (glossaryEntities) => {
    const options = [];
    const entities = glossaryEntities ? Object.values(glossaryEntities) : [];
    entities.forEach(glossary => {
      options.push({ label: glossary.name, value: glossary.id });
    })
    return options;
  }
)

export const getGlossaryOptionsByIds = createSelector(
  [getIdList, getGlossaryEntities],
  (ids, glossaryEntities) => {
    const options = [];
    _filter(glossaryEntities, entity => ids.includes(entity.category)).forEach(glossary => {
      options.push({ label: glossary.name, value: glossary.id });
    })
    return options;
  }
)

export const getWordListOptions = createSelector(
  [getWordListEntities, getClientEntities], (wordlistEntities, clientEntities) => {
    const sortedWordLists = orderBy(wordlistEntities, ['name'])
    return sortedWordLists.map(wordlist => {
      const client = clientEntities[wordlist.client]
      return {
        label: wordlist.name,
        value: wordlist.id,
        clientName: client ? client.name : '',
        clientFullNameEn: client ? client.fullNameEn: '',
        clientFullNameJp: client ? client.fullNameJp: ''
      }
    });
  }
)
