import * as types from 'actionTypes/mht';
import * as utils from 'misc/utils';

// Retrieve ====================================================================
const retrieve = utils.createRetrieveAction(types.RETRIEVE);
export const retrieveRequest = retrieve.requestFn;
export const retrieveSuccess = retrieve.successFn;
export const retrieveFailure = retrieve.failureFn;

// MHT Sourcefile Upload =======================================================
export const sourcefileUploadRequest = (values) => ({
  type: types.SOURCEFILE_UPLOAD.REQUEST,
  payload: { values }
});
export const sourcefileUploadSuccess = () => ({
  type: types.SOURCEFILE_UPLOAD.SUCCESS
});
export const sourcefileUploadFailure = (error) => ({
  type: types.SOURCEFILE_UPLOAD.FAILURE,
  payload: { error }
});

// List Pending
const listPending = utils.createSearchAction(types.LIST_PENDING);
export const listPendingRequest = listPending.requestFn;
export const listPendingSuccess = listPending.successFn;
export const listPendingFailure = listPending.failureFn;

export const commitPendingRequest = (values) => ({
  type: types.COMMIT_PENDING.REQUEST,
  payload: { values }
});
export const commitPendingSuccess = () => ({
  type: types.COMMIT_PENDING.SUCCESS
});
export const commitPendingFailure = (error) => ({
  type: types.COMMIT_PENDING.FAILURE,
  payload: { error }
});

export const rejectPendingRequest = (values) => ({
  type: types.REJECT_PENDING.REQUEST,
  payload: { values }
});
export const rejectPendingSuccess = () => ({
  type: types.REJECT_PENDING.SUCCESS
});
export const rejectPendingFailure = (error) => ({
  type: types.REJECT_PENDING.FAILURE,
  payload: { error }
});

export const downloadCommentsRequest = (id, file) => ({
  type: types.DOWNLOAD_COMMENTS.REQUEST,
  payload: { id, file }
})

export const downloadCommentsSuccess = () => ({
  type: types.DOWNLOAD_COMMENTS.SUCCESS,
})

export const downloadCommentsFailure = (error) => ({
  type: types.DOWNLOAD_COMMENTS.FAILURE,
  payload: { error }
})

export const downloadTargetFileRequest = (id, file, language, fontSizeAdjust, fontSize, fontFamilyAdjust, fontFamily) => ({
  type: types.DOWNLOAD_TARGET_FILE.REQUEST,
  payload: { id, file, language, fontSizeAdjust, fontSize, fontFamilyAdjust, fontFamily }
})

export const downloadTargetFileSuccess = () => ({
  type: types.DOWNLOAD_TARGET_FILE.SUCCESS,
})

export const downloadTargetFileFailure = (error) => ({
  type: types.DOWNLOAD_TARGET_FILE.FAILURE,
  payload: { error }
})

export const downloadBilingualExcelRequest = (id, role, file) => ({
  type: types.DOWNLOAD_BILINGUAL_EXCEL.REQUEST,
  payload: { id, role, file }
})
export const downloadBilingualExcelSuccess = () => ({
  type: types.DOWNLOAD_BILINGUAL_EXCEL.SUCCESS,
})

export const downloadBilingualExcelFailure = (error) => ({
  type: types.DOWNLOAD_BILINGUAL_EXCEL.FAILURE,
  payload: { error }
})

export const downloadBilingualTmxRequest = (id, file) => ({
  type: types.DOWNLOAD_BILINGUAL_TMX.REQUEST,
  payload: { id, file }
})

export const downloadBilingualTmxSuccess = () => ({
  type: types.DOWNLOAD_BILINGUAL_TMX.SUCCESS,
})

export const downloadBilingualTmxFailure = (error) => ({
  type: types.DOWNLOAD_BILINGUAL_TMX.FAILURE,
  payload: { error }
})

export const updateAnalysisRequest = (id) => ({
  type: types.UPDATE_ANALYSIS.REQUEST,
  payload: { id }
})

export const updateAnalysisSuccess = () => ({
  type: types.UPDATE_ANALYSIS.SUCCESS,
})

export const updateAnalysisFailure = (error) => ({
  type: types.UPDATE_ANALYSIS.FAILURE,
  payload: { error }
})

export const showPreviewRequest = (id) => ({
  type: types.SHOW_PREVIEW.REQUEST,
  payload: { id }
})

export const showPreviewSuccess = () => ({
  type: types.SHOW_PREVIEW.SUCCESS,
})

export const showPreviewFailure = (error) => ({
  type: types.SHOW_PREVIEW.FAILURE,
  payload: { error }
})

export const analysisReportRequest = (currentMonth) => ({
  type: types.ANALYSIS_REPORT.REQUEST,
  payload: { currentMonth }
})

export const analysisReportSuccess = () => ({
  type: types.ANALYSIS_REPORT.SUCCESS,
})

export const analysisReportFailure = (error) => ({
  type: types.ANALYSIS_REPORT.FAILURE,
  payload: { error }
})


export const reCommitRequest = (id) => ({
  type: types.RECOMMIT.REQUEST,
  payload: { id }
})

export const reCommitSuccess = () => ({
  type: types.RECOMMIT.SUCCESS,
})

export const reCommitFailure = (error) => ({
  type: types.RECOMMIT.FAILURE,
  payload: { error }
})