import * as types from 'actionTypes/wordlists';
import * as utils from 'misc/utils';


// List ========================================================================
const list = utils.createSearchAction(types.LIST);
export const listRequest = list.requestFn;
export const listSuccess = list.successFn;
export const listFailure = list.failureFn;

// Retrieve ====================================================================
const retrieve = utils.createRetrieveAction(types.RETRIEVE);
export const retrieveRequest = retrieve.requestFn;
export const retrieveSuccess = retrieve.successFn;
export const retrieveFailure = retrieve.failureFn;

// Update ======================================================================
export const updateWordListRequest = (values) => ({
  type: types.UPDATE.REQUEST,
  payload: { values }
});
export const updateWordListSuccess = (values) => ({
  type: types.UPDATE.SUCCESS,
  payload: { values }
});

export const updateWordListFailure = (error) => ({
  type: types.UPDATE.FAILURE,
  payload: { error }
});

// Batch Delete ================================================================
export const batchDeleteRequest = (values) => ({
  type: types.BATCH_DELETE.REQUEST,
  payload: { values }
});
export const batchDeleteSuccess = (values) => ({
  type: types.BATCH_DELETE.SUCCESS,
  payload: { values }
});

export const batchDeleteFailure = (error) => ({
  type: types.BATCH_DELETE.FAILURE,
  payload: { error }
});


// Upload ======================================================================
export const uploadRequest = (values) => ({
  type: types.UPLOAD.REQUEST,
  payload: { values }
});

export const uploadSuccess = () => ({
  type: types.UPLOAD.SUCCESS
});

export const uploadFailure = (error) => ({
  type: types.UPLOAD.FAILURE,
  payload: { error }
});


// Export WordList Terms =======================================================

export const exportExcelRequest = (id, name, colKeys) => ({
  type: types.EXPORT_EXCEL.REQUEST,
  payload: { id, name, colKeys }
})
export const exportExcelSuccess = (id) => ({
  type: types.EXPORT_EXCEL.SUCCESS,
})
export const exportExcelFailure = (error) => ({
  type: types.EXPORT_EXCEL.FAILURE,
  payload: { error }
})

// Autocomplete ================================================================
export const autocompleteRequest = (q) => ({
  type: types.AUTOCOMPLETE.REQUEST,
  payload: { q }
});

export const autocompleteSuccess = () => ({
  type: types.AUTOCOMPLETE.SUCCESS
});

export const autocompleteFailure = (error) => ({
  type: types.AUTOCOMPLETE.FAILURE,
  payload: { error }
});