import React from "react";
import {TaTabs} from "../../taUi/taTabs/taTabs";
import {TaTab} from "../../taUi/taTabs/taTab";
import styles from "./TaMhtEditorWidget.module.css";
import {TaMhtEditorWidgetReplace} from "./TaMhtEditorWidgetReplace";
import {TaMhtEditorWidgetFilter} from "./TaMhtEditorWidgetFilter";
import * as PropTypes from "prop-types";
import {TaMhtEditorWidgetSpellcheck} from "./TaMhtEditorWidgetSpellcheck";
import {escapeStringRegexp} from "./textTools";

const defaultHighlightState = {
  string: '',
  items: [],
  data: {},
  count: 0,
  time: 0
}

export class TaMhtEditorWidgets extends React.Component {

  static propTypes = {
    segments: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    filter: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    highlight: PropTypes.object.isRequired,

    defaultSearch: PropTypes.func.isRequired,
    onSearchRequest: PropTypes.func.isRequired,
    onFilterRequest: PropTypes.func.isRequired,
    onHighlightRequest: PropTypes.func.isRequired,
    onReplaceRequest: PropTypes.func.isRequired,

    onResetFilterRequest: PropTypes.func.isRequired,
    pickSegmentProperty: PropTypes.func.isRequired,
    pickMutatedSegmentProperty: PropTypes.func.isRequired,

    checkSaveWarningModal: PropTypes.func.isRequired,
  };

  goPrevHighlight = () => {
    const highlight = this.props.highlight;
    const index = (highlight.activeIndex) ? highlight.activeIndex - 1 : (highlight.count) ? highlight.count - 1 : 0;
    this.setHighlightState({
      activeSegment: highlight.items[index].id,
      activeIndex: index,
    })
  }

  goNextHighlight = () => {
    const highlight = this.props.highlight;
    const index = (highlight.activeIndex + 1 < highlight.count) ? highlight.activeIndex + 1 : 0;
    this.setHighlightState({
      activeSegment: highlight.items[index].id,
      activeIndex: index,
    })
  }

  _applyHighlight = (find, htmlText, plainText, caseSensitive, key) => {

    // https://remark.js.org
    // https://mdxjs.com/guides/writing-a-plugin (unist-util-visit)
    // renderToStaticMarkup()

    // loop dom nodes
    // https://stackoverflow.com/questions/4256339/javascript-how-to-loop-through-all-dom-elements-on-a-page

    // https://stackoverflow.com/questions/2712136/how-do-i-make-this-loop-all-children-recursively

    // even try jquery node variant?
    // https://www.npmjs.com/package/jquery

    let matches = [];
    let flags = (!caseSensitive) ? 'mi' : 'm';

    const markUpSingle = (str) => {
      const re = new RegExp('(?<![>])' + escapeStringRegexp(find) + '(?!</mark>)', flags);
      return str.replace(re, (match) => {
        matches.push(key);
        return '<mark data-key="' + key + '">' + match + '</mark>';
      });
    }

    const markUpAll = (str) => {
      // const re = new RegExp('(?<!<(?:span|mark)[^>]+?)' + find, 'g' + flags);
      // return str.replace(re, (match, offset, a) => {
      //   matches.push(offset);
      //   return '<mark data-key="' + offset + '">' + match + '</mark>';
      // });

      const re = new RegExp('([^<]*?)(' + escapeStringRegexp(find) + ')', 'g' + flags);
      return str.replace(re, (match, p1, p2, offset) => {
        let subStr = p1;
        if (p1 && (p1.startsWith('span') || p1.startsWith('mark'))) {
          subStr += p2;
        } else {
          matches.push(offset);
          subStr += '<mark data-key="' + offset + '">' + p2 + '</mark>';
        }
        return subStr;
      });

    }

    const markUp = (str) => {
      return (key) ? markUpSingle(str) : markUpAll(str)
    }

    let lostFormatting = false;
    let newString = markUp(htmlText);

    if (htmlText === newString) {
      // try again without formatting
      newString = '<p>' + markUp(plainText) + '</p>';
      lostFormatting = true;
    }

    return {
      string: newString,
      lostFormatting: lostFormatting,
      matches: matches
    };

  }

  applyHighlight = (find, htmlText, plainText, caseSensitive, key) => {

    let matches = [];
    let flags = (!caseSensitive) ? 'mi' : 'm';

    const markUpSingle = (str) => {
      const re = new RegExp('(?<![>])' + escapeStringRegexp(find) + '(?!</mark>)', flags);
      return str.replace(re, (match) => {
        matches.push(key);
        return '<mark data-key="' + key + '">' + match + '</mark>';
      });
    }
    const markUpAll = (str) => {
      const re = new RegExp('(?<!<(?:span|mark)[^>]+?)' + escapeStringRegexp(find), 'g' + flags);
      return str.replace(re, (match, offset, a) => {
        matches.push(offset);
        return '<mark data-key="' + offset + '">' + match + '</mark>';
      });

      // todo: safari:
      // const re = new RegExp('([^<]*?)('+escapeStringRegexp(find)+')', 'g'+flags);
      // return str.replace(re, (match,p1,p2,offset) => {
      //   let subStr = p1;
      //   if(p1 && (p1.startsWith('span') || p1.startsWith('mark'))){
      //     subStr += p2;
      //   } else {
      //     matches.push(offset);
      //     subStr += '<mark data-key="'+offset+'">' + p2 + '</mark>';
      //   }
      //   return subStr;
      // });

    }
    const markUp = (str) => {
      return (key) ? markUpSingle(str) : markUpAll(str)
    }

    let lostFormatting = false;
    let newString = markUp(htmlText);

    if (htmlText === newString) {
      // try again without formatting
      let spanTag = htmlText.replace(/^<p><span([^>]+)(.*$)/,'$1');
      if (htmlText === spanTag){
        spanTag = '';
      }
      newString = '<p><span'+spanTag+'>' + markUp(plainText) + '</span></p>';
      lostFormatting = true;
    }

    return {
      string: newString,
      lostFormatting: lostFormatting,
      matches: matches
    };

  }

  requestReplace = (newString, replaceAll) => {

    const props = this.props;
    const highlight = props.highlight;

    const items = highlight.items;
    const item = items[highlight.activeIndex];

    if (item) {

      const segmentId = item.id;
      const matchKey = item.key;

      const find = (typeof item.string !== 'undefined') ? item.string : highlight.string;

      const regexpMatch = new RegExp('<mark data-key="' + matchKey + '">' + escapeStringRegexp(find) + '</mark>', "gmi");
      const regexpClean = new RegExp('<mark data-key="(.*?)">(.*?)</mark>', "gmi");

      if (
        segmentId &&
        highlight.data[segmentId]
      ) {

        const replaced = highlight.data[segmentId].string.replace(
          regexpMatch,
          newString
        );

        const cleaned = replaced.replace(
          regexpClean,
          '$2'
        );

        props.onReplaceRequest(
          cleaned,
          props.segments.data[segmentId],
          () => {

            // removeHighlight

            const oldState = highlight;
            let newState = {
              items: [],
              data: {}
            }
            oldState.items.forEach(oItem => {
              const oItemId = oItem.id;
              if (
                segmentId === oItemId &&
                oItem.key === matchKey
              ) {

                if (oldState.data[oItemId].matches.length > 1) {
                  newState.data[oItemId] = oldState.data[oItemId];
                  newState.data[oItemId].string = replaced;
                  newState.data[oItemId].matches = newState.data[oItemId].matches.filter(m => m.key !== matchKey);
                }

              } else {
                newState.items.push(oItem);
                newState.data[oItemId] = oldState.data[oItemId];
              }

            })

            // return to first highlight, if last
            newState.activeIndex = (
              highlight.activeIndex + 1 < highlight.count
            ) ? highlight.activeIndex : 0;

            newState.activeSegment = (
              newState.items.length
            ) ? newState.items[newState.activeIndex].id : 0;

            this.setHighlightState(newState, true);

          }
        )
      }

    }

  }

  removeHighlight = (removeIndex, replaced) => {
    const oldState = this.props.highlight || {};
    const itemRef = oldState.items[removeIndex];

    // const removeMark = (str) => {
    //   const find = '<mark data-key="'+itemRef.key+'">' + oldState.string + '</mark>';
    //   const re = new RegExp(escapeStringRegexp(find), "g");
    //   return str.replace(re, oldState.string);
    // }

    let newState = {
      items: [],
      data: {}
    }
    oldState.items.forEach((item, index) => {
      if (index !== removeIndex) {
        newState.items.push(item);
        if (itemRef.id !== item.id) {
          newState.data[item.id] = oldState.data[item.id];
        } else {
          if (oldState.data[item.id].matches.length > 1) {
            newState.data[item.id] = oldState.data[item.id];
            newState.data[item.id].string = replaced;
            newState.data[item.id].matches = newState.data[item.id].matches.filter(m => m.key !== itemRef.key);
          }
        }
      }
    })
    this.setHighlightState(newState, true);
  }

  setHighlightState = (newState, forceScroll) => {

    const props = this.props;
    const oldState = props.highlight || {};

    let highlight = {
      ...defaultHighlightState,
      ...oldState,
      ...newState,
      time: Date.now()
    }
    highlight.count = highlight.items.length;

    const requestScroll = (forceScroll) ? highlight.activeSegment : (highlight.activeSegment !== oldState.activeSegment);

    props.onHighlightRequest(highlight, requestScroll);
  }

  render() {
    const props = this.props;

    return (
      <React.Fragment>
        <TaTabs
          spacing={'compact'}
          background={'grey'}
          className={styles.tabs}
          clear={true}
          onChange={(tab) => {
            // scroll top?
            props.onHighlightRequest(defaultHighlightState)
          }}
        >
          <TaTab
            label={'Filter'}
            className={styles.tabFilter}
          >
            <TaMhtEditorWidgetFilter
              search={props.search}
              filter={props.filter}
              defaultSearch={props.defaultSearch}
              onSearchRequest={props.onSearchRequest}
              onFilterRequest={props.onFilterRequest}
              onResetRequest={props.onResetFilterRequest}
            />
          </TaTab>
          <TaTab
            label={'Search & Replace'}
          >
            <TaMhtEditorWidgetReplace
              segments={props.segments}
              highlight={props.highlight}
              refresh={props.highlight.refresh}

              goPrevHighlight={this.goPrevHighlight}
              goNextHighlight={this.goNextHighlight}
              applyHighlight={this.applyHighlight}
              setHighlightState={this.setHighlightState}
              requestReplace={this.requestReplace}

              pickSegmentProperty={props.pickSegmentProperty}
              pickMutatedSegmentProperty={props.pickMutatedSegmentProperty}
            />
          </TaTab>
          <TaTab
            label={'Spellcheck'}
            disabled={props.targetLanguage !== 'en-US'}
          >
            <TaMhtEditorWidgetSpellcheck
              mhtId={props.mhtId}

              segments={props.segments}
              highlight={props.highlight}
              refresh={props.highlight.refresh}

              goPrevHighlight={this.goPrevHighlight}
              goNextHighlight={this.goNextHighlight}
              applyHighlight={this.applyHighlight}
              setHighlightState={this.setHighlightState}
              requestReplace={this.requestReplace}

              pickSegmentProperty={props.pickSegmentProperty}
              pickMutatedSegmentProperty={props.pickMutatedSegmentProperty}

              checkSaveWarningModal={props.checkSaveWarningModal}
            />
          </TaTab>
        </TaTabs>
      </React.Fragment>
    )
  }
}
