import { createAsyncActionTypes } from 'misc/utils';

const PREFIX = 'jobs';

// Documents ===================================================================
export const JOB_SOURCEFILE_LIST = createAsyncActionTypes(`${PREFIX}/JOB_SOURCEFILE_LIST`);
export const JOB_REFERENCE_LIST = createAsyncActionTypes(`${PREFIX}/JOB_REFERENCE_LIST`);
export const JOB_COMMON_REFERENCE_LIST = createAsyncActionTypes(`${PREFIX}/JOB_COMMON_REFERENCE_LIST`);
export const JOB_CLIENT_REFERENCE_LIST = createAsyncActionTypes(`${PREFIX}/JOB_CLIENT_REFERENCE_LIST`);

// Messages ====================================================================
export const JOB_MESSAGE_LIST = createAsyncActionTypes(`${PREFIX}/JOB_MESSAGE_LIST`);
export const JOB_MESSAGE_CREATE = createAsyncActionTypes(`${PREFIX}/JOB_MESSAGE_CREATE`);
export const JOB_MESSAGE_UPDATE = createAsyncActionTypes(`${PREFIX}/JOB_MESSAGE_UPDATE`);
export const JOB_MESSAGE_REMOVE = createAsyncActionTypes(`${PREFIX}/JOB_MESSAGE_REMOVE`);
