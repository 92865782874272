import React from "react";
import {TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";
import {Modal} from "react-bootstrap";
import {
  TRANSLATION_MEMORY_DETAIL_CLEAR_MODAL
} from "misc/constants";
import {connectModal} from "redux-modal";
import Spinner from "components/core/Spinner";
import {connect} from "react-redux";
import {isTranslationMemoryClearModalLoading} from "selectors/loading";

class TranslationMemoryClearModal extends React.Component {

  hide = () => {
    this.props.handleHide();
  };

  render() {
    const {
      show,
      onSubmit,
      isLoading
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={this.hide}
        animation={true}
        backdrop="static">
          <TaModalHeader
            label={'Clear Translation Memory'}
            onCancel={this.hide}
          />
          <TaModalContent>
            <div className="p-15">
              <p><b>Are you sure you want to continue?</b></p>
              <p>All translation units in this list will be <b>permanently deleted</b> from the translation memory.</p>
            </div>
            <Spinner isLoading={isLoading} />
          </TaModalContent>
          <TaModalFooter>
            <TaButton
              type="reset"
              icon={'clear'}
              label={'Cancel'}
              onClick={this.hide}
            />
            <TaButton
              type="submit"
              icon={'check'}
              iconAlign={'right'}
              label={'Confirm'}
              onClick={onSubmit}
            />
          </TaModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: isTranslationMemoryClearModalLoading(state)
  };
};

TranslationMemoryClearModal = connectModal({
  name: TRANSLATION_MEMORY_DETAIL_CLEAR_MODAL
})(TranslationMemoryClearModal);

export default connect(
  mapStateToProps
)(TranslationMemoryClearModal);