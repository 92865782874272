import { connect } from 'react-redux';
import { getNestedFieldOptions } from 'selectors/options';
import SelectOptionField from './SelectOptionField';


const mapStateToProps = (state) => {
  return {
    name: 'field',
    label: 'Field',
    options: getNestedFieldOptions(state),
  }
}

export default connect(
  mapStateToProps,
)(SelectOptionField);