import React from "react";
import PropTypes from 'prop-types';
import "../taInput/style.css";
import "./style.css";

import {TaIcon} from "components/taUi/taIcon";


export class TaInputTextarea extends React.PureComponent {

  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    autocomplete: PropTypes.oneOf(['off']),
    iconBefore: PropTypes.string,
    iconAfter: PropTypes.string,
    onEvent: PropTypes.func,
    autofocus: PropTypes.bool,
  };

  static defaultProps = {
    value: ''
  };

  constructor(props) {
    super(props);
    this.inputRef = null;
    this.setInputRef = element => {
      this.inputRef = element;
    };
    this.state = {
      hasFocus: false,
      value: ''
    };
  };

  componentDidMount() {
    if (
      this.props.value
    ) {
      this.setState({
        value: this.props.value
      });
    }
    if (
      this.props.autofocus &&
      this.inputRef
    ) {
      this.inputRef.focus();
    }
  }

  focus = () => {
    // Focus the text input using the raw DOM API
    if (this.textInput) this.textInput.focus();
  };

  onInputFocus = (event) => {
    this.setState({
      hasFocus: true
    });
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  onInputBlur = (event) => {
    this.setState({
      hasFocus: false
    });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  onInputChange = (event) => {
    this.updateStateValue(event.target.value);
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  onKeyUp = (event) => {
    if (event.key === 'Enter') {
      this.emitEvent('enter');
      // } else if (event.key === 'Escape') {
      //   this.updateStateValue('', 'escape');
    }
  };

  clearInput = () => {
    this.updateStateValue('');
  };

  updateStateValue = (value, eventName) => {
    this.setState(
      {
        value: value
      },
      () => this.emitEvent(eventName || 'change')
    );
  };

  emitEvent = (eventName) => {
    const props = this.props;
    if (props.onEvent) {
      props.onEvent({
        name: eventName,
        data: {
          property: props.name,
          value: this.state.value
        }
      });
    }
  };

  render() {

    const props = this.props;
    const inputId = props.id;
    const baseClass = 'ta-input';

    let classNames = [baseClass, baseClass + '--textarea'];
    if (props.isInvalid) {
      classNames.push(baseClass + '--invalid');
    }
    if (this.state.hasFocus) {
      classNames.push(baseClass + '--focus');
    }
    if (!this.state.value) {
      classNames.push(baseClass + '--empty');
    }
    if (props.className) {
      classNames.push(props.className);
    }

    return (
      <label id={inputId} className={classNames.join(' ')}>
        {props.iconBefore && <div className={'ta-input__icon'}><TaIcon name={props.iconBefore}/></div>}
        <textarea name={props.name}
                  value={this.state.value}
                  placeholder={props.placeholder}
                  onChange={this.onInputChange}
                  onKeyUp={this.onKeyUp}
                  onFocus={this.onInputFocus}
                  onBlur={this.onInputBlur}
                  ref={this.setInputRef}
        />
        {(props.iconAfter) && <div className="ta-input__icon"><TaIcon name={props.iconAfter}/></div>}
      </label>
    );
  }
}
