import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';
import { EMPLOYEE_ASSIGNMENT_PROJECT_UPLOAD_MODAL } from 'misc/constants';
import { getAssignmentProjectsById } from 'selectors/lists';
import {
  projectDownloadRequest,
  assignmentProjectUploadRequest,
  assignmentProjectRemoveRequest
} from 'actions/documents'
import PureRenderComponent from 'components/core/PureRenderComponent';
import EmployeeAssignmentProject from './presenter';



class EmployeeAssignmentProjectContainer extends PureRenderComponent {

  showUploadModal = () => {
    this.props.show(EMPLOYEE_ASSIGNMENT_PROJECT_UPLOAD_MODAL, {
      title: 'Completed File',
      handleUpload: this.handleUpload
    })
  }

  handleUpload = (files) => {
    this.props.assignmentProjectUploadRequest(
      this.props.assignmentId,
      files,
      this.props.proecessRequired,
    );
  }

  handleDownload = (file) => {
    this.props.projectDownloadRequest(file);
  }

  handleRemove = (file) => {
    this.props.assignmentProjectRemoveRequest(this.props.assignmentId, file)
  }

  render() {
    return (
      <EmployeeAssignmentProject
        {...this.props}
        showUploadModal={this.showUploadModal}
        handleDownload={this.handleDownload}
        handleRemove={this.handleRemove} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const assignmentId = props.assignmentId;
  return {
    projects: getAssignmentProjectsById(state, assignmentId)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    projectDownloadRequest,
    assignmentProjectUploadRequest,
    assignmentProjectRemoveRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeAssignmentProjectContainer)
