import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { Content } from 'reactjs-admin-lte';
import InvoiceListFilter from './InvoiceListFilter';
import InvoiceListTab from './InvoiceListTab';
import InvoiceListTabPane from './InvoiceListTabPane';


const InvoiceList = (props) => {
  
  const {
    isLoading,
    lsInvoices,
    mdInvoices,
    irInvoices,
    trInvoices,
    ttInvoices,
    ntInvoices,
    currentMonth,
    currentTab,
    handlePrev,
    handleNext,
    handleTabSelect,
    handleCheck,
    handleTabOpen
  } = props;
  
  return (
    <Content.Body>
      <InvoiceListFilter
        currentMonth={currentMonth}
        handlePrev={handlePrev}
        handleNext={handleNext} />
      <Tab.Container
        id="invoice-list"
        activeKey={currentTab}
        onSelect={handleTabSelect}>
        <div>
          <Nav bsStyle="tabs">
            <InvoiceListTab
              eventKey="ls"
              title="LS"
              isLoading={isLoading}
              invoices={lsInvoices} />
            <InvoiceListTab
              eventKey="md"
              title="MD"
              isLoading={isLoading}
              invoices={mdInvoices} />
            <InvoiceListTab
              eventKey="ir"
              title="IR"
              isLoading={isLoading}
              invoices={irInvoices} />
            <InvoiceListTab
              eventKey="tr"
              title="TR"
              isLoading={isLoading}
              invoices={trInvoices} />
            <InvoiceListTab
              eventKey="tt"
              title="TT"
              isLoading={isLoading}
              invoices={ttInvoices} />
            <InvoiceListTab
              eventKey="nt"
              title="NT"
              isLoading={isLoading}
              invoices={ntInvoices} />
          </Nav>
          <Tab.Content animation={false}>
            <InvoiceListTabPane
              eventKey="ls"
              isLoading={isLoading}
              invoices={lsInvoices}
              handleCheck={handleCheck}
              handleTabOpen={handleTabOpen} />
            <InvoiceListTabPane
              eventKey="md"
              isLoading={isLoading}
              invoices={mdInvoices}
              handleCheck={handleCheck}
              handleTabOpen={handleTabOpen} />
            <InvoiceListTabPane
              eventKey="ir"
              isLoading={isLoading}
              invoices={irInvoices}
              handleCheck={handleCheck}
              handleTabOpen={handleTabOpen} />
            <InvoiceListTabPane
              eventKey="tr"
              isLoading={isLoading}
              invoices={trInvoices}
              handleCheck={handleCheck}
              handleTabOpen={handleTabOpen} />
            <InvoiceListTabPane
              eventKey="tt"
              isLoading={isLoading}
              invoices={ttInvoices}
              handleCheck={handleCheck}
              handleTabOpen={handleTabOpen} />
            <InvoiceListTabPane
              eventKey="nt"
              isLoading={isLoading}
              invoices={ntInvoices}
              handleCheck={handleCheck}
              handleTabOpen={handleTabOpen} />
          </Tab.Content>
        </div>
      </Tab.Container>
    </Content.Body>
  )
}

export default InvoiceList;