import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clientCommonReferenceDownloadRequest } from 'actions/documents';
import { jobClientReferenceListRequest } from 'actions/jobs/common';
import { getJobClientReferencesById } from 'selectors/lists';
import { isJobClientReferenceListLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobClientReference from './presenter';


class JobClientReferenceContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.jobClientReferenceListRequest(this.props.jobId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.jobId && this.props.jobId !== prevProps.jobId) {
      this.props.jobClientReferenceListRequest(this.props.jobId);
    }
  }

  handleDownload = (file) => {
    this.props.clientCommonReferenceDownloadRequest(file);
  }
  
  render() {
    return (
      <JobClientReference
        {...this.props}
        handleDownload={this.handleDownload} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const id = props.jobId;
  return {
    isLoading: isJobClientReferenceListLoading(state, id),
    files: getJobClientReferencesById(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    jobClientReferenceListRequest,
    clientCommonReferenceDownloadRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobClientReferenceContainer)
