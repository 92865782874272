import { takeLatest, call, put } from 'redux-saga/effects';
import { camelizeKeys } from 'humps';
import { normalize } from 'normalizr';

import API from 'services/CoreApi';
import * as schemas from 'schemas/core';
import * as actions from 'actions/bootstrap';
import * as actionTypes from 'actionTypes/bootstrap';
import * as entityActions from 'actions/entities';
import { userSchema, groupSchema } from 'schemas/users';

// Manager =====================================================================
function* managerBootstrap(action) {
  try {
    const response = yield call(API.managerBootstrap);
    const {
      countries,
      services,
      applications,
      users,
      groups,
      categories,
      industries,
      fields
    } = response.data;

    const normalizedCountries = normalize(camelizeKeys(countries), [schemas.countrySchema]);
    const normalizedServices = normalize(camelizeKeys(services), [schemas.serviceSchema]);
    const normalizedApplications = normalize(camelizeKeys(applications), [schemas.applicationSchema]);
    const normalizedIndustries = normalize(camelizeKeys(industries), [schemas.parentIndustrySchema]);
    const normalizedFields = normalize(camelizeKeys(fields), [schemas.parentFieldSchema]);
    const normalizedCategories = normalize(camelizeKeys(categories), [schemas.parentCategorySchema]);
    const normalizedUsers = normalize(camelizeKeys(users), [userSchema]);
    const normalizedGroups = normalize(camelizeKeys(groups), [groupSchema]);

    yield put(entityActions.merge(normalizedCountries.entities))
    yield put(entityActions.merge(normalizedServices.entities));
    yield put(entityActions.merge(normalizedApplications.entities));
    yield put(entityActions.merge(normalizedUsers.entities));
    yield put(entityActions.merge(normalizedGroups.entities));
    yield put(entityActions.merge(normalizedIndustries.entities));
    yield put(entityActions.merge(normalizedFields.entities));
    yield put(entityActions.merge(normalizedCategories.entities));
    yield put(actions.managerBootstrapSuccess());

  } catch (error) {
    yield put(actions.managerBootstrapFailure(error));
  }
}

// Employee ====================================================================
function* employeeBootstrap(action) {
  try {
    const response = yield call(API.employeeBootstrap);
    const {
      countries,
      services,
      applications,
      users,
      groups,
      categories,
      industries,
      fields
    } = response.data;

    const normalizedCountries = normalize(camelizeKeys(countries), [schemas.countrySchema]);
    const normalizedServices = normalize(camelizeKeys(services), [schemas.serviceSchema]);
    const normalizedApplications = normalize(camelizeKeys(applications), [schemas.applicationSchema]);
    const normalizedIndustries = normalize(camelizeKeys(industries), [schemas.parentIndustrySchema]);
    const normalizedFields = normalize(camelizeKeys(fields), [schemas.parentFieldSchema]);
    const normalizedCategories = normalize(camelizeKeys(categories), [schemas.parentCategorySchema]);
    const normalizedUsers = normalize(camelizeKeys(users), [userSchema]);
    const normalizedGroups = normalize(camelizeKeys(groups), [groupSchema]);

    yield put(entityActions.merge(normalizedCountries.entities))
    yield put(entityActions.merge(normalizedServices.entities));
    yield put(entityActions.merge(normalizedApplications.entities));
    yield put(entityActions.merge(normalizedUsers.entities));
    yield put(entityActions.merge(normalizedGroups.entities));
    yield put(entityActions.merge(normalizedIndustries.entities));
    yield put(entityActions.merge(normalizedFields.entities));
    yield put(entityActions.merge(normalizedCategories.entities));

    yield put(actions.employeeBootstrapSuccess());

  } catch (error) {
    yield put(actions.employeeBootstrapFailure(error));
  }
}

function* watchManagerBootstrap() {
  yield takeLatest(actionTypes.MANAGER_BOOTSTRAP.REQUEST, managerBootstrap);
}

function* watchEmployeeBootstrap() {
  yield takeLatest(actionTypes.EMPLOYEE_BOOTSTRAP.REQUEST, employeeBootstrap);
}

export {
  watchManagerBootstrap,
  watchEmployeeBootstrap
}