import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { unsupportedOrderListRequest } from 'actions/quotes';
import { getUnsupportedOrders, getUnsupportedOrderListPageSize, getUnsupportedOrderListCurrentPage } from 'selectors/lists';
import { isUnsupportedOrderListLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import UnsupportedOrderList from './presenter';


class UnsupportedOrderListContainer extends PureRenderComponent {

  componentDidMount() {
    const params = { page: this.props.currentPage };
    this.props.unsupportedOrderListRequest(params);
  }

  handlePageSelect = (page) => {
    const params = { page }
    this.props.unsupportedOrderListRequest(params);
  }
  
  render() {
    console.log(this.props);
    return <UnsupportedOrderList
      {...this.props}
      handleDownloadDocument={this.handleDownloadDocument}
      handlePageSelect={this.handlePageSelect} />
  }
}


const mapStateToProps = (state) => {
  return {
    orders: getUnsupportedOrders(state),
    isLoading: isUnsupportedOrderListLoading(state),
    pageSize: getUnsupportedOrderListPageSize(state),
    currentPage: getUnsupportedOrderListCurrentPage(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    unsupportedOrderListRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsupportedOrderListContainer);
