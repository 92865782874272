import React from 'react';
import { NavItem } from 'react-bootstrap';


const UserDetailTab = (props) => {
  const {
    eventKey,
    activeKey,
    onSelect,
    title,
  } = props;

  return (
    <NavItem
      active={eventKey === activeKey}
      eventKey={eventKey}
      onSelect={onSelect}>
      {title}
    </NavItem>
  );
}

export default UserDetailTab;
