import React from 'react';
import { CUSTOMER_DETAIL } from 'misc/constants';
import { datetimeFormatter } from 'misc/cellFormatters';
import { BooleanFieldCellRenderer, LinkFieldCellRenderer } from 'misc/cellRenderers';
import PureRenderComponent from 'components/core/PureRenderComponent';
import ListTable from 'components/core/ListTable';


class CustomerListTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'Username',
          field: 'user.username',
          width: 190,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: CUSTOMER_DETAIL
          },
        },
        {
          headerName: 'Email',
          field: 'user.email',
          width: 250,
        },
        {
          headerName: 'AE',
          field: 'ae.username',
          width: 250,
        },
        {
          headerName: 'Client',
          field: 'client.name',
          width: 200,
        },
        {
          headerName: 'Country',
          field: 'country.name',
          width: 200,
        },
        {
          headerName: 'Language',
          field: 'language',
          width: 100,
        },
        {
          headerName: 'Active',
          field: 'user.isActive',
          suppressFilter: true,
          cellRendererFramework: BooleanFieldCellRenderer,
          width: 85,
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: datetimeFormatter,
          width: 150,
        },
        {
          headerName: 'Modified',
          field: 'modified',
          valueFormatter: datetimeFormatter,
          width: 150,
        },
      ]
    }
  }

  render() {
    return (
      <ListTable
        wrapperHeight="75vh"
        columnDefs={this.state.columnDefs}
        rowData={this.props.customers}
        deltaRowDataMode={true}
        getRowNodeId={data => data.id} />
    );
  }
}

export default CustomerListTable;
