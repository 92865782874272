import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { deliveryListRequest } from 'actions/jobs/managers';
import { getDeliveryJobs, getDeliveryJobCurrentPage, getDeliveryJobPageSize } from 'selectors/lists';
import { isDeliveryListLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobDeliveryList from './presenter';


class JobDeliveryListContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.deliveryListRequest({ page: 1 });
  }

  handlePageSelect = (page) => {
    const params = { page }
    this.props.deliveryListRequest(params);
  }

  render() {
    return (
      <JobDeliveryList
        {...this.props}
        handlePageSelect={this.handlePageSelect} />
    );
  }
}


const mapStateToProps = (state) => {
  return {
    jobs: getDeliveryJobs(state),
    isLoading: isDeliveryListLoading(state),
    pageSize: getDeliveryJobPageSize(state),
    currentPage: getDeliveryJobCurrentPage(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    deliveryListRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobDeliveryListContainer);
