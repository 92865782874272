import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { Content } from 'reactjs-admin-lte';
import EmployeeJobScheduleFilter from './EmployeeJobScheduleFilter';
import EmployeeJobScheduleTab from './EmployeeJobScheduleTab';
import EmployeeJobScheduleTabPane from './EmployeeJobScheduleTabPane';


const EmployeeJobSchedule = (props) => {

  const {
    myUserId,
    isDailyJobLoading,
    isFutureJobLoading,
    currentDate,
    handleDateChange,
    handlePrev,
    handleNext,
    currentTab,
    handleTabSelect,
    dailyJobs,
    futureJobs,
    handleTabOpen
  } = props;

  return (
    <Content.Body>
      <EmployeeJobScheduleFilter
        currentDate={currentDate}
        handleDateChange={handleDateChange}
        handlePrev={handlePrev}
        handleNext={handleNext} />
        <Tab.Container
          id="employee-job-daily-schedule"
          activeKey={currentTab}
          onSelect={handleTabSelect}>
          <div>
            <Nav bsStyle="tabs">
              <EmployeeJobScheduleTab
                eventKey="daily"
                title="Daily Jobs"
                isLoading={isDailyJobLoading}
                jobs={dailyJobs} />
              <EmployeeJobScheduleTab
                eventKey="future"
                title="Future Jobs"
                isLoading={isFutureJobLoading}
                jobs={futureJobs} />
            </Nav>
            <Tab.Content animation={false}>
              <EmployeeJobScheduleTabPane
                eventKey="daily"
                myUserId={myUserId}
                jobs={dailyJobs}
                isLoading={isDailyJobLoading}
                handleTabOpen={handleTabOpen} />
              <EmployeeJobScheduleTabPane
                eventKey="future"
                myUserId={myUserId}
                jobs={futureJobs}
                isLoading={isFutureJobLoading}
                handleTabOpen={handleTabOpen} />
            </Tab.Content>
          </div>
        </Tab.Container>
    </Content.Body>
  )
}


export default EmployeeJobSchedule;