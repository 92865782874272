import React from 'react';
import { Content, Box } from 'reactjs-admin-lte';
import { Button } from 'react-bootstrap';
import Pagination from 'components/core/Pagination';
import JobRnQListTable from './JobRnQListTable';


const JobRnQList = (props) => {

  const {
    jobs,
    isLoading,
    selected,
    currentPage,
    pageSize,
    handlePageSelect,
    handleTabOpen,
    handleSelect,
    handleBulkVerify,
  } = props;
  
  return (
    <Content.Body>
      <div className="mb-10">
        <Button
          bsStyle="primary"
          bsSize="sm"
          onClick={handleBulkVerify}
          disabled={
            selected === undefined ||
            selected.length === 0
          }>
          Verify
        </Button>
        </div>
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body
          noPadding={true}>
          <JobRnQListTable
            jobs={jobs}
            selected={selected}
            handleSelect={handleSelect}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
        <Box.Footer>
          <Pagination
            currentPage={currentPage}
            totalPages={pageSize}
            onChange={handlePageSelect} />
        </Box.Footer>
      </Box>
    </Content.Body>
  );
}

export default JobRnQList;
