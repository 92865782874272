import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { retrieveRequest } from 'actions/clients';
import { getClientById } from 'selectors/clients';
import PureRenderComponent from 'components/core/PureRenderComponent';
import ClientDetail from './presenter';


class ClientDetailContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.retrieveRequest(this.props.id);
  }
  
  render() {
    return (
      <ClientDetail
        {...this.props} />
    )
  }
}

const mapStateToProps = (state, props) => {
  const id = props.params.id;
  return {
    id,
    client: getClientById(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    retrieveRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientDetailContainer);
