import React from 'react';
import { getFieldTotalCount, deadlineComparator, getRowHeight } from 'misc/utils';
import {
  ClientFieldCellRenderer,
  LinkedAssignmentFieldCellRenderer,
  EmployeeScheduleStatusCellRenderer
} from 'misc/cellRenderers';
import * as constants from 'misc/constants';
import { EMPLOYEE_STATUS_MAP } from 'misc/config';
import ListTable from 'components/core/ListTable';
import PureRenderComponent from 'components/core/PureRenderComponent';


class EmployeeJobScheduleTable extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = {
      targetSum: 0,
      additionalSum: 0,
      columnDefs: [
        {
          headerName: 'No.',
          field: 'number',
          pinned: 'left',
          cellClass: 'ag-jobNumber',
          width: 140,
        },
        {
          headerName: 'Client',
          field: 'client.name',
          width: 150,
          cellRendererFramework: ClientFieldCellRenderer,
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data, nodeB.data)
          ),
        },
        {
          headerName: 'Subject',
          field: 'subject',
          width: 250,
        },
        {
          headerName: 'Service',
          field: 'service.name',
          width: 80,
        },
        {
          headerName: 'Lv',
          field: 'level',
          width: 40,
        },
        {
          headerName: 'ToTra',
          field: 'totra.assignee.name',
          width: 85,
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.totra, nodeB.data.totra)
          ),
          cellRendererFramework: LinkedAssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'totra',
            tab: constants.TOTRA_JOB_DETAIL,
            sentStatus: EMPLOYEE_STATUS_MAP.totra.sent,
            nowStatus: EMPLOYEE_STATUS_MAP.totra.now,
            userId: this.props.myUserId,
            handleTabOpen:this.props.handleTabOpen
          },
        },
        {
          headerName: 'Tra',
          field: 'translator.assignee.name',
          width: 85,
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.translator, nodeB.data.translator)
          ),
          cellRendererFramework: LinkedAssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'translator',
            tab: constants.TRANSLATOR_JOB_DETAIL,
            sentStatus: EMPLOYEE_STATUS_MAP.translator.sent,
            nowStatus: EMPLOYEE_STATUS_MAP.translator.now,
            userId: this.props.myUserId,
            handleTabOpen:this.props.handleTabOpen
          },
        },
        {
          headerName: 'Edi',
          field: 'editor.assignee.name',
          width: 85,
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.editor, nodeB.data.editor)
          ),
          cellRendererFramework: LinkedAssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'editor',
            tab: constants.EDITOR_JOB_DETAIL,
            sentStatus: EMPLOYEE_STATUS_MAP.editor.sent,
            nowStatus: EMPLOYEE_STATUS_MAP.editor.now,
            userId: this.props.myUserId,
            handleTabOpen:this.props.handleTabOpen
          },
        },
        {
          headerName: 'Che',
          field: 'checker.assignee.name',
          width: 85,
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.checker, nodeB.data.checker)
          ),
          cellRendererFramework: LinkedAssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'checker',
            tab: constants.CHECKER_JOB_DETAIL,
            sentStatus: EMPLOYEE_STATUS_MAP.checker.sent,
            nowStatus: EMPLOYEE_STATUS_MAP.checker.now,
            userId: this.props.myUserId,
            handleTabOpen:this.props.handleTabOpen
          },
        },
        {
          headerName: 'FE1',
          field: 'finalEditor.assignee.name',
          width: 85,
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.finalEditor, nodeB.data.finalEditor)
          ),
          cellRendererFramework: LinkedAssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'finalEditor',
            tab: constants.FINAL_EDITOR_JOB_DETAIL,
            sentStatus: EMPLOYEE_STATUS_MAP.finalEditor.sent,
            nowStatus: EMPLOYEE_STATUS_MAP.finalEditor.now,
            userId: this.props.myUserId,
            handleTabOpen:this.props.handleTabOpen
          },
        },
        {
          headerName: 'FE2',
          field: 'secondFinalEditor.assignee.name',
          width: 85,
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.secondFinalEditor, nodeB.data.secondFinalEditor)
          ),
          cellRendererFramework: LinkedAssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'secondFinalEditor',
            tab: constants.SECOND_FINAL_EDITOR_JOB_DETAIL,
            sentStatus: EMPLOYEE_STATUS_MAP.secondFinalEditor.sent,
            nowStatus: EMPLOYEE_STATUS_MAP.secondFinalEditor.now,
            userId: this.props.myUserId,
            handleTabOpen:this.props.handleTabOpen
          },
        },
        {
          headerName: 'DTP1',
          field: 'dtp.assignee.name',
          width: 85,
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.dtp, nodeB.data.dtp)
          ),
          cellRendererFramework: LinkedAssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'dtp',
            tab: constants.DTP_JOB_DETAIL,
            sentStatus: EMPLOYEE_STATUS_MAP.dtp.sent,
            nowStatus: EMPLOYEE_STATUS_MAP.dtp.now,
            userId: this.props.myUserId,
            handleTabOpen:this.props.handleTabOpen
          },
        },
        {
          headerName: 'DTP2',
          field: 'secondDtp.assignee.name',
          width: 85,
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.secondDtp, nodeB.data.secondDtp)
          ),
          cellRendererFramework: LinkedAssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'secondDtp',
            tab: constants.SECOND_DTP_JOB_DETAIL,
            sentStatus: EMPLOYEE_STATUS_MAP.secondDtp.sent,
            nowStatus: EMPLOYEE_STATUS_MAP.secondDtp.now,
            userId: this.props.myUserId,
            handleTabOpen:this.props.handleTabOpen
          },
        },
        {
          headerName: 'Target',
          field: 'targetCount',
          filter: 'agNumberColumnFilter',
          width: 85,
        },
        {
          headerName: 'Add',
          field: 'additionalCount',
          filter: 'agNumberColumnFilter',
          width: 75,
        },
        {
          headerName: 'Coordinator',
          field: 'coordinator.name',
          width: 120,
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 140,
          pinned: 'right',
          cellClass: 'statusRow',
          cellRendererFramework: EmployeeScheduleStatusCellRenderer,
          cellRendererParams: {
            userId: this.props.myUserId,
          },
        },
      ]
    }
  }

  componentDidMount() {
    this.setState({
      targetSum: getFieldTotalCount(this.props.jobs, 'targetCount'),
      additionalSum: getFieldTotalCount(this.props.jobs, 'additionalCount')
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.jobs !== this.props.jobs) {
      this.setState({
        targetSum: getFieldTotalCount(this.props.jobs, 'targetCount'),
        additionalSum: getFieldTotalCount(this.props.jobs, 'additionalCount'),
      })
    }
  }

  getPinnedBottomRowData = () => ([{
    'targetCount': this.state.targetSum,
    'additionalCount': this.state.additionalSum,
  }])

  handleFilterChanged = (event) => {
    let targetSum = 0;
    let additionalSum = 0;
    event.api.forEachNodeAfterFilter(node => {
      targetSum += node.data.targetCount;
      additionalSum += node.data.additionalCount;
    });
    this.setState({ targetSum, additionalSum });
  }

  render() {
    return (
      <ListTable
        wrapperHeight='75vh'
        rowClass='ag-double-row'
        getRowHeight={getRowHeight}
        columnDefs={this.state.columnDefs}
        rowData={this.props.jobs}
        onFilterChanged={this.handleFilterChanged}
        pinnedBottomRowData={this.getPinnedBottomRowData()} />
    )
  }
}

export default EmployeeJobScheduleTable;
