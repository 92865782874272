import React from 'react'
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Modal, Table, Alert } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import { JOB_PROCESS_MODAL } from 'misc/constants';
import { processRequest } from 'actions/jobs/managers';
import { isJobProcessModalLoading } from 'selectors/loading';
import DateFormatter from 'components/core/DateFormatter'
import Spinner from 'components/core/Spinner';
import PureRenderComponent from 'components/core/PureRenderComponent';


class JobProcessModal extends PureRenderComponent {
  
  handleProcess = (jobId, status, assignmentId, isUrgent) => {
    this.props.processRequest(jobId, {
      status,
      isUrgent,
      assignment: assignmentId
    });
  }

  render() {
    
    const {
      show,
      handleHide,
      status,
      showAssignment,
      assigneeName,
      assigneeEmail,
      deadline,
      isStrictDeadline,
      sourceCount,
      targetCount,
      additionalCount,
      jobId,
      assignmentId,
      isLoading,
    } = this.props;
    
    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Process job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert bsStyle="warning" className="mb-0">
            Going to change Job Status to: <strong>{status}</strong>
          </Alert>
          { showAssignment &&
            <Table striped bordered className="mt-20">
              <tbody>
                <tr><th>Username</th><td>{assigneeName}</td></tr>
                <tr><th>Email</th><td>{assigneeEmail}</td></tr>
                <tr>
                  <th>Deadline</th>
                  <td>
                    <DateFormatter
                      datetime={deadline}
                      isStrict={isStrictDeadline} />
                  </td>
                </tr>
                <tr><th>Source</th><td>{sourceCount}</td></tr>
                <tr><th>Target</th><td>{targetCount}</td></tr>
                <tr><th>Additional</th><td>{additionalCount}</td></tr>
              </tbody>
            </Table>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pull-left"
            bsSize="sm"
            type="button"
            onClick={handleHide}>
            <FontAwesome name="close" className="mr-5" />Close
          </Button>
          { showAssignment &&
            <Button
              bsStyle="warning"
              bsSize="sm"
              type="submit"
              onClick={() => this.handleProcess(jobId, status, assignmentId, true)}>
              <FontAwesome name="bell" className="mr-5" />Urgent
            </Button>
          }
          <Button
            bsStyle="primary"
            bsSize="sm"
            type="submit"
            onClick={() => this.handleProcess(jobId, status, assignmentId, false)}>
            <FontAwesome name="paper-plane-o" className="mr-5" />Submit
          </Button>
        </Modal.Footer>
        <Spinner isLoading={isLoading} />
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: isJobProcessModalLoading(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    processRequest
  }, disptach)
}

JobProcessModal = connectModal({ name: JOB_PROCESS_MODAL })(JobProcessModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobProcessModal)

