import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { retrieveRequest } from 'actions/users';
import {
  getUserValueById,
  isEmployeeUser,
  isSkillRequiredUser,
  hasTranslatorRole,
  hasEditorRole,
  hasCheckerRole,
  hasFinalEditorRole,
  hasSecondFinalEditorRole
} from 'selectors/users';
import { isUserDetailLoading } from 'selectors/loading';
import { selectNestedTab } from 'actions/tabs';
import { getUserDetailCurrentTab } from 'selectors/tabs';
import { USER_DETAIL } from 'misc/constants';
import PureRenderComponent from 'components/core/PureRenderComponent';
import UserDetail from './presenter';


class UserDetailContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.retrieveRequest(this.props.id);
  }

  handleTabSelect = (eventKey) => {
    this.props.selectNestedTab(USER_DETAIL, this.props.id, eventKey);
  }

  render() {
    return (
      <UserDetail
        {...this.props}
        handleTabSelect={this.handleTabSelect} />
    );
  }
}


const mapStateToProps = (state, props) => {
  const id = props.params.id;
  return {
    id,
    user: getUserValueById(state, id),
    isLoading: isUserDetailLoading(state, id),
    currentTab: getUserDetailCurrentTab(state, id),
    isEmployeeUser: isEmployeeUser(state, id),
    isSkillRequiredUser: isSkillRequiredUser(state, id),
    hasTranslatorRole: hasTranslatorRole(state, id),
    hasEditorRole: hasEditorRole(state, id),
    hasCheckerRole: hasCheckerRole(state, id),
    hasFinalEditorRole: hasFinalEditorRole(state, id),
    hasSecondFinalEditorRole: hasSecondFinalEditorRole(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    retrieveRequest,
    selectNestedTab
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailContainer);
