import React from 'react';
import { Content, Box } from 'reactjs-admin-lte';
import PaymentSearchForm from './PaymentSearchForm';
import PaymentSearchTable from './PaymentSearchTable';

const PaymentSearch = (props) => {
  
  const { 
    form,
    isLoading,
    initialValues,
    handleSearch,
    handleReset,
    employeeOptions,
    roleOptions,
    payments,
    handleCheck,
    handleTabOpen
  } = props;
  
  return (
    <Content.Body>
      <PaymentSearchForm
        form={form}
        initialValues={initialValues}
        handleSearch={handleSearch}
        handleReset={handleReset}
        roleOptions={roleOptions}
        employeeOptions={employeeOptions} />
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body noPadding={true}>
          <PaymentSearchTable
            payments={payments}
            handleCheck={handleCheck}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
      </Box>
    </Content.Body>
  );
}

export default PaymentSearch;
