import React from 'react';
import { Box } from 'reactjs-admin-lte';
import LabelGroup from 'components/core/LabelGroup';
import UserAboutItem from './UserAboutItem';


const UserAbout = (props) => {

  const {
    isLoading,
    name,
    email,
    groups
  } = props;

  return (
    <Box
      className="box-widget"
      loading={isLoading}>
      <Box.Header border={true}>
        <Box.Title>About</Box.Title>
      </Box.Header>
      <Box.Body>
        <UserAboutItem
          icon="tag"
          title="Short Name">
          <p className="text-muted">{name}</p>
        </UserAboutItem>
        <hr className="mt-10 mb-10" />
        <UserAboutItem
          icon="envelope"
          title="Email">
          <p className="text-muted">{email}</p>
        </UserAboutItem>
        <hr className="mt-10 mb-10" />
        <UserAboutItem
          icon="group"
          title="Group">
          <LabelGroup items={groups} />
        </UserAboutItem>
      </Box.Body>
    </Box>
  );
}

export default UserAbout;
