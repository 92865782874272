import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {hide, show} from 'redux-modal';
import {reset, stopSubmit} from 'redux-form';
import PureRenderComponent from 'components/core/PureRenderComponent';
import GlossaryApi from "services/GlossaryApi";
import {
    updateRequest,
    retrieveRequest,
    exportExcelRequest
} from "actions/glossaries";
import {
    GLOSSARY_DETAIL_SETTINGS_MODAL,
    GLOSSARY_DETAIL_IMPORT_MODAL,
    GLOSSARY_DETAIL_IMPORT_FORM
} from "misc/constants";
import {GlossaryDetail} from "components/mht/GlossaryDetail/presenter";
import {TaPane} from "components/taUi/taPane/taPane";
import {
    getCurrentGlossaryDetail
} from "selectors/lists";
import {getGlossaryColumns, getGlossaryUpdated} from "../../../selectors/glossaryUi";
import {setLanguageColumns,resetColumn} from "../../../actions/glossaryUi";

class GlossaryDetailContainer extends PureRenderComponent {

    static defaultProps = {
        glossary: null
    };

    constructor() {
        super();
        this.searchDebounce = null;
        this.defaultFilter = () => {
            return {
                pendingOnly: false
            };
        }
        this.defaultSearch = () => {
            return {
                string: '',
                isValid: false
            };
        }
        this.state = {
            updated: 0,
            scrollToBottom: 0,

            items: [],
            isLoading: true,
            isSaving: false,

            page: 1,
            itemsPerPage: 50,
            totalItemCount: 0,
            isFiltered: false,
            search: this.defaultSearch(),
            filter: this.defaultFilter()
        };
    }

    componentDidMount() {
        this.props.retrieveRequest(this.props.params.id);
        // todo: timeout is a hack...
        this.loadTimeout = setTimeout(this.loadItems, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.loadTimeout)
        this.props.resetColumn({ glossaryKey: this.props.params.id });
    }

    loadItems = (page, scrollToBottom) => {
        this.setState({
            isLoading: true
        }, () => {

            let filter = {}
            if (this.state.filter) {
                Object.keys(this.state.filter).forEach(key => {
                    if (this.state.filter[key]) {
                        filter[key] = this.state.filter[key];
                    }
                })
            }

            if (this.state.search.string) {
                filter.string = this.state.search.string;
            }

            GlossaryApi
                .listItems(this.props.params.id, page || 1, filter)
                .then(response => {

                    let newState = {
                        updated: Date.now(),
                        items: response.data.results,
                        totalItemCount: response.data.count,
                        isLoading: false,
                        isFiltered: !!(filter && Object.keys(filter).length)
                    };

                    const totalPages = this.calcTotalPageCount(newState.totalItemCount, this.state.itemsPerPage);
                    let newPage = (page) ? page : this.state.page;

                    if (newPage > totalPages) {
                        newPage = totalPages;
                    }
                    newState.page = newPage;

                    if (scrollToBottom) {
                        newState.scrollToBottom = Date.now();
                    }

                    this.setState(newState);

                })
                .catch(error => {
                    console.error('loadList - error', error);
                });
        });
    };

    handleImport = (values) => {
        const {id} = this.props.glossary;
        GlossaryApi.importItems(id, values)
            .then(response => {
                if (response.data.saved) {
                    this.loadItems();
                }
                this.props.hide(GLOSSARY_DETAIL_IMPORT_MODAL);
                this.props.reset(GLOSSARY_DETAIL_IMPORT_FORM);
            })
            .catch(error => {
                if (error.response.status === 400) {
                    this.props.stopSubmit(
                        GLOSSARY_DETAIL_IMPORT_FORM,
                        {_error: error.response.data.detail}
                    );
                }
            });
    };

    handleOpenImportModal = () => {
        this.props.show(GLOSSARY_DETAIL_IMPORT_MODAL, {
            onSubmit: this.handleImport
        });
    };

    handleSetting = (values) => {
        const {id} = this.props.glossary;
        this.props.updateRequest({
            id,
            data: values
        });
    };

    handleOpenSettingModal = () => {
        const {glossary} = this.props;
        this.props.show(GLOSSARY_DETAIL_SETTINGS_MODAL, {
            onSubmit: this.handleSetting,
            listId: glossary.id,
            initialValues: glossary
        });
    };

    handleExport = () => {
        const {
            glossary,
            glossarySettingsColumns
        } = this.props;

        const languagesToExport = (Object.keys(glossarySettingsColumns)).filter(key => {
            return glossarySettingsColumns[key].visible;
        }).sort((a, b) => (
            glossarySettingsColumns[a].order - glossarySettingsColumns[b].order
        ));

        this.props.exportExcelRequest(
            glossary.id,
            glossary.name,
            languagesToExport
        );
    };

    onSave = (mutations, languages, forceMerge) => {

        const listId = this.props.params.id;
        this.setState({
            isSaving: true,
            errors: {}
        });

        let errors = {};

        let addItems = [];
        Object.keys(mutations.added).forEach((key) => {
            let value = {
                glossary: listId
            };

            languages.forEach((language) => {
                value[language.key] = mutations.data[key][language.key];
            });

            addItems.push(GlossaryApi.addItem(value).catch(error => {
                if (
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.non_field_errors &&
                    error.response.data.non_field_errors.indexOf("Sorry, that item already exists.") >= 0
                ) {
                    errors.hasDuplicate = true;
                    if (typeof errors.items === 'undefined') {
                        errors.items = {};
                    }
                    if (typeof errors.duplicate === 'undefined') {
                        errors.duplicate = {};
                    }
                    errors.items[key] = value;
                    errors.duplicate[key] = true;
                }
            }));

        });

        Promise
            .all(addItems)
            .then(response => {
                const editedKeys = Object.keys(mutations.edited);
                let updatePromise;
                if (
                    !Object.keys(errors).length &&
                    editedKeys.length
                ) {
                    let updateItems = [];
                    editedKeys.forEach((key) => {
                        if (!mutations.added[key]) {
                            let value = {
                                glossary: listId,
                                id: key
                            };
                            languages.forEach((language) => {
                                value[language.key] = mutations.data[key][language.key];
                            });
                            updateItems.push(value);
                        }
                    });
                    updatePromise = new Promise((resolve, reject) => {
                        GlossaryApi
                            .batchSaveItems(updateItems, forceMerge)
                            .then(updateResponse => {
                                const updateResponseErrors = updateResponse.data.errors;
                                if (
                                    updateResponseErrors.length
                                ) {
                                    errors.hasMerge = true;
                                    if (typeof errors.items === 'undefined') {
                                        errors.items = {};
                                    }
                                    if (typeof errors.merge === 'undefined') {
                                        errors.merge = {};
                                    }
                                    updateResponseErrors.forEach(item => {
                                        errors.items[item.id] = item;
                                        errors.merge[item.id] = true;
                                    });
                                    reject();
                                } else {
                                    resolve();
                                }
                            }).catch(reject)
                    });
                } else {
                    updatePromise = new Promise((resolve, reject) => resolve());
                }
                return updatePromise;

            })
            .then(response => {
                let removePromise;
                let removeItems = Object.keys(mutations.removed);
                if (
                    !Object.keys(errors).length &&
                    removeItems.length > 0
                ) {
                    removePromise = GlossaryApi.batchDeleteItems(removeItems);
                } else {
                    removePromise = new Promise((resolve, reject) => resolve());
                }
                return removePromise;
            })
            .catch((error) => {
                errors.unknown = error;
            })
            .finally(() => {
                if (Object.keys(errors).length) {
                    this.setState({
                        isSaving: false,
                        errors: errors
                    });
                } else {
                    this.setState({
                        isSaving: false
                    }, () => this.loadItems());
                }
            });

    };

    onDismissError = (errorKey) => {

        let errors = this.state.errors;

        if (errorKey === 'merge') {
            errors.merge = {};
            errors.hasMerge = false;
        } else if (errorKey === 'duplicate') {
            errors.duplicate = {};
            errors.hasDuplicate = false;
        }

        this.setState({
            updated: Date.now,
            errors: errors
        });

    };

    calcTotalPageCount = (totalItemCount, itemsPerPage) => {
        return Math.ceil(totalItemCount / itemsPerPage);
    }

    onSearchInput = (value) => {

        let newSearch = this.defaultSearch();
        newSearch.string = encodeURIComponent(value);
        newSearch.isValid = (value.length > 1);

        this.setState({
            search: newSearch
        }, () => {
            if (this.searchDebounce) {
                clearTimeout(this.searchDebounce);
            }
            this.searchDebounce = setTimeout(this.loadItems, (newSearch.isValid) ? 500 : 0);
        });

    }

    onFilterInput = (key, value) => {

        let newFilter = this.defaultFilter();
        newFilter[key] = value;

        this.setState({
            filter: newFilter,
        }, () => {
            this.loadItems()
        });

    }

    goToPage = (page, scrollToBottom) => {
        this.loadItems(page, scrollToBottom)
    }

    onToggleLanguageColumn = (columns) => {
        const props = this.props;
        props.setLanguageColumns({
            glossaryKey: props.params.id,
            columns: columns
        })
    }

    render() {
        const state = this.state;
        const props = this.props;
        const glossary = (this.props.glossary) ? this.props.glossary : {};
        const totalPageCount = this.calcTotalPageCount(state.totalItemCount, state.itemsPerPage)
        const isLastPage = totalPageCount === state.page;
        return (
            <TaPane size={'page'}>
                <GlossaryDetail
                    updated={state.updated + props.glossarySettingsUpdated}
                    list={glossary}
                    columns={props.glossarySettingsColumns}
                    items={state.items}
                    errors={state.errors}
                    isLoading={state.isLoading}
                    isSaving={state.isSaving}
                    handleExport={this.handleExport}
                    handleOpenSettingModal={this.handleOpenSettingModal}
                    handleOpenImportModal={this.handleOpenImportModal}
                    onSave={this.onSave}
                    onDismissError={this.onDismissError}
                    search={state.search}
                    isFiltered={state.isFiltered}
                    page={state.page}
                    itemsPerPage={state.itemsPerPage}
                    totalPageCount={totalPageCount}
                    totalItemCount={state.totalItemCount}
                    isLastPage={isLastPage}
                    scrollToBottom={state.scrollToBottom}
                    onSearchInput={this.onSearchInput}
                    onFilterInput={this.onFilterInput}
                    onToggleLanguageColumn={this.onToggleLanguageColumn}
                    goToPage={this.goToPage}
                />
            </TaPane>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        glossary: getCurrentGlossaryDetail(state),
        glossarySettingsColumns: getGlossaryColumns(state, ownProps.params.id),
        glossarySettingsUpdated: getGlossaryUpdated(state, ownProps.params.id)
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        show,
        hide,
        reset,
        stopSubmit,
        retrieveRequest,
        updateRequest,
        exportExcelRequest,
        setLanguageColumns,
        resetColumn
    }, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GlossaryDetailContainer);