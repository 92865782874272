import { schema } from 'normalizr';
import { industrySchema } from './core';
import { userSchema } from './users';

export const clientSchema = new schema.Entity('clients', {
  industry: industrySchema
});

export const ngUserSchema = new schema.Entity('ngUsers', {
  client: clientSchema,
  user: userSchema
});

export const preferableUserSchema = new schema.Entity('preferableUsers', {
  client: clientSchema,
  user: userSchema
});


export const websiteSchema = new schema.Entity('websites', {
  client: clientSchema,
});