import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, getFormValues } from 'redux-form';
import { JOB_SPLIT_ISSUE_FORM } from 'misc/constants';
import { parseAssignmentValues } from 'misc/utils';
import { retrieveRequest, splitIssueRequest } from 'actions/jobs/managers';
import { getJobSplitIssueFormInitialValuesById } from 'selectors/forms';
import { isJobSplitIssueLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobSplitIssue from './presenter';


class JobSplitIssueContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.retrieveRequest(this.props.id);
  }

  handleIssue = (values) => {
    const formData = parseAssignmentValues(values);
    this.props.splitIssueRequest(this.props.form, this.props.id, formData);
  }

  render() {
    return (
      <JobSplitIssue
        {...this.props}
        handleIssue={this.handleIssue} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const { id } = props.params;
  const form = `${JOB_SPLIT_ISSUE_FORM}/${id}`
  const formSelector = formValueSelector(form);
  const client = formSelector(state, 'client');
  return {
    id,
    form,
    client,
    formValues: getFormValues(form)(state),
    isLoading: isJobSplitIssueLoading(state, id),
    initialValues: getJobSplitIssueFormInitialValuesById(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    retrieveRequest,
    splitIssueRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobSplitIssueContainer);