import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Content, Box } from 'reactjs-admin-lte';
import { Button } from 'react-bootstrap';
import Pagination from 'components/core/Pagination';
import WordListFilter from './WordListFilter';
import WordListTable from './WordListTable';

const WordList = (props) => {

  const {
    wordlists,
    pageSize,
    currentPage,
    isLoading,
    client,
    keyword,
    selected,
    handleSelect,
    handleTabOpen,
    handleSubmit,
    handlePageSelect,
    handleKeywordChange,
    handleClientChange,
    handleDelete,
    showAddModal
  } = props;

  return (
    <Content.Body>
      <WordListFilter
        client={client}
        keyword={keyword}
        handleSubmit={handleSubmit}
        handleKeywordChange={handleKeywordChange}
        handleClientChange={handleClientChange} />
      <div className="clearfix">
        <Button
          bsStyle="danger"
          bsSize="sm"
          type="button"
          className="mb-10 pull-right"
          disabled={selected.length === 0}
          onClick={handleDelete}>
          <FontAwesome name="times-circle" className="mr-5" />Delete
        </Button>
      </div>
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body noPadding={true}>
          <WordListTable
            wordlists={wordlists}
            handleSelect={handleSelect}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
        <Box.Footer>
          <Button
            type="button"
            bsStyle="primary"
            onClick={showAddModal}>
            <FontAwesome name="plus" className="mr-5" />Add New WordList
          </Button>
          <Pagination
            currentPage={currentPage}
            totalPages={pageSize}
            onChange={handlePageSelect} />
        </Box.Footer>
      </Box>
    </Content.Body>
  )
}

export default WordList;