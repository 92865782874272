import React from 'react';
import Logo from 'assets/images/logo.svg';
import { MainHeader } from 'reactjs-admin-lte';
import HeaderCustomMenu from './HeaderCustomMenu';
import HeaderUserMenu from './HeaderUserMenu';
import HeaderRefreshMenu from './HeaderRefreshMenu';
import HeaderChangeViewMenu from './HeaderChangeViewMenu';


const Header = (props) => {

  const {
    me,
    logout,
    handleTabOpen,
  } = props;

  return (
    <MainHeader>
      <MainHeader.Logo>
        <MainHeader.Logo.Mini>
          <img src={Logo} width="30" height="30" alt="logo" />
        </MainHeader.Logo.Mini>
        <MainHeader.Logo.Large>
          <img src={Logo} width="30" height="30" alt="logo" />
        </MainHeader.Logo.Large>
      </MainHeader.Logo>
      <MainHeader.Navbar>
        <MainHeader.SidebarToggle />
        <HeaderCustomMenu>
          <HeaderRefreshMenu />
          <HeaderUserMenu
            me={me}
            logout={logout}
            handleTabOpen={handleTabOpen} />
          <HeaderChangeViewMenu />
        </HeaderCustomMenu>
      </MainHeader.Navbar>
    </MainHeader>
  )
}

export default Header;
