import { createAsyncActionTypes } from 'misc/utils';

const PREFIX = 'memories';

export const SEARCH = createAsyncActionTypes(`${PREFIX}/SEARCH`);
export const COUNT = createAsyncActionTypes(`${PREFIX}/COUNT`);
export const SEARCH_LIST = createAsyncActionTypes(`${PREFIX}/SEARCH_LIST`);
export const SEARCH_CREATE = createAsyncActionTypes(`${PREFIX}/SEARCH_CREATE`);
export const SEARCH_UPDATE = createAsyncActionTypes(`${PREFIX}/SEARCH_UPDATE`);
export const SEARCH_CLEAR = createAsyncActionTypes(`${PREFIX}/SEARCH_CLEAR`);
export const SEARCH_DELETE = createAsyncActionTypes(`${PREFIX}/SEARCH_DELETE`);
export const SEARCH_IMPORT_TMX = createAsyncActionTypes(`${PREFIX}/SEARCH_IMPORT_TMX`);

export const UPLOAD = createAsyncActionTypes(`${PREFIX}/UPLOAD`);
export const UPLOAD_PENALTY = createAsyncActionTypes(`${PREFIX}/UPLOAD_PENALTY`);

export const EXPORT_EXCEL = createAsyncActionTypes(`${PREFIX}/EXPORT_EXCEL`);
export const EXPORT_TMX = createAsyncActionTypes(`${PREFIX}/EXPORT_TMX`);

export const ITEM_UPDATE = createAsyncActionTypes(`${PREFIX}/ITEM_UPDATE`);

