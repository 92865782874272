import React from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { EMPLOYEE_STATUS_MAP } from 'misc/config';
import { secondDtpCurrentJobsRequest, secondDtpBulkFinishRequest } from 'actions/jobs/employees';
import { getSecondDtpCurrentJobs } from 'selectors/lists';
import { isSecondDtpCurrentJobsLoading } from 'selectors/loading';
import EmployeeCurrentJobsHOC from './EmployeeCurrentJobsHOC';
import PureRenderComponent from 'components/core/PureRenderComponent';
import EmployeeCurrentJobs from './presenter'


class SecondDtpCurrentJobs extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = { selected: [] };
  }

  handleSelect = (e) => {
    const { checked, value } = e.target;
    const newValue = [...this.state.selected];
    checked
      ? newValue.push(value)
      : newValue.splice(newValue.indexOf(value), 1)
    this.setState({ selected: newValue });
  }

  handleBulkFinish = () => {
    this.props.secondDtpBulkFinishRequest({ jobs: this.state.selected });
    this.setState({ selected: [] });
  }

  render() {
    return (
      <EmployeeCurrentJobs
        {...this.props}
        selected={this.state.selected}
        handleSelect={this.handleSelect}
        handleBulkFinish={this.handleBulkFinish} />
    );
  }
}

const mapStateToProps = (state) => {
  const roleKey = 'secondDtp';
  return {
    roleKey,
    allowBulkFinish: true,
    title: 'Second DTP',
    sentToStatus: EMPLOYEE_STATUS_MAP[roleKey].sent,
    jobs: getSecondDtpCurrentJobs(state),
    isLoading: isSecondDtpCurrentJobsLoading(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest: secondDtpCurrentJobsRequest,
    secondDtpBulkFinishRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeCurrentJobsHOC(SecondDtpCurrentJobs));
