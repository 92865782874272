import React from "react";
import * as PropTypes from 'prop-types';
import './style.css';

export const TaPane = (props) => {
  const {
    size,
    direction,
    scroll,
    flex,
    className,
    padding,
    ...attributes
  } = props;

  let classNames = ['ta-pane'];
  if (size && size !== 'auto') {
    classNames.push('ta-pane--' + size);
  }
  if (direction && direction !== 'column') {
    classNames.push('ta-pane--' + direction);
  }

  if (scroll === 'auto') {
    classNames.push('ta-pane--scroll');
  } else if (scroll && scroll !== 'hidden') {
    classNames.push('ta-pane--scroll-' + scroll);
  }

  if (className) {
    classNames.push(className);
  }
  if (padding === 'auto') {
    classNames.push('ta-pane--padding');
  } else if (padding) {
    classNames.push('ta-pane--padding-' + padding);
  }

  if (flex === 'auto') {
    classNames.push('ta-pane--flex');
  } else if (flex) {
    if (typeof attributes.style === 'undefined') {
      attributes.style = {};
    }
    attributes.style.flex = flex;
  }

  return (
    <div className={classNames.join(' ')} {...attributes}>
      {(padding === 'auto') ? (
        <div className="ta-pane__padding">{props.children}</div>
      ): props.children }
    </div>
  );
};
TaPane.propTypes = {
  size: PropTypes.oneOf(['page', 'full', 'auto']),
  flex: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column']),
  scroll: PropTypes.oneOf(['x', 'y', 'auto', 'hidden']),
  padding: PropTypes.string
};