import React from 'react';
import FontAwesome from 'react-fontawesome';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import moment from 'moment';
import classNames from 'classnames';
import DateFormatter from 'components/core/DateFormatter'
import UserAvatar from 'components/users/UserAvatar'
import JobAssignmentTable from './JobAssignmentTable';


const JobAssignment = (props) => {

  const {
    isLoading,
    executable,
    assignment,
    projects,
    uploadable,
    handleDownload,
    handleRemove,
    handleFinish,
    removable,
    showUploadModal
  } = props;

  const isCreated = assignment.status === 'Created';
  const isWorking = assignment.status === 'Working';
  const isFinished = assignment.status === 'Finished';
  const isExpired = (
    assignment.status !== 'Finished' &&
    moment().isAfter(assignment.deadline)
  )
  const cls = classNames({
    'box-widget': true,
    'box-created': isCreated,
    'box-working': isWorking,
    'box-finished': isFinished,
    'box-expired': isExpired
  })

  return (
    <Box
      loading={isLoading}
      className={cls}>
      <Box.Header>
        <Box.Title>{assignment.role.name}</Box.Title>
        <Box.Tools>
          { executable &&
            <DropdownButton
              bsStyle="box-tool"
              title={<FontAwesome name="wrench" />}
              noCaret
              id="dropdown-no-caret">
              { uploadable &&
                <MenuItem onClick={showUploadModal}>
                  Upload completed file
                </MenuItem>
              }
              <MenuItem
                disabled={isFinished}
                onClick={() => !isFinished && handleFinish(assignment)}>
                Finish assignment
              </MenuItem>
            </DropdownButton>
          }
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <div className="widget-user-2 widget-user-header clearfix">
        <div className="widget-user-image">
          <UserAvatar url={(assignment.assignee) ? assignment.assignee.avatar : ''} className="img-circle" />
        </div>
        <h3 className="widget-user-username">{(assignment.assignee) ? assignment.assignee.username : ''}</h3>
        <h5 className="widget-user-desc">
          <DateFormatter
            datetime={assignment.deadline}
            isStrict={assignment.strictDeadline} />
        </h5>
      </div>
      <Box.Footer className="no-padding">
        <JobAssignmentTable
          documents={projects}
          handleDownload={handleDownload}
          removable={removable}
          handleRemove={handleRemove} />
      </Box.Footer>
    </Box>
  );
}

export default JobAssignment;
