
import axios from 'axios';

const EmployeeApi = {
  // Job Schedule 
  employeeJobDailySchedule: (params) => {
    return axios.get(`jobs/employee/daily/`, { params });
  },
  employeeJobFutureSchedule: (params) => {
    return axios.get(`jobs/employee/future/`, { params });
  },
  
  // ToTra
  totraCurrentJobs: () => {
    return axios.get('jobs/to-tra/');
  },
  totraJobRetrieve: (id) => {
    return axios.get(`jobs/to-tra/${id}/`);
  },
  
  // Translator
  translatorCurrentJobs: () => {
    return axios.get('jobs/translator/');
  },
  translatorJobRetrieve: (id) => {
    return axios.get(`jobs/translator/${id}`);
  },

  // Editor
  editorCurrentJobs: () => {
    return axios.get('jobs/editor/');
  },
  editorJobRetrieve: (id) => {
    return axios.get(`jobs/editor/${id}`);
  },

  // Checker
  checkerCurrentJobs: () => {
    return axios.get('jobs/checker/');
  },
  checkerJobRetrieve: (id) => {
    return axios.get(`jobs/checker/${id}`);
  },

  // Final Editor
  finalEditorCurrentJobs: () => {
    return axios.get('jobs/final-editor/');
  },
  finalEditorJobRetrieve: (id) => {
    return axios.get(`jobs/final-editor/${id}`);
  },

  // Second Final Editor
  secondFinalEditorCurrentJobs: () => {
    return axios.get('jobs/second-final-editor/');
  },
  secondFinalEditorJobRetrieve: (id) => {
    return axios.get(`jobs/second-final-editor/${id}`);
  },

  // DTP
  dtpCurrentJobs: () => {
    return axios.get('jobs/dtp/');
  },
  dtpJobRetrieve: (id) => {
    return axios.get(`jobs/dtp/${id}`);
  },

  // Second DTP
  secondDtpCurrentJobs: () => {
    return axios.get('jobs/second-dtp/');
  },
  secondDtpJobRetrieve: (id) => {
    return axios.get(`jobs/second-dtp/${id}`);
  },

  // Actual Count
  reportCount: (id, values) => {
    return axios.patch(`jobs/translator/${id}/`, values);
  },

  // History
  history: (params) => {
    return axios.get(`jobs/history/`, { params });
  },

  // process 
  employeeJobStart: (id, values) => {
    return axios.put(`jobs/employee/${id}/start/`, values);
  },
  employeeJobFinish: (id, values) => {
    return axios.put(`jobs/employee/${id}/finish/`, values);
  },

  bulkFinish: (values) => {
    return axios.post(`jobs/bulk-finish/`, values);
  },

  // Print
  print: (id) => {
    let printWin = window.open('about:blank');
    return axios({
      url: `assignments/${id}/print/`,
      method: 'GET',
      reponseType: 'document',
    }).then(response => {
      printWin.document.open();
      printWin.document.write(response.data);
      printWin = null;
    }, (error) => {
      printWin.close();
      printWin = null;
    });
  },

  // CSV
  historyCsvExport: (params, onDownloadProgress) => {
    return axios({
      url: `jobs/history/export/`,
      method: 'GET',
      params,
      responseType: 'blob',
      onDownloadProgress
    });
  },

  allReferenceDownload: (id, onDownloadProgress) => {
    return axios({
      url: `jobs/${id}/documents/`,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress
    });
  },
}

export default EmployeeApi;