import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { DEFAULT_MONTH_FORMAT } from 'misc/config';
import { INVOICE_LIST } from 'misc/constants';
import { selectTab } from 'actions/tabs';
import  { listRequest, patchRequest } from 'actions/invoices';
import { setFilter } from 'actions/filters';
import { isInvoiceListLoading } from 'selectors/loading';
import { getInvoices } from 'selectors/lists';
import { getInvoiceListCurrentTab } from 'selectors/tabs';
import { getInvoiceListCurrentMonth } from 'selectors/filters';
import PureRenderComponent from 'components/core/PureRenderComponent';
import InvoiceList from './presenter';


class InvoiceListContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.listRequest(this.props.currentMonth);
  }
  
  handleTabSelect = (tab) => {
    this.props.selectTab(INVOICE_LIST, tab);
  }

  handlePrev = () => {
    const { currentMonth } = this.props;
    const prev = moment(currentMonth, DEFAULT_MONTH_FORMAT).subtract(1, 'months');
    this.handleMonthChange(prev)
  }
  
  handleNext = () => {
    const { currentMonth } = this.props;
    const next = moment(currentMonth, DEFAULT_MONTH_FORMAT).add(1, 'months');
    this.handleMonthChange(next)
  }

  handleMonthChange = (month) => {
    const currentMonth = moment(month).format(DEFAULT_MONTH_FORMAT);
    this.props.setFilter(INVOICE_LIST, 'currentMonth', currentMonth);
    this.props.listRequest(currentMonth);
  }

  handleCheck = (e) => {
    const { value, checked } = e.target;
    this.props.patchRequest(value, { checked });
  }
  
  render() {
    return (
      <InvoiceList
        {...this.props}
        handlePrev={this.handlePrev}
        handleNext={this.handleNext}
        handleTabSelect={this.handleTabSelect}
        handleCheck={this.handleCheck} />
    );
  }
}

const mapStateToProps = (state) => {
  
  const {
    lsInvoices,
    mdInvoices,
    irInvoices,
    trInvoices,
    ttInvoices,
    ntInvoices,
  } = getInvoices(state)
  
  return {
    isLoading: isInvoiceListLoading(state),
    lsInvoices,
    mdInvoices,
    irInvoices,
    trInvoices,
    ttInvoices,
    ntInvoices,
    currentTab: getInvoiceListCurrentTab(state),
    currentMonth: getInvoiceListCurrentMonth(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    selectTab,
    setFilter,
    listRequest,
    patchRequest
  }, disptach)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceListContainer);
