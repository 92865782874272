import { takeLatest, call, put, fork } from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { hide } from 'redux-modal';
import { normalize } from 'normalizr';
import API from 'services/jobs/JobApi';
import * as schemas from 'schemas/jobs';
import * as documentSchemas from 'schemas/documents';
import * as actionTypes from 'actionTypes/jobs/common';
import * as actions from 'actions/jobs/common';
import * as entityActions from 'actions/entities';
import * as listActions from 'actions/lists';
import * as constants from 'misc/constants';
import * as coreSagas from 'sagas/core';
import { setLoading } from 'actions/loading';



// Source File List ========================================================
function* jobSourcefileList(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.JOB_SOURCEFILE}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.sourcefileList, id);
    const normalized = normalize(camelizeKeys(response.data), [documentSchemas.sourcefileSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setNestedResult(constants.JOB_SOURCEFILE, id, normalized.result));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.jobSourcefileListFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Reference List ========================================================
function* jobReferenceList(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.JOB_REFERENCE}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.referenceList, id);
    const normalized = normalize(camelizeKeys(response.data), [documentSchemas.referenceSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setNestedResult(constants.JOB_REFERENCE, id, normalized.result));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.jobReferenceListFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Job Common Reference List ===================================================
function* jobCommonReferenceListRequest(action) {
  const { group } = action.payload;
  const loadingKey = `${constants.JOB_COMMON_REFERENCE}/${group}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.commonReferenceList, group);
    const normalized = normalize(camelizeKeys(response.data), [documentSchemas.jobCommonReferenceSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setNestedResult(constants.JOB_COMMON_REFERENCE, group, normalized.result));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.jobCommonReferenceListFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Job Client Reference List ===================================================
function* jobClientReferenceListRequest(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.JOB_CLIENT_REFERENCE}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.clientReferenceList, id);
    const normalized = normalize(camelizeKeys(response.data), [documentSchemas.clientReferenceSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setNestedResult(constants.JOB_CLIENT_REFERENCE, id, normalized.result));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.jobClientReferenceListFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Message List ================================================================
function* jobMessageList(action) {
  const { id } = action.payload;
  const loadKey = `${constants.JOB_MESSAGE_LIST}/${id}`;
  yield put(setLoading(loadKey, true));
  try {
    const response = yield call(API.messageList, id);
    const normalized = normalize(camelizeKeys(response.data), [schemas.messageSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setNestedResult(constants.JOB_MESSAGE_LIST, id, normalized.result));
    yield put(setLoading(loadKey, false));
  } catch (error) {
    yield put(actions.jobMessageListFailure(error));
    yield put(setLoading(loadKey, false));
  }
}

// Message Create ==============================================================
function* jobMessageCreate(action) {
  yield put(setLoading(constants.JOB_MESSAGE_MODAL, true));
  const { id, values } = action.payload;
  try {
    const response = yield call(API.messageCreate, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.messageSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addNestedItem(constants.JOB_MESSAGE_LIST, id, normalized.result));
    yield put(hide(constants.JOB_MESSAGE_MODAL));
    yield put(setLoading(constants.JOB_MESSAGE_MODAL, false));
  } catch (error) {
    yield put(actions.jobMessageCreateFailure(error));
    yield put(setLoading(constants.JOB_MESSAGE_MODAL, false));
  }
}

// Message Update ==============================================================
function* jobMessageUpdate(action) {
  yield put(setLoading(constants.JOB_MESSAGE_MODAL, true));
  const { id, messageId, values } = action.payload;
  try {
    const response = yield call(API.messageUpdate, id, messageId, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.messageSchema);
    const data = normalized.entities['messages'][messageId];
    yield put(entityActions.sync('messages', messageId, data));
    yield fork(coreSagas.successNotification, 'Message updated successfully.');
    yield put(hide(constants.JOB_MESSAGE_MODAL));
    yield put(setLoading(constants.JOB_MESSAGE_MODAL, false));
  } catch (error) {
    yield put(actions.jobMessageUpdateFailure(error));
    yield put(setLoading(constants.JOB_MESSAGE_MODAL, false));
  }
}

// Message Remove ==============================================================
function* jobMessageRemove(action) {
  const { id, message } = action.payload;
  const confirmed = yield call(coreSagas.confirm, 'Are you sure to delete message?');
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      yield call(API.messageRemove, id, message.id);
      yield put(listActions.removeNestedItem(constants.JOB_MESSAGE_LIST, id, message.id));
      yield put(entityActions.remove('messages', message.id));
      yield fork(coreSagas.successNotification, 'Message deleted successfully.');
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.jobMessageRemoveFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

function* watchJobSourcefileList() {
  yield takeLatest(actionTypes.JOB_SOURCEFILE_LIST.REQUEST, jobSourcefileList);
}
function* watchJobReferenceList() {
  yield takeLatest(actionTypes.JOB_REFERENCE_LIST.REQUEST, jobReferenceList);
}
function* watchJobCommonReferenceList() {
  yield takeLatest(actionTypes.JOB_COMMON_REFERENCE_LIST.REQUEST, jobCommonReferenceListRequest);
}
function* watchJobClientReferenceList() {
  yield takeLatest(actionTypes.JOB_CLIENT_REFERENCE_LIST.REQUEST, jobClientReferenceListRequest);
}


function* watchJobMessageList() {
  yield takeLatest(actionTypes.JOB_MESSAGE_LIST.REQUEST, jobMessageList);
}
function* watchJobMessageCreate() {
  yield takeLatest(actionTypes.JOB_MESSAGE_CREATE.REQUEST, jobMessageCreate);
}
function* watchJobMessageUpdate() {
  yield takeLatest(actionTypes.JOB_MESSAGE_UPDATE.REQUEST, jobMessageUpdate);
}
function* watchJobMessageRemove() {
  yield takeLatest(actionTypes.JOB_MESSAGE_REMOVE.REQUEST, jobMessageRemove);
}

export {
  watchJobSourcefileList,
  watchJobReferenceList,
  watchJobCommonReferenceList,
  watchJobClientReferenceList,
  
  watchJobMessageList,
  watchJobMessageCreate,
  watchJobMessageUpdate,
  watchJobMessageRemove
}