import React from 'react';
import {bindActionCreators} from 'redux';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {managerBootstrapRequest} from 'actions/bootstrap';
import {isManager} from 'selectors/auth';
import PureRenderComponent from 'components/core/PureRenderComponent';

class ManagerRoute extends PureRenderComponent {

  componentDidMount() {
    this.props.managerBootstrapRequest();
  }

  render() {
    return this.props.isManager
      ? <Route
        {...this.props}
        component={this.props.component}/>
      : <Redirect to="/employee"/>;
  }
}

const mapStateToProps = (state) => {
  return {
    isManager: isManager(state)
  };
};

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    managerBootstrapRequest
  }, disptach);
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagerRoute);
