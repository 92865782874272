import { takeLatest, call, put, fork } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { hide } from 'redux-modal';
import { reset, stopSubmit } from 'redux-form';
import API from 'services/AuthApi';
import { userSchema } from 'schemas/users';
import { setLoading } from 'actions/loading';
import { setViewAs } from 'actions/settings';
import * as constants from 'misc/constants';
import * as actions from 'actions/auth';
import * as actionTypes from 'actionTypes/auth';
import * as entityActions from 'actions/entities';
import { clearTabs } from 'actions/globalTabs';
import { successNotification } from 'sagas/core';


function* authorize(data) {
  const normalized = normalize(camelizeKeys(data.user), userSchema);
  yield put(entityActions.merge(normalized.entities));
  yield call(API.setAuthToken, data.token);
  yield put(actions.authorize(normalized.result));
}

function* login(action) {
  const { form, values } = action.payload;
  yield put(setLoading(constants.LOGIN, true));
  try {
    const response = yield call(API.login, decamelizeKeys(values));
    if (response.data.user.groups.length === 0) {
      throw new Error('Users without appropriate roles cannot log in.');
    }
    yield fork(authorize, response.data);
    yield put(setLoading(constants.LOGIN, false));
  } catch (error) {
    if (error.response && error.response.status === 400) {
      yield put(stopSubmit(form, { _error: 'Unable to log in with provided credentials.' }));
    } else {
      yield put(stopSubmit(form, { _error: error.message }));
    }
    yield put(setLoading(constants.LOGIN, false));
  }
}

function* refresh(action) {
  yield put(setLoading(constants.REFRESH, true));
  try {
    const token = yield call(API.getAuthToken);
    const response = yield call(API.refresh, token);
    yield fork(authorize, response.data);
    yield put(actions.refreshSuccess());
    yield put(setLoading(constants.REFRESH, false));  
  } catch (error) {
    yield call(API.removeAuthToken);
    yield put(actions.refreshFailure(error));
    yield put(setLoading(constants.REFRESH, false));
  }
}

function* passwordChange(action) {
  const { values } = action.payload;
  yield put(setLoading(constants.PASSWORD_CHANGE_MODAL, true));
  try {
    const response = yield call(API.passwordChange, decamelizeKeys(values));
    yield fork(authorize, response.data);
    yield put(hide(constants.PASSWORD_CHANGE_MODAL));
    yield put(reset(constants.PASSWORD_CHANGE_FORM));
    yield fork(successNotification, 'Password changed successfuly.');
    yield put(setLoading(constants.PASSWORD_CHANGE_MODAL, false));
  } catch (error) {
    if (error.response.data.current_password) {
      const message = error.response.data.current_password;
      yield put(stopSubmit(constants.PASSWORD_CHANGE_FORM, { currentPassword: message }));
    }
    yield put(actions.passwordChangeFailure(error));
    yield put(setLoading(constants.PASSWORD_CHANGE_MODAL, false));
  }
}

function* logout(action) {
  yield call(API.removeAuthToken);
  yield put(setViewAs(''));
  yield put(clearTabs());
}
function* watchLogin() {
  yield takeLatest(actionTypes.LOGIN.REQUEST, login);
}
function* watchRefresh() {
  yield takeLatest(actionTypes.REFRESH.REQUEST, refresh);
}
function* watchPasswordChange() {
  yield takeLatest(actionTypes.PASSWORD_CHANGE.REQUEST, passwordChange);
}
function* watchLogout() {
  yield takeLatest(actionTypes.LOGOUT, logout);
}

export {
  watchLogin,
  watchRefresh,
  watchPasswordChange,
  watchLogout
}