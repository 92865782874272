import React from 'react';
import { MHT_JOB_DETAIL } from 'misc/constants';
import { ScheduleStatusCellRenderer, BooleanFieldCellRenderer, LinkFieldCellRenderer } from 'misc/cellRenderers';
import { datetimeFormatter, currencyFormatter } from 'misc/cellFormatters';
import ListTable from 'components/core/ListTable';
import PureRenderComponent from 'components/core/PureRenderComponent';


class MHTJobListTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'No.',
          field: 'number',
          pinned: 'left',
          cellClass: 'ag-jobNumber',
          lockPinned: true,
          lockPosition: true,
          width: 140,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: MHT_JOB_DETAIL,
          },
        },
        {
          headerName: 'Message',
          field: 'hasUnreadCustomerMessage',
          suppressFilter: true,
          cellRendererFramework: BooleanFieldCellRenderer,
          width: 100,
          valueGetter: (params) => {
            return !params.data.hasUnreadCustomerMessage;
          }
        },
        {
          headerName: 'Customer',
          field: 'customer.name',
          width: 175,
        },
        {
          headerName: 'Coordinator',
          field: 'coordinator.name',
          width: 175,
        },
        {
            headerName: 'Client',
            field: 'client.name',
            width: 175,
          },
        {
          headerName: 'Deadline',
          field: 'deadline',
          width: 120,
          valueFormatter: datetimeFormatter
        },
        {
          headerName: 'Service',
          field: 'service.name',
          width: 80,
        },
        {
          headerName: 'Lv',
          field: 'level',
          width: 40,
        },
        {
          headerName: 'Quotation',
          field: 'quotation',
          width: 110,
          filter: 'agNumberColumnFilter',
          valueFormatter: currencyFormatter
        },
        {
          headerName: 'Status',
          field: 'status',
          cellRendererFramework: ScheduleStatusCellRenderer,
          width: 110,
        },
      ]
    }
  }

  render() {
    return (
      <ListTable
        columnDefs={this.state.columnDefs}
        rowData={this.props.jobs}
        deltaRowDataMode={true}
        getRowNodeId={data => data.id} />
    );
  }
}

export default MHTJobListTable;
