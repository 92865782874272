import React from "react";
import * as PropTypes from "prop-types";
import {TaTableRow} from "components/taUi/taTable/taTableRow";
import {TaTableCell} from "components/taUi/taTable/taTableCell";
import {TaText} from "components/taUi/taText/taText";

export class TaMhtEditorSegmentSeparator extends React.Component {

  static propTypes = {
    rowIndex: PropTypes.number.isRequired,
    segment: PropTypes.object.isRequired,
    cellWidthIndex: PropTypes.number.isRequired,
    cellWidthStatus: PropTypes.number.isRequired,
    cellGroupWidth: PropTypes.string.isRequired
  };

  shouldComponentUpdate(nextProps, nextState) {
    return false
  }

  render() {

    const {
      rowIndex,
      segment,
      cellWidthIndex,
      cellWidthStatus,
      cellGroupWidth,
    } = this.props;

    const data = {
      tab: {
        label: 'Tab',
        icon: 'keyboard_tab'
      },
      pagebreak: {
        label: 'Page Break',
        icon: 'keyboard_arrow_down'
      },
      columnbreak: {
        label: 'Column Break',
        icon: 'keyboard_arrow_right'
      }
    }

    const segmentId = segment.id;
    const type = segment.separator_type;

    return (
      <TaTableRow
        key={segmentId}
        id={segmentId}
        rowIndex={rowIndex}
      >
        <TaTableCell
          width={cellWidthIndex}
        >
          <TaText color={'light'}>{segment.order}</TaText>
        </TaTableCell>
        <TaTableCell
          style={{width: cellGroupWidth, textAlign: 'center', zIndex: '5', background: '#f5f6f7'}}
        >
          <TaText color={'light'}>{data[type].label}</TaText>
        </TaTableCell>
        <TaTableCell
          width={cellWidthStatus}
        />
      </TaTableRow>
    );

  }
}
