import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Button, Modal, Radio } from 'react-bootstrap';

const MHTAutoLockSetting = (props) => {

  const {
    requireAutoLock,
    handleFormChange,
    handleSlide,
  } = props;

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Auto Lock</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Do you want to lock repeated segments automatically?</p>
        <Radio
          checked={requireAutoLock === true}
          onChange={() => handleFormChange('requireAutoLock', true)}>Yes
        </Radio>
        <Radio
          checked={requireAutoLock === false}
          onChange={() => handleFormChange('requireAutoLock', false)}>No
        </Radio>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="pull-left"
          bsStyle="default"
          bsSize="sm"
          onClick={() => handleSlide(2)}
          type="button">
          <FontAwesome name="angle-left" className="mr-5" />Back
        </Button>
        <Button
          bsStyle="primary"
          bsSize="sm"
          onClick={() => handleSlide(4)}
          type="button">
          Next<FontAwesome name="angle-right" className="ml-5" />
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
}

export default MHTAutoLockSetting;