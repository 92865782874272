import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Nav, Navbar, NavItem, Button, InputGroup, FormControl } from 'react-bootstrap';
import { JOB_STATUS_OPTIONS } from 'misc/config';
import FilterBar from 'components/core/FilterBar';
import Select from 'components/core/Select';
import ClientAsyncSelect from 'components/core/ClientAsyncSelect'

const statusOptions = [
  { label: "Job Pending", value: "Job Pending" },
  ...JOB_STATUS_OPTIONS,
]

const messageOptions = [
  { label: "未読あり", value: true },
  { label: "未読なし", value: false }
]

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.client === nextProps.client &&
    prevProps.keyword === nextProps.keyword &&
    prevProps.status === nextProps.status &&
    prevProps.messageState === nextProps.messageState
  );
}

const MHTJobListFilter = React.memo(({
  client,
  status,
  keyword,
  messageState,
  handleSubmit,
  handleMessageStateChange,
  handleStatusChange,
  handleClientChange,
  handleKeywordChange
 }) => {
  return (
    <FilterBar>
      <Nav>
        <NavItem disabled>Client</NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <ClientAsyncSelect
          value={client}
          clearable={true}
          placeholder=""
          onChange={handleClientChange}
          style={{ width: 200 }} />
      </Navbar.Form>
      <Nav>
        <NavItem disabled>Status</NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <Select
          value={status}
          clearable={true}
          placeholder=""
          options={statusOptions}
          onChange={value => handleStatusChange(value)}
          style={{ width: 200 }} />
      </Navbar.Form>
      <Nav>
        <NavItem disabled>Message</NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <Select
          value={messageState}
          clearable={true}
          placeholder=""
          options={messageOptions}
          onChange={value => handleMessageStateChange(value)}
          style={{ width: 120 }} />
      </Navbar.Form>
      <Navbar.Form pullRight>
        <InputGroup bsSize="sm">
          <FormControl
            value={keyword}
            onChange={(e) => handleKeywordChange(e.target.value)}
            onKeyPress={(e) => {
              e.key === 'Enter' && handleSubmit()
            }}
            type="text"
            style={{ width: 200 }}
            bsSize="sm" />
          <InputGroup.Button>
            <Button
              bsSize="sm"
              onClick={handleSubmit}>
              <FontAwesome name="search" />
            </Button>
          </InputGroup.Button>
        </InputGroup>
      </Navbar.Form>
    </FilterBar>
  );
}, areEqual);

export default MHTJobListFilter;
