import React from "react";
import * as PropTypes from 'prop-types';
import './taCard.css';

export const TaCard = (props) => {

  const {
    className,
    scroll,
    flex,
    ...attributes
  } = props;

  const baseClass = 'ta-card';

  let classNames = [baseClass];
  if (className) {
    classNames.push(className);
  }

  if (scroll === 'auto') {
    classNames.push(baseClass + '--scroll');
  } else if (scroll) {
    classNames.push(baseClass + '--scroll-' + scroll);
  }

  if (flex === 'auto') {
    classNames.push(baseClass + '--flex');
  } else if (flex && scroll !== 'hidden') {
    if (typeof attributes.style === 'undefined') {
      attributes.style = {};
    }
    attributes.style.flex = flex;
  }

  return (
    <div className={classNames.join(' ')} {...attributes}>{props.children}</div>
  )

};
TaCard.propTypes = {
  scroll: PropTypes.oneOf(['x', 'y', 'auto', 'hidden']),
  flex: PropTypes.oneOfType([
    PropTypes.oneOf(['auto']),
    PropTypes.number
  ]),
};
