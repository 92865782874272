import React from 'react';
import { MainSidebar } from 'reactjs-admin-lte';
import MainSidebarMenuTreeView from './MainSidebarMenuTreeView';
import { getTabConfigByName } from 'misc/utils';
import * as constants from 'misc/constants';


const ManagerSidebar = (props) => {

  const {
    handleTabOpen,
    isAE,
    isCoordinator,
    isAccounting,
    currentTab
  } = props;

  const handleClick = (tabName) => {
    const tabConfig = getTabConfigByName(tabName);
    handleTabOpen(tabConfig)
  }

  return (
    <MainSidebar>
      <MainSidebar.Menu>
        <MainSidebar.Menu.Header>NAVIGATION</MainSidebar.Menu.Header>
        <MainSidebar.Menu.Item
          title="Daily Schedule"
          iconName="calendar-check-o"
          active={currentTab === constants.JOB_SCHEDULE}
          onClick={() => handleClick(constants.JOB_SCHEDULE)} />

        <MainSidebar.Menu.Item
          title="Calendar"
          iconName="calendar"
          active={currentTab === constants.CALENDAR}
          onClick={() => handleClick(constants.CALENDAR)} />

        <MainSidebar.Menu.Item
          title="Job Search"
          iconName="search"
          active={currentTab === constants.JOB_SEARCH}
          onClick={() => handleClick(constants.JOB_SEARCH)} />

         { isAE &&
          <MainSidebarMenuTreeView
            title="AE"
            iconName="briefcase"
            active={[
              constants.JOB_CREATE,
              constants.TEMPLATE_LIST,
              constants.JOB_DRAFT_LIST,
              // constants.WORKING_LIST,
              constants.DELIVERY_LIST,
              constants.RNQ_LIST,
              constants.SALES_REPORT,
              constants.SALES_TARGET
            ].includes(currentTab)}>

            <MainSidebar.Menu.Item
              title="New Job"
              iconName="circle-o"
              active={currentTab === constants.JOB_CREATE}
              onClick={() => handleClick(constants.JOB_CREATE)}/>

            <MainSidebar.Menu.Item
              title="Template List"
              iconName="circle-o"
              active={currentTab === constants.TEMPLATE_LIST}
              onClick={() => handleClick(constants.TEMPLATE_LIST)} />
            
            <MainSidebar.Menu.Item
              title="Draft List"
              iconName="circle-o"
              active={currentTab === constants.JOB_DRAFT_LIST}
              onClick={() => handleClick(constants.JOB_DRAFT_LIST)} />
            
            <MainSidebar.Menu.Item
              title="Delivery List"
              iconName="circle-o"
              active={currentTab === constants.DELIVERY_LIST}
              onClick={() => handleClick(constants.DELIVERY_LIST)} />

            <MainSidebar.Menu.Item
              title="RnQ List"
              iconName="circle-o"
              active={currentTab === constants.RNQ_LIST}
              onClick={() => handleClick(constants.RNQ_LIST)} />

            <MainSidebar.Menu.Item
              title="Sales & MHT Report"
              iconName="circle-o"
              active={currentTab === constants.SALES_REPORT}
              onClick={() => handleClick(constants.SALES_REPORT)} />

            <MainSidebar.Menu.Item
              title="Sales Target"
              iconName="circle-o"
              active={currentTab === constants.SALES_TARGET}
              onClick={() => handleClick(constants.SALES_TARGET)} />

          </MainSidebarMenuTreeView>
        }

        { isCoordinator &&
          <MainSidebarMenuTreeView
            title="Coordinator"
            active={[
              constants.PENDING_LIST,
              constants.PREPARING_LIST,
              constants.TOTRA_LIST,
            ].includes(currentTab)}
            iconName="heart">

            <MainSidebar.Menu.Item
              title="Pending List"
              iconName="circle-o"
              active={currentTab === constants.PENDING_LIST}
              onClick={() => handleClick(constants.PENDING_LIST)} />
            <MainSidebar.Menu.Item
              title="Preparing List"
              iconName="circle-o"
              active={currentTab === constants.PREPARING_LIST}
              onClick={() => handleClick(constants.PREPARING_LIST)} />

            <MainSidebar.Menu.Item
              title="ToTra List"
              iconName="circle-o"
              active={currentTab === constants.TOTRA_LIST}
              onClick={() => handleClick(constants.TOTRA_LIST)} />

          </MainSidebarMenuTreeView>
        }

        <MainSidebarMenuTreeView
          title="MHT"
          iconName="laptop"
          active={[
            constants.TERM_SEARCH,
            constants.WORDLIST,
            constants.GLOSSARY_LIST,
            constants.TRANSLATION_MEMORY_SEARCH,
            constants.TRANSLATION_MEMORY_LIST,
            constants.UNSUPPORTED_ORDER_LIST,
          ].includes(currentTab)}>

          <MainSidebar.Menu.Item
            title="Term Search"
            iconName="circle-o"
            active={currentTab === constants.TERM_SEARCH}
            onClick={() => handleClick(constants.TERM_SEARCH)} />
          <MainSidebar.Menu.Item
            title="Client WordList"
            iconName="circle-o"
            active={currentTab === constants.WORDLIST}
            onClick={() => handleClick(constants.WORDLIST)} />

          { isCoordinator &&
            <MainSidebar.Menu.Item
              title="Category"
              iconName="circle-o"
              active={currentTab === constants.GLOSSARY_LIST}
              onClick={() => handleClick(constants.GLOSSARY_LIST)} />
          }
          { isCoordinator &&
            <MainSidebar.Menu.Item
              title="Translation Memory Search"
              iconName="circle-o"
              active={currentTab === constants.TRANSLATION_MEMORY_SEARCH}
              onClick={() => handleClick(constants.TRANSLATION_MEMORY_SEARCH)} />
          }
          { isCoordinator &&
            <MainSidebar.Menu.Item
              title="Translation Memory List"
              iconName="circle-o"
              active={currentTab === constants.TRANSLATION_MEMORY_LIST}
              onClick={() => handleClick(constants.TRANSLATION_MEMORY_LIST)} />
          }
          { isAE &&
            <MainSidebar.Menu.Item
              title="Quote List"
              iconName="circle-o"
              active={currentTab === constants.QUOTE_LIST}
              onClick={() => handleClick(constants.QUOTE_LIST)} />
          }
          <MainSidebar.Menu.Item
            title="MH Job List"
            iconName="circle-o"
            active={currentTab === constants.MHT_JOB_LIST}
            onClick={() => handleClick(constants.MHT_JOB_LIST)} />

          <MainSidebar.Menu.Item
            title="Unsupported Order List"
            iconName="circle-o"
            active={currentTab === constants.UNSUPPORTED_ORDER_LIST}
            onClick={() => handleClick(constants.UNSUPPORTED_ORDER_LIST)} />

        </MainSidebarMenuTreeView>

        <MainSidebarMenuTreeView
          title="Client"
          iconName="building"
          active={[
            constants.CLIENT_CREATE,
            constants.CLIENT_LIST
          ].includes(currentTab)}>

          <MainSidebar.Menu.Item
            title="New Client"
            iconName="circle-o"
            active={currentTab === constants.CLIENT_CREATE}
            onClick={() => handleClick(constants.CLIENT_CREATE)} />
          <MainSidebar.Menu.Item
            title="Client List"
            iconName="circle-o"
            active={currentTab === constants.CLIENT_LIST}
            onClick={() => handleClick(constants.CLIENT_LIST)} />
        </MainSidebarMenuTreeView>

        { isCoordinator &&
          <MainSidebarMenuTreeView
            title="User"
            iconName="users"
            active={[
              constants.USER_CREATE,
              constants.USER_LIST
            ].includes(currentTab)}>

            <MainSidebar.Menu.Item
              title="New User"
              iconName="circle-o"
              onClick={() => handleClick(constants.USER_CREATE)} />
            <MainSidebar.Menu.Item
              title="User List"
              iconName="circle-o"
              onClick={() => handleClick(constants.USER_LIST)} />

          </MainSidebarMenuTreeView>
        }

        <MainSidebarMenuTreeView
          title="MHT TT"
          iconName="cog"
          active={[
            constants.CUSTOMER_CREATE,
            constants.CUSTOMER_LIST
          ].includes(currentTab)}>

          <MainSidebar.Menu.Item
            title="New Customer"
            iconName="circle-o"
            active={currentTab === constants.CUSTOMER_CREATE}
            onClick={() => handleClick(constants.CUSTOMER_CREATE)} />
          <MainSidebar.Menu.Item
            title="Customer List"
            iconName="circle-o"
            active={currentTab === constants.CUSTOMER_LIST}
            onClick={() => handleClick(constants.CUSTOMER_LIST)} />
          {/* <MainSidebar.Menu.Item
            title="Unit Price"
            iconName="circle-o" /> */}
        </MainSidebarMenuTreeView>

        { isAccounting &&
          <MainSidebarMenuTreeView
            title="Accounting"
            iconName="money"
            active={[
              constants.INVOICE_LIST,
              constants.PAYMENT_SEARCH
            ].includes(currentTab)}>

            <MainSidebar.Menu.Item
              title="Invoice List"
              iconName="circle-o"
              active={currentTab === constants.INVOICE_LIST}
              onClick={() => handleClick(constants.INVOICE_LIST)} />

            <MainSidebar.Menu.Item
              title="Payment Search"
              iconName="circle-o"
              active={currentTab === constants.PAYMENT_SEARCH}
              onClick={() => handleClick(constants.PAYMENT_SEARCH)} />

          </MainSidebarMenuTreeView>
        }

        <MainSidebar.Menu.Header>SETTINGS</MainSidebar.Menu.Header>

        <MainSidebar.Menu.Item
          title="Profile"
          iconName="user-circle-o"
          active={currentTab === constants.PROFILE}
          onClick={() => handleClick(constants.PROFILE)} />
      </MainSidebar.Menu>
    </MainSidebar>
  )
}

export default ManagerSidebar;
