import * as types from 'actionTypes/loading';

export const setLoading = (name, isLoading) => {
  return {
    type: types.SET_LOADING,
    payload: {
        name,
        isLoading
    }
  }
}