import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { JOB_DUPLICATE_FORM } from 'misc/constants';
import { duplicateCreateRequest, duplicateDraftRequest } from 'actions/jobs/managers';
import { retrieveRequest } from 'actions/jobs/managers';
import { getJobDuplicateFormInitialValuesById } from 'selectors/forms';
import { isJobDuplicateLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobDuplicate from './presenter';


class JobDuplicateContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.retrieveRequest(this.props.id);
  }

  handleCreate = (values) => {
    this.props.duplicateCreateRequest(this.props.id, this.props.form, values);
  }

  handleSaveAsDraft = (values) => {
    this.props.duplicateDraftRequest(this.props.id, this.props.form, values);
  }

  render() {
    console.log(this.props);
    return (
      <JobDuplicate
        {...this.props}
        handleCreate={this.handleCreate}
        handleSaveAsDraft={this.handleSaveAsDraft} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const { id } = props.params;
  const form = `${JOB_DUPLICATE_FORM}/${id}`
  const formSelector = formValueSelector(form);
  const client = formSelector(state, 'client');
  return {
    id,
    form,
    client,
    isLoading: isJobDuplicateLoading(state, id),
    initialValues: getJobDuplicateFormInitialValuesById(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    retrieveRequest,
    duplicateCreateRequest,
    duplicateDraftRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobDuplicateContainer);