
import axios from 'axios';

const ManagerApi = {
  
  pendingCreate: (values) => {
    return axios.post(`jobs/pending/create/`, values);
  },
  splitCreate: (values) => {
    return axios.post(`jobs/split/create/`, values);
  },
  draftToPending: (id, values) => {
    return axios.put(`jobs/draft/${id}/pending/`, values);
  },
  draftUpdate: (id, values) => {
    return axios.put(`jobs/draft/${id}/`, values);
  },
  pendingDraft: (values) => {
    return axios.post(`jobs/pending/draft/`, values);
  },
  splitDraft: (values) => {
    return axios.post(`jobs/split/draft/`, values);
  },
  splitIssue: (values) => {
    return axios.post(`jobs/split/issue/`, values);
  },
  issue: (id, values) => {
    return axios.put(`jobs/${id}/issue/`, values);
  },
  edit: (id, values) => {
    return axios.put(`jobs/${id}/edit/`, values);
  },
  retrieve: (id) => {
    return axios.get(`jobs/${id}/`);
  },

  patch: (id, values) => {
    return axios.patch(`jobs/${id}/`, values);
  },
  cancel: (id) => {
    return axios.put(`jobs/${id}/cancel/`);
  },
  remove: (id) => {
    return axios.delete(`jobs/${id}/`);
  },

  dailySchedule: (params) => {
    return axios.get(`jobs/daily/`, { params });
  },
  futureSchedule: (params) => {
    return axios.get(`jobs/future/`, { params });
  },
  rearrange: (ids) => {
    return axios.post(`jobs/rearrange/`, { jobs: ids });
  },
  search: (params) => {
    return axios.get(`jobs/search/`, { params });
  },
  draftList: () => {
    return axios.get(`jobs/draft/`);
  },
  // workingList: () => {
  //   return axios.get(`jobs/working/`);
  // },
  deliveryList: (params) => {
    return axios.get(`jobs/delivery/`, { params });
  },  
  pendingList: () => {
    return axios.get(`jobs/pending/`);
  },
  preparingList: (params) => {
    return axios.get(`jobs/preparing/`, { params });
  },
  totraList: () => {
    return axios.get(`jobs/totra/`);
  },
  rnqList: (params) => {
    return axios.get(`jobs/rnq/`, { params });
  },

  print: (id) => {
    let printWin = window.open('about:blank');
    return axios({
      url: `jobs/${id}/print/`,
      method: 'GET',
      reponseType: 'document',
    }).then(response => {
      printWin.document.open();
      printWin.document.write(response.data);
      printWin = null;
    }, (error) => {
      printWin.close();
      printWin = null;
    });
  },

  invoiceList: (id) => {
    return axios.get(`jobs/${id}/invoices/`);
  },
  memberList: (id) => {
    return axios.get(`jobs/${id}/members/`);
  },

  process: (id, values) => {
    return axios.put(`jobs/${id}/process/`, values);
  },

  fetchLatestNumber: (type) => {
    return axios.get(`jobs/latest/`, { params: type });
  },
  // Payment Search ============================================================
  paymentSearch: (params) => {
    return axios.get('payments/search/', { params });
  },
  // Sales Reort ===============================================================
  salesReport: (params) => {
    return axios.get('jobs/report/sales/', { params });
  },
  // Count Reort ===============================================================
  countReport: (params) => {
    return axios.get('jobs/report/count/', { params });
  },

  suggestAssignment: (params) => {
    return axios.get(`jobs/suggest/`, { params });
  },

  assignmentPatch: (id, values) => {
    return axios.patch(`assignments/${id}/`, values)
  },

  autocomplete: (params) => {
    return axios.get(`jobs/autocomplete/`, { params });
  },

  bulkVerify: (values) => {
    return axios.post(`jobs/bulk-verify/`, values);
  },
  mhtJobList: (params) => {
    return axios.get(`jobs/mht/`, { params });
  },
  mhtJobRetrieve: (id) => {
    return axios.get(`jobs/mht/${id}/`);
  },
  mhtJobMessageList: (id) => {
    return axios.get(`jobs/mht/${id}/messages/`);
  },
  mhtJobMessageCreate: (values) => {
    return axios.post(`mht-messages/manager/`, values);
  },
  mhtJobMessageRead: (id) => {
    return axios.post(`mht-messages/${id}/`, { read: true });
  },
  mhtJobMessageBatchRead: (values) => {
    return axios.post(`mht-messages/batch-read/`, values);
  },
  mhtInvoiceDownload: (values, onDownloadProgress) => {
    return axios({
      url: `jobs/mht/${values.id}/pdf/invoice/`,
      method: 'POST',
      data: {
        language: values.language,
        to: values.to,
      },
      responseType: 'blob',
      onDownloadProgress
    });
  },
  mhtJobDelivery: (id) => {
    return axios.post(`jobs/mht/${id}/delivery/`);
  },
  mhtJobRedelivery: (id) => {
    return axios.post(`jobs/mht/${id}/redelivery/`);
  },
  quoteSourcefileList: (id) => {
    return axios.get(`jobs/${id}/quote-sourcefiles/`);
  },
}

export default ManagerApi;