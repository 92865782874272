import React from "react";
import * as PropTypes from 'prop-types';
import './taTab.css';
import {TaBadge} from "components/taUi/taBadge/taBadge";
import {TaIcon} from "components/taUi/taIcon";

const baseClass = 'ta-tab-label';

export const TaTabLabel = (props) => {
  const {
    label,
    icon,
    badge,
    isActive,
    className,
    ...attributes
  } = props;

  let classNames = [baseClass];
  if (className) {
    classNames.push(className);
  }
  if (attributes.disabled) {
    classNames.push(baseClass + '--disabled');
  }
  if (isActive) {
    classNames.push(baseClass + '--active');
  }

  return (
    <button className={classNames.join(' ')} {...attributes}
    >
      {(icon) && <TaIcon name={icon}/>}
      {(label) && <span>{props.label}</span>}
      {(badge) && <TaBadge value={badge}/>}
    </button>
  )

};
TaTabLabel.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  badge: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isActive: PropTypes.bool.isRequired,
};