import React from 'react';
import { CLIENT_DETAIL } from 'misc/constants';
import { datetimeFormatter } from 'misc/cellFormatters';
import { BooleanFieldCellRenderer, LinkFieldCellRenderer } from 'misc/cellRenderers';
import PureRenderComponent from 'components/core/PureRenderComponent';
import ListTable from 'components/core/ListTable';


class ClientListTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'Name',
          field: 'name',
          width: 190,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: CLIENT_DETAIL
          },
        },
        {
          headerName: 'Full Name In English',
          field: 'fullNameEn',
          width: 250,
        },
        {
          headerName: 'Full Name In Japanese',
          field: 'fullNameJp',
          width: 250,
        },
        {
          headerName: 'Industry',
          field: 'industry.name',
          width: 200,
        },
        {
          headerName: 'Active',
          field: 'isActive',
          suppressFilter: true,
          cellRendererFramework: BooleanFieldCellRenderer,
          width: 85,
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: datetimeFormatter,
          width: 150,
        },
        {
          headerName: 'Modified',
          field: 'modified',
          valueFormatter: datetimeFormatter,
          width: 150,
        },
      ]
    }
  }

  render() {
    return (
      <ListTable
        wrapperHeight="75vh"
        columnDefs={this.state.columnDefs}
        rowData={this.props.clients}
        deltaRowDataMode={true}
        getRowNodeId={data => data.id} />
    );
  }
}

export default ClientListTable;
