import React from "react";
import PropTypes from 'prop-types';
import './style.css';
import {TaIcon} from "components/taUi/taIcon";

export class TaInputCheckbox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hasFocus: false,
      isChecked: false
    };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  };

  componentDidMount() {
    this.setState({
      isChecked: !!this.props.value
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.value !== this.props.value) {
      this.setState({
        isChecked: !!this.props.value
      });
    }
  }

  onFocus(event) {
    this.setState({
      hasFocus: true
    },()=>{
      this.emitEvent(this.state.isChecked, 'onFocus', event);
      // this.onChange(event);
    });
  };
  onBlur(event) {
    this.setState({
      hasFocus: false
    },()=>{
      this.emitEvent(this.state.isChecked, 'onBlur', event);
    });
  };
  onChange(event) {
    const newValue = !this.state.isChecked;
    this.setState({
      isChecked: newValue
    },()=>{
      this.emitEvent(newValue, 'onChange', event);
    });
  };

  emitEvent = (value, eventName, event) => {
    const props = this.props;
    ['onEvent', eventName].forEach((fn) => {
        if (typeof props[fn] === 'function') {
          props[fn]({
            name: eventName.substring(2).toLowerCase(),
            data: {
              property: props.name,
              // value: (event.target.checked) ? (props.value) ? props.value : true : false;
              value: value
            },
            event: event
          });
        }
      }
    );
  };

  render() {
    const props = this.props;
    const isChecked = this.state.isChecked;

    let classNames = ['ta-input', 'ta-input--checkbox'];
    if (this.state.hasFocus) {
      classNames.push('ta-input--focus');
    }
    if (isChecked) {
      classNames.push('ta-input--checked');
    }
    if (props.disabled) {
      classNames.push('ta-input--disabled');
    }
    if (props.alignIcon && props.alignIcon === 'right') {
      classNames.push('ta-input--align-right');
    }
    if (props.className) {
      classNames.push(props.className);
    }

    const iconName = (isChecked) ? (props.iconChecked) ? props.iconChecked : 'check_box' : (props.iconUnchecked) ? props.iconUnchecked : 'check_box_outline_blank';

    return (
      <label
        className={classNames.join(' ')}
      >
        <input
          type="checkbox"
          name={props.name}
          checked={isChecked?'checked':false}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          disabled={props.disabled}
        />
        <TaIcon className="ta-input__icon" name={iconName}/>
        {(!!props.label) ? <span className="ta-input__label">{props.label}</span> : null}

      </label>
    );
  }

}

TaInputCheckbox.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  label: PropTypes.string,
  alignIcon: PropTypes.string,
  iconChecked: PropTypes.string,
  iconUnchecked: PropTypes.string

};