import React from 'react';
import FontAwesome from 'react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { connectModal } from 'redux-modal';
import { Button, Modal } from 'react-bootstrap';
import Cropper from 'react-cropper';
import { isUserAvatarUploadModalLoading } from 'selectors/loading';
import { USER_AVATAR_UPLOAD_MODAL } from 'misc/constants';
import { avatarUploadRequest } from 'actions/users';
import Spinner from 'components/core/Spinner';
import PureRenderComponent from 'components/core/PureRenderComponent';


class UserAvatarUploadModal extends PureRenderComponent {
  
  handleAvatarUpload = () => {
    const avatar = this.refs.cropper.getCroppedCanvas().toDataURL();
    this.props.avatarUploadRequest(this.props.userId, avatar);
  }

  render() {

    const {
      show,
      handleHide,
      src,
      isLoading
    } = this.props;
    
    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Crop your new profile picture</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          <Cropper
            ref='cropper'
            src={src}
            dragMode="none"
            movable={false}
            scalable={false}
            zoomable={false}
            zoomOnWheel={false}
            style={{ width: '100%' }}
            aspectRatio={1} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pull-left"
            bsSize="sm"
            type="button"
            onClick={handleHide}>
            <FontAwesome name="close" className="mr-5" />Close
          </Button>
          <Button
            bsStyle="primary"
            bsSize="sm"
            type="button"
            onClick={this.handleAvatarUpload}>
              Submit
          </Button>
        </Modal.Footer>
        <Spinner isLoading={isLoading} />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: isUserAvatarUploadModalLoading(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    avatarUploadRequest
  }, disptach)
}

UserAvatarUploadModal = connectModal({
  name: USER_AVATAR_UPLOAD_MODAL
})(UserAvatarUploadModal)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAvatarUploadModal);

