import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { EMPLOYEE_STATUS_MAP } from 'misc/config';
import { checkerCurrentJobsRequest } from 'actions/jobs/employees';
import { getCheckerCurrentJobs } from 'selectors/lists';
import { isCheckerCurrentJobsLoading } from 'selectors/loading';
import EmployeeCurrentJobsHOC from './EmployeeCurrentJobsHOC';
import EmployeeCurrentJobs from './presenter'


const mapStateToProps = (state) => {
  const roleKey = 'checker';
  return {
    roleKey,
    sentToStatus: EMPLOYEE_STATUS_MAP[roleKey].sent,
    jobs: getCheckerCurrentJobs(state),
    isLoading: isCheckerCurrentJobsLoading(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest: checkerCurrentJobsRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeCurrentJobsHOC(EmployeeCurrentJobs));
