import React from 'react';

const EmptyRow = ({ colSpan }) => (
  <tr>
    <td
      className="text-center"
      colSpan={colSpan}>
      No Rows To Show
    </td>
  </tr>
)

export default EmptyRow;