import { put, select, takeLatest, call } from 'redux-saga/effects';
import { show } from 'redux-modal';
import { getTabs, getCurrentTab } from 'selectors/globalTabs';
import { MHT_EDITOR } from 'misc/constants';
import { MHT_EXIT_CONFIRM_MODAL } from 'misc/constants';
import * as actions from 'actions/globalTabs';
import * as actionTypes from 'actionTypes/globalTabs';

function* selectTab(action) {
  yield window.scrollTo({ top: 0, behavior: 'smooth' });
}

function* addOrSelectTab(action) {
  const {
    id,
    title,
    componentPath,
    componentName,
    params,
    allowRefresh,
    requireCloseConfirm = false
  } = action.payload;
  const tabs = yield select(getTabs);
  const exists = tabs.findIndex(tab => tab.id === id) !== -1;
  if (exists) {
    yield put(actions.selectTab(id));
  } else {
    yield put(actions.addTab(
      id,
      title,
      componentPath,
      componentName,
      params,
      allowRefresh,
      requireCloseConfirm
    ));
  }
}

function* selectLastTab(action) {
  const tabs = yield select(getTabs);
  const lastTab = tabs[tabs.length - 1];
  if (lastTab) {
    yield put(actions.selectTab(lastTab.id));
  }
}

function* closeCurrentTab() {
  const currentTab = yield select(getCurrentTab);
  yield put(actions.closeTab(currentTab));
}

function* confirmTabClose(action) {
  const { tab } = action.payload;
  switch (tab.componentName) {
    case MHT_EDITOR:
      yield put(show(MHT_EXIT_CONFIRM_MODAL))
      break;
    default:
      yield put(actions.closeTab(tab.id));
      yield call(selectLastTab, {});
  }
}

function* watchSelectTab() {
  yield takeLatest(actionTypes.SELECT_TAB, selectTab);
}

function* watchAddOrSelectTab() {
  yield takeLatest(actionTypes.ADD_OR_SELECT_TAB, addOrSelectTab);
}

function* watchCloseTab() {
  yield takeLatest(actionTypes.CLOSE_TAB, selectLastTab);
}

function* watchConfirmTabClose() {
  yield takeLatest(actionTypes.CONFIRM_TAB_CLOSE, confirmTabClose);
}

export {
  watchSelectTab,
  watchAddOrSelectTab,
  watchCloseTab,
  watchConfirmTabClose,
  closeCurrentTab
}