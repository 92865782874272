import React from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SALES_REPORT } from 'misc/constants';
import { DEFAULT_MONTH_FORMAT } from 'misc/config';
import { setFilter } from 'actions/filters';
import { salesReportRequest } from 'actions/accounting';
import { analysisReportRequest } from 'actions/mht';
import { getSalesReportCurrentMonth } from 'selectors/filters';
import { getAEOptions } from 'selectors/options';
import { isSalesReportLoading, isAnalysisReportLoading } from 'selectors/loading';
import { getSalesReport, getAnalysisReport } from 'selectors/lists';
import PureRenderComponent from 'components/core/PureRenderComponent';
import SalesReport from './presenter';


class SalesReportContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.salesReportRequest(this.props.currentMonth);
    this.props.analysisReportRequest(this.props.currentMonth);
  }

  handlePrev = () => {
    const { currentMonth } = this.props;
    const prev = moment(currentMonth, DEFAULT_MONTH_FORMAT).subtract(1, 'months');
    this.handleMonthChange(prev)
  }

  handleNext = () => {
    const { currentMonth } = this.props;
    const next = moment(currentMonth, DEFAULT_MONTH_FORMAT).add(1, 'months');
    this.handleMonthChange(next)
  }

  handleMonthChange = (month) => {
    const currentMonth = moment(month).format(DEFAULT_MONTH_FORMAT);
    this.props.setFilter(SALES_REPORT, 'currentMonth', currentMonth);
    this.props.salesReportRequest(currentMonth);
    this.props.analysisReportRequest(currentMonth);
  }

  render() {
    return (
      <SalesReport
        {...this.props}
        handlePrev={this.handlePrev}
        handleNext={this.handleNext} />
    );
  }
}

const mapStateToProps = (state) => {
  const salesReport = getSalesReport(state);
  const analysisReport = getAnalysisReport(state);
  return {
    isSalesReportLoading: isSalesReportLoading(state),
    isAnalysisReportLoading: isAnalysisReportLoading(state),
    quotationResults: salesReport['quotationResults'],
    quotationTotal: salesReport['quotationTotal'],
    countResults: salesReport['countResults'],
    countTotal: salesReport['countTotal'],
    target: salesReport['target'],
    progress: salesReport['progress'],
    analysisResults: analysisReport['results'],
    avgMonthly: analysisReport['avgMonthly'],
    currentMonth: getSalesReportCurrentMonth(state),
    aeOptions: getAEOptions(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    setFilter,
    salesReportRequest,
    analysisReportRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesReportContainer);
