import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Button, Modal, FormGroup, Form, ControlLabel, Checkbox } from 'react-bootstrap';
import { MEMORY_SUGGEST_RATIOS, SEARCH_METHOD_OPTIONS } from 'misc/config';
import Select from 'components/core/Select';
import PureRenderComponent from 'components/core/PureRenderComponent';
import ClientAsyncSelect from 'components/core/ClientAsyncSelect';
import JobNumberAsyncSelect from 'components/core/JobNumberAsyncSelect';


class MHTMemoryReferenceSetting extends PureRenderComponent {
  render() {

    const {
      clients,
      jobs,
      memorySuggestRatio,
      includeTentative,
      excludePenalty,
      searchMethod,
      handleFormChange,
      handleFormArrayChange,
      handleSlide
    } = this.props;

    return (
      <React.Fragment>
        <Modal.Header closeButton>
          <Modal.Title>Specify Translation Memory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup>
              <ControlLabel>Client</ControlLabel>
              <ClientAsyncSelect
                value={clients}
                multi={true}
                onChange={value => handleFormArrayChange('referenceClients', value)} />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Job</ControlLabel>
              <JobNumberAsyncSelect
                value={jobs}
                multi={true}
                closeOnSelect={false}
                onSelectResetsInput={false}
                onBlurResetsInput={false}
                onChange={value => handleFormArrayChange('referenceJobs', value)} />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Memory Suggest Ratio</ControlLabel>
              <Select
                value={memorySuggestRatio}
                onChange={value => handleFormChange('memorySuggestRatio', value)}
                options={MEMORY_SUGGEST_RATIOS} />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Search Method</ControlLabel>
              <Select
                value={searchMethod}
                onChange={value => handleFormChange('searchMethod', value)}
                options={SEARCH_METHOD_OPTIONS} />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Tentatvive memory</ControlLabel>
              <Checkbox
                className="mt-0"
                checked={includeTentative}
                onChange={e => handleFormChange('includeTentative', e.target.checked)}>Include Tentatvive memory</Checkbox>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Penalty</ControlLabel>
              <Checkbox
                className="mt-0"
                checked={excludePenalty}
                onChange={e => handleFormChange('excludePenalty', e.target.checked)}>Exclude penalty TM</Checkbox>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pull-left"
            bsStyle="default"
            bsSize="sm"
            onClick={() => handleSlide(0)}
            type="button">
            <FontAwesome name="angle-left" className="mr-5" />Back
          </Button>
          <Button
            bsStyle="primary"
            bsSize="sm"
            onClick={() => handleSlide(2)}
            type="button">
            Next<FontAwesome name="angle-right" className="ml-5" />
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}



export default MHTMemoryReferenceSetting;