import React from 'react';
import { Tab } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import JobScheduleTable from './JobScheduleTable';


const JobScheduleTabPane = (props) => {
  const {
    eventKey,
    jobs,
    rowDrag,
    handleRowDrag,
    viewAs,
    isLoading,
    handleTabOpen,
    handleSelectionChange
  } = props;

  return (
    <Tab.Pane eventKey={eventKey}>
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body noPadding={true}>
          <JobScheduleTable
            jobs={jobs}
            rowDrag={rowDrag}
            handleRowDrag={handleRowDrag}
            handleTabOpen={handleTabOpen}
            viewAs={viewAs}
            handleSelectionChange={handleSelectionChange} />
        </Box.Body>
      </Box>
    </Tab.Pane>
  );
}

export default JobScheduleTabPane;
