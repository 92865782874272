import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';
import { jobSourcefileListRequest } from 'actions/jobs/common';
import {
  jobSourcefileUploadRequest,
  jobSourcefileRemoveRequest,
  sourcefileDownloadRequest
} from 'actions/documents';
import { getDetailTabConfigByName } from 'misc/utils';
import { sourcefileUploadRequest as mhtSourcefileUploadRequest } from 'actions/mht';
import { getJobSourcefilesById } from 'selectors/lists'
import { isJobSourcefileListLoading } from 'selectors/loading';
import { SINGLE_FILE_UPLOAD_MODAL, MHT_FILE_UPLOAD_MODAL, MHT_EDITOR } from 'misc/constants';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobSourceFile from './presenter';


class JobSourcefileContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.jobSourcefileListRequest(this.props.jobId);
  }

  handleDownload = (file) => {
    this.props.sourcefileDownloadRequest(file);
    this.props.handleJobStart && this.props.handleJobStart();
  }

  showUploadModal = () => {
    this.props.show(SINGLE_FILE_UPLOAD_MODAL, {
      title: 'Source File',
      handleUpload: this.handleUpload
    })
  }

  showUploadMHTModal = () => {
    this.props.show(MHT_FILE_UPLOAD_MODAL, {
      job: this.props.job,
      handleSetupMHT: this.handleSetupMHT
    });
  }

  handleSetupMHT = (formData) => {
    this.props.mhtSourcefileUploadRequest(formData);
  }

  handleUpload = (values) => {
    this.props.jobSourcefileUploadRequest(this.props.jobId, values);
  }

  handleRemove = (file) => {
    this.props.jobSourcefileRemoveRequest(this.props.jobId, file);
  }

  hasMHT = () => {
    return this.props.files.filter(f => f.mht !== null).length > 0
  }

  hasFile = () => {
    return this.props.files && this.props.files.length > 0
  }

  handleOpenMHT = (id, stored) => {
    const tabParams = {
      id,
      stored,
      role: this.props.roleKey,
      service: this.props.job.service,
      client: this.props.job.client,
      assignment: this.props.assignment,
      status: this.props.job.status
     };
    const tabConfig = getDetailTabConfigByName(
      MHT_EDITOR,
      id,
      this.props.job.number,
      tabParams
    );
    this.props.handleJobStart && this.props.handleJobStart();
    this.props.handleTabOpen(tabConfig);
  }

  render() {
    return (
      <JobSourceFile
        {...this.props}
        hasMHT={this.hasMHT}
        hasFile={this.hasFile}
        handleOpenMHT={this.handleOpenMHT}
        showMHTTermSettingModal={this.showMHTTermSettingModal}
        showUploadModal={this.showUploadModal}
        showUploadMHTModal={this.showUploadMHTModal}
        handleDownload={this.handleDownload}
        handleRemove={this.handleRemove} />
    )
  }
}

const mapStateToProps = (state, props) => {
  const id = props.jobId
  return {
    files: getJobSourcefilesById(state, id),
    isLoading: isJobSourcefileListLoading(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    jobSourcefileListRequest,
    jobSourcefileUploadRequest,
    jobSourcefileRemoveRequest,
    mhtSourcefileUploadRequest,
    sourcefileDownloadRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobSourcefileContainer)
