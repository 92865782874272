import React from "react";
import * as PropTypes from "prop-types";
import "./taMenu.css";

export const TaMenuItem = (props) => {

  const {
    name,
    className,
    disabled,
    callback,
    onClick,
    ...attributes
  } = props;


  let classNames = ['ta-menu-item'];
  let itemProps = {}

  if (disabled) {
    classNames.push('ta-menu-item--disabled');
  } else {
    if(callback){
      itemProps.onClick = () => callback(name);
    } else if (onClick) {
      itemProps.onClick = onClick;
    }
  }
  if (className) {
    classNames.push(className);
  }

  return <div {...attributes} {...itemProps} className={classNames.join(' ')} />

};
TaMenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  callback: PropTypes.func,
  disabled: PropTypes.bool
}
