import React from 'react';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';
import Select from 'components/core/Select';
import WordListAsyncSelect from 'components/core/WordListAsyncSelect';
import { getWordListOptionsById, getParentCategoryOptions, getNestedCategoryOptionsByParents } from 'selectors/options';
import { Button, Modal, FormGroup, ControlLabel } from 'react-bootstrap';


class MHTTermBaseSetting extends React.PureComponent {

  componentDidUpdate(prevProps) {

    if (!isEqual(prevProps.file, this.props.file)) {
      this.props.setDefaultWordlistSetting(this.props.wordlistOptions);
    }

    if (
      !isEqual(prevProps.referenceParentCategories, this.props.referenceParentCategories) &&
      this.props.referenceParentCategories.length === 0
    ) {
      this.props.resetFormDataByKey('referenceCategories', []);
    }
  }

  render() {
    const {
      referenceParentCategories,
      referenceCategories,
      referenceWordlists,
      referenceAdditionalWordlists,
      wordlistOptions,
      parentCategoryOptions,
      categoryOptions,
      handleFormArrayChange,
      handleSlide,
    } = this.props;

    return (
      <React.Fragment>
        <Modal.Header closeButton>
          <Modal.Title>Specify Term Base</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>WordList</ControlLabel>
            <Select
              value={referenceWordlists}
              multi={true}
              onChange={value => handleFormArrayChange('referenceWordlists', value)}
              options={wordlistOptions} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Additional WordList</ControlLabel>
            <WordListAsyncSelect
              value={referenceAdditionalWordlists}
              multi={true}
              onChange={value => handleFormArrayChange('referenceAdditionalWordlists', value)} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Category 1</ControlLabel>
            <Select
              value={referenceParentCategories}
              multi={true}
              onChange={value => handleFormArrayChange('referenceParentCategories', value)}
              options={parentCategoryOptions} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Category 2</ControlLabel>
            <Select
              value={referenceCategories}
              multi={true}
              disabled={referenceParentCategories.length === 0}
              onChange={value => handleFormArrayChange('referenceCategories', value)}
              options={categoryOptions} />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pull-left"
            bsStyle="default"
            bsSize="sm"
            onClick={() => handleSlide(1)}
            type="button">
            <FontAwesome name="angle-left" className="mr-5" />Back
          </Button>
          <Button
            bsStyle="primary"
            bsSize="sm"
            onClick={() => handleSlide(3)}
            type="button">
            Next<FontAwesome name="angle-right" className="ml-5" />
          </Button>
        </Modal.Footer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const parentCategories = ownProps.referenceParentCategories;
  return {
    wordlistOptions: getWordListOptionsById(state, ownProps.client),
    parentCategoryOptions: getParentCategoryOptions(state),
    categoryOptions: getNestedCategoryOptionsByParents(state, parentCategories)
  }
}

export default connect(
  mapStateToProps
)(MHTTermBaseSetting);
