import * as types from 'actionTypes/lists';

export const setResult = (name, result) => ({
  type: types.SET_RESULT,
  payload: {
    name,
    result,
  }
});

export const addItem = (name, result) => ({
  type: types.ADD_ITEM,
  payload: {
    name,
    result
  }
})

export const removeItem = (name, result) => ({
  type: types.REMOVE_ITEM,
  payload: {
    name,
    result
  }
})

export const clearResult = (name) => ({
  type: types.CLEAR_RESULT,
  payload: { name }
})


export const setNestedResult = (name, id, result) => ({
  type: types.SET_NESTED_RESULT,
  payload: {
    name,
    id,
    result,
  }
});

export const addNestedItem = (name, id, result) => ({
  type: types.ADD_NESTED_ITEM,
  payload: {
    name,
    id,
    result,
  }
});

export const addNestedItems = (name, id, result) => ({
  type: types.ADD_NESTED_ITEMS,
  payload: {
    name,
    id,
    result,
  }
});

export const removeNestedItem = (name, id, result) => ({
  type: types.REMOVE_NESTED_ITEM,
  payload: {
    name,
    id,
    result,
  }
});


export const setDailyResult = (name, currentDate, result) => ({
  type: types.SET_DAILY_RESULT,
  payload: {
    name,
    currentDate,
    result,
  }
});

export const setMonthlyResult = (name, currentMonth, result) => ({
  type: types.SET_MONTHLY_RESULT,
  payload: {
    name,
    currentMonth,
    result,
  }
});

export const setYearlyResult = (name, currentYear, result) => ({
  type: types.SET_YEARLY_RESULT,
  payload: {
    name,
    currentYear,
    result,
  }
});

export const addYearlyItem = (name, currentYear, result) => ({
  type: types.ADD_YEARLY_ITEM,
  payload: {
    name,
    currentYear,
    result,
  }
});

export const setReportResult = (
  name,
  currentMonth,
  quotationResults,
  quotationTotal,
  countResults,
  countTotal,
  target,
  progress
) => ({
  type: types.SET_REPORT_RESULT,
  payload: {
    name,
    currentMonth,
    quotationResults,
    quotationTotal,
    countResults,
    countTotal,
    target,
    progress
  }
})

export const setAnalysisReportResult = (
  name,
  currentMonth,
  results,
  avgMonthly
) => ({
  type: types.SET_ANALYSIS_REPORT_RESULT,
  payload: {
    name,
    currentMonth,
    results,
    avgMonthly
  }
})

export const setPaginationResult = (name, page, result, totalCount) => ({
  type: types.SET_PAGINATION_RESULT,
  payload: {
    name,
    page,
    result,
    totalCount
  }
});

export const addPaginationItem = (name, result) => ({
  type: types.ADD_PAGINATION_ITEM,
  payload: {
    name,
    result,
  }
});

export const removePaginationItem = (name, result) => ({
  type: types.REMOVE_PAGINATION_ITEM,
  payload: {
    name,
    result,
  }
});

export const clearPaginationResult = (name) => ({
  type: types.CLEAR_PAGINATION_RESULT,
  payload: { name }
});


export const setHits = (name, count) => ({
  type: types.SET_HITS,
  payload: { name, count }
})