import React from "react";
import './taTable.css';

export class TaTableColumn extends React.PureComponent {

  render() {
    const {
      className,
      ...attributes
    } = this.props;

    const baseClass = 'ta-table-column';
    let classNames = [baseClass];
    if (className) {
      classNames.push(className);
    }

    return (
      <div className={classNames.join(' ')} {...attributes}>{this.props.children}</div>
    )
  }

}
