import * as types from 'actionTypes/templates';
import * as utils from 'misc/utils';

// Create ======================================================================
const create = utils.createCreateAction(types.CREATE);
export const createRequest = create.requestFn;
export const createSuccess = create.successFn;
export const createFailure = create.failureFn;

// List ========================================================================
const list = utils.createListAction(types.LIST);
export const listRequest = list.requestFn;
export const listSuccess = list.successFn;
export const listFailure = list.failureFn;

// Retrieve ====================================================================
const retrieve = utils.createRetrieveAction(types.RETRIEVE);
export const retrieveRequest = retrieve.requestFn;
export const retrieveSuccess = retrieve.successFn;
export const retrieveFailure = retrieve.failureFn;

// Update ======================================================================
const update = utils.createUpdateAction(types.UPDATE);
export const updateRequest = update.requestFn;
export const updateSuccess = update.successFn;
export const updateFailure = update.failureFn;

// Remove ======================================================================
const remove = utils.createRemoveAction(types.REMOVE);
export const removeRequest = remove.requestFn;
export const removeSuccess = remove.successFn;
export const removeFailure = remove.failureFn;