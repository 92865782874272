import * as constants from 'misc/constants';

export const getJobScheduelCurrentTab = state => state.tabs[constants.JOB_SCHEDULE];
export const getInvoiceListCurrentTab = state => state.tabs[constants.INVOICE_LIST];
export const getEmployeeJobScheduleCurrentTab = state => state.tabs[constants.EMPLOYEE_JOB_SCHEDULE];
export const getJobToTraListCurrentTab = state => state.tabs[constants.TOTRA_LIST];

export const getUserDetailCurrentTab = (state, id) => {
  const tab = state.tabs[constants.USER_DETAIL][id];
  return tab || 'account';
}

export const getSettingProfileCurrentTab = (state, id) => {
  const tab = state.tabs[constants.SETTINGS_PROFILE][id];
  return tab || 'profile';
}