import React from 'react';
import { Content } from 'reactjs-admin-lte';
import JobDuplicateForm from './JobDuplicateForm';


const JobDuplicate = (props) => {  
  return (
    <Content.Body>
      <JobDuplicateForm {...props} />
    </Content.Body>
  );
}

export default JobDuplicate;