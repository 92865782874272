import * as types from 'actionTypes/auth';

// Login =======================================================================
export const loginRequest = (form, values) => ({
  type: types.LOGIN.REQUEST,
  payload: { form, values }
});
export const loginSuccess = (token) => ({
  type: types.LOGIN.SUCCESS,
  payload: { token }
});
export const loginFailure = (error) => ({
  type: types.LOGIN.FAILURE,
  payload: { error }
});

// Refresh =====================================================================
export const refreshRequest = () => ({
  type: types.REFRESH.REQUEST,
});
export const refreshSuccess = () => ({
  type: types.REFRESH.SUCCESS
});
export const refreshFailure = (error) => ({
  type: types.REFRESH.FAILURE,
  payload: { error }
});

// Password change =============================================================
export const passwordChangeRequest = (values) => ({
  type: types.PASSWORD_CHANGE.REQUEST,
  payload: { values }
});
export const passwordChangeSuccess = () => ({
  type: types.PASSWORD_CHANGE.SUCCESS
});
export const passwordChangeFailure = (error) => ({
  type: types.PASSWORD_CHANGE.FAILURE,
  payload: { error }
});


// Authorize =============================================================
export const authorize = (me) => ({
  type: types.AUTHORIZE,
  payload: { me }
});
export const logout = () => ({
  type: types.LOGOUT
});