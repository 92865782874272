
import axios from 'axios';

const InvoiceApi = {
  create: (values) => {
    return axios.post(`invoices/`, values);
  },
  list: (params) => {
    return axios.get(`invoices/`, { params });
  },
  update: (id, values) => {
    return axios.put(`invoices/${id}/`, values);
  },
  patch: (id, values) => {
    return axios.patch(`invoices/${id}/`, values);
  },
  print: (id) => {
    let printWin = window.open('about:blank');
    return axios({
      url: `invoices/${id}/print/`,
      method: 'GET',
      reponseType: 'document',
    }).then(response => {
      printWin.document.open();
      printWin.document.write(response.data);
      printWin = null;
    }, (error) => {
      printWin.close();
      printWin = null;
    });
  },
}

export default InvoiceApi;
