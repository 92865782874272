import React from 'react';
import HorizontalSelectField from './HorizontalSelectField';


const MultiSelectHirizontalField = (props) => {

  const handleChange = (value) => {
    const valueList = value.split(',')
    const v = valueList.includes('') ? [] : valueList
    return props.input.onChange(v);
  }

  return (
    <HorizontalSelectField
        {...props}
        multi={true}
        onChange={value => handleChange(value)} />
  );
}

export default MultiSelectHirizontalField;