import React from 'react'
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import { JOB_INVOICE_FORM, JOB_INVOICE_MODAL } from 'misc/constants'
import { isJobInvoiceModalLoading } from 'selectors/loading';
import CharField from 'components/core/CharField';
import TextField from 'components/core/TextField';
import CheckboxField from 'components/core/CheckboxField';
import CheckboxGroupField from 'components/core/CheckboxGroupField';
import Spinner from 'components/core/Spinner';
import * as validators from 'misc/validators';
import PureRenderComponent from 'components/core/PureRenderComponent';


class JobInvoiceModal extends PureRenderComponent {

  render() {
    const {
      show,
      title,
      handleHide,
      isLoading,
      memberOptions,
      handleSave,
      handleFinish,
      handleSubmit
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        bsSize="lg"
        backdrop="static">
        <Form
          noValidate
          autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              name="jobs"
              label="Including jobs"
              colSize={6}
              welled={true}
              validate={[
                validators.required,
                validators.minLength1
              ]}
              options={memberOptions}
              isRequired={true}
              component={CheckboxGroupField.Labeled} />
            <Field
              name="invoice"
              label="Invoice"
              type="number"
              validate={[
                validators.required,
                validators.positiveNumber
              ]}
              isRequired={true}
              component={CharField.Labeled} />
            <Field
              name="notes"
              label="Accounting Info"
              component={TextField.Labeled} />
            <Field
              name="seeQuotation"
              label="See quotation"
              component={CheckboxField.Labeled} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pull-left"
              bsSize="sm"
              type="button"
              onClick={handleHide}>
              <FontAwesome name="close" className="mr-5" />Close
            </Button>
            <Button
              className="pull-left"
              bsStyle="primary"
              bsSize="sm"
              type="button"
              onClick={handleSubmit(values => handleSave(values))}>
              <FontAwesome name="save" className="mr-5" />Save
            </Button>
            <Button
              bsStyle="success"
              bsSize="sm"
              type="button"
              onClick={handleSubmit(values => handleFinish(values))}>
              <FontAwesome name="check" className="mr-5" />Job finished
            </Button>
          </Modal.Footer>
          <Spinner isLoading={isLoading} />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: isJobInvoiceModalLoading(state)
  }
}

JobInvoiceModal = reduxForm({ form: JOB_INVOICE_FORM })(JobInvoiceModal);
JobInvoiceModal = connectModal({ name: JOB_INVOICE_MODAL })(JobInvoiceModal);

export default connect(
  mapStateToProps
)(JobInvoiceModal);