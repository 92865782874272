import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getSpecialtiesByUserId } from 'selectors/users';
import {
  specialtyCreateRequest,
  specialtyListRequest,
  specialtyUpdateRequest
} from 'actions/users';
import { isUserSpecialtyLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import UserSpecialty from './presenter';


class UserSpecialtyContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.specialtyListRequest(this.props.userId);
  }

  handleChange = (id, field, value) => {
    const { userId } = this.props;
    const level = value || 0;
    id
      ? this.props.specialtyUpdateRequest(userId, id, { level })
      : this.props.specialtyCreateRequest(userId, { field, level })
  }

  render() {
    return (
      <UserSpecialty
        {...this.props}
        handleChange={this.handleChange} />
    )
  }
}

const mapStateToProps = (state, props) => {
  const id = props.userId;
  return {
    isLoading: isUserSpecialtyLoading(state, id),
    specialties: getSpecialtiesByUserId(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    specialtyCreateRequest,
    specialtyListRequest,
    specialtyUpdateRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSpecialtyContainer)
