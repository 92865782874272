import React from 'react';
import { connect } from 'react-redux';
import Routes from 'components/core/Routes';
import Notifications from 'react-notification-system-redux';
import ConfirmModal from 'components/core/ConfirmModal';
import ServerErrorModal from 'components/core/ServerErrorModal';
import ChangePasswordModal from 'components/auth/ChangePasswordModal';
import UserAvatarUploadModal from 'components/users/UserAvatarUploadButton/UserAvatarUploadModal';
import { MultipleFileUploadModal } from 'components/documents/FileUploadModal';
import FileDownloadProgressModal from 'components/core/FileDownloadProgressModal';
import JobMessageModal from 'components/jobs/common/JobMessageModal';

import 'fullcalendar/dist/fullcalendar.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import 'admin-lte/dist/css/AdminLTE.css';
import 'admin-lte/dist/css/skins/_all-skins.css';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import 'rc-time-picker/assets/index.css';
import 'cropperjs/dist/cropper.css';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-balham.css';
import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';
import  "@kapost/react-component-slider/lib/stylesheets/component-slider.css";
import 'assets/css/employeeReference.css';
import 'assets/css/reset.css';
import 'assets/css/login.css';
import 'assets/css/userSkill.css';
import 'assets/css/userAbout.css';
import 'assets/css/jobQuickEdit.css';
import 'assets/css/jobDetail.css';
import 'assets/css/jobAssignmentFields.css';
import 'assets/css/jobWorkingFlow.css';
import 'assets/css/checkboxGroupField.css';
import 'assets/css/employeeJobFinishModal.css';
import 'assets/css/jobNote.css';
import 'assets/css/jobMessage.css';
import 'assets/css/fileUploadModal.css';
import 'assets/css/timeField.css';
import 'assets/css/splash.css';
import 'assets/css/datePicker.css';
import 'assets/css/dateRangeFormField.css';
import 'assets/css/editorField.css';
import 'assets/css/header.css';
import 'assets/css/listTable.css';
import 'assets/css/selectField.css';
import 'assets/css/slidebar.css';
import 'assets/css/tabs.css';
import 'assets/css/filterBar.css';
import 'assets/css/calendar.css';
import 'assets/css/jobDeliveryDetail.css';
import 'assets/css/style.css';

import 'assets/css/style.css';
import 'components/taUi/style.css';
import TranslationMemorySettingsModal from "components/mht/TranslationMemoryDetail/translationMemorySettingsModal";
import TranslationMemoryImportModal from "components/mht/TranslationMemoryDetail/translationMemoryImportModal";
import TranslationMemoryExportModal from "components/mht/TranslationMemoryDetail/translationMemoryExportModal";
import TranslationMemoryClearModal from "components/mht/TranslationMemoryDetail/translationMemoryClearModal";
import TranslationMemoryAttributesModal from "components/mht/TranslationMemoryDetail/translationMemoryAttributesModal";
import MHTAnalysisModal from 'components/mht/MHTAnalysisModal';
import MHTExitConfirmModal from 'components/mht/MHTExitConfirmModal';

const App = (props) => {
  const { notifications } = props;
  return (
    <div>
      <Routes />
      <Notifications notifications={notifications} />
      <ConfirmModal />
      <ServerErrorModal />
      <JobMessageModal />
      <ChangePasswordModal />
      <MultipleFileUploadModal />
      <FileDownloadProgressModal />
      <UserAvatarUploadModal />

      <TranslationMemorySettingsModal/>
      <TranslationMemoryImportModal/>
      <TranslationMemoryExportModal/>
      <TranslationMemoryClearModal/>
      <TranslationMemoryAttributesModal/>
      <MHTAnalysisModal />
      <MHTExitConfirmModal />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications
  }
}

export default connect(mapStateToProps)(App);
