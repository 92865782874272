import React from 'react';
import { Content, Box } from 'reactjs-admin-lte';
import { Row, Col } from 'react-bootstrap';
import SalesReportFilter from './SalesReportFilter';
import SalesReportTable from './SalesReportTable';
import CountReportTable from './CountReportTable';
import MHTReportTable from './MHTReportTable';


const SalesReport = (props) => {

  const {
    currentMonth,
    handlePrev,
    handleNext,
    quotationResults,
    quotationTotal,
    countResults,
    countTotal,
    target,
    progress,
    analysisResults,
    avgMonthly,
    isSalesReportLoading,
    isAnalysisReportLoading,
    aeOptions
  } = props;

  return (
    <Content.Body>
      <SalesReportFilter
        currentMonth={currentMonth}
        handlePrev={handlePrev}
        handleNext={handleNext} />
      <Row>
        <Col lg={7}>
          <Box
            className="box-widget mb-0"
            loading={isSalesReportLoading}>
            <Box.Body
              noPadding={true}>
              <SalesReportTable
                results={quotationResults}
                total={quotationTotal}
                target={target}
                progress={progress}
                aeOptions={aeOptions} />
            </Box.Body>
          </Box>
        </Col>
        <Col lg={3}>
          <Box
            className="box-widget mb-0"
            loading={isSalesReportLoading}>
            <Box.Body noPadding={true}>
              <CountReportTable
                results={countResults}
                total={countTotal} />
            </Box.Body>
          </Box>
        </Col>
        <Col lg={2}>
          <Box
            className="box-widget mb-0"
            loading={isAnalysisReportLoading}>
            <Box.Body noPadding={true}>
              <MHTReportTable
                results={analysisResults}
                avgMonthly={avgMonthly} />
            </Box.Body>
          </Box>
        </Col>
      </Row>
    </Content.Body>
  )
}

export default SalesReport;
