import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { EMPLOYEE_STATUS_MAP } from 'misc/config';
import { totraCurrentJobsRequest } from 'actions/jobs/employees';
import { isToTraCurrentJobsLoading } from 'selectors/loading';
import { getToTraCurrentJobs } from 'selectors/lists';
import EmployeeCurrentJobsHOC from './EmployeeCurrentJobsHOC';
import EmployeeCurrentJobs from './presenter'



const mapStateToProps = (state) => {
  const roleKey = 'totra';
  return {
    roleKey,
    sentToStatus: EMPLOYEE_STATUS_MAP[roleKey].sent,
    isLoading: isToTraCurrentJobsLoading(state),
    jobs: getToTraCurrentJobs(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest: totraCurrentJobsRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeCurrentJobsHOC(EmployeeCurrentJobs));
