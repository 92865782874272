import { createAsyncActionTypes } from 'misc/utils';

const PREFIX = 'accounting';

export const SALES_TARGET_LIST = createAsyncActionTypes(`${PREFIX}/SALES_TARGET_LIST`);
export const SALES_TARGET_CREATE = createAsyncActionTypes(`${PREFIX}/SALES_TARGET_CREATE`);
export const SALES_TARGET_PATCH = createAsyncActionTypes(`${PREFIX}/SALES_TARGET_PATCH`);

export const SALES_REPORT = createAsyncActionTypes(`${PREFIX}/SALES_REPORT`);

export const SET_SALES_TARGET_RESULT = `${PREFIX}/SET_SALES_TARGET_RESULT `; 