import axios from 'axios';
import { logout } from 'actions/auth';
import { serverError } from 'actions/core';


const configureDefault = (store) => {
  const baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';
  axios.defaults.baseURL = `${baseURL}/api/v1/`;
  axios.defaults.xsrfCookieName = 'csrftoken';
  axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  axios.defaults.headers.common['Authorization'] = `JWT ${localStorage.getItem('token')}`;

  axios.interceptors.response.use(
    (config) => {
      return config;
    },
    (error) => {
      if (error.response.status === 401) {
        store.dispatch(logout());
      } else if (error.response.status === 500) {
        if (error.response.data instanceof Blob) {
            // Handle blob error response
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                try {
                  const jsonData = JSON.parse(reader.result);
                  const message = jsonData.detail;
                  store.dispatch(serverError(message));
                  reject(message);
                } catch (e) {
                  store.dispatch(serverError("server error"));
                  reject("server error");
                }
              };
              reader.onerror = () => {
                store.dispatch(serverError("server error"));
                reject("server error");
              };
              reader.readAsText(error.response.data);
            });
        } else {
          const message = getErrorMessage(error.response);
          store.dispatch(serverError(message));
        }
      }
      return Promise.reject(error);
    }
  )
}

const getErrorMessage = (response) => {
  const message = response.data;
  return typeof message === 'object'
    ? JSON.stringify(message, null, 4)
    : message;
}

export default configureDefault;