import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Form, Button } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { Box } from 'reactjs-admin-lte';
import * as validators from 'misc/validators';
import Callout from 'components/core/Callout';
import CharField from 'components/core/CharField';
import SelectField from 'components/core/SelectField';
import ClientAsyncSelectField from 'components/core/ClientAsyncSelectField';
import { CountrySelectOptionField } from 'components/core/SelectOptionField';
import { AESelectOptionField } from 'components/core/SelectOptionField';
import validate from './validate';

const CustomerCreateForm = (props) => {
  const {
    invalid,
    submitFailed,
    isLoading,
    handleSubmit,
    handleCreate
  } = props;
  
  return (
    <Form
      noValidate
      autoComplete="off">
      <Callout
        invalid={invalid}
        submitFailed={submitFailed} />
      <Box
        className="box-widget"
        loading={isLoading}>
        <Box.Body>
          <Field
            name="username"
            label="Username"
            type="text"
            validate={[
              validators.required,
              validators.maxLength100,
              validators.username
            ]}
            isRequired={true}
            component={CharField.Labeled} />
          <Field
            name="email"
            label="Email"
            type="text"
            isRequired={true}
            validate={[
              validators.required,
              validators.maxLength254,
              validators.email
            ]}
            component={CharField.Labeled} />
          <Field
            name="client"
            label="Client"
            validate={[validators.required]}
            isRequired={true}
            component={ClientAsyncSelectField.Labeled} />
          <CountrySelectOptionField
            isRequired={true}
            validate={[validators.required]} />
          <AESelectOptionField
            isRequired={true}
            validate={[validators.required]} />
          <Field
              name="language"
              label="Language"
              validate={[validators.required]}
              isRequired={true}
              options={[{label: "Japanese", value: "ja-JP"}, {label: "English", value: "en-US"}]}
              component={SelectField.Labeled} />
          <Field
            name="to"
            label="TO"
            type="text"
            validate={[
              validators.maxLength50
            ]}
            component={CharField.Labeled} />
          <Field
            name="password"
            label="Password"
            type="password"
            isRequired={true}
            validate={[
              validators.required,
              validators.maxLength128
            ]}
            component={CharField.Labeled} />
          <Field
            name="passwordConfirm"
            label="Password (again)"
            type="password"
            isRequired={true}
            validate={[
              validators.required,
              validators.maxLength128
            ]}
            component={CharField.Labeled} />
        </Box.Body>
        <Box.Footer>
          <Button
            type="button"
            bsStyle="primary"
            bsSize="sm"
            onClick={handleSubmit(values => handleCreate(values))}>
            <FontAwesome name="paper-plane-o" className="mr-5" />Submit
          </Button>
        </Box.Footer>
      </Box>
    </Form>
  )
}

export default reduxForm({
  validate
})(CustomerCreateForm);