import { createAsyncActionTypes } from 'misc/utils';

const PREFIX = 'documents';

export const SOURCEFILE_UPLOAD = createAsyncActionTypes(`${PREFIX}/SOURCEFILE_UPLOAD`);
export const SOURCEFILE_REMOVE = createAsyncActionTypes(`${PREFIX}/SOURCEFILE_REMOVE`);

export const JOB_SOURCEFILE_UPLOAD = createAsyncActionTypes(`${PREFIX}/JOB_SOURCEFILE_UPLOAD`);
export const JOB_SOURCEFILE_REMOVE = createAsyncActionTypes(`${PREFIX}/JOB_SOURCEFILE_REMOVE`);
export const SOURCEFILE_DOWNLOAD = createAsyncActionTypes(`${PREFIX}/SOURCEFILE_DOWNLOAD`);

export const REFERENCE_UPLOAD = createAsyncActionTypes(`${PREFIX}/REFERENCE_UPLOAD`);
export const REFERENCE_REMOVE = createAsyncActionTypes(`${PREFIX}/REFERENCE_REMOVE`);

export const JOB_REFERENCE_UPLOAD = createAsyncActionTypes(`${PREFIX}/JOB_REFERENCE_UPLOAD`);
export const JOB_REFERENCE_REMOVE = createAsyncActionTypes(`${PREFIX}/JOB_REFERENCE_REMOVE`);
export const REFERENCE_DOWNLOAD = createAsyncActionTypes(`${PREFIX}/REFERENCE_DOWNLOAD`);

export const ASSIGNMENT_PROJECT_UPLOAD = createAsyncActionTypes(`${PREFIX}/ASSIGNMENT_PROJECT_UPLOAD`);
export const ASSIGNMENT_PROJECT_REMOVE = createAsyncActionTypes(`${PREFIX}/ASSIGNMENT_PROJECT_REMOVE`);
export const PROJECT_DOWNLOAD = createAsyncActionTypes(`${PREFIX}/PROJECT_DOWNLOAD`);

export const JOB_COMMON_REFERENCE_UPLOAD = createAsyncActionTypes(`${PREFIX}/JOB_COMMON_REFERENCE_UPLOAD`);
export const JOB_COMMON_REFERENCE_REMOVE = createAsyncActionTypes(`${PREFIX}/JOB_COMMON_REFERENCE_REMOVE`);
export const JOB_COMMON_REFERENCE_DOWNLOAD = createAsyncActionTypes(`${PREFIX}/JOB_COMMON_REFERENCE_DOWNLOAD`);

export const CLIENT_COMMON_REFERENCE_UPLOAD = createAsyncActionTypes(`${PREFIX}/CLIENT_COMMON_REFERENCE_UPLOAD`);
export const CLIENT_COMMON_REFERENCE_UPDATE = createAsyncActionTypes(`${PREFIX}/CLIENT_COMMON_REFERENCE_UPDATE`);
export const CLIENT_COMMON_REFERENCE_USE = createAsyncActionTypes(`${PREFIX}/CLIENT_COMMON_REFERENCE_USE`);
export const CLIENT_COMMON_REFERENCE_REMOVE = createAsyncActionTypes(`${PREFIX}/CLIENT_COMMON_REFERENCE_REMOVE`);
export const CLIENT_COMMON_REFERENCE_DOWNLOAD = createAsyncActionTypes(`${PREFIX}/CLIENT_COMMON_REFERENCE_DOWNLOAD`);


export const TARGETFILE_UPLOAD = createAsyncActionTypes(`${PREFIX}/TARGETFILE_UPLOAD`);
export const TARGETFILE_DOWNLOAD = createAsyncActionTypes(`${PREFIX}/TARGETFILE_DOWNLOAD`);
export const TARGETFILE_REMOVE = createAsyncActionTypes(`${PREFIX}/TARGETFILE_REMOVE`);
export const QUOTE_SOURCEFILE_DOWNLOAD = createAsyncActionTypes(`${PREFIX}/QUOTE_SOURCEFILE_DOWNLOAD`);

export const UNSUPPORTED_FILE_DOWNLOAD = createAsyncActionTypes(`${PREFIX}/UNSUPPORTED_FILE_DOWNLOAD`);