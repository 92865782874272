import React, {useState} from "react";
import {TaButton} from "../../taUi/taButton/taButton";
import TaTableCell from "../../taUi/taTable/taTableCell";
import {TaInputText} from "../../taUi/taInputText";
import {TaTableRow} from "../../taUi/taTable/taTableRow";

function createDefaultItem() {
    return {
        id: 'new_' + Date.now()
    };
}

export function WordListAddRow(
    {
        languages,
        cellWidth,
        onCommit
    }
) {
    const [item, setItem] = useState(createDefaultItem());
    const [isValid, setIsValid] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState({});

    const resetForm = () => {
        setItem(createDefaultItem());
        setErrors({});
        setIsValid(false);
        setIsSubmitted(false);
    }

    const onSubmit = () => {
        setIsSubmitted(true);
        if (isValid && typeof onCommit === 'function') {
            onCommit({...item});
            resetForm();
        }
    }

    const onEvent = (taInputEvent) => {

        const event = taInputEvent.name;
        let _item = Object.assign({}, item);
        let _isValid = true;
        let _errors = {};

        if (event === 'change') {
            _item[taInputEvent.data.property] = taInputEvent.data.value;
        }
        languages.forEach((lang) => {
            if (!(
                typeof _item[lang.key] !== 'undefined' &&
                _item[lang.key].length
            )) {
                _isValid = false;
                _errors[lang.key] = true;
            }
        });

        setErrors(_errors);
        setIsValid(_isValid);
        setItem(_item);

        if (event === 'enter') {
            onSubmit();
        }

    };

    return (languages.length) ? (
        <TaTableRow key={item.id}>
            <TaTableCell width={cellWidth}>&nbsp;</TaTableCell>
            {
                languages.map((language) => {
                    return (
                        <TaTableCell key={language.key} className={'ta-table-cell--border-top'}>
                            <TaInputText
                                name={language.key}
                                placeholder={`Enter ${language.label} Term`}
                                onEvent={(event) => onEvent(event)}
                                value={(item[language.key]) ? item[language.key] : ''}
                                invalid={(
                                    isSubmitted &&
                                    errors[language.key])
                                }
                                clear={true}
                            />
                        </TaTableCell>
                    );
                })
            }
            <TaTableCell width={cellWidth}>
                <TaButton
                    type="button"
                    icon="add"
                    tooltip={'Add New Term'}
                    onClick={() => onEvent({name:'enter'})}
                />
            </TaTableCell>
        </TaTableRow>
    ) : null;
}