import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import VirtualizedSelect from 'react-virtualized-select'
import { autocompleteRequest } from 'actions/clients';
import { getClientOptions } from 'selectors/options';
import { isClientAutoCompleteLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';


class ClientAsyncSelect extends PureRenderComponent {

  handleInputChange = (value) => {
    value && this.props.autocompleteRequest(value);
  }

  render() {

    const {
      value,
      onChange,
      clearable,
      disabled,
      className,
      isLoading,
      style,
      multi,
      placeholder,
      clientOptions
    } = this.props;

    return (
      <VirtualizedSelect
        simpleValue={true}
        clearable={clearable}
        disabled={disabled}
        isLoading={isLoading}
        value={value}
        onChange={onChange}
        options={clientOptions}
        className={className}
        multi={multi}
        placeholder={placeholder}
        style={style}
        onInputChange={(value) => this.handleInputChange(value)}
        filterOption={(option, filterString) => {
          return (
            option.label.toLowerCase().indexOf(filterString) !== -1 ||
            option.fullNameEn.toLowerCase().indexOf(filterString) !== -1 ||
            option.fullNameJp.toLowerCase().indexOf(filterString) !== -1
          )
        }} />
    )
  }
}


const mapStateToProps = (state) => {
  return {
    isLoading: isClientAutoCompleteLoading(state),
    clientOptions: getClientOptions(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    autocompleteRequest,
  }, disptach)
}

ClientAsyncSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientAsyncSelect);


export default ClientAsyncSelect;
