import React from 'react';
import { Box } from 'reactjs-admin-lte';
import DocumentTable from 'components/documents/DocumentTable';


const JobClientReference = (props) => {

  const {
    isLoading,
    files,
    title,
    sizeVisible,
    handleDownload
  } = props;

  return (
    <Box
      className="box-widget"
      loading={isLoading}>
      <Box.Header border={true}>
        <Box.Title>{title}</Box.Title>
        <Box.Tools>
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <DocumentTable
          documents={files}
          sizeVisible={sizeVisible}
          handleDownload={handleDownload} />
      </Box.Body>
    </Box>
  )
}

JobClientReference.defaultProps = {
  title: 'Client Common References'
}

export default JobClientReference;
