import React from 'react';
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import { EMPLOYEE_JOB_REPORT_COUNT_MODAL } from 'misc/constants';
import { EMPLOYEE_STATUS_MAP } from 'misc/config';
import { printRequest, employeeJobStartRequest, allReferenceDownloadRequest } from 'actions/jobs/employees';
import { getJobValueById, getAssignmentsByJobId } from 'selectors/jobs';
import PureRenderComponent from 'components/core/PureRenderComponent';


const EmployeeJobDetailHOC = (WrappedComponent) => {

  return class extends PureRenderComponent {

    componentDidMount() {
      this.props.retrieveRequest(this.props.id);
    }

    handlePrint = () => {
      const id = this.props.job[this.props.roleKey].id
      this.props.printRequest(id);
    }

    showReportCountModal = () => {
      this.props.show(EMPLOYEE_JOB_REPORT_COUNT_MODAL, { id: this.props.id });
    }

    handleJobStart = () => {
      const { id, job, roleKey } = this.props;
      const statuses = EMPLOYEE_STATUS_MAP[roleKey];
      if (job.status === statuses.sent) {
        this.props.employeeJobStartRequest(id, {
          assignment: job[roleKey].id,
          status: statuses.now
        });
      }
    }

    handleDownloadAll = () => {
      this.props.allReferenceDownloadRequest(
        this.props.id,
        this.props.job.number
      );
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handlePrint={this.handlePrint}
          handleJobStart={this.handleJobStart}
          handleDownloadAll={this.handleDownloadAll}
          showReportCountModal={this.showReportCountModal} />
      );
    }
  }
}

const mapStateToProps = (state, props) => {
  const { id, fromHistory, readOnly } = props.params;
  return {
    id,
    readOnly,
    job: getJobValueById(state, id),
    proecessRequired: fromHistory ? false : true,
    assignments: getAssignmentsByJobId(state, id)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    printRequest,
    employeeJobStartRequest,
    allReferenceDownloadRequest
  }, disptach)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  EmployeeJobDetailHOC
);