import React from "react";
import PropTypes from "prop-types";
import './taInputDropdown.css';
import {TaIcon} from "components/taUi/taIcon";

export class TaInputDropdown extends React.PureComponent {

  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        group: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string.isRequired
          })
        )
      })
    ),
    onEvent: PropTypes.func,
    autoFocus: PropTypes.bool,
    isActive: PropTypes.bool,
    icon: PropTypes.string,
    size:PropTypes.number,
  };

  static defaultProps = {
    value: '',
    config: {}
  };

  constructor(props) {
    super(props);
    this.state = {
      hasFocus: false,
      value: ''
    };
    this.inputRef = React.createRef();
  };

  componentDidMount() {
    const props = this.props;
    const hasValue = typeof props.value !== 'undefined';
    const hasDefaultValue = (props.config) ? typeof props.config.defaultValue !== 'undefined' : false;

    if (
      hasValue ||
      hasDefaultValue
    ) {
      this.setState({
        value: (hasValue) ? props.value : hasDefaultValue
      });
    }

    if (this.props.autoFocus) {
      this.inputRef.current.focus();
    }

  }

  focus = () => {
    this.inputRef.current.focus();
  };
  blur = () => {
    this.inputRef.current.blur();
  };

  onInputFocus = () => {
    this.setState({
      hasFocus: true
    });
  };

  onInputBlur = () => {
    this.setState({
      hasFocus: false
    });
  };

  onInputChange = (event) => {
    this.setState(
      {
        value: event.target.value
      },
      () => this.emitEvent('change')
    );
  };

  emitEvent = (eventName) => {
    const props = this.props;
    if (props.onEvent) {
      props.onEvent({
        name: eventName,
        data: {
          property: props.name,
          value: this.state.value
        }
      });
    }
  };

  render() {

    const renderOption = (option) => {
      return <option key={option.value} value={option.value}>{option.label}</option>;
    };

    const renderOptions = (options) => {

      return options.map(item => {


        if (
          item.group
        ) {
          return (
            <optgroup key={item.label} label={item.label}>
              {item.group.map(renderOption)}
            </optgroup>
          );
        } else {
          return renderOption(item);
        }
      });

    };

    const props = this.props;
    const attributes = {};
    if (props.style) {
      attributes.style = props.style;
    }

    // const getVisibleValue = (props) => {
    //
    //   const currentRef = this.inputRef.current;
    //
    //   return (
    //     currentRef &&
    //     currentRef.selectedOptions &&
    //     currentRef.selectedOptions[0]
    //   ) ? currentRef.selectedOptions[0].label : (props.placeholder) ? props.placeholder : 'Select';
    // };

    let baseClass = 'ta-input';

    let classNames = [baseClass, baseClass + '--dropdown'];
    if (props.invalid) {
      classNames.push(baseClass + '--invalid');
    }
    if (props.isActive) {
      classNames.push(baseClass + '--active')
    }
    if (this.state.hasFocus) {
      classNames.push(baseClass + '--focus');
    }
    if (!this.state.value) {
      classNames.push(baseClass + '--empty');
    }
    if (props.size) {
      classNames.push(baseClass + '--dropdown--list')
    }
    if (props.className) {
      classNames.push(props.className);
    }

    return (
      <label className={classNames.join(' ')} {...attributes}>
        {/*<div className={'ta-input__label'}>{getVisibleValue(props)}</div>*/}
        {
          (!!props.icon) &&
          <div className={'ta-input__icon'}>
            <TaIcon name={props.icon}/>
          </div>
        }
        <select
          className={'arw-input__value'}
          id={props.id}
          name={props.name}
          value={this.state.value}
          onChange={this.onInputChange}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          ref={this.inputRef}
          size={props.size}
        >
          {renderOptions(props.options)}
        </select>
        {
          (!props.size) &&
          <div className={'ta-input__icon'}>
            <TaIcon name="expand_more" color={'light'}/>
          </div>
        }
      </label>
    );
  }
}
