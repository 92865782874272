import React from 'react'
import FontAwesome from 'react-fontawesome';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Modal, Form } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import { EMPLOYEE_JOB_REPORT_COUNT_FORM, EMPLOYEE_JOB_REPORT_COUNT_MODAL } from 'misc/constants';
import * as validators from 'misc/validators';
import { reportCountRequest } from 'actions/jobs/employees';
import { isEmployeeJobReportCountModalLoading } from 'selectors/loading';
import CharField from 'components/core/CharField';
import Spinner from 'components/core/Spinner';
import PureRenderComponent from 'components/core/PureRenderComponent';


class EmployeeJobReportCountModal extends PureRenderComponent {
  
  handleReport = (values) => {
    this.props.reportCountRequest(this.props.id, values);
  }

  render() {
    
    const {
      show,
      isLoading,
      handleHide,
      handleSubmit
    } = this.props;
    
    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static"
        bsSize="small">
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(this.handleReport)}>
          <Modal.Header closeButton>
            <Modal.Title>Report Actual Count</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              name="targetCount"
              label="Count"
              type="number"
              validate={[
                validators.required,
                validators.positiveNumber
              ]}
              isRequired={true}
              component={CharField.Labeled} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pull-left"
              bsSize="sm"
              type="button"
              onClick={handleHide}>
              <FontAwesome name="close" className="mr-5" />Close
            </Button>
            <Button
              bsStyle="primary"
              bsSize="sm"
              type="submit">
                <FontAwesome name="paper-plane-o" className="mr-5" />Submit
            </Button>
          </Modal.Footer>
          <Spinner isLoading={isLoading} />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: isEmployeeJobReportCountModalLoading(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    reportCountRequest
  }, disptach)
}

EmployeeJobReportCountModal = connectModal({
  name: EMPLOYEE_JOB_REPORT_COUNT_MODAL
})(EmployeeJobReportCountModal);

EmployeeJobReportCountModal = reduxForm({
  form: EMPLOYEE_JOB_REPORT_COUNT_FORM
})(EmployeeJobReportCountModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeJobReportCountModal);
