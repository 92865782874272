import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TEMPLATE_EDIT_FORM } from 'misc/constants';
import { retrieveRequest } from 'actions/templates';
import { getTemplateById } from 'selectors/templates';
import { getTemplateEditFormInitialValuesById } from 'selectors/forms';
import { isTemplateEditLoading } from 'selectors/loading';
import { updateRequest, removeRequest } from 'actions/templates';
import PureRenderComponent from 'components/core/PureRenderComponent';
import TemplateEdit from './presenter';


class TemplateEditContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.retrieveRequest(this.props.id);
  }
  
  handleUpdate = (values) => {
    this.props.updateRequest(this.props.form, this.props.id, values);
  }

  handleRemove = () => {
    this.props.removeRequest(this.props.template);
  }

  render() {
    return (
      <TemplateEdit
        {...this.props}
        handleUpdate={this.handleUpdate}
        handleRemove={this.handleRemove} />
    );
  }
}


const mapStateToProps = (state, props) => {
  const id = props.params.id;
  const form = `${TEMPLATE_EDIT_FORM}/${id}`
  return {
    id,
    form,
    isLoading: isTemplateEditLoading(state, id),
    template: getTemplateById(state, id),
    initialValues: getTemplateEditFormInitialValuesById(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    retrieveRequest,
    updateRequest,
    removeRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateEditContainer);
