import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { LOGIN_FORM } from 'misc/constants';
import { loginRequest } from 'actions/auth';
import { isAuthenticated } from 'selectors/auth';
import { isLoginLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import Login from './presenter';



class LoginContainer extends PureRenderComponent {

  handleLogin = (values) => {
    this.props.loginRequest(this.props.form, values);
  }

  render () {
    return this.props.isAuthenticated
     ? <Redirect to="/" />
     : <Login
        {...this.props}
        handleLogin={this.handleLogin} />
  }
}

const mapStateToProps = (state) => {
  return {
    form: LOGIN_FORM,
    isAuthenticated: isAuthenticated(state),
    isLoading: isLoginLoading(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    loginRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
