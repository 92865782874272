import React from "react";
import PropTypes from 'prop-types';
import './style.css';

export const TaBadge = (props) => {

    let classNames = ['ta-badge'];
    if (props.className) {
        classNames.push(props.className);
    }
    if (props.size && props.size !== 'default') {
        classNames.push('ta-badge--' + props.size);
    }
    if (props.position) {
        if (props.position !== 'inline') {
            classNames.push('ta-badge--' + props.position);
        }
    } else {
        classNames.push('ta-badge--top-right');
    }

    return (
      <div className={classNames.join(' ')}><span>{props.value}</span></div>
    )
};

TaBadge.propTypes = {
    position: PropTypes.oneOf(['top-right','top-left','center-right','center-left']),
    size: PropTypes.oneOf(['small','default']),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};
