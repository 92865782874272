import React from 'react';
import JobMessageListItem from './JobMessageListItem';

const Empty = (props) => (
  <div
    style={{ backgroundColor: '#f9f9f9' }}
    className="text-center p-10">
    No Rows To Show
  </div>
)


const JobMessageList = (props) => {
  const {
    myUserId,
    messages,
    isCoordinator,
    showMessageEditModal,
    handleRemove,
  } = props;
  
  return messages && messages.length > 0
    ? messages.map(message => (
      <JobMessageListItem
        key={message.id}
        myUserId={myUserId}
        message={message}
        isCoordinator={isCoordinator}
        showMessageEditModal={showMessageEditModal}
        handleRemove={handleRemove} />
    ))
    : <Empty />
}

export default JobMessageList;