import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import { initialize } from 'redux-form';
import {
  jobMessageListRequest,
  jobMessageCreateRequest,
  jobMessageUpdateRequest,
  jobMessageRemoveRequest
} from 'actions/jobs/common';
import { isJobMessageListLoading } from 'selectors/loading';
import { getJobMessagesById } from 'selectors/lists';
import { getMessageReceiverOptions } from 'selectors/options';
import { JOB_MESSAGE_MODAL, JOB_MESSAGE_FORM } from 'misc/constants';
import { MESSAGE_RECEIVER_ROLE_MAP } from 'misc/config';
import { getMyUserId } from 'selectors/auth';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobMessage from './presenter';



class JobMessageContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.jobMessageListRequest(this.props.jobId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.jobId && this.props.jobId !== prevProps.jobId) {
      this.props.jobMessageListRequest(this.props.jobId);
    }
  }

  getInitialRoles = () => {
    const { receiverOptions, roleKey } = this.props;

    switch (roleKey) {
      case 'totra':
      case 'dtp':
      case 'secondDtp':
        return receiverOptions
          .filter(role => (
            role.label === 'Coordinator' ||
            role.label === 'AE' ||
            role.label === 'DTP' ||
            role.label === 'Second DTP' ||
            role.label === 'ToTra'
          )).map(role => role.value);

      case 'finalEditor':
      case 'secondFinalEditor':
        return receiverOptions
          .filter(role => (
            role.label === 'Coordinator' ||
            role.label === 'AE' ||
            role.label === 'Final Editor' ||
            role.label === 'Second Final Editor'
          )).map(role => role.value);

      case 'checker':
        return receiverOptions
          .filter(role => (
            role.label !== 'Translator'
          )).map(role => role.value);

      case 'editor':
        return receiverOptions
          .filter(role => (
            role.label !== 'Translator'
          )).map(role => role.value);

      case 'translator':
        return receiverOptions.map(role => role.value);

      default:
        return receiverOptions
          .filter(role => (
            role.label === 'Coordinator' ||
            role.label === 'AE'
          ))
          .map(role => role.value)
    }
  }

  showMessageAddModal = () => {
    this.props.show(JOB_MESSAGE_MODAL, {
      title: 'Add New Message',
      mode: 'submit',
      isReceiverHidden: this.props.isReceiverHidden, 
      _handleSubmit: (values) => {
        this.props.jobMessageCreateRequest(this.props.jobId, values)
      }
    });
    this.props.initialize(JOB_MESSAGE_FORM, {
      roles: this.getInitialRoles(),
      senderRole: MESSAGE_RECEIVER_ROLE_MAP[this.props.roleKey]
    });
  }

  showMessageEditModal = (message) => {
    this.props.show(JOB_MESSAGE_MODAL, {
      title: 'Edit Message',
      mode: 'update',
      isReceiverHidden: this.props.isReceiverHidden,
      _handleSubmit: (values) => {
        this.props.jobMessageUpdateRequest(this.props.jobId, message.id, values)
      }
    });
    this.props.initialize(JOB_MESSAGE_FORM, {
      body: message.body,
      roles: message.roles.map(role => role.id),
      senderRole: MESSAGE_RECEIVER_ROLE_MAP[this.props.roleKey]
    });
  }

  handleRemove = (message) => {
    this.props.jobMessageRemoveRequest(this.props.jobId, message);
  }

  render() {
    return (
      <JobMessage
        {...this.props}
        showMessageAddModal={this.showMessageAddModal}
        showMessageEditModal={this.showMessageEditModal}
        handleRemove={this.handleRemove} />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.jobId;
  return {
    myUserId: getMyUserId(state),
    isLoading: isJobMessageListLoading(state, id),
    messages: getJobMessagesById(state, id),
    receiverOptions: getMessageReceiverOptions(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    initialize,
    jobMessageListRequest,
    jobMessageCreateRequest,
    jobMessageUpdateRequest,
    jobMessageRemoveRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobMessageContainer);
