import React from 'react';
import { FormGroup } from 'react-bootstrap';
import ReactQuill from 'react-quill';

const ColorLists = [
  '#000000', '#993300', '#333300', '#003366', '#000080', '#333399', '#333333',
  '#800000', '#FF6600', '#808000', '#008080', '#0000FF', '#666699', '#808080',
  '#FF0000', '#FF9900', '#00ff00', '#33CCCC', '#3366FF', '#800080', '#969696',
  '#FF00FF', '#FFCC00', '#FFFF00', '#00FFFF', '#00CCFF', '#993366', '#C0C0C0',
  '#FF99CC', '#FFCC99', '#FFFF99', '#CCFFFF', '#99CCFF', '#CC99FF', '#FFFFFF',
]

const EditorField = (props) => {
  const { invalid, touched } = props.meta
  const hasError = (touched && invalid) ? 'error' : null;
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{ 'color': ColorLists }, { 'background': ColorLists }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link'],
      ['clean']
    ],
  };
  return (
    <FormGroup
      validationState={hasError}
      className="form-editor">
      <ReactQuill
        theme="snow"
        name={props.name}
        modules={modules}
        value={props.input.value}
        onChange={(value) => props.input.onChange(value)}
        onBlur={() => {}} />
    </FormGroup>
  )
}

export default EditorField;
