import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Content, Box } from 'reactjs-admin-lte';
import { Button } from 'react-bootstrap';
import Pagination from 'components/core/Pagination';
import QuoteListFilter from './QuoteListFilter';
import QuoteListTable from './QuoteListTable';


const QuoteList = (props) => {

  const {
    quotes,
    isLoading,
    handleTabOpen,
    currentPage,
    pageSize,
    handlePageSelect,
    handleSelect,
    status,
    statusOptions,
    handleStatusChange,
    handleDelete,
    selected,
  } = props;
  
  return (
    <Content.Body>
      <QuoteListFilter
        status={status}
        statusOptions={statusOptions}
        handleStatusChange={handleStatusChange} />
      <div className="clearfix">
        <Button
          bsStyle="danger"
          bsSize="sm"
          type="button"
          className="mb-10 pull-right"
          disabled={selected.length === 0}
          onClick={handleDelete}>
          <FontAwesome name="times-circle" className="mr-5" />Delete
        </Button>
      </div>
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body
          noPadding={true}>
          <QuoteListTable
            quotes={quotes}
            selected={selected}
            handleSelect={handleSelect}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
        <Box.Footer>
          <Pagination
            currentPage={currentPage}
            totalPages={pageSize}
            onChange={handlePageSelect} />
        </Box.Footer>
      </Box>
    </Content.Body>
  );
}

export default QuoteList;
