import React from 'react';
import { datetimeFormatter } from 'misc/cellFormatters';
import { TRANSLATION_MEMORY_DETAIL } from 'misc/constants';
import {
  BooleanFieldCellRenderer,
  LinkFieldCellRenderer,
  MultipleFieldCellRenderer,
  CheckboxCellRenderer
} from 'misc/cellRenderers';
import PureRenderComponent from 'components/core/PureRenderComponent';
import ListTable from 'components/core/ListTable';


class TranslationMemoryListTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: '',
          field: '',
          suppressFilter: true,
          pinned: 'left',
          width: 30,
          cellRendererFramework: CheckboxCellRenderer,
          cellRendererParams: {
            handleCheck: this.props.handleSelect
          },
        },
        {
          headerName: 'Name',
          field: 'name',
          width: 250,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: TRANSLATION_MEMORY_DETAIL
          },
        },
        {
          headerName: 'Client',
          field: 'client.name',
          width: 250,
        },
        {
          headerName: 'Jobs',
          field: 'jobs',
          width: 350,
          cellRendererFramework: MultipleFieldCellRenderer,
          cellRendererParams: {
            key: 'number'
          },
        },
        {
          headerName: 'Penalty',
          field: 'penaltyOnly',
          cellRendererFramework: BooleanFieldCellRenderer,
          width: 85,
        },
        {
          headerName: 'User',
          field: 'user.username',
          width: 250,
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: datetimeFormatter,
          width: 150,
        },
      ]
    }
  }

  render() {
    return (
      <ListTable
        wrapperHeight="75vh"
        columnDefs={this.state.columnDefs}
        rowData={this.props.memorySearches}
        deltaRowDataMode={true}
        getRowNodeId={data => data.id} />
    );
  }
}

export default TranslationMemoryListTable;
