import * as types from 'actionTypes/settings';

export const setSkin = (skin) => ({
  type: types.SET_SKIN,
  payload: { skin }
})

export const setViewAs = (viewAs) => ({
  type: types.SET_VIEW_AS,
  payload: { viewAs }
})

export const setLocation = (location) => ({
  type: types.SET_LOCATION,
  payload: { location }
})