import { schema } from 'normalizr';

export const sourcefileSchema = new schema.Entity('sourcefiles');
export const referenceSchema = new schema.Entity('references');
export const jobCommonReferenceSchema = new schema.Entity('jobCommonReferences');
export const clientReferenceSchema = new schema.Entity('clientReferences');
export const projectSchema = new schema.Entity('projects');
export const targetfileSchema = new schema.Entity('targetfiles');
export const quoteSourcefileSchema = new schema.Entity('quoteSourcefiles', {
    targetFiles: [targetfileSchema]
});
