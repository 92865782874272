import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { templateSchema } from 'schemas/templates';
import { getEntities, getTemplateEntities } from 'selectors/entities';
import * as coreSelectors from 'selectors/core';

export const getTemplateById = createSelector(
  [coreSelectors.getId, getTemplateEntities],
  (id, entities) => entities[id]
)

export const getTemplateValueById = createSelector(
  [coreSelectors.getId, getEntities],
  (id, entities) => denormalize(id, templateSchema, entities)
)