import React from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import Select from 'components/core/Select';
import FilterBar from 'components/core/FilterBar';


const QuoteListFilter = (props) => {
  
  const {
    status,
    statusOptions,
    handleStatusChange,
  } = props;
  
  return (
    <FilterBar>
      <Nav>
        <NavItem disabled>Status</NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <Select
          value={status}
          clearable={true}
          placeholder=""
          options={statusOptions}
          onChange={value => handleStatusChange(value)}
          style={{ width: 200 }} />
      </Navbar.Form>
    </FilterBar>
  );
}

export default QuoteListFilter;
