import React from 'react';
import { Content, Box } from 'reactjs-admin-lte';
import Pagination from 'components/core/Pagination';
import JobDeliveryListTable from './JobDeliveryListTable';


const JobDeliveryList = (props) => {
  
  const {
    jobs,
    isLoading,
    currentPage,
    pageSize,
    handlePageSelect,
    handleTabOpen
  } = props;
  
  return (
    <Content.Body>
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body noPadding={true}>
          <JobDeliveryListTable
            jobs={jobs}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
        <Box.Footer>
          <Pagination
            currentPage={currentPage}
            totalPages={pageSize}
            onChange={handlePageSelect} />
        </Box.Footer>
      </Box>
    </Content.Body>
  );
}

export default JobDeliveryList;
