import { connect } from 'react-redux';
import { getCategoryOptions } from 'selectors/options';
import SelectOptionField from './SelectOptionField';


const mapStateToProps = (state, props) => {
  return {
    name: 'category',
    label: 'Category',
    // options: getCategoryOptions(state, props.jobType),
    options: getCategoryOptions(state),
  }
}

export default connect(
  mapStateToProps,
)(SelectOptionField);