import orderBy from 'lodash.orderby';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { jobSchema, assignmentSchema } from 'schemas/jobs';
import { getEntities, getJobEntities } from 'selectors/entities';
import * as coreSelectors from 'selectors/core';


export const getJobById = createSelector(
  [coreSelectors.getId, getJobEntities],
  (id, entities) => entities[id]
)

export const getJobValueById = createSelector(
  [coreSelectors.getId, getEntities],
  (id, entities) => denormalize(id, jobSchema, entities)
)


export const getAssignmentsByJobId = createSelector(
  [getJobValueById],
  (job) => {  
    const {
      totra,
      translator,
      editor,
      checker,
      finalEditor,
      secondFinalEditor,
      dtp,
      secondDtp
    } = Object.assign({}, job);
    
    const assignments = [
      totra,
      translator,
      editor,
      checker,
      finalEditor,
      secondFinalEditor,
      dtp,
      secondDtp
    ].filter(assignment => (
      assignment !== null &&
      assignment !== undefined
    ));   
    return orderBy(assignments, ['endDate', 'endTime']);
  }
)


export const getAssignmentValueById = createSelector(
  [coreSelectors.getId, getEntities],
  (id, entities) => denormalize(id, assignmentSchema, entities)
)

export const isAssignmentProcessed = (state) => {
  const modal = state.modal;
  const assignmentId = (
    modal.EmployeeJobFinishModal &&
    modal.EmployeeJobFinishModal.props &&
    modal.EmployeeJobFinishModal.props.assignment
  ) ? modal.EmployeeJobFinishModal.props.assignment.id
    : null;
  return state.jobs.assignmentProcessed[assignmentId]
}

export const getJobScheduleQuickEdit = (state, name) => state.jobs.quickEdit[name];