import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'react-bootstrap';
import { formValueSelector } from 'redux-form';
import { FormControl, InputGroup } from 'react-bootstrap';
import { fetchLatestNumberRequest } from 'actions/jobs/managers';
import LabeledField from 'components/core/LabeledField';
import FontAwesome from 'react-fontawesome';
import PureRenderComponent from 'components/core/PureRenderComponent';



class JobBaseNumberInputField extends PureRenderComponent {

  handleFetch = () => {
    const {
      form,
      type,
      fetchLatestNumberRequest
    } = this.props;
    fetchLatestNumberRequest(form, type);
  }

  render() {

    const {
      input,
      disabled,
      bsSize,
    } = this.props;

    return (
      <InputGroup bsSize={bsSize}>
        <FormControl
          {...input}
          type="text"
          bsSize={bsSize}
          disabled={disabled} />
        <InputGroup.Button>
          <Button
            type="button"
            bsStyle="primary"
            bsSize={bsSize}
            disabled={disabled}
            onClick={this.handleFetch}>
            <FontAwesome name="cloud-download" className="mr-5" />Fetch
          </Button>
        </InputGroup.Button>
      </InputGroup>
    )
  }
}

const LabeledJobBaseNumberInputField = (props) => {
  return (
    <LabeledField {...props}>
      <JobBaseNumberInputField {...props} />
    </LabeledField>
  );
}


const mapStateToProps = (state, props) => {
  const form = props.formName;
  const formSelector = formValueSelector(form);
  const type = formSelector(state, 'type');
  return {
    form,
    type,
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    fetchLatestNumberRequest
  }, disptach)
}


JobBaseNumberInputField = connect(
  mapStateToProps,
  mapDispatchToProps
)(JobBaseNumberInputField)

JobBaseNumberInputField.Labeled = LabeledJobBaseNumberInputField;

export default JobBaseNumberInputField;
