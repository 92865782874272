import moment from 'moment';
import numeral from 'numeral';
import { 
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_MONTH_FORMAT
} from './config';


export const dateFormatter = ({ value }) => {
  return value
    ? moment(value).format(DEFAULT_DATE_FORMAT)
    : null
}

export const datetimeFormatter = ({ value }) => {
  return value
    ? moment(value).format(DEFAULT_DATETIME_FORMAT)
    : null
}

export const monthFormatter = ({ value }) => {
  return value
    ? moment(value).format(DEFAULT_MONTH_FORMAT)
    : null
}

export const currencyFormatter = ({ value }) => {
  return value
    ? numeral(value).format('0,0')
    : 0;
}

export const percentFormatter = ({ value }) => {
  return value
    ? numeral(value).format('0.00%')
    : '0%'
}