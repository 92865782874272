import React from 'react'
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { refreshRequest } from 'actions/auth';
import { isRefreshLoading } from 'selectors/loading';
import { isAuthenticated, isRefreshed, isManager, isEmployee } from 'selectors/auth';
import Splash from 'components/core/Splash';
import PureRenderComponent from 'components/core/PureRenderComponent';


class AuthRoute extends PureRenderComponent {

  componentDidMount() {
    this.props.refreshRequest();
  }

  render() {

    const {
      isAuthenticated,
      isRefreshed,
      isLoading,
      isManager,
      isEmployee,
      children
    } = this.props;

    if (!isAuthenticated && !isRefreshed && !isLoading) {
      return <Redirect to="/login" />
    } else if (isAuthenticated && isRefreshed && !isLoading && (isManager || isEmployee)) {
      return children;
    } else {
      return <Splash />
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    isRefreshed: isRefreshed(state),
    isLoading: isRefreshLoading(state),
    isManager: isManager(state),
    isEmployee: isEmployee(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    refreshRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthRoute)
