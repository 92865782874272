import { createAsyncActionTypes } from 'misc/utils';

const PREFIX = 'mht';

export const RETRIEVE = createAsyncActionTypes(`${PREFIX}/RETRIEVE`);
export const SOURCEFILE_UPLOAD = createAsyncActionTypes(`${PREFIX}/SOURCEFILE_UPLOAD`);
export const LIST_PENDING = createAsyncActionTypes(`${PREFIX}/LIST_PENDING`);
export const COMMIT_PENDING = createAsyncActionTypes(`${PREFIX}/COMMIT_PENDING`);
export const REJECT_PENDING = createAsyncActionTypes(`${PREFIX}/REJECT_PENDING`);

export const UPDATE_ANALYSIS = createAsyncActionTypes(`${PREFIX}/UPDATE_ANALYSIS`);
export const DOWNLOAD_COMMENTS = createAsyncActionTypes(`${PREFIX}/DOWNLOAD_COMMENTS`);
export const DOWNLOAD_TARGET_FILE = createAsyncActionTypes(`${PREFIX}/DOWNLOAD_TARGET_FILE`);
export const DOWNLOAD_BILINGUAL_EXCEL = createAsyncActionTypes(`${PREFIX}/DOWNLOAD_BILINGUAL_EXCEL`);
export const DOWNLOAD_BILINGUAL_TMX = createAsyncActionTypes(`${PREFIX}/DOWNLOAD_BILINGUAL_TMX`);
export const SHOW_PREVIEW = createAsyncActionTypes(`${PREFIX}/SHOW_PREVIEW`);

export const RECOMMIT = createAsyncActionTypes(`${PREFIX}/RECOMMIT`);

export const ANALYSIS_REPORT = createAsyncActionTypes(`${PREFIX}/ANALYSIS_REPORT`);