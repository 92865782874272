import React from 'react';
import { Content } from 'reactjs-admin-lte';
import JobCreateByTemplateForm from './JobCreateByTemplateForm';


const JobCreateByTemplate = (props) => {  
  return (
    <Content.Body>
      <JobCreateByTemplateForm {...props} />
    </Content.Body>
  );
}

export default JobCreateByTemplate;