import * as types from 'actionTypes/calendars';

const initialState = {
  calendar: {
    users: [],
    assignments: [],
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_CALENDAR:
      return setCalendar(state, action);
    default:
      return state;
  }
}

const setCalendar = (state, action) => {
  const { users, assignments, holidays } = action.payload;
  return {
    ...state,
    calendar: {
      ...state.userCalendar,
      users,
      assignments,
      holidays,
    }
  }
}
