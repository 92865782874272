import React from 'react';
import { Content, Box } from 'reactjs-admin-lte';
import Pagination from 'components/core/Pagination';
import TranslationMemorySearchForm from './TranslationMemorySearchForm';
import TranslationMemorySearchTable from './TranslationMemorySearchTable';


const TranslationMemorySearch = (props) => {

  const {
    form,
    service,
    showSaveModal,
    handleReset,
    isLoading,
    handleSearch,
    memories,
    currentPage,
    pageSize,
    handlePageSelect = () => {}
  } = props;

  return (
    <Content.Body>
      <TranslationMemorySearchForm
        form={form}
        service={service}
        showSaveModal={showSaveModal}
        handleReset={handleReset}
        handleSearch={handleSearch} />
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body noPadding={true}>
          <TranslationMemorySearchTable
            memories={memories}
            service={service} />
        </Box.Body>
        <Box.Footer>
          <Pagination
            currentPage={currentPage}
            totalPages={pageSize}
            onChange={handlePageSelect} />
        </Box.Footer>
      </Box>
    </Content.Body>
  )
}

export default TranslationMemorySearch;