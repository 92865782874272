import React from 'react';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Table, Button } from 'react-bootstrap';
import { DEFAULT_DATETIME_FORMAT } from 'misc/config'
import EmptyRow from 'components/core/EmptyRow';


const ClientUserPreferenceTableRow = ({ preference, handleRemove }) => (
  <tr>
    <td>{preference.user.username}</td>
    <td>{moment(preference.created).format(DEFAULT_DATETIME_FORMAT)}</td>
    <td className="text-center">
      <Button
        bsSize="xs"
        bsStyle="danger"
        onClick={() => handleRemove(preference)}>
        <FontAwesome name="trash" />
      </Button>
    </td>
  </tr>
)

const ClientUserPreferenceTable = (props) => {

  const {
    preferences,
    handleRemove
  } = props;

  return (
    <Table
      striped
      hover
      className="table-layout-fixed">
      <thead>
        <tr>
          <th>User</th>
          <th>Created</th>
          <th className="text-center" width="100px">Action</th>
        </tr>
      </thead>
      <tbody>
        { preferences && preferences.length > 0
          ? preferences.map(preference => (
              <ClientUserPreferenceTableRow
                key={preference.id}
                preference={preference}
                handleRemove={handleRemove} />
            ))
          : <EmptyRow colSpan={3} />
        }
      </tbody>
    </Table>
  );
}

export default ClientUserPreferenceTable;
