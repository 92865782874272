import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';
import { CLIENT_WEBSITE_MODAL } from 'misc/constants';
import { websiteListRequest, websiteRemoveRequest, websiteAddRequest } from 'actions/clients';
import { getClientWebsitesById } from 'selectors/lists';
import { isClientWebsiteLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import ClientWebsite from './presenter';


class ClientWebsiteContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.clientId &&
    this.props.websiteListRequest(this.props.clientId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.clientId && this.props.clientId !== prevProps.clientId) {
      this.props.websiteListRequest(this.props.clientId);
    }
  }
  showAddModal = () => {
    this.props.show(CLIENT_WEBSITE_MODAL, {
      handleAddUrl: this.handleAddUrl
    })
  }
  handleAddUrl = (values) => {
    const formData = { ...values, client: this.props.clientId };
    this.props.websiteAddRequest(this.props.clientId, formData);
  }
 
  handleRemove = (website) => {
    this.props.websiteRemoveRequest(website);
  }
  
  render() {
    return (
      <ClientWebsite
        {...this.props}
        showAddModal={this.showAddModal}
        handleRemove={this.handleRemove} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const id = props.clientId;
  return {
    isLoading: isClientWebsiteLoading(state, id),
    websites: getClientWebsitesById(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    websiteListRequest,
    websiteAddRequest,
    websiteRemoveRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientWebsiteContainer)
