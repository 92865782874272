import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getFocusByUserId } from 'selectors/users';
import {
  focusCreateRequest,
  focusListRequest,
  focusUpdateRequest
} from 'actions/users';
import { isUserFocusLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import UserFocus from './presenter';


class UserFocusContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.focusListRequest(this.props.userId);
  }

  handleChange = (id, industry, value) => {
    const { userId } = this.props;
    const level = value || 0;
    id
      ? this.props.focusUpdateRequest(userId, id, { level })
      : this.props.focusCreateRequest(userId, { industry, level })
  }

  render() {
    return (
      <UserFocus
        {...this.props}
        handleChange={this.handleChange} />
    )
  }
}

const mapStateToProps = (state, props) => {
  const id = props.userId;
  return {
    isLoading: isUserFocusLoading(state, id),
    focus: getFocusByUserId(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    focusCreateRequest,
    focusListRequest,
    focusUpdateRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserFocusContainer)
