import React from 'react';
import { Content, Box } from 'reactjs-admin-lte';
import Pagination from 'components/core/Pagination';
import UserListFilter from './UserListFilter';
import UserListTable from './UserListTable';


const UserList = (props) => {
  
  const {
    users,
    isLoading,
    pageSize,
    currentPage,
    keyword,
    handleKeywordChange,
    group,
    handleGroupChange,
    service,
    handleServiceChange,
    groupOptions,
    serviceOptions,
    handlePageSelect,
    handleSubmit,
    handleTabOpen
  } = props;
  
  return (
    <Content.Body>
      <UserListFilter
        keyword={keyword}
        handleKeywordChange={handleKeywordChange}
        group={group}
        groupOptions={groupOptions}
        handleGroupChange={handleGroupChange}
        service={service}
        serviceOptions={serviceOptions}
        handleServiceChange={handleServiceChange}
        handleSubmit={handleSubmit} />
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body noPadding={true}>
          <UserListTable 
            users={users}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
        <Box.Footer>
          <Pagination
            currentPage={currentPage}
            totalPages={pageSize}
            onChange={handlePageSelect} />
        </Box.Footer>
      </Box>
    </Content.Body>
  )
}

export default UserList;