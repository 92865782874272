import React from 'react';
import FontAwesome from 'react-fontawesome';
import classNames from 'classnames';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';


const TooltipIconButton = (props) => {

  const {
    id,
    icon,
    description,
    disabled,
    onClick,
    className
  } = props;

  const cls = classNames({
    btn: true
  }, className)

  const tooltip = (
    <Tooltip id={id}>{description}</Tooltip>
  );

  return (
    <OverlayTrigger placement="top" overlay={tooltip}>
      <button
        className={cls}
        type="button"
        disabled={disabled}
        onClick={onClick}>
        <FontAwesome name={icon} />
      </button>
    </OverlayTrigger>
  );
}

export default TooltipIconButton;