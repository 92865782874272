import React from 'react';
import FontAwesome from 'react-fontawesome';
import { NavItem } from 'react-bootstrap';


const JobDeliveryDetailTab = (props) => {
  const {
    eventKey,
    activeKey,
    onSelect,
    title,
    requireCount,
    count,
    isLoading,
    disabled
  } = props;

  return (
    <NavItem
      disabled={disabled}
      active={eventKey === activeKey}
      eventKey={eventKey}
      onSelect={onSelect}>
      <span>{title}</span>
      { requireCount &&
        <span className="label label-default ml-5">
          { isLoading
            ? <FontAwesome name="refresh" spin />
            : count
          }
        </span>
      }
    </NavItem>
  );
}

export default JobDeliveryDetailTab;
