import React from 'react';
import FontAwesome from 'react-fontawesome';
import { show } from 'redux-modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { USER_AVATAR_UPLOAD_MODAL } from 'misc/constants';
import PureRenderComponent from 'components/core/PureRenderComponent';


class UserAvatarUploadButton extends PureRenderComponent {
    
  handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      this.props.show(USER_AVATAR_UPLOAD_MODAL, {
        src: e.target.result,
        userId: this.props.userId
      });
    }
    fileReader.readAsDataURL(file);
    event.target.value = null;
  }
  
  render() {
    return (
      <label className="btn btn-default btn-block btn-file">
        <FontAwesome name="photo" className="mr-5" />Upload New Picture
        <input
          type="file"
          accept="image/*"
          onChange={this.handleFileChange} />
      </label>
    );
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
  }, disptach)
}

export default connect(
  null,
  mapDispatchToProps
)(UserAvatarUploadButton);
