
export const SETTINGS_PROFILE = 'SettingsProfile';
// Manager =====================================================================
// Calendar
export const CALENDAR_FORM = 'CalendarForm';
export const CALENDAR = 'Calendar';
// Jobs ========================================================================
// Job Schedule ================================================================
export const JOB_SCHEDULE = 'JobSchedule';
export const JOB_DAILY_SCHEDULE = 'JobDailySchedule';
export const JOB_FUTURE_SCHEDULE = 'JobFutureSchedule';
// Job Search ==================================================================
export const JOB_SEARCH = 'JobSearch';
export const JOB_SEARCH_FORM = 'JobSearchForm';
// Job Create ==================================================================
export const JOB_CREATE = 'JobCreate';
export const JOB_CREATE_FORM = 'JobCreateForm';
// Job Duplicate ===============================================================
export const JOB_DUPLICATE = 'JobDuplicate';
export const JOB_DUPLICATE_FORM = 'JobDuplicateForm';
// Job Create By Template ======================================================
export const JOB_CREATE_BY_TEMPLATE = 'JobCreateByTemplate';
export const JOB_CREATE_BY_TEMPLATE_FORM = 'JobCreateByTemplateForm';
// Template List ===============================================================
export const TEMPLATE_LIST = 'TemplateList';
// Template Create =============================================================
export const TEMPLATE_CREATE_MODAL = 'TemplateCreateModal';
export const TEMPLATE_CREATE_FORM = 'TemplateCreateForm';
// Template Detail =============================================================
export const TEMPLATE_DETAIL = 'TemplateDetail';
// Template Edit ===============================================================
export const TEMPLATE_EDIT = 'TemplateEdit';
export const TEMPLATE_EDIT_FORM = 'TemplateEditForm';
// Job Draft List ============================================================
export const JOB_DRAFT_LIST = 'JobDraftList';
// Job Draft Edit ==============================================================
export const JOB_DRAFT_EDIT = 'JobDraftEdit';
export const JOB_DRAFT_EDIT_FORM = 'JobDraftEditForm';
// Job Working List ============================================================
// export const WORKING_LIST = 'JobWorkingList';
// Job Delivery List ===========================================================
export const DELIVERY_LIST = 'JobDeliveryList';
export const DELIVERY_DETAIL = 'JobDeliveryDetail';
// Job Pending List ============================================================
export const PENDING_LIST = 'JobPendingList';
// Job Preparing List ==========================================================
export const PREPARING_LIST = 'JobPreparingList';
// Job ToTra List ==============================================================
export const TOTRA_LIST = 'JobToTraList';
// Job RnQ List ==============================================================
export const RNQ_LIST = 'JobRnQList';
// Job Split ===================================================================
export const JOB_SPLIT = 'JobSplit';
export const JOB_SPLIT_FORM = 'JobSplitForm';
// Job Issue ===================================================================
export const JOB_ISSUE = 'JobIssue';
export const JOB_ISSUE_FORM = 'JobIssueForm';
// Job Split Issue =============================================================
export const JOB_SPLIT_ISSUE = 'JobSplitIssue';
export const JOB_SPLIT_ISSUE_FORM = 'JobSplitIssueForm';
// Job Detail ==================================================================
export const JOB_DETAIL = 'JobDetail';
// Job Edit ====================================================================
export const JOB_EDIT = 'JobEdit';
export const JOB_EDIT_FORM = 'JobEditForm';
// Job Process =================================================================
export const JOB_PROCESS_MODAL = 'JobProcessModal';

// Job Invoice =================================================================
export const JOB_INVOICE_LIST = 'JobInvoiceList';
export const JOB_INVOICE_FORM = 'JobInvoiceForm';
export const JOB_INVOICE_MODAL = 'JobInvoiceModal';

export const JOB_MEMBER_LIST = 'JobMemberList';

export const JOB_NUMBER_AUTO_COMPLETE = 'JobNumberAutoComplete';

// Clients =====================================================================
// Client Create ===============================================================
export const CLIENT_CREATE = 'ClientCreate';
export const CLIENT_CREATE_FORM = 'ClientCreateForm';
// Client List =================================================================
export const CLIENT_LIST = 'ClientList';
// Client Detail ===============================================================
export const CLIENT_DETAIL = 'ClientDetail';
// Client Edit =================================================================
export const CLIENT_EDIT = 'ClientEdit';
export const CLIENT_EDIT_FORM = 'ClientEditForm';
// Client User Preference ======================================================
export const CLIENT_USER_PREFERENCE_MODAL = 'ClinetUserPreferenceModal';
export const CLIENT_USER_PREFERENCE_FORM = 'ClinetUserPreferenceForm';
// Client Preferable User ======================================================
export const CLIENT_PREFERABLE_USER = 'ClientPreferableUser';
// Client NG User ==============================================================
export const CLIENT_NG_USER = 'ClientNgUser';
// Client Common Reference =====================================================
export const CLIENT_COMMON_REFERENCE = 'ClientCommonReference';
// Client Auto Complete ========================================================
export const CLIENT_AUTO_COMPLETE = 'ClientAutoComplete';
// Client Website ==============================================================
export const CLIENT_WEBSITE = "ClientWebsite";
export const CLIENT_WEBSITE_MODAL = 'ClinetWebsiteModal';
export const CLIENT_WEBSITE_FORM = 'ClinetWebsiteForm';

// Users =======================================================================
// User Create =================================================================
export const USER_CREATE = 'UserCreate';
export const USER_CREATE_FORM = 'UserCreateForm';
// User List ==================================================================
export const USER_LIST = 'UserList';
// User Detail =================================================================
export const USER_DETAIL = 'UserDetail';
// User Account ================================================================
export const USER_ACCOUNT = 'UserAccount';
export const USER_ACCOUNT_FORM = 'UserAccountForm';
// User Profile ================================================================
export const USER_PROFILE = 'UserProfile';
export const USER_PROFILE_FORM = 'UserProfileForm';

// User Schedule ===============================================================
export const USER_SCHEDULE = 'UserSchedule';
export const USER_SCHEDULE_FORM = 'UserScheduleForm'

// User Focus ================================================================
export const USER_FOCUS = 'UserFocus';
export const USER_SPECIALTY = 'UserSpecialty';
export const USER_TRANSLATION_SKILL = 'UserTranslationSkill';
export const USER_EDIT_SKILL = 'UserEditSkill';
export const USER_CHECK_SKILL = 'UserCheckSkill';
export const USER_FINAL_EDIT_SKILL = 'UserFinalEditSkill';
export const USER_SECOND_FINAL_EDIT_SKILL = 'UserSecondFinalEditSkill';
export const USER_SOFTWARE = 'UserSoftware';

// User Avatar Upload ==========================================================
export const USER_AVATAR_UPLOAD_MODAL = 'UserAvatarUploadModal';

// Accounting ==================================================================
export const PAYMENT_SEARCH = 'PaymentSearch';
export const PAYMENT_SEARCH_FORM = 'PaymentSearchForm';
export const INVOICE_LIST = 'InvoiceList';
export const SALES_REPORT = 'SalesReport';
export const NUMBER_REPORT = 'NumberReport';
export const SALES_TARGET = 'SalesTarget';


// MHT =========================================================================
export const TERM_SEARCH = 'TermSearch';
export const WORDLIST = 'WordList';
export const WORDLIST_DETAIL = 'WordListDetail';
export const GLOSSARY_LIST = 'GlossaryList';
export const GLOSSARY_DETAIL = 'GlossaryDetail';
export const TRANSLATION_MEMORY_SEARCH = 'TranslationMemorySearch';
export const TRANSLATION_MEMORY_LIST = 'TranslationMemoryList';
export const TRANSLATION_MEMORY_DETAIL = 'TranslationMemoryDetail';

export const TRANSLATION_MEMORY_SEARCH_FORM = 'TranslationMemorySearchForm';
export const TRANSLATION_MEMORY_SEARCH_SAVE_MODAL = 'TranslationMemorySearchSaveModal';
export const TRANSLATION_MEMORY_SEARCH_SAVE_FORM = 'TranslationMemorySearchSaveForm';

export const WORDLIST_UPLOAD_MODAL = 'WordListUploadModal';
export const WORDLIST_UPLOAD_FORM = 'WordListUploadForm';
export const WORDLIST_AUTO_COMPLETE = 'WordListAutoComplete';

export const WORDLIST_DETAIL_SETTINGS_MODAL = 'WordListSettingsModal';
export const WORDLIST_DETAIL_SETTINGS_FORM = 'WordListSettingsForm';
export const WORDLIST_DETAIL_IMPORT_MODAL = 'WordListImportModal';
export const WORDLIST_DETAIL_IMPORT_FORM = 'WordListImportForm';
export const WORDLIST_DETAIL_EXPORT_MODAL = 'WordListExportModal';
export const WORDLIST_DETAIL_EXPORT_FORM = 'WordListExportForm';

export const GLOSSARY_DETAIL_SETTINGS_MODAL = 'GlossarySettingsModal';
export const GLOSSARY_DETAIL_SETTINGS_FORM = 'GlossarySettingsForm';
export const GLOSSARY_DETAIL_IMPORT_MODAL = 'GlossaryImportModal';
export const GLOSSARY_DETAIL_IMPORT_FORM = 'GlossaryImportForm';
export const GLOSSARY_UPLOAD_MODAL = 'GlossaryUploadModal';
export const GLOSSARY_UPLOAD_FORM = 'GlossaryUploadForm';

export const MEMORY_COUNT = 'MemoryCount';
export const MEMORY_SEARCH = 'MemorySearch';
export const MEMORY_SEARCH_LIST = 'MemorySearchList';
export const MHT_EDITOR = 'MHTEditor';
export const MHT_ANALYSIS_MODAL = 'MHTAnalysisModal';
export const MHT_EXIT_CONFIRM_MODAL = 'MHTExitConfirmModal';

export const JOB_QUICK_EDIT = 'JobQuickEdit';
export const JOB_QUICK_EDIT_FORM = 'JobQuickEditForm';

export const MHT_FILE_UPLOAD_MODAL = 'MHTFileUploadModal';
export const MHT_MEMORY_SETTING_MODAL = 'MHTMemorySettingModal';
export const MHT_TERM_SETTING_MODAL = 'MHTTermSettingModal';
export const MHT_SETUP_PROGRESS_MODAL = 'MHTSetupProgressModal';

export const TRANSLATION_MEMORY_UPLOAD_MODAL = 'TranslationMemoryUploadModal';
export const TRANSLATION_MEMORY_UPLOAD_FORM = 'TranslationMemoryUploadForm';

export const TRANSLATION_MEMORY_DETAIL_SETTINGS_MODAL = 'TranslationMemorySettingsModal';
export const TRANSLATION_MEMORY_DETAIL_SETTINGS_FORM = 'TranslationMemorySettingsForm';
export const TRANSLATION_MEMORY_DETAIL_IMPORT_MODAL = 'TranslationMemoryImportModal';
export const TRANSLATION_MEMORY_DETAIL_IMPORT_FORM = 'TranslationMemoryImportForm';
export const TRANSLATION_MEMORY_DETAIL_EXPORT_MODAL = 'TranslationMemoryExportModal';
export const TRANSLATION_MEMORY_DETAIL_EXPORT_FORM = 'TranslationMemoryExportForm';
export const TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL = 'TranslationMemoryAttributesModal';
export const TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_FORM = 'TranslationMemoryAttributesForm';
export const TRANSLATION_MEMORY_DETAIL_CLEAR_MODAL = 'TranslationMemoryClearModal';


export const ANALYSIS_REPORT = 'AnalysisReport';

// Employee Job Schedule =======================================================
export const EMPLOYEE_JOB_SCHEDULE = 'EmployeeJobSchedule';
export const EMPLOYEE_JOB_DAILY_SCHEDULE = 'EmployeeJobDailySchedule';
export const EMPLOYEE_JOB_FUTURE_SCHEDULE = 'EmployeeJobFutureSchedule';


// ToTra =======================================================================
export const TOTRA_CURRENT_JOBS = 'ToTraCurrentJobs';
export const TOTRA_JOB_DETAIL = 'ToTraJobDetail';

// Translator ==================================================================
export const TRANSLATOR_CURRENT_JOBS = 'TranslatorCurrentJobs';
export const TRANSLATOR_JOB_DETAIL = 'TranslatorJobDetail';

// Editor ======================================================================
export const EDITOR_CURRENT_JOBS = 'EditorCurrentJobs';
export const EDITOR_JOB_DETAIL = 'EditorJobDetail';

// Checker =====================================================================
export const CHECKER_CURRENT_JOBS = 'CheckerCurrentJobs';
export const CHECKER_JOB_DETAIL = 'CheckerJobDetail';

// Final Editor ================================================================
export const FINAL_EDITOR_CURRENT_JOBS = 'FinalEditorCurrentJobs';
export const FINAL_EDITOR_JOB_DETAIL = 'FinalEditorJobDetail';

// Second Final Editor =========================================================
export const SECOND_FINAL_EDITOR_CURRENT_JOBS = 'SecondFinalEditorCurrentJobs';
export const SECOND_FINAL_EDITOR_JOB_DETAIL = 'SecondFinalEditorJobDetail';

// DTP =========================================================================
export const DTP_CURRENT_JOBS = 'DtpCurrentJobs';
export const DTP_JOB_DETAIL = 'DtpJobDetail';

// Second DTP ==================================================================
export const SECOND_DTP_CURRENT_JOBS = 'SecondDtpCurrentJobs';
export const SECOND_DTP_JOB_DETAIL = 'SecondDtpJobDetail';

// Employee Job History ========================================================
export const EMPLOYEE_JOB_HISTORY = 'EmployeeJobHistory';
export const EMPLOYEE_JOB_HISTORY_FORM = 'EmployeeJobHistoryForm';

// Employee (Translator) Report Count ==========================================
export const EMPLOYEE_JOB_REPORT_COUNT_MODAL = 'EmployeeJobReportCountModal';
export const EMPLOYEE_JOB_REPORT_COUNT_FORM = 'EmployeeJobReportCountForm';
export const EMPLOYEE_JOB_FINISH_MODAL = 'EmployeeJobFinishModal';
export const EMPLOYEE_MHT_JOB_FINISH_MODAL = 'EmployeeMHTJobFinishModal';

export const EMPLOYEE_ASSIGNMENT_PROJECT_UPLOAD_MODAL = 'EmployeeAssignmentProjectUploadModal'

// Confirm =====================================================================
export const CONFIRM_MODAL = 'ConfirmModal';

// File Upload =================================================================
export const MULTIPLE_FILE_UPLOAD_MODAL = 'MultipleFileUploadModal';
export const SINGLE_FILE_UPLOAD_MODAL = 'SingleFileUploadModal';
export const SINGLE_FILE_UPLOAD_FORM = 'SingleFileUploadForm';
export const FILE_UPDATE_MODAL = 'FileUpdateModal';
export const FILE_UPDATE_FORM = 'FileUpdateForm';

// File Download ===============================================================
export const FILE_DOWNLOAD_PROGRESS_MODAL = 'FileDownloadProgressModal';

// File
export const JOB_SOURCEFILE = 'JobSourceFile';
export const JOB_REFERENCE = 'JobReference';
export const JOB_COMMON_REFERENCE = 'JobCommonReference';
export const JOB_CLIENT_REFERENCE = 'JobClientReference';
export const ASSIGNMENT_PROJECT = 'AssignmentProject';
export const QUOTE_SOURCEFILE = "QuoteSourceFile"

export const JOB_MESSAGE_LIST = 'JobMessageList';
export const JOB_MESSAGE_MODAL = 'JobMessageModal';
export const JOB_MESSAGE_FORM = 'JobMessageForm';

export const MHT_JOB_MESSAGE_MODAL = 'MHTJobMessageModal';
export const MHT_JOB_MESSAGE_FORM = 'MHTJobMessageForm';

export const MHT_SETUP_ERROR_MODAL = 'MHTSetupErrorModal';

export const CALENDAR_FOMR = 'CalendarForm';
export const PROFILE = 'Profile';
export const SERVER_ERROR_MODAL = 'ServerErrorModal';
// Login =======================================================================
export const LOGIN = 'Login';
export const LOGIN_FORM = 'LoginForm';

// Refresh =====================================================================
export const REFRESH = 'Refresh'

// Password Change =============================================================
export const PASSWORD_CHANGE_MODAL = 'PasswordChangeModal';
export const PASSWORD_CHANGE_FORM = 'PasswordChangeForm';

// Customers ===================================================================
// Customer Create =============================================================
export const CUSTOMER_CREATE = 'CustomerCreate';
export const CUSTOMER_CREATE_FORM = 'CustomerCreateForm';
export const CUSTOMER_LIST = 'CustomerList';
export const CUSTOMER_DETAIL = "CustomerDetail";
export const CUSTOMER_EDIT_FORM = 'CustomerEditForm';

// Quotes =====================================================================
export const QUOTE_LIST = "QuoteList";
export const QUOTE_DETAIL = "QuoteDetail";

export const UNSUPPORTED_ORDER_LIST = "UnsupportedOrderList";
export const UNSUPPORTED_ORDER_DETAIL = "UnsupportedOrderDetail";

export const MHT_JOB_LIST = "MHTJobList";
export const MHT_JOB_DETAIL = "MHTJobDetail";
export const MHT_JOB_MESSAGE = "MHTJobMessage";
export const MHT_INVOICE_DOWNLOAD_MODAL = "MHTInvoiceDownloadModal";
export const MHT_INVOICE_DOWNLOAD_FORM = "MHTInvoiceDownloadForm";