import * as constants from 'misc/constants';

export const isConfirmModalLoading = state => state.loading[constants.CONFIRM_MODAL];

// auth ========================================================================
export const isRefreshLoading = state => state.loading[constants.REFRESH];
export const isLoginLoading = state => state.loading[constants.LOGIN];
export const isChangePasswordModalLoading = state => state.loading[constants.PASSWORD_CHANGE_MODAL];

// File Upload =================================================================
export const isMultipleFileUploadModalLoading = state => state.loading[constants.MULTIPLE_FILE_UPLOAD_MODAL];
export const isSingleFileUploadModalLoading = state => state.loading[constants.SINGLE_FILE_UPLOAD_MODAL];
export const isFileUpdateModalLoading = state => state.loading[constants.FILE_UPDATE_MODAL];

// users =======================================================================
export const isUserListLoading = state => state.loading[constants.USER_LIST];
export const isUserCreateLoading = state => state.loading[constants.USER_CREATE];
export const isUserAvatarUploadModalLoading = state => state.loading[constants.USER_AVATAR_UPLOAD_MODAL];
export const isUserDetailLoading = (state, id) => state.loading[`${constants.USER_DETAIL}/${id}`]
export const isUserProfileLoading = (state, id) => state.loading[`${constants.USER_PROFILE}/${id}`]
export const isUserAccountLoading = (state, id) => (
  state.loading[`${constants.USER_ACCOUNT}/${id}`] ||
  state.loading[`${constants.USER_DETAIL}/${id}`]
)
export const isUserFocusLoading = (state, id) => state.loading[`${constants.USER_FOCUS}/${id}`];
export const isUserSpecialtyLoading = (state, id) => state.loading[`${constants.USER_SPECIALTY}/${id}`];
export const isUserScheduleLoading = (state, id) => state.loading[`${constants.USER_SCHEDULE}/${id}`];
export const isUserSoftwareLoading = (state, id) => state.loading[`${constants.USER_SOFTWARE}/${id}`];

export const isUserTranslationSkillLoading = (state, id) =>  state.loading[`${constants.USER_TRANSLATION_SKILL}/${id}`];
export const isUserEditSkillLoading = (state, id) =>  state.loading[`${constants.USER_EDIT_SKILL}/${id}`];
export const isUserCheckSkillLoading = (state, id) =>  state.loading[`${constants.USER_CHECK_SKILL}/${id}`];
export const isUserFinalEditSkillLoading = (state, id) =>  state.loading[`${constants.USER_FINAL_EDIT_SKILL}/${id}`];
export const isUserSecondFinalEditSkillLoading = (state, id) =>  state.loading[`${constants.USER_SECOND_FINAL_EDIT_SKILL}/${id}`];


// clients =====================================================================
export const isClientCreateLoading = state => state.loading[constants.CLIENT_CREATE];
export const isClientListLoading = state => state.loading[constants.CLIENT_LIST];
export const isClientAutoCompleteLoading = state => state.loading[constants.CLIENT_AUTO_COMPLETE];
export const isClientUserPreferenceModalLoading = state => state.loading[constants.CLIENT_USER_PREFERENCE_MODAL];
export const isClientPreferableUserLoading = (state, id) => state.loading[`${constants.CLIENT_PREFERABLE_USER}/${id}`];
export const isClientNgUserLoading = (state, id) => state.loading[`${constants.CLIENT_NG_USER}/${id}`];
export const isClientCommonReferenceLoading = (state, id) => state.loading[`${constants.CLIENT_COMMON_REFERENCE}/${id}`];
export const isClientWebsiteLoading = (state, id) => state.loading[`${constants.CLIENT_WEBSITE}/${id}`];
export const isClientWebsiteModalLoading = state => state.loading[constants.CLIENT_WEBSITE_MODAL]
export const isClientEditLoading = (state, id) => (
  state.loading[`${constants.CLIENT_EDIT}/${id}`] ||
  state.loading[`${constants.CLIENT_DETAIL}/${id}`]
)

// Employee ====================================================================
export const isEmployeeJobDailyScheduleLoading = state => state.loading[constants.EMPLOYEE_JOB_DAILY_SCHEDULE];
export const isEmployeeJobFutureScheduleLoading = state => state.loading[constants.EMPLOYEE_JOB_FUTURE_SCHEDULE];

// Employee Current Jobs =======================================================
export const isToTraCurrentJobsLoading = state => state.loading[constants.TOTRA_CURRENT_JOBS];
export const isTranslatorCurrentJobsLoading = state => state.loading[constants.TRANSLATOR_CURRENT_JOBS];
export const isEditorCurrentJobsLoading = state => state.loading[constants.EDITOR_CURRENT_JOBS];
export const isCheckerCurrentJobsLoading = state => state.loading[constants.CHECKER_CURRENT_JOBS];
export const isFinalEditorCurrentJobsLoading = state => state.loading[constants.FINAL_EDITOR_CURRENT_JOBS];
export const isSecondFinalEditorCurrentJobsLoading = state => state.loading[constants.SECOND_FINAL_EDITOR_CURRENT_JOBS];
export const isDtpCurrentJobsLoading = state => state.loading[constants.DTP_CURRENT_JOBS];
export const isSecondDtpCurrentJobsLoading = state => state.loading[constants.SECOND_DTP_CURRENT_JOBS];

// Employee Job Detail =========================================================
export const isToTraJobDetailLoading = (state, id) => state.loading[`${constants.TOTRA_JOB_DETAIL}/${id}`];
export const isTranslatorJobDetailLoading = (state, id) => state.loading[`${constants.TRANSLATOR_JOB_DETAIL}/${id}`];
export const isEditorJobDetailLoading = (state, id) => state.loading[`${constants.EDITOR_JOB_DETAIL}/${id}`];
export const isCheckerJobDetailLoading = (state, id) => state.loading[`${constants.CHECKER_JOB_DETAIL}/${id}`];
export const isFinalEditorJobDetailLoading = (state, id) => state.loading[`${constants.FINAL_EDITOR_JOB_DETAIL}/${id}`];
export const isSecondFinalEditorJobDetailLoading = (state, id) => state.loading[`${constants.SECOND_FINAL_EDITOR_JOB_DETAIL}/${id}`];
export const isDtpJobDetailLoading = (state, id) => state.loading[`${constants.DTP_JOB_DETAIL}/${id}`];
export const isSecondDtpJobDetailLoading = (state, id) => state.loading[`${constants.SECOND_DTP_JOB_DETAIL}/${id}`];

export const isEmployeeJobReportCountModalLoading = state => state.loading[constants.EMPLOYEE_JOB_REPORT_COUNT_MODAL];
export const isEmployeeJobFinishModalLoading = state => state.loading[constants.EMPLOYEE_JOB_FINISH_MODAL];
export const isJobHistoryLoading = state => state.loading[constants.EMPLOYEE_JOB_HISTORY];

// Manager =====================================================================
export const isDailyScheduleLoading = state => state.loading[constants.JOB_DAILY_SCHEDULE];
export const isFutureScheduleLoading = state => state.loading[constants.JOB_FUTURE_SCHEDULE];
export const isJobSearchLoading = state => state.loading[constants.JOB_SEARCH];
// export const isWorkingListLoading = state => state.loading[constants.WORKING_LIST];
export const isDraftListLoading = state => state.loading[constants.JOB_DRAFT_LIST];
export const isDeliveryListLoading = state => state.loading[constants.DELIVERY_LIST];
export const isPendingListLoading = state => state.loading[constants.PENDING_LIST];
export const isPreparingListLoading = state => state.loading[constants.PREPARING_LIST];
export const isToTraListLoading = state => state.loading[constants.TOTRA_LIST];
export const isRnQListLoading = state => state.loading[constants.RNQ_LIST];

export const isSalesTargetLoading = state => state.loading[constants.SALES_TARGET];
export const isSalesReportLoading = state => state.loading[constants.SALES_REPORT];
export const isNumberReportLoading = state => state.loading[constants.NUMBER_REPORT];

export const isJobMemberListLoading = (state, id) => state.loading[`${constants.JOB_MEMBER_LIST}/${id}`]

export const isJobDetailLoading = (state, id) => state.loading[`${constants.JOB_DETAIL}/${id}`];
export const isJobSourcefileListLoading = (state, id) => state.loading[`${constants.JOB_SOURCEFILE}/${id}`];
export const isJobReferenceListLoading = (state, id) => state.loading[`${constants.JOB_REFERENCE}/${id}`];
export const isJobClientReferenceListLoading = (state, id) => state.loading[`${constants.JOB_CLIENT_REFERENCE}/${id}`];
export const isJobCommonReferenceListLoading = (state, group) => state.loading[`${constants.JOB_COMMON_REFERENCE}/${group}`];
export const isJobMessageListLoading = (state, id) => state.loading[`${constants.JOB_MESSAGE_LIST}/${id}`];
export const isJobMessageLoading = state => state.loading[`${constants.JOB_MESSAGE_MODAL}`];

export const isJobInvoiceListLoading = (state, id) => state.loading[`${constants.JOB_INVOICE_LIST}/${id}`];

export const isJobProcessModalLoading = state => state.loading[`${constants.JOB_PROCESS_MODAL}`];
export const isJobInvoiceModalLoading = state => state.loading[`${constants.JOB_INVOICE_MODAL}`];

export const isJobCreateLoading = state => state.loading[constants.JOB_CREATE];

export const isJobDuplicateLoading = (state, id) => (
  state.loading[`${constants.JOB_DUPLICATE}/${id}`] ||
  state.loading[`${constants.JOB_DETAIL}/${id}`]
)
export const isJobCreateByTemplateLoading = (state, id) => (
  state.loading[`${constants.JOB_CREATE_BY_TEMPLATE}/${id}`] ||
  state.loading[`${constants.TEMPLATE_DETAIL}/${id}`]
)
export const isJobSplitLoading = (state, id) => (
  state.loading[`${constants.JOB_SPLIT}/${id}`] ||
  state.loading[`${constants.JOB_DETAIL}/${id}`]
)
export const isJobIssueLoading = (state, id) => (
  state.loading[`${constants.JOB_ISSUE}/${id}`] ||
  state.loading[`${constants.JOB_DETAIL}/${id}`]
)
export const isJobSplitIssueLoading = (state, id) => (
  state.loading[`${constants.JOB_SPLIT_ISSUE}/${id}`] ||
  state.loading[`${constants.JOB_DETAIL}/${id}`]
)
export const isJobDraftEditLoading = (state, id) => (
  state.loading[`${constants.JOB_DRAFT_EDIT}/${id}`] ||
  state.loading[`${constants.JOB_DETAIL}/${id}`]
)
export const isJobEditLoading = (state, id) => (
  state.loading[`${constants.JOB_EDIT}/${id}`] ||
  state.loading[`${constants.JOB_DETAIL}/${id}`]
)

export const isJobQuickEditLoading = (state, form) => state.loading[`${form}`]

export const isPaymentSearchLoading = state => state.loading[constants.PAYMENT_SEARCH];
export const isAssignmentSuggestLoading = (state, form) => state.loading[form];

export const isJobNumberAutoCompleteLoading = state => state.loading[constants.JOB_NUMBER_AUTO_COMPLETE];

// Templates ===================================================================
export const isTemplateListLoading = state => state.loading[constants.TEMPLATE_LIST];
export const isTemplateCreateModalLoading = state => state.loading[constants.TEMPLATE_CREATE_MODAL];
export const isTemplateEditLoading = (state, id) => (
  state.loading[`${constants.TEMPLATE_DETAIL}/${id}`] ||
  state.loading[`${constants.TEMPLATE_EDIT}/${id}`]
)

// Invoices ====================================================================
export const isInvoiceListLoading = state => state.loading[constants.INVOICE_LIST];

// MHT =========================================================================
export const isAnalysisReportLoading = state => state.loading[constants.ANALYSIS_REPORT];

export const isWordListLoading = state => state.loading[constants.WORDLIST];
export const isWordListUploadModalLoading = state => state.loading[constants.WORDLIST_UPLOAD_MODAL];
export const isWordListAutoCompleteLoading = state => state.loading[constants.WORDLIST_AUTO_COMPLETE];
export const isWordListDetailSettingsModalLoading = state => state.loading[constants.WORDLIST_DETAIL_SETTINGS_MODAL];
export const isWordListDetailImportModalLoading = state => state.loading[constants.WORDLIST_DETAIL_IMPORT_MODAL];
export const isWordListDetailExportModalLoading = state => state.loading[constants.WORDLIST_DETAIL_EXPORT_MODAL];
export const isWordListDetailAttributesModalLoading = state => state.loading[constants.TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL];



export const isTermSearchLoading = state => state.loading[constants.TERM_SEARCH];
export const isGlossaryListLoading = state => state.loading[constants.GLOSSARY_LIST];
export const isGlossaryUploadLoading = state => state.loading[constants.GLOSSARY_UPLOAD_MODAL];
export const isGlossaryDetailSettingsModalLoading = state => state.loading[constants.GLOSSARY_DETAIL_SETTINGS_MODAL];
export const isMemorySearchListLoading = state => state.loading[constants.MEMORY_SEARCH_LIST];
export const isMemorySearchLoading = state => state.loading[constants.MEMORY_SEARCH]
export const isMemorySearchSaveModalLoading = state => state.loading[constants.TRANSLATION_MEMORY_SEARCH_SAVE_MODAL];

export const isWordListDetailEditModalLoading = state => state.loading[constants.MEMORY_COUNT]
export const isTranslationMemoryUploadModalLoading = state => state.loading[constants.TRANSLATION_MEMORY_UPLOAD_MODAL]

export const isTranslationMemorySettingsModalLoading = state => state.loading[constants.TRANSLATION_MEMORY_DETAIL_SETTINGS_MODAL]
export const isTranslationMemoryClearModalLoading = state => state.loading[constants.TRANSLATION_MEMORY_DETAIL_CLEAR_MODAL]
export const isEmployeeAssignmentProjectUploadModalLoading = state => state.loading[constants.EMPLOYEE_ASSIGNMENT_PROJECT_UPLOAD_MODAL]


// Calendar ====================================================================
export const isCalendarLoading = state => state.loading[constants.CALENDAR];

// customers ===================================================================
export const isCustomerCreateLoading = state => state.loading[constants.CUSTOMER_CREATE];
export const isCustomerListLoading = state => state.loading[constants.CUSTOMER_LIST];

export const isQuoteListLoading = state => state.loading[constants.QUOTE_LIST];
export const isQuoteRetrieveLoading = (state, id) => state.loading[`${constants.QUOTE_DETAIL}/${id}`];

export const isUnsupportedOrderListLoading = state => state.loading[constants.UNSUPPORTED_ORDER_LIST];
export const isUnsupportedOrderRetrieveLoading = (state, id) => state.loading[`${constants.UNSUPPORTED_ORDER_DETAIL}/${id}`];

export const isMHTJobListLoading = state => state.loading[constants.MHT_JOB_LIST];
export const isMHTJobRetrieveLoading = (state, id) => state.loading[`${constants.MHT_JOB_DETAIL}/${id}`];
export const isMHTJobMessageListLoading = (state, id) => state.loading[`${constants.MHT_JOB_MESSAGE}/${id}`];