import React from 'react';
import { Content } from 'reactjs-admin-lte';
import JobSplitForm from './JobSplitForm';


const JobSplit = (props) => {  
  return (
    <Content.Body>
      <JobSplitForm {...props} />
    </Content.Body>
  );
}

export default JobSplit;