import React from 'react';
import { Box } from 'reactjs-admin-lte';
import TooltipIconButton from 'components/core/TooltipIconButton';
import JobMessageList from './JobMessageList';

const JobMessage = (props) => {
  
  const {
    isLoading,
    messages,
    myUserId,
    isCoordinator,
    showMessageAddModal,
    showMessageEditModal,
    handleRemove
  } = props;
  
  return (
    <Box
      className="box-widget"
      loading={isLoading}>
      <Box.Header border={true}>
        <Box.Title>Messages</Box.Title>
        <Box.Tools>
          <TooltipIconButton
            id="add-new-message"
            className="btn-box-tool"
            icon="envelope-o"
            description="Add New Message"
            onClick={showMessageAddModal} />
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <JobMessageList
          messages={messages}
          myUserId={myUserId}
          isCoordinator={isCoordinator}
          showMessageEditModal={showMessageEditModal}
          handleRemove={handleRemove} />
      </Box.Body>
    </Box>
  );
}

export default JobMessage;