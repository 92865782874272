import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CLIENT_CREATE_FORM } from 'misc/constants';
import { createRequest } from 'actions/clients';
import { isClientCreateLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import ClientCreate from './presenter';


class ClientCreateContainer extends PureRenderComponent {
  
  handleCreate = (values) => {
    this.props.createRequest(this.props.form, values);
  }

  render() {
    return (
      <ClientCreate
        {...this.props}
        handleCreate={this.handleCreate} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: CLIENT_CREATE_FORM,
    isLoading: isClientCreateLoading(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    createRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientCreateContainer);

