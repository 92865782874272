import disableClipboard from "components/mht/MHTEditor/ckEditorConfig/plugins/disableClipboard";
import disableInput from "components/mht/MHTEditor/ckEditorConfig/plugins/disableInput";

function disableEnter(editor) {
  editor.editing.view.document.on('enter', (event, data) => {
    data.preventDefault();
    event.stop();
  });
}

export const ckEditorConfigSource = {
  extraPlugins: [
    disableInput,
    disableEnter,
    disableClipboard
  ],
  toolbar: []
};
