import * as types from 'actionTypes/quotes';
import * as utils from 'misc/utils';

export const list = utils.createSearchAction(types.LIST);
export const listRequest = list.requestFn;
export const listSuccess = list.successFn;
export const listFailure = list.failureFn;

// Retrieve ====================================================================
const retrieve = utils.createRetrieveAction(types.RETRIEVE);
export const retrieveRequest = retrieve.requestFn;
export const retrieveSuccess = retrieve.successFn;
export const retrieveFailure = retrieve.failureFn;

// Batch Delete ================================================================
export const batchDeleteRequest = (values) => ({
  type: types.BATCH_DELETE.REQUEST,
  payload: { values }
});
export const batchDeleteSuccess = (values) => ({
  type: types.BATCH_DELETE.SUCCESS,
  payload: { values }
});
  
export const batchDeleteFailure = (error) => ({
  type: types.BATCH_DELETE.FAILURE,
  payload: { error }
});

export const unsupportedOrderList = utils.createSearchAction(types.UNSUPPORTED_ORDER_LIST);
export const unsupportedOrderListRequest = unsupportedOrderList.requestFn;
export const unsupportedOrderListSuccess = unsupportedOrderList.successFn;
export const unsupportedOrderListFailure = unsupportedOrderList.failureFn;


export const unsupportedOrderRetrieve = utils.createRetrieveAction(types.UNSUPPORTED_ORDER_RETRIEVE);
export const unsupportedOrderRetrieveRequest = unsupportedOrderRetrieve.requestFn;
export const unsupportedOrderRetrieveSuccess = unsupportedOrderRetrieve.successFn;
export const unsupportedOrderRetrieveFailure = unsupportedOrderRetrieve.failureFn;