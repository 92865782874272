import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { unsupportedFileDownloadRequest } from 'actions/documents';
import { unsupportedOrderRetrieveRequest } from 'actions/quotes';
import { getUnsupportedOrderValueById } from 'selectors/quotes';
import { isUnsupportedOrderRetrieveLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import UnsupportedOrderDetail from './presenter';


class UnsupportedOrderDetailContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.unsupportedOrderRetrieveRequest(this.props.id);
  }

  handleDownload = (e, file) => {
    console.log(file);
    e.preventDefault()
    this.props.unsupportedFileDownloadRequest(file)
  }

  render() {
    return (
      <UnsupportedOrderDetail
        {...this.props}
        handleDownload={this.handleDownload}
       />
    );
  }
}

const mapStateToProps = (state, props) => {
  const id = props.params.id;
  return {
    id,
    order: getUnsupportedOrderValueById(state, id),
    isLoading: isUnsupportedOrderRetrieveLoading(state, id),
  }
}
  
const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    unsupportedOrderRetrieveRequest,
    unsupportedFileDownloadRequest
  }, disptach)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnsupportedOrderDetailContainer);