import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'components/core/Select';
import { JOB_DETAIL } from 'misc/constants';
import { memberListRequest } from 'actions/jobs/managers';
import { isJobMemberListLoading } from 'selectors/loading';
import { getMemberOptionsByJobId } from 'selectors/options';
import { getDetailTabConfigByName } from 'misc/utils';
import PureRenderComponent from 'components/core/PureRenderComponent';


class JobDetailMemberSelect extends PureRenderComponent {

  componentDidMount() {
    this.props.memberListRequest(this.props.id);
  }

  handleMemberSelect = (member) => {
    const { label, value } = member;
    if (value === this.props.id) return;
    const tabConfig = getDetailTabConfigByName(
      JOB_DETAIL,
      value,
      label,
      { id: value }
    );
    this.props.handleTabOpen(tabConfig)
  }

  render() {

    const {
      isLoading,
      memberOptions,
    } = this.props;

    return (
      <Select
        options={memberOptions}
        value={this.props.id}
        onChange={this.handleMemberSelect}
        isLoading={isLoading}
        simpleValue={false}
        clearable={false}
        style={{ width: 150 }} />
    )
  }
}

const mapStateToProps = (state, props) => {
  const id = props.jobId;
  return {
    id,
    isLoading: isJobMemberListLoading(state, id),
    memberOptions: getMemberOptionsByJobId(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    memberListRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobDetailMemberSelect);