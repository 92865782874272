import { schema } from 'normalizr';
import { categorySchema, parentCategorySchema } from './core';
import { sourcefileSchema } from './documents';
import { wordlistSchema } from './wordlists';
import { clientSchema } from './clients';
import { jobSchema } from './jobs';

export const mhtSchema = new schema.Entity('mht', {
  document: sourcefileSchema,
  referenceParentCategories: [parentCategorySchema],
  referenceCategories: [categorySchema],
  referenceWordlists: [wordlistSchema],
  referenceAdditionalWordlists: [wordlistSchema],
  referenceJobs: [jobSchema],
  referenceClients: [clientSchema],
});

export const analysisSchema = new schema.Entity('analysis');