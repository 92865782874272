import React from 'react';
import { connect } from 'react-redux';
import { Tab } from 'react-bootstrap';
import { Layout, Content } from 'reactjs-admin-lte';
import * as constants from 'misc/constants';
import * as authSelectors from 'selectors/auth';

import { EmployeeSidebar } from 'components/core/Sidebar';
import Header from 'components/core/Header';
import GlobalTabs from 'components/core/GlobalTabs';

import EmployeeJobFinishModal from 'components/jobs/employees/EmployeeJobFinishModal';
import EmployeeMHTJobFinishModal from 'components/jobs/employees/EmployeeMHTJobFinishModal';
import EmployeeJobReportCountModal from 'components/jobs/employees/EmployeeJobReportCountModal';

import EmployeeJobSchedule from 'components/jobs/employees/EmployeeJobSchedule';
import ToTraCurrentJobs from 'components/jobs/employees/EmployeeCurrentJobs/ToTraCurrentJobs';
import ToTraJobDetail from 'components/jobs/employees/EmployeeJobDetail/ToTraJobDetail';
import TranslatorCurrentJobs from 'components/jobs/employees/EmployeeCurrentJobs/TranslatorCurrentJobs';
import TranslatorJobDetail from 'components/jobs/employees/EmployeeJobDetail/TranslatorJobDetail';
import EditorCurrentJobs from 'components/jobs/employees/EmployeeCurrentJobs/EditorCurrentJobs';
import EditorJobDetail from 'components/jobs/employees/EmployeeJobDetail/EditorJobDetail';
import CheckerCurrentJobs from 'components/jobs/employees/EmployeeCurrentJobs/CheckerCurrentJobs';
import CheckerJobDetail from 'components/jobs/employees/EmployeeJobDetail/CheckerJobDetail';
import FinalEditorCurrentJobs from 'components/jobs/employees/EmployeeCurrentJobs/FinalEditorCurrentJobs';
import FinalEditorJobDetail from 'components/jobs/employees/EmployeeJobDetail/FinalEditorJobDetail';
import SecondFinalEditorCurrentJobs from 'components/jobs/employees/EmployeeCurrentJobs/SecondFinalEditorCurrentJobs';
import SecondFinalEditorJobDetail from 'components/jobs/employees/EmployeeJobDetail/SecondFinalEditorJobDetail';
import DtpCurrentJobs from 'components/jobs/employees/EmployeeCurrentJobs/DtpCurrentJobs';
import DtpJobDetail from 'components/jobs/employees/EmployeeJobDetail/DtpJobDetail';
import SecondDtpCurrentJobs from 'components/jobs/employees/EmployeeCurrentJobs/SecondDtpCurrentJobs';
import SecondDtpJobDetail from 'components/jobs/employees/EmployeeJobDetail/SecondDtpJobDetail';
import EmployeeAssignmentProjectUploadModal from 'components/jobs/employees/EmployeeAssignmentProjectUploadModal';
import MHTEditor from 'components/mht/MHTEditor';
import EmployeeJobHistory from 'components/jobs/employees/EmployeeJobHistory';
import SalesReport from 'components/accounting/SalesReport';
import SalesTarget from 'components/accounting/SalesTarget';
import InvoiceList from 'components/invoices/InvoiceList';
import Profile from 'components/settings/Profile';
import SiteHOC from './SiteHOC';

const componentMap = {
  [constants.EMPLOYEE_JOB_SCHEDULE]: EmployeeJobSchedule,
  [constants.TOTRA_CURRENT_JOBS]: ToTraCurrentJobs,
  [constants.TOTRA_JOB_DETAIL]: ToTraJobDetail,
  [constants.TRANSLATOR_CURRENT_JOBS]: TranslatorCurrentJobs,
  [constants.TRANSLATOR_JOB_DETAIL]: TranslatorJobDetail,
  [constants.EDITOR_CURRENT_JOBS]: EditorCurrentJobs,
  [constants.EDITOR_JOB_DETAIL]: EditorJobDetail,
  [constants.CHECKER_CURRENT_JOBS]: CheckerCurrentJobs,
  [constants.CHECKER_JOB_DETAIL]: CheckerJobDetail,
  [constants.FINAL_EDITOR_CURRENT_JOBS]: FinalEditorCurrentJobs,
  [constants.FINAL_EDITOR_JOB_DETAIL]: FinalEditorJobDetail,
  [constants.SECOND_FINAL_EDITOR_CURRENT_JOBS]: SecondFinalEditorCurrentJobs,
  [constants.SECOND_FINAL_EDITOR_JOB_DETAIL]: SecondFinalEditorJobDetail,
  [constants.DTP_CURRENT_JOBS]: DtpCurrentJobs,
  [constants.DTP_JOB_DETAIL]: DtpJobDetail,
  [constants.SECOND_DTP_CURRENT_JOBS]: SecondDtpCurrentJobs,
  [constants.SECOND_DTP_JOB_DETAIL]: SecondDtpJobDetail,
  [constants.MHT_EDITOR]: MHTEditor,
  [constants.EMPLOYEE_JOB_HISTORY]: EmployeeJobHistory,
  [constants.SALES_REPORT]: SalesReport,
  [constants.SALES_TARGET]: SalesTarget,
  [constants.INVOICE_LIST]: InvoiceList,
  [constants.PROFILE]: Profile
}


const Employee = (props) => {
  
  const {
    skin,
    me,
    logout,
    tabs,
    currentTab,
    renderTabContent,
    handleTabOpen,
    handleTabSelect,
    handleTabClose,
    handleOpenTabCloseConfirm
  } = props;
  
  return (
    <Layout skin={skin}>
      <Header
        me={me}
        logout={logout}
        handleTabOpen={handleTabOpen} />
      <EmployeeSidebar
        {...props}
        handleTabOpen={handleTabOpen} />
      <Content>
        <Tab.Container
          id="global-tabset"
          activeKey={currentTab}
          onSelect={handleTabSelect}>
          <div>
            <GlobalTabs
              tabs={tabs}
              activeKey={currentTab}
              onSelect={handleTabSelect}
              handleTabClose={handleTabClose}
              handleOpenTabCloseConfirm={handleOpenTabCloseConfirm} />
              <Tab.Content animation={false}>
                {renderTabContent(componentMap)}
              </Tab.Content>
          </div>
        </Tab.Container>
      </Content>
      <EmployeeJobFinishModal />
      <EmployeeMHTJobFinishModal />
      <EmployeeJobReportCountModal />
      <EmployeeAssignmentProjectUploadModal />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthorizedEmployee: authSelectors.isAuthorizedEmployee(state),
    isToTra: authSelectors.isToTra(state),
    isTranslator: authSelectors.isTranslator(state),
    isEditor: authSelectors.isEditor(state),
    isChecker: authSelectors.isChecker(state),
    isFinalEditor: authSelectors.isFinalEditor(state),
    isSecondFinalEditor: authSelectors.isSecondFinalEditor(state),
    isDtp: authSelectors.isDtp(state),
    isSecondDtp: authSelectors.isSecondDtp(state),
    isSuperuser: authSelectors.isSuperuser(state),
  }
}

export default connect(
  mapStateToProps
)(SiteHOC(Employee));
