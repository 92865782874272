import React from 'react';
import { UNSUPPORTED_ORDER_DETAIL } from 'misc/constants';
import { datetimeFormatter } from 'misc/cellFormatters';
import { LinkFieldCellRenderer } from 'misc/cellRenderers';
import ListTable from 'components/core/ListTable';
import PureRenderComponent from 'components/core/PureRenderComponent';

class UnsupportedOrderListTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 140,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: UNSUPPORTED_ORDER_DETAIL
          },
        },
        {
            headerName: 'Customer',
            field: 'customer.user.username',
            width: 200,
          },
        {
          headerName: 'Client',
          field: 'customer.client.name',
          width: 200,
        },
        {
            headerName: 'AE',
            field: 'customer.ae.name',
            width: 200,
          },
        {
          headerName: 'Service',
          field: 'service.name',
          width: 80,
        },
        {
          headerName: 'Layout',
          field: 'layout',
          width: 150,
        },
        {
            headerName: 'Created',
            field: 'created',
            width: 130,
            valueFormatter: datetimeFormatter,
          },
      ]
    }
  }

  render() {
    return (
      <ListTable
        columnDefs={this.state.columnDefs}
        rowData={this.props.orders}
        deltaRowDataMode={true}
        getRowNodeId={data => data.id} />
    )
  }
}

export default UnsupportedOrderListTable;
