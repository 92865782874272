import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { EMPLOYEE_STATUS_MAP } from 'misc/config';
import { secondFinalEditorCurrentJobsRequest } from 'actions/jobs/employees';
import { getSecondFinalEditorCurrentJobs } from 'selectors/lists';
import { isSecondFinalEditorCurrentJobsLoading } from 'selectors/loading';
import EmployeeCurrentJobsHOC from './EmployeeCurrentJobsHOC';
import EmployeeCurrentJobs from './presenter'


const mapStateToProps = (state) => {
  const roleKey = 'secondFinalEditor';
  return {
    roleKey,
    sentToStatus: EMPLOYEE_STATUS_MAP[roleKey].sent,
    jobs: getSecondFinalEditorCurrentJobs(state),
    isLoading: isSecondFinalEditorCurrentJobsLoading(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest: secondFinalEditorCurrentJobsRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeCurrentJobsHOC(EmployeeCurrentJobs));
