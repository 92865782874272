import React from 'react';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { DEFAULT_DATE_FORMAT } from 'misc/config';
import Select from 'components/core/Select';
import FilterBar from 'components/core/FilterBar';


const CalendarFilter = (props) => {

  const {
    handlePrev,
    handleNext,
    group,
    groupOptions,
    handleGroupChange,
    visibleRange: { start, end },
  } = props;

  const _end = moment(end).subtract(1, 'days').format(DEFAULT_DATE_FORMAT)

  return (
    <FilterBar>
      <Nav>
        <NavItem onClick={handlePrev}>
          <FontAwesome name="chevron-left" className="mr-10" />Prev
        </NavItem>
        <NavItem disabled>{start} - {_end}</NavItem>
        <NavItem onClick={handleNext}>
          Next<FontAwesome name="chevron-right" className="ml-10" />
        </NavItem>
      </Nav>
      <Nav>
        <NavItem disabled>Group</NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <Select
          value={group}
          clearable={true}
          placeholder=""
          options={groupOptions}
          onChange={handleGroupChange}
          style={{ width: 200 }} />
      </Navbar.Form>
    </FilterBar>
  );
}

export default CalendarFilter;