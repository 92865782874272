import React from 'react';
import * as PropTypes from "prop-types";

import "components/mht/TranslationMemoryDetail/translationMemoryDetail.module.css";

import {TaInputText} from "components/taUi/taInputText";
import {TaToolbar} from "components/taUi/taToolbar/taToolbar";
import {TaButton} from "components/taUi/taButton/taButton";
import {TaTableHeader} from "components/taUi/taTable/taTableHead";
import {TaTableRow} from "components/taUi/taTable/taTableRow";
import {TaTableCell} from "components/taUi/taTable/taTableCell";
import {TaTableBody} from "components/taUi/taTable/taTableBody";
import {TaTableFooter} from "components/taUi/taTable/taTableFooter";
import {TaTable} from "components/taUi/taTable/taTable";
import {TaPane} from "components/taUi/taPane/taPane";
import {TaToolbarGroup} from "components/taUi/taToolbar/taToolbarGroup";
import {TaCard} from "components/taUi/taCard/taCard";
import {TaText} from "components/taUi/taText/taText";
import {TaTableColumns} from "components/taUi/taTable/taTableColumns";
import {TaTableColumn} from "components/taUi/taTable/taTableColumn";
import {TaMenu} from "components/taUi/taMenu/taMenu";
import {TaMenuItem} from "components/taUi/taMenu/taMenuItem";
import TaLoader from "components/taUi/taLoader";
import TaTableCellEditable from "components/taUi/taTable/taTableCellEditable";
import TranslationMemoryWarningModal from "components/mht/TranslationMemoryDetail/translationMemoryWarningModal";

import styles from 'components/mht/TranslationMemoryDetail/translationMemoryDetail.module.css';
import {TaModal, TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";

export class TranslationMemoryDetail extends React.PureComponent {

  static propTypes = {
    languages: PropTypes.shape({
      src: PropTypes.string.isRequired,
      dst: PropTypes.string.isRequired
    }).isRequired,
    items: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    totalItemCount: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onQuery: PropTypes.func.isRequired,
    onModal: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
  };

  constructor() {
    super();

    this.defaultMutations = () => {
      return {
        index: {},
        added: {},
        edited: {},
        removed: {},
        // committed: {},
        // rejected: {},
        data: {},
        error: {}
      };
    };

    this.newItem_default = () => {
      const d = new Date();
      const newKey = 'new_' + d.getTime();
      return {
        key: newKey,
        value: {
          is_pending: true
        },
        errors: {},
        isPristine: true,
        isValid: false,
        isSubmitted: false
      };
    };

    this.state = {
      items: [],
      mutations: Object.assign({}, this.defaultMutations()),
      newItem: this.newItem_default(),
      selected: {
        items: {},
        count: 0,
        isNone: true,
        isAll: false,
        isSome: false
      },
      modalWarning: {
        open: false,
        saving: false
      },
      updated: 0
    };

  }

  // componentDidMount() {
  // }

  componentDidUpdate(prevProps) {
    // if (
    //   prevProps.updated &&
    //   this.props.updated !== prevProps.updated
    // ) {
    if (
      prevProps.isSaving &&
      this.props.isSaving !== prevProps.isSaving
    ) {
      this.setState({
        mutations: this.defaultMutations()
      });
    }
    // }
  }

  selected_toggleItem = (itemId) => {
    let newItems = Object.assign({}, this.state.selected.items);
    if (newItems[itemId]) {
      delete newItems[itemId];
    } else {
      newItems[itemId] = true;
    }
    this.selected_setState(newItems);
  };

  selected_calcState = (newItems) => {

    const props = this.props;
    const state = this.state;

    const loadedItems = (props.items) ? props.items : [];
    const loadedItemCount = loadedItems.length;

    const addedItemsCount = Object.keys(state.mutations.added).length;
    const removedItemsCount = Object.keys(state.mutations.removed).length;

    const totalItemsCount = loadedItemCount - removedItemsCount + addedItemsCount;

    const selectedItemsCount = Object.keys(newItems).length;

    const isNoneSelected = (!selectedItemsCount);
    const isAllSelected = (!isNoneSelected && totalItemsCount === selectedItemsCount);
    const isSomeSelected = (!isAllSelected && totalItemsCount > 1);

    return {
      items: newItems,
      count: selectedItemsCount,
      isNone: isNoneSelected,
      isAll: isAllSelected,
      isSome: isSomeSelected
    };

  };

  selected_setState = (newItems) => {
    this.setState({
      selected: this.selected_calcState(newItems)
    });
  };

  onSearchInput = (taInputEvent) => {

    const eventName = taInputEvent.name;
    const isChange = (eventName === 'change');

    if (
      isChange ||
      eventName === 'escape'
    ) {
      this.props.onSearch((isChange) ? taInputEvent.data.value : '');
    }

  };

  bulkAction = (action) => {

    const selected = this.state.selected;
    let newState = {};

    if (selected.count) {

      newState.selected = this.selected_calcState({});

      let newSelected = {};
      let newMutations = Object.assign({}, this.state.mutations);

      Object.keys(selected.items).forEach(key => {

        const timestamp = Date.now();

        if (
          action === 'remove'
        ) {

          if (newMutations.added[key]) {
            delete newMutations.added[key];
            newMutations.index[key] = timestamp;
          } else {
            newMutations.removed[key] = true;
          }
          delete newMutations.edited[key];
          delete newMutations.data[key];
          delete newMutations.error[key];

        }

        // out-commented, because removed immediately
        // newMutations.index[key] = timestamp;

      });

      if (
        action === 'remove'
      ) {
        newState.selected = newSelected;
      }
      newState.mutations = newMutations;

    }

    return newState;

  };

  action_save = (force_merge) => {

    const save = this.props.onSave;
    const mutations = this.state.mutations;
    const errorKeys = Object.keys(mutations.error);

    if (typeof save === 'function') {

      if (errorKeys.length) {

        let errors = [];
        errorKeys.forEach(id => {
          const errorData = mutations.error[id];
          Object.keys(errorData.fields).forEach(lng => {
            errors.push(<span>Memory #{errorData.index + 1}: <b style={{textTransform: 'capitalize'}}>{lng}</b> is required. </span>);
          });
        });
        const errorCount = errors.length;

        this.setState({
          modalWarning: {
            open: true,
            title: 'Invalid Values',
            isError: true,
            body: (
              <React.Fragment>
                <p>The changes cannot be saved. Please review the
                  following {(errorCount > 1) ? errorCount + ' issues' : 'issue'}:</p>
                <ul>
                  {
                    errors.map((error, errorIndex) => {
                      return <li key={errorIndex}>{error}</li>;
                    })
                  }
                </ul>
              </React.Fragment>
            )
          }
        });

      } else {
        save(mutations, force_merge);
      }

    }

  };

  onCellBlur = (property, currentValue, newValue, row, rowIndex) => {

    if (currentValue !== newValue) {
      const time = Date.now();
      const rowId = row.id;

      let newMutations = this.state.mutations;
      const currentMutation = (newMutations.data[rowId]) ? newMutations.data[rowId] : {};

      newMutations.data[rowId] = Object.assign({}, row, currentMutation);
      newMutations.data[rowId][property] = newValue;
      newMutations.edited[rowId] = time;
      newMutations.index[rowId] = time;

      const isInValid = this.cellIsInValid(newValue);
      if (isInValid) {
        if (typeof newMutations.error[rowId] === 'undefined') {
          newMutations.error[rowId] = {
            id: rowId,
            index: rowIndex,
            fields: {}
          };
        }
        newMutations.error[rowId].fields[property] = isInValid;

      } else if (newMutations.error[rowId]) {
        if (typeof newMutations.error[rowId].fields[property] !== 'undefined') {
          delete newMutations.error[rowId].fields[property];
        }
        if (!Object.keys(newMutations.error[rowId].fields).length) {
          delete newMutations.error[rowId];
        }
      }

      this.setState({
        mutations: newMutations,
        updated: time
      });

    }

  };

  cellIsInValid = (value) => {
    return (!value.length) ? 'required' : false;
  };

  bulkEditAttributes = () => {

    const props = this.props;
    const selected = this.state.selected;
    if (selected.count) {
      const selectedIds = Object.keys(selected.items);
      if (selected.count === 1) {
        const row = props.items.find(item => (item.id === selectedIds[0]));
        props.onModal('attrSingle', row);
      } else {
        props.onModal('attrMany', selectedIds);
      }
    }

  };

  editAllAttributes = () => {
    this.props.onModal('attrAll');
  };

  showBulkDeleteWarning = () => {
    const selected = this.state.selected;
    if (selected.count) {
      let title = 'Delete ';
      if (selected.count > 1) {
        title += selected.count;
        title += ' ';
      }
      title += 'Translation Memory Unit';
      if (selected.count > 1) {
        title += 's';
      }
      this.setState({
        modalWarning: {
          open: true,
          title: title,
          body: (<React.Fragment>
            <p>Are you sure you want to continue?</p>
            <p>The selected translation units will be <b>permanently deleted</b> from this translation memory.</p>
          </React.Fragment>),
          saving: false
        }
      });
    }
  };
  onModalWarningConfirm = () => {

    this.setState({
      modalWarning: {
        ...this.state.modalWarning,
        saving: true
      }
    });

    const removeKeys = Object.keys(this.state.selected.items);
    this.props.onRemove(removeKeys).finally(e =>{
      console.log('removed');
      let newState = {
        ...this.bulkAction('remove')
      };
      this.hideModalWarning(newState);
    })

  };

  hideModalWarning = (extend) => {


    this.setState({

      modalWarning: {
        open: false,
        saving: false
      },
      ...(extend ? extend : {})
    });
  };

  onConfirmMergeModal = () => {
    this.action_save(true)
  };
  onDismissMergeModal = () => {

    const props = this.props;
    let newMutations = Object.assign({}, this.state.mutations);

    Object.keys(props.errors.merge).forEach(key => {
      delete newMutations.edited[key];
      delete newMutations.index[key];
      delete newMutations.data[key];
    });

    this.setState({
      mutations: newMutations
    }, () => props.onDismissError('merge'));

  };

  render() {

    const state = this.state;
    const props = this.props;

    const isLoading = props.isLoading;
    const isSaving = props.isSaving;
    const languages = props.languages;
    const mutations = state.mutations;

    const itemsPerPage = 50;
    const itemCount = props.items.length;
    const totalItemCount = props.totalItemCount;
    const mutationsCount = Object.keys(state.mutations.index).length;

    const thisPage = props.page;
    const hasPages = totalItemCount > itemsPerPage;

    const totalPages = Math.ceil(totalItemCount / itemsPerPage);
    const labelPages = (hasPages) ? 'Page ' + thisPage + ' of ' + totalPages : '';

    const fromRow = (thisPage * itemsPerPage) - itemsPerPage + 1;
    const toRow = fromRow - 1 + itemsPerPage;
    const labelRows = (hasPages) ? 'Row ' + fromRow + ' - ' + ((thisPage < totalPages) ? toRow : totalItemCount) + ' of ' + totalItemCount : itemCount + ' rows';

    const cellWidthIndex = 48;
    const cellWidthAttribute = 128 + 64;

    const listAttributes = (row) => {

      let items = [];

      if (row['client']) {
        items.push(row['client']['name'])
      }

      [
        // 'categories',
        // 'tags',
        // 'clients',
        'jobs'
      ].forEach(key => {
        if (row[key]) {
          const value = row[key];
          const pick = (key === 'jobs') ? 'number' : 'name';
          value.forEach(item => items.push(item[pick]));
        }
      });
      return items.join(', ');

    };

    return (
      <React.Fragment>
        <TaToolbar>
          <TaButton
            icon={'settings'}
            tooltip={'Settings'}
            onClick={(event) => props.onModal('settings')}
            disabled={isLoading}
          />
          <TaMenu
            icon={'build'}
            disabled={isLoading}
          >
            {/* <div onClick={(event) => this.editAllAttributes()}>Batch Change Attributes</div> */}
            <TaMenuItem
              name={'batchDeleteTranslationMemory'}
              children={'Batch Delete Translation Memory'}
              callback={(event) => props.onModal('clear')}
            />
            {/* <div onClick={(event) => props.onModal('clear')}>Batch Delete Translation Memory</div> */}
            {/*<div onClick={(event) => props.onModal('delete')}>Delete Translation Memory</div>*/}
          </TaMenu>
          {/* <TaButton
            icon={'archive'}
            tooltip={'Import Translation Memory'}
            onClick={(event) => props.onModal('import')}
            disabled={isLoading}
          /> */}
          <TaButton
            icon={'unarchive'}
            tooltip={'Export Translation Memory'}
            onClick={(event) => props.onModal('export')}
            disabled={isLoading}
          />
          <TaButton
            icon={'remove_circle'}
            tooltip={'Remove Selected Item' + (state.selected.count > 1 ? 's' : '')}
            onClick={(event) => this.showBulkDeleteWarning()}
            disabled={state.selected.isNone}
          />
          <TaButton
            icon={'edit'}
            tooltip={'Edit Item Attributes'}
            onClick={(event) => this.bulkEditAttributes()}
            disabled={state.selected.isNone}
          />
          <TaToolbarGroup>
            <TaInputText name="search"
                         onEvent={this.onSearchInput}
                         iconBefore="search"
                         placeholder="Search Memory"
                         clear={true}
                         isActive={props.search.isValid}
            />
          </TaToolbarGroup>
          <TaButton
            type="submit"
            icon={'check_circle'}
            label={'Save'}
            disabled={mutationsCount === 0}
            onClick={(e)=>this.action_save()}
            isLoading={isSaving}
          />
        </TaToolbar>
        <TaPane flex={'auto'} style={{position: 'relative', zIndex: 0}}>
          <TaCard style={{position: 'absolute', left: '8px', right: '8px', top: '8px', bottom: '8px'}}>

            <TaTableColumns>
              <TaTableColumn
                style={{width: cellWidthIndex + 'px', flex: '0 0 ' + cellWidthIndex + 'px'}}
              />
              <TaTableColumn/>
              <TaTableColumn/>
              <TaTableColumn
                style={{width: cellWidthAttribute + 'px', flex: '0 0 ' + cellWidthAttribute + 'px'}}
              />
            </TaTableColumns>
            <TaTable scroll={'y'} flex={'auto'}>
              <TaTableHeader look={'card'}>
                <TaTableRow>
                  <TaTableCell
                    width={cellWidthIndex}
                    color={'light'}>#</TaTableCell>
                  <TaTableCell
                    style={{width: 'calc(50% - ' + ((cellWidthIndex + cellWidthAttribute) / 2) + 'px)'}}
                  >
                    {languages.src}
                  </TaTableCell>
                  <TaTableCell
                    style={{width: 'calc(50% - ' + ((cellWidthIndex + cellWidthAttribute) / 2) + 'px)'}}
                  >
                    {languages.dst}
                  </TaTableCell>
                  <TaTableCell width={cellWidthAttribute}>
                    Attributes
                  </TaTableCell>
                </TaTableRow>
              </TaTableHeader>
              <TaTableBody>
                {
                  isLoading ? (
                    <TaLoader isLoading={isLoading}/>
                  ) : (

                    props.items.map((row, rowIndex) => {

                      const isChecked = (
                        state.selected &&
                        state.selected.items &&
                        state.selected.items[row.id]
                      );

                      const isMutated = mutations.index[row.id];
                      const reactKey = (isMutated) ? (row.id + '_' + isMutated) : row.id;

                      if (mutations.removed[row.id]) {
                        return null;
                      }

                      const term = (isMutated) ? Object.assign({}, row, mutations.data[row.id]) : row;

                      let rowClassList = ['ta-table-row'];

                      if (rowIndex % 2 === 0) {
                        rowClassList.push('ta-table-row--even');
                      } else {
                        rowClassList.push('ta-table-row--odd');
                      }
                      if (isChecked) {
                        rowClassList.push('ta-table-row--selected');
                        rowClassList.push(styles.rowSelected);
                      }

                      return (
                        <TaTableRow key={reactKey}
                                    className={rowClassList.join(' ')}
                        >
                          <TaTableCell
                            width={cellWidthIndex}
                            onClick={(event) => this.selected_toggleItem(row.id)}
                          >
                            <TaText
                              color={(term.is_pending) ? 'alert' : 'light'}
                            >{fromRow + rowIndex}{(term.is_pending) && <span>&nbsp;&#9679;</span>}</TaText>
                          </TaTableCell>
                          <TaTableCellEditable
                            style={{width: 'calc(50% - ' + ((cellWidthIndex + cellWidthAttribute) / 2) + 'px)'}}
                            error={this.cellIsInValid(term.source)}
                            onBlur={(value) => this.onCellBlur('source', term.source, value, row, rowIndex)}
                            children={term.source}
                          />
                          <TaTableCellEditable
                            style={{width: 'calc(50% - ' + ((cellWidthIndex + cellWidthAttribute) / 2) + 'px)'}}
                            error={this.cellIsInValid(term.target)}
                            onBlur={(value) => this.onCellBlur('target', term.target, value, row, rowIndex)}
                            children={term.target}
                          />
                          <TaTableCell
                            width={cellWidthAttribute}
                            color={'light'}
                            onClick={(event) => props.onModal('attrSingle', row)}
                            className={styles.expandCell}
                          >
                            <div>
                              {listAttributes(row)}
                            </div>
                            <i><span>&#8230;</span></i>
                          </TaTableCell>
                        </TaTableRow>
                      );
                    })
                  )
                }
              </TaTableBody>
              <TaTableFooter look={'card'}>
                <TaTableRow>
                  <TaTableCell
                    valign={'center'}
                  >
                    {labelRows}
                  </TaTableCell>
                  <TaTableCell
                    align={'right'}
                    valign={'center'}
                  >
                    <div>{labelPages}</div>
                  </TaTableCell>
                  <TaTableCell width={40} noWrapper>
                    <div style={{padding: '8px 8px 8px 0'}}>
                      <TaButton
                        icon={'arrow_back'}
                        disabled={(thisPage === 1)}
                        onClick={(e) => props.goPage(thisPage - 1)}
                      />
                    </div>
                  </TaTableCell>
                  <TaTableCell width={40} noWrapper>
                    <div style={{padding: '8px 8px 8px 0'}}>
                      <TaButton
                        icon={'arrow_forward'}
                        disabled={(thisPage === totalPages)}
                        onClick={(e) => props.goPage(thisPage + 1)}
                      />
                    </div>
                  </TaTableCell>
                </TaTableRow>
              </TaTableFooter>
            </TaTable>
          </TaCard>
        </TaPane>
        <TranslationMemoryWarningModal
          label={state.modalWarning.title}
          children={state.modalWarning.body}
          isOpen={state.modalWarning.open}
          isSaving={state.modalWarning.saving}
          onConfirm={this.onModalWarningConfirm}
          onCancel={this.hideModalWarning}
        />
        <TaModal
          isOpen={!!(props.errors && props.errors.hasMerge)}
          onCancel={(e) => props.onDismissError('merge')}
        >
          {
            (props.errors && props.errors.hasMerge) && (
              <React.Fragment>
                <TaModalHeader
                  icon={'warning'}
                  warning
                  label={'Duplicate Memory Units'}
                  onCancel={(e) => props.onDismissError('merge')}
                />
                <TaModalContent type={'text'}>
                  <p>The following <i>updated</i> memory pairs already exist:</p>
                  <TaTable
                    flex={'auto'}
                    style={{marginBottom: '12px'}}
                  >
                    <TaTableHeader>
                      <TaTableRow>
                        <TaTableCell
                          style={{width: '50%'}}
                        >
                          {languages.src}
                        </TaTableCell>
                        <TaTableCell
                          style={{width: '50%'}}
                        >
                          {languages.dst}
                        </TaTableCell>
                      </TaTableRow>
                    </TaTableHeader>
                  <TaTableBody>
                    {Object
                      .keys(props.errors.merge)
                      .map((key, index) => {
                        return (
                          <TaTableRow key={key}>
                            <TaTableCell
                              style={{width: '50%'}}
                            >
                              {props.errors.items[key].source}
                            </TaTableCell>
                            <TaTableCell
                              style={{width: '50%'}}
                            >
                              {props.errors.items[key].target}
                            </TaTableCell>
                          </TaTableRow>
                        );
                      })}
                  </TaTableBody>
                  </TaTable>
                  <p>Would you like to <b>merge the duplicates</b>?</p>
                </TaModalContent>
                <TaModalFooter>
                  <TaButton
                    type="reset"
                    icon={'clear'}
                    label={'No, discard'}
                    onClick={()=>this.onDismissMergeModal()}
                  />
                  <TaButton
                    type="submit"
                    icon={'check'}
                    iconAlign={'right'}
                    label={'Yes, merge'}
                    onClick={()=>this.onConfirmMergeModal()}
                    isLoading={state.isSaving}
                  />
                </TaModalFooter>
              </React.Fragment>
            )}
        </TaModal>
        {/*< pre> {JSON.stringify(state.mutations, null, 2)};</pre>*/}
      </React.Fragment>
    );
  }
}

export default TranslationMemoryDetail;
