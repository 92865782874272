import React from "react";
import "./taMenu.css";
import {TaButton} from "components/taUi/taButton/taButton";

export const TaMenu = (props) => {

  const {
    className,
    children,
    ...attributes
  } = props;

  let classNames = ['ta-menu'];
  if (className) {
    className.push(className);
  }

  return (
    <div className={classNames.join(' ')}
    >
      <TaButton
        className={'ta-menu__button'} {...attributes}
      />
      {
        (props.children) && (
        <div className={'ta-menu__list'}>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};

TaMenu.propTypes = Object.assign(
  {},
  TaButton.propTypes
);
