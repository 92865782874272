import React from 'react'
import FontAwesome from 'react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change, touch } from 'redux-form';
import { Form, Button, Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import * as validators from 'misc/validators';
import { isSingleFileUploadModalLoading } from 'selectors/loading';
import { SINGLE_FILE_UPLOAD_FORM, SINGLE_FILE_UPLOAD_MODAL } from 'misc/constants';
import Spinner from 'components/core/Spinner';
import CharField from 'components/core/CharField';
import SingleDropzoneField from 'components/core/SingleDropzoneField';
import PureRenderComponent from 'components/core/PureRenderComponent';


class SingleFileUploadModal extends PureRenderComponent {

  componentDidUpdate(prevProps) {
    if (this.props.file && this.props.file !== prevProps.file) {
      const { file } = this.props;
      this.props.change('name', file.name);
      this.props.touch(['name'])
    }
  }

  render() {
    const {
      show,
      title,
      invalid,
      isLoading,
      handleHide,
      handleSubmit,
      handleUpload
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Form noValidate onSubmit={handleSubmit(handleUpload)}>
          <Modal.Header closeButton>
            <Modal.Title>Upload {title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="file-upload-modal no-padding">
            <Field
              name="file"
              validate={[validators.required]}
              component={SingleDropzoneField} />
            <div className="p-10">
              <Field
                name="name"
                label="Name"
                type="text"
                validate={[
                  validators.required,
                  validators.maxLength150,
                ]}
                isRequired={true}
                component={CharField.Labeled} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pull-left"
              bsSize="sm"
              type="button"
              onClick={handleHide}>
              <FontAwesome name="close" className="mr-5" />Close
            </Button>
            <Button
              bsStyle="primary"
              bsSize="sm"
              type="submit"
              disabled={invalid}>
              <FontAwesome name="paper-plane-o" className="mr-5" />Submit
            </Button>
          </Modal.Footer>
        </Form>
        <Spinner isLoading={isLoading} />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const formSelector = formValueSelector(SINGLE_FILE_UPLOAD_FORM);
  const file = formSelector(state, 'file');
  return {
    file,
    isLoading: isSingleFileUploadModalLoading(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    change,
    touch,
  }, dispatch)
}

SingleFileUploadModal = connectModal({ name: SINGLE_FILE_UPLOAD_MODAL })(SingleFileUploadModal);
SingleFileUploadModal = reduxForm({ form: SINGLE_FILE_UPLOAD_FORM })(SingleFileUploadModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleFileUploadModal);
