import React from 'react';
import { Checkbox } from 'react-bootstrap';
import { QUOTE_DETAIL } from 'misc/constants';
import { datetimeFormatter } from 'misc/cellFormatters';
import { LinkFieldCellRenderer } from 'misc/cellRenderers';
import ListTable from 'components/core/ListTable';
import PureRenderComponent from 'components/core/PureRenderComponent';


export class CheckboxCellRenderer extends React.Component {
  render() {
    const { value, data, colDef } = this.props;
    const { handleCheck } = colDef.cellRendererParams;
    return data && data.id
      ? (
        <Checkbox
          checked={value}
          value={data.id}
          onChange={handleCheck}
          disabled={!data.isDeletable}
          className="mt-5 mb-5" />
      ) : null
  }
}


class QuoteListTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: '',
          field: '',
          suppressFilter: true,
          pinned: 'left',
          width: 30,
          cellRendererFramework: CheckboxCellRenderer,
          cellRendererParams: {
            handleCheck: this.props.handleSelect
          },
        },
        {
          headerName: 'ID',
          field: 'id',
          width: 140,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: QUOTE_DETAIL
          },
        },
        {
            headerName: 'Customer',
            field: 'customer.username',
            width: 200,
          },
        {
          headerName: 'Client',
          field: 'customer.client.name',
          width: 200,
        },
        {
          headerName: 'Service',
          field: 'service.name',
          width: 80,
        },
        {
          headerName: 'Lv',
          field: 'level',
          width: 40,
        },
        {
            headerName: 'Deadline',
            field: 'deliveryTime',
            width: 150,
            valueFormatter: datetimeFormatter
        },
        {
          headerName: 'Count',
          field: 'totalCount',
          width: 80,
        },
        {
          headerName: 'Job',
          field: 'job.number',
          width: 150,
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 110,
        },
        {
            headerName: 'Created',
            field: 'created',
            width: 130,
            valueFormatter: datetimeFormatter,
          },
      ]
    }
  }

  render() {
    return (
      <ListTable
        columnDefs={this.state.columnDefs}
        rowData={this.props.quotes}
        deltaRowDataMode={true}
        getRowNodeId={data => data.id} />
    )
  }
}

export default QuoteListTable;
