import React from "react";
import PropTypes from 'prop-types';
import Dropzone from "react-dropzone";
import {DOCX_TYPE, MAX_FILE_SIZE, MIN_FILE_SIZE, PPTX_TYPE, XLSX_TYPE} from "misc/config";
import {humanizeBytes} from "misc/utils";
import './taInputDropzone.css';

export const TaInputDropzone = (props) => {

  const baseClass = 'ta-input-dropzone';

  const {
    className,
    accept,
    onDropAccepted,
    onDropRejected
  } = props;

  let classNames = [baseClass];
  if (className) {
    classNames.push(className);
  }

  return (
    <Dropzone
      name="document"
      accept={accept}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      className={classNames.join(' ')}
      activeClassName={baseClass + '--active'}
      maxSize={MAX_FILE_SIZE}
      minSize={MIN_FILE_SIZE}
      multiple={false}
    >
      <div className={baseClass + '__placeholder'}>{
        !!props.children ? props.children : (
          <React.Fragment>
            Drag file or click here to upload a file.<br/>
            Max file upload size is <b>{humanizeBytes(MAX_FILE_SIZE)}</b>
          </React.Fragment>
        )
      }</div>
    </Dropzone>
  );
};

TaInputDropzone.propTypes = {
  accept: PropTypes.string,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func
};
TaInputDropzone.defaultProps = {
  accept: ``,
  onDropAccepted: () => null,
  onDropRejected: () => null
};
