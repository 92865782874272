import * as types from 'actionTypes/memories';
import * as utils from 'misc/utils';


// Search List =================================================================
const searchList = utils.createSearchAction(types.SEARCH_LIST);
export const searchListRequest = searchList.requestFn;
export const searchListSuccess = searchList.successFn;
export const searchListFailure = searchList.failureFn;


// Search Create ===============================================================
const searchCreate = utils.createCreateAction(types.SEARCH_CREATE);
export const searchCreateRequest = searchCreate.requestFn;
export const searchCreateSuccess = searchCreate.successFn;
export const searchCreateFailure = searchCreate.failureFn;


// Search ======================================================================
export const searchRequest = (params) => ({
  type: types.SEARCH.REQUEST,
  payload: { params }
})

export const searchSuccess = () => ({
  type: types.SEARCH.SUCCESS
});
export const searchFailure = (error) => ({
  type: types.SEARCH.FAILURE,
  payload: { error }
});


// Count =======================================================================
export const countRequest = (params) => ({
  type: types.COUNT.REQUEST,
  payload: { params }
})

export const countSuccess = () => ({
  type: types.COUNT.SUCCESS
});
export const countFailure = (error) => ({
  type: types.COUNT.FAILURE,
  payload: { error }
});

// Upload ======================================================================
export const uploadRequest = (values) => ({
  type: types.UPLOAD.REQUEST,
  payload: { values }
})

export const uploadSuccess = () => ({
  type: types.UPLOAD.SUCCESS
});
export const uploadFailure = (error) => ({
  type: types.UPLOAD.FAILURE,
  payload: { error }
});

export const uploadPenltyRequest = (values) => ({
  type: types.UPLOAD_PENALTY.REQUEST,
  payload: { values }
})

export const uploadPenltySuccess = () => ({
  type: types.UPLOAD_PENALTY.SUCCESS
});
export const uploadPenltyFailure = (error) => ({
  type: types.UPLOAD_PENALTY.FAILURE,
  payload: { error }
});

// Update =======================================================

export const searchUpdateRequest = (values) => ({
  type: types.SEARCH_UPDATE.REQUEST,
  payload: { values }
});
export const searchUpdateSuccess = (values) => ({
  type: types.SEARCH_UPDATE.SUCCESS,
  payload: { values }
});

export const searchUpdateFailure = (error) => ({
  type: types.SEARCH_UPDATE.FAILURE,
  payload: { error }
});

// Clear =======================================================

export const searchClearRequest = (values) => ({
  type: types.SEARCH_CLEAR.REQUEST,
  payload: { values }
});
export const searchClearSuccess = (values) => ({
  type: types.SEARCH_CLEAR.SUCCESS,
  payload: { values }
});

export const searchClearFailure = (error) => ({
  type: types.SEARCH_CLEAR.FAILURE,
  payload: { error }
});

// Delete =======================================================

export const searchDeleteRequest = (values) => ({
  type: types.SEARCH_DELETE.REQUEST,
  payload: { values }
});
export const searchDeleteSuccess = (values) => ({
  type: types.SEARCH_DELETE.SUCCESS,
  payload: { values }
});

export const searchDeleteFailure = (error) => ({
  type: types.SEARCH_DELETE.FAILURE,
  payload: { error }
});

// Import tmx ======================================================================

export const searchImportTmxRequest = (values) => ({
  type: types.SEARCH_IMPORT_TMX.REQUEST,
  payload: { values }
})

export const searchImportTmxSuccess = () => ({
  type: types.SEARCH_IMPORT_TMX.SUCCESS
});
export const searchImportTmxFailure = (error) => ({
  type: types.SEARCH_IMPORT_TMX.FAILURE,
  payload: { error }
});

// Export Excel =======================================================

export const exportExcelRequest = (id, name) => ({
  type: types.EXPORT_EXCEL.REQUEST,
  payload: { id, name }
})
export const exportExcelSuccess = (id) => ({
  type: types.EXPORT_EXCEL.SUCCESS,
})
export const exportExcelFailure = (error) => ({
  type: types.EXPORT_EXCEL.FAILURE,
  payload: { error }
})

// Export tmx =======================================================

export const exportTmxRequest = (id, name) => ({
  type: types.EXPORT_TMX.REQUEST,
  payload: { id, name }
})
export const exportTmxSuccess = (id) => ({
  type: types.EXPORT_TMX.SUCCESS,
})
export const exportTmxFailure = (error) => ({
  type: types.EXPORT_TMX.FAILURE,
  payload: { error }
})

// Update Item =======================================================

export const itemUpdateRequest = (values) => ({
  type: types.ITEM_UPDATE.REQUEST,
  payload: { values }
});
export const itemUpdateSuccess = (values) => ({
  type: types.ITEM_UPDATE.SUCCESS,
  payload: { values }
});

export const itemUpdateFailure = (error) => ({
  type: types.ITEM_UPDATE.FAILURE,
  payload: { error }
});