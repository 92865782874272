import React from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { EMPLOYEE_STATUS_MAP } from 'misc/config';
import { dtpCurrentJobsRequest, dtpBulkFinishRequest } from 'actions/jobs/employees';
import { getDtpCurrentJobs } from 'selectors/lists';
import { isDtpCurrentJobsLoading } from 'selectors/loading';
import EmployeeCurrentJobsHOC from './EmployeeCurrentJobsHOC';
import PureRenderComponent from 'components/core/PureRenderComponent';
import EmployeeCurrentJobs from './presenter'


class DtpCurrentJobs extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = { selected: [] };
  }

  handleSelect = (e) => {
    const { checked, value } = e.target;
    const newValue = [...this.state.selected];
    checked
      ? newValue.push(value)
      : newValue.splice(newValue.indexOf(value), 1)
    this.setState({ selected: newValue });
  }

  handleBulkFinish = () => {
    this.props.dtpBulkFinishRequest({ jobs: this.state.selected });
    this.setState({ selected: [] });
  }

  render() {
    return (
      <EmployeeCurrentJobs
        {...this.props}
        selected={this.state.selected}
        handleSelect={this.handleSelect}
        handleBulkFinish={this.handleBulkFinish} />
    );
  }
}


const mapStateToProps = (state) => {
  const roleKey = 'dtp';
  return {
    roleKey,
    title: 'DTP',
    allowBulkFinish: true,
    sentToStatus: EMPLOYEE_STATUS_MAP[roleKey].sent,
    jobs: getDtpCurrentJobs(state),
    isLoading: isDtpCurrentJobsLoading(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest: dtpCurrentJobsRequest,
    dtpBulkFinishRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeCurrentJobsHOC(DtpCurrentJobs));
