import * as types from 'actionTypes/calendars';


export const calendarRequest = (params) => ({
  type: types.CALENDAR.REQUEST,
  payload: { params }
});

export const calendarSuccess = () => ({
  type: types.CALENDAR.SUCCESS,
});

export const calendarFailure = () => ({
  type: types.CALENDAR.FAILURE,
});


export const setCalendar = (users, assignments) => ({
  type: types.SET_CALENDAR,
  payload: {
    users,
    assignments,
  }
});