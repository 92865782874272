import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import { initialize } from 'redux-form';
import {
  mhtJobMessageListRequest,
  mhtJobMessageCreateRequest,
  mhtJobMessageReadRequest,
  mhtJobMessageBatchReadRequest,
} from 'actions/jobs/managers';
import { getMHTJobMessagesById } from 'selectors/lists';
import { isMHTJobMessageListLoading } from 'selectors/loading';
import { MHT_JOB_MESSAGE_MODAL, MHT_JOB_MESSAGE_FORM } from 'misc/constants';
import MHTJobMessage from './presenter';
import PureRenderComponent from 'components/core/PureRenderComponent';


class MHTJobMessageContainer extends PureRenderComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.mhtJobMessageListRequest(this.props.jobId);
  }

  showMessageAddModal = () => {
    this.props.show(MHT_JOB_MESSAGE_MODAL, {
      _handleSubmit: (values) => {
        this.props.mhtJobMessageCreateRequest({
          job: this.props.jobId,
          body: values.body,
        })
      }
    });
    this.props.initialize(MHT_JOB_MESSAGE_FORM, {});
  }

  handleReadMessage = (messageId, messageRead, fromStaff) => {
    if (messageRead || fromStaff) return;
    this.props.mhtJobMessageReadRequest(messageId);
  }
  handleReadAllMessages = () => {
    var messages = this.getUnreadMessageFromCustomer().map(m => m.id);
    this.props.mhtJobMessageBatchReadRequest({ messages });
  }

  isMessageAllRead = () => {
    return this.getUnreadMessageFromCustomer().length == 0
  }

  getUnreadMessageFromCustomer = () => {
    return this.props.messages.filter(m => m.fromCustomer && !m.read)
  }

  render() {
    console.log(this.props);
    return (
      <MHTJobMessage
        {...this.props}
        isMessageAllRead={this.isMessageAllRead}
        showMessageAddModal={this.showMessageAddModal}
        handleReadMessage={this.handleReadMessage}
        handleReadAllMessages={this.handleReadAllMessages}
        />
    )
  }
}


const mapStateToProps = (state, ownProps) => {
  const id = ownProps.jobId;
  return {
    messages: getMHTJobMessagesById(state, id),
    isLoading: isMHTJobMessageListLoading(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    initialize,
    mhtJobMessageListRequest,
    mhtJobMessageReadRequest,
    mhtJobMessageCreateRequest,
    mhtJobMessageBatchReadRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MHTJobMessageContainer);
