import React from "react";
import 'components/taUi/taFormGroup/taFormGroup.css';

export const TaFormGroup = (props) => {

  const {
    className
  } = props;

  const baseClass = 'ta-form-group';

  let classNames = [baseClass];
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} {...props}/>
  );
};