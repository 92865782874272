import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import { JOB_TYPE_OPTIONS } from 'misc/config';
import * as validators from 'misc/validators';
import Callout from 'components/core/Callout';
import CharField from 'components/core/CharField';
import SelectField from 'components/core/SelectField';
import JobBasicInfoFields from 'components/jobs/managers/JobBasicInfoFields';
import JobNotesField from 'components/jobs/managers/JobNotesField';
import { AESelectOptionField } from 'components/core/SelectOptionField';
import Sourcefile from 'components/jobs/managers/Sourcefile';
import Reference from 'components/jobs/managers/Reference';
import JobClientCommonReference from 'components/jobs/managers/JobClientCommonReference';


const JobDuplicateForm = (props) => {

  const {
    form,
    isLoading,
    client,
    invalid,
    submitFailed,
    handleSubmit,
    handleCreate,
    handleSaveAsDraft
  } = props;

  const numberField = (
    <Row>
      <Col lg={12}>
        <Field
          name="type"
          label="Type"
          options={JOB_TYPE_OPTIONS}
          validate={[validators.required]}
          isRequired={true}
          component={SelectField.Labeled} />
      </Col>
    </Row>
  )

  const subjectField = (
    <Row>
      <Col lg={12}>
        <Field
          name="aeSubject"
          label="AE Subject"
          type="text"
          validate={[
            validators.required,
            validators.maxLength150
          ]}
          isRequired={true}
          component={CharField.Labeled} />
      </Col>
      <Col lg={12}>
        <Field
          name="subject"
          label="Subject"
          type="text"
          disabled={true}
          component={CharField.Labeled} />
      </Col>
    </Row>
  )

  const userField = (
    <Row>
      <Col lg={12}>
        <AESelectOptionField
          isRequired={true}
          validate={[validators.required]} />
      </Col>
    </Row>
  )

  return (
    <Form
      noValidate
      autoComplete="off">
      <Row>
        <Col lg={6}>
          <JobBasicInfoFields
            form={form}
            isLoading={isLoading}
            numberField={numberField}
            subjectField={subjectField}
            userField={userField} />
        </Col>
        <Col lg={6}>
          <JobNotesField
            name="instruction"
            title="Instruction"
            isLoading={isLoading}
            isRequired={true} />
          <JobNotesField
            name="notes"
            title="Job notes"
            isRequired={true}
            isLoading={isLoading} />
          <Sourcefile form={form} />
          <Reference form={form} />
          <JobClientCommonReference clientId={client} />
        </Col>
      </Row>
      <Callout
        invalid={invalid}
        submitFailed={submitFailed} />
      <Button
        type="button"
        bsStyle="primary"
        bsSize="sm"
        onClick={handleSubmit(values => handleCreate(values))}>
        <FontAwesome name="paper-plane-o" className="mr-5" />Create New Job
      </Button>
      <button
        type="button"
        className="btn btn-sm bg-purple ml-10"
        onClick={handleSubmit(values => handleSaveAsDraft(values))}>
        <FontAwesome name="file-text-o" className="mr-5" />Save as Draft
      </button>
    </Form>
  )
}

export default reduxForm({
  enableReinitialize: true,
  validate: validators.jobFormValidate
})(JobDuplicateForm);
