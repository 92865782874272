import { createAsyncActionTypes } from 'misc/utils';

const PREFIX = 'users';

export const LIST = createAsyncActionTypes(`${PREFIX}/LIST`);
export const CREATE = createAsyncActionTypes(`${PREFIX}/CREATE`);
export const RETRIEVE = createAsyncActionTypes(`${PREFIX}/RETRIEVE`);
export const UPDATE = createAsyncActionTypes(`${PREFIX}/UPDATE`);
export const REMOVE = createAsyncActionTypes(`${PREFIX}/REMOVE`);

export const PROFILE_RETRIEVE = createAsyncActionTypes(`${PREFIX}/PROFILE_RETRIEVE`);
export const PROFILE_UPDATE = createAsyncActionTypes(`${PREFIX}/PROFILE_UPDATE`);

export const AVATAR_UPLOAD = createAsyncActionTypes(`${PREFIX}/AVATAR_UPLOAD`);


export const SALES_TARGET_LIST = createAsyncActionTypes(`${PREFIX}/SALES_TARGET_LIST`);
export const SALES_TARGET_CREATE = createAsyncActionTypes(`${PREFIX}/SALES_TARGET_CREATE`);
export const SALES_TARGET_PATCH = createAsyncActionTypes(`${PREFIX}/SALES_TARGET_PATCH`);


export const SCHEDULE_LIST = createAsyncActionTypes(`${PREFIX}/SCHEDULE_LIST`);
export const SCHEDULE_UPDATE_OR_CREATE = createAsyncActionTypes(`${PREFIX}/SCHEDULE_UPDATE_OR_CREATE`);

export const FOCUS_LIST = createAsyncActionTypes(`${PREFIX}/FOCUS_LIST`);
export const FOCUS_CREATE = createAsyncActionTypes(`${PREFIX}/FOCUS_CREATE`);
export const FOCUS_UPDATE = createAsyncActionTypes(`${PREFIX}/FOCUS_UPDATE`);

export const SPECIALTY_LIST = createAsyncActionTypes(`${PREFIX}/SPECIALTY_LIST`);
export const SPECIALTY_CREATE = createAsyncActionTypes(`${PREFIX}/SPECIALTY_CREATE`);
export const SPECIALTY_UPDATE = createAsyncActionTypes(`${PREFIX}/SPECIALTY_UPDATE`);

export const SOFTWARE_LIST = createAsyncActionTypes(`${PREFIX}/SOFTWARE_LIST`);
export const SOFTWARE_CREATE = createAsyncActionTypes(`${PREFIX}/SOFTWARE_CREATE`);
export const SOFTWARE_DELETE = createAsyncActionTypes(`${PREFIX}/SOFTWARE_DELETE`);

export const TRANSLATION_SKILL_LIST = createAsyncActionTypes(`${PREFIX}/TRANSLATION_SKILL_LIST`);
export const TRANSLATION_SKILL_CREATE = createAsyncActionTypes(`${PREFIX}/TRANSLATION_SKILL_CREATE`);
export const TRANSLATION_SKILL_UPDATE = createAsyncActionTypes(`${PREFIX}/TRANSLATION_SKILL_UPDATE`);

export const EDIT_SKILL_LIST = createAsyncActionTypes(`${PREFIX}/EDIT_SKILL_LIST`);
export const EDIT_SKILL_CREATE = createAsyncActionTypes(`${PREFIX}/EDIT_SKILL_CREATE`);
export const EDIT_SKILL_UPDATE = createAsyncActionTypes(`${PREFIX}/EDIT_SKILL_UPDATE`);

export const CHECK_SKILL_LIST = createAsyncActionTypes(`${PREFIX}/CHECK_SKILL_LIST`);
export const CHECK_SKILL_CREATE = createAsyncActionTypes(`${PREFIX}/CHECK_SKILL_CREATE`);
export const CHECK_SKILL_UPDATE = createAsyncActionTypes(`${PREFIX}/CHECK_SKILL_UPDATE`);

export const FINAL_EDIT_SKILL_LIST = createAsyncActionTypes(`${PREFIX}/FINAL_EDIT_SKILL_LIST`);
export const FINAL_EDIT_SKILL_CREATE = createAsyncActionTypes(`${PREFIX}/FINAL_EDIT_SKILL_CREATE`);
export const FINAL_EDIT_SKILL_UPDATE = createAsyncActionTypes(`${PREFIX}/FINAL_EDIT_SKILL_UPDATE`);

export const SECOND_FINAL_EDIT_SKILL_LIST = createAsyncActionTypes(`${PREFIX}/SECOND_FINAL_EDIT_SKILL_LIST`);
export const SECOND_FINAL_EDIT_SKILL_CREATE = createAsyncActionTypes(`${PREFIX}/SECOND_FINAL_EDIT_SKILL_CREATE`);
export const SECOND_FINAL_EDIT_SKILL_UPDATE = createAsyncActionTypes(`${PREFIX}/SECOND_FINAL_EDIT_SKILL_UPDATE`);