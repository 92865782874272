import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { isEmployee, isManager } from 'selectors/auth';
import { setLocation } from 'actions/settings';
import PureRenderComponent from 'components/core/PureRenderComponent';


class HeaderChangeViewMenu extends PureRenderComponent {

  handleChangeView = () => {
    const location = window.location.pathname === '/manager'
      ? '/employee'
      : '/manager'
    this.props.setLocation(location);
    window.location.replace(location);
  }
  
  render() {
    const { isManager, isEmployee } = this.props;
    return isEmployee && isManager && (
        <li>
            <a onClick={this.handleChangeView}>
            <FontAwesome name="exchange" />
          </a>
        </li>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isEmployee: isEmployee(state),
    isManager: isManager(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setLocation
  }, dispatch)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderChangeViewMenu);