import React from 'react';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Button, Checkbox } from 'react-bootstrap';
import { DEFAULT_DATETIME_FORMAT } from 'misc/config';
import { humanizeBytes } from 'misc/utils';


const DocumentsTableRow = (props) => {

  const {
    idx,
    isMHT,
    document,
    handleUse,
    downloadable,
    handleDownload,
    showUpdateModal,
    handleRemove,
    isMHTClickable,
    handleOpenMHT,
    sizeVisible,
    updatable,
    removable,
    usable,
    jobId,
    hasAction
  } = props;

  const filename = document.name;
  const filesize = humanizeBytes(document.size);
  const fileUploaded = moment(document.created).format(DEFAULT_DATETIME_FORMAT);

  const filenameSection = document.stored && downloadable
    ? <a onClick={() => handleDownload(document)}>{filename}</a>
    : <span>{filename}</span>

  return isMHT ?
  (
    <tr>
      <td>
        <Button onClick={() => handleOpenMHT(document.mht, document.stored)} disabled={!isMHTClickable}>
          <FontAwesome name="laptop" />
          <div className="m-5">MHT</div>
        </Button>
      </td>
      <td></td>
      <td></td>
      { removable && (
        <td className="text-center">
          <Button
            type="button"
            bsSize="xs"
            bsStyle="danger"
            onClick={() => handleRemove(document, idx) }>
            <FontAwesome name="trash" />
          </Button>
        </td>
      )}
    </tr>
  )
  : (
      <tr>
        <td>
          <OverlayTrigger placement="top" overlay={<Tooltip id={`filename-${idx}`}>{filename}</Tooltip>}>
            {filenameSection}
          </OverlayTrigger>
        </td>
        { sizeVisible &&
          <td>
            <OverlayTrigger placement="top" overlay={<Tooltip id={`filesize-${idx}`}>{filesize}</Tooltip>}>
              <span>{filesize}</span>
            </OverlayTrigger>
          </td>
        }
        <td>
          <OverlayTrigger placement="top" overlay={<Tooltip id={`fileUploaded-${idx}`}>{fileUploaded}</Tooltip>}>
            <span>{fileUploaded}</span>
          </OverlayTrigger>
        </td>
        { hasAction && (
          <td className="text-center">
            { updatable && (
              <Button
                type="button"
                bsSize="xs"
                bsStyle="success"
                onClick={() => showUpdateModal(document) }>
                <FontAwesome name="refresh" />
              </Button>
            )}
            { updatable && removable && ' ' }
            { removable && (
              <Button
                type="button"
                bsSize="xs"
                bsStyle="danger"
                onClick={() => handleRemove(document, idx) }>
                <FontAwesome name="trash" />
              </Button>
            )}
            { usable && (
              <Checkbox
                className="mt-0 mb-0"
                checked={document.jobs.includes(jobId)}
                value={document.id}
                onChange={(e) => handleUse(e.target.value,  e.target.checked)} />
            )}
          </td>
        )}
      </tr>
    )
}

export default DocumentsTableRow;
