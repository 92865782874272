import React from "react";
import './taText.css';

export const TaText = (props) => {

  const baseClass = 'ta-text';

  const {
    color,
    className,
    ...attributes
  } = props;

  let classNames = [baseClass];

  if (color) {
    classNames.push(baseClass + '--' + color);
  }

  if (className) {
    classNames.push(className);
  }

  return (
    <span className={classNames.join(' ')} {...attributes}>
      {props.children}
    </span>
  )

};