import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isUserFinalEditSkillLoading } from 'selectors/loading';
import { getFinalEditSkillsByUserId } from 'selectors/users';
import {
  finalEditSkillListRequest,
  finalEditSkillCreateRequest,
  finalEditSkillUpdateRequest
} from 'actions/users'
import UserSkillHOC from './UserSkillHOC';
import UserSkill from './presenter';


const mapStateToProps = (state, props) => {
  const id = props.userId;
  return {
    isLoading: isUserFinalEditSkillLoading(state, id),
    skills: getFinalEditSkillsByUserId(state, id)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest: finalEditSkillListRequest,
    createRequest: finalEditSkillCreateRequest,
    updateRequest: finalEditSkillUpdateRequest,
  }, disptach)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSkillHOC(UserSkill))
