import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';
import { MULTIPLE_FILE_UPLOAD_MODAL } from 'misc/constants';
import { commonReferenceListRequest } from 'actions/clients';
import { clientCommonReferenceDownloadRequest } from 'actions/documents';
import { isClientCommonReferenceLoading } from 'selectors/loading';
import { getClientCommonReferencesById } from 'selectors/lists';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobClientCommonReference from './presenter';



class JobClientCommonReferenceContainer extends PureRenderComponent {

  static defaultProps = {
    title: 'Client References',
    uploadable: false,
    removable: false,
    usable: false,
    jobId: null, 
    clientId: null
  }
  
  componentDidMount() {
    this.props.clientId &&
    this.props.commonReferenceListRequest(this.props.clientId)
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.clientId && this.props.clientId !== prevProps.clientId) {
      this.props.commonReferenceListRequest(this.props.clientId)
    }
  }
  
  showUploadModal = () => {
    this.props.show(MULTIPLE_FILE_UPLOAD_MODAL, {
      title: 'Client Common Reference',
      handleUpload: this.handleUpload
    })
  }
  handleDownload = (file) => {
    this.props.clientCommonReferenceDownloadRequest(file);
  }

  handleUpload = (files) => {
    this.props.clientCommonReferenceUploadRequest(this.props.clientId, files, this.props.jobId)
  }

  handleUse = () => {}

  render() {
    return (
      <JobClientCommonReference
        {...this.props}
        showUploadModal={this.showUploadModal}
        handleDownload={this.handleDownload}
        handleUse={this.handleUse} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const id = props.clientId;
  return {
    isLoading: isClientCommonReferenceLoading(state, id),
    files: getClientCommonReferencesById(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    commonReferenceListRequest,
    clientCommonReferenceDownloadRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobClientCommonReferenceContainer)
