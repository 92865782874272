import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { JOB_CREATE_BY_TEMPLATE_FORM } from 'misc/constants';
import { templateByCreateRequest, templateByDraftRequest } from 'actions/jobs/managers';
import { retrieveRequest } from 'actions/templates';
import { getJobCreateFormInitialValuesByTemplateId } from 'selectors/forms';
import { isJobCreateByTemplateLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobCreateByTemplate from './presenter';


class JobCreateByTemplateContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.retrieveRequest(this.props.id);
  }

  handleCreate = (values) => {
    this.props.templateByCreateRequest(this.props.id, this.props.form, values);
  }

  handleSaveAsDraft = (values) => {
    this.props.templateByDraftRequest(this.props.id, this.props.form, values);
  }

  render() {
    return (
      <JobCreateByTemplate
        {...this.props}
        handleCreate={this.handleCreate}
        handleSaveAsDraft={this.handleSaveAsDraft} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const id = props.params.id;
  const form = `${JOB_CREATE_BY_TEMPLATE_FORM}/${id}`;
  const formSelector = formValueSelector(form);
  const client = formSelector(state, 'client');
  return {
    id,
    form,
    client,
    isLoading: isJobCreateByTemplateLoading(state, id),
    initialValues: getJobCreateFormInitialValuesByTemplateId(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    retrieveRequest,
    templateByCreateRequest,
    templateByDraftRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobCreateByTemplateContainer);