import React from "react";
import * as PropTypes from "prop-types";
import {TaTableRow} from "components/taUi/taTable/taTableRow";
import {TaTableCell} from "components/taUi/taTable/taTableCell";
import {TaText} from "components/taUi/taText/taText";
import {TaTableCellGroup} from "components/taUi/taTable/taTableCellGroup";

export class TaMhtEditorSegmentSleep extends React.Component {

  static propTypes = {
    rowIndex: PropTypes.number.isRequired,
    segment: PropTypes.object.isRequired,
    pickSegmentProperty: PropTypes.func.isRequired,
    timeMutated: PropTypes.number.isRequired,
    timeUpdated: PropTypes.string.isRequired,
    cellWidthIndex: PropTypes.number.isRequired,
    cellWidthStatus: PropTypes.number.isRequired,
    cellGroupWidth: PropTypes.string.isRequired
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      // this.props.timeUpdated !== nextProps.timeUpdated ||
      this.props.timeMutated !== nextProps.timeMutated
    )
  }

  render() {

    const {
      rowIndex,
      segment,
      cellWidthIndex,
      cellWidthStatus,
      cellGroupWidth,
      pickSegmentProperty
    } = this.props;

    const segmentId = segment.id;

    return (
      <TaTableRow
        key={segmentId}
        id={segmentId}
        rowIndex={rowIndex}
      >
        <TaTableCell
          width={cellWidthIndex}
        >
          <TaText color={'light'}>{segment.order}</TaText>
        </TaTableCell>
        <TaTableCellGroup
          style={{width: cellGroupWidth}}
        >
          <TaTableCell
            noWrapper={true}
            width={'50%'}
          >
            <div className={'ta-ck--off ta-ck--sleep'}
                 dangerouslySetInnerHTML={{__html: pickSegmentProperty(segment, 'source_html')}}/>
          </TaTableCell>
          <TaTableCell
            noWrapper={true}
            width={'50%'}
          >
            <div className={'ta-ck--off ta-ck--sleep'}
                 dangerouslySetInnerHTML={{__html: pickSegmentProperty(segment, 'target_html')}}/>
          </TaTableCell>
        </TaTableCellGroup>
        <TaTableCell
          width={cellWidthStatus}
        />
      </TaTableRow>
    );

  }
}
