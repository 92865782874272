import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import { isWordListLoading } from 'selectors/loading';
import { listRequest, uploadRequest, batchDeleteRequest } from 'actions/wordlists';
import { getWordLists, getWordListCurrentPage, getWordListPageSize } from 'selectors/lists';
import { WORDLIST_UPLOAD_MODAL } from 'misc/constants';
import PureRenderComponent from 'components/core/PureRenderComponent';
import WordList from './presenter';


class WordListContainer extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = {
      client: '',
      keyword: '',
      selected: [],
    }
  }

  componentDidMount() {
    const params = { page: this.props.currentPage };
    this.props.listRequest(params);
  }

  handlePageSelect = (page) => {
    const { client, keyword } = this.state;
    this.props.listRequest({ client, keyword, page });
  }

  handleClientChange = (client) => {
    this.setState({ client })
    this.props.listRequest({
      client,
      page: 1,
      keyword: this.state.keyword,
    });
  }

  handleKeywordChange = (keyword) => {
    this.setState({ keyword })
  }

  handleSubmit = () => {
    const { client, keyword } = this.state;
    this.props.listRequest({
      keyword,
      client,
      page: this.props.currentPage
    });
  }

  handleUpload = (values) => {
    this.props.uploadRequest(values)
  }

  showAddModal = () => {
    this.props.show(WORDLIST_UPLOAD_MODAL, {
      handleAddWordList: this.handleUpload
    })
  }

  handleSelect = (e) => {
    const { checked, value } = e.target;
    const newValue = [...this.state.selected];
    checked
      ? newValue.push(value)
      : newValue.splice(newValue.indexOf(value), 1)
    this.setState({ selected: newValue });
  }

  handleDelete = () => {
    this.props.batchDeleteRequest({ items: this.state.selected });
    this.setState({ selected: [] });
  }

  render() {
    return (
      <WordList
        {...this.props}
        selected={this.state.selected}
        client={this.state.client}
        keyword={this.state.keyword}
        handleSubmit={this.handleSubmit}
        handleClientChange={this.handleClientChange}
        handleKeywordChange={this.handleKeywordChange}
        handlePageSelect={this.handlePageSelect}
        handleSelect={this.handleSelect}
        handleDelete={this.handleDelete}
        showAddModal={this.showAddModal}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    wordlists: getWordLists(state),
    isLoading: isWordListLoading(state),
    pageSize: getWordListPageSize(state),
    currentPage: getWordListCurrentPage(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    listRequest,
    uploadRequest,
    batchDeleteRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WordListContainer);
