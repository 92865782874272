import React from "react";
import * as PropTypes from 'prop-types';
import './taTable.css';
import TaTableCell, {TaTableCellPropTypes} from "components/taUi/taTable/taTableCell";

const TaTableCellEditablePropTypes = {
  ...TaTableCellPropTypes,
  type: PropTypes.oneOf(['string', 'html']),
  // onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  // onChange: PropTypes.func,
  onPaste: PropTypes.func,
  readOnly: PropTypes.bool
};

const TaTableCellEditable = (props) => {

  const {
    type,
    onFocus,
    onBlur,
    onChange,
    onPaste,
    readOnly,
    children,
    className,
    error,
    required,
    ...attributes
  } = props;


  let classNames = ['ta-table-cell--editable']

  if (className) {
    classNames.push(className);
  }
  if (error) {
    classNames.push('ta-table-cell--error');

  }
  if (required) {
    classNames.push('ta-table-cell--error');
  }

  let wrapperAttr = {};

  if (!readOnly) {

    wrapperAttr = {
      className: 'ta-table-cell--editable',
      contentEditable: true,
      suppressContentEditableWarning: true
    }

    if (error && typeof error === 'string') {
      wrapperAttr['data-error'] = error;
    }

    if (typeof onBlur === 'function') {
      wrapperAttr.onBlur = (event) => {
        const target = event.currentTarget;
        const value = (type === 'html') ? target.innerHTML : target.textContent;
        onBlur(value);
      };
    }
    if (typeof onChange === 'function') {
      wrapperAttr.onInput = (event) => {
        console.log('fire')
        const target = event.currentTarget;
        const value = (type === 'html') ? target.innerHTML : target.textContent;
        onChange(value);
      };
    }

    wrapperAttr.onPaste = (event) => {
      if (typeof onPaste === 'function') {
        onPaste(event);
      } else {
        // see: https://developer.mozilla.org/en-US/docs/Web/API/Element/paste_event
        let content = (event.clipboardData || window.clipboardData).getData('text');

        const selection = window.getSelection();
        if (!selection.rangeCount) return false;
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(content));

        event.preventDefault();
      }
    }

    wrapperAttr.onKeyPress = (event) => {
      if (
        event.key === 'Enter'
      ) {
        event.preventDefault();
        event.currentTarget.blur();
      }
    };
  }

  if (type === 'html') {
    wrapperAttr.dangerouslySetInnerHTML = {__html: children};
  } else {
    wrapperAttr.children = children;
  }

  return (
    <TaTableCell {...attributes} noWrapper={true} className={classNames.join(' ')}>
      <div {...wrapperAttr} />
    </TaTableCell>
  );

};

TaTableCellEditable.propTypes = TaTableCellEditablePropTypes;

export default TaTableCellEditable;
