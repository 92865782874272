import React from "react";
import * as PropTypes from "prop-types";
import ReactModal from "react-modal";

import './taModal.css'
import {TaButton} from "components/taUi/taButton/taButton";
import {TaIcon} from "components/taUi/taIcon";

export const TaModal = (props) => {
    let classNames = ['ta-modal'];
    if(props.className) {
        classNames.push(props.className)
    }
    return (
        <ReactModal
            isOpen={props.isOpen}
            onRequestClose={props.onCancel}
            ariaHideApp={false}
            className={classNames.join(' ')}
            overlayClassName="ta-modal-overlay"
        >{props.children}</ReactModal>
    )
}

TaModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired
};

export const TaModalHeader = (props) => {
    let classNames = ['ta-modal-header'];
    let icon;
    if(props.className) {
        classNames.push(props.className)
    }
    if(props.warning) {
        icon = 'warning';
        classNames.push(classNames[0] + '--warning');
    }
    if(props.error) {
        icon = 'error';
        classNames.push(classNames[0] + '--error');
    }
    if(props.icon) {
        icon = props.icon;
    }

    return (
        <div className={classNames.join(' ')}>
            {(icon) && <TaIcon name={icon}/> }
            {(props.label) && <TaModalHeaderLabel value={props.label}/> }
            {(props.onCancel) && <TaButton icon="clear" onClick={props.onCancel} look={'light'} /> }
        </div>
    )
}
TaModalHeader.propTypes = {
    label: PropTypes.string,
    onCancel: PropTypes.func.isRequired
};
export const TaModalHeaderLabel = (props) => {
    let classNames = ['ta-modal-header__label'];
    if(props.className) {
        classNames.push(props.className)
    }
    return (
        <div className={classNames.join(' ')}>
            <span>{props.value}</span>
        </div>
    )
}
TaModalHeaderLabel.propTypes = {
    value: PropTypes.string.isRequired
};

export const TaModalHeaderActions = (props) => {
    let classNames = ['ta-modal-header__actions'];
    if(props.className) {
        classNames.push(props.className)
    }
    return (
        <div className={classNames.join(' ')}>
            {props.children}
        </div>
    )
}
export const TaModalContent = (props) => {
    const baseClass = 'ta-modal-content';
    let classNames = [baseClass];
    if (props.scroll === 'auto') {
        classNames.push(baseClass+'--scroll');
    } else if (props.scroll && props.scroll !== 'hidden') {
        classNames.push(baseClass+'--scroll-' + props.scroll);
    }
    if(props.type) {
        classNames.push(baseClass+'--'+props.type);
    }
    if(props.className) {
        classNames.push(props.className)
    }
    return (
        <div className={classNames.join(' ')}>
            {props.children}
        </div>
    )
}
export const TaModalContentMargin = (props) => {
    let classNames = ['ta-modal-content__margin'];
    if(props.className) {
        classNames.push(props.className)
    }
    return (
      <div className={classNames.join(' ')}>
          {props.children}
      </div>
    )
}


export const TaModalScroll = (props) => {
    let classNames = ['ta-modal-content ta-modal-content--scroll'];
    if(props.className) {
        classNames.push(props.className)
    }
    return (
        <div className={classNames.join(' ')}>
            {props.children}
        </div>
    )
}
export const TaModalFooter = (props) => {
    let classNames = ['ta-modal-footer'];
    if(props.className) {
        classNames.push(props.className)
    }
    return (
        <div className={classNames.join(' ')}>
            {props.children}
        </div>
    )
}
