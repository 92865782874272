import React from "react";
import * as PropTypes from "prop-types";
import CKEditor from "@ckeditor/ckeditor5-react";
import TaSegmentEditor from "@stefanyanku/ckeditor5-build-balloon";
import {ckEditorConfigTarget} from "./ckEditorConfig/ckEditorConfigTarget";
import {ckEditorConfigSource} from "./ckEditorConfig/ckEditorConfigSource";

export class TaMhtEditorSegmentSource extends React.Component {

  static propTypes = {
    html: PropTypes.string.isRequired,
    canEdit: PropTypes.bool.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.blurTimeout = null;
    this.state = {
      hasChanged: false,
      hasFocus: false,
      offset: 0
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.canEdit !== nextProps.canEdit ||
      this.props.html !== nextProps.html ||
      this.state.hasFocus !== nextState.hasFocus
    );
  }

  componentWillUnmount() {
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout);
    }
  }

  passive_onClick = (event, isEmptyString) => {
    let offset = 0;

    if (!isEmptyString) {
      // see: https://developer.mozilla.org/en-US/docs/Web/API/Document/caretRangeFromPoint
      if (document.caretPositionFromPoint) {
        offset = document.caretPositionFromPoint(event.clientX, event.clientY).offset;
      } else if (document.caretRangeFromPoint) {
        offset = document.caretRangeFromPoint(event.clientX, event.clientY).startOffset;
      }
    }

    this.setState({hasFocus: true, offset: offset})
  }

  onInit = (editor) => {
    const offset = this.state.offset;
    editor.editing.view.focus()
    if (offset) {
      editor.model.change(writer => {
        const root = editor.model.document.getRoot();
        const startPosition = writer.createPositionFromPath(root, [0, offset]);
        const range = writer.createRange(startPosition);
        writer.setSelection(range);
      })
    }
    this.props.onFocus(null, this.props.segment, editor);
  }

  onBlur = (event, editor, isEdit) => {
    const cntxt = this;
    const props = cntxt.props;
    if (isEdit && this.state.hasChanged) {
      props.onChange(editor.getData(), props.segment);
      this.setChanged(false)
    }
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout);
    }
    this.blurTimeout = setTimeout(() => {
      cntxt.setState({hasFocus: false});
      cntxt.blurTimeout = null;
    }, 900);
    props.onBlur(event, props.segment, editor);
  }

  onChange = () => {
    if (!this.state.hasChanged) {
      this.setChanged(true)
    }
  }

  setChanged = (value) => this.setState({hasChanged: value});

  render() {

    const {
      html,
      canEdit,
      preprocessHtml,
      validateEmptyString
    } = this.props;

    return (
      <React.Fragment>
        {
          (this.state.hasFocus) ? (
            (canEdit) ? (
              <CKEditor
                key={'edit'}
                editor={TaSegmentEditor}
                config={ckEditorConfigTarget}
                data={html}
                onInit={this.onInit}
                onChange={this.onChange}
                onBlur={(event, editor) => this.onBlur(event, editor, true)}
              />
            ) : (
              <CKEditor
                key={'split'}
                editor={TaSegmentEditor}
                config={ckEditorConfigSource}
                data={html}
                onInit={this.onInit}
                onBlur={(event, editor) => this.onBlur(event, editor, false)}
              />
            )
          ) : (
            <div
              className={'ck ta-ck--passive'}
              dangerouslySetInnerHTML={{__html: preprocessHtml(html)}}
              onClick={(event => this.passive_onClick(event, validateEmptyString(html)))}
            />
          )
        }
      </React.Fragment>
    );

  }
}
