import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'misc/config';
import { selectTab } from 'actions/tabs';
import { setFilter } from 'actions/filters';
import { setViewAs } from 'actions/settings';
import { JOB_SCHEDULE } from 'misc/constants';
import {
  dailyScheduleRequest,
  futureScheduleRequest,
  rearrangeRequest,
  setQuickEdit
} from 'actions/jobs/managers';
import {
  getJobScheduleCurrentDate,
  getJobScheduleViewAs,
  getJobScheduleDeadline,
  getJobScheduleAssignee,
  isEmployeeSelected,
  isFESchedule
} from 'selectors/filters';
import { isDailyScheduleLoading, isFutureScheduleLoading } from 'selectors/loading';
import { getDailySchedule, getFutureSchedule } from 'selectors/lists';
import { getJobScheduelCurrentTab } from 'selectors/tabs';
import { isCoordinator } from 'selectors/auth';
import { getViewAsOptions, getAssigneeOptionsByDeadline } from 'selectors/options';
import PureRenderComponent from 'components/core/PureRenderComponent';

import JobSchedule from './presenter';


class JobScheduleContainer extends PureRenderComponent {

  componentDidMount() {
    let viewAs;
    if (!this.props.viewAs) {
      const first = this.props.viewAsOptions[0];
      viewAs = first.value;
    } else {
      viewAs = this.props.viewAs;
    }
    this.props.setViewAs(viewAs);
    this.fetchSchedule({
      viewAs,
      currentDate: this.props.currentDate,
      deadline: this.props.deadline,
      assignee: this.props.assignee
    });
  }

  fetchSchedule = (params) => {
    this.props.dailyScheduleRequest(params);
    this.props.futureScheduleRequest(params);
  }

  handleRowDrag = (event) => {
    const orderedIds = [];
    event.api.forEachNode(node => {
      orderedIds.push(node.data.id)
    })
    this.props.rearrangeRequest(orderedIds);
  }

  handleTabSelect = (tab) => {
    this.props.selectTab(JOB_SCHEDULE, tab);
  }

  handleViewAsChange = (viewAs) => {
    this.props.setViewAs(viewAs);
    const { currentDate, deadline, assignee } = this.props;
    this.fetchSchedule({ currentDate, deadline, assignee, viewAs });
  }

  handleDeadlineChange = (deadline) => {
    this.props.setFilter(JOB_SCHEDULE, 'deadline', deadline);
    const { currentDate, viewAs, assignee } = this.props;
    this.props.setFilter(JOB_SCHEDULE, 'assignee', null);
    this.fetchSchedule({ currentDate, deadline, assignee, viewAs });
  }

  handleAssigneeChange = (assignee) => {
    this.props.setFilter(JOB_SCHEDULE, 'assignee', assignee);
    const { currentDate, viewAs, deadline } = this.props;
    this.fetchSchedule({ currentDate, deadline, assignee, viewAs });
  }

  handlePrev = () => {
    const { currentDate } = this.props;
    const prev = moment(currentDate, DEFAULT_DATE_FORMAT).subtract(1, 'days');
    this.handleDateChange(prev)
  }

  handleNext = () => {
    const { currentDate } = this.props;
    const next = moment(currentDate, DEFAULT_DATE_FORMAT).add(1, 'days');
    this.handleDateChange(next)
  }

  handleDateChange = (date) => {
    const currentDate = moment(date).format(DEFAULT_DATE_FORMAT);
    this.props.setFilter(JOB_SCHEDULE, 'currentDate', currentDate);
    const { viewAs, deadline, assignee } = this.props;
    this.fetchSchedule({ currentDate, deadline, assignee, viewAs });
  }

  handleSelectionChange = (event) => {
    const selectedRows = event.api.getSelectedRows();
    selectedRows.length > 0 &&
    this.props.setQuickEdit(JOB_SCHEDULE, selectedRows[0].id);
  }

  render() {
    return (
      <JobSchedule
        {...this.props}
        quickEditName={JOB_SCHEDULE}
        handlePrev={this.handlePrev}
        handleNext={this.handleNext}
        handleDateChange={this.handleDateChange}
        handleViewAsChange={this.handleViewAsChange}
        handleDeadlineChange={this.handleDeadlineChange}
        handleAssigneeChange={this.handleAssigneeChange}
        handleTabSelect={this.handleTabSelect}
        handleRowDrag={this.handleRowDrag}
        handleSelectionChange={this.handleSelectionChange} />
    )
  }
}

const mapStateToProps = (state) => {
  const {
    toEnJobs,
    toJpJobs,
    toOtherJobs
  } = getDailySchedule(state);

  return {
    toEnJobs,
    toJpJobs,
    toOtherJobs,
    futureJobs: getFutureSchedule(state),
    isDailyLoading: isDailyScheduleLoading(state),
    isFutureLoading: isFutureScheduleLoading(state),
    currentDate: getJobScheduleCurrentDate(state),
    viewAs: getJobScheduleViewAs(state),
    deadline: getJobScheduleDeadline(state),
    assignee: getJobScheduleAssignee(state),
    isCoordinator: isCoordinator(state),
    isEmployeeSelected: isEmployeeSelected(state),
    isFESchedule: isFESchedule(state),
    viewAsOptions: getViewAsOptions(state),
    assigneeOptions: getAssigneeOptionsByDeadline(state),
    currentTab: getJobScheduelCurrentTab(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    selectTab,
    setFilter,
    setViewAs,
    dailyScheduleRequest,
    futureScheduleRequest,
    rearrangeRequest,
    setQuickEdit,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobScheduleContainer)