import { delay } from 'redux-saga';
import { takeLatest, call, fork, put, all } from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { show, hide } from 'redux-modal';
import { reset, stopSubmit } from 'redux-form';
import { normalize } from 'normalizr';
import API from 'services/WordListApi';
import * as actions from 'actions/wordlists';
import * as schemas from 'schemas/wordlists';
import * as actionTypes from 'actionTypes/wordlists';
import * as entityActions from 'actions/entities';
import * as listActions from 'actions/lists';
import * as constants from 'misc/constants';
import * as coreSagas from 'sagas/core';
import { setDownloadProgress } from 'actions/core';
import { setLoading } from 'actions/loading';
import { XLSX_TYPE } from 'misc/config';
import { saveFile } from 'misc/utils';
import {setTabTitle} from "actions/globalTabs";
import { successNotification } from 'sagas/core';

function* list(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.WORDLIST, true));
  try {
    const response = yield call(API.list, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data.results), [schemas.wordlistSchema]);
    const totalCount = response.data.count ? response.data.count : 1;
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setPaginationResult(
      constants.WORDLIST,
      params.page,
      normalized.result,
      totalCount
    ));
    yield put(setLoading(constants.WORDLIST, false));
  } catch (error) {
    yield put(actions.listFailure(error));
    yield put(setLoading(constants.WORDLIST, false));
  }
}

function* upload(action) {
  const { values } = action.payload;
  yield put(setLoading(constants.WORDLIST_UPLOAD_MODAL, true));
  try {
    const response = yield call(API.upload, values);
    const normalized = normalize(camelizeKeys(response.data.wordlist), schemas.wordlistSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(hide(constants.WORDLIST_UPLOAD_MODAL));
    yield put(reset(constants.WORDLIST_UPLOAD_FORM));
    yield put(listActions.addPaginationItem(constants.WORDLIST, normalized.result))
    yield put(setLoading(constants.WORDLIST_UPLOAD_MODAL, false));
    yield fork(
      coreSagas.checkTaskProgress,
      response.data.task_id,
      'WordList registration in progress...',
      'WordList registration completed'
    );
  } catch (error) {
    yield put(actions.uploadFailure(error));
    if (error.response.status === 400) {
      yield put(stopSubmit(
        constants.WORDLIST_UPLOAD_FORM,
        { _error: error.response.data.detail }
      ));
    }
    yield put(setLoading(constants.WORDLIST_UPLOAD_MODAL, false));
  }
}

function* exportExcel(action) {
  yield put(show(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  const { id, name, colKeys } = action.payload;
  try {
    const [downloadPromise, chan] = yield call(
      coreSagas.createDownloader,
      API.exportExcel,
      { id, colKeys }
    );
    yield fork(coreSagas.watchDownloadProgress, chan);
    const response = yield call(() => downloadPromise);
    yield call(saveFile, response.data, name, XLSX_TYPE);
    yield put(actions.exportExcelSuccess());
    yield delay(1000);
    yield put(hide(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
    yield fork(successNotification, `${name} exported successfuly.`);
  } catch (error) {
    yield put(actions.exportExcelFailure(error));
  } finally {
    yield put(setDownloadProgress(0));
  }
}

// Retrieve ====================================================================
function* retrieve(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.WORDLIST_DETAIL}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.retrieveList, id);
    const normalized = normalize(camelizeKeys(response.data), schemas.wordlistSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.retrieveFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

function* update(action) {
  const { values } = action.payload;
  yield put(setLoading(constants.WORDLIST_DETAIL_SETTINGS_MODAL, true));
  try {
    const response = yield call(API.updateList, values.id, decamelizeKeys(values.data));
    const normalized = normalize(camelizeKeys(response.data), schemas.wordlistSchema);
    const tabId = constants.WORDLIST_DETAIL + '/' + values.id;
    yield put(entityActions.merge(normalized.entities));
    yield put(setTabTitle(tabId, 'WordList Editor: ' + values.data.name));
    yield put(hide(constants.WORDLIST_DETAIL_SETTINGS_MODAL));
    yield put(reset(constants.WORDLIST_DETAIL_SETTINGS_FORM));
    yield put(setLoading(constants.WORDLIST_DETAIL_SETTINGS_MODAL, false));
  } catch (error) {
    yield put(actions.updateWordListFailure(error));
    yield put(setLoading(constants.WORDLIST_DETAIL_SETTINGS_MODAL, false));
  }
}

function* batchDelete(action) {
  const message = `Are you sure to delete selected WordList?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    const { values } = action.payload;
    yield put(setLoading(constants.CONFIRM_MODAL, true))
    try {
      yield call(API.batchDelete, decamelizeKeys(values));
      yield all(values.items.map(id => put(listActions.removePaginationItem(constants.WORDLIST, id))));
      yield fork(coreSagas.successNotification, 'WordList deleted successfuly.');
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.batchDeleteFailure(error));
      yield put(setLoading(constants.MEMORY_SEARCH_LIST, false));
    }
  }
}

// Auto complete ===============================================================
function* autocomplete(action) {
  const { q } = action.payload;
  yield put(setLoading(constants.WORDLIST_AUTO_COMPLETE, true));
  yield call(delay, 700);
  try {
    const response = yield call(API.autocomplete, { q });
    const normalized = normalize(camelizeKeys(response.data), [schemas.wordlistSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(constants.WORDLIST_AUTO_COMPLETE, false));
  } catch (error) {
    yield put(actions.autocompleteFailure(error));
    yield put(setLoading(constants.WORDLIST_AUTO_COMPLETE, false));
  }
}

function* watchList() {
  yield takeLatest(actionTypes.LIST.REQUEST, list);
}

function* watchUpload() {
  yield takeLatest(actionTypes.UPLOAD.REQUEST, upload);
}

function* watchExportExcel() {
  yield takeLatest(actionTypes.EXPORT_EXCEL.REQUEST, exportExcel);
}

function* watchUpdate() {
  yield takeLatest(actionTypes.UPDATE.REQUEST, update);
}

function* watchRetrieve() {
  yield takeLatest(actionTypes.RETRIEVE.REQUEST, retrieve)
}

function* watchBatchDelete() {
  yield takeLatest(actionTypes.BATCH_DELETE.REQUEST, batchDelete);
}

function* watchAutoComplete() {
  yield takeLatest(actionTypes.AUTOCOMPLETE.REQUEST, autocomplete)
}

export {
  watchList,
  watchRetrieve,
  watchBatchDelete,
  watchUpload,
  watchExportExcel,
  watchUpdate,
  watchAutoComplete
}