import React from 'react';
import {Form, Button} from 'react-bootstrap';
import {XLSX_TYPE} from "misc/config";

import {TaModal, TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import MHTApi from "services/MHTApi";

import {TaButton} from "components/taUi/taButton/taButton";
import FontAwesome from "react-fontawesome";
import {TaInputDropzone} from "components/taUi/taInputDropzone/taInputDropzone";
import * as PropTypes from "prop-types";
import {TaFormMessages} from "components/taUi/taFormMessage/taFormMessage";


class TaMhtEditorImportExcelModal extends React.Component {

  static propTypes = {
    mhtId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onError: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      formMessages: []
    };
  }

  componentDidMount() {
    this.formClear();
  }

  onCancel = () => {
    this.formClear();
    if (typeof this.props.onCancel === 'function') {
      this.props.onCancel();
    }
  };

  onConfirm = (response) => {
    this.formClear();
    if (typeof this.props.onConfirm === 'function') {
      this.props.onConfirm(response.data);
    }
  };

  onError = (error) => {

    let message = 'Error';

    if (
      error.response &&
      error.response.data &&
      error.response.data.file
    ) {
      message += ': Please format the content of the Excel file according to specifications. Use the file generated by "Export Bilingual File" as a reference';
    } else if (error.message) {
      message += ': ' + error.message;
    }

    this.setState({
      errors: [{type: 'error', message: message}],
      isSaving: false
    });
    if (typeof this.props.onError === 'function') {
      this.props.onError();
    }
  };

  formClear = () => {
    this.setState({
      errors: [],
      isSaving: false,
      isDirty: false,
      formData: {},
    });
  };

  onSubmit = () => {


    this.setState({
      isSaving: true
    }, () => {

      MHTApi
        .importExel(this.props.mhtId, this.state.formData)
        .then(this.onConfirm)
        .catch(this.onError);

    });

  };

  onDropAccepted = (acceptedFiles) => {
    const file = acceptedFiles[0];
    this.setState({
      isDirty: true,
      formData: {
        ...this.state.formData,
        name: file.name,
        file
      }
    });
  };

  onOptionChange = (key, value) => {

    this.setState({
      isDirty: true,
      formData: {
        ...this.state.formData,
        [key]: value
      }
    });

  };

  render() {

    const {
      formData,
      isDirty,
      isSaving
    } = this.state;

    const {
      isOpen
    } = this.props;

    const hasFile = (formData && formData.file);

    return (
      <TaModal
        isOpen={isOpen}
        onCancel={this.onCancel}
      >
        <TaModalHeader
          label={'Upload Bilingual File'}
          onCancel={this.onCancel}
        />
        <TaModalContent>
          <Form
            noValidate
            autoComplete="off"
          >
            <TaInputDropzone
              accept={`${XLSX_TYPE}`}
              onDropAccepted={this.onDropAccepted}
            />
            {
              hasFile && (
                <React.Fragment>
                  <ul className="nav nav-pills nav-stacked">
                    <li>
                      <a>
                        {formData.file.name}
                        <span className="pull-right" style={{marginTop: '-2px'}}>
                            <Button bsStyle="danger" onClick={this.formClear} bsSize="xs">
                              <FontAwesome name="ban" className="mr-5"/>Cancel
                            </Button>
                          </span>
                      </a>
                    </li>
                  </ul>
                  <div className={'taFormMessage taFormMessage--error'}>

                  </div>
                  {/*<TaFormGroup>*/}
                  {/*  <Checkbox*/}
                  {/*    checked={formData.includeSheetHeader}*/}
                  {/*    onChange={e => this.onOptionChange('includeSheetHeader', e.target.checked)}>Include*/}
                  {/*    header</Checkbox>*/}
                  {/*  <Checkbox*/}
                  {/*    checked={formData.includeSheetFooter}*/}
                  {/*    onChange={e => this.onOptionChange('includeSheetFooter', e.target.checked)}>Include*/}
                  {/*    footer</Checkbox>*/}
                  {/*  <Checkbox*/}
                  {/*    checked={formData.includeSheetComment}*/}
                  {/*    onChange={e => this.onOptionChange('includeSheetComment', e.target.checked)}>Include*/}
                  {/*    comment</Checkbox>*/}
                  {/*  <Checkbox*/}
                  {/*    checked={formData.includeSheetHyperlink}*/}
                  {/*    onChange={e => this.onOptionChange('includeSheetHyperlink', e.target.checked)}>Include*/}
                  {/*    hyperlink</Checkbox>*/}
                  {/*  <Checkbox*/}
                  {/*    checked={formData.includeSheetAltText}*/}
                  {/*    onChange={e => this.onOptionChange('includeSheetAltText', e.target.checked)}>Include alt*/}
                  {/*    text</Checkbox>*/}
                  {/*  <Checkbox*/}
                  {/*    checked={formData.includeSheetHiddenContent}*/}
                  {/*    onChange={e => this.onOptionChange('includeSheetHiddenContent', e.target.checked)}>Include hidden*/}
                  {/*    content</Checkbox>*/}
                  {/*  <Checkbox*/}
                  {/*    checked={formData.includeSheetName}*/}
                  {/*    onChange={e => this.onOptionChange('includeSheetName', e.target.checked)}>Include sheet*/}
                  {/*    name</Checkbox>*/}
                  {/*</TaFormGroup>*/}
                </React.Fragment>
              )
            }
          </Form>
          <TaFormMessages messages={this.state.errors}/>
        </TaModalContent>
        <TaModalFooter>
          <TaButton
            type="reset"
            icon={'clear'}
            label={'Cancel'}
            onClick={() => this.onCancel()}
          />
          <TaButton
            type="submit"
            icon={'check'}
            label={'Upload'}
            // iconAlign={'right'}
            onClick={() => this.onSubmit()}
            disabled={!isDirty}
            isLoading={isSaving}
          />
        </TaModalFooter>
      </TaModal>
    );
  }
}

export default TaMhtEditorImportExcelModal;
