import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AuthRoute from 'components/auth/AuthRoute';
import DefaultRoute from 'components/auth/DefaultRoute';
import ManagerRoute from 'components/auth/ManagerRoute';
import EmployeeRoute from 'components/auth/EmployeeRoute';
import Login from 'components/auth/Login'; 
import Manager from 'components/core/Manager';
import Employee from 'components/core/Employee';
import NotFound from 'components/core/NotFound';


const Routes = (props) => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={Login} />
      <AuthRoute>
        <Switch>
          <ManagerRoute exact path="/manager" component={Manager} />
          <EmployeeRoute exact path="/employee" component={Employee} />
          <Route path="/" component={DefaultRoute} />
        </Switch>
      </AuthRoute>
      <Route path="*" component={NotFound} />
    </Switch>
  </BrowserRouter>
)

export default Routes;
