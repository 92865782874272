import React from "react";
import * as PropTypes from 'prop-types';
import './taTable.css';

export const TaTableBody = (
  {
    className,
    scroll,
    flex,
    ...props
  }
) => {

  let classNames = ['ta-table-body'];
  if (props.className) {
    classNames.push(className);
  }
  if (scroll === 'auto') {
    classNames.push('ta-table-body--scroll');
  } else if (scroll && scroll !== 'hidden') {
    classNames.push('ta-table-body--scroll-' + scroll);
  }
  if (flex === 'auto') {
    classNames.push('ta-table-body--flex');
  } else if (flex) {
    classNames.push('ta-table-body--flex-' + flex);
  }

  return (
    <div className={classNames.join(' ')} {...props}/>
  );

};
TaTableBody.propTypes = {
  scroll: PropTypes.oneOf(['x', 'y', 'auto', 'hidden']),
  flex: PropTypes.oneOfType([
    PropTypes.oneOf(['auto']),
    PropTypes.number
  ])
};
