import React from 'react';
import { HelpBlock } from 'react-bootstrap';
import PureRenderComponent from 'components/core/PureRenderComponent';


const FieldHOC = (WrappedComponent) => {
  return class extends PureRenderComponent {
    render() {
      const {
        label,
        isRequired,
        meta: {
          invalid,
          touched,
          error
        }
      } = this.props;
      const hasError = (touched && invalid) ? 'error' : null;
      const requiredMark = isRequired
        ? <span className="text-danger ml-5">*</span>
        : null

      const helpBlock = hasError
        ? <HelpBlock>{error}</HelpBlock>
        : null

        return (
        <WrappedComponent
          {...this.props}
          label={label}
          isRequired={isRequired}
          hasError={hasError}
          requiredMark={requiredMark}
          helpBlock={helpBlock}
          error={error} />
      );
    }
  }
}

export default FieldHOC;
