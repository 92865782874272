import React from "react";
import styles from "./TaMhtEditorWidget.module.css";
import {TaButton} from "../../taUi/taButton/taButton";
import * as PropTypes from "prop-types";
import {TaInputText} from "../../taUi/taInputText";

import MHTApi from "services/MHTApi";
import {TaInputDropdown} from "../../taUi/taInputDropdown/taInputDropdown";
import {TaIcon} from "../../taUi/taIcon";
import delay from "../../taUi/util/delay";

export class TaMhtEditorWidgetSpellcheck extends React.Component {

  static propTypes = {
    segments: PropTypes.object.isRequired,
    highlight: PropTypes.object.isRequired,

    setHighlightState: PropTypes.func.isRequired,
    goPrevHighlight: PropTypes.func.isRequired,
    goNextHighlight: PropTypes.func.isRequired,

    checkSaveWarningModal: PropTypes.func.isRequired,
    // refresh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      defaultReplaceString: '',
      replaceString: '',
      isChecking: false,
      isComplete: false
    };
  }

  onInput = (event) => {
    this.setState({
      [event.data.property]: event.data.value || ''
    });
  }

  onSuggestion = (event) => {
    this.setState({
      replaceString: event.data.value || '',
      defaultReplaceString: event.data.value || ''
    });
  }

  onSubmit = (event)=> {
    event.preventDefault();
    this.props.checkSaveWarningModal(this.requestSpellcheck);
  }

  clear = (event)=> {
    this.setState({
      isChecking: false,
      isComplete: false
    })
    this.props.setHighlightState({
      string: '',
      items: [],
      data: {},
      activeSegment: '',
      activeIndex: 0,
    })
  }

  requestSpellcheck = (mhtId) => {
    const props = this.props;
    const spellcheck = MHTApi.spellcheck(props.mhtId);

    this.setState({
      isChecking: true,
      isComplete: false
    },()=>{
      // delay at least 500ms for better UI experience
      delay(spellcheck, 500)
        .promise
        .then(response => {

          const props = this.props;
          const searchContext = 'target';

          let items = [];
          let data = {};

          response.data.forEach(item => {

            const segmentId = item.segment_id;
            const segment = props.segments.data[segmentId];

            let htmlString = props.pickSegmentProperty(segment, searchContext + '_html');

            item.errors.forEach((error,eIndex) => {

              const eKey = 'e'+eIndex;

              const highlight = props.applyHighlight(
                error.word,
                htmlString,
                item.target,
                true,
                eKey
              );

              if(highlight.matches.length){

                htmlString = highlight.string;

                if (!eIndex) {
                  data[segmentId] = highlight;
                } else {
                  data[segmentId].string = highlight.string
                  data[segmentId].matches = [
                    ...data[segmentId].matches,
                    ...highlight.matches
                  ];
                  if (!data[segmentId].lostFormatting) {
                    data[segmentId].lostFormatting = highlight.lostFormatting
                  }
                }

                items.push({
                  id: segmentId,
                  key: eKey,
                  string: error.word,
                  suggestions: error.suggestions
                })

              }

            })
          })

          props.setHighlightState({
            string: '',
            items: items,
            data: data,
            activeSegment: (items.length) ? items[0].id : '',
            activeIndex: 0,
          });

        })
        .catch(error => {
          console.log('spellcheck - error',error);
        })
        .finally(() => {
          this.setState({
            isChecking: false,
            isComplete: true
          })
        });
    })


  }

  componentDidUpdate(prevProps) {

    if(
      prevProps.highlight.time !== this.props.highlight.time ||
      prevProps.highlight.activeIndex !== this.props.highlight.activeIndex
    ){

      const highlight = this.props.highlight;
      const activeIndex = highlight.activeIndex;
      let newReplaceString = '';
      if (
        highlight.count &&
        highlight.items[activeIndex] &&
        highlight.items[activeIndex].suggestions.length
      ) {
        newReplaceString = highlight.items[activeIndex].suggestions[0];
      }
      this.setState({
        replaceString: newReplaceString,
        defaultReplaceString: newReplaceString
      })
    } else if (
      prevProps.highlight.time !== this.props.highlight.time &&
      prevProps.highlight.count !== this.props.highlight.count &&
      this.props.highlight.count === 0
    ) {
      this.setState({
        replaceString: '',
        defaultReplaceString: ''
      })
    }

  }

  render() {

    const {
      mhtId,
      highlight,
      goPrevHighlight,
      goNextHighlight,
      requestReplace
    } = this.props;

    const {
      isChecking,
      isComplete,
      defaultReplaceString,
      replaceString,
    } = this.state;

    const activeIndex = highlight.activeIndex;
    const activeError = (
      highlight.items &&
      highlight.items[activeIndex]
    )?highlight.items[activeIndex].string:'';
    const suggestions = [];
    if (
      highlight.items &&
      highlight.items[activeIndex] &&
      highlight.items[activeIndex].suggestions
    ) {
      highlight.items[activeIndex].suggestions.forEach(suggestion=>{
        suggestions.push({label:suggestion,value:suggestion})
      })
    }

    return (
      <form
        className={styles.padding}
        onSubmit={(e) => this.onSubmit(e)}
      >
        <section
          className={styles.row}
        >
          <div className={styles.half}>
            <TaInputText
              key={'searchString'+activeError}
              name="searchString"
              iconBefore={'report'}
              value={activeError}
              placeholder="Not in Dictionary"
              readOnly={true}
              className={styles.margin}
            />
            <TaInputText
              name="replaceString"
              key={'replaceString'+defaultReplaceString}
              value={defaultReplaceString}
              iconBefore={'edit'}
              onEvent={this.onInput}
              placeholder="Replace with"
              clear={true}
              className={styles.margin}
            />
          </div>
          <div className={styles.suggestionsWrapper}>
            <TaInputDropdown
              key={'suggestions'+defaultReplaceString}
              value={defaultReplaceString}
              className={styles.suggestions}
              onEvent={this.onSuggestion}
              name={'suggestions'}
              options={suggestions}
              size={10}
            />
          </div>
        </section>
        <section
          className={styles.row}
        >
          {
            (!highlight.count && !isComplete) &&
            (
              <TaButton
                type={'submit'}
                label={(isChecking) ? 'Checking...': 'Check'}
                className={styles.margin}
                isLoading={isChecking}
              />
            )
          }
          {
            (!!highlight.count || isComplete) &&
              (
                <TaButton
                  type={'button'}
                  // icon={'clear'}
                  label={'Done'}
                  onClick={this.clear}
                  className={styles.margin}
                />
              )
          }
          <div className={styles.hspace}></div>
          {
            (!!highlight.count) && (
              <div className={styles.counter}>
                <span>{(highlight.activeIndex + 1) + '/' + highlight.count}</span>
              </div>
            )
          }
          {
            (!highlight.count && isComplete) && (
              <div className={styles.counter}>
                <span className={'ta-color--confirm'}>Complete</span>
                <TaIcon name={'check_circle'} className={'ta-color--confirm'}/>
              </div>
            )
          }
          <TaButton
            type={'button'}
            icon={'chevron_left'}
            onClick={goPrevHighlight}
            className={styles.margin}
            disabled={!highlight.count}
          />
          <TaButton
            type={'button'}
            icon={'chevron_right'}
            onClick={goNextHighlight}
            className={styles.margin}
            disabled={!highlight.count}
          />
          <TaButton
            type={'primary'}
            label={'Replace'}
            onClick={() => requestReplace(replaceString)}
            className={styles.margin}
            disabled={!highlight.count || !highlight.activeSegment}
          />
          {/*<TaButton*/}
          {/*  type={'button'}*/}
          {/*  label={'Replace All'}*/}
          {/*  onClick={()=>requestReplace(replaceString, true)}*/}
          {/*  className={styles.margin}*/}
          {/*  disabled={!highlight.count}*/}
          {/*/>*/}
        </section>
        {/*<pre> {JSON.stringify(this.state, null, 2)};</pre>*/}
        {/*<pre> {JSON.stringify(activeError, null, 2)};</pre>*/}
      </form>
    )
  }
}
