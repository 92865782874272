export const convertHtmlToText = (html) => {
  let element = document.createElement('div');
  element.innerHTML = html || '';
  return element.textContent || element.innerText || '';
}

export function escapeStringRegexp(string) {
  // see: https://github.com/sindresorhus/escape-string-regexp/blob/main/index.js
  if (typeof string !== 'string') {
    throw new TypeError('Expected a string');
  }
  // Escape characters with special meaning either inside or outside character sets.
  // Use a simple backslash escape when it’s always valid, and a `\xnn` escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
  return string
    .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
    .replace(/-/g, '\\x2d');
}