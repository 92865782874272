import React from 'react'
import FontAwesome from 'react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import { CONFIRM_MODAL } from 'misc/constants';
import { confirmYes, confirmNo } from 'actions/core';
import { isConfirmModalLoading } from 'selectors/loading';
import Spinner from 'components/core/Spinner';
import PureRenderComponent from 'components/core/PureRenderComponent';


class ConfirmModal extends PureRenderComponent {
  
  render() {
    
    const {
      show,
      handleHide,
      message,
      isLoading,
      confirmNo,
      confirmYes,
    } = this.props;
    
    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button
            className="pull-left"
            bsSize="sm"
            type="button"
            onClick={confirmNo}>
            <FontAwesome name="close" className="mr-5" />No
          </Button>
          <Button
            bsStyle="success"
            bsSize="sm"
            type="button"
            onClick={confirmYes}>
            <FontAwesome name="check" className="mr-5" />Yes
          </Button>
        </Modal.Footer>
        <Spinner isLoading={isLoading} />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: isConfirmModalLoading(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    confirmYes,
    confirmNo
  }, disptach)
}

ConfirmModal = connectModal({ name: CONFIRM_MODAL })(ConfirmModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmModal);

