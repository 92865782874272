import React from 'react';
import { Checkbox } from 'react-bootstrap';
import { datetimeFormatter } from 'misc/cellFormatters';
import { JOB_DETAIL } from 'misc/constants';
import { LinkFieldCellRenderer } from 'misc/cellRenderers';
import ListTable from 'components/core/ListTable';
import PureRenderComponent from 'components/core/PureRenderComponent';

const CheckboxCellRenderer = (props) => {
  const { data, colDef } = props;
  const { handleSelect, selected } = colDef.cellRendererParams;
  return data && data.id
    ? (
      <Checkbox
        checked={selected.indexOf(data.id) !== -1}
        value={data.id}
        onChange={handleSelect}
        className="mt-5 mb-5" />
    ) : null
}


class JobRnQListTable extends PureRenderComponent {
  getColumnDefs = () => {
    const  {
      handleTabOpen,
      selected,
      handleSelect
     } = this.props;
    
     const columnDefs = []

    columnDefs.push({
      headerName: '',
      field: '',
      suppressFilter: true,
      pinned: 'left',
      width: 30,
      cellRendererFramework: CheckboxCellRenderer,
      cellRendererParams: {
        selected: selected,
        handleSelect: handleSelect
      },
    })
    columnDefs.push({
      headerName: 'No.',
      field: 'number',
      pinned: 'left',
      cellClass: 'ag-jobNumber',
      width: 140,
      cellRendererFramework: LinkFieldCellRenderer,
      cellRendererParams: {
        handleTabOpen: handleTabOpen,
        tab: JOB_DETAIL,
      },
    });
    
    columnDefs.push({
      headerName: 'Client',
      field: 'client.name',
      width: 250,
    });
    columnDefs.push({
      headerName: 'Subject',
      field: 'subject',
      width: 300,
    });
    columnDefs.push({
      headerName: 'Deadline',
      field: 'deadline',
      width: 150,
      valueFormatter: datetimeFormatter
    });
    columnDefs.push({
      headerName: 'Lv',
      field: 'level',
      width: 40,
    });
    columnDefs.push({
      headerName: 'Coordinator',
      field: 'coordinator.name',
      width: 120,
    });
    columnDefs.push({
      headerName: 'Status',
      field: 'status',
      width: 140,
      cellClass: 'statusRow',
    });

    return columnDefs;
  }

  render() {
    return (
      <ListTable
        wrapperHeight="83vh"
        columnDefs={this.getColumnDefs()}
        rowData={this.props.jobs}
        getRowClass={this.getRowClass}
        deltaRowDataMode={true}
        getRowNodeId={data => data.id} />
    )
  }
}

export default JobRnQListTable;
