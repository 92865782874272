import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {hide, show} from 'redux-modal';
import {reset, stopSubmit} from 'redux-form';
import PureRenderComponent from 'components/core/PureRenderComponent';
import WordListApi from "services/WordListApi";
import {retrieveRequest, exportExcelRequest, updateWordListRequest} from "actions/wordlists";
import {
  WORDLIST_DETAIL_IMPORT_MODAL,
  WORDLIST_DETAIL_SETTINGS_MODAL,
  WORDLIST_DETAIL_IMPORT_FORM
} from "misc/constants";
import {WordListDetail} from "components/mht/WordListDetail/presenter";
import {TaPane} from "components/taUi/taPane/taPane";
import {getCurrentWordListDetail} from "selectors/lists";
import {TaModal, TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";
import {
  getListSettingsColumns,
  getListSettingsUpdated
} from "../../../selectors/wordListUi";
import {setLanguageColumns, resetColumn} from "../../../actions/wordListUi";

class WordListDetailContainer extends PureRenderComponent {

  static defaultProps = {
    wordList: null
  };

  constructor() {
    super();

    this.defaultFilter = () => {
      return {
        pendingOnly: false
      };
    }
    this.defaultSearch = () => {
      return {
        string: '',
        isValid: false
      };
    }
    this.searchDebounce = null;

    this.state = {
      updated: 0,
      scrollToBottom: 0,
      items: [],
      isLoading: true,
      isSaving: false,
      isSavingModal: false,
      showConfirmClearModal: false,
      showConfirmDeleteModal: false,
      page: 1,
      itemsPerPage: 50,
      totalItemCount: 0,
      isFiltered: false,
      search: this.defaultSearch(),
      filter: this.defaultFilter(),
    };
  }

  componentDidMount() {
    this.props.retrieveRequest(this.props.params.id);

    // todo: timeout is a hack...
    this.loadTimeout = setTimeout(this.loadItems, 100);

  }

  componentWillUnmount() {
    clearTimeout(this.loadTimeout)
    this.props.resetColumn({ wordlistKey: this.props.params.id });
  }

  loadItems = (page, scrollToBottom) => {
    this.setState({
      isLoading: true
    }, () => {

      let filter = {}
      if (this.state.filter) {
        Object.keys(this.state.filter).forEach(key => {
          if (this.state.filter[key]) {
            filter[key] = this.state.filter[key];
          }
        })
      }

      if (this.state.search.string) {
        filter.string = this.state.search.string;
      }

      WordListApi
        .listItems(this.props.params.id, page || 1, filter)
        .then(response => {

          let newState = {
            updated: Date.now(),
            items: response.data.results, //response.data.results,
            totalItemCount: response.data.count,
            isLoading: false,
            isFiltered: !!(filter && Object.keys(filter).length)
          };

          const totalPages = this.calcTotalPageCount(newState.totalItemCount, this.state.itemsPerPage);
          let newPage = (page) ? page : this.state.page;

          if (newPage > totalPages) {
            newPage = totalPages;
          }
          newState.page = newPage;

          if (scrollToBottom) {
            newState.scrollToBottom = Date.now();
          }

          this.setState(newState);
        })
        .catch(error => {
          console.error('loadList - error', error);
        });
    });
  };

  triggerModal = (name) => {

    const props = this.props;
    const list = props.wordList;

    switch (name) {

      case 'clear':

        return WordListApi
          .clearList(this.props.wordList.id)
          .then(response => {
            this.setState({
              updated: Date.now(),
              items: []
            });
          });

      case 'delete':
        this.setState({
          showConfirmDeleteModal: true
        });
        break;

      case 'import':
        props.show(WORDLIST_DETAIL_IMPORT_MODAL, {
          onSubmit: (values) => {
            WordListApi.importItems(list.id, values)
              .then(response => {
                if (response.data.saved) {
                  this.loadItems();
                }
                props.hide(WORDLIST_DETAIL_IMPORT_MODAL);
                props.reset(WORDLIST_DETAIL_IMPORT_FORM);
              })
              .catch(error => {
                if (error.response.status === 400) {
                  this.props.stopSubmit(
                    WORDLIST_DETAIL_IMPORT_FORM,
                    {_error: error.response.data.detail}
                  );
                }
              });
          }
        });
        break;

      case 'export':

        const settings = props.listSettingsColumns;
        const languagesToExport = (Object.keys(settings)).filter(key => {
          return settings[key].visible;
        }).sort((a, b) => (
            settings[a].order - settings[b].order
        ));
        this.props.exportExcelRequest(
            list.id,
            list.name,
            languagesToExport
        );
        // props.show(WORDLIST_DETAIL_EXPORT_MODAL, {
        //   onSubmit: (values) => {
        //     console.log('onSubmit - export modal', values);
        //   },
        //   listId: list.id,
        //   initialValues: {}
        // });
        break;

      default:
        props.show(WORDLIST_DETAIL_SETTINGS_MODAL, {
          onSubmit: (values) => {
            this.props.updateWordListRequest({
              id: list.id,
              data: values
            });
          },
          listId: list.id,
          initialValues: list
        });
    }

  };

  onSave = (mutations, languages, forceMerge) => {

    const listId = this.props.params.id;
    this.setState({
      isSaving: true,
      errors: {}
    }, () => {

      let errors = {};
      let insertPromises = [];
      let updateItems = [];

      Object
        .keys(mutations.data)
        .forEach((key) => {

          let value = {
            wordlist: listId
          };

          if (mutations.rejected[key]) {
            value.is_pending = true;
          } else if (mutations.committed[key]) {
            value.is_pending = false;
          } else {
            value.is_pending = mutations.data[key].is_pending;
          }

          languages.forEach((language) => {
            if (typeof mutations.data[key][language.key] !== 'undefined') {
              value[language.key] = mutations.data[key][language.key];
            }
          });

          if (
            mutations.added[key]
          ) {
            insertPromises.push(WordListApi.addItem(value).catch(error => {
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.non_field_errors &&
                error.response.data.non_field_errors.indexOf("Sorry, that item already exists.") >= 0
              ) {
                errors.hasDuplicate = true;
                if (typeof errors.items === 'undefined') {
                  errors.items = {};
                }
                if (typeof errors.duplicate === 'undefined') {
                  errors.duplicate = {};
                }
                errors.items[key] = value;
                errors.duplicate[key] = true;
              }
            }));

          } else if (
            !mutations.removed[key]
          ) {
            value.id = key;
            updateItems.push(value);
          }
        });

      // do in sequence to deliver one error at the time

      Promise
        .all(insertPromises)
        .then(response => {
          // Update
          let promise;
          if (updateItems && updateItems.length) {
            promise = new Promise(function (resolve, reject) {
              WordListApi
                .batchSaveItems(updateItems, forceMerge)
                .then(updateResponse => {
                  const updateResponseErrors = updateResponse.data.errors;
                  if (
                    updateResponseErrors &&
                    updateResponseErrors.length
                  ) {
                    errors.hasMerge = true;
                    if (typeof errors.items === 'undefined') {
                      errors.items = {};
                    }
                    if (typeof errors.merge === 'undefined') {
                      errors.merge = {};
                    }
                    updateResponseErrors.forEach(item => {
                      errors.items[item.id] = item;
                      errors.merge[item.id] = true;
                    });
                    reject();
                  } else {
                    resolve();
                  }
                }).catch(reject)
            });
          } else {
            promise = new Promise(function (resolve, reject) {
              resolve();
            });
          }
          return promise;
        })
        .then(response => {
          // remove
          let promise;
          const removeItems = Object.keys(mutations.removed);
          if (removeItems && removeItems.length) {
            promise = WordListApi.batchDeleteItems(removeItems);
          } else {
            promise = new Promise(function (resolve, reject) {
              resolve();
            });
          }
          return promise;
        })
        .catch(error => {
          errors.unknown = error;
          console.error(error);
        })
        .finally(() => {
          if (Object.keys(errors).length) {
            this.setState({
              isSaving: false,
              errors: errors
            });
          } else {
            this.setState({
              isSaving: false
            }, () => {
              this.loadItems(this.state.page)
            });
            // todo: push results to state instead of reload
          }
        });

    });

  };

  hideConfirmDeleteModal = () => {
    this.setState({
      showConfirmDeleteModal: false
    });
  };

  onConfirmDeleteModal = () => {
    this.setState({
      isSavingModal: true
    }, () => {
      WordListApi
        .deleteList(this.props.wordList.id)
        .then(response => {
          this.setState({
            isSavingModal: false,
            showConfirmDeleteModal: false
          }, () => {
            //todo: close tab
          });
        });
    });
  };

  onDismissError = (errorKey) => {

    let errors = this.state.errors;

    if (errorKey === 'merge') {
      errors.merge = {};
      errors.hasMerge = false;
    } else if (errorKey === 'duplicate') {
      errors.duplicate = {};
      errors.hasDuplicate = false;
    }

    this.setState({
      updated: Date.now(),
      errors: errors
    });

  };

  calcTotalPageCount = (totalItemCount, itemsPerPage) => {
    return Math.ceil(totalItemCount / itemsPerPage);
  }

  onSearchInput = (value) => {

    let newSearch = this.defaultSearch();
    newSearch.string = encodeURIComponent(value);
    newSearch.isValid = (value.length > 1);

    this.setState({
      search: newSearch
    }, () => {
      if (this.searchDebounce) {
        clearTimeout(this.searchDebounce);
      }
      this.searchDebounce = setTimeout(this.loadItems, (newSearch.isValid) ? 500 : 0);
    });

  }

  onFilterInput = (key, value) => {

    let newFilter = this.defaultFilter();
    newFilter[key] = value;

    this.setState({
      filter: newFilter,
    }, () => {
      this.loadItems()
    });

  }

  goToPage = (page, scrollToBottom) => {
    this.loadItems(page, scrollToBottom)
  }

  onToggleLanguageColumn = (columns)=> {
    const props = this.props;
    props.setLanguageColumns({
      wordlistKey: props.params.id,
      columns: columns
    })
  }

  render() {

    const props = this.props;
    const state = this.state;
    const wordList = (props.wordList) ? props.wordList : {};

    const totalPageCount = this.calcTotalPageCount(state.totalItemCount, state.itemsPerPage)
    const isLastPage = totalPageCount === state.page;

    return (
      <React.Fragment>
        <TaPane size={'page'}>
          <WordListDetail
            updated={state.updated + props.listSettingsUpdated}
            list={wordList}
            columns={props.listSettingsColumns}
            items={state.items}
            errors={state.errors}
            isLoading={state.isLoading}
            isSaving={state.isSaving}
            onModal={this.triggerModal}
            onSave={this.onSave}
            onDismissError={this.onDismissError}
            isFiltered={state.isFiltered}
            page={state.page}
            itemsPerPage={state.itemsPerPage}
            totalPageCount={totalPageCount}
            totalItemCount={state.totalItemCount}
            isLastPage={isLastPage}

            filter={state.filter}
            search={state.search}

            scrollToBottom={state.scrollToBottom}
            onSearchInput={this.onSearchInput}
            onFilterInput={this.onFilterInput}
            onToggleLanguageColumn={this.onToggleLanguageColumn}
            goToPage={this.goToPage}
          />
        </TaPane>
        <TaModal
          isOpen={state.showConfirmDeleteModal}
          onCancel={this.hideConfirmDeleteModal}
        >
          <TaModalHeader
            label={'Delete Wordlist'}
            onCancel={this.hideConfirmDeleteModal}
          />
          <TaModalContent type={'text'}>
            <p>Are you sure you want to continue?</p>
            <p>This list will be <b>permanently deleted</b>.</p>
          </TaModalContent>
          <TaModalFooter>
            <TaButton
              type="reset"
              icon={'clear'}
              label={'Cancel'}
              onClick={this.hideConfirmDeleteModal}
            />
            <TaButton
              type="submit"
              icon={'delete'}
              iconAlign={'right'}
              label={'Delete'}
              onClick={this.onConfirmDeleteModal}
              isLoading={state.isLoading}
            />
          </TaModalFooter>
        </TaModal>
      </React.Fragment>

    );
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    wordList: getCurrentWordListDetail(state),
    listSettingsColumns: getListSettingsColumns(state,ownProps.params.id),
    listSettingsUpdated: getListSettingsUpdated(state,ownProps.params.id)
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    show,
    hide,
    reset,
    stopSubmit,
    retrieveRequest,
    updateWordListRequest,
    exportExcelRequest,
    setLanguageColumns,
    resetColumn
  }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WordListDetailContainer);