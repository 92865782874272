import axios from 'axios';

const GlossaryApi = {
  list: (params) => {
    return axios.get(`glossaries/`, {params});
  },
  listAll: () => {
    return axios.get(`glossaries/all/`);
  },
  retrieve: (id, config) => {
    return axios.get(`glossaries/${id}/`, config);
  },
  update: (id, config) => {
    return axios.put(`glossaries/${id}/`, config);
  },
  batchDelete: (values) => {
    return axios.post(`glossaries/batch-delete/`, values);
  },
  upload: (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('file', values.file);
    formData.append('first_language', values.firstLanguage);
    formData.append('second_language', values.secondLanguage);
    formData.append('category', values.category);
    formData.append('notes', values.notes || '');
    return axios.post(`glossaries/upload/`, formData);
  },
  exportExcel: (params, onDownloadProgress) => {
    const { id, colKeys } = params;
    return axios({
      url: `glossaries/${id}/export/`,
      method: 'POST',
      data: {
        keys: colKeys
      },
      responseType: 'blob',
      onDownloadProgress
    });
  },
  importItems: (id, values) => {
    const formData = new FormData();
    formData.append('file', values.file);
    return axios.post(`glossaries/${id}/import/`, formData);
  },
  listItems: (listId, page, filter) => {
    let url = `glossaries/${listId}/items/`;
    let queryString = [];
    if (page === 'all') {
      url += 'all/'
    } else if (page) {
      queryString.push('page='+page)
    }
    if (filter && filter.string) {
      queryString.push('term='+filter.string)
    }
    if (filter && filter.pendingOnly) {
      queryString.push('is_pending='+filter.pendingOnly)
    }
    if(queryString.length) {
      url += '?';
      url += queryString.join('&')
    }
    return axios.get(url)
  },
  addItem: (item) => {
    return axios.post(`glossary-items/`, item);
  },
  batchSaveItems: (items, forceMerge) => {
    return axios.post(`glossary-items/batch-save/`, {items: items, force_merge: !!forceMerge});
  },
  batchDeleteItems: (itemIds) => {
    return axios.post(`glossary-items/batch-delete/`, {items: itemIds});
  },
};

export default GlossaryApi;
