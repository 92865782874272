import { v4 as uuidv4 } from 'uuid';
import * as types from 'actionTypes/globalTabs';

export const addTab = (
  id,
  title,
  componentPath,
  componentName,
  params = {},
  allowRefresh = false,
  requireCloseConfirm = false
) => ({
  type: types.ADD_TAB,
  payload: {
    id,
    title,
    componentPath,
    componentName,
    params,
    key: uuidv4(),
    allowRefresh,
    requireCloseConfirm
  }
});

export const addOrSelectTab = (
  id,
  title,
  componentPath,
  componentName,
  params = {},
  allowRefresh = false,
  requireCloseConfirm = false
) => ({
  type: types.ADD_OR_SELECT_TAB,
  payload: {
    id,
    title,
    componentPath,
    componentName,
    params,
    allowRefresh,
    requireCloseConfirm
  }
});

export const selectTab = (id) => ({
  type: types.SELECT_TAB,
  payload: { id }
});

export const closeTab = (id) => ({
  type: types.CLOSE_TAB,
  payload: { id }
});

export const refreshTab = () => {
  const newKey = uuidv4();
  return {
    type: types.REFRESH_TAB,
    payload: { key: newKey }
  }
}

export const clearTabs = () => ({
  type: types.CLEAR_TABS,
})

export const setTabTitle = (id, title) => ({
  type: types.SET_TAB_TITLE,
  payload: { id, title }
});

export const setConfirmRequire = (id, flag) => ({
  type: types.SET_CONFIRM_REQUIRE,
  payload: { id, flag }
})

export const confirmTabClose = (tab) => ({
  type: types.CONFIRM_TAB_CLOSE,
  payload: { tab }
})
