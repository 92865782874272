import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';
import { assignmentFinishRequest } from 'actions/jobs/managers';
import {
  projectDownloadRequest,
  assignmentProjectUploadRequest,
  assignmentProjectRemoveRequest
} from 'actions/documents'
import { getAssignmentProjectsById } from 'selectors/lists';
import { EMPLOYEE_ASSIGNMENT_PROJECT_UPLOAD_MODAL } from 'misc/constants';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobAssignment from './presenter';


class JobAssignmentContainer extends PureRenderComponent {

  showUploadModal = () => {
    this.props.show(EMPLOYEE_ASSIGNMENT_PROJECT_UPLOAD_MODAL, {
      title: 'Completed File',
      handleUpload: this.handleUpload
    })
  }

  handleUpload = (files) => {
    this.props.assignmentProjectUploadRequest(this.props.assignmentId, files);
  }

  handleDownload = (file) => {
    this.props.projectDownloadRequest(file);
    this.props.handleJobStart && this.props.handleJobStart();
  }

  handleFinish = (assignment) => {
    this.props.assignmentFinishRequest(assignment);
  }

  handleRemove = (file) => {
    this.props.assignmentProjectRemoveRequest(this.props.assignmentId, file)
  }

  render() {
    return (
      <JobAssignment
        {...this.props}
        showUploadModal={this.showUploadModal}
        handleUpload={this.handleUpload}
        handleDownload={this.handleDownload}
        handleFinish={this.handleFinish}
        handleRemove={this.handleRemove} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const assignmentId = props.assignmentId;
  return {
    projects: getAssignmentProjectsById(state, assignmentId)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    assignmentFinishRequest,
    projectDownloadRequest,
    assignmentProjectUploadRequest,
    assignmentProjectRemoveRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobAssignmentContainer);