import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, getFormValues } from 'redux-form';
import { JOB_EDIT_FORM } from 'misc/constants';
import { parseAssignmentValues } from 'misc/utils';
import { isJobEditLoading } from 'selectors/loading';
import { getJobEditFormInitialValuesById } from 'selectors/forms';
import { getJobValueById } from 'selectors/jobs';
import { editRequest, retrieveRequest, removeRequest } from 'actions/jobs/managers';
import { getMyUserId, isSuperuser } from 'selectors/auth';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobEdit from './presenter';


class JobEditContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.retrieveRequest(this.props.id);
  }

  handleEdit = (values) => {
    const formData = parseAssignmentValues(values);
    this.props.editRequest(this.props.form, this.props.id, formData);
  }

  handleRemove = () => {
    this.props.removeRequest(this.props.job);
  }

  render() {
    return (
      <JobEdit
        {...this.props}
        handleEdit={this.handleEdit}
        handleRemove={this.handleRemove} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const { id } = props.params;
  const form = `${JOB_EDIT_FORM}/${id}`;
  const formSelector = formValueSelector(form);
  const client = formSelector(state, 'client');
  const job = getJobValueById(state, id);
  const isSplit = !!job.branch;
  const isMHJob = job.type === "MH";
  return {
    id,
    form,
    client,
    isSplit,
    isMHJob,
    job,
    myUserId: getMyUserId(state),
    isSuperuser: isSuperuser(state),
    formValues: getFormValues(form)(state),
    initialValues: getJobEditFormInitialValuesById(state, id),
    isLoading: isJobEditLoading(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    retrieveRequest,
    removeRequest,
    editRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobEditContainer);
