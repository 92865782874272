import React from 'react';
import { Content } from 'reactjs-admin-lte';
import TemplateEditForm from './TemplateEditForm';


const TemplateEdit = (props) => {
  return (
    <Content.Body>
      <TemplateEditForm {...props} />
    </Content.Body>
  );
}

export default TemplateEdit;