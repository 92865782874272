import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import classNames from 'classnames';
import { refreshTab } from 'actions/globalTabs'
import { isRefreshableTab } from 'selectors/globalTabs';
import PureRenderComponent from 'components/core/PureRenderComponent';


class HeaderRefreshMenu extends PureRenderComponent {

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.isRefreshableTab && 
    this.props.refreshTab();
  }
  
  render() {
    const cls = classNames({
      'refresh-btn': true,
      'disabled': !this.props.isRefreshableTab,
    })
    
    return (
      <li className={cls}>
        <a onClick={this.handleRefresh}>
          <FontAwesome name="refresh" />
        </a>
      </li>
    );
  } 
}


const mapStateToProps = (state) => {
  return {
    isRefreshableTab: isRefreshableTab(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    refreshTab
  }, dispatch)
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderRefreshMenu);
