import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isUserSecondFinalEditSkillLoading } from 'selectors/loading';
import { getSecondFinalEditSkillsByUserId } from 'selectors/users';
import {
    secondFinalEditSkillListRequest,
    secondFinalEditSkillCreateRequest,
    secondFinalEditSkillUpdateRequest,
} from 'actions/users'
import UserSkillHOC from './UserSkillHOC';
import UserSkill from './presenter';


const mapStateToProps = (state, props) => {
  const id = props.userId;
  return {
    isLoading: isUserSecondFinalEditSkillLoading(state, id),
    skills: getSecondFinalEditSkillsByUserId(state, id)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest: secondFinalEditSkillListRequest,
    createRequest: secondFinalEditSkillCreateRequest,
    updateRequest: secondFinalEditSkillUpdateRequest,
  }, disptach)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSkillHOC(UserSkill))
