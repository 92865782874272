import React from 'react';
import { Table } from 'react-bootstrap';

const percentRound = (num, digit) => {
  const digitVal = Math.pow(10, digit);
  let retVal;
  if (digitVal < 1) {
    retVal = Math.round(num * digitVal) * Math.pow(10, -1 * digit);
  } else {
    retVal = Math.round(num * digitVal) / digitVal;
  }
  return retVal;
}


const getCountRatioValue = (analysis, key) => {
  const count = analysis[key] || 0;
  const ratio = percentRound(((count / analysis['totalCount']) * 100), 2)
  return { count, ratio }
}

const MHTAnalysisTable = (props) => {
  const { analysis } = props;
  const autoLockValue = getCountRatioValue(analysis, "autoLockCount")
  const repeatValue = getCountRatioValue(analysis, "repeatCount")
  const perfectMatchValue = getCountRatioValue(analysis, "memoryPerfectMatchCount")
  const generalMatchValue = getCountRatioValue(analysis, "memoryGeneralMatchCount")
  const closeMatchValue = getCountRatioValue(analysis, "memoryCloseMatchCount")
  const fuzzyMatchValue = getCountRatioValue(analysis, "memoryFuzzyMatchCount")
  const termMatchValue = getCountRatioValue(analysis, "termMatchCount")
  const newValue = getCountRatioValue(analysis, "newCount")
  const sumValue = getCountRatioValue(analysis, "totalCount")

  const lockValue = getCountRatioValue(analysis, "lockCount")
  const totraValue = getCountRatioValue(analysis, "totraCount")

  const totalSourceVolumeCount = sumValue.count - (lockValue.count + totraValue.count)
  const totalSourceVolumeRatio = percentRound(((totalSourceVolumeCount / sumValue.count) * 100), 2)

  return (
    <React.Fragment>
      <Table striped bordered className="mb-10">
        <thead>
          <tr>
            <th
              className="text-center"
              colSpan={2}>
                Source<br />
                (Character/Word Count)
            </th>
            <th
              style={{ verticalAlign: 'bottom' }}>%
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width="55%">Repeat</td>
            <td width="20%">{repeatValue.count}</td>
            <td width="25%">{repeatValue.ratio}%</td>
          </tr>
          <tr>
            <td width="55%">Auto Lock</td>
            <td width="20%">{autoLockValue.count}</td>
            <td width="25%">{autoLockValue.ratio}%</td>
          </tr>
          <tr>
            <td>Perfect match</td>
            <td>{perfectMatchValue.count}</td>
            <td>{perfectMatchValue.ratio}%</td>
          </tr>
          <tr>
            <td>General match</td>
            <td>{generalMatchValue.count}</td>
            <td>{generalMatchValue.ratio}%</td>
          </tr>
          <tr>
            <td>Close match</td>
            <td>{closeMatchValue.count}</td>
            <td>{closeMatchValue.ratio}%</td>
          </tr>
          <tr>
            <td>Fuzzy match</td>
            <td>{fuzzyMatchValue.count}</td>
            <td>{fuzzyMatchValue.ratio}%</td>
          </tr>
          <tr>
            <td>Term match</td>
            <td>{termMatchValue.count}</td>
            <td>{termMatchValue.ratio}%</td>
          </tr>
          <tr>
            <td>New</td>
            <td>{newValue.count}</td>
            <td>{newValue.ratio}%</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>{sumValue.count}</td>
            <td>{sumValue.ratio}%</td>
          </tr>
        </tbody>
      </Table>
      <Table striped bordered className="mb-10">
        <tbody>
          <tr>
            <td width="55%">Lock</td>
            <td width="20%">{lockValue.count}</td>
            <td width="25%">{lockValue.ratio}%</td>
          </tr>
          <tr>
            <td>Totra</td>
            <td>{totraValue.count}</td>
            <td>{totraValue.ratio}%</td>
          </tr>
          <tr>
            <td><strong>Total source volume</strong></td>
            <td><strong>{totalSourceVolumeCount}</strong></td>
            <td><strong>{totalSourceVolumeRatio}%</strong></td>
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  )
}

export default MHTAnalysisTable;