import React from 'react';
import { Tab } from 'react-bootstrap';

const UserDetailTabPane = (props) => {
  const {
    eventKey,
    children
  } = props;
    
  return (
    <Tab.Pane
      eventKey={eventKey}
      mountOnEnter={true}>
      {children}
    </Tab.Pane>
  );
}

export default UserDetailTabPane;
