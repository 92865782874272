import React from 'react';
import { Tab } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import JobToTraListTable from './JobToTraListTable';


const JobToTraListTabPane = (props) => {
  const {
    eventKey,
    jobs,
    isLoading,
    handleTabOpen
  } = props;
    
  return (
    <Tab.Pane eventKey={eventKey}>
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body noPadding={true}>
          <JobToTraListTable
            jobs={jobs}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
      </Box>
    </Tab.Pane>
  );
}

export default JobToTraListTabPane;
