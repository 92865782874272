import React from 'react';
import * as PropTypes from "prop-types";
import {
  TaModal,
  TaModalContent,
  TaModalContentMargin,
  TaModalFooter,
  TaModalHeader
} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";
import VirtualizedSelect from "react-virtualized-select";
import {TaFormField} from "../../taUi/taFormField/taFormField";

const composeJobOption = (job) => ({
  label: job.number,
  value: job.id
});

class TranslationMemoryWarningModal extends React.Component {

  static propTypes = {
    label: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      selectedJobs: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (
        prevProps.isOpen !== this.props.isOpen
    ) {
      this.setState({
        selectedJobs: this.props.jobs ? this.props.jobs.map(composeJobOption) : []
      });
    }

  }

  handleSelectedJobsChange = (upatedSelectedJobs) => {
    this.setState({
      selectedJobs: upatedSelectedJobs
    });
  }

  render() {

    const state = this.state;

    const {
      isOpen,
      isSaving,
      isError,
      onCancel,
      onConfirm,
      label,
      children,
        jobs
    } = this.props;

    return (
      <TaModal
        isOpen={isOpen}
        onCancel={onCancel}
      >
        <TaModalHeader
          label={label}
          warning={!isError}
          error={isError}
          onCancel={onCancel}
        />
        <TaModalContent scroll={'auto'}>
          <TaModalContentMargin>
            {children}
            {jobs ? (
                <TaFormField
                    label={"Selected Jobs"}
                >
                  <VirtualizedSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      multi={true}
                      simpleValue={false}
                      filterOption={(option, filterString) => {
                        return option.label && option.label.toLowerCase().indexOf(filterString.toLowerCase()) !== -1
                      }}
                      isLoading={false}
                      value={state.selectedJobs}
                      options={jobs.map(composeJobOption)}
                      onChange={v=>this.handleSelectedJobsChange(v)}
                  />
                </TaFormField>

            ) : null}
          </TaModalContentMargin>
        </TaModalContent>
        <TaModalFooter>
          <TaButton
            type="reset"
            icon={'clear'}
            label={'Cancel'}
            onClick={onCancel}
          />
          <TaButton
            type="submit"
            icon={(isError) ? '' : 'check'}
            label={(isError) ? 'Review' : 'Confirm'}
            onClick={()=> {
              if (typeof jobs !== 'undefined') {
                onConfirm([...state.selectedJobs.map(j=>j.value)])
              } else {
                onConfirm()
            }}}
            isLoading={isSaving}
          />
        </TaModalFooter>
      </TaModal>
    );
  }
}

export default TranslationMemoryWarningModal;