import React from 'react'
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Button, Modal, Form } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import { Field, reduxForm } from 'redux-form';
import { MHT_JOB_MESSAGE_MODAL, MHT_JOB_MESSAGE_FORM } from 'misc/constants';
import { isJobMessageLoading } from 'selectors/loading';
import TextField from 'components/core/TextField'
import Spinner from 'components/core/Spinner';
import * as validators from 'misc/validators';
import PureRenderComponent from 'components/core/PureRenderComponent';

class MHTJobMessageModal extends PureRenderComponent {

  handleSubmit = (values) => {
    this.props._handleSubmit(values);
  }

  render() {
    const {
      show,
      isLoading,
      handleHide,
      handleSubmit,
    } = this.props

    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              name="body"
              label="Message"
              validate={[validators.required]}
              isRequired={true}
              component={TextField.Labeled} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pull-left"
              bsSize="sm"
              type="button"
              onClick={handleHide}>
              <FontAwesome name="close" className="mr-5" />Close
            </Button>
            <Button
              bsStyle="primary"
              bsSize="sm"
              type="submit">
              <FontAwesome name="paper-plane-o" className="mr-5" />Submit
            </Button>
          </Modal.Footer>
        </Form>
        <Spinner isLoading={isLoading} />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: isJobMessageLoading(state),
  }
}


MHTJobMessageModal = connectModal({ name: MHT_JOB_MESSAGE_MODAL })(MHTJobMessageModal);
MHTJobMessageModal = reduxForm({ form: MHT_JOB_MESSAGE_FORM })(MHTJobMessageModal);

export default connect(
  mapStateToProps,
)(MHTJobMessageModal);

