import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import UserTranslationSkill from './UserTranslationSkill';
import UserEditSkill from './UserEditSkill';
import UserCheckSkill from './UserCheckSkill';
import UserFinalEditSkill from './UserFinalEditSkill';
import UserSecondFinalEditSkill from './UserSecondFinalEditSkill';



const UserSkill = (props) => {

  const {
    userId,
    hasTranslatorRole,
    hasEditorRole,
    hasCheckerRole,
    hasFinalEditorRole,
    hasSecondFinalEditorRole
  } = props;

  const getDefaultActiveKey = () => {
    if (hasTranslatorRole) {
      return 1;
    } else if (hasEditorRole) {
      return 2;
    } else if (hasCheckerRole) {
      return 3;
    } else if (hasFinalEditorRole) {
      return 4;
    } else if (hasSecondFinalEditorRole) {
      return 5;
    } else {
      return 1;
    }
  }

  return (
    <Tabs
      defaultActiveKey={getDefaultActiveKey()}
      animation={false}
      mountOnEnter={true}
      id="user-skills-tabs"
      className="nav-tabs-custom mb-0">
      { hasTranslatorRole &&
        <Tab eventKey={1} title="Translation">
          <UserTranslationSkill userId={userId} />
        </Tab>
      }
      { hasEditorRole &&
        <Tab eventKey={2} title="Edit">
          <UserEditSkill userId={userId} />
        </Tab>
      }
      { hasCheckerRole &&
        <Tab eventKey={3} title="Check">
          <UserCheckSkill userId={userId} />
        </Tab>
      }
      { hasFinalEditorRole &&
        <Tab eventKey={4} title="FE">
          <UserFinalEditSkill userId={userId} />
        </Tab>
      }
      { hasSecondFinalEditorRole &&
        <Tab eventKey={5} title="2nd FE">
          <UserSecondFinalEditSkill userId={userId} />
        </Tab>
      }
    </Tabs>
  );
}

export default UserSkill;
