import SelectField from './SelectField';
import LabeledSelectField from './LabeledSelectField';
import HorizontalSelectField from './HorizontalSelectField';
import MultiSelectField from './MultiSelectField';
import MultiSelectHirizontalField from './MultiSelectHirizontalField';

SelectField.Labeled = LabeledSelectField;
SelectField.Horizontal = HorizontalSelectField;
SelectField.Multi = MultiSelectField;
SelectField.MultiHorizontal = MultiSelectHirizontalField;

export default SelectField;
