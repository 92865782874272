import React from 'react';
import ListTable from 'components/core/ListTable';

const percentFormatter = ({ value }) => {
  return value
    ? `${Math.round(value)}%`
    : '0%'
}


const MHTReportTable = (props) => {
  console.log(props);

  const { results } = props;

  const columnDefs = [{
    headerName: '',
    field: 'title',
    pinned: 'left',
    width: 100,
    lockPinned: true,
    lockPosition: true,
    suppressMovable: true,
    suppressSorting: true,
    suppressFilter: true,
  }];

  columnDefs.push({
    field: 'avgDaily',
    headerName: 'Daily',
    cellClass: 'ag-text-right',
    width: 90,
    suppressMovable: true,
    suppressSorting: true,
    suppressFilter: true,
    lockPinned: true,
    valueFormatter: percentFormatter
  });

  columnDefs.push({
    field: 'avgWeekly',
    headerName: 'Weekly',
    cellClass: 'ag-text-right',
    width: 90,
    suppressMovable: true,
    suppressSorting: true,
    suppressFilter: true,
    lockPinned: true,
    valueFormatter: percentFormatter
  });

  const getPinnedBottomRowData = () => {
    const { avgMonthly } = props;
    return [avgMonthly];
  }

  return (
    <ListTable
      wrapperHeight="81vh"
      columnDefs={columnDefs}
      pinnedBottomRowData={getPinnedBottomRowData()}
      rowData={results} />
  );
}

export default MHTReportTable;
