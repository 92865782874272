import React from 'react'
import FontAwesome from 'react-fontawesome';
import { Button, Modal, Form } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import { Field, reduxForm } from 'redux-form';
import { MHT_INVOICE_DOWNLOAD_MODAL, MHT_INVOICE_DOWNLOAD_FORM } from 'misc/constants';
import * as validators from 'misc/validators';
import Spinner from 'components/core/Spinner';
import CharField from 'components/core/CharField';
import SelectField from 'components/core/SelectField';
import PureRenderComponent from 'components/core/PureRenderComponent';


class MHTInvoiceDownloadModal extends PureRenderComponent {

  handleSubmit = (values) => {
    this.props.handleDownload(values);
  }

  render() {
    const {
      show,
      handleHide,
      isLoading,
      handleSubmit,
    } = this.props;
    
    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Download Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              name="to"
              label="TO"
              validate={[validators.required]}
              isRequired={true}
              component={CharField.Labeled} />
            <Field
              name="language"
              label="Language"
              validate={[validators.required]}
              isRequired={true}
              options={[{label: "Japanese", value: "ja-JP"}, {label: "English", value: "en-US"}]}
              component={SelectField.Labeled} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pull-left"
              bsSize="sm"
              type="button"
              onClick={handleHide}>
              <FontAwesome name="close" className="mr-5" />Cancel
            </Button>
              <Button
                bsStyle="primary"
                bsSize="sm"
                type="submit">
                <FontAwesome name="paper-plane-o" className="mr-5" />Submit
              </Button>
          </Modal.Footer>
          </Form>
        <Spinner isLoading={isLoading} />
      </Modal>
    );
  }
}

MHTInvoiceDownloadModal = connectModal({ name: MHT_INVOICE_DOWNLOAD_MODAL })(MHTInvoiceDownloadModal);
MHTInvoiceDownloadModal = reduxForm({ form: MHT_INVOICE_DOWNLOAD_FORM })(MHTInvoiceDownloadModal);

export default MHTInvoiceDownloadModal;
