import React from 'react';
import PureRenderComponent from 'components/core/PureRenderComponent';


const EmployeeCurrentJobsHOC = (WrappedComponent) => {
  
  return class extends PureRenderComponent {
    
    componentDidMount() {
      this.props.listRequest();
    }

    render() {
      return (
        <WrappedComponent
          {...this.props} />
      );
    }
  }
}


export default EmployeeCurrentJobsHOC