import * as types from 'actionTypes/core';

const initialState = {
  downloadProgress: 0,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_DOWNLOAD_PROGRESS:
      return setDownloadProgress(state, action);
    default:
      return state;
  }
}


const setDownloadProgress = (state, action) => {
  const { progress } = action.payload;
  return {
    ...state,
    downloadProgress: progress
  }
}