import React from 'react';
import VirtualizedSelect from 'react-virtualized-select';

const SelectField = (props) => {
  const {
    input,
    multi,
    disabled,
    simpleValue,
    backspaceRemoves,
    clearable,
    placeholder,
    options,
    style,
  } = props;

  return (
    <VirtualizedSelect
      {...input}
      style={style}
      disabled={disabled}
      backspaceRemoves={backspaceRemoves}
      clearable={clearable}
      simpleValue={simpleValue}
      multi={multi}
      options={options}
      value={input.value}
      onChange={props.onChange ? props.onChange : input.onChange}
      onBlur={() => input.onBlur()}
      placeholder={placeholder} />
  );
}

SelectField.defaultProps = {
  simpleValue: true,
  clearable: true,
  disabled: false,
  backspaceRemoves: false,
  placeholder: '',
}

export default SelectField;