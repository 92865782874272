import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { rnqListRequest, bulkVerfiyRequest } from 'actions/jobs/managers';
// import { selectTab } from 'actions/tabs';
import { getRnQJobs, getRnQJobPageSize, getRnQJobCurrentPage } from 'selectors/lists';
// import { getJobToTraListCurrentTab } from 'selectors/tabs';
import { isRnQListLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobRnQList from './presenter';


class JobRnQListContainer extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = { selected: [] };
  }
  
  componentDidMount() {
    this.props.rnqListRequest({ page: 1 });
  }

  handleSelect = (e) => {
    const { checked, value } = e.target;
    const newValue = [...this.state.selected];
    checked
      ? newValue.push(value)
      : newValue.splice(newValue.indexOf(value), 1)
    this.setState({ selected: newValue });
  }

  handleBulkVerify = () => {
    this.props.bulkVerfiyRequest({ jobs: this.state.selected });
    this.setState({ selected: [] });
  }

  handlePageSelect = (page) => {
    const params = { page }
    this.props.rnqListRequest(params);
  }
  
  render() {
    return (
      <JobRnQList
        {...this.props}
        selected={this.state.selected}
        handlePageSelect={this.handlePageSelect}
        handleSelect={this.handleSelect}
        handleBulkVerify={this.handleBulkVerify} />
    )
  }
}


const mapStateToProps = (state) => {
  return {
    jobs: getRnQJobs(state),
    isLoading: isRnQListLoading(state),
    pageSize: getRnQJobPageSize(state),
    currentPage: getRnQJobCurrentPage(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    rnqListRequest,
    bulkVerfiyRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobRnQListContainer);
