import React from 'react';
import { Box } from 'reactjs-admin-lte';
import { Table } from 'react-bootstrap';
import { Field } from 'redux-form';
import TooltipIconButton from 'components/core/TooltipIconButton';
import CheckboxGroupField from 'components/core/CheckboxGroupField';
import JobAssignmentField from './JobAssignmentField'


const JobAssignmentFields = (props) => {

  const {
    isLoading,
    getOptions,
    assigneeGroupOptions,
    requireAssignChangeNotification,
    notificationChanged,
    handleOptionReset,
    handleSubmit,
    handleSuggest,
    handleRefresh,
    handleTrash,
    resetState
  } = props;

  return (
    <Box
      className="box-widget job-assignment-box"
      loading={isLoading}>
      <Box.Header border={true}>
        <Box.Title>Assignment</Box.Title>
        <Box.Tools>
          <TooltipIconButton
            id="suggest-button"
            className="btn-box-tool"
            icon="magic"
            description="Auto assign"
            onClick={handleSubmit(values => handleSuggest(values))} />
          <TooltipIconButton
            id="refresh-button"
            className="btn-box-tool"
            icon="refresh"
            description="Refresh assign"
            onClick={handleSubmit(values => handleRefresh(values))} />
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <Table>
          <thead>
            <tr>
              <th width={80}></th>
              <th>User</th>
              <th width={90}>Start</th>
              <th width={65}></th>
              <th width={90}>End</th>
              <th width={65}></th>
              <th width={50}>Strict</th>
              <th width={45}>Auto</th>
              <th width={42}></th>
            </tr>
          </thead>
          <tbody>
            <JobAssignmentField
              name="totra"
              label="ToTra"
              resetState={resetState['totra']}
              options={getOptions('totra')}
              handleOptionReset={handleOptionReset}
              handleTrash={handleTrash} />
            <JobAssignmentField
              name="translator"
              label="Translator"
              options={getOptions('translator')}
              resetState={resetState['translator']}
              handleOptionReset={handleOptionReset}
              handleTrash={handleTrash} />
            <JobAssignmentField
              name="editor"
              label="Editor"
              options={getOptions('editor')}
              resetState={resetState['editor']}
              handleOptionReset={handleOptionReset}
              handleTrash={handleTrash} />
            <JobAssignmentField
              name="checker"
              label="Checker"
              options={getOptions('checker')}
              resetState={resetState['checker']}
              handleOptionReset={handleOptionReset}
              handleTrash={handleTrash} />
            <JobAssignmentField
              name="finalEditor"
              label="FE"
              options={getOptions('finalEditor')}
              resetState={resetState['finalEditor']}
              handleOptionReset={handleOptionReset}
              handleTrash={handleTrash} />
            <JobAssignmentField
              name="secondFinalEditor"
              label="2nd FE"
              options={getOptions('secondFinalEditor')}
              resetState={resetState['secondFinalEditor']}
              handleOptionReset={handleOptionReset}
              handleTrash={handleTrash} />
            <JobAssignmentField
              name="dtp"
              label="DTP"
              options={getOptions('dtp')}
              resetState={resetState['dtp']}
              handleOptionReset={handleOptionReset}
              handleTrash={handleTrash} />
            <JobAssignmentField
              name="secondDtp"
              label="2nd DTP"
              options={getOptions('secondDtp')}
              resetState={resetState['secondDtp']}
              handleOptionReset={handleOptionReset}
              handleTrash={handleTrash} />
          </tbody>
        </Table>
        { requireAssignChangeNotification && notificationChanged() && 
          <div className="p-10">
            <Field
              name="notifications"
              label="Send Deadline Change Email Notification to"
              options={assigneeGroupOptions}
              component={CheckboxGroupField.Labeled} />
          </div>
        }
      </Box.Body>
    </Box>
  )
}

export default JobAssignmentFields;
