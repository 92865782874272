import axios from 'axios';

const CoreApi = {
  managerBootstrap: () => {
    return axios.get(`/bootstrap/manager/`);
  },
  employeeBootstrap: () => {
    return axios.get(`/bootstrap/employee/`);
  },
  checkTaskProgress: (id) => {
    return axios.get(`/tasks/${id}`);
  },
  checkConcurrentTaskProgress: (id) => {
    return axios.get(`/tasks/${id}/progress`);
  },
}

export default CoreApi;
