import React from 'react';
import { Row, Col, Tab } from 'react-bootstrap';


const JobDeliveryDetailTabPane = (props) => {
  
  const {
    eventKey,
    navigation,
    content
  } = props;
    
  return (
    <Tab.Pane eventKey={eventKey}>
      <Row>
        <Col lg={3}>{navigation}</Col>
        <Col lg={9}>{content}</Col>
      </Row>
    </Tab.Pane>
  );
}

export default JobDeliveryDetailTabPane;
