import React from 'react';
import FontAwesome from 'react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { connectModal } from 'redux-modal';
import { Button, Modal } from 'react-bootstrap';
import { EMPLOYEE_JOB_FINISH_MODAL } from 'misc/constants';
import { employeeJobFinishRequest, setProcessed } from 'actions/jobs/employees';
import { isEmployeeJobFinishModalLoading } from 'selectors/loading';
import Spinner from 'components/core/Spinner';
import EmployeeJobFinishConfirm from './EmployeeJobFinishConfirm'
import EmployeeJobFinishSuccess from './EmployeeJobFinishSuccess';
import { isAssignmentProcessed } from 'selectors/jobs';
import PureRenderComponent from 'components/core/PureRenderComponent';


class EmployeeJobFinishModalContainer extends PureRenderComponent {

  handleSubmit = () => {
    this.props.employeeJobFinishRequest(
      this.props.assignment,
    );
  }

  handleHide = () => {
    this.props.handleHide();
    this.props.setProcessed(this.props.assignment.id, false)
  }

  render() {

    const {
      show,
      isLoading,
      message,
      processed,
      projects,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={this.handleHide}
        animation={true}
        bsSize="large"
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Process job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { !processed
            ? <EmployeeJobFinishConfirm
                message={message}
                documents={projects} />
            : <EmployeeJobFinishSuccess />
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pull-left"
            bsSize="sm"
            type="button"
            onClick={this.handleHide}>
            <FontAwesome name="close" className="mr-5" />Close
          </Button>
          { !processed &&
            <Button
              bsStyle="primary"
              bsSize="sm"
              disabled={projects.length === 0}
              onClick={this.handleSubmit}
              type="button">
              <FontAwesome name="check" className="mr-5" />Process
            </Button>
          }
        </Modal.Footer>
        <Spinner isLoading={isLoading} />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    processed: isAssignmentProcessed(state),
    isLoading: isEmployeeJobFinishModalLoading(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    employeeJobFinishRequest,
    setProcessed
  }, disptach)
}

EmployeeJobFinishModalContainer = connectModal({
  name: EMPLOYEE_JOB_FINISH_MODAL
})(EmployeeJobFinishModalContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeJobFinishModalContainer)
