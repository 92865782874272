import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reset, change, formValueSelector } from 'redux-form';
import { PAYMENT_SEARCH_FORM } from 'misc/constants';
import { paymentSearchRequest, assignmentPaymentCheckRequest } from 'actions/jobs/managers';
import { getPaymentSearch } from 'selectors/lists'
import { isPaymentSearchLoading } from 'selectors/loading';
import {
  getPaymentSearchRoleOptions,
  getPaymentSearchUserOptions
} from 'selectors/options';
import { getPaymentSearchFormInitialValues } from 'selectors/forms';
import PureRenderComponent from 'components/core/PureRenderComponent';
import PaymentSearch from './presenter';


class PaymentSearchContainer extends PureRenderComponent {

  componentDidUpdate(prevProps) {
    if (prevProps.role !== this.props.role) {
      this.props.change(this.props.form, 'employee', '');
    }
  }

  handleSearch = (values) => {
    this.props.paymentSearchRequest(values);
  }

  handleReset = () => {
    this.props.reset(this.props.form);
  }

  handleCheck = (e) => {
    const { value, checked } = e.target;
    this.props.assignmentPaymentCheckRequest(value, { isPaid: checked });
  }

  render() {
    return (
      <PaymentSearch
        {...this.props}
        handleSearch={this.handleSearch}
        handleReset={this.handleReset}
        handleCheck={this.handleCheck} />
    )
  }
}

const mapStateToProps = (state) => {
  const form = PAYMENT_SEARCH_FORM;
  const formSelector = formValueSelector(form);
  const role = formSelector(state, 'role');
  return {
    form,
    role,
    isLoading: isPaymentSearchLoading(state),
    initialValues: getPaymentSearchFormInitialValues(state),
    payments: getPaymentSearch(state),
    roleOptions: getPaymentSearchRoleOptions(state),
    employeeOptions: getPaymentSearchUserOptions(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    reset,
    change,
    paymentSearchRequest,
    assignmentPaymentCheckRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSearchContainer);
