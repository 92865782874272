import React from 'react';
import classNames from 'classnames';
import { Checkbox, Row, Col } from 'react-bootstrap';
import LabeledField from 'components/core/LabeledField';

const CheckboxGroupField = (props) => {
  const {
    options,
    input,
    disabled,
    colSize,
    welled
  } = props;

  const handleChange = (event, option) => {
    const newValue = [...input.value];
    event.target.checked
      ? newValue.push(option.value)
      : newValue.splice(newValue.indexOf(option.value), 1)
    return input.onChange(newValue);
  }

  const cls = classNames({
    welled: welled
  })

  return (
    <Row className={cls}>
      {options.map((option, index) => (
        <Col lg={colSize} key={index}>
          <Checkbox
            className="mt-3 mb-3"
            name={`${input.name}[${index}]`}
            value={option.value}
            disabled={disabled || option.disabled}
            checked={input.value.indexOf(option.value) !== -1}
            onChange={(event) => handleChange(event, option)}
            onBlur={() => input.onBlur()}>
            {option.label}
          </Checkbox>
        </Col>
      ))}
    </Row>
  )
}

const LabeledCheckboxGroupField = (props) => {
  return (
    <LabeledField {...props}>
      <CheckboxGroupField {...props} />
    </LabeledField>
  )
}

CheckboxGroupField.defaultProps = {
  colSize: 4,
  welled: false
}

CheckboxGroupField.Labeled = LabeledCheckboxGroupField;

export default CheckboxGroupField;
