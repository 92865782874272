export default function disableInput(editor) {
  editor.editing.view.document.on( 'keydown', ( evt, data ) => {
    // disable any key exept tab/arrows/command/ctrl:
    const keyCode = data.keyCode;
    if (
      !(data.ctrlKey || data.metaKey) &&
      keyCode !== 9 &&
      keyCode !== 37 &&
      keyCode !== 39
    ) {
      data.preventDefault();
      evt.stop();
    }
  } );
}
