import {
    SET_DEFAULT_LANGUAGE_COLUMNS,
    SET_LANGUAGE_COLUMNS,
    SET_LANGUAGE_COLUMN,
    RESET_COLUMN
  } from "../actionTypes/wordListUi";
  
  export const setDefaultLanguageColumns = (payload) => ({
    type: SET_DEFAULT_LANGUAGE_COLUMNS,
    payload: {...payload}
  })
  
  export const setLanguageColumns = (payload) => {
    return {
      type: SET_LANGUAGE_COLUMNS,
      payload: {...payload}
    }
  }
  
  export const setLanguageColumn = (payload) => ({
    type: SET_LANGUAGE_COLUMN,
    payload: {...payload}
  })
  
  
  export const resetColumn = (payload) => ({
    type: RESET_COLUMN,
    payload: {...payload}
  })
  