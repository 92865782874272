import React from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { reset, change, formValueSelector, getFormValues } from 'redux-form';
import { EMPLOYEE_JOB_HISTORY, EMPLOYEE_JOB_HISTORY_FORM } from 'misc/constants';
import { getJobHistoryFormInitialValues } from 'selectors/forms';
import { isJobHistoryLoading } from 'selectors/loading';
import { isAuthorizedEmployee } from 'selectors/auth';
import { getMyAssignmentRoleOptions } from 'selectors/options';
import { getHistoryJobs, getJobHistoryPageSize, getJobHistoryCurrentPage } from 'selectors/lists';
import { clearPaginationResult } from 'actions/lists';
import { historySearchRequest, historyCsvExportRequest } from 'actions/jobs/employees';
import PureRenderComponent from 'components/core/PureRenderComponent';
import EmployeeJobHistory from './presenter';


const roleKeyIdMap = {
  1: 'translator',
  2: 'editor',
  3: 'checker',
  4: 'finalEditor',
  5: 'secondFinalEditor',
  6: 'dtp',
  7: 'secondDtp',
  8: 'totra'
}


class EmployeeJobHistoryContainer extends PureRenderComponent {

  componentDidUpdate(prevProps) {
    if (prevProps.role !== this.props.role) {
      this.resetAssigneeFilter(this.props.role);
    }
  }

  componentWillUnmount() {
    this.handleReset();
    this.props.clearPaginationResult(EMPLOYEE_JOB_HISTORY);
  }

  resetAssigneeFilter = (role) => {
    const roleKey = roleKeyIdMap[role];
    this.props.change(this.props.form, roleKey, '');
    this.props.clearPaginationResult(EMPLOYEE_JOB_HISTORY);
  }
    
  handleSearch = (values) => {
    const formData = { ...values, page: 1 };
    this.props.historySearchRequest(formData);
  }

  handlePageSelect = (page) => {
    const formData = { ...this.props.formValues, page }
    this.props.historySearchRequest(formData);
  }

  handleCsvExport = (values) => {
    this.props.historyCsvExportRequest(values);
  }
  
  handleReset = () => {
    this.props.reset(this.props.form);
  }
  
  render() {
    return (
      <EmployeeJobHistory
        {...this.props}
        handleReset={this.handleReset}
        handleSearch={this.handleSearch}
        handlePageSelect={this.handlePageSelect}
        handleCsvExport={this.handleCsvExport} />
    );
  }
}

const mapStateToProps = (state) => {
  const form = EMPLOYEE_JOB_HISTORY_FORM;
  const formSelector = formValueSelector(form);
  const role = formSelector(state, 'role');
  const roleKey = roleKeyIdMap[role];
  const formValues = getFormValues(form)(state);
  return {
    form,
    role,
    roleKey,
    formValues,
    jobs: getHistoryJobs(state),
    pageSize: getJobHistoryPageSize(state),
    currentPage: getJobHistoryCurrentPage(state),
    isAuthorizedEmployee: isAuthorizedEmployee(state),
    initialValues: getJobHistoryFormInitialValues(state),
    isLoading: isJobHistoryLoading(state),
    roleOptions: getMyAssignmentRoleOptions(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    historySearchRequest,
    historyCsvExportRequest,
    clearPaginationResult,
    reset,
    change,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeJobHistoryContainer);