import React from "react";

import styles from "./TaMhtEditorWidget.module.css";
import {TaInputText} from "../../taUi/taInputText";
import {TaButton} from "../../taUi/taButton/taButton";
import * as PropTypes from "prop-types";
import {convertHtmlToText} from "./textTools";
import {TaIcon} from "../../taUi/taIcon";

export class TaMhtEditorWidgetReplace extends React.Component {

  static propTypes = {
    segments: PropTypes.object.isRequired,
    highlight: PropTypes.object.isRequired,
    setHighlightState: PropTypes.func.isRequired,
    goPrevHighlight: PropTypes.func.isRequired,
    goNextHighlight: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      resetForm: 0,
      focusInput: '',
      pasted: '',
      searchString: '',
      replaceString: '',
      isSent: false
    };

    this.onInput = (event) => {
      const property = event.data.property;
      let value = event.data.value;

      let oldState = {...this.state};
      let newState = {...this.state};

      if (
        event.name === 'paste'
      ) {
        newState.pasted = event.data.value;
      } else if (
        event.name === 'change'
      ) {
        if (oldState.pasted) {
          value = oldState.pasted.trim();
          newState.resetForm = Date.now();
          newState.focusInput = [property];
          newState.pasted = '';
        }

      }

      if (oldState[property] !== value) {
        newState.isSent = (property === 'searchString') ? false : this.state.isSent;
        newState[property] = value || '';
      }

      this.setState(newState)
    }

    this.onSearch = () => {
      this.setState({
        isSent: true,
        isReplaced: false
      }, this.searchSegments);
    }
    this.onReset = () => {
      this.setState({
        resetForm: Date.now(),
        focusInput: '',
        searchString: '',
        replaceString: '',
        isSent: false,
        isReplaced: false
      }, this.searchSegments);
    }

    this.searchSegments = () => {

      const caseSensitive = false;

      const props = this.props;
      let searchString = this.state.searchString;
      const isValid = !!(searchString && searchString.trim());

      const searchContext = 'target';
      let matches = [];
      let segmentData = {};

      if (isValid) {

        if (!caseSensitive) {
          searchString = searchString.toLowerCase();
        }

        props.segments.all.forEach((segmentId, index) => {

          const segment = props.segments.data[segmentId];

          if (!segment.is_locked) {
            const mutatedHtmlProperty = props.pickMutatedSegmentProperty(segment, searchContext + '_html');

            let plainText = '';
            let hasMatch = false;

            if (mutatedHtmlProperty) {
              plainText = convertHtmlToText(mutatedHtmlProperty);
            } else {
              plainText = segment[searchContext + '_text'];
            }

            if (caseSensitive) {
              hasMatch = plainText.includes(searchString);
            } else {
              hasMatch = plainText.toLowerCase().includes(searchString);
            }

            if (hasMatch) {
              const highlighted = props.applyHighlight(
                searchString,
                props.pickSegmentProperty(segment, searchContext + '_html'),
                plainText,
                caseSensitive
              );
              segmentData[segmentId] = highlighted;
              highlighted.matches.forEach((key) => {
                matches.push({
                  id: segmentId,
                  key: key
                })
              })
            }
          }

        })
      }

      props.setHighlightState({
        string: searchString,
        items: matches,
        data: segmentData,
        activeSegment: (matches.length) ? matches[0].id : '',
        activeIndex: 0,
      })

    }

  }

  render() {

    const {
      highlight,
      goPrevHighlight,
      goNextHighlight,
      requestReplace
    } = this.props;

    const {
      resetForm,
      isSent,
      isReplaced,
      searchString,
      replaceString,
      focusInput
    } = this.state;

    return (
      <form
        key={resetForm}
        className={styles.padding}
        onSubmit={(event) => {
          event.preventDefault();
          this.onSearch()
        }}
      >
        <TaInputText
          name="searchString"
          value={searchString}
          autofocus={!!focusInput && (focusInput.toString() === 'searchString')}
          iconBefore={'search'}
          onEvent={this.onInput}
          placeholder="Search for"
          clear={true}
          className={styles.margin}
        />
        <TaInputText
          name="replaceString"
          value={replaceString}
          autofocus={!!focusInput && (focusInput.toString() === 'replaceString')}
          iconBefore={'edit'}
          onEvent={this.onInput}
          placeholder="Replace with"
          clear={true}
          className={styles.margin}
        />
        <section
          className={styles.row}
        >
          {
            (!isSent) && (
              <TaButton
                type={'submit'}
                label={'Search'}
                className={styles.margin}
              />
            )
          }
          {
            (isSent) && (
              <TaButton
                type={'button'}
                label={'Done'}
                className={styles.margin}
                onClick={() => this.onReset()}
              />
            )
          }
          <div className={styles.hspace}></div>
          <div className={styles.counter}>
            {
              (!!highlight.count) && (
                <span>{(highlight.activeIndex + 1) + '/' + highlight.count}</span>
              )
            }
            {
              (!highlight.count && isSent && !isReplaced) && (
                <span className={'ta-color--alert'}>Nothing Found</span>
              )
            }
            {
              (!highlight.count && isSent && isReplaced) && (
                <React.Fragment>
                  <span className={'ta-color--confirm'}>Complete</span>
                  <TaIcon name={'check_circle'} className={'ta-color--confirm'}/>
                </React.Fragment>
              )
            }
          </div>
          <TaButton
            type={'button'}
            icon={'chevron_left'}
            onClick={goPrevHighlight}
            className={styles.margin}
            disabled={!highlight.count}
          />
          <TaButton
            type={'button'}
            icon={'chevron_right'}
            onClick={goNextHighlight}
            className={styles.margin}
            disabled={!highlight.count}
          />
          <TaButton
            type={'primary'}
            label={'Replace'}
            onClick={() => {
              this.setState({
                isReplaced: true
              }, () => requestReplace(replaceString));
            }}
            className={styles.margin}
            disabled={!highlight.count || !highlight.activeSegment}
          />
          {/*<TaButton*/}
          {/*  type={'button'}*/}
          {/*  label={'Replace All'}*/}
          {/*  onClick={()=>requestReplace(replaceString, true)}*/}
          {/*  className={styles.margin}*/}
          {/*  disabled={!highlight.count}*/}
          {/*/>*/}
        </section>
        {/*<pre> {JSON.stringify(this.state, null, 2)};</pre>*/}
        {/*<pre> {JSON.stringify(highlight, null, 2)};</pre>*/}
      </form>
    )
  }
}
