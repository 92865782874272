import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Navbar, InputGroup, FormControl, Button } from 'react-bootstrap';
import FilterBar from 'components/core/FilterBar';

const areEqual = (prevProps, nextProps) => {
  return prevProps.keyword === nextProps.keyword;
}

const TermSearchFilter = React.memo((props) => {

  const {
    keyword,
    handleSubmit,
    handleKeywordChange
  } = props;

  return (
    <FilterBar>
      <Navbar.Form pullRight>
        <InputGroup bsSize="sm">
          <FormControl
            value={keyword}
            onChange={(e) => handleKeywordChange(e.target.value)}
            onKeyPress={(e) => {
              e.key === 'Enter' && handleSubmit()
            }}
            type="text"
            style={{ width: 200 }}
            bsSize="sm" />
          <InputGroup.Button>
            <Button
              bsSize="sm"
              onClick={handleSubmit}>
              <FontAwesome name="search" />
            </Button>
          </InputGroup.Button>
        </InputGroup>
      </Navbar.Form>
    </FilterBar>
  );
}, areEqual);

export default TermSearchFilter;
