import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Navbar, Nav, NavItem, InputGroup, FormControl, Button } from 'react-bootstrap';
import Select from 'components/core/Select';
import FilterBar from 'components/core/FilterBar';
import ClientAsyncSelect from 'components/core/ClientAsyncSelect';


const CustomerListFilter = (props) => {
  
  const {
    keyword,
    ae,
    aeOptions,
    handleAEChange,
    client,
    handleClientChange,
    handleSubmit,
    handleKeywordChange
  } = props;
  
  return (
    <FilterBar>
      <Nav>
        <NavItem disabled>AE</NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <Select
          value={ae}
          clearable={true}
          placeholder=""
          options={aeOptions}
          onChange={handleAEChange}
          style={{ width: 200 }} />
      </Navbar.Form>
      <Nav>
        <NavItem disabled>Client</NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <ClientAsyncSelect
          value={client}
          clearable={true}
          placeholder=""
          onChange={handleClientChange}
          style={{ width: 200 }} />
      </Navbar.Form>
      <Navbar.Form pullRight>
        <InputGroup bsSize="sm">
          <FormControl
            value={keyword}
            onChange={(e) => handleKeywordChange(e.target.value)}
            onKeyPress={(e) => {
              e.key === 'Enter' && handleSubmit()
            }}
            type="text"
            style={{ width: 200 }}
            bsSize="sm" />
          <InputGroup.Button>
            <Button
              bsSize="sm"
              onClick={handleSubmit}>
              <FontAwesome name="search" />
            </Button>
          </InputGroup.Button>
        </InputGroup>
      </Navbar.Form>
    </FilterBar>
  );
}

export default CustomerListFilter;
