import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { JOB_ISSUE_FORM } from 'misc/constants';
import { isJobIssueLoading } from 'selectors/loading';
import { getJobById } from 'selectors/jobs';
import { getJobIssueFormInitialValuesById } from 'selectors/forms';
import { issueRequest, retrieveRequest, removePendingRequest } from 'actions/jobs/managers';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobIssue from './presenter';


class JobIssueContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.retrieveRequest(this.props.id);
  }

  handleIssue = (values) => {
    this.props.issueRequest(this.props.form, this.props.id, values);
  }

  handleRemove = () => {
    this.props.removePendingRequest(this.props.job);
  }

  render() {
    return (
      <JobIssue
        {...this.props}
        handleIssue={this.handleIssue}
        handleRemove={this.handleRemove} />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.params;
  const form = `${JOB_ISSUE_FORM}/${id}`;
  const formSelector = formValueSelector(form);
  const type = formSelector(state, 'type');
  const baseNumber = formSelector(state, 'baseNumber');
  const client = formSelector(state, 'client');
  const job = getJobById(state, id);
  const isSplit = job ? (!!job.number && type !== 'TT' && type !== 'MH') : false;
  const isMHJob = type === "MH";
  return {
    id,
    form,
    type,
    baseNumber,
    client,
    job,
    isSplit,
    isMHJob,
    initialValues: getJobIssueFormInitialValuesById(state, id),
    isLoading: isJobIssueLoading(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    retrieveRequest,
    issueRequest,
    removePendingRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobIssueContainer);
