import React from "react";
import * as PropTypes from "prop-types";
import {TaMhtEditorSegment} from "components/mht/MHTEditor/TaMhtEditorSegment";
import {TaMhtEditorSegmentSleep} from "components/mht/MHTEditor/TaMhtEditorSegmentSleep";
import {TaMhtEditorSegmentSeparator} from "./TaMhtEditorSegmentSeparator";

export class TaMhtEditorSegmentsGroupItems extends React.Component {

  static propTypes = {
    items: PropTypes.array.isRequired,
    mutations: PropTypes.object.isRequired,
    comments: PropTypes.object.isRequired,
    selectedSegment: PropTypes.object.isRequired,

    isEditSourceEnabled: PropTypes.bool.isRequired,
    userRole: PropTypes.string.isRequired,

    timeLoaded: PropTypes.number.isRequired,
    timeMutated: PropTypes.number.isRequired,
    timeSearched: PropTypes.number.isRequired,
    timeFiltered: PropTypes.number.isRequired,
    timeCommented: PropTypes.number.isRequired,

    getBackgroundColor: PropTypes.func.isRequired,
    pickSegmentProperty: PropTypes.func.isRequired,

    onRowSelect: PropTypes.func.isRequired,
    onSourceFocus: PropTypes.func.isRequired,
    onSourceBlur: PropTypes.func.isRequired,
    onSourceChange: PropTypes.func.isRequired,
    onTargetChange: PropTypes.func.isRequired,

    cellWidthIndex: PropTypes.number.isRequired,
    cellWidthStatus: PropTypes.number.isRequired,
    cellGroupWidth: PropTypes.string.isRequired
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isVisible !== nextProps.isVisible ||
      this.props.selectedSegment.time !== nextProps.selectedSegment.time ||
      this.props.timeLoaded !== nextProps.timeLoaded ||
      this.props.timeMutated !== nextProps.timeMutated ||
      this.props.timeSearched !== nextProps.timeSearched ||
      this.props.timeFiltered !== nextProps.timeFiltered ||
      this.props.timeCommented !== nextProps.timeCommented ||
      this.props.isEditSourceEnabled !== nextProps.isEditSourceEnabled ||
      (
        this.props.highlight.time !== nextProps.highlight.time &&
        !!nextProps.isVisible
      )
    );
  }

  render() {

    const {
      userRole,
      items,
      data,
      mutations,
      comments,
      selectedSegment,

      isEditSourceEnabled,

      timeLoaded,

      cellWidthIndex,
      cellWidthStatus,
      cellGroupWidth,
      pickSegmentProperty,
      getBackgroundColor,
      onRowSelect,
      onSourceFocus,
      onSourceBlur,
      onSourceChange,
      onTargetFocus,
      onTargetChange,
      isVisible,
      rowIndexModifier,
      highlight
    } = this.props;

    return items.map((segmentId, rowIndex) => {
      // const segmentId = segment.id;
      const segment = data[segmentId];
      const realRowIndex = rowIndex + rowIndexModifier;

      const timeSegmentUpdated = segment.modified;
      const timeSegmentMutated = (mutations.index[segmentId]) ? mutations.index[segment.id] : 0;

      const isSelectedSegment = (typeof selectedSegment.items[segmentId] !== 'undefined');
      const hasComment = !!(comments.index[segmentId]);

      const hasHighlight = (
        highlight &&
        !!highlight.count &&
        typeof highlight.data[segmentId] !== "undefined"
      );
      const isActiveHighlight = (
        hasHighlight &&
        highlight.items[highlight.activeIndex] &&
        highlight.items[highlight.activeIndex].id === segmentId
      );
      const highlightKey = (isActiveHighlight) ? highlight.items[highlight.activeIndex].key : '';
      const highlighted = (hasHighlight) ? highlight.data[segmentId] : '';

      return (!!segment.separator_type) ? (
        <TaMhtEditorSegmentSeparator
          key={segmentId}
          rowIndex={realRowIndex}
          segment={segment}
          cellWidthIndex={cellWidthIndex}
          cellWidthStatus={cellWidthStatus}
          cellGroupWidth={cellGroupWidth}
        />
      ) : (isVisible) ? (
        <TaMhtEditorSegment
          key={segmentId}
          groupIndex={rowIndex}
          rowIndex={realRowIndex}
          segment={segment}
          userRole={userRole}
          timeLoaded={timeLoaded}
          timeMutated={timeSegmentMutated}
          timeUpdated={timeSegmentUpdated}

          isEditSourceEnabled={isEditSourceEnabled}
          isSelected={isSelectedSegment}
          hasComment={hasComment}

          hasHighlight={hasHighlight}
          isActiveHighlight={isActiveHighlight}
          highlightKey={highlightKey}
          highlighted={highlighted}

          cellWidthIndex={cellWidthIndex}
          cellWidthStatus={cellWidthStatus}
          cellGroupWidth={cellGroupWidth}

          pickSegmentProperty={pickSegmentProperty}
          getBackgroundColor={getBackgroundColor}

          onRowSelect={onRowSelect}
          onSourceFocus={onSourceFocus}
          onSourceBlur={onSourceBlur}
          onSourceChange={onSourceChange}
          onTargetChange={onTargetChange}
          onTargetFocus={onTargetFocus}
        />
      ) : (
        <TaMhtEditorSegmentSleep
          key={segmentId}
          rowIndex={realRowIndex}
          segment={segment}
          timeMutated={timeSegmentMutated}
          timeUpdated={timeSegmentUpdated}
          cellWidthIndex={cellWidthIndex}
          cellWidthStatus={cellWidthStatus}
          cellGroupWidth={cellGroupWidth}
          pickSegmentProperty={pickSegmentProperty}
        />
      )
    })

  }

}


