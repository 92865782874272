import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { ASSIGNMENT_ROLE_VALUE_MAP } from 'misc/config';
import { setAssigneeOptions, resetAssigneeOptions } from 'actions/jobs/managers';
import {
  getToTraOptions,
  getTranslatorOptions,
  getEditorOptions,
  getCheckerOptions,
  getFinalEditorOptions,
  getSecondFinalEditorOptions,
  getDtpOptions,
  getSecondDtpOptions,
  getAssigneeOptions
} from 'selectors/options';
import { getAssigneeGroupOptions } from 'selectors/options';
import { isAssignmentSuggestLoading } from 'selectors/loading';
import { suggestAssignmentRequest, refreshAssignmentRequest } from 'actions/jobs/managers';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobAssignmentFields from './presenter';



class JobAssignmentFieldsContainer extends PureRenderComponent {
  
  constructor(props) {
    super(props);
    this.state = {
      resetState: {
        totra: false,
        translator: false,
        editor: false,
        checker: false,
        finalEditor: false,
        secondFinalEditor: false,
        dtp: false,
        secondDtp: false,
      }
    }
  }

  componentDidMount() {
    this.props.setAssigneeOptions(this.props.form, {
      totra: this.getInitialOptions('totra'),
      translator: this.getInitialOptions('translator'),
      editor: this.getInitialOptions('editor'),
      checker: this.getInitialOptions('checker'),
      finalEditor: this.getInitialOptions('finalEditor'),
      secondFinalEditor: this.getInitialOptions('secondFinalEditor'),
      dtp: this.getInitialOptions('dtp'),
      secondDtp: this.getInitialOptions('secondDtp'),
    });
  }

  componentWillUnmount() {
    this.props.resetAssigneeOptions(this.props.form);
  }

  getInitialOptions = (name) => {
    const assignment = this.props.initialValues[name];
    return assignment && assignment.assignee
      ? [assignment.assignee]
      : []
  }

  getOptions = (name) => {
    return this.state.resetState[name]
      ? this.props[`${name}AllOptions`]
      : this.props[`${name}Options`]
  }

  handleOptionReset = (name) => {
    this.setState({
      ...this.state,
      resetState: {
        ...this.state.resetState,
        [name]: !this.state.resetState[name]
      }
    })
  }

  notificationChanged = () => {
    return Object.keys(ASSIGNMENT_ROLE_VALUE_MAP).some(key => {
      const initialValue = this.props.initialValues && this.props.initialValues[key] ? this.props.initialValues[key] : {};
      const formValue = this.props.formValues && this.props.formValues[key] ? this.props.formValues[key] : {};
      return (
        (initialValue.startDate !== formValue.startDate) ||
        (initialValue.startTime !== formValue.startTime) ||
        (initialValue.endDate !== formValue.endDate) ||
        (initialValue.endTime !== formValue.endTime)
      )
    })
  }

  handleSuggest = (values) => {
    this.props.suggestAssignmentRequest(this.props.form, values);
  }

  handleRefresh = (values) => {
    this.props.refreshAssignmentRequest(this.props.form, values);
  }

  handleTrash = (name) => {
    this.props.change(this.props.form, name, {});
  }

  render() {
    return (
      <JobAssignmentFields
        {...this.props}
        getOptions={this.getOptions}
        resetState={this.state.resetState}
        notificationChanged={this.notificationChanged}
        handleOptionReset={this.handleOptionReset}
        handleSuggest={this.handleSuggest}
        handleRefresh={this.handleRefresh}
        handleTrash={this.handleTrash} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const form = props.form;
  const options = getAssigneeOptions(state, form);
  return {
    isLoading: isAssignmentSuggestLoading(state, form),
    ...options,
    totraAllOptions: getToTraOptions(state),
    translatorAllOptions: getTranslatorOptions(state),
    editorAllOptions: getEditorOptions(state),
    checkerAllOptions: getCheckerOptions(state),
    finalEditorAllOptions: getFinalEditorOptions(state),
    secondFinalEditorAllOptions: getSecondFinalEditorOptions(state),
    dtpAllOptions: getDtpOptions(state),
    secondDtpAllOptions: getSecondDtpOptions(state),
    assigneeGroupOptions: getAssigneeGroupOptions(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    change,
    setAssigneeOptions,
    resetAssigneeOptions,
    suggestAssignmentRequest,
    refreshAssignmentRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobAssignmentFieldsContainer);
