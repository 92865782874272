import axios from 'axios';

const AccountingApi = {
  salesTargetList: (params) => {
    return axios.get(`accounting/targets/`, { params });
  },
  salesTargetCreate: (values) => {
    return axios.post(`accounting/targets/`, values);
  },
  salesTargetPatch: (id, values) => {
    return axios.patch(`accounting/targets/${id}/`, values);
  },

  // Sales Reort ===============================================================
  salesReport: (params) => {
    return axios.get('accounting/sales/', { params });
  },
}



export default AccountingApi;