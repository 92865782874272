import React from 'react';
import SelectField from './SelectField';
import HorizontalField from 'components/core/HorizontalField';


const HorizontalSelectField = (props) => {
  return (
    <HorizontalField {...props}>
      <SelectField {...props} />
    </HorizontalField>
  );
}

export default HorizontalSelectField;