import React from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DEFAULT_YEAR_FORMAT } from 'misc/config';
import { SALES_TARGET } from 'misc/constants';
import { setFilter } from 'actions/filters';
import { salesTargetListRequest, salesTargetCreateRequest, salesTargetPatchRequest } from 'actions/accounting';
import { getAEUsers } from 'selectors/users';
import { isSalesTargetLoading } from 'selectors/loading';
import { getSalesTargetCurrentYear } from 'selectors/filters';
import { getSalesTargets } from 'selectors/accounting';
import { isSuperuser } from 'selectors/auth';
import PureRenderComponent from 'components/core/PureRenderComponent';
import SalesTarget from './presenter';


class SalesTargetContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.salesTargetListRequest(this.props.currentYear);
  }

  handlePrev = () => {
    const prev = moment(this.props.currentYear, DEFAULT_YEAR_FORMAT).subtract(1, 'years');
    this.handleYearChange(prev)
  }
  
  handleNext = () => {
    const next = moment(this.props.currentYear, DEFAULT_YEAR_FORMAT).add(1, 'years');
    this.handleYearChange(next)
  }

  handleYearChange = (year) => {
    const currentYear = moment(year).format(DEFAULT_YEAR_FORMAT);
    this.props.setFilter(SALES_TARGET, 'currentYear', currentYear);
    this.props.salesTargetListRequest(currentYear);
  }

  handleCreate = (values) => {
    this.props.salesTargetCreateRequest(this.props.currentYear, values);
  }
  handlePatch = (id, values) => {
    this.props.salesTargetPatchRequest(id, values);
  }

  render() {
    return (
      <SalesTarget
        {...this.props}
        handlePrev={this.handlePrev}
        handleNext={this.handleNext}
        handleCreate={this.handleCreate}
        handlePatch={this.handlePatch} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    salesTargets: getSalesTargets(state),
    aeUsers: getAEUsers(state),
    currentYear: getSalesTargetCurrentYear(state),
    isLoading: isSalesTargetLoading(state),
    isSuperuser: isSuperuser(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    setFilter,
    salesTargetListRequest,
    salesTargetCreateRequest,
    salesTargetPatchRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesTargetContainer);
