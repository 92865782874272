import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { Content, Box } from 'reactjs-admin-lte';
import MHTJobFiles from './MHTJobFiles';
import MHTJobMessage from 'components/jobs/managers/MHTJobMessage';



const MHTJobDetail = (props) => {
  const {
    id,
    job,
    isLoading,
    files,
    showInvoiceDownloadModal,
    showUploadModal,
    downloadSourceFile,
    downloadTargetFile,
    removeTargetFile,
    targetfileUploaded,
    isDeliveryable,
    isReDeliveryable,
    handleDelivery,
    handlRedelivery,
   } = props;
  return (
    <div>
      <Content.Header>
        <h1>
          {job.number}
          <Button
            bsSize="sm"
            bsStyle="success"
            className="pull-right ml-10"
            type="button"
            disabled={!targetfileUploaded() || !isReDeliveryable()}
            onClick={handlRedelivery}>
            Redelivery
        </Button>
          <Button
            bsSize="sm"
            bsStyle="primary"
            className="pull-right"
            type="button"
            disabled={!targetfileUploaded() || !isDeliveryable()}
            onClick={handleDelivery}>
            Delivery
        </Button>
        </h1>
      </Content.Header>
      <Content.Body>
        <Row>
          <Col lg={8}>
            <Box className="box-widget mb-10" loading={isLoading}>
            <Box.Header>
              <Box.Title>Job Info</Box.Title>
              <Box.Tools>
                <Box.CollapsedToggleButton />
              </Box.Tools>
            </Box.Header>
              <Box.Body noPadding={true}>
                <Table striped bordered className="mb-0">
                  <tbody>
                    <tr>
                      <th>Service</th>
                      <td>{job.service.name}</td>
                    </tr>
                    <tr>
                      <th>Level</th>
                      <td>{job.level}</td>
                    </tr>
                    <tr>
                      <th>Customer</th>
                      <td>{job.customer.name}</td>
                    </tr>
                    <tr>
                      <th>Client</th>
                      <td>{job.client.name}</td>
                    </tr>
                    <tr>
                      <th>Deadline</th>
                      <td>{job.deadline}</td>
                    </tr>
                    <tr>
                      <th>Source Count</th>
                      <td>{job.sourceCount}</td>
                    </tr>
                    <tr>
                      <th>Quotation</th>
                      <td>{job.quotation}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>{job.status}</td>
                    </tr>
                    <tr>
                      <th>Invoice</th>
                      <td>
                        <Button
                          bsSize="sm"
                          bsStyle="primary"
                          type="button"
                          onClick={showInvoiceDownloadModal}>
                          <FontAwesome name="cloud-download" className="mr-5" />Download
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Box.Body>
            </Box>
            <MHTJobFiles
              isLoading={isLoading}
              files={files}
              showUploadModal={showUploadModal}
              downloadSourceFile={downloadSourceFile}
              downloadTargetFile={downloadTargetFile}
              removeTargetFile={removeTargetFile}
            />
          </Col>
          <Col lg={4}>
            <MHTJobMessage jobId={id} />
          </Col>
        </Row>
      </Content.Body>
    </div>
  );
}

export default MHTJobDetail;