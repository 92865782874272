import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { getSavedLocation } from 'selectors/settings';



const DefaultRoute = (props) => {
  const { location } = props;
  return location === '/employee'
    ? <Redirect to="/employee" />
    : <Redirect to="/manager" />
}

const mapStateToProps = (state) => {
  return {
    location: getSavedLocation(state),
  }
}

export default connect(mapStateToProps)(DefaultRoute)
