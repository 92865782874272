import moment from 'moment';
import * as types from 'actionTypes/lists';
import * as constants from 'misc/constants';

const today = moment();
const currentDate = today.format('YYYY-MM-DD');
const currentMonth = today.format('YYYY-MM');
const currentYear = today.format('YYYY');


const initialDailyState = {
  [currentDate]: null
}
const initialMonthlyState = {
  [currentMonth]: null
}
const initialYearlyState = {
  [currentYear]: null
}

const initalReportState = {
  [currentMonth]: {
    quotationResults: [],
    countResults: [],
    quotationTotal: { title: 'Total' },
    countTotal: { title: 'Total' },
    target: { title: 'Target' },
    progress: { title: 'Progress' }
  }
}

const initalAnalysisReportState = {
  [currentMonth]: {
    results: [],
    avgMonthly: { title: 'Avg.'}
  }
}

const intialPaginationState = {
  hits: 0,
  currentPage: 1,
  totalCount: 1,
  pages: {},
}


const initialState = {

  // Manager ===================================================================
  // Jobs ======================================================================
  [constants.JOB_DAILY_SCHEDULE]: initialDailyState,
  [constants.JOB_FUTURE_SCHEDULE]: initialDailyState,
  [constants.JOB_SEARCH]: intialPaginationState,
  [constants.DELIVERY_LIST]: intialPaginationState,
  [constants.TEMPLATE_LIST]: [],
  [constants.JOB_DRAFT_LIST]: [],
  // [constants.WORKING_LIST]: [],
  [constants.PENDING_LIST]: [],
  [constants.PREPARING_LIST]: [],
  [constants.TOTRA_LIST]: [],
  [constants.RNQ_LIST]: intialPaginationState,
  // Clients ===================================================================
  [constants.CLIENT_LIST]: intialPaginationState,
  [constants.CLIENT_NG_USER]: {},
  [constants.CLIENT_PREFERABLE_USER]: {},
  [constants.CLIENT_COMMON_REFERENCE]: {},
  [constants.CLIENT_WEBSITE]: {},
  // Users =====================================================================
  [constants.USER_LIST]: intialPaginationState,
  // Accounting ================================================================
  [constants.PAYMENT_SEARCH]: [],
  [constants.INVOICE_LIST]: initialMonthlyState,
  [constants.SALES_REPORT]: initalReportState,
  [constants.NUMBER_REPORT]: initalReportState,
  [constants.SALES_TARGET]: initialYearlyState,


  // Employee ==================================================================
  // Jobs ======================================================================
  [constants.EMPLOYEE_JOB_DAILY_SCHEDULE]: initialDailyState,
  [constants.EMPLOYEE_JOB_FUTURE_SCHEDULE]: initialDailyState,
  [constants.TOTRA_CURRENT_JOBS]: [],
  [constants.TRANSLATOR_CURRENT_JOBS]: [],
  [constants.EDITOR_CURRENT_JOBS]: [],
  [constants.CHECKER_CURRENT_JOBS]: [],
  [constants.FINAL_EDITOR_CURRENT_JOBS]: [],
  [constants.SECOND_FINAL_EDITOR_CURRENT_JOBS]: [],
  [constants.DTP_CURRENT_JOBS]: [],
  [constants.SECOND_DTP_CURRENT_JOBS]: [],
  [constants.EMPLOYEE_JOB_HISTORY]: intialPaginationState,

  // Common ====================================================================
  // Documents =================================================================
  [constants.JOB_SOURCEFILE]: {},
  [constants.JOB_REFERENCE]: {},
  [constants.JOB_COMMON_REFERENCE]: {},
  [constants.JOB_CLIENT_REFERENCE]: {},
  [constants.ASSIGNMENT_PROJECT]: {},
  [constants.QUOTE_SOURCEFILE]: {},

  // Messages ==================================================================
  [constants.JOB_MESSAGE_LIST]: {},
  [constants.JOB_INVOICE_LIST]: {},

  // wordlist ==================================================================
  [constants.WORDLIST]: intialPaginationState,
  [constants.GLOSSARY_LIST]: intialPaginationState,
  [constants.TERM_SEARCH]: intialPaginationState,
  [constants.MEMORY_SEARCH]: intialPaginationState,
  [constants.MEMORY_SEARCH_LIST]: intialPaginationState,

  [constants.ANALYSIS_REPORT]: initalAnalysisReportState,

  // Customers ==================================================================
  [constants.CUSTOMER_LIST]: intialPaginationState,

  // Quotes ==================================================================
  [constants.QUOTE_LIST]: intialPaginationState,
  // Unsupported Order =====================================================
  [constants.UNSUPPORTED_ORDER_LIST]: intialPaginationState,

  // MHT Delivery  ==================================================================
  [constants.MHT_JOB_LIST]: intialPaginationState,
  [constants.MHT_JOB_MESSAGE]: {},
}


export default function (state = initialState, action) {
  switch (action.type) {

    case types.SET_RESULT:
      return setResult(state, action);

    case types.ADD_ITEM:
      return addItem(state, action);

    case types.REMOVE_ITEM:
      return removeItem(state, action)

    case types.CLEAR_RESULT:
      return clearResult(state, action)

    case types.SET_NESTED_RESULT:
      return setNestedResult(state, action);

    case types.ADD_NESTED_ITEM:
      return addNestedItem(state, action);

    case types.ADD_NESTED_ITEMS:
      return addNestedItems(state, action);

    case types.REMOVE_NESTED_ITEM:
      return removeNestedItem(state, action);

    case types.SET_DAILY_RESULT:
      return setDailyResult(state, action);

    case types.SET_MONTHLY_RESULT:
      return setMonthlyResult(state, action);

    case types.SET_YEARLY_RESULT:
      return setYearlyResult(state, action);

    case types.ADD_YEARLY_ITEM:
      return addYearlyItem(state, action);

    case types.SET_REPORT_RESULT:
      return setReportResult(state, action);

    case types.SET_ANALYSIS_REPORT_RESULT:
        return setAnalysisReportResult(state, action);

    case types.SET_PAGINATION_RESULT:
      return setPaginationResult(state, action);

    case types.ADD_PAGINATION_ITEM:
      return addPaginationItem(state, action);

    case types.REMOVE_PAGINATION_ITEM:
      return removePaginationItem(state, action);

    case types.CLEAR_PAGINATION_RESULT:
      return clearPaginationResult(state, action);

    case types.SET_HITS:
      return setHits(state, action);

    default:
      return state;
  }
}

const setResult = (state, action) => {
  const { name, result } = action.payload;
  return {
    ...state,
    [name]: result
  }
}

const addItem = (state, action) => {
  const { name, result } = action.payload;
  const _list = state[name] ? state[name] : [];
  return {
    ...state,
    [name]: [
      ..._list,
      result
    ]
  }
}


const removeItem = (state, action) => {
  const { name, result } = action.payload;
  return {
    ...state,
    [name]: state[name].filter(item => item !== result)
  }
}

const clearResult = (state, action) => {
  const { name } = action.payload;
  return {
    ...state,
    [name]: []
  }
}


const setNestedResult = (state, action) => {
  const { name, id, result } = action.payload;
  return {
    ...state,
    [name]: {
      ...state[name],
      [id]: result
    }
  }
}

const addNestedItem = (state, action) => {
  const { name, id, result } = action.payload;
  const _list = state[name][id] ? state[name][id] : [];
  return {
    ...state,
    [name]: {
      ...state[name],
      [id]: [
        ..._list,
        result
      ]
    }
  }
}

const addNestedItems = (state, action) => {
  const { name, id, result } = action.payload;
  const _list = state[name][id] ? state[name][id] : [];
  return {
    ...state,
    [name]: {
      ...state[name],
      [id]: [
        ..._list,
        ...result
      ]
    }
  }
}

const removeNestedItem = (state, action) => {
  const { name, id, result } = action.payload;
  return {
    ...state,
    [name]: {
      ...state[name],
      [id]: state[name][id].filter(item => item !== result)
    }
  }
}

const setDailyResult = (state, action) => {
  const { name, currentDate, result } = action.payload;
  return {
    ...state,
    [name]: {
      ...state[name],
      [currentDate]: result
    }
  }
}

const setMonthlyResult = (state, action) => {
  const { name, currentMonth, result } = action.payload;
  return {
    ...state,
    [name]: {
      ...state[name],
      [currentMonth]: result
    }
  }
}

const setYearlyResult = (state, action) => {
  const { name, currentYear, result } = action.payload;
  return {
    ...state,
    [name]: {
      ...state[name],
      [currentYear]: result
    }
  }
}

const addYearlyItem = (state, action) => {
  const { name, currentYear, result } = action.payload;
  const _list = state[name][currentYear] ? state[name][currentYear] : [];
  return {
    ...state,
    [name]: {
      ...state[name],
      [currentYear]: [
        ..._list,
        result
      ]
    }
  }
}

const setReportResult = (state, action) => {
  const {
    name,
    currentMonth,
    quotationResults,
    quotationTotal,
    countResults,
    countTotal,
    target,
    progress
   } = action.payload;
  return {
    ...state,
    [name]: {
      ...state[name],
      [currentMonth]: {
        quotationResults,
        quotationTotal,
        countResults,
        countTotal,
        target,
        progress
      }
    }
  }
}

const setAnalysisReportResult = (state, action) => {
  const {
    name,
    currentMonth,
    results,
    avgMonthly,
   } = action.payload;
  return {
    ...state,
    [name]: {
      ...state[name],
      [currentMonth]: {
        results,
        avgMonthly,
      }
    }
  }
}

const setPaginationResult = (state, action) => {
  const { name, page, result, totalCount } = action.payload
  return {
    ...state,
    [name]: {
      ...state[name],
      currentPage: page,
      totalCount: totalCount,
      pages: {
        ...state[name].pages,
        [page]: result
      },
    }
  }
}

const addPaginationItem = (state, action) => {
  const { name, result } = action.payload;
  const curentPage = state[name]['currentPage'];
  return {
    ...state,
    [name]: {
      ...state[name],
      pages: {
        ...state[name].pages,
        [curentPage]: [
          ...state[name].pages[curentPage],
          result
        ]
      }
    }
  }
}

const removePaginationItem = (state, action) => {
  const { name, result } = action.payload;
  const pages = { ...state[name]['pages'] };
  Object.keys(pages).forEach(key => {
    if (pages[key].includes(result)) {
      pages[key] = pages[key].filter(id => id !== result)
    }
  })
  return {
    ...state,
    [name]: {
      ...state[name],
      pages,
    }
  }
}

const clearPaginationResult = (state, action) => {
  const { name } = action.payload;
  return {
    ...state,
    [name]: intialPaginationState
  }
}

const setHits = (state, action) => {
  const { name, count } = action.payload;
  return {
    ...state,
    [name]: {
      ...state[name],
      hits: count
    }
  }
}