import React from 'react';
import { Box } from 'reactjs-admin-lte';
import { createMarkup } from 'misc/utils';

const JobNote = (props) => {
  const {
    title,
    content,
    isLoading,
    collapsed
  } = props;

  return (
    <Box
      collapsed={collapsed}
      loading={isLoading}
      className="box-widget job-note-box">
      <Box.Header border={true}>
        <Box.Title>{title}</Box.Title>
        <Box.Tools>
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body>
        <div dangerouslySetInnerHTML={createMarkup(content)} />
      </Box.Body>
    </Box>
  )
}

JobNote.defaultProps = {
  collapsed: false
}

export default JobNote;
