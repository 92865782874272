import React from 'react'
import { connect } from 'react-redux';
import { Button, Modal, Alert } from 'react-bootstrap';
import { connectModal } from 'redux-modal'
import FontAwesome from 'react-fontawesome';
import Dropzone from 'react-dropzone';
import { isEmployeeAssignmentProjectUploadModalLoading } from 'selectors/loading';
import { EMPLOYEE_ASSIGNMENT_PROJECT_UPLOAD_MODAL } from 'misc/constants';
import { MAX_FILE_SIZE, MIN_FILE_SIZE, LARGE_FILE_SIZE } from 'misc/config';
import Spinner from 'components/core/Spinner';
import { humanizeBytes } from 'misc/utils';


class EmployeeAssignmentProjectUploadModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      errors: [],
      warning: [],
    }
  }

  handleDropSuccess = (acceptedFiles) => {
    const files = this.state.files;
    const warning = this.state.warning;
    acceptedFiles.forEach(file => {
      if (file.size > LARGE_FILE_SIZE) {
        warning.push(`${file.name} (${humanizeBytes(file.size)}): Large file size (${humanizeBytes(LARGE_FILE_SIZE)}), please allow additional time.`);
      }
      files.push(file);
    })
    this.setState({ files });
  }

  handleDropFailure = (rejectedFiles) => {
    const errors = [];
    rejectedFiles.forEach(file => {
      if (file.size > MAX_FILE_SIZE) {
        errors.push(`${file.name} (${humanizeBytes(file.size)}): File exceeds Max size limit, ${humanizeBytes(MAX_FILE_SIZE)}. Please upload a smaller file.`);
      } else if (file.size < MIN_FILE_SIZE) {
        errors.push(`${file.name} (${humanizeBytes(file.size)}): It looks like an empty file!`);
      }
    })
    this.setState({ errors });
  }


  handleDismiss = (key) => {
    this.setState({ [key]: [] });
  }

  handleCancel = (index) => {
    const files = this.state.files.filter((file, idx) => idx !== index)
    this.setState({ files: files });
  }

  renderFiles = () => {
    return this.state.files.map((file, idx) => (
      <li key={idx}>
        <a>
          {file.name}
          <span className="pull-right" style={{ marginTop: '-2px' }}>
            <Button bsStyle="danger" onClick={() => this.handleCancel(idx)} bsSize="xs">
              <FontAwesome name="ban" className="mr-5" />Cancel
            </Button>
          </span>
        </a>
      </li>
    ))
  }

  render() {

    const {
      show,
      title,
      handleUpload,
      handleHide,
      isLoading,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Upload {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="file-upload-modal no-padding">
          <Dropzone
            onDropAccepted={this.handleDropSuccess}
            onDropRejected={this.handleDropFailure}
            maxSize={MAX_FILE_SIZE}
            minSize={MIN_FILE_SIZE}
            className="file-upload"
            activeClassName="active"
            multiple={true}>
            <p className="text-center">Drag file or click here to upload file(s).<br />Max file upload size is <b>{humanizeBytes(MAX_FILE_SIZE)}</b>.</p>
          </Dropzone>

          { this.state.errors &&
            this.state.errors.length > 0 &&
            <div className="m-5">
              <Alert
                onDismiss={() => this.handleDismiss('errors')}
                bsStyle="danger"
                className="mb-0">
                <ul className="pl-15">
                  { this.state.errors.map((e, idx) => <li key={idx}>{e}</li>) }
                </ul>
              </Alert>
            </div>
          }
          { this.state.warning &&
            this.state.warning.length > 0 &&
            <div className="m-5">
              <Alert
                onDismiss={() => this.handleDismiss('warning')}
                bsStyle="warning"
                className="mb-0">
                <ul className="pl-15">
                  { this.state.warning.map((e, idx) => <li key={idx}>{e}</li>) }
                </ul>
              </Alert>
            </div>
          }

          <ul className="nav nav-pills nav-stacked">
            {this.renderFiles()}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pull-left"
            bsSize="sm"
            type="button"
            onClick={handleHide}>
            <FontAwesome name="close" className="mr-5" />Close
        </Button>
          <Button
            bsStyle="primary"
            bsSize="sm"
            type="button"
            onClick={() => handleUpload(this.state.files)}
            disabled={this.state.files.length === 0}>
            <FontAwesome name="paper-plane-o" className="mr-5" />Submit
          </Button>
        </Modal.Footer>
        <Spinner isLoading={isLoading} />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: isEmployeeAssignmentProjectUploadModalLoading(state)
  }
}

EmployeeAssignmentProjectUploadModal = connectModal({
  name: EMPLOYEE_ASSIGNMENT_PROJECT_UPLOAD_MODAL
})(EmployeeAssignmentProjectUploadModal);

export default connect(
  mapStateToProps
)(EmployeeAssignmentProjectUploadModal);
