import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Alert, Modal, Button, Form } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import { Field, reduxForm, formValueSelector, change, reset } from 'redux-form';
import {
  TRANSLATION_MEMORY_UPLOAD_MODAL,
  TRANSLATION_MEMORY_UPLOAD_FORM,
 } from 'misc/constants';
import * as validators from 'misc/validators';
import { getMemoryServiceOptions } from 'selectors/options';
import { isTranslationMemoryUploadModalLoading } from 'selectors/loading';
import FontAwesome from 'react-fontawesome';
import Spinner from 'components/core/Spinner';
import PureRenderComponent from 'components/core/PureRenderComponent';
import CharField from 'components/core/CharField';
import SelectField from 'components/core/SelectField';
import CheckboxField from 'components/core/CheckboxField';
import DatePickerField from 'components/core/DatePickerField';
import ClientAsyncSelectField from 'components/core/ClientAsyncSelectField';
import JobNumberAsyncSelectField from 'components/core/JobNumberAsyncSelectField';
import SingleDropzoneField from 'components/core/SingleDropzoneField';


class TranslationMemoryUploadModal extends PureRenderComponent {

  hide = () => {
    this.props.handleHide()
    this.props.reset(TRANSLATION_MEMORY_UPLOAD_FORM);
  }

  handleCancel = () => {
    this.props.change('file', null);
  }

  renderFiles = () => {
    return this.props.file && (
      <li>
        <a>
          {this.props.file.name}
          <span className="pull-right" style={{ marginTop: '-2px' }}>
            <Button bsStyle="danger" onClick={this.handleCancel} bsSize="xs">
              <FontAwesome name="ban" className="mr-5" />Cancel
            </Button>
          </span>
        </a>
      </li>
    )
  }

  render() {
    const {
      show,
      error,
      handleHide,
      handleSubmit,
      handleAddTranslationMemory,
      isLoading,
      serviceOptions,
      addList,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Form
          noValidate
          autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title>Add New Translation Memory</Modal.Title>
          </Modal.Header>
          <Modal.Body className="file-upload-modal no-padding">
            <Field
              name="file"
              component={SingleDropzoneField}
              validate={[validators.required]}
              isRequired={true} />
            <ul className="nav nav-pills nav-stacked">
              {this.renderFiles()}
            </ul>
            {
              error &&
              <div className="m-5">
                <Alert
                  bsStyle="danger"
                  className="mb-0">
                  <ul className="pl-15">
                    <li>{error}</li>
                  </ul>
                </Alert>
              </div>
            }
            <div className="p-15">
              <Field
                name="service"
                label="Service"
                validate={[
                  validators.required,
                ]}
                isRequired={true}
                options={serviceOptions}
                component={SelectField.Labeled} />
              <Field
                name="client"
                label="Client"
                validate={[
                  validators.required
                ]}
                isRequired={true}
                component={ClientAsyncSelectField.Labeled} />
              <Field
                name="jobs"
                label="Job"
                multi={true}
                component={JobNumberAsyncSelectField.Labeled} />
              <Field
                name="registered"
                label="Register date"
                bsSize="sm"
                component={DatePickerField.Labeled} />
              <Field
                className="mt-5 mb-15"
                name="isPenalty"
                label="penalty"
                component={CheckboxField.Labeled} />
              <Field
                className="mt-5 mb-10"
                name="addList"
                label="Add Translation Memory List"
                component={CheckboxField.Labeled} />
              { addList && (
                <Field
                  name="listName"
                  label="List Name"
                  type="textr"
                  validate={[
                    validators.required,
                  ]}
                  isRequired={true}
                  component={CharField.Labeled} />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pull-left"
              bsSize="sm"
              type="button"
              onClick={this.hide}>
              <FontAwesome name="close" className="mr-5" />Close
            </Button>
            <Button
              bsStyle="primary"
              bsSize="sm"
              type="button"
              onClick={handleSubmit(values => handleAddTranslationMemory(values))}>
              <FontAwesome name="paper-plane-o" className="mr-5" />Submit
            </Button>
          </Modal.Footer>
          <Spinner isLoading={isLoading} />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const formSelector = formValueSelector(TRANSLATION_MEMORY_UPLOAD_FORM);
  const file = formSelector(state, 'file');
  const addList = formSelector(state, 'addList');
  return {
    file,
    addList,
    serviceOptions: getMemoryServiceOptions(state),
    isLoading: isTranslationMemoryUploadModalLoading(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    reset,
    change,
  }, dispatch)
}

TranslationMemoryUploadModal = reduxForm({
  form: TRANSLATION_MEMORY_UPLOAD_FORM
})(TranslationMemoryUploadModal);

TranslationMemoryUploadModal = connectModal({
  name: TRANSLATION_MEMORY_UPLOAD_MODAL
})(TranslationMemoryUploadModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationMemoryUploadModal);

