
import axios from 'axios';

const JobApi = {
  sourcefileList: (id) => {
    return axios.get(`jobs/${id}/sourcefiles/`);
  },
  referenceList: (id) => {
    return axios.get(`jobs/${id}/references/`);
  },
  commonReferenceList: (group) => {
    return axios.get(`job-groups/${group}/references/`);
  },
  clientReferenceList: (id) => {
    return axios.get(`jobs/${id}/references/common/`);
  },
  // Message ===================================================================
  messageCreate: (id, values) => {
    return axios.post(`jobs/${id}/messages/`, values);
  },
  messageList: (id) => {
    return axios.get(`jobs/${id}/messages/`);
  },
  messageUpdate: (id, messageId, values) => {
    return axios.put(`jobs/${id}/messages/${messageId}/`, values);
  },
  messageRemove: (id, messageId) => {
    return axios.delete(`jobs/${id}/messages/${messageId}`);
  },
}

export default JobApi;