import React from 'react';
import { FormControl, FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';

const TextField = (props) => {
  
  const {
    input,
    disabled,
    rows = 8
  } = props;
  
  return (
    <FormControl
      {...input}
      componentClass="textarea"
      disabled={disabled}
      rows={rows} />
  );
}

const LabeledTextField = (props) => {
  const { label, isRequired } = props;
  const { invalid, touched, error } = props.meta
  const hasError = (touched && invalid) ? 'error' : null;

  const requiredMark = isRequired
    ? <span className="text-danger">*</span>
    : null

  const helpBlock = hasError
    ? <HelpBlock>{error}</HelpBlock>
    : null
  
  return (
    <FormGroup validationState={hasError}>
      <ControlLabel>
        {label}
        {requiredMark}
      </ControlLabel>
      <TextField {...props} />
      {helpBlock}
    </FormGroup>
  )
}

TextField.Labeled = LabeledTextField;

export default TextField;
