import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Form, Button, FormGroup, ControlLabel } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import { Field, reduxForm } from 'redux-form';
import * as validators from 'misc/validators';
import Callout from 'components/core/Callout';
import CharField from 'components/core/CharField';
import TextField from 'components/core/TextField';
import CheckboxField from 'components/core/CheckboxField'
import CheckboxGroupField from 'components/core/CheckboxGroupField';
import validate from './validate';


const UserCreateForm = (props) => {
  const {
    isLoading,
    groupOptions,
    handleSubmit,
    invalid,
    submitFailed,
  } = props;
  
  return (
    <Form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}>
      <Callout
        invalid={invalid}
        submitFailed={submitFailed} />
      <Box
        className="box-widget"
        loading={isLoading}>
        <Box.Body>
          <Field
            name="username"
            label="Username"
            type="text"
            isRequired={true}
            validate={[
              validators.required,
              validators.maxLength150,
              validators.username
            ]}
            component={CharField.Labeled} />
          <Field
            name="name"
            label="Short Name"
            type="text" 
            isRequired={true}
            validate={[
              validators.required,
              validators.maxLength50,
            ]}
            component={CharField.Labeled} />
          <Field
            name="firstName"
            label="First Name"
            type="text"
            validate={[
              validators.maxLength30,
            ]}
            component={CharField.Labeled} />
          <Field
            name="lastName"
            label="Last Name"
            type="text"
            validate={[
              validators.maxLength150,
            ]}
            component={CharField.Labeled} />
          <Field
            name="email"
            label="Email"
            type="text"
            isRequired={true}
            validate={[
              validators.required,
              validators.maxLength254,
              validators.email
            ]}
            component={CharField.Labeled} />
          <Field
            name="password"
            label="Password"
            type="password"
            isRequired={true}
            validate={[
              validators.required,
              validators.maxLength128
            ]}
            component={CharField.Labeled} />
          <Field
            name="passwordConfirm"
            label="Password (again)"
            type="password"
            isRequired={true}
            validate={[
              validators.required,
              validators.maxLength128
            ]}
            component={CharField.Labeled} />
          <Field
            name="groups"
            label="Groups"
            isRequired={true}
            options={groupOptions}
            validate={[
              validators.required,
              validators.minLength1
            ]}
            component={CheckboxGroupField.Labeled} />
          <FormGroup>
            <ControlLabel>Options</ControlLabel>
            <Field
              name="isFreelance"
              label="is Freelance"
              component={CheckboxField.Labeled} />
            <Field
              name="isNative"
              label="is Native"
              component={CheckboxField.Labeled} />
            <Field
              name="isActive"
              label="is Active"
              component={CheckboxField.Labeled} />
          </FormGroup>
          <Field
            name="notes"
            label="Notes"
            component={TextField.Labeled} />
        </Box.Body>
        <Box.Footer>
          <Button bsStyle="primary" bsSize="sm" type="submit">
            <FontAwesome name="paper-plane-o" className="mr-5" />Submit
          </Button>
        </Box.Footer>
      </Box>
    </Form>
  )
}

export default reduxForm({
  validate
})(UserCreateForm);
