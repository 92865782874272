import React from 'react';
import classNames from 'classnames';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import LabeledField from 'components/core/LabeledField';
import HorizontalField from 'components/core/HorizontalField';


const TimeField = (props) => {

  const {
    input,
    disabled,
    className,
  } = props;

  return (
    <TimePicker
      {...input}
      disabled={disabled}
      showSecond={false}
      minuteStep={15}
      defaultOpenValue={moment().hour(0).minute(0).second(0)}
      value={
        input.value
        ? moment(input.value, 'HH:mm:ss')
        : null
      }
      format="HH:mm"
      onChange={(value) => {
        input.onChange(value && value.format('HH:mm:ss'))
      }}
      onBlur={() => input.onBlur()}
      className={classNames(className)} />
  )
}

const LabeledTimeField = (props) => {
  return (
    <LabeledField {...props}>
      <TimeField {...props} />
    </LabeledField>
  );
}

const HorizontalTimeField = (props) => {
  return (
    <HorizontalField {...props}>
      <TimeField {...props} />
    </HorizontalField>
  );
}

const InlineTimeField = (props) => {
  const { invalid, touched } = props.meta
  const cls = classNames({
    'has-error': invalid && touched
  })
  return (
    <TimeField
      {...props}
      className={cls} />
  );
}

TimeField.Labeled = LabeledTimeField;
TimeField.Horizontal = HorizontalTimeField;
TimeField.Inline = InlineTimeField

export default TimeField;
