import React from 'react';
import ListTable from 'components/core/ListTable';
import { JOB_DETAIL } from 'misc/constants';
import { getFieldTotalCount, getDetailTabConfigByName } from 'misc/utils';
import { CheckboxCellRenderer } from 'misc/cellRenderers';
import { datetimeFormatter, currencyFormatter} from 'misc/cellFormatters';
import PureRenderComponent from 'components/core/PureRenderComponent';


class LinkFieldCellRenderer extends PureRenderComponent {
  render() {
    const { value, data, colDef } = this.props;
    const { tab, handleTabOpen, params = {} } = colDef.cellRendererParams;
    if (value && data && data.job) {
      const id = data.job.id;
      const tabParams = { ...params, id };
      const tabConfig = getDetailTabConfigByName(tab, id, value, tabParams);
      return <a onClick={() => handleTabOpen(tabConfig)}>{value}</a>
    }
    return null;
  }
}

class PaymentSearchTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      countSum: 0,
      columnDefs: [
        {
          headerName: 'No.',
          field: 'job.number',
          pinned: 'left',
          cellClass: 'ag-jobNumber',
          width: 170,
          lockPinned: true,
          lockPosition: true,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: JOB_DETAIL,
          },
        },
        {
          headerName: 'Service',
          field: 'job.service.name',
          width: 150,
        },
        {
          headerName: 'Deadline',
          field: 'deadline',
          width: 180,
          valueFormatter: datetimeFormatter,
        },
        {
          headerName: 'Count',
          field: 'count',
          width: 120,
          filter: 'agNumberColumnFilter',
          valueFormatter: currencyFormatter
        },
        {
          headerName: 'Paid',
          field: 'isPaid',
          suppressFilter: true,
          cellRendererFramework: CheckboxCellRenderer,
          cellRendererParams: { handleCheck: this.props.handleCheck },
          width: 100,
        }
      ]
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.payments !== this.props.payments) {
      this.setState({ countSum: getFieldTotalCount(this.props.payments, 'count') })
    }
  }

  handleFilterChanged = (event) => {
    let sum = 0
    event.api.forEachNodeAfterFilter(node => {
      sum += node.data.count
    });
    this.setState({ countSum: sum });
  }

  getPinnedBottomRowData = () => ([{
    'count': this.state.countSum,
  }]);

  render() {
    return (
      <ListTable
        columnDefs={this.state.columnDefs}
        rowData={this.props.payments}
        wrapperHeight='66vh'
        suppressRowClickSelection={true}
        onFilterChanged={this.handleFilterChanged}
        pinnedBottomRowData={this.getPinnedBottomRowData()} />
    );
  }
}

export default PaymentSearchTable;
