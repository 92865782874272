import React from 'react';
import ListTable from 'components/core/ListTable';
import { getTabByRole } from 'misc/config';
import { getFieldTotalCount } from 'misc/utils';
import { datetimeFormatter } from 'misc/cellFormatters';
import { LinkFieldCellRenderer } from 'misc/cellRenderers';
import PureRenderComponent from 'components/core/PureRenderComponent';


class EmployeeJobHistoryTable extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = {
      sourceSum: 0,
      targetSum: 0,
      additionalSum: 0,
    }
  }

  getColumnDefs = () => {
    const { roleKey, handleTabOpen } = this.props;
    const isTranslator = roleKey === 'translator';

    const columnDefs = [];
    columnDefs.push({
      headerName: 'No.',
      field: 'number',
      pinned: 'left',
      cellClass: 'ag-jobNumber',
      lockPinned: true,
      lockPosition: true,
      width: 140,
      cellRendererFramework: LinkFieldCellRenderer,
      cellRendererParams: {
        handleTabOpen,
        tab: getTabByRole(roleKey),
        params: { fromHistory: true }
      },
    });

    if (!isTranslator) {
      columnDefs.push({
        headerName: 'Client',
        field: 'client.name',
        width: 250,
      });
    }

    columnDefs.push({
      headerName: 'Deadline',
      field: `${roleKey}.deadline`,
      width: 140,
      valueFormatter: datetimeFormatter,
    });
    columnDefs.push({
      headerName: 'Subject',
      field: 'subject',
      width: 300,
    })
    columnDefs.push({
      headerName: 'Service',
      field: 'service.name',
      width: 80,
    })
    columnDefs.push({
      headerName: 'Lv',
      field: 'level',
      width: 40,
    })
    if (!isTranslator) {
      columnDefs.push({
        headerName: 'AE',
        field: 'ae.name',
        width: 80,
      });
    }
    columnDefs.push({
      headerName: 'Coordinator',
      field: 'coordinator.name',
      width: 120,
    });
    if (isTranslator) {
      columnDefs.push({
        headerName: 'Source',
        field: 'sourceCount',
        width: 90,
        filter: 'agNumberColumnFilter',
      })
    }

    columnDefs.push({
      headerName: 'Target',
      field: 'targetCount',
      width: 90,
      filter: 'agNumberColumnFilter',
    });
    
    if (!isTranslator) {
      columnDefs.push({
        headerName: 'Add',
        field: 'additionalCount',
        width: 90,
        filter: 'agNumberColumnFilter',
      });
    }

    columnDefs.push({
      headerName: 'Status',
      field: 'status',
      width: 140,
    });
    return columnDefs;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.jobs !== this.props.jobs) {
      this.setState({
        sourceSum: getFieldTotalCount(this.props.jobs, 'sourceCount'),
        targetSum: getFieldTotalCount(this.props.jobs, 'targetCount'),
        additionalSum: getFieldTotalCount(this.props.jobs, 'additionalCount')
      })
    }
  }

  getPinnedBottomRowData = () => ([{
    'sourceCount': this.state.sourceSum,
    'targetCount': this.state.targetSum,
    'additionalCount': this.state.additionalSum,
  }])

  handleFilterChanged = (event) => {
    let sourceSum = 0;
    let targetSum = 0;
    let additionalSum = 0;
    event.api.forEachNodeAfterFilter(node => {
      sourceSum += node.data.sourceCount;
      targetSum += node.data.targetCount;
      additionalSum += node.data.additionalCount;
    });
    this.setState({
      sourceSum,
      targetSum,
      additionalSum
    });
  }

  render() {
    return (
      <ListTable
        wrapperHeight='53vh'
        columnDefs={this.getColumnDefs()}
        rowData={this.props.jobs}
        deltaRowDataMode={true}
        onFilterChanged={this.handleFilterChanged}
        getRowNodeId={data => data.id}
        pinnedBottomRowData={this.getPinnedBottomRowData()} />
    )
  }
}

export default EmployeeJobHistoryTable;
