import React from 'react'
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Button, Modal, Form } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import { Field, reduxForm } from 'redux-form';
import { JOB_MESSAGE_MODAL, JOB_MESSAGE_FORM } from 'misc/constants';
import { isJobMessageLoading } from 'selectors/loading';
import { getMessageReceiverOptions } from 'selectors/options';
import TextField from 'components/core/TextField'
import CheckboxGroupField from 'components/core/CheckboxGroupField'
import Spinner from 'components/core/Spinner';
import * as validators from 'misc/validators';
import PureRenderComponent from 'components/core/PureRenderComponent';


const SubmitButton = (
  <Button
    bsStyle="primary"
    bsSize="sm"
    type="submit">
    <FontAwesome name="paper-plane-o" className="mr-5" />Submit
  </Button>
)

const UpdateButton = (
  <Button
    bsStyle="success"
    bsSize="sm"
    type="submit">
    <FontAwesome name="refresh" className="mr-5" />Update
  </Button>
)

const buttonMap = {
  'submit': SubmitButton,
  'update': UpdateButton
}

class JobMessageModal extends PureRenderComponent {

  handleSubmit = (values) => {
    this.props._handleSubmit(values);
  }

  render() {
    const {
      show,
      handleHide,
      isLoading,
      title,
      mode,
      handleSubmit,
      isReceiverHidden,
      receiverOptions
    } = this.props

    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              name="body"
              label="Message"
              validate={[validators.required]}
              isRequired={true}
              component={TextField.Labeled} />
            { !isReceiverHidden && <Field
                name="roles"
                label="Send To"
                validate={[
                  validators.required,
                  validators.minLength1
                ]}
                options={receiverOptions}
                isRequired={true}
                component={CheckboxGroupField.Labeled} />
              }
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pull-left"
              bsSize="sm"
              type="button"
              onClick={handleHide}>
              <FontAwesome name="close" className="mr-5" />Close
            </Button>
            {buttonMap[mode]}
          </Modal.Footer>
        </Form>
        <Spinner isLoading={isLoading} />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: isJobMessageLoading(state),
    receiverOptions: getMessageReceiverOptions(state),
  }
}


JobMessageModal = connectModal({ name: JOB_MESSAGE_MODAL })(JobMessageModal);
JobMessageModal = reduxForm({ form: JOB_MESSAGE_FORM })(JobMessageModal);

export default connect(
  mapStateToProps
)(JobMessageModal);

