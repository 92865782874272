import React from 'react';
import { JOB_DETAIL, DELIVERY_DETAIL } from 'misc/constants';
import {
  getFieldTotalCount,
  getRowHeight,
  deadlineComparator
} from 'misc/utils';
import {
  LinkFieldCellRenderer,
  AssignmentFieldCellRenderer,
  ClientFieldCellRenderer,
  ScheduleStatusCellRenderer
} from 'misc/cellRenderers';
import PureRenderComponent from 'components/core/PureRenderComponent';
import ListTable from 'components/core/ListTable';


class JobSearchTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      targetSum: 0,
      additionalSum: 0,
      columnDefs: [
        {
          headerName: 'No.',
          field: 'number',
          pinned: 'left',
          cellClass: 'ag-jobNumber',
          lockPinned: true,
          lockPosition: true,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: JOB_DETAIL,
          },
          width: 110,
        },
        {
          headerName: 'Client',
          field: 'client.name',
          cellRendererFramework: ClientFieldCellRenderer,
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data, nodeB.data)
          ),
          width: 150,
        },
        {
          headerName: 'Subject',
          field: 'subject',
          width: 250,
        },
        {
          headerName: 'Memo',
          field: 'memo',
          width: 85,
        },
        {
          headerName: 'Service',
          field: 'service.name',
          width: 80,
        },
        {
          headerName: 'Lv',
          field: 'level',
          width: 40,
        },
        {
          headerName: 'ToTra',
          field: 'totra.assignee.name',
          cellRendererFramework: AssignmentFieldCellRenderer,
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.totra, nodeB.data.totra)
          ),
          cellRendererParams: {
            roleKey: 'totra',
          },
          width: 85,
        },
        {
          headerName: 'Tra',
          field: 'translator.assignee.name',
          cellRendererFramework: AssignmentFieldCellRenderer,
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.translator, nodeB.data.translator)
          ),
          cellRendererParams: {
            roleKey: 'translator',
          },
          width: 85,
        },
        {
          headerName: 'Edi',
          field: 'editor.assignee.name',
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.editor, nodeB.data.editor)
          ),
          cellRendererFramework: AssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'editor',
          },
          width: 85,
        },
        {
          headerName: 'Che',
          field: 'checker.assignee.name',
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.checker, nodeB.data.checker)
          ),
          cellRendererFramework: AssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'checker',
          },
          width: 85,
        },
        {
          headerName: 'FE1',
          field: 'finalEditor.assignee.name',
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.finalEditor, nodeB.data.finalEditor)
          ),
          cellRendererFramework: AssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'finalEditor',
          },
          width: 85,
        },
        {
          headerName: 'FE2',
          field: 'secondFinalEditor.assignee.name',
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.secondFinalEditor, nodeB.data.secondFinalEditor)
          ),
          cellRendererFramework: AssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'secondFinalEditor',
          },
          width: 85,
        },
        {
          headerName: 'DTP1',
          field: 'dtp.assignee.name',
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.dtp, nodeB.data.dtp)
          ),
          cellRendererFramework: AssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'dtp',
          },
          width: 85,
        },
        {
          headerName: 'DTP2',
          field: 'secondDtp.assignee.name',
          comparator: (valueA, valueB, nodeA, nodeB) => (
            deadlineComparator(nodeA.data.secondDtp, nodeB.data.secondDtp)
          ),
          cellRendererFramework: AssignmentFieldCellRenderer,
          cellRendererParams: {
            roleKey: 'secondDtp',
          },
          width: 85,
        },
        {
          headerName: 'Target',
          field: 'targetCount',
          filter: 'agNumberColumnFilter',
          width: 85,
        },
        {
          headerName: 'Add',
          field: 'additionalCount',
          filter: 'agNumberColumnFilter',
          width: 85,
        },
        {
          headerName: 'Coordinator',
          field: 'coordinator.name',
          width: 95,
        },
        {
          headerName: 'AE',
          field: 'ae.name',
          width: 80,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: DELIVERY_DETAIL,
          },
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 140,
          pinned: 'right',
          cellRendererFramework: ScheduleStatusCellRenderer
        }
      ]
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.jobs !== this.props.jobs) {
      this.setState({
        targetSum: getFieldTotalCount(this.props.jobs, 'targetCount'),
        additionalSum: getFieldTotalCount(this.props.jobs, 'additionalCount')
      })
    }
  }

  navigateToNextCell = (params) => {
    const previousCell = params.previousCellDef;
    const suggestedNextCell = params.nextCellDef;

    const KEY_UP = 38;
    const KEY_DOWN = 40;
    const KEY_LEFT = 37;
    const KEY_RIGHT = 39;

    switch (params.key) {
      case KEY_DOWN:
        this.gridApi.forEachNode(node => {
          if (previousCell.rowIndex + 1 === node.rowIndex) {
            node.setSelected(true);
          }
        });
        return suggestedNextCell;

      case KEY_UP:
        this.gridApi.forEachNode(node => {
          if (previousCell.rowIndex - 1 === node.rowIndex) {
            node.setSelected(true);
          }
        });
        return suggestedNextCell;

      case KEY_LEFT:
      case KEY_RIGHT:
        return suggestedNextCell;
      default:
        return
    }
  }

  getPinnedBottomRowData = () => ([{
    'targetCount': this.state.targetSum,
    'additionalCount': this.state.additionalSum,
  }])

  onGridReady = (params) => {
    this.gridApi = params.api;
  }

  handleFilterChanged = (event) => {
    let targetSum = 0;
    let additionalSum = 0
    event.api.forEachNodeAfterFilter(node => {
      targetSum += node.data.targetCount;
      additionalSum += node.data.additionalCount;
    });
    this.setState({ targetSum, additionalSum });
  }

  render() {
    return (
      <ListTable
        wrapperHeight="53vh"
        rowSelection="single"
        rowClass='ag-double-row'
        rowMultiSelectWithClick={false}
        suppressCellSelection={false}
        onGridReady={this.onGridReady}
        navigateToNextCell={this.navigateToNextCell}
        getRowHeight={getRowHeight}
        columnDefs={this.state.columnDefs}
        rowData={this.props.jobs}
        onFilterChanged={this.handleFilterChanged}
        onSelectionChanged={this.props.handleSelectionChange}
        pinnedBottomRowData={this.getPinnedBottomRowData()} />
    );
  }
}


export default JobSearchTable;
