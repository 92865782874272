import React from "react";
import * as PropTypes from "prop-types";
import CKEditor from "@ckeditor/ckeditor5-react";
import TaSegmentEditor from "@stefanyanku/ckeditor5-build-balloon";
import {ckEditorConfigTarget} from "./ckEditorConfig/ckEditorConfigTarget";
import segmentStyles from "components/mht/MHTEditor/TaMhtEditorSegment.module.css";

export class TaMhtEditorSegmentTarget extends React.Component {

  static propTypes = {
    tabindex: PropTypes.number.isRequired,
    html: PropTypes.string.isRequired,
    onFocus: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    validateEmptyString: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasChanged: false,
      hasFocus: false,
      offset: 0
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.html !== nextProps.html ||
      this.state.hasFocus !== nextState.hasFocus ||
      this.props.highlightKey !== nextProps.highlightKey ||
      this.props.hasHighlight !== nextProps.hasHighlight
    );
  }

  passive_onClick = (event, isEmptyString) => {
    let offset = 0;
    if (!isEmptyString) {
      // see: https://developer.mozilla.org/en-US/docs/Web/API/Document/caretRangeFromPoint
      if (document.caretPositionFromPoint) {
        offset = document.caretPositionFromPoint(event.clientX, event.clientY).offset;
      } else if (document.caretRangeFromPoint) {
        offset = document.caretRangeFromPoint(event.clientX, event.clientY).startOffset;
      }
    }
    this.setState({hasFocus: true, offset: offset})
  }

  onInit = (editor) => {
    const offset = this.state.offset;
    editor.editing.view.focus();
    this.onFocus();
    if (offset) {
      editor.model.change( writer => {
        const root = editor.model.document.getRoot();
        const startPosition = writer.createPositionFromPath( root, [ 0, offset ] );
        const range = writer.createRange( startPosition );
        writer.setSelection( range );
      } )
    }
  }

  onFocus = () => {
    this.setState({hasFocus: true});
    this.props.onFocus(this.props.segment);
  }

  onBlur = (event, editor) => {
    const props = this.props;
    if (this.state.hasChanged) {
      props.onChange(editor.getData(), props.segment);
      this.setChanged(false)
    }
    this.setState({hasFocus: false})
    if (props.onBlur){
      props.onBlur(event, props.segment, editor)
    }
  }

  onChange = () => {
    if (!this.state.hasChanged) {
      this.setChanged(true)
    }
  }

  setChanged = (value) => this.setState({hasChanged: value});

  render() {

    const {
      tabindex,
      html,
      preprocessHtml,
      validateEmptyString
    } = this.props;

    return (
      <React.Fragment>
        {
          (this.state.hasFocus) ? (
            <CKEditor
              tabIndex={tabindex}
              editor={TaSegmentEditor}
              config={ckEditorConfigTarget}
              data={html}
              onInit={this.onInit}
              onBlur={this.onBlur}
              onChange={this.onChange}
            />
          ) : (
            <React.Fragment>
              <input
                className={segmentStyles.focusTrap}
                onFocus={(e)=>this.onFocus(e)}
              />
              <div
                className={'ck ta-ck--passive'}
                dangerouslySetInnerHTML={{__html: preprocessHtml(html)}}
                onClick={(event => this.passive_onClick(event, validateEmptyString(html)))}
              />
            </React.Fragment>
          )
        }
      </React.Fragment>
    );

  }
}
