import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Checkbox } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';


const MemberCheckBox = (props) => {
  const {
    member,
    selectedJobs,
    handleSelect
  } = props;

  const handleChange = (e) => {
    const { checked } = e.target;
    handleSelect(checked, member)
  }

  const isChecked = () => {
    return selectedJobs.findIndex(job => (
      job.value === member.value
    )) !== -1
  }

  return (
    <Checkbox
      onChange={handleChange}
      checked={isChecked()}
      disabled={member.disabled}>
      {member.label}
    </Checkbox>
  );
}


const JobDeliveryDetailJobNavigation = (props) => {

  const {
    memberOptions,
    isLoading,
    isJobSelected,
    selectedJobs,
    isAllJobSelected,
    handleJobSelect,
    handleAllJobSelect,
    showInvoiceAddModal
  } = props;

  return (
    <Box
      className="box-primary navigation-box"
      loading={isLoading}>
      <Box.Header>
        <Box.Title>Job Navigation</Box.Title>
      </Box.Header>
      <Box.Body>
        <ul className="list-unstyled">
          <li>
            <Checkbox
              className="mt-0"
              onChange={handleAllJobSelect}
              checked={isAllJobSelected()}>Selct all
            </Checkbox>
          </li>
          <li>
            <ul className="list-unstyled ml-15">
              {memberOptions.map(member => (
                <li key={member.value}>
                  <MemberCheckBox
                    member={member}
                    selectedJobs={selectedJobs}
                    handleSelect={handleJobSelect} />
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </Box.Body>
      <Box.Footer>
        <button
          className="btn bg-green btn-sm pull-right"
          disabled={!isJobSelected()}
          onClick={showInvoiceAddModal}>
          <FontAwesome name="paperclip" className="mr-5" />Invoice
        </button>
      </Box.Footer>
    </Box>
  );
}

export default JobDeliveryDetailJobNavigation;
