import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CLIENT_EDIT_FORM } from 'misc/constants';
import { updateRequest, removeRequest } from 'actions/clients';
import { isSuperuser } from 'selectors/auth';
import { getClientById } from 'selectors/clients';
import { isClientEditLoading } from 'selectors/loading';
import { getClientEditFormInitialValues } from 'selectors/forms';
import PureRenderComponent from 'components/core/PureRenderComponent';
import ClientEditForm from './ClientEditForm';


class ClientEditContainer extends PureRenderComponent {
  
  handleUpdate = (values) => {
    this.props.updateRequest(
      this.props.form,
      this.props.clientId,
      values
    );
  }

  handleRemove = () => {
    this.props.removeRequest(this.props.client);
  } 

  render() {
    return (
      <ClientEditForm
        {...this.props}
        handleUpdate={this.handleUpdate}
        handleRemove={this.handleRemove} />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.clientId;
  const form = `${CLIENT_EDIT_FORM}/${id}`;
  return {
    form,
    client: getClientById(state, id),
    isLoading: isClientEditLoading(state, id),
    initialValues: getClientEditFormInitialValues(state, id),
    isSuperuser: isSuperuser(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    updateRequest,
    removeRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientEditContainer);
