import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { JOB_SPLIT_FORM } from 'misc/constants';
import { splitCreateRequest, splitDraftRequest } from 'actions/jobs/managers';
import { retrieveRequest } from 'actions/jobs/managers';
import { getJobSplitFormInitialValuesById } from 'selectors/forms';
import { isJobSplitLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobSplit from './presenter';


class JobSplitContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.retrieveRequest(this.props.id);
  }

  handleCreate = (values) => {
    this.props.splitCreateRequest(this.props.id, this.props.form, values);
  }

  handleSaveAsDraft = (values) => {
    this.props.splitDraftRequest(this.props.id, this.props.form, values);
  }

  render() {
    return (
      <JobSplit
        {...this.props}
        handleCreate={this.handleCreate}
        handleSaveAsDraft={this.handleSaveAsDraft} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const { id } = props.params;
  const form = `${JOB_SPLIT_FORM}/${id}`
  const formSelector = formValueSelector(form);
  const client = formSelector(state, 'client');
  return {
    id,
    form,
    client,
    isLoading: isJobSplitLoading(state, id),
    initialValues: getJobSplitFormInitialValuesById(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    retrieveRequest,
    splitCreateRequest,
    splitDraftRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobSplitContainer);