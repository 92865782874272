import React from 'react';
import { Table } from 'react-bootstrap';
import { Content, Box } from 'reactjs-admin-lte';

const UnsupportedOrderDetail = (props) => {
  const {
    order,
    isLoading,
    handleDownload
  } = props;
  return (
    <div>
      <Content.Body>
        <Box className="box-widget mb-10" loading={isLoading}>
          <Box.Body noPadding={true}>
            <Table striped bordered className="mb-0">
              <tbody>
                <tr>
                  <th width="25%">Created</th>
                  <td>{order.created}</td>
                </tr>
                <tr>
                  <th width="25%">Source Language</th>
                  <td>{order.sourceLanguage}</td>
                </tr>
                <tr>
                  <th width="25%">Target Language</th>
                  <td>{order.targetLanguage}</td>
                </tr>
                <tr>
                  <th width="25%">Customer</th>
                  <td>{order.customer.user.username}</td>
                </tr>
                <tr>
                  <th width="25%">Client</th>
                  <td>{order.customer.client.name}</td>
                </tr>
                <tr>
                  <th width="25%">AE</th>
                  <td>{order.customer.ae.username}</td>
                </tr>
                <tr>
                  <th width="25%">Document</th>
                  <td>
                    <a onClick={e => handleDownload(e, order.sourceFile)}>{order.sourceFile && order.sourceFile.name}</a>
                  </td>
                </tr>
                <tr>
                  <th width="25%">Instruction</th>
                  <td>{order.instruction}</td>
                </tr>
              </tbody>
            </Table>
          </Box.Body>
        </Box>
      </Content.Body>
    </div>
  );
}

export default UnsupportedOrderDetail;