import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { connectModal } from 'redux-modal';
import { Field, reduxForm } from 'redux-form';
import { Button, Modal, Form } from 'react-bootstrap';
import {
  TRANSLATION_MEMORY_SEARCH_SAVE_FORM,
  TRANSLATION_MEMORY_SEARCH_SAVE_MODAL,
 } from 'misc/constants';
 import { isMemorySearchSaveModalLoading } from 'selectors/loading';
import { searchCreateRequest } from 'actions/memories';
import * as validators from 'misc/validators';
import FontAwesome from 'react-fontawesome';
import Spinner from 'components/core/Spinner';
import PureRenderComponent from 'components/core/PureRenderComponent';
import CharField from 'components/core/CharField';


class TranslationMemorySearchSaveModal extends PureRenderComponent {

  hide = () => {
    this.props.handleHide()
    this.props.reset(TRANSLATION_MEMORY_SEARCH_SAVE_FORM);
  }

  handleSubmit = (values) => {
    this.props.searchCreateRequest(TRANSLATION_MEMORY_SEARCH_SAVE_FORM, {
      ...values,
      ...this.props.formValues
    })
  }

  render() {

    const {
      show,
      handleHide,
      handleSubmit,
      isLoading
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Translation Memory</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              name="name"
              label="Name"
              component={CharField.Labeled}
              validate={[validators.required]}
              isRequired={true} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pull-left"
              bsSize="sm"
              type="button"
              onClick={this.hide}>
              <FontAwesome name="close" className="mr-5" />Close
            </Button>
            <Button
              bsStyle="primary"
              bsSize="sm"
              type="submit"
              onClick={() => {}}>
              <FontAwesome name="paper-plane-o" className="mr-5" />Submit
            </Button>
          </Modal.Footer>
          <Spinner isLoading={isLoading} />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: isMemorySearchSaveModalLoading(state)
  }
}


const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    searchCreateRequest
  }, disptach)
}


TranslationMemorySearchSaveModal = reduxForm({
  form: TRANSLATION_MEMORY_SEARCH_SAVE_FORM
})(TranslationMemorySearchSaveModal);

TranslationMemorySearchSaveModal = connectModal({
  name: TRANSLATION_MEMORY_SEARCH_SAVE_MODAL
})(TranslationMemorySearchSaveModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationMemorySearchSaveModal);

