import React from "react";
import * as PropTypes from 'prop-types';
import './taFormField.css';

export const TaFormField = (props) => {

  const {
    label,
    description,
    disabled,
    isRequired,
    className,
    meta
  } = props;

  const baseClass = 'ta-form-field';
  const {invalid, touched, error} = (meta) ? meta : {};
  const hasError = (touched && invalid) ? 'error' : null;

  let classNames = [baseClass];
  if (invalid) {
    classNames.push(baseClass + '--invalid');
  }
  if (touched) {
    classNames.push(baseClass + '--touched');
  }
  if (hasError) {
    classNames.push(baseClass + '--error');
  }
  if (disabled) {
    classNames.push(baseClass + '--disabled');
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')}>
      {label && (
        <label className="ta-form-field__label">
          {label}
          {isRequired && (
            <span className="ta-form-field__label__required">*</span>
          )}
        </label>
      )}
      <div className="ta-form-field__input">{props.children}</div>
      {description && (
        <div className="ta-form-field__description">{description}</div>
      )}
    </div>
  );
};