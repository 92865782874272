import React from 'react';
import { Box } from 'reactjs-admin-lte';
import { Table } from 'react-bootstrap';


const UserSoftware = (props) => {

  const {
    isLoading,
    software,
    collapsed,
    hasHeader,
    handleChange
  } = props;

  const renderRow = () => {
    return software.map(row => {
      return (
        <React.Fragment key={row.type}>
          <tr className="active">
            <td><strong>{row.type}</strong></td>
            <td></td>
          </tr>
          {row.children.map(child => (
            <tr key={child.applicationId}>
              <td>{child.applicationName}</td>
              <td>
                <input
                  value={child.softwareId || ''}
                  checked={child.softwareId || ''}
                  onChange={(e) => handleChange(
                    child.softwareId,
                    child.applicationId,
                    e.target.checked
                  )}
                  type="checkbox" />
              </td>
            </tr>
          ))}
      </React.Fragment>
    )});
  }

  return (
    <Box
      className="box-widget mb-0"
      collapsed={collapsed}
      loading={isLoading}>
      { hasHeader &&
        <Box.Header border={true}>
          <Box.Title>Software</Box.Title>
          <Box.Tools>
            <Box.CollapsedToggleButton />
          </Box.Tools>
        </Box.Header>
      }
      <Box.Body noPadding={true}>
        <Table>
          <thead>
            <tr>
              <th>Application</th>
              <th width="20%">Use</th>
            </tr>
          </thead>
          <tbody>
            {renderRow()}
          </tbody>
        </Table>
      </Box.Body>
    </Box>
  )
}

UserSoftware.defaultProps = {
  hasHeader: false,
  collapsed: false
}

export default UserSoftware;