import axios from 'axios';

const WordListApi = {

  list: (params) => {
    return axios.get(`wordlists/`, {params});
  },

  batchDelete: (values) => {
    return axios.post(`wordlists/batch-delete/`, values);
  },

  upload: (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('file', values.file);
    formData.append('client', values.client);
    formData.append('notes', values.notes || '');
    return axios.post(`wordlists/upload/`, formData)
  },
  exportExcel: (params, onDownloadProgress) => {
    const { id, colKeys } = params;
    return axios({
      url: `wordlists/${id}/export/`,
      method: 'POST',
      data: {
        keys: colKeys
      },
      responseType: 'blob',
      onDownloadProgress
    });
  },
  retrieveList: (listId, config) => {
    return axios.get(`wordlists/${listId}/`, config);
  },
  updateList: (listId, config) => {
    return axios.put(`wordlists/${listId}/`, config);
  },
  clearList: (listId) => {
    return axios.post(`wordlists/${listId}/clear/`);
  },
  listItems: (listId, page, filter) => {
    let url = (listId) ? `wordlists/${listId}/items/` : `wordlist-items/`;
    let queryString = [];

    if (page === 'all') {
      url += 'all/'
    } else if (page) {
      queryString.push('page='+page)
    }
    if (filter && filter.string) {
      queryString.push('term='+filter.string)
    }
    if (filter && filter.pendingOnly) {
      queryString.push('is_pending='+filter.pendingOnly)
    }
    if(queryString.length) {
      url += '?';
      url += queryString.join('&')
    }

    return axios.get(url)

  },
  retrieveItem: (itemId, config) => {
    return axios.get(`wordlist-items/${itemId}/`, config)
  },
  importItems: (listId, values) => {
    const formData = new FormData();
    formData.append('file', values.file);
    return axios.post(`wordlists/${listId}/import/`, formData)
  },
  addItem: (item) => {
    return axios.post(`wordlist-items/`, item)
  },
  updateItem: (id, item) => {
    return axios.patch(`wordlist-items/${id}/`, item);
  },
  deleteItem: (id) => {
    return axios.delete(`wordlist-items/${id}/`);
  },

  batchSaveItems: (items, forceMerge) => {
    return axios.post(`wordlist-items/batch-save/`, {items: items, force_merge: !!forceMerge})
  },
  batchDeleteItems: (itemIds) => {
    return axios.post(`wordlist-items/batch-delete/`, {items: itemIds})
  },
  autocomplete: (params) => {
    return axios.get(`wordlists/autocomplete/`, { params });
  },
}

export default WordListApi;