import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Content } from 'reactjs-admin-lte';
import ClientNgUser from 'components/clients/ClientUserPreference/ClientNgUser';
import ClientPreferableUser from 'components/clients/ClientUserPreference/ClientPreferableUser';
import ClientCommonReference from 'components/clients/ClientCommonReference';
import ClientEdit from 'components/clients/ClientEdit';
import ClientWebsite from 'components/clients/ClientWebsite';

const ClientDetail = (props) => {

  const {
    id,
    client
  } = props;

  return (
    <div>
      <Content.Header>
        <h1>
          {client.name}
          <small>{client.fullNameJp}</small>
        </h1>
      </Content.Header>
      <Content.Body>
        <Row>
          <Col lg={6}>
            <ClientEdit clientId={id} />
          </Col>
          <Col lg={6}>
            <ClientCommonReference
              clientId={id}
              uploadable={true}
              updatable={true}
              removable={true} />
            <ClientPreferableUser clientId={id} />
            <ClientNgUser clientId={id} />
            <ClientWebsite clientId={id} />
          </Col>
        </Row>
      </Content.Body>
    </div>
  );
}

export default ClientDetail;
