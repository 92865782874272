import { delay } from 'redux-saga';
import { takeLatest, call, put, fork, select, all } from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import { show, hide } from 'redux-modal';
import { reset, arrayPush, arrayRemove } from 'redux-form';
import API from 'services/DocumentApi';
import ManagerApi from 'services/jobs/ManagerApi';
import * as schemas from 'schemas/documents';
import * as actions from 'actions/documents';
import * as actionTypes from 'actionTypes/documents';
import * as entityActions from 'actions/entities';
import * as listActions from 'actions/lists';
import * as coreSagas from 'sagas/core';
import * as constants from 'misc/constants';
import { getAssignmentValueById, getJobById } from 'selectors/jobs'
import { getAssignmentProjectsById } from 'selectors/lists';
import { setLoading } from 'actions/loading';
import { setDownloadProgress } from 'actions/core';
import { quoteSourcefileListRequest } from 'actions/jobs/managers';
import { saveFile } from 'misc/utils';


function* _download(action, api, successFn, failureFn) {
  yield put(show(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  const { file } = action.payload;
  try {
    const [downloadPromise, chan] = yield call(
      coreSagas.createDownloader,
      api,
      file
    );
    yield fork(coreSagas.watchDownloadProgress, chan);
    const response = yield call(() => downloadPromise);
    yield call(saveFile, response.data, file.name, file.contentType);
    yield put(successFn());
    yield delay(1000);
    yield put(hide(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  } catch (error) {
    console.error(error);
    yield put(failureFn());
  } finally {
    yield put(setDownloadProgress(0));
  }
}


function* _remove(api, listName, listKey, entityKey, file, successFn, failureFn, successMessage) {
  const message = `Are you sure to delete ${file.name} ?`
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      yield call(api, file.id);
      yield put(listActions.removeNestedItem(listName, listKey, file.id));
      yield put(entityActions.remove(entityKey, file.id));
      yield put(successFn());
      yield put(hide(constants.CONFIRM_MODAL));
      yield fork(coreSagas.successNotification, successMessage);
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(failureFn(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

function* sourcefileUpload(action) {
  yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, true));
  const { form, files } = action.payload;
  try {
    const response = yield call(API.sourcefileUpload, files);
    const normalized = normalize(camelizeKeys(response), [schemas.sourcefileSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield all(normalized.result.map(res => put(arrayPush(form, 'sourcefiles', res))));
    yield put(hide(constants.MULTIPLE_FILE_UPLOAD_MODAL));
    // yield put(reset(form));
    yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, false));
  } catch (error) {
    yield put(actions.sourcefileUploadFailure(error));
    yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, false));
  }
}

function* sourcefileRemove(action) {
  const { form, file, index } = action.payload;
  const message = `Are you sure to delete ${file.name} ?`
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true));
      yield call(API.sourcefileRemove, file.id);
      yield put(arrayRemove(form, 'sourcefiles', index));
      yield put(entityActions.remove('sourcefiles', file.id));
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.sourcefileRemoveFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

// Job Source File Upload ======================================================
function* jobSourcefileUpload(action) {
  yield put(setLoading(constants.SINGLE_FILE_UPLOAD_MODAL, true));
  const { jobId, values: { name, file } } = action.payload;
  try {
    const response = yield call(API.jobSourcefileUpload, jobId, file, name);
    const normalized = normalize(camelizeKeys(response.data), schemas.sourcefileSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addNestedItem(constants.JOB_SOURCEFILE, jobId, normalized.result));
    yield put(hide(constants.SINGLE_FILE_UPLOAD_MODAL));
    yield put(reset(constants.SINGLE_FILE_UPLOAD_FORM));
    yield fork(coreSagas.successNotification, 'Source File uploaded successfully.');
    yield put(setLoading(constants.SINGLE_FILE_UPLOAD_MODAL, false));
  } catch (error) {
    yield put(actions.jobSourcefileUploadFailure(error));
    yield put(setLoading(constants.SINGLE_FILE_UPLOAD_MODAL, false));
  }
}

// Job Source File Remove ======================================================
function* jobSourcefileRemove(action) {
  const { jobId, file } = action.payload;
  yield fork(
    _remove,
    API.sourcefileRemove,
    constants.JOB_SOURCEFILE,
    jobId,
    'sourcefiles',
    file,
    actions.jobSourcefileRemoveSuccess,
    actions.jobSourcefileRemoveFailure,
    'Source file deleted successfully.'
  );
}

// Sourcefile download =========================================================
function* sourcefileDownload(action) {
  yield fork(
    _download,
    action,
    API.sourcefileDownload,
    actions.sourcefileDownloadSuccess,
    actions.sourcefileDownloadFailure
  )
}

// Reference Upload ============================================================
function* referenceUpload(action) {
  yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, true));
  const { form, files } = action.payload;
  try {
    const response = yield call(API.referenceUpload, files);
    const normalized = normalize(camelizeKeys(response), [schemas.referenceSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield all(normalized.result.map(res => put(arrayPush(form, 'references', res))));
    yield put(hide(constants.MULTIPLE_FILE_UPLOAD_MODAL));
    yield put(hide(form));
    yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, false));
  } catch (error) {
    yield put(actions.referenceUploadFailure(error));
    yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, false));
  }
}

// Reference Remove ============================================================
function* referenceRemove(action) {
  const { form, file, index } = action.payload;
  const message = `Are you sure to delete ${file.name} ?`
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true));
      yield call(API.referenceRemove, file.id);
      yield put(arrayRemove(form, 'references', index));
      yield put(entityActions.remove('references', file.id));
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.referenceRemoveFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

// Job References Upload =======================================================
function* jobReferenceUpload(action) {
  yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, true));
  const { jobId, files } = action.payload;
  try {
    const response = yield call(API.jobReferenceUpload, jobId, files);
    const normalized = normalize(camelizeKeys(response), [schemas.referenceSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addNestedItems(constants.JOB_REFERENCE, jobId, normalized.result));
    yield put(hide(constants.MULTIPLE_FILE_UPLOAD_MODAL));
    yield fork(coreSagas.successNotification, 'Job Specific Reference uploaded successfully.');
    yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, false));
  } catch (error) {
    yield put(actions.jobReferenceUploadFailure(error));
    yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, false));
  }
}

// Job Reference Remove ========================================================
function* jobReferenceRemove(action) {
  const { jobId, file } = action.payload;
  yield fork(
    _remove,
    API.referenceRemove,
    constants.JOB_REFERENCE,
    jobId,
    'references',
    file,
    actions.jobReferenceRemoveSuccess,
    actions.jobReferenceRemoveFailure,
    'Reference deleted successfully.'
  );
}

// Reference download ==========================================================
function* referenceDownload(action) {
  yield fork(
    _download,
    action,
    API.referenceDownload,
    actions.referenceDownloadSuccess,
    actions.referenceDownloadFailure
  )
}

// Job Common Reference Upload ================================================
function* jobCommonReferenceUpload(action) {
  yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, true));
  const { jobGroup, files } = action.payload;
  try {
    const response = yield call(API.jobCommonReferenceUpload, jobGroup, files);
    const normalized = normalize(camelizeKeys(response), [schemas.jobCommonReferenceSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addNestedItems(constants.JOB_COMMON_REFERENCE, jobGroup, normalized.result));
    yield put(actions.jobCommonReferenceUploadSuccess());
    yield put(hide(constants.MULTIPLE_FILE_UPLOAD_MODAL));
    yield fork(coreSagas.successNotification, 'Job Common Reference uploaded successfully.');
    yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, false));
  } catch (error) {
    yield put(actions.jobCommonReferenceUploadFailure(error));
    yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, false));
  }
}

// Job Common Reference Upload ================================================
function* jobCommonReferenceRemove(action) {
  const { jobGroup, file } = action.payload;
  yield fork(
    _remove,
    API.jobCommonReferenceRemove,
    constants.JOB_COMMON_REFERENCE,
    jobGroup,
    'jobCommonReferences',
    file,
    actions.jobCommonReferenceRemoveSuccess,
    actions.jobCommonReferenceRemoveFailure,
    'Common reference deleted successfully.'
  );
}

// Job Common Reference download ===============================================
function* jobCommonReferenceDownload(action) {
  yield fork(
    _download,
    action,
    API.jobCommonReferenceDownload,
    actions.jobCommonReferenceDownloadSuccess,
    actions.jobCommonReferenceDownloadFailure
  )
}

// Client Common References Upload =============================================
function* clientCommonReferenceUpload(action) {
  yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, true));
  const { clientId, files, jobId } = action.payload;
  try {
    const response = yield call(API.clientCommonReferenceUpload, clientId, files, jobId);
    const normalized = normalize(camelizeKeys(response), [schemas.clientReferenceSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addNestedItems(constants.CLIENT_COMMON_REFERENCE, clientId, normalized.result));
    yield put(hide(constants.MULTIPLE_FILE_UPLOAD_MODAL));
    yield fork(coreSagas.successNotification, 'Client common referece uploaded successfully.');
    yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, false));
  } catch (error) {
    yield put(actions.clientCommonReferenceUploadFailure(error));
    yield put(setLoading(constants.MULTIPLE_FILE_UPLOAD_MODAL, false));
  }
}

// Client Common Reference Update ==============================================
function* clientCommonReferenceUpdate(action) {
  yield put(setLoading(constants.FILE_UPDATE_MODAL, true));
  const { id, values: { name, file } } = action.payload;
  try {
    const response = yield call(API.clientCommonReferenceUpdate, id, name, file);
    const normalized = normalize(camelizeKeys(response.data), schemas.clientReferenceSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(hide(constants.FILE_UPDATE_MODAL));
    yield fork(coreSagas.successNotification, 'Client common referece updated successfully.');
    yield put(setLoading(constants.FILE_UPDATE_MODAL, false));
  } catch (error) {
    yield put(actions.clientCommonReferenceUpdateFailure(error));
    yield put(setLoading(constants.FILE_UPDATE_MODAL, false));
  }
}

function* clientCommonReferenceUse(action) {
  const { id, values } = action.payload;
  try {
    const response = yield call(API.clientCommonReferenceUse, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.clientReferenceSchema);
    const data = normalized.entities['clientReferences'][id];
    yield put(entityActions.sync('clientReferences', id, data));
    const { use } = values;
    if (use) {
      yield fork(coreSagas.successNotification, `${data.name} is visible to employees.`);
    } else {
      yield fork(coreSagas.successNotification, `${data.name} is hidden from employees.`);
    }
  } catch (error) {
    yield put(actions.clientCommonReferenceUseFailure(error));
  }
}

// Client Common Reference Remove ==============================================
function* clientCommonReferenceRemove(action) {
  const { clientId, file } = action.payload;
  yield fork(
    _remove,
    API.clientCommonReferenceRemove,
    constants.CLIENT_COMMON_REFERENCE,
    clientId,
    'commonReferences',
    file,
    actions.clientCommonReferenceRemoveSuccess,
    actions.clientCommonReferenceRemoveFailure,
    'Client common reference deleted successfully.'
  );
}

// Client Common Reference download ============================================
function* clientCommonReferenceDownload(action) {
  yield fork(
    _download,
    action,
    API.clientCommonReferenceDownload,
    actions.clientCommonReferenceDownloadSuccess,
    actions.clientCommonReferenceDownloadFailure
  )
}

// Job Common Reference Upload ================================================
function* assignmentProjectUpload(action) {
  yield put(setLoading(constants.EMPLOYEE_ASSIGNMENT_PROJECT_UPLOAD_MODAL, true));
  const { assignmentId, files, proecessRequired } = action.payload;
  try {
    if (files.length > 0) {
      const response = yield call(API.assignmentProjectUpload, assignmentId, files);
      const normalized = normalize(camelizeKeys(response), [schemas.projectSchema]);
      yield put(entityActions.merge(normalized.entities));
      yield put(listActions.addNestedItems(constants.ASSIGNMENT_PROJECT, assignmentId, normalized.result));
      yield put(hide(constants.EMPLOYEE_ASSIGNMENT_PROJECT_UPLOAD_MODAL));
      yield put(setLoading(constants.EMPLOYEE_ASSIGNMENT_PROJECT_UPLOAD_MODAL, false));
    } else {
      yield put(hide(constants.EMPLOYEE_ASSIGNMENT_PROJECT_UPLOAD_MODAL));
      yield put(setLoading(constants.EMPLOYEE_ASSIGNMENT_PROJECT_UPLOAD_MODAL, false));
    }
    if (proecessRequired) {
      const assignment = yield select(getAssignmentValueById, assignmentId);
      const job = yield select(getJobById, assignment.job);
      const projects = yield select(getAssignmentProjectsById, assignmentId);
      const message = `Are you sure to finish ${job.number} ?`;
      yield put(show(constants.EMPLOYEE_JOB_FINISH_MODAL, {
        assignment,
        projects,
        message,
      }));
    }
  } catch (error) {
    yield put(actions.assignmentProjectUploadFailure(error));
    yield put(setLoading(constants.EMPLOYEE_ASSIGNMENT_PROJECT_UPLOAD_MODAL, false));
  }
}

// Project Remove ==============================================================
function* assignmentProjectRemove(action) {
  const { assignmentId, file } = action.payload;
  yield fork(
    _remove,
    API.projectRemove,
    constants.ASSIGNMENT_PROJECT,
    assignmentId,
    'projects',
    file,
    actions.assignmentProjectRemoveSuccess,
    actions.assignmentProjectRemoveFailure,
    'Completed file deleted successfully.'
  );
}

// Project download ============================================================
function* projectDownload(action) {
  yield fork(
    _download,
    action,
    API.projectDownload,
    actions.projectDownloadSuccess,
    actions.projectDownloadFailure
  )
}

// Target File Upload =========================================================
function* targetfileUpload(action) {
  yield put(setLoading(constants.SINGLE_FILE_UPLOAD_MODAL, true));
  const { jobId, sourceFileId, values: { name, file } } = action.payload;
  try {
    yield call(API.targetfileUpload, sourceFileId, file, name);
    yield put(hide(constants.SINGLE_FILE_UPLOAD_MODAL));
    yield put(reset(constants.SINGLE_FILE_UPLOAD_FORM));
    yield put(setLoading(constants.SINGLE_FILE_UPLOAD_MODAL, false));
    yield put(quoteSourcefileListRequest(jobId));
  } catch (error) {
    yield put(actions.targetfileUploadFailure(error));
    yield put(setLoading(constants.SINGLE_FILE_UPLOAD_MODAL, false));
  }
}

function* targetfileDownload(action) {
  yield fork(
    _download,
    action,
    API.targetfileDownload,
    actions.targetfileDownloadSuccess,
    actions.targetfileDownloadFailure
  )
}

function* targetfileRemove(action) {
  const { jobId, quoteSourcefileId, file } = action.payload;
  const loadingKey = `${constants.QUOTE_SOURCEFILE}/${jobId}`;
  const message = `Are you sure to delete ${file.name} ?`
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true));
      yield call(API.targetfileRemove, file.id);
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
      yield put(setLoading(loadingKey, true));
      const response = yield call(ManagerApi.quoteSourcefileList, jobId);
      const normalized = normalize(camelizeKeys(response.data), [schemas.quoteSourcefileSchema]);
      console.log(normalized)
      const data = normalized.entities.quoteSourcefiles[quoteSourcefileId];
      yield put(entityActions.sync("quoteSourcefiles", quoteSourcefileId, data));
      yield put(setLoading(loadingKey, false));
    } catch (error) {
      yield put(actions.targetfileRemoveFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
      yield put(setLoading(loadingKey, false));
    }
  }
}

function* quoteSourcefileDownload(action) {
  yield fork(
    _download,
    action,
    API.quoteSourcefileDownload,
    actions.quoteSourcefileDownloadSuccess,
    actions.quoteSourcefileDownloadFailure
  )
}

function* unsupportedFileDownload(action) {
  console.log(action);
  console.log(API.unsupportedFileDownload);
  yield fork(
    _download,
    action,
    API.unsupportedFileDownload,
    actions.unsupportedFileDownloadSuccess,
    actions.unsupportedFileDownloadFailure
  )
}

function* watchSourcefileUpload() {
  yield takeLatest(actionTypes.SOURCEFILE_UPLOAD.REQUEST, sourcefileUpload);
}
function* watchSourcefileRemove() {
  yield takeLatest(actionTypes.SOURCEFILE_REMOVE.REQUEST, sourcefileRemove);
}
function* watchSourcefileDownload() {
  yield takeLatest(actionTypes.SOURCEFILE_DOWNLOAD.REQUEST, sourcefileDownload);
}

function* watchJobSourcefileUpload() {
  yield takeLatest(actionTypes.JOB_SOURCEFILE_UPLOAD.REQUEST, jobSourcefileUpload)
}

function* watchJobSourcefileRemove() {
  yield takeLatest(actionTypes.JOB_SOURCEFILE_REMOVE.REQUEST, jobSourcefileRemove)
}

function* watchReferenceUpload() {
  yield takeLatest(actionTypes.REFERENCE_UPLOAD.REQUEST, referenceUpload);
}
function* watchReferenceRemove() {
  yield takeLatest(actionTypes.REFERENCE_REMOVE.REQUEST, referenceRemove);
}

function* watchReferenceDownload() {
  yield takeLatest(actionTypes.REFERENCE_DOWNLOAD.REQUEST, referenceDownload);
}

function* watchJobReferenceUpload() {
  yield takeLatest(actionTypes.JOB_REFERENCE_UPLOAD.REQUEST, jobReferenceUpload);
}
function* watchJobReferenceRemove() {
  yield takeLatest(actionTypes.JOB_REFERENCE_REMOVE.REQUEST, jobReferenceRemove);
}


function* watchJobCommonReferenceDownload() {
  yield takeLatest(actionTypes.JOB_COMMON_REFERENCE_DOWNLOAD.REQUEST, jobCommonReferenceDownload);
}
function* watchJobCommonReferenceUpload() {
  yield takeLatest(actionTypes.JOB_COMMON_REFERENCE_UPLOAD.REQUEST, jobCommonReferenceUpload);
}
function* watchJobCommonReferenceRemove() {
  yield takeLatest(actionTypes.JOB_COMMON_REFERENCE_REMOVE.REQUEST, jobCommonReferenceRemove);
}


function* watchClientCommonReferenceDownload() {
  yield takeLatest(actionTypes.CLIENT_COMMON_REFERENCE_DOWNLOAD.REQUEST, clientCommonReferenceDownload);
}
function* watchClientCommonReferenceUpload() {
  yield takeLatest(actionTypes.CLIENT_COMMON_REFERENCE_UPLOAD.REQUEST, clientCommonReferenceUpload);
}
function* watchClientCommonReferenceUpdate() {
  yield takeLatest(actionTypes.CLIENT_COMMON_REFERENCE_UPDATE.REQUEST, clientCommonReferenceUpdate);
}
function* watchClientCommonReferenceUse() {
  yield takeLatest(actionTypes.CLIENT_COMMON_REFERENCE_USE.REQUEST, clientCommonReferenceUse);
}
function* watchClientCommonReferenceRemove() {
  yield takeLatest(actionTypes.CLIENT_COMMON_REFERENCE_REMOVE.REQUEST, clientCommonReferenceRemove);
}


function* watchAssignmentProjectUpload() {
  yield takeLatest(actionTypes.ASSIGNMENT_PROJECT_UPLOAD.REQUEST, assignmentProjectUpload);
}
function* watchAssignmentProjectRemove() {
  yield takeLatest(actionTypes.ASSIGNMENT_PROJECT_REMOVE.REQUEST, assignmentProjectRemove);
}
function* watchProjectDownload() {
  yield takeLatest(actionTypes.PROJECT_DOWNLOAD.REQUEST, projectDownload);
}

function* watchTargetfileUpload() {
  yield takeLatest(actionTypes.TARGETFILE_UPLOAD.REQUEST, targetfileUpload);
}
function* watchTargetfileDownload() {
  yield takeLatest(actionTypes.TARGETFILE_DOWNLOAD.REQUEST, targetfileDownload);
}
function* watchTargetfileRemove() {
  yield takeLatest(actionTypes.TARGETFILE_REMOVE.REQUEST, targetfileRemove);
}
function* watchQuoteSourcefileDownload() {
  yield takeLatest(actionTypes.QUOTE_SOURCEFILE_DOWNLOAD.REQUEST, quoteSourcefileDownload);
}

function* watchUnsupportedFileDownload() {
  yield takeLatest(actionTypes.UNSUPPORTED_FILE_DOWNLOAD.REQUEST, unsupportedFileDownload);
}




export {
  watchJobSourcefileUpload,
  watchJobSourcefileRemove,
  watchSourcefileUpload,
  watchSourcefileRemove,
  watchSourcefileDownload,

  watchReferenceUpload,
  watchReferenceRemove,
  watchJobReferenceUpload,
  watchJobReferenceRemove,
  watchReferenceDownload,

  watchJobCommonReferenceUpload,
  watchJobCommonReferenceRemove,
  watchJobCommonReferenceDownload,

  watchClientCommonReferenceUpload,
  watchClientCommonReferenceUpdate,
  watchClientCommonReferenceUse,
  watchClientCommonReferenceRemove,
  watchClientCommonReferenceDownload,

  watchAssignmentProjectUpload,
  watchAssignmentProjectRemove, 
  watchProjectDownload,

  watchTargetfileUpload,
  watchTargetfileDownload,
  watchTargetfileRemove,
  watchQuoteSourcefileDownload,
  watchUnsupportedFileDownload,
}