import * as types from 'actionTypes/documents';

const createDownloadAction = (type) => ({
  requestFn: (file) => ({ type: type.REQUEST, payload: { file } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

// Job Sourcefile Upload =======================================================
export const sourcefileUploadRequest = (form, files) => ({
  type: types.SOURCEFILE_UPLOAD.REQUEST,
    payload: { form, files }
});
export const sourcefileUploadSuccess = () => ({
  type: types.SOURCEFILE_UPLOAD.SUCCESS
});
export const sourcefileUploadFailure = (error) => ({
  type: types.SOURCEFILE_UPLOAD.FAILURE,
  payload: { error }
});

// Job Sourcefile Remove =======================================================
export const sourcefileRemoveRequest = (form, file, index) => ({
  type: types.SOURCEFILE_REMOVE.REQUEST,
  payload: { form, file, index }
});
export const sourcefileRemoveSuccess = () => ({
  type: types.SOURCEFILE_REMOVE.SUCCESS
});
export const sourcefileRemoveFailure = (error) => ({
  type: types.SOURCEFILE_REMOVE.FAILURE,
  payload: { error }
});

// Job Sourcefile Upload =======================================================
export const jobSourcefileUploadRequest = (jobId, values) => ({
  type: types.JOB_SOURCEFILE_UPLOAD.REQUEST,
    payload: { jobId, values }
});
export const jobSourcefileUploadSuccess = () => ({
  type: types.JOB_SOURCEFILE_UPLOAD.SUCCESS
});
export const jobSourcefileUploadFailure = (error) => ({
  type: types.JOB_SOURCEFILE_UPLOAD.FAILURE,
  payload: { error }
});

// Job Sourcefile Remove =======================================================
export const jobSourcefileRemoveRequest = (jobId, file) => ({
  type: types.JOB_SOURCEFILE_REMOVE.REQUEST,
  payload: { jobId, file }
});
export const jobSourcefileRemoveSuccess = () => ({
  type: types.JOB_SOURCEFILE_REMOVE.SUCCESS
});
export const jobSourcefileRemoveFailure = (error) => ({
  type: types.JOB_SOURCEFILE_REMOVE.FAILURE,
  payload: { error }
});

// Sourcefile Download =========================================================
const sourcefileDownload = createDownloadAction(types.SOURCEFILE_DOWNLOAD);
export const sourcefileDownloadRequest = sourcefileDownload.requestFn;
export const sourcefileDownloadSuccess = sourcefileDownload.successFn;
export const sourcefileDownloadFailure = sourcefileDownload.failureFn;



// Job Sourcefile Upload =======================================================
export const referenceUploadRequest = (form, files) => ({
  type: types.REFERENCE_UPLOAD.REQUEST,
    payload: { form, files }
});
export const referenceUploadSuccess = () => ({
  type: types.REFERENCE_UPLOAD.SUCCESS
});
export const referenceUploadFailure = (error) => ({
  type: types.REFERENCE_UPLOAD.FAILURE,
  payload: { error }
});

// Job Sourcefile Remove =======================================================
export const referenceRemoveRequest = (form, file, index) => ({
  type: types.REFERENCE_REMOVE.REQUEST,
  payload: { form, file, index }
});
export const referenceRemoveSuccess = () => ({
  type: types.REFERENCE_REMOVE.SUCCESS
});
export const referenceRemoveFailure = (error) => ({
  type: types.REFERENCE_REMOVE.FAILURE,
  payload: { error }
});

// Job Reference Upload ========================================================
export const jobReferenceUploadRequest = (jobId, files) => ({
  type: types.JOB_REFERENCE_UPLOAD.REQUEST,
  payload: { jobId, files }
});
export const jobReferenceUploadSuccess = () => ({
  type: types.JOB_REFERENCE_UPLOAD.SUCCESS
});
export const jobReferenceUploadFailure = (error) => ({
  type: types.JOB_REFERENCE_UPLOAD.FAILURE,
  payload: { error }
});

// Job Reference Remove ========================================================
export const jobReferenceRemoveRequest = (jobId, file) => ({
  type: types.JOB_REFERENCE_REMOVE.REQUEST,
  payload: { jobId, file }
});
export const jobReferenceRemoveSuccess = () => ({
  type: types.JOB_REFERENCE_REMOVE.SUCCESS
});
export const jobReferenceRemoveFailure = (error) => ({
  type: types.JOB_REFERENCE_REMOVE.FAILURE,
  payload: { error }
});

// Reference Download ==========================================================
const referenceDownload = createDownloadAction(types.REFERENCE_DOWNLOAD);
export const referenceDownloadRequest = referenceDownload.requestFn;
export const referenceDownloadSuccess = referenceDownload.successFn;
export const referenceDownloadFailure = referenceDownload.failureFn;


// Job Common Reference Upload =================================================
export const jobCommonReferenceUploadRequest = (jobGroup, files) => ({
  type: types.JOB_COMMON_REFERENCE_UPLOAD.REQUEST,
  payload: { jobGroup, files }
});
export const jobCommonReferenceUploadSuccess = () => ({
  type: types.JOB_COMMON_REFERENCE_UPLOAD.SUCCESS
});
export const jobCommonReferenceUploadFailure = (error) => ({
  type: types.JOB_COMMON_REFERENCE_UPLOAD.FAILURE,
  payload: { error }
});

// Job Common Reference Remove =================================================
export const jobCommonReferenceRemoveRequest = (jobGroup, file) => ({
  type: types.JOB_COMMON_REFERENCE_REMOVE.REQUEST,
  payload: { jobGroup, file }
});
export const jobCommonReferenceRemoveSuccess = () => ({
  type: types.JOB_COMMON_REFERENCE_REMOVE.SUCCESS
});
export const jobCommonReferenceRemoveFailure = (error) => ({
  type: types.JOB_COMMON_REFERENCE_REMOVE.FAILURE,
  payload: { error }
});

// Job Common Reference Download ===============================================
const jobCommonReferenceDownload = createDownloadAction(types.JOB_COMMON_REFERENCE_DOWNLOAD);
export const jobCommonReferenceDownloadRequest = jobCommonReferenceDownload.requestFn;
export const jobCommonReferenceDownloadSuccess = jobCommonReferenceDownload.successFn;
export const jobCommonReferenceDownloadFailure = jobCommonReferenceDownload.failureFn;


// Client Common Reference Upload ==============================================
export const clientCommonReferenceUploadRequest = (clientId, files, jobId) => ({
  type: types.CLIENT_COMMON_REFERENCE_UPLOAD.REQUEST,
  payload: { clientId, files, jobId }
});
export const clientCommonReferenceUploadSuccess = () => ({
  type: types.CLIENT_COMMON_REFERENCE_UPLOAD.SUCCESS
});
export const clientCommonReferenceUploadFailure = (error) => ({
  type: types.CLIENT_COMMON_REFERENCE_UPLOAD.FAILURE,
  payload: { error }
});

// Client Common Reference Upadte ==============================================
export const clientCommonReferenceUpdateRequest = (id, values) => ({
  type: types.CLIENT_COMMON_REFERENCE_UPDATE.REQUEST,
  payload: { id, values }
})
export const clientCommonReferenceUpdateSuccess = () => ({
  type: types.CLIENT_COMMON_REFERENCE_UPDATE.SUCCESS
})
export const clientCommonReferenceUpdateFailure = (error) => ({
  type: types.CLIENT_COMMON_REFERENCE_UPDATE.FAILURE,
  payload: { error }
})

// Client Common Reference Use =================================================
export const clientCommonReferenceUseRequest = (id, values) => ({
  type: types.CLIENT_COMMON_REFERENCE_USE.REQUEST,
  payload: { id, values }
})
export const clientCommonReferenceUseSuccess = () => ({
  type: types.CLIENT_COMMON_REFERENCE_USE.SUCCESS
})
export const clientCommonReferenceUseFailure = (error) => ({
  type: types.CLIENT_COMMON_REFERENCE_USE.FAILURE,
  payload: { error }
})

// Client Common Reference Remove ==============================================
export const clientCommonReferenceRemoveRequest = (clientId, file) => ({
  type: types.CLIENT_COMMON_REFERENCE_REMOVE.REQUEST,
  payload: { clientId, file }
});
export const clientCommonReferenceRemoveSuccess = () => ({
  type: types.CLIENT_COMMON_REFERENCE_REMOVE.SUCCESS
});
export const clientCommonReferenceRemoveFailure = (error) => ({
  type: types.CLIENT_COMMON_REFERENCE_REMOVE.FAILURE,
  payload: { error }
});

// Client Common Reference Download ============================================
const clientCommonReferenceDownload = createDownloadAction(types.CLIENT_COMMON_REFERENCE_DOWNLOAD);
export const clientCommonReferenceDownloadRequest = clientCommonReferenceDownload.requestFn;
export const clientCommonReferenceDownloadSuccess = clientCommonReferenceDownload.successFn;
export const clientCommonReferenceDownloadFailure = clientCommonReferenceDownload.failureFn;

// Assignment Project Upload ===================================================
export const assignmentProjectUploadRequest = (assignmentId, files, proecessRequired = false) => ({
  type: types.ASSIGNMENT_PROJECT_UPLOAD.REQUEST,
  payload: { assignmentId, files, proecessRequired }
});
export const assignmentProjectUploadSuccess = () => ({
  type: types.ASSIGNMENT_PROJECT_UPLOAD.SUCCESS
});
export const assignmentProjectUploadFailure = (error) => ({
  type: types.ASSIGNMENT_PROJECT_UPLOAD.FAILURE,
  payload: { error }
});

// Assignment Project Remove ==========================================================
export const assignmentProjectRemoveRequest = (assignmentId, file) => ({
  type: types.ASSIGNMENT_PROJECT_REMOVE.REQUEST,
  payload: { assignmentId, file }
});
export const assignmentProjectRemoveSuccess = () => ({
  type: types.ASSIGNMENT_PROJECT_REMOVE.SUCCESS
});
export const assignmentProjectRemoveFailure = (error) => ({
  type: types.ASSIGNMENT_PROJECT_REMOVE.FAILURE,
  payload: { error }
});

// Project Download ============================================================
const projectDownload = createDownloadAction(types.PROJECT_DOWNLOAD);
export const projectDownloadRequest = projectDownload.requestFn;
export const projectDownloadSuccess = projectDownload.successFn;
export const projectDownloadFailure = projectDownload.failureFn;


// Targetfile Upload ===========================================================
export const targetfileUploadRequest = (jobId, sourceFileId, values) => ({
  type: types.TARGETFILE_UPLOAD.REQUEST,
    payload: { jobId, sourceFileId, values }
});
export const targetfileUploadSuccess = () => ({
  type: types.TARGETFILE_UPLOAD.SUCCESS
});
export const targetfileUploadFailure = (error) => ({
  type: types.TARGETFILE_UPLOAD.FAILURE,
  payload: { error }
});

export const targetfileDownloadRequest = (file) => ({
  type: types.TARGETFILE_DOWNLOAD.REQUEST,
    payload: { file }
});
export const targetfileDownloadSuccess = () => ({
  type: types.TARGETFILE_DOWNLOAD.SUCCESS
});
export const targetfileDownloadFailure = (error) => ({
  type: types.TARGETFILE_DOWNLOAD.FAILURE,
  payload: { error }
});

export const targetfileRemoveRequest = (jobId, quoteSourcefileId, file) => ({
  type: types.TARGETFILE_REMOVE.REQUEST,
    payload: { jobId, quoteSourcefileId, file }
});
export const targetfileRemoveSuccess = () => ({
  type: types.TARGETFILE_REMOVE.SUCCESS
});
export const targetfileRemoveFailure = (error) => ({
  type: types.TARGETFILE_REMOVE.FAILURE,
  payload: { error }
});

export const quoteSourcefileDownloadRequest = (file) => ({
  type: types.QUOTE_SOURCEFILE_DOWNLOAD.REQUEST,
  payload: { file }
});
export const quoteSourcefileDownloadSuccess = () => ({
  type: types.QUOTE_SOURCEFILE_DOWNLOAD.SUCCESS
});
export const quoteSourcefileDownloadFailure = (error) => ({
  type: types.QUOTE_SOURCEFILE_DOWNLOAD.FAILURE,
  payload: { error }
});


export const unsupportedFileDownloadRequest = (file) => ({
  type: types.UNSUPPORTED_FILE_DOWNLOAD.REQUEST,
  payload: { file }
});
export const unsupportedFileDownloadSuccess = () => ({
  type: types.UNSUPPORTED_FILE_DOWNLOAD.SUCCESS
});
export const unsupportedFileDownloadFailure = (error) => ({
  type: types.UNSUPPORTED_FILE_DOWNLOAD.FAILURE,
  payload: { error }
});