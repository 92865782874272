import { schema } from 'normalizr';

export const serviceSchema = new schema.Entity('services');
export const countrySchema = new schema.Entity('countries');
export const applicationSchema = new schema.Entity('applications');
export const industrySchema = new schema.Entity('industries')
export const parentIndustrySchema = new schema.Entity(
  'parentIndustries',
  { children: [industrySchema] }
)

export const fieldSchema = new schema.Entity('fields');
export const parentFieldSchema = new schema.Entity(
  'parentFields',
  { children: [fieldSchema] }
)

export const categorySchema = new schema.Entity('categories');
export const parentCategorySchema = new schema.Entity(
  'parentCategories',
  { children: [categorySchema] }
)