import { all } from 'redux-saga/effects';
import * as authSagas from './auth';
import * as coreSagas from './core';
import * as accountingSagas from './accounting';
import * as bootstrapSagas from './bootstrap';
import * as globalTabSagas from './globalTabs';
import * as clientSagas from './clients';
import * as documentSagas from './documents';
import * as templateSagas from './templates';
import * as invoiceSagas from './invoices';
import * as jobSagas from './jobs/common';
import * as managerSagas from './jobs/managers';
import * as employeeSagas from './jobs/employees';
import * as userSagas from './users';
import * as wordlistSagas from './wordlists';
import * as glossarySagas from './glossaries';
import * as termSagas from './terms';
import * as memorySagas from './memories';
import * as mhtSagas from './mht';
import * as calendarSagas from './calendars';
import * as customerSagas from './customers';
import * as quoteSagas from './quotes';

export default function* rootSaga() {

  yield all([

     // Core ===================================================================
     coreSagas.watchServerError(),

    // Auth ====================================================================
    authSagas.watchLogin(),
    authSagas.watchRefresh(),
    authSagas.watchPasswordChange(),
    authSagas.watchLogout(),

    accountingSagas.watchSalesTargetList(),
    accountingSagas.watchSalesTargetCreate(),
    accountingSagas.watchSalesTargetPatch(),
    accountingSagas.watchSalesReport(),

    // Users ===================================================================
    userSagas.watchList(),
    userSagas.watchCreate(),
    userSagas.watchUpdate(),
    userSagas.watchRetrieve(),
    userSagas.watchRemove(),

    userSagas.watchAvatarUpload(),
    userSagas.watchProfileRetrieve(),
    userSagas.watchProfileUpdate(),

    userSagas.watchTranslationSkillList(),
    userSagas.watchTranslationSkillCreate(),
    userSagas.watchTranslationSkillUpdate(),

    userSagas.watchEditSkillList(),
    userSagas.watchEditSkillCreate(),
    userSagas.watchEditSkillUpdate(),

    userSagas.watchCheckSkillList(),
    userSagas.watchCheckSkillCreate(),
    userSagas.watchCheckSkillUpdate(),

    userSagas.watchFinalEditSkillList(),
    userSagas.watchFinalEditSkillCreate(),
    userSagas.watchFinalEditSkillUpdate(),

    userSagas.watchSecondFinalEditSkillList(),
    userSagas.watchSecondFinalEditSkillCreate(),
    userSagas.watchSecondFinalEditSkillUpdate(),

    userSagas.watchFocusList(),
    userSagas.watchFocusCreate(),
    userSagas.watchFocusUpdate(),

    userSagas.watchSpecialtyList(),
    userSagas.watchSpecialtyCreate(),
    userSagas.watchSpecialtyUpdate(),

    userSagas.watchScheduleList(),
    userSagas.watchScheduleUpdateOrCreate(),

    userSagas.watchSoftwareList(),
    userSagas.watchSoftwareCreate(),
    userSagas.watchSoftwareDelete(),

    // bootstrap ===============================================================
    bootstrapSagas.watchManagerBootstrap(),
    bootstrapSagas.watchEmployeeBootstrap(),

    // tabs ====================================================================
    globalTabSagas.watchSelectTab(),
    globalTabSagas.watchAddOrSelectTab(),
    globalTabSagas.watchCloseTab(),
    globalTabSagas.watchConfirmTabClose(),

    // Client ==================================================================
    clientSagas.watchCreate(),
    clientSagas.watchList(),
    clientSagas.watchRetrieve(),
    clientSagas.watchUpdate(),
    clientSagas.watchRemove(),
    clientSagas.watchAutoComplete(),
    clientSagas.watchPreferableUserList(),
    clientSagas.watchPreferableUserAdd(),
    clientSagas.watchPreferableUserRemove(),
    clientSagas.watchNgUserList(),
    clientSagas.watchNgUserAdd(),
    clientSagas.watchNgUserRemove(),
    clientSagas.watchWebsiteList(),
    clientSagas.watchWebsiteAdd(),
    clientSagas.watchWebsiteRemove(),
    clientSagas.watchCommonReferenceList(),
    clientSagas.watchWordList(),

    // Template ================================================================
    templateSagas.watchCreate(),
    templateSagas.watchList(),
    templateSagas.watchRetrieve(),
    templateSagas.watchUpdate(),
    templateSagas.watchRemove(),

    // Sourcefile ==============================================================
    documentSagas.watchSourcefileUpload(),
    documentSagas.watchSourcefileRemove(),
    documentSagas.watchJobSourcefileUpload(),
    documentSagas.watchJobSourcefileRemove(),
    documentSagas.watchSourcefileDownload(),

    // Reference ===============================================================
    documentSagas.watchReferenceUpload(),
    documentSagas.watchReferenceRemove(),
    documentSagas.watchJobReferenceUpload(),
    documentSagas.watchJobReferenceRemove(),
    documentSagas.watchReferenceDownload(),

    // Job Common Reference ====================================================
    documentSagas.watchJobCommonReferenceUpload(),
    documentSagas.watchJobCommonReferenceRemove(),
    documentSagas.watchJobCommonReferenceDownload(),

    // Client Common Reference =================================================
    documentSagas.watchClientCommonReferenceUpload(),
    documentSagas.watchClientCommonReferenceUpdate(),
    documentSagas.watchClientCommonReferenceRemove(),
    documentSagas.watchClientCommonReferenceUse(),
    documentSagas.watchClientCommonReferenceDownload(),

    // Project =================================================================
    documentSagas.watchAssignmentProjectUpload(),
    documentSagas.watchAssignmentProjectRemove(),
    documentSagas.watchProjectDownload(),

    // Targetfile ==============================================================
    documentSagas.watchTargetfileUpload(),
    documentSagas.watchTargetfileDownload(),
    documentSagas.watchTargetfileRemove(),
    documentSagas.watchQuoteSourcefileDownload(),
    documentSagas.watchUnsupportedFileDownload(),

    // Manager jobs ============================================================
    managerSagas.watchRetrieve(),
    managerSagas.watchPatch(),
    managerSagas.watchCancel(),
    managerSagas.watchRemove(),

    managerSagas.watchRemoveDraft(),
    managerSagas.watchRemovePending(),

    managerSagas.watchDailySchedule(),
    managerSagas.watchFutureSchedule(),
    managerSagas.watchRearrange(),
    managerSagas.watchMemberList(),
    managerSagas.watchFetchLatestNumber(),
    managerSagas.watchSearch(),
    managerSagas.watchDraftList(),
    // managerSagas.watchWorkingList(),
    managerSagas.watchDeliveryList(),
    managerSagas.watchPendingList(),
    managerSagas.watchPreparingList(),
    managerSagas.watchToTraList(),
    managerSagas.watchRnQList(),

    managerSagas.watchCreate(),
    managerSagas.watchDraft(),
    managerSagas.watchDuplicateCreate(),
    managerSagas.watchDuplicateDraft(),
    managerSagas.watchTemplateByCreate(),
    managerSagas.watchTemplateByDraft(),
    managerSagas.watchSplitCreate(),
    managerSagas.watchSplitDraft(),
    managerSagas.watchSplitIssue(),
    managerSagas.watchDraftUpdate(),
    managerSagas.watchDraftToPending(),
    managerSagas.watchIssue(),
    managerSagas.watchEdit(),
    managerSagas.watchQuickEdit(),
    managerSagas.watchBulkVerify(),

    managerSagas.watchSuggestAssignment(),
    managerSagas.watchRefreshAssignment(),
    managerSagas.watchAssignmentFinish(),
    managerSagas.watchAssignmentPaymentCheck(),

    managerSagas.watchPrint(),
    managerSagas.watchProcess(),
    managerSagas.watchInvoiceList(),
    managerSagas.watchPaymentSearch(),
    managerSagas.watchAutoComplete(),

    managerSagas.watchMHTJobList(),
    managerSagas.watchMHTJobRertrieve(),
    managerSagas.watchMHTJobMessageList(),
    managerSagas.watchMHTJobMessageCreate(),
    managerSagas.watchMHTJobMessageRead(),
    managerSagas.watchMHTJobMessageBatchRead(),
    managerSagas.watchMHTJobDelivery(),
    managerSagas.watchMHTJobRedelivery(),
    managerSagas.watchMHTInvoiceDownload(),
    managerSagas.watchQuoteSourefileList(),

    // Employee jobs ===========================================================
    employeeSagas.watchEmployeeJobDailySchedule(),
    employeeSagas.watchEmployeeJobFutureSchedule(),
    employeeSagas.watchTotraCurrentJobs(),
    employeeSagas.watchToTraJobRetrieve(),
    employeeSagas.watchTranslatorCurrentJobs(),
    employeeSagas.watchTranslatorJobRetrieve(),
    employeeSagas.watchEditorCurrentJobs(),
    employeeSagas.watchEditorJobRetrieve(),
    employeeSagas.watchCheckerCurrentJobs(),
    employeeSagas.watchCheckerJobRetrieve(),
    employeeSagas.watchFinalEditorCurrentJobs(),
    employeeSagas.watchFinalEditorJobRetrieve(),
    employeeSagas.watchSecondFinalEditorCurrentJobs(),
    employeeSagas.watchSecondFinalEditorJobRetrieve(),
    employeeSagas.watchDtpCurrentJobs(),
    employeeSagas.watchDtpJobRetrieve(),
    employeeSagas.watchSecondDtpCurrentJobs(),
    employeeSagas.watchSecondDtpJobRetrieve(),
    employeeSagas.watchReportCount(),
    employeeSagas.watchPrint(),
    employeeSagas.watchHistorySearch(),
    employeeSagas.watchHistoryCsvExport(),
    employeeSagas.watchEmployeeJobStart(),
    employeeSagas.watchEmployeeJobFinish(),
    employeeSagas.watchAllReferenceDownload(),

    employeeSagas.watchDtpBulkFinish(),
    employeeSagas.watchSecondDtpBulkFinish(),

    // Invoice =================================================================
    invoiceSagas.watchCreate(),
    invoiceSagas.watchList(),
    invoiceSagas.watchUpdate(),
    invoiceSagas.watchPatch(),
    invoiceSagas.watchPrint(),

    // Common jobs =============================================================
    jobSagas.watchJobSourcefileList(),
    jobSagas.watchJobReferenceList(),
    jobSagas.watchJobCommonReferenceList(),
    jobSagas.watchJobClientReferenceList(),
    jobSagas.watchJobMessageList(),
    jobSagas.watchJobMessageCreate(),
    jobSagas.watchJobMessageUpdate(),
    jobSagas.watchJobMessageRemove(),

    // wordlists ===============================================================
    wordlistSagas.watchList(),
    wordlistSagas.watchRetrieve(),
    wordlistSagas.watchUpdate(),
    wordlistSagas.watchBatchDelete(),
    wordlistSagas.watchUpload(),
    wordlistSagas.watchExportExcel(),
    wordlistSagas.watchAutoComplete(),

    // glossaries ==============================================================
    glossarySagas.watchList(),
    glossarySagas.watchListAll(),
    glossarySagas.watchRetrieve(),
    glossarySagas.watchUpdate(),
    glossarySagas.watchBatchDelete(),
    glossarySagas.watchUpload(),
    glossarySagas.watchExportExcel(),

    // terms ===================================================================
    termSagas.watchSearch(),

    // memories ================================================================
    memorySagas.watchSearch(),
    memorySagas.watchCount(),
    memorySagas.watchSearchList(),
    memorySagas.watchSearchCreate(),
    memorySagas.watchUpload(),

    memorySagas.watchSearchUpdate(),
    memorySagas.watchSearchClear(),
    memorySagas.watchSearchDelete(),

    memorySagas.watchImportTmx(),
    memorySagas.watchExportExcel(),
    memorySagas.watchExportTmx(),

    memorySagas.watchItemUpdate(),

    mhtSagas.watchRetrieve(),
    mhtSagas.watchReCommit(),
    mhtSagas.watchUpdateAnalysis(),
    mhtSagas.watchSourcefileUpload(),
    mhtSagas.watchDownloadComments(),
    mhtSagas.watchDownloadTargetFile(),
    mhtSagas.watchDownloadBilingualExcel(),
    mhtSagas.watchDownloadBilingualTmx(),
    mhtSagas.watchShowPreview(),
    mhtSagas.watchAnalysisReport(),

    // calendars ===============================================================
    calendarSagas.watchCalendar(),
    
    // customers ===============================================================
    customerSagas.watchList(),
    customerSagas.watchCreate(),
    customerSagas.watchRetrieve(),
    customerSagas.watchUpdate(),

    // quotes
    quoteSagas.watchList(),
    quoteSagas.watchRetrieve(),
    quoteSagas.watchBatchDelete(),
    quoteSagas.watchUnsupportedOrderList(),
    quoteSagas.watchUnsupportedOrderRetrieve(),
  ]);
}
