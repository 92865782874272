import { MHT_EXIT_CONFIRM_MODAL } from 'misc/constants';
import { getEntities, getMHTEntities } from 'selectors/entities';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { mhtSchema } from 'schemas/mht';
import * as coreSelectors from 'selectors/core';


export const isMHTExitConfirmModalOpen = (state) => {
  const modalState = state.modal[MHT_EXIT_CONFIRM_MODAL] || {}
  return modalState['show'] && modalState['show'] === true
    ? true
    : false;
}

export const getMHTById = createSelector(
  [coreSelectors.getId, getMHTEntities],
  (id, entities) => entities[id]
)

export const getMHTValueById = createSelector(
  [coreSelectors.getId, getEntities],
  (id, entities) => denormalize(id, mhtSchema, entities)
)