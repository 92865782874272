import React from 'react';
import isEqual from 'lodash.isequal';

class PureRenderComponent extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const propsDiff = isEqual(nextProps, this.props);
    const stateDiff = isEqual(nextState, this.state);
    return !(propsDiff && stateDiff);
  }
}

export default PureRenderComponent;