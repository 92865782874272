import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Field } from 'redux-form';
import { FormGroup, ControlLabel, Row, Col, Button } from 'react-bootstrap';
import DatePickerField from 'components/core/DatePickerField';
import SelectField from 'components/core/SelectField';
import CheckboxField from 'components/core/CheckboxField';
import TimeField from 'components/core/TimeField'


const JobQuickEditAssignmentField = (props) => {

  const {
    label,
    name,
    options,
    handleTrash
  } = props;
  
  return (
    <Row>
      <FormGroup>
        <Col componentClass={ControlLabel} lg={1}>
          {label}
        </Col>
        <Col lg={2}>
          <Field
            name={`${name}.assignee`}
            options={options}
            component={SelectField} />
        </Col>
        <Col lg={2}>
          <Field
            name={`${name}.startDate`}
            component={DatePickerField.Inline} />
        </Col>
        <Col lg={1}>
          <Field
            name={`${name}.startTime`}
            component={TimeField.Inline} />
        </Col>
        <Col lg={2}>
          <Field
            name={`${name}.endDate`}
            component={DatePickerField.Inline} />
        </Col>
        <Col lg={1}>
          <Field
            name={`${name}.endTime`}
            component={TimeField.Inline} />
        </Col>
        <Col lg={1}>
          <Field
            name={`${name}.autoProcess`}
            label="Auto"
            component={CheckboxField.Labeled} />
        </Col>
        <Col lg={1}>
          <Field
            name={`${name}.strictDeadline`}
            label="Strict"
            component={CheckboxField.Labeled} />
        </Col>
        <Col lg={1}>
          <Button
            bsSize="sm"
            onClick={() => handleTrash(name)}>
              <FontAwesome name="trash" />
          </Button>
        </Col>
      </FormGroup>
    </Row>
  );
}

export default JobQuickEditAssignmentField;