import { connect } from 'react-redux';
import { getCountryOptions } from 'selectors/options';
import SelectOptionField from './SelectOptionField';


const mapStateToProps = (state) => {
  return {
    name: 'country',
    label: 'Country',
    options: getCountryOptions(state),
  }
}

export default connect(
  mapStateToProps,
)(SelectOptionField);