import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isGlossaryListLoading } from 'selectors/loading';
import { listRequest, uploadRequest, batchDeleteRequest } from 'actions/glossaries';
import { show } from 'redux-modal';
import { GLOSSARY_UPLOAD_MODAL } from 'misc/constants';
import { getGlossaries, getGlossaryListCurrentPage, getGlossaryListPageSize } from 'selectors/lists';
import { getNestedCategoryOptions } from 'selectors/options';
import PureRenderComponent from 'components/core/PureRenderComponent';
import GlossaryList from './presenter';


class GlossaryListContainer extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = {
      category: '',
      keyword: '',
      selected: [],
    }
  }

  componentDidMount() {
    this.props.listRequest({ page: this.props.currentPage });
  }

  handlePageSelect = (page) => {
    const { category, keyword } = this.state;
    this.props.listRequest({
      category,
      keyword,
      page
    });
  }

  handleKeywordChange = (keyword) => {
    this.setState({ keyword })
  }

  handleCategoryChange = (category) => {
    this.setState({ category });
    this.props.listRequest({
      category,
      page: 1,
      keyword: this.state.keyword
    });
  }

  handleSubmit = () => {
    const { category, keyword } = this.state;
    this.props.listRequest({
      keyword,
      category,
      page: this.props.currentPage
    });
  }

  handleUpload = (values) => {
    this.props.uploadRequest(values)
  }

  showAddModal = () => {
    this.props.show(GLOSSARY_UPLOAD_MODAL, {
      handleAddGlossary: this.handleUpload
    })
  }

  handleSelect = (e) => {
    const { checked, value } = e.target;
    const newValue = [...this.state.selected];
    checked
      ? newValue.push(value)
      : newValue.splice(newValue.indexOf(value), 1)
    this.setState({ selected: newValue });
  }

  handleDelete = () => {
    this.props.batchDeleteRequest({ items: this.state.selected });
    this.setState({ selected: [] });
  }

  render() {
    return (
      <GlossaryList
        {...this.props}
        selected={this.state.selected}
        keyword={this.state.keyword}
        category={this.state.category}
        handleSelect={this.handleSelect}
        handleDelete={this.handleDelete}
        handleSubmit={this.handleSubmit}
        handleKeywordChange={this.handleKeywordChange}
        handleCategoryChange={this.handleCategoryChange}
        handlePageSelect={this.handlePageSelect}
        showAddModal={this.showAddModal}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    glossaries: getGlossaries(state),
    categoryOptions: getNestedCategoryOptions(state),
    isLoading: isGlossaryListLoading(state),
    pageSize: getGlossaryListPageSize(state),
    currentPage: getGlossaryListCurrentPage(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    listRequest,
    uploadRequest,
    batchDeleteRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlossaryListContainer);
