import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Button } from 'react-bootstrap';


const EmployeeJobDetailTools = (props) => {
  
  const {
    isTranslatorJob,
    handlePrint,
    showReportCountModal
  } = props;
  
  return (
    <ul className="job-detail-tools list-unstyled list-inline pull-right">
      <li>
        <Button bsSize="sm" onClick={handlePrint}>
          <FontAwesome name="print" className="mr-5"/>Print
        </Button>
      </li>
      { isTranslatorJob &&
        <li>
          <Button
            className="btn btn-sm bg-purple"
            onClick={showReportCountModal}>
            <FontAwesome name="flag" className="mr-5"/>Report actual count
          </Button>
        </li>
      }
    </ul>
  );
}

export default EmployeeJobDetailTools;