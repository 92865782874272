import React from 'react';
import jQuery from 'jquery';
import 'fullcalendar';
import isEqual from 'lodash.isequal';


class Calendar extends React.Component {
  static defaultProps = {
    id: null,
    theme: 'standard',
    header: null,
    style: null,
    className: null,
    isRTL: false,
    weekends: true,
    hiddenDays: [],
    fixedWeekCount: true,
    weekNumbers: false,
    businessHours: false,
    height: 'auto',
    contentHeight: null,
    aspectRatio: 1.35,
    eventLimit: false,
    defaultDate: null,
    editable: false,
    clickable: false,
    selectable: false,
    selectConstraint: null,
    // eventConstraint: 'available',
    eventConstraint: null,
    droppable: false,
    eventStartEditable: false,
    eventDurationEditable: false,
    defaultView: 'basic',
    allDaySlot: true,
    allDayText: 'all-day',
    slotDuration: '00:30:00',
    slotLabelInterval: null,
    snapDuration: null,
    scrollTime: '06:00:00',
    minTime: '00:00:00',
    maxTime: '24:00:00',
    slotEventOverlap: true,
    nowIndicator: true,
    dragRevertDuration: 500,
    dragOpacity: 0.75,
    dragScroll: false,
    eventOverlap: true,
    eventRender: null,
    dayRender: null,
    selectMinDistance: 1,
    nextDayThreshold: '01:00:00',
    locale: null,
    options: null,
    onDayClick: null,
    onEventClick: null,
    onEventMouseover: null,
    onEventMouseout: null,
    onEventDragStart: null,
    onEventDragStop: null,
    onEventDrop: null,
    onEventResizeStart: null,
    onEventResizeStop: null,
    onEventResize: null,
    onViewRender: null,
    onDrop: null,
    columnHeader: true,
    weekNumberCalculation: 'ISO'
  }

  gotoDate = (date) => {
      this.schedule.fullCalendar('gotoDate', date);
  }

  prev = () => {
    this.schedule.fullCalendar('prev');
  }

  next = () => {
    this.schedule.fullCalendar('next');
  }

  prevYear = () => {
    this.schedule.fullCalendar('prevYear');
  }

  nextYear = () => {
    this.schedule.fullCalendar('nextYear');
  }

  today = () => {
    this.schedule.fullCalendar('today');
  }

  incrementDate = (duration) => {
    this.schedule.fullCalendar('incrementDate', duration);
  }

  changeView = (viewName) => {
    this.schedule.fullCalendar('changeView', viewName);
  }

  getDate = () => {
    return this.schedule.fullCalendar('getDate');
  }

  getWeek = () => {
    const start = this.getDate();
    const end = start.clone().endOf('week');
    return { start, end };
  }

  getCurrentDateRange = () => {
    const start = this.schedule.fullCalendar('getView').start;
    const end = this.schedule.fullCalendar('getView').end
    return { start, end };
  }

  componentDidMount() {
    this.config = {
      theme: this.props.theme,
      header: this.props.header,
      isRTL: this.props.rtl,
      weekends: this.props.weekends,
      hiddenDays: this.props.hiddenDays,
      fixedWeekCount: this.props.fixedWeekCount,
      weekNumbers: this.props.weekNumbers,
      businessHours: this.props.businessHours,
      weekNumberCalculation: this.props.weekNumberCalculation,
      selectMinDistance: this.props.selectMinDistance,
      height: this.props.height,
      contentHeight: this.props.contentHeight,
      aspectRatio: this.props.aspectRatio,
      eventLimit: this.props.eventLimit,
      defaultDate: this.props.defaultDate,
      timezone: this.props.timezone,
      editable: this.props.editable,
      selectable: this.props.selectable,
      selectConstraint: this.props.selectConstraint,
      eventConstraint: this.props.eventConstraint,
      droppable: this.props.droppable,
      eventStartEditable: this.props.eventStartEditable,
      eventDurationEditable: this.props.eventDurationEditable,
      defaultView: this.props.defaultView,
      allDaySlot: this.props.allDaySlot,
      allDayText: this.props.allDayText,
      slotDuration: this.props.slotDuration,
      slotLabelInterval: this.props.slotLabelInterval,
      snapDuration: this.props.snapDuration,
      scrollTime: this.props.scrollTime,
      minTime: this.props.minTime,
      maxTime: this.props.maxTime,
      slotEventOverlap: this.props.slotEventOverlap,
      nowIndicator: this.props.nowIndicator,
      dragRevertDuration: this.props.dragRevertDuration,
      dragOpacity: this.props.dragOpacity,
      dragScroll: this.props.dragScroll,
      eventOverlap: this.props.eventOverlap,
      dayRender: this.props.dayRender,
      displayEventTime: this.props.displayEventTime,
      displayEventEnd: this.props.displayEventEnd,
      timeFormat: this.props.timeFormat,
      columnHeader: this.props.columnHeader,
      eventColor: this.props.eventColor,
      visibleRange:this.props.visibleRange,
      nextDayThreshold: this.props.nextDayThreshold,
      dayClick: (date, jsEvent, view) => {
        if (this.props.onDayClick) {
          this.props.onDayClick({
            'date': date,
            'jsEvent': jsEvent,
            'view': view
          });
        }
      },
      drop: (date, jsEvent, ui, resourceId) => {
        if (this.props.onDrop) {
          this.props.onDrop({
            'date': date,
            'jsEvent': jsEvent,
            'resourceId': resourceId
          });
        }
      },
      eventClick: (calEvent, jsEvent, view) => {
        if (this.props.clickable && this.props.onEventClick) {
          this.props.onEventClick(calEvent);
        }
      },
      eventMouseover: (calEvent, jsEvent, view) => {
        if (this.props.onEventMouseover) {
          this.props.onEventMouseover(calEvent);
        }
      },
      eventMouseout: (calEvent, jsEvent, view) => {
        if (this.props.onEventMouseout) {
          this.props.onEventMouseout(calEvent);
        }
      },
      eventDragStart: (calEvent, jsEvent, ui, view) => {
        if (this.props.onEventDragStart) {
          this.props.onEventDragStart(calEvent);
        }
      },
      eventDragStop: (event, jsEvent, ui, view) => {
        if (this.props.onEventDragStop) {
          this.props.onEventDragStop({
            'event': event,
            'jsEvent': jsEvent,
            'view': view
          });
        }
      },
      eventDrop: (event, delta, revertFunc, jsEvent, ui, view) => {
        if (this.props.onEventDrop) {
          this.props.onEventDrop(event)
        }
      },
      eventResizeStart: (event, jsEvent, ui, view) => {
        if (this.props.onEventResizeStart) {
          this.props.onEventResizeStart({
            'event': event,
            'jsEvent': jsEvent,
            'view': view
          });
        }
      },
      eventResizeStop: (event, jsEvent, ui, view) => {
        if (this.props.onEventResizeStop) {
          this.props.onEventResizeStop({
            'event': event,
            'jsEvent': jsEvent,
            'view': view
          });
        }
      },
      eventResize: (event, delta, revertFunc, jsEvent, ui, view) => {
        if (this.props.onEventResize) {
          this.props.onEventResize(event)
        }
      },
      viewRender: (view, element) => {
        if (this.props.onViewRender) {
          this.props.onViewRender({
            'view': view,
            'element': element
          });
        }
      },
      select: (start, end) => {
        if (this.props.onSelect) {
          this.props.onSelect(start, end);
        }
      },
      customButtons: {
        customPrev: {
          icon: 'fc-icon-left-single-arrow',
          click: () => {
            this.prev();
            if (this.props.onPrevClick) {
              const { start, end } = this.getCurrentDateRange();
              this.props.onPrevClick(start, end);
            }
          }
        },
        customNext: {
          icon: 'fc-icon-right-single-arrow',
          click: () => {
            this.next();
            if (this.props.onNextClick) {
              const { start, end } = this.getCurrentDateRange();
              this.props.onNextClick(start, end);
            }
          }
        },
        customRefresh: {
          text: 'refresh',
          click: () => {
            if (this.props.fetchEvent) {
              const { start, end } = this.getCurrentDateRange();
              this.props.fetchEvent(start, end);
            }
          }
        }
      },
    };

    if (this.props.locale) {
      for (let prop in this.props.locale) {
        this.config[prop] = this.props.locale[prop];
      }
    }

    if (this.props.options) {
      for (let prop in this.props.options) {
        this.config[prop] = this.props.options[prop];
      }
    }

    this.schedule = jQuery(this.scheduleEl);
    this.schedule.fullCalendar(this.config);
    this.schedule.fullCalendar('addEventSource', this.props.events);
    // this.events = [...this.props.events];
    // const { start, end } = this.getCurrentDateRange();
    // this.props.fetchEvent && this.props.fetchEvent(start, end);
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return false;
  // }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.events, this.props.events)) {
      this.schedule.fullCalendar('removeEventSources');
      this.schedule.fullCalendar('addEventSource', this.props.events);
    }
    if (!isEqual(prevProps.visibleRange, this.props.visibleRange)) {
      this.schedule.fullCalendar('option', 'visibleRange', this.props.visibleRange);
    }
    if (!isEqual(prevProps.columnHeader, this.props.columnHeader)) {
      this.schedule.fullCalendar('option', 'columnHeader', this.props.columnHeader);
    }
    if (!isEqual(prevProps.businessHours, this.props.businessHours)) {
      this.schedule.fullCalendar('option', 'businessHours', this.props.businessHours);
    }

    if (!isEqual(prevProps.defaultView, this.props.defaultView)) {
      this.schedule.fullCalendar('changeView', this.props.defaultView);
    }
  }

  componentWillUnmount() {
    jQuery(this.scheduleEl).fullCalendar('destroy');
  }

  render() {
    const {
      id,
      style,
      className
    } = this.props;

    return (
      <div
        id={id}
        ref={(el) => this.scheduleEl = el}
        style={style}
        className={className} />
    );
  }
}

export default Calendar;