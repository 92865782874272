import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import { JOB_TYPE_OPTIONS } from 'misc/config';
import * as validators from 'misc/validators';
import Callout from 'components/core/Callout';
import CharField from 'components/core/CharField';
import SelectField from 'components/core/SelectField';
import JobBaseNumberInputField from 'components/jobs/managers/JobBaseNumberInputField';
import JobBasicInfoFields from 'components/jobs/managers/JobBasicInfoFields';
import JobNotesField from 'components/jobs/managers/JobNotesField';
import { AESelectOptionField, CoordinatorSelectOptionField } from 'components/core/SelectOptionField';
import JobAdditionalCountRolesField from 'components/jobs/managers/JobAdditionalCountRolesField';
import JobAssignmentFields from 'components/jobs/managers/JobAssignmentFields';
import Sourcefile from 'components/jobs/managers/Sourcefile';
import Reference from 'components/jobs/managers/Reference';
import JobClientCommonReference from 'components/jobs/managers/JobClientCommonReference';


const JobSplitIssueForm = (props) => {

  const {
    form,
    client,
    isLoading,
    initialValues,
    invalid,
    formValues,
    handleIssue,
    submitFailed,
    handleSubmit,
  } = props;

  const numberField = (
    <Row>
      <Col lg={4}>
        <Field
          name="type"
          label="Type"
          options={JOB_TYPE_OPTIONS}
          validate={[validators.required]}
          disabled={true}
          isRequired={true}
          component={SelectField.Labeled} />
      </Col>
      <Col lg={8}>
        <Field
          name="baseNumber"
          label="Base number"
          validate={[
            validators.required,
            validators.numeric,
            validators.minLength7,
            validators.maxLength8
          ]}
          disabled={true}
          isRequired={true}
          type="text"
          bsSize="sm"
          formName={form}
          component={JobBaseNumberInputField.Labeled} />
      </Col>
      <Col lg={12}>
        <Field
          name="branch"
          label="Split No."
          validate={[
            validators.required,
            validators.alphanumeric,
            validators.maxLength246
          ]}
          isRequired={true}
          type="text"
          component={CharField.Labeled} />
      </Col>
    </Row>
  )

  const subjectField = (
    <Row>
      <Col lg={12}>
        <Field
          name="aeSubject"
          label="AE Subject"
          type="text"
          validate={[validators.maxLength150]}
          component={CharField.Labeled} />
      </Col>
      <Col lg={12}>
        <Field
          name="subject"
          label="Subject"
          type="text"
          validate={[
            validators.required,
            validators.maxLength150
          ]}
          isRequired={true}
          component={CharField.Labeled} />
      </Col>
    </Row>
  )

  const memoField = (
    <Row>
      <Col lg={12}>
        <Field
          name="memo"
          label="Memo"
          type="text"
          validate={[validators.maxLength150]}
          component={CharField.Labeled} />
      </Col>
    </Row>
  )

  const userField = (
    <Row>
      <Col lg={6}>
        <AESelectOptionField
          isRequired={true}
          validate={[validators.required]} />
      </Col>
      <Col lg={6}>
        <CoordinatorSelectOptionField
          isRequired={true}
          validate={[validators.required]} />
      </Col>
    </Row>
  )

  const additionalCountRolesField = (
    <JobAdditionalCountRolesField formName={form} />
  )

  return (
    <Form
      noValidate
      autoComplete="off">
      <Row>
        <Col lg={6}>
          <JobBasicInfoFields
            form={form}
            isLoading={isLoading}
            numberField={numberField}
            subjectField={subjectField}
            memoField={memoField}
            additionalCountRolesField={additionalCountRolesField}
            userField={userField} />
          <JobAssignmentFields
            form={props.form}
            requireAssignChangeNotification={false}
            initialValues={initialValues}
            formValues={formValues}
            handleSubmit={handleSubmit} />
        </Col>
        <Col lg={6}>
          <JobNotesField
            name="instruction"
            title="Instruction"
            isLoading={isLoading}
            isRequired={true} />
          <JobNotesField
            name="notes"
            title="Job notes"
            isLoading={isLoading} />
          <Sourcefile form={form} />
          <Reference form={form} />
          <JobClientCommonReference clientId={client} />
        </Col>
      </Row>
      <Callout
        invalid={invalid}
        submitFailed={submitFailed} />
      <Button
        type="button"
        bsStyle="primary"
        bsSize="sm"
        onClick={handleSubmit(values => handleIssue(values))}>
        <FontAwesome name="paper-plane-o" className="mr-5" />Issue
      </Button>
    </Form>
  )
}

export default reduxForm({
  enableReinitialize: true,
  validate: validators.jobFormFullValidate
})(JobSplitIssueForm);
