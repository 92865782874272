import React from 'react';
import { LoginPage } from 'reactjs-admin-lte';
import LogoBlue from 'assets/images/logo-blue.svg';
import Spinner from 'components/core/Spinner';
import LoginForm from './LoginForm';


const Login = (props) => {
  
  const {
    form,
    handleLogin,
    isLoading
  } = props;
  
  return (
    <LoginPage>
      <LoginPage.Logo>
        <img
          src={LogoBlue}
          alt="logo"
          width="60"
          height="60" />
        <h3 className="login-logo-text">
          MHT Corporation
          <span>-</span>
          <strong>INTRANET</strong>
        </h3>
      </LoginPage.Logo>
      <LoginPage.Body>
        <LoginForm
          form={form}
          onSubmit={handleLogin} />
        <Spinner isLoading={isLoading} />
      </LoginPage.Body>
    </LoginPage>
  );
}

export default Login;
