import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Box } from 'reactjs-admin-lte';
import { Radio, Button } from 'react-bootstrap';


const InvoiceRadio = (props) => {

  const {
    index,
    invoice,
    selectedInvoice,
    handleInvoiceSelect,
  } = props;

  return (
    <Radio
      checked={selectedInvoice === index}
      value={invoice.id}
      onChange={() => handleInvoiceSelect(index)}>
      Invoice #{invoice.seqNo}
    </Radio>
  )
}

const JobDeliveryDetailInvoiceNavigation = (props) => {

  const {
    invoices,
    isLoading,
    selectedInvoice,
    handleInvoiceSelect,
    handleInvoicePrint,
    showInvoiceEditModal
  } = props;

  return (
    <Box
      className="box-primary navigation-box"
      loading={isLoading}>
      <Box.Header>
        <Box.Title>Invoice</Box.Title>
      </Box.Header>
      <Box.Body>
        {invoices.map((invoice, index) => (
          <InvoiceRadio
            index={index}
            key={invoice.id}
            invoice={invoice}
            selectedInvoice={selectedInvoice}
            handleInvoiceSelect={handleInvoiceSelect} />
        ))}
      </Box.Body>
      <Box.Footer>
        <Button
          bsStyle="default"
          bsSize="sm"
          type="button"
          onClick={handleInvoicePrint}>
          <FontAwesome name="print" className="mr-5" />Print
        </Button>
        <button
          className="btn btn-sm bg-green pull-right"
          type="button"
          onClick={showInvoiceEditModal}>
          <FontAwesome name="edit" className="mr-5" />Edit Invoice
        </button>
      </Box.Footer>
    </Box>
  );
}
export default JobDeliveryDetailInvoiceNavigation;
