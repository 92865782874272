import React from 'react';
import classNames from 'classnames';
import CalendarUserInfo from './CalendarUserInfo';


const CalendarUser = (props) => {
  const cls = classNames({
    'calendar-user': true,
    'has-header': props.hasColumnHeader,
  })
  return (
    <div className={cls}>
      <CalendarUserInfo
        id={props.id}
        username={props.username}
        userAvatar={props.userAvatar}
        isFreelance={props.isFreelance}
        handleTabOpen={props.handleTabOpen}
        selectNestedTab={props.selectNestedTab} />
    </div>
  )
}

export default CalendarUser;