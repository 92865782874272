import { connect } from 'react-redux';
import { getNestedIndustryOptions } from 'selectors/options';
import SelectOptionField from './SelectOptionField';


const mapStateToProps = (state) => {
  return {
    name: 'industry',
    label: 'Industry',
    options: getNestedIndustryOptions(state),
  }
}

export default connect(
  mapStateToProps,
)(SelectOptionField);