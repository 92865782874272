import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import VirtualizedSelect from 'react-virtualized-select'
import { autocompleteRequest } from 'actions/clients';
import { getClientOptions } from 'selectors/options';
import { isClientAutoCompleteLoading } from 'selectors/loading';
import LabeledField from 'components/core/LabeledField';
import HorizontalField from 'components/core/HorizontalField';
import PureRenderComponent from 'components/core/PureRenderComponent';


class ClientAsyncSelectField extends PureRenderComponent {

  handleInputChange = (value) => {
    value && this.props.autocompleteRequest(value);
  }

  render() {

    const {
      input,
      clientOptions,
      isLoading,
      style
    } = this.props;

    return (
      <VirtualizedSelect
        {...input}
        style={style}
        simpleValue={true}
        options={clientOptions}
        filterOption={(options, filterString) => {
          return (
            options.label.toLowerCase().indexOf(filterString) !== -1 ||
            options.fullNameEn.toLowerCase().indexOf(filterString) !== -1 ||
            options.fullNameJp.toLowerCase().indexOf(filterString) !== -1
          )
        }}
        isLoading={isLoading}
        onChange={(value) => input.onChange(value)}
        onInputChange={(value) => this.handleInputChange(value)}
        placeholder=""
        onBlur={() => input.onBlur()} />
    )
  }
}

const LabeledClientAsyncSelectField = (props) => {
  return (
    <LabeledField {...props}>
      <ClientAsyncSelectField {...props} />
    </LabeledField>
  );
}

const HorizontalClientAsyncSelectField = (props) => {
  return (
    <HorizontalField {...props}>
      <ClientAsyncSelectField {...props} />
    </HorizontalField>
  );
}


const mapStateToProps = (state) => {
  return {
    isLoading: isClientAutoCompleteLoading(state),
    clientOptions: getClientOptions(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    autocompleteRequest,
  }, disptach)
}

ClientAsyncSelectField = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientAsyncSelectField);

ClientAsyncSelectField.Labeled = LabeledClientAsyncSelectField;
ClientAsyncSelectField.Horizontal = HorizontalClientAsyncSelectField;

export default ClientAsyncSelectField;
