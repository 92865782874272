import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FormGroup, Form, ControlLabel} from 'react-bootstrap';
import Select from 'components/core/Select';
import WordListAsyncSelect from 'components/core/WordListAsyncSelect';
import { normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import { mhtSchema } from 'schemas';
import {TaModal, TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import MHTApi from "services/MHTApi";
import { sync } from 'actions/entities';
import {wordlistRequest} from 'actions/clients';
import {listAllRequest as glossaryListRequest} from 'actions/glossaries';
import { getWordListOptionsById, getParentCategoryOptions, getNestedCategoryOptions } from 'selectors/options';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import {TaButton} from "components/taUi/taButton/taButton";
import {TaFormGroup} from "components/taUi/taFormGroup/taFormGroup";
import PureRenderComponent from 'components/core/PureRenderComponent';


class TaMhtEditorSettingsTerm extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDirty: false,
      isSaving: false,
      formData: {
        reference_parent_categories: [],
        reference_categories: [],
        reference_wordlists: [],
        reference_additional_wordlists: []
      }
    };
  }

  componentDidMount() {
    this.requestData();
    if (!isEmpty(this.props.mht)) {
      this.mapPropsToFormDataState();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.mht, this.props.mht)) {
      this.mapPropsToFormDataState();
    }

    if (this.state.formData.reference_parent_categories.length == 0) {
      this.setState({
        formData: {
          ...this.state.formData,
          reference_categories: []
        }
      })
    }
  }

  mapPropsToFormDataState = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        reference_parent_categories: this.props.mht.referenceParentCategories,
        reference_categories: this.props.mht.referenceCategories,
        reference_wordlists: this.props.mht.referenceWordlists,
        reference_additional_wordlists: this.props.mht.referenceAdditionalWordlists
      }
    });
  }

  requestData = () => {
    if (this.props.client && this.props.client.id) {
      this.props.wordlistRequest(this.props.client.id);
    }
  };


  onCancel = () => {
    if (typeof this.props.onCancel === 'function') {
      this.props.onCancel();
    }
  };

  onSubmit = () => {
    const id = this.props.mht.id;
    this.setState({
      isSaving: true
    }, () => {
      MHTApi
        .update(id, this.state.formData)
        .then(response => {
          const normalized = normalize(camelizeKeys(response.data), mhtSchema);
          const { result, entities: { mht } } = normalized;
          this.props.sync('mht', result, mht[result]);
          if (typeof this.props.onConfirm === 'function') {
            this.props.onConfirm(response.data);
          }
        })
        .catch(error => {
          console.error('listSegmentTerms - error', error);
        })
        .finally(() => {
          this.setState({
            isSaving: false
          });
        });
    });
  };

  onSelectChange = (key, value) => {
    let newValue;
    newValue = value ? value.split(',') : []
    this.setState({
      isDirty: true,
      formData: {
        ...this.state.formData,
        [key]: newValue
      }
    });
  };

  getFilteredCategoryOptions = () => {
    const parentCategories = this.state.formData.reference_parent_categories;
    return this.props.categoryOptions.filter(option => {
      return parentCategories.includes(option.parentValue)
    })
  }


  render() {

    const {
      parentCategoryOptions,
      wordlistOptions
    } = this.props;

    return (
      <TaModal
        isOpen={this.props.isOpen}
        onCancel={this.onCancel}
      >
        <TaModalHeader
          label={'Specify Term Base'}
          onCancel={this.onCancel}
        />
        <TaModalContent scroll={'auto'}>
          <TaFormGroup style={{paddingBottom:'72px'}}>
            <Form>
              <FormGroup>
                <ControlLabel>WordList</ControlLabel>
                <Select
                  value={this.state.formData.reference_wordlists}
                  multi={true}
                  onChange={value => this.onSelectChange('reference_wordlists', value)}
                  options={wordlistOptions}/>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Additional WordList</ControlLabel>
                <WordListAsyncSelect
                  value={this.state.formData.reference_additional_wordlists}
                  multi={true}
                  onChange={value => this.onSelectChange('reference_additional_wordlists', value)} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Category 1</ControlLabel>
                <Select
                  value={this.state.formData.reference_parent_categories}
                  multi={true}
                  onChange={value => this.onSelectChange('reference_parent_categories', value)}
                  options={parentCategoryOptions}/>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Category 2</ControlLabel>
                <Select
                  value={this.state.formData.reference_categories}
                  multi={true}
                  onChange={value => this.onSelectChange('reference_categories', value)}
                  options={this.getFilteredCategoryOptions()}/>
              </FormGroup>
            </Form>
          </TaFormGroup>
        </TaModalContent>
        <TaModalFooter>
          <TaButton
            type="reset"
            icon={'clear'}
            label={'Cancel'}
            onClick={this.onCancel}
          />
          <TaButton
            type="submit"
            icon={'check'}
            label={'Save'}
            iconAlign={'right'}
            onClick={this.onSubmit}
            // disabled={!state.isDirty}
            isLoading={this.state.isSaving}
          />
        </TaModalFooter>
      </TaModal>
    );
  }
}


const mapStateToProps = (state, ownProps) => {
  const clientId = (ownProps.client && ownProps.client.id) ? ownProps.client.id : 0;
  return {
    wordlistOptions: getWordListOptionsById(state, clientId),
    parentCategoryOptions: getParentCategoryOptions(state),
    categoryOptions: getNestedCategoryOptions(state)
  };
};

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    sync,
    wordlistRequest,
    glossaryListRequest
  }, disptach);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaMhtEditorSettingsTerm);


