import { delay, eventChannel, END } from 'redux-saga';
import { take, put, race, takeLatest, call } from 'redux-saga/effects';
import { show, hide } from 'redux-modal';
import Notifications from 'react-notification-system-redux';
import { SERVER_ERROR_MODAL, CONFIRM_MODAL } from 'misc/constants';
import { getNotificationOptions } from 'misc/utils';
import API from 'services/CoreApi';
import * as actions from 'actions/core';
import * as actionTypes from 'actionTypes/core';


function* confirm(message) {
  yield put(show(CONFIRM_MODAL, { message }));
  const { yes, no } = yield race({
    yes: take(actionTypes.CONFIRM_YES),
    no: take(actionTypes.CONFIRM_NO)
  })
  if (no) yield put(hide(CONFIRM_MODAL));
  return !!yes;
}

function* serverError(action) {
  const { message } = action.payload;
  yield put(show(SERVER_ERROR_MODAL, { message })); 
}

function* watchDownloadProgress(chan) {
  while (true) {
    const progress = yield take(chan);
    yield put(actions.setDownloadProgress(progress));
  }
}

function createDownloader(api, params) {
  let emit;
  const chan = eventChannel(emitter => {
    emit = emitter;
    return () => {};
  });
  const handleDownloadProgress = ({ loaded, total }) => {
    let percentage = 0;
    if (total === 0) {
      percentage = loaded;
    } else {
      percentage = Math.round((loaded * 100 / total));
    }
    emit(percentage);
    if (percentage === 100) emit(END);
  }
  const downloadPromise = api(params, handleDownloadProgress);
  return [downloadPromise, chan];
}

function* successNotification(message) {
  const options = getNotificationOptions('Success', message);
  yield put(Notifications.success(options))
}

function* errorNotification(message) {
  const options = getNotificationOptions('Error', message);
  yield put(Notifications.error(options))
}


function* checkTaskProgress(id, startMessage, endMessage) {
  const startOptions = getNotificationOptions('Info', startMessage, 0);
  yield put(Notifications.info(startOptions));
  const endOptions = getNotificationOptions('Info', endMessage);
  while (true) {
    try {
      const response = yield call(API.checkTaskProgress, id);
      if (response.data.state === 'SUCCESS') {
        yield put(Notifications.hide(startOptions.uid));
        yield put(Notifications.info(endOptions));
        break;
      }
      yield delay(2000);
    } catch (e) {
      yield put(Notifications.hide(startOptions.uid));
      const errorOptions = getNotificationOptions('Error', 'Server Error');
      yield put(Notifications.error(errorOptions));
      break;
    }
  }
}

function* checkConcurrentTaskProgress(id, startMessage, endMessage) {
  const startOptions = getNotificationOptions('Info', startMessage, 0);
  yield put(Notifications.info(startOptions));
  const endOptions = getNotificationOptions('Info', endMessage);
  while (true) {
    try {
      const response = yield call(API.checkConcurrentTaskProgress, id);
      if (response.data.state === 'SUCCESS') {
        yield put(Notifications.hide(startOptions.uid));
        yield put(Notifications.info(endOptions));
        break;
      }
      yield delay(2000);
    } catch (e) {
      yield put(Notifications.hide(startOptions.uid));
      const errorOptions = getNotificationOptions('Error', 'Server Error');
      yield put(Notifications.error(errorOptions));
      break;
    }
  }
}

function* watchServerError() {
  yield takeLatest(actionTypes.SERVER_ERROR, serverError);
}

export {
  confirm,
  watchDownloadProgress,
  createDownloader,
  checkTaskProgress,
  checkConcurrentTaskProgress,
  successNotification,
  errorNotification,
  watchServerError
}