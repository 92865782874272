import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { clientSchema } from 'schemas/clients';
import { getEntities, getClientEntities } from 'selectors/entities';
import * as coreSelectors from 'selectors/core';


export const getClientById = createSelector(
  [coreSelectors.getId, getClientEntities],
  (id, entities) => entities[id]
)

export const getClientValueById = createSelector(
  [coreSelectors.getId, getEntities],
  (id, entities) => denormalize(id, clientSchema, entities)
)

