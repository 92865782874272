import React from 'react';
import { Box } from 'reactjs-admin-lte';
import TooltipIconButton from 'components/core/TooltipIconButton';
import ClientUserPreferenceTable from './ClientUserPreferenceTable';


const ClientUserPreference = (props) => {
  
  const {
    isLoading,
    title,
    preferences,
    showAddModal,
    handleRemove
  } = props;
  
  return (
    <Box
      className="box-widget"
      loading={isLoading}>
      <Box.Header
        border={true}>
        <Box.Title>{title}</Box.Title>
        <Box.Tools>
          <TooltipIconButton
            id="add-client-user-preference"
            className="btn-box-tool"
            icon="edit"
            description={`Add ${title}`}
            onClick={showAddModal} />
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <ClientUserPreferenceTable
          preferences={preferences}
          handleRemove={handleRemove} />
      </Box.Body> 
    </Box>
  );
}

export default ClientUserPreference;