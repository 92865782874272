import React from 'react';
import FontAwesome from 'react-fontawesome';
import FilterBar from 'components/core/FilterBar';
import { Nav, NavItem } from 'react-bootstrap';


const SalesReportFilter = (props) => {
  
  const {
    currentMonth,
    handlePrev,
    handleNext
  } = props;
  
  return (
    <FilterBar>
      <Nav>
        <NavItem onClick={handlePrev}>
          <FontAwesome name="chevron-left" className="mr-10" />Prev Month
        </NavItem>
        <NavItem disabled={true}>{currentMonth}</NavItem>
        <NavItem onClick={handleNext}>
          Next Month<FontAwesome name="chevron-right" className="ml-10" />
        </NavItem>
      </Nav>
    </FilterBar>
  );
}

export default SalesReportFilter;