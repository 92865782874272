import React from 'react';
import { Table } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import DateFormatter from 'components/core/DateFormatter'

const EmployeeJobDetailBasicInfo = (props) => {
  
  const {
    isLoading,
    job,
    assignment,
    isChecker = false,
  } = props;

  return (
    <Box
      className="box-widget"
      loading={isLoading}>
      <Box.Header>
        <Box.Title>Basic Information</Box.Title>
        <Box.Tools>
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <Table responsive>
          <tbody>
            <tr>
              <th>Number</th><td>{job.number}</td>
              <th>Status</th><td>{job.status}</td>
            </tr>
            <tr>
              <th>Client</th><td>{job.client && job.client.name}</td>
              <th>Source</th><td>{job.sourceCount}</td>
            </tr>
            <tr>
              <th>Service</th><td>{job.service && job.service.name}</td>
              <th>Target</th><td>{job.targetCount}</td>
            </tr>
            <tr>
              <th>Deadline</th>
              <td>
                <DateFormatter
                  datetime={assignment && assignment['deadline']}
                  format="YYYY-MM-DD HH:mm"
                  isStrict={assignment && assignment['strictDeadline']} />
              </td>
              <th>Additional</th><td>{job.additionalCount}</td>
            </tr>
            <tr>
              <th>Subject</th><td colSpan="3">{job.subject}</td>
            </tr>
            <tr>
              <th>AE</th><td>{job.ae && job.ae.username}</td>
              <th>{assignment && assignment['role'] && assignment['role']['name']}</th>
              <td>{assignment && assignment['assignee'] && assignment['assignee']['username']}</td>
            </tr>
            <tr>
              <th>Coordinator</th><td colSpan={3}>{job.coordinator && job.coordinator.username}</td>
            </tr>
            {isChecker &&
              <tr>
                <th>Memo</th>
                <td colSpan={3}>{job.memo}</td>
              </tr>
            }
          </tbody>
        </Table>
      </Box.Body>
    </Box>
  )
}

export default EmployeeJobDetailBasicInfo;