import * as types from 'actionTypes/accounting';

export const salesTargetListRequest = (currentYear) => ({
  type: types.SALES_TARGET_LIST.REQUEST,
  payload: { currentYear }
});

export const salesTargetListSuccess = () => ({
  type: types.SALES_TARGET_LIST.SUCCESS
});

export const salesTargetListFailure = (error) => ({
  type: types.SALES_TARGET_LIST.FAILURE,
  payload: { error }
});

export const salesTargetCreateRequest = (currentYear, values) => ({
  type: types.SALES_TARGET_CREATE.REQUEST,
  payload: { currentYear, values }
});

export const salesTargetCreateSuccess = () => ({
  type: types.SALES_TARGET_CREATE.SUCCESS
});

export const salesTargetCreateFailure = (error) => ({
  type: types.SALES_TARGET_CREATE.FAILURE,
  payload: { error }
});

export const salesTargetPatchRequest = (id, values) => ({
  type: types.SALES_TARGET_PATCH.REQUEST,
  payload: { id, values }
});

export const salesTargetPatchSuccess = () => ({
  type: types.SALES_TARGET_PATCH.SUCCESS
});

export const salesTargetPatchFailure = (error) => ({
  type: types.SALES_TARGET_PATCH.FAILURE,
  payload: { error }
});

// Sales Report ================================================================
export const salesReportRequest = (currentMonth) => ({
  type: types.SALES_REPORT.REQUEST,
  payload: { currentMonth }
});

export const salesReportSuccess = () => ({
  type: types.SALES_REPORT.SUCCESS,
});

export const salesReportFailure = (error) => ({
  type: types.SALES_REPORT.FAILURE,
  payload: { error }
});