import { schema } from 'normalizr';
import { userSchema } from 'schemas/users';
import { clientSchema } from 'schemas/clients';
import { serviceSchema, industrySchema, fieldSchema } from 'schemas/core';

const templateSchema = new schema.Entity('templates', {
  service: serviceSchema,
  industry: industrySchema,
  field: fieldSchema,
  client: clientSchema,
  ae: userSchema,
  creator: userSchema,
});

export {
  templateSchema
}
