import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Form, FormGroup, ControlLabel, Button } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { Box } from 'reactjs-admin-lte';
import * as validators from 'misc/validators';
import CharField from 'components/core/CharField';
import TextField from 'components/core/TextField';
import CheckboxField from 'components/core/CheckboxField';
import CheckboxGroupField from 'components/core/CheckboxGroupField';


const UserAccountForm = (props) => {
  
  const {
    isLoading,
    hasHeader,
    handleUpdate,
    handleRemove,
    handleSubmit,
    isSuperuser
  } = props;
  
  return (
    <Form
      autoComplete="off"
      noValidate>
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        { hasHeader &&
          <Box.Header border={true}>
            <Box.Title>Account</Box.Title>
            <Box.Tools>
              <Box.CollapsedToggleButton />
            </Box.Tools>
          </Box.Header>
        }
        <Box.Body>
          <Field
            name="username"
            label="Username"
            type="text"
            validate={[
              validators.required,
              validators.username,
              validators.maxLength150
            ]}
            isRequired={true}
            component={CharField.Labeled} />
          <Field
            name="name"
            label="Short Name"
            type="text"
            validate={[
              validators.required,
              validators.maxLength50,
            ]}
            isRequired={true}
            component={CharField.Labeled} />
          <Field
            name="firstName"
            label="First Name"
            type="text"
            component={CharField.Labeled} />
          <Field
            name="lastName"
            label="Last Name"
            type="text"
            component={CharField.Labeled} />
          <Field
            name="email"
            label="Email"
            type="email"
            validate={[
              validators.required,
              validators.email
            ]}
            isRequired={true}
            component={CharField.Labeled} />
          <Field
            name="groups"
            label="Groups"
            col={4}
            validate={[
              validators.required,
              validators.minLength1
            ]}
            isRequired={true}
            options={props.groupOptions}
            component={CheckboxGroupField.Labeled} />
          <FormGroup>
            <ControlLabel>Options</ControlLabel>
            <Field
              name="isFreelance"
              label="is Freelance"
              component={CheckboxField.Labeled} />
            <Field
              name="isNative"
              label="is Native"
              component={CheckboxField.Labeled} />
            <Field
              name="isActive"
              label="is Active"
              component={CheckboxField.Labeled} />
          </FormGroup>
          <Field
            name="notes"
            label="Notes"
            component={TextField.Labeled} />
        </Box.Body>
        <Box.Footer>
          <Button
            bsStyle="success"
            bsSize="sm"
            type="button"
            onClick={handleSubmit(values => handleUpdate(values))}>
            <FontAwesome name="refresh" className="mr-5" />Update
          </Button>
          { isSuperuser &&
            <Button
              bsStyle="danger"
              bsSize="sm"
              type="button"
              className="ml-10"
              onClick={handleRemove}>
              <FontAwesome name="trash" className="mr-5" />Delete
            </Button>
          }
        </Box.Footer>
      </Box>
    </Form>
  )
}

export default reduxForm({
  enableReinitialize: true
})(UserAccountForm);
