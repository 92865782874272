import { schema } from 'normalizr';
import { serviceSchema } from './core';
import { clientSchema } from './clients';
import { jobSchema } from './jobs';

export const memorySchema = new schema.Entity('memories', {
  jobs: [jobSchema],
  client: clientSchema,
});

export const memorySearchSchema = new schema.Entity('memorySearches', {
  client: clientSchema,
  jobs: [jobSchema],
  service: serviceSchema
});