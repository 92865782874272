// copied from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml'

function plainTextToHtml(text ) {
  text = text
    // Encode <>.
    .replace( /</g, '&lt;' )
    .replace( />/g, '&gt;' )
    // Creates paragraphs for every line breaks.
    // .replace( /\n/g, '</p><p>' )
    // Preserve trailing spaces (only the first and last one – the rest is handled below).
    .replace( /^\s/, '&nbsp;' )
    .replace( /\s$/, '&nbsp;' )
    // Preserve other subsequent spaces now.
    .replace( /\s\s/g, ' &nbsp;' );

  if ( text.indexOf( '</p><p>' ) > -1 ) {
    // If we created paragraphs above, add the trailing ones.
    text = `<p>${ text }</p>`;
  }

  // TODO:
  // * What about '\nfoo' vs ' foo'?

  return text;
}
export default plainTextToHtml;
