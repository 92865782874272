import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Content } from 'reactjs-admin-lte';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, FormGroup, ControlLabel } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import * as validators from 'misc/validators';
import CharField from 'components/core/CharField';
import CheckboxField from 'components/core/CheckboxField';
import SelectField from 'components/core/SelectField';
import { AESelectOptionField } from 'components/core/SelectOptionField';
import ClientAsyncSelectField from 'components/core/ClientAsyncSelectField';
import { CountrySelectOptionField } from 'components/core/SelectOptionField';

const CustomerDetail = (props) => {

  const {
    isLoading,
    customer,
    handleSubmit,
    handleUpdate,
  } = props;

  console.log(customer);
  return (
    <div>
      <Content.Body>
        <Form
          noValidate
          autoComplete="off">
          <Box
            className="box-widget"
            loading={isLoading}>
            <Box.Body>
              <Field
                name="username"
                label="Username"
                type="text"
                validate={[
                  validators.required,
                  validators.username,
                  validators.maxLength150
                ]}
                isRequired={true}
                component={CharField.Labeled} />
              <Field
                name="email"
                label="Email"
                type="email"
                validate={[
                  validators.required,
                  validators.email
                ]}
                isRequired={true}
                component={CharField.Labeled} />
            <CountrySelectOptionField />
            <AESelectOptionField
              isRequired={true}
              validate={[validators.required]} />
            <Field
              name="language"
              label="Language"
              validate={[validators.required]}
              isRequired={true}
              options={[{label: "Japanese", value: "ja-JP"}, {label: "English", value: "en-US"}]}
              component={SelectField.Labeled} />
            <Field
              name="to"
              label="TO"
              type="text"
              validate={[
                validators.maxLength50
              ]}
              component={CharField.Labeled} />
            <Field
                name="client"
                label="Client"
                validate={[validators.required]}
                isRequired={true}
                component={ClientAsyncSelectField.Labeled} />
            <FormGroup>
                <ControlLabel>Options</ControlLabel>
                <Field
                name="isActive"
                label="is Active"
                className="mt-3 mb-3"
                component={CheckboxField.Labeled} />
            </FormGroup>
            </Box.Body>
            <Box.Footer>
              <Button
                  type="button"
                  bsStyle="success"
                  bsSize="sm"
                  onClick={handleSubmit(values => handleUpdate(values))}>
                  <FontAwesome name="refresh" className="mr-5" />Update
              </Button>
            </Box.Footer>
          </Box>
        </Form>
      </Content.Body>
    </div>
  );
}

export default reduxForm({
  enableReinitialize: true
})(CustomerDetail);

