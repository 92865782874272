import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CLIENT_LIST } from 'misc/constants';
import { listRequest } from 'actions/clients';
import { getClients, getClientListCurrentPage, getClientListPageSize } from 'selectors/lists';
import { isClientListLoading } from 'selectors/loading';
import { getClientListKeyword } from 'selectors/filters';
import { setFilter } from 'actions/filters'
import PureRenderComponent from 'components/core/PureRenderComponent';
import ClientList from './presenter';


class ClientListContainer extends PureRenderComponent {

  componentDidMount() {
    const params = { page: this.props.currentPage };
    this.props.listRequest(params);
  }

  handleKeywordChange = (keyword) => {
    this.props.setFilter(CLIENT_LIST, 'keyword', keyword)
  }

  handlePageSelect = (page) => {
    const params = { keyword: this.props.keyword, page };
    this.props.listRequest(params);
  }

  handleSubmit = () => {
    const params = { keyword: this.props.keyword, page: 1 };
    this.props.listRequest(params);
  }

  render() {
    return (
      <ClientList
        {...this.props}
        handleKeywordChange={this.handleKeywordChange}
        handlePageSelect={this.handlePageSelect}
        handleSubmit={this.handleSubmit} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: getClients(state),
    keyword: getClientListKeyword(state),
    isLoading: isClientListLoading(state),
    pageSize: getClientListPageSize(state),
    currentPage: getClientListCurrentPage(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest,
    setFilter
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientListContainer);