import { schema } from 'normalizr';
import { userSchema } from './users';
import { clientSchema } from './clients';
import { countrySchema } from './core';

export const customerSchema = new schema.Entity('customers', {
  user: userSchema,
  ae: userSchema,
  client: clientSchema,
  country: countrySchema,
});
