import React from "react";
import * as PropTypes from 'prop-types';
import './taTable.css';

export const TaTableCellPropTypes = {
  className: PropTypes.string,
  flex: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  noWrapper: PropTypes.bool
};

export const TaTableCell = (props) => {

  const {
    flex,
    align,
    valign,
    color,
    width,
    className,
    noWrapper,
    children,
    ...attributes
  } = props;

  const baseClass = 'ta-table-cell';
  let classNames = [baseClass];
  if (align) {
    classNames.push(baseClass + '--' + align);
  }
  if (valign) {
    classNames.push(baseClass + '--valign-' + valign);
  }
  if (color) {
    classNames.push(baseClass + '--' + color);
  }
  if (flex) {
    if (typeof attributes.style === 'undefined') {
      attributes.style = {};
    }
    attributes.style.flex = flex;
  }

  if (width) {
    if (typeof attributes.style === 'undefined') {
      attributes.style = {};
    }

    const isString = (typeof width === 'string');
    const isPercent = (isString && width.includes('%'));
    const isPx = (isString && !isPercent && width.includes('px'));
    const unit = (isPercent || isPx) ? '' : 'px';

    attributes.style.flexGrow = '0';
    attributes.style.flexShrink = '0';
    attributes.style.flexBasis = width + unit;
    attributes.style.width = width + unit;

  }

  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} {...attributes}>
      {(!!noWrapper) ? children : <div>{children}</div>}
    </div>
  );

};

TaTableCell.propTypes = TaTableCellPropTypes;

export default TaTableCell;
