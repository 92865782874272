import React from 'react'
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import * as validators from 'misc/validators';
import { getAssigneeGroupOptions } from 'selectors/options';
import CheckboxGroupField from 'components/core/CheckboxGroupField';
import PureRenderComponent from 'components/core/PureRenderComponent';


class JobAdditionalCountRolesField extends PureRenderComponent {

  render() {
    const {
      additionalCount,
      assigneeGroupOptions
    } = this.props;

    return additionalCount > 0 && (
      <Field
        name="additionalCountRoles"
        label="Roles for Additional count"
        validate={[
          validators.required,
          validators.minLength1
        ]}
        options={assigneeGroupOptions}
        isRequired={true}
        component={CheckboxGroupField.Labeled} />
    )
  }
}


const mapStateToProps = (state, props) => {
  const form = props.formName;
  const formSelector = formValueSelector(form);
  const additionalCount = formSelector(state, 'additionalCount');
  return {
    additionalCount,
    assigneeGroupOptions: getAssigneeGroupOptions(state)
  }
}

export default connect(
  mapStateToProps
)(JobAdditionalCountRolesField);


