import React from 'react';
import { DELIVERY_DETAIL } from 'misc/constants';
import { LinkFieldCellRenderer } from 'misc/cellRenderers';
import { dateFormatter, datetimeFormatter, currencyFormatter } from 'misc/cellFormatters';
import ListTable from 'components/core/ListTable';
import PureRenderComponent from 'components/core/PureRenderComponent';


class JobdDeliveryListTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'No.',
          field: 'number',
          pinned: 'left',
          cellClass: 'ag-jobNumber',
          lockPinned: true,
          lockPosition: true,
          width: 140,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: DELIVERY_DETAIL,
          },
        },
        {
          headerName: 'Client',
          field: 'client.name',
          width: 175,
        },
        {
          headerName: 'Deadline',
          field: 'deadline',
          width: 120,
          valueFormatter: datetimeFormatter
        },
        {
          headerName: 'AE Subject',
          field: 'aeSubject',
          width: 320,
        },
        {
          headerName: 'Service',
          field: 'service.name',
          width: 80,
        },
        {
          headerName: 'Lv',
          field: 'level',
          width: 40,
        },
        {
          headerName: 'Quotation',
          field: 'quotation',
          width: 110,
          filter: 'agNumberColumnFilter',
          valueFormatter: currencyFormatter
        },
        {
          headerName: 'Receive Date',
          field: 'receiveDate',
          width: 140,
          valueFormatter: dateFormatter,
        },
        {
          headerName: 'Coordinator',
          field: 'coordinator.name',
          width: 120,
        }
      ]
    }
  }

  render() {
    return (
      <ListTable
        columnDefs={this.state.columnDefs}
        rowData={this.props.jobs}
        deltaRowDataMode={true}
        getRowNodeId={data => data.id} />
    );
  }
}

export default JobdDeliveryListTable;
