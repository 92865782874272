import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { totraListRequest } from 'actions/jobs/managers';
import { selectTab } from 'actions/tabs';
import { getToTraJobs } from 'selectors/lists';
import { getJobToTraListCurrentTab } from 'selectors/tabs';
import { isToTraListLoading } from 'selectors/loading';
import { TOTRA_LIST } from 'misc/constants';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobToTraList from './presenter';


class JobToTraListContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.totraListRequest();
  }
  
  handleTabSelect = (tab) => {
    this.props.selectTab(TOTRA_LIST, tab);
  }

  render() {
    return (
      <JobToTraList
        {...this.props}
        handleTabSelect={this.handleTabSelect} />
    )
  }
}


const mapStateToProps = (state) => {
  const {
    validatingJobs,
    sentToJobs,
  } = getToTraJobs(state);
  
  return {
    validatingJobs,
    sentToJobs,
    currentTab: getJobToTraListCurrentTab(state),
    isLoading: isToTraListLoading(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    totraListRequest,
    selectTab
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobToTraListContainer);
