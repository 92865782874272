import React from "react";
import * as PropTypes from "prop-types";
import {TaMhtEditorSegmentsGroupItems} from "components/mht/MHTEditor/TaMhtEditorSegmentsGroupItems";
import segmentStyles from "components/mht/MHTEditor/TaMhtEditorSegment.module.css";

export class TaMhtEditorSegmentsGroup extends React.Component {

  static propTypes = {

    scrollContainerRef: PropTypes.object.isRequired,

    items: PropTypes.array.isRequired,
    mutations: PropTypes.object.isRequired,
    comments: PropTypes.object.isRequired,
    selectedSegment: PropTypes.object.isRequired,

    isEditSourceEnabled: PropTypes.bool.isRequired,
    userRole: PropTypes.string.isRequired,

    timeLoaded: PropTypes.number.isRequired,
    timeMutated: PropTypes.number.isRequired,
    timeSearched: PropTypes.number.isRequired,
    timeFiltered: PropTypes.number.isRequired,
    timeCommented: PropTypes.number.isRequired,

    getBackgroundColor: PropTypes.func.isRequired,
    pickSegmentProperty: PropTypes.func.isRequired,

    onRowSelect: PropTypes.func.isRequired,
    onSourceFocus: PropTypes.func.isRequired,
    onSourceBlur: PropTypes.func.isRequired,
    onSourceChange: PropTypes.func.isRequired,
    onTargetFocus: PropTypes.func.isRequired,
    onTargetChange: PropTypes.func.isRequired,

    cellWidthIndex: PropTypes.number.isRequired,
    cellWidthStatus: PropTypes.number.isRequired,
    cellGroupWidth: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.groupRef = null;
    this.scrollWatch = null;
    this.throttle = null;
    this.groupRef = React.createRef();

    this.state = {
      isVisible: false,
    //  height: 0
    }

    this.handleScoll = (event) => {
      if (this.throttle === null) {
        this.throttle = setTimeout(this.setVisibility, 100);
      }
    }

    this.checkVisibility = (parentHeight, scrollTop) => {

      const groupRef = this.groupRef.current;
      const offsetTop = groupRef.offsetTop;
      const height = groupRef.clientHeight;
      const margin = (parentHeight) ? (parentHeight * 2) + 360 : 360;

      const isVisibleTop = (
        (parentHeight + margin) >= (offsetTop - scrollTop) &&
        offsetTop >= (scrollTop - margin)
      );

      const isVisibleBottom = (
        parentHeight >= (offsetTop + height - scrollTop - margin) &&
        (offsetTop + height) >= (scrollTop - margin)
      );

      const isCenter = (
        !isVisibleTop &&
        !isVisibleBottom &&
        offsetTop < scrollTop &&
        parentHeight < (offsetTop + height - scrollTop)
      );

      return (isVisibleTop || isVisibleBottom || isCenter);

    };

    this.setVisibility = () => {
      this.throttle = null;

      const scrollRef = props.scrollContainerRef; // event.target
      const scrollTop = scrollRef.scrollTop;
      const scrollHeight = scrollRef.clientHeight;

      // const groupRef = this.groupRef.current;
      // const groupHeight = groupRef.clientHeight;
      const isVisible = this.checkVisibility(scrollHeight, scrollTop);

      // if (props.index === 1) {
      //   console.log('- '+props.index+' -')
      //   console.log('scrollTop',scrollTop)
      //   console.log('height - scrollRef', scrollHeight)
      //   console.log('height - groupRef', groupHeight)
      //   console.log('isVisible - '+ props.index,isVisible)
      // }

      if (this.state.isVisible !== isVisible) {
        this.setState({
          isVisible: isVisible,
          // height: (!isVisible) ? groupHeight : 0
        })
      }
    };

  }

  componentDidMount() {

    if (
      !this.props.index &&
      !this.props.scrollContainerRef.scrollTop
    ) {
      this.setState({isVisible: true, height: 0})
    } else {
      this.throttle = setTimeout(this.setVisibility, 10*this.props.index);
    }

    if (typeof this.props.scrollContainerRef.addEventListener === 'function') {
      this.props.scrollContainerRef.addEventListener('scroll', this.handleScoll);
    }

  }

  componentWillUnmount() {
    if (typeof this.props.scrollContainerRef.removeEventListener === 'function') {
      this.props.scrollContainerRef.removeEventListener('scroll', this.handleScoll);
    }
    if (this.throttle !== null) {
      clearTimeout(this.throttle);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.selectedSegment.time !== nextProps.selectedSegment.time ||
      this.props.timeLoaded !== nextProps.timeLoaded ||
      this.props.timeMutated !== nextProps.timeMutated ||
      this.props.timeSearched !== nextProps.timeSearched ||
      this.props.timeFiltered !== nextProps.timeFiltered ||
      this.props.timeCommented !== nextProps.timeCommented ||
      this.props.isEditSourceEnabled !== nextProps.isEditSourceEnabled ||
      this.state.isVisible !== nextState.isVisible ||
      (
        this.props.highlight.time !== nextProps.highlight.time &&
        !!nextState.isVisible
      )
    );
  }

  render() {

    const {
      index,
      chunkSize,
      ...props
    } = this.props;

    const rowIndexModifier = (index) ? chunkSize * index + 1 : 1;

    return (
      <div
        ref={this.groupRef}
      >{
        <TaMhtEditorSegmentsGroupItems
          isVisible={this.state.isVisible}
          rowIndexModifier={rowIndexModifier}
          {...props}
        />
      }
      </div>
    );
  }
}
