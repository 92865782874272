import React from 'react';
import { Content, Box } from 'reactjs-admin-lte';
import Pagination from 'components/core/Pagination';
import TranslatorJobHistoryForm from './TranslatorJobHistoryForm';
import EmployeeJobHistoryForm from './EmployeeJobHistoryForm';
import EmployeeJobHistoryTable from './EmployeeJobHistoryTable';


const EmployeeJobHistory = (props) => {
  const {
    form,
    roleKey,
    initialValues,
    isLoading,
    jobs,
    roleOptions,
    currentPage,
    pageSize,
    handlePageSelect,
    isAuthorizedEmployee,
    handleSearch,
    handleReset,
    handleCsvExport,
    handleTabOpen
  } = props;

  const HistoryForm = isAuthorizedEmployee
    ? EmployeeJobHistoryForm　
    : TranslatorJobHistoryForm

  
  return (
    <Content.Body>
      <HistoryForm
        form={form}
        isLoading={isLoading}
        initialValues={initialValues}
        roleKey={roleKey}
        handleSearch={handleSearch}
        handleReset={handleReset}
        handleCsvExport={handleCsvExport}
        roleOptions={roleOptions} />
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body noPadding={true}>
          <EmployeeJobHistoryTable
            jobs={jobs}
            roleKey={roleKey}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
        <Box.Footer>
          <Pagination
            currentPage={currentPage}
            totalPages={pageSize}
            onChange={handlePageSelect} />
        </Box.Footer>
      </Box>
    </Content.Body>
  );
}

export default EmployeeJobHistory;