import React from "react";
import * as PropTypes from 'prop-types';
import './taTable.css';

export class TaTableRow extends React.PureComponent {

  render() {
    const {
      className,
      rowIndex,
      isSelected,
      ...attributes
    } = this.props;

    const baseClass = 'ta-table-row';
    let classNames = [baseClass];
    if (rowIndex) {
      if (rowIndex % 2 === 0) {
        classNames.push(baseClass + '--even');
      } else {
        classNames.push(baseClass + '--odd');
      }
    }
    if (isSelected) {
      classNames.push(baseClass + '--selected');
    }
    if (className) {
      classNames.push(className);
    }

    return (
      <div className={classNames.join(' ')} {...attributes}>{this.props.children}</div>
    )
  }

}

TaTableRow.propTypes = {
  rowIndex: PropTypes.number,
  isSelected: PropTypes.bool
};
