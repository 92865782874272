import React from "react";
import {TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";
import {Form, Modal} from "react-bootstrap";
import {change, Field, reduxForm, reset} from "redux-form";
import {connect} from "react-redux";
import {isWordListDetailAttributesModalLoading} from "selectors/loading";
import {bindActionCreators} from "redux";
import {
  TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_FORM,
  TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL
} from "misc/constants";
import Spinner from "components/core/Spinner";
import {connectModal} from "redux-modal";
import * as validators from 'misc/validators';
import ClientAsyncSelectField from "components/core/ClientAsyncSelectField";
import JobNumberAsyncSelectField from "components/core/JobNumberAsyncSelectField";

class TranslationMemoryAttributesModal extends React.Component {

  hide = () => {
    this.props.handleHide();
    this.props.reset(TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_FORM);
  };

  render() {
    const {
      show,
      handleSubmit,
      onSubmit,
      isLoading,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={this.hide}
        animation={true}
        backdrop="static">
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit((values => onSubmit(values)))}
          onReset={this.hide}
        >
          <TaModalHeader
            label={'Edit Attributes'}
            onCancel={this.hide}
          />
          <TaModalContent>
            <div className="p-15">
              <Field
                name="client"
                label="Client"
                validate={[
                  validators.required
                ]}
                isRequired={true}
                component={ClientAsyncSelectField.Labeled}/>
              <Field
                name="jobs"
                label="Job"
                multi={true}
                component={JobNumberAsyncSelectField.Labeled}/>
            </div>
            <Spinner isLoading={isLoading}/>
          </TaModalContent>
          <TaModalFooter>
            <TaButton
              type="reset"
              icon={'clear'}
              label={'Cancel'}
            />
            <TaButton
              type="submit"
              icon={'check'}
              iconAlign={'right'}
              label={'Save'}
            />
          </TaModalFooter>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {

  const key = TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL;
  const hasInitialValues = (
    state.modal &&
    state.modal[key] &&
    state.modal[key].props &&
    state.modal[key].props.initialValues
  );

  // const _denormalized= denormalize(initialValues, schemas.memorySearchSchema, getEntities(state));
  // console.log('mapStateToProps - denormalizeItem', _denormalized);

  let denormalized = {};

  if (hasInitialValues) {
    const initialValues = state.modal[key].props.initialValues;

    if (
      initialValues.client &&
      initialValues.client.id
    ) {
      denormalized.client = initialValues.client.id;
    }

    [
      // 'clients',
      'jobs'
    ].forEach(key => {
      if (initialValues[key]) {
        denormalized[key] = initialValues[key].map(item => item.id);
      }
    });
  }

  return {
    initialValues: denormalized,
    isLoading: isWordListDetailAttributesModalLoading(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    change,
    reset
  }, dispatch);
};

TranslationMemoryAttributesModal = connectModal({
  name: TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL
})(TranslationMemoryAttributesModal);

TranslationMemoryAttributesModal = reduxForm({
  form: TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_FORM,
  enableReinitialize: true
})(TranslationMemoryAttributesModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationMemoryAttributesModal);
