import React from 'react';
import { getFieldTotalCount, getDetailTabConfigByName } from 'misc/utils';
import { DELIVERY_DETAIL } from 'misc/constants';
import { CheckboxCellRenderer } from 'misc/cellRenderers';
import { dateFormatter, datetimeFormatter, currencyFormatter } from 'misc/cellFormatters';
import ListTable from 'components/core/ListTable';
import PureRenderComponent from 'components/core/PureRenderComponent';


const InvoiceIDCellRenderer = (props) => {
  const { value, data, colDef } = props;
  const { handleTabOpen } = colDef.cellRendererParams;
  if (value && data && data.jobs && data.jobs.length > 0) {
    const job = data.jobs[0];
    const tabConfig = getDetailTabConfigByName(DELIVERY_DETAIL, job.id, job.number, { id: job.id });
    return <a onClick={() => handleTabOpen(tabConfig)}>{value}</a>
  }
  return null;
}


class InvoiceListTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      invoiceSum: 0,
      columnDefs: [
        {
          headerName: 'Invoice ID',
          field: 'group',
          pinned: 'left',
          lockPinned: true,
          lockPosition: true,
          width: 120,
          cellRendererFramework: InvoiceIDCellRenderer,
          cellRendererParams: { handleTabOpen: this.props.handleTabOpen },
        },
        {
          headerName: 'No.',
          field: 'seqNo',
          filter: 'agNumberColumnFilter',
          width: 80,
        },
        {
          headerName: 'Client',
          width: 130,
          valueGetter: (params) => {
            return params.data.jobs && params.data.jobs.length > 0
              ? params.data.jobs[0].client.name
              : ''
          }
        },
        {
          headerName: 'Invoice',
          field: 'invoice',
          filter: 'agNumberColumnFilter',
          valueFormatter: currencyFormatter,
          width: 190,
        },
        {
          headerName: 'Receive Date',
          field: 'receiveDate',
          valueFormatter: dateFormatter,
          width: 150,
        },
        {
          headerName: 'Deadline',
          field: 'deadlineDate',
          valueFormatter: datetimeFormatter,
          width: 150,
        },
        {
          headerName: 'checked',
          field: 'checked',
          suppressFilter: true,
          cellRendererFramework: CheckboxCellRenderer,
          cellRendererParams: { handleCheck: this.props.handleCheck },
          width: 100,
        }
      ]
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.invoices !== this.props.invoices) {
      this.setState({ invoiceSum: getFieldTotalCount(this.props.invoices, 'invoice') })
    }
  }

  handleFilterChanged = (event) => {
    let sum = 0
    event.api.forEachNodeAfterFilter(node => {
      sum += node.data.invoice
    });
    this.setState({ invoiceSum: sum });
  }

  getPinnedBottomRowData = () => ([{
    'invoice': this.state.invoiceSum,
  }]);

  render() {
    return (
      <ListTable
        columnDefs={this.state.columnDefs}
        rowData={this.props.invoices}
        suppressRowClickSelection={true}
        onFilterChanged={this.handleFilterChanged}
        pinnedBottomRowData={this.getPinnedBottomRowData()}
        wrapperHeight="78vh" />
    );
  }
}

export default InvoiceListTable;
