import React from 'react';
import { Content, Box } from 'reactjs-admin-lte/lib';
import SalesTargetFilter from './SalesTargetFilter';
import SalesTargetTable from './SalesTargetTable';


const SalesTarget = (props) => {

  const {
    currentYear,
    handlePrev,
    handleNext,
    handleCreate,
    handlePatch,
    isLoading,
    salesTargets,
    aeUsers,
    isSuperuser
  } = props;

  return (
    <Content.Body>
      <SalesTargetFilter
        currentYear={currentYear}
        handlePrev={handlePrev}
        handleNext={handleNext} />
      <Box
        className="box-widget saleTargetPage mb-0"
        loading={isLoading}>
        <Box.Body noPadding={true}>
          <SalesTargetTable
            result={salesTargets}
            aeUsers={aeUsers}
            editable={isSuperuser}
            handleCreate={handleCreate}
            handlePatch={handlePatch} />
        </Box.Body>
      </Box>
    </Content.Body>
  )
}

export default SalesTarget;
