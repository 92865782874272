import React from 'react';
import { Table } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import DateFormatter from 'components/core/DateFormatter'


const JobDetailBasicInfo = (props) => {

  const {
    isLoading,
    job
  } = props;

  return (
    <Box
      className="box-widget"
      loading={isLoading}>
      <Box.Header>
        <Box.Title>Basic Information</Box.Title>
        <Box.Tools>
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <Table responsive>
          <tbody>
            <tr>
              <th>Number</th><td>{job.number}</td>
              <th>Status</th><td>{job.status}</td>
            </tr>
            <tr>
              <th>Client</th><td>{job.client && job.client.name}</td>
              <th>Created</th><td><DateFormatter datetime={job.created} /></td>
            </tr>
            <tr>
              <th>Service</th><td>{job.service && job.service.name}</td>
              <th>Source</th><td>{job.sourceCount}</td>
            </tr>
            <tr>
              <th>Deadline</th>
              <td>
                <DateFormatter
                  datetime={job.deadline}
                  isStrict={job.strictDeadline} />
              </td>
              <th>Target</th><td>{job.targetCount}</td>
            </tr>
            <tr>
              <th>Level</th><td>{job.level}</td>
              <th>Additional</th><td>{job.additionalCount}</td>
            </tr>
            <tr>
              <th>Coordinator</th><td>{job.coordinator && job.coordinator.username}</td>
              <th>AE</th><td>{job.ae && job.ae.username}</td>
            </tr>
            <tr>
              <th>Industry</th><td colSpan={3}>{job.industry && job.industry.name}</td>
            </tr>
            <tr>
              <th>Field</th><td colSpan={3}>{job.field && job.field.name}</td>
            </tr>
            <tr>
              <th>Subject</th><td colSpan="3">{job.subject}</td>
            </tr>
            <tr>
              <th>Memo</th><td colSpan="3">{job.memo}</td>
            </tr>
          </tbody>
        </Table>
      </Box.Body>
    </Box>
  );
}

export default JobDetailBasicInfo;