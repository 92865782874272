import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { checkerJobRetrieveRequest } from 'actions/jobs/employees';
import { isCheckerJobDetailLoading } from 'selectors/loading';
import EmployeeJobDetailHOC from './EmployeeJobDetailHOC';
import JobDetail from './presenter';


const mapStateToProps = (state, props) => {
  const { id } = props.params;
  return {
    id,
    roleKey: 'checker',
    isLoading: isCheckerJobDetailLoading(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    retrieveRequest: checkerJobRetrieveRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeJobDetailHOC(JobDetail));