import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import {TaBadge} from "components/taUi/taBadge/taBadge";
import {TaIcon} from "components/taUi/taIcon";
import {TaTooltip} from "components/taUi/taTooltip/TaTooltip";

export const TaButton = (props) => {

  const {
    type,
    label,
    icon,
    iconAlign,
    badge,
    tooltip,
    look,
    color,
    className,
    isLoading,
    active,
    ...attributes
  } = props;

  let classNames = ['ta-button'];
  if (type && type !== 'button') {
    classNames.push('ta-button--' + type);
  }
  if (active) {
    classNames.push('ta-button--active');
  }
  if (props.disabled) {
    classNames.push('ta-button--disabled');
  }
  if (color) {
    classNames.push('ta-button--' + color);
  }
  if (iconAlign) {
    classNames.push('ta-button--icon-' + iconAlign);
  }
  if (isLoading) {
    classNames.push('ta-button--is-loading');
  }
  if (look && look !== 'button') {
    classNames.push('ta-button--' + look);
  }
  if (className) {
    classNames.push(className);
  }

  const inner = (
    <React.Fragment>
      {(icon) && <TaIcon name={icon}/>}
      {(label) && <span>{props.label}</span>}
      {(badge) && <TaBadge value={badge}/>}
    </React.Fragment>
  );

  return (
    <button
      className={classNames.join(' ')}
      type={(type && (type === 'submit' || type === 'reset')) ? type : 'button'}
      {...attributes}
    >{
      (tooltip) ? (
        <TaTooltip
          value={tooltip}
          children={inner}
          className={'ta-button__main'}
        />
      ) : (
        <div
          children={inner}
          className={'ta-button__main'}
        />
      )
    }
    </button>
  );
};

TaButton.propTypes = {
  badge: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  icon: PropTypes.string,
  iconAlign: PropTypes.oneOf(['left', 'right']),
  tooltip: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['button', 'primary', 'submit', 'reset']),
  look: PropTypes.oneOf(['button', 'light', 'tile']),
  color: PropTypes.oneOf(['primary', 'accent', 'warn']),
  active: PropTypes.bool,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ]),
  isLoading: PropTypes.bool,
};
