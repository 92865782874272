import { formValueSelector } from 'redux-form';

export const getDownloadProgress = state => state.core.downloadProgress;
export const getId = (state, id) => id;
export const getFormName = (state, formName) => formName;
export const getJobType = (state, jobType) => jobType;
export const getFormValueSelector = (state, form, value) => {
  const s = formValueSelector(form);
  return s(state, value);
}

export const getIdList = (state, ids) => ids;