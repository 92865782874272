import React from 'react';
import FontAwesome from 'react-fontawesome';
import classNames from 'classnames';
import UserAvatar from 'components/users/UserAvatar';
import { getTabConfigByName } from 'misc/utils';
import { PROFILE } from 'misc/constants';
import DateFormatter from 'components/core/DateFormatter';
import PureRenderComponent from 'components/core/PureRenderComponent';


class HeaderUserMenu extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  toggle = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  }

  handleProfileTabOpen = () => {
    const tabConfig = getTabConfigByName(PROFILE)
    this.props.handleTabOpen(tabConfig);
    this.setState({ open: false });
  }
  
  render() {
    
    const { 
      me,
      logout,
    } = this.props;
    
    const cls = classNames({
      'dropdown': true,
      'user': true,
      'user-menu': true,
      'open': this.state.open
    })

    return (
      <li className={cls}>
        <a onClick={this.toggle}>
          <UserAvatar url={me.avatar} className="user-image" />
          <span className="hidden-xs">{me.username}</span>
        </a>
        <ul className="dropdown-menu">
          <li className="user-header">
            <UserAvatar url={me.avatar} className="img-circle" />
            <p>
              {me.username}
              <small>
                <span className="mr-5">Member since</span>
                <DateFormatter datetime={me.dateJoined} format="MMM. YYYY" />
              </small>
            </p>
          </li>
          <li className="user-footer">
            <div className="pull-left">
              <a className="btn btn-default btn-flat" onClick={this.handleProfileTabOpen}>
                <FontAwesome name="id-card" className="mr-5" />Profile
              </a>
            </div>
            <div className="pull-right">
              <a className="btn btn-default btn-flat" onClick={logout}>
                <FontAwesome name="sign-out" className="mr-5" />Sign out
              </a>
            </div>
          </li>
        </ul>
      </li>
    );
  }
}


export default HeaderUserMenu;
