import axios from 'axios';

const ClientApi = {
  create: (values) => {
    return axios.post('clients/', values);
  },
  list: (params) => {
    return axios.get('clients/', { params });
  },
  retrieve: (id) => {
    return axios.get(`clients/${id}/`);
  },
  update: (id, values) => {
    return axios.put(`clients/${id}/`, values);
  },
  remove: (id) => {
    return axios.delete(`clients/${id}/`);
  },
  preferableUserList: (clientId) => {
    return axios.get(`clients/${clientId}/preferable/`);
  },
  preferableUserAdd: (values) => {
    return axios.post('preferable/', values)
  },
  preferableUserRemove: (id) => {
    return axios.delete(`preferable/${id}/`);
  },
  ngUserList: (clientId) => {
    return axios.get(`clients/${clientId}/ng/`);
  },
  ngUserAdd: (values) => {
    return axios.post('ng/', values)
  },
  ngUserRemove: (id) => {
    return axios.delete(`ng/${id}/`);
  },
  websiteList: (id) => {
    return axios.get(`clients/${id}/websites/`);
  },
  websiteAdd: (values) => {
    return axios.post(`websites/`, values);
  },
  websiteRemove: (id) => {
    return axios.delete(`websites/${id}`);
  },
  commonReferenceList: (clientId) => {
    return axios.get(`clients/${clientId}/references/`);
  },
  wordlist: (clientId) => {
    return axios.get(`clients/${clientId}/wordlists/`);
  },
  autocomplete: (params) => {
    return axios.get(`clients/autocomplete/`, { params });
  },
}

export default ClientApi;
