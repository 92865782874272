import React from 'react';
import { Content, Box } from 'reactjs-admin-lte';
import { StickyContainer, Sticky } from 'react-sticky';
import Pagination from 'components/core/Pagination';
import JobQuickEdit from 'components/jobs/managers/JobQuickEdit';
import JobSearchForm from './JobSearchForm';
import JobSearchTable from './JobSearchTable';


const JobSearch = (props) => {
  const {
    form,
    jobs,
    quickEditName,
    currentPage,
    pageSize,
    initialValues,
    isLoading,
    isCoordinator,
    handleSearch,
    handleReset,
    handlePageSelect,
    handleSelectionChange,
    handleTabOpen
  } = props;
  
  return (
    <StickyContainer>
      <Content.Body>
        <JobSearchForm
          form={form}
          initialValues={initialValues}
          handleSearch={handleSearch}
          handleReset={handleReset} />
        <Box
          className="box-widget mb-0"
          loading={isLoading}>
          <Box.Body noPadding={true}>
            <JobSearchTable
              jobs={jobs}
              handleSelectionChange={handleSelectionChange}
              isCoordinator={isCoordinator}
              handleTabOpen={handleTabOpen} />
          </Box.Body>
          <Box.Footer>
            <Pagination
              currentPage={currentPage}
              totalPages={pageSize}
              onChange={handlePageSelect} />
          </Box.Footer>
        </Box>
        { isCoordinator &&
          <Sticky topOffset={-100}>
            {({ style }) => {
              return (
                <div style={{
                  ...style,
                  top: 'inherit',
                  bottom: 0,
                  zIndex: 10
                }}>
                  <JobQuickEdit name={quickEditName} />
                </div>
              );
            }}
          </Sticky>
        }
      </Content.Body>
    </StickyContainer>
  );
}

export default JobSearch;