import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';
import { MULTIPLE_FILE_UPLOAD_MODAL } from 'misc/constants';
import { referenceUploadRequest, referenceRemoveRequest, referenceDownloadRequest } from 'actions/documents'
import { getReferencesByForm } from 'selectors/lists';
import PureRenderComponent from 'components/core/PureRenderComponent';
import Reference from './presenter';


class ReferenceContainer extends PureRenderComponent {
  
  showUploadModal = () => {
    this.props.show(MULTIPLE_FILE_UPLOAD_MODAL, {
      title: 'Reference',
      handleUpload: this.handleUpload
    })
  }

  handleUpload = (files) => {
    this.props.referenceUploadRequest(this.props.form, files);
  }

  handleDownload = (document) => {
    this.props.referenceDownloadRequest(document);
  }

  handleRemove = (document, idx) => {
    this.props.referenceRemoveRequest(this.props.form, document, idx);
  }
  
  render() {
    return (
      <Reference
        {...this.props}
        showUploadModal={this.showUploadModal}
        handleDownload={this.handleDownload}
        handleRemove={this.handleRemove} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const form = props.form;
  return {
    documents: getReferencesByForm(state, form, 'references')
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    referenceUploadRequest,
    referenceRemoveRequest,
    referenceDownloadRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferenceContainer)
