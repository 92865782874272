import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware, { END } from 'redux-saga';
import rootReducer from 'reducers';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['settings']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(sagaMiddleware)),
  );
  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  const persistor = persistStore(store)  
  return { store, persistor };
}
