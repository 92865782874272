import React from 'react'
import FontAwesome from 'react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Button, Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import * as validators from 'misc/validators';
import { PASSWORD_CHANGE_MODAL, PASSWORD_CHANGE_FORM } from 'misc/constants';
import { passwordChangeRequest } from 'actions/auth';
import { isChangePasswordModalLoading } from 'selectors/loading';
import Spinner from 'components/core/Spinner';
import CharField from 'components/core/CharField';
import PureRenderComponent from 'components/core/PureRenderComponent';
import validate from './validate';



class ChangePasswordModal extends PureRenderComponent {

  render() {
    const {
      show,
      handleHide,
      isLoading,
      handleSubmit,
      passwordChangeRequest
    } = this.props;
    
    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Form
          noValidate
          autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              name="currentPassword"
              label="Current Password"
              type="password"
              validate={[validators.required]}
              isRequired={true}
              component={CharField.Labeled} />
            <Field
              name="password"
              label="New Password"
              type="password"
              validate={[validators.required]}
              isRequired={true}
              component={CharField.Labeled} />
            <Field
              name="passwordConfirm"
              label="New Password (again)"
              type="password"
              validate={[validators.required]}
              isRequired={true}
              component={CharField.Labeled} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pull-left"
              bsSize="sm"
              type="button"
              onClick={handleHide}>
              <FontAwesome name="close" className="mr-5" />Close
            </Button>
            <Button
              bsStyle="primary"
              bsSize="sm"
              type="button"
              onClick={handleSubmit(values => passwordChangeRequest(values))}>
              <FontAwesome name="paper-plane-o" className="mr-5" />Submit
            </Button>
          </Modal.Footer>
        </Form>
        <Spinner isLoading={isLoading} />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: isChangePasswordModalLoading(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    passwordChangeRequest,
  }, disptach)
}

ChangePasswordModal = connectModal({
  name: PASSWORD_CHANGE_MODAL 
})(ChangePasswordModal);

ChangePasswordModal = reduxForm({
  form: PASSWORD_CHANGE_FORM,
  validate
})(ChangePasswordModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordModal);