import * as types from 'actionTypes/jobs/common';

// Job Sourcefile List =========================================================
export const jobSourcefileListRequest = (id) => ({
  type: types.JOB_SOURCEFILE_LIST.REQUEST,
  payload: { id }
});
export const jobSourcefileListSuccess = () => ({
  type: types.JOB_SOURCEFILE_LIST.SUCCESS
});
export const jobSourcefileListFailure = (error) => ({
  type: types.JOB_SOURCEFILE_LIST.FAILURE,
  payload: { error }
});

// Job Reference List =========================================================
export const jobReferenceListRequest = (id) => ({
  type: types.JOB_REFERENCE_LIST.REQUEST,
  payload: { id }
});
export const jobReferenceListSuccess = () => ({
  type: types.JOB_REFERENCE_LIST.SUCCESS
});
export const jobReferenceListFailure = (error) => ({
  type: types.JOB_REFERENCE_LIST.FAILURE,
  payload: { error }
});

// Job Common Reference List ===================================================
export const jobCommonReferenceListRequest = (group) => ({
  type: types.JOB_COMMON_REFERENCE_LIST.REQUEST,
  payload: { group }
});
export const jobCommonReferenceListSuccess = () => ({
  type: types.JOB_COMMON_REFERENCE_LIST.SUCCESS
});
export const jobCommonReferenceListFailure = (error) => ({
  type: types.JOB_COMMON_REFERENCE_LIST.FAILURE,
  payload: { error }
});

// Job Clent Reference List ====================================================
export const jobClientReferenceListRequest = (id) => ({
  type: types.JOB_CLIENT_REFERENCE_LIST.REQUEST,
  payload: { id }
});
export const jobClientReferenceListSuccess = () => ({
  type: types.JOB_CLIENT_REFERENCE_LIST.SUCCESS
});
export const jobClientReferenceListFailure = (error) => ({
  type: types.JOB_CLIENT_REFERENCE_LIST.FAILURE,
  payload: { error }
});


// Job Message List ============================================================
export const jobMessageListRequest = (id) => ({
  type: types.JOB_MESSAGE_LIST.REQUEST,
  payload: { id }
});
export const jobMessageListSuccess = () => ({
  type: types.JOB_MESSAGE_LIST.SUCCESS
});
export const jobMessageListFailure = (error) => ({
  type: types.JOB_MESSAGE_LIST.FAILURE,
  payload: { error }
});

// Job Message Create ==========================================================
export const jobMessageCreateRequest = (id, values) => ({
  type: types.JOB_MESSAGE_CREATE.REQUEST,
  payload: { id, values }
});
export const jobMessageCreateSuccess = () => ({
  type: types.JOB_MESSAGE_CREATE.SUCCESS
});
export const jobMessageCreateFailure = (error) => ({
  type: types.JOB_MESSAGE_CREATE.FAILURE,
  payload: { error }
});

// Job Message Update ==========================================================
export const jobMessageUpdateRequest = (id, messageId, values) => ({
  type: types.JOB_MESSAGE_UPDATE.REQUEST,
  payload: { id, messageId, values }
});
export const jobMessageUpdateSuccess = () => ({
  type: types.JOB_MESSAGE_UPDATE.SUCCESS
});
export const jobMessageUpdateFailure = (error) => ({
  type: types.JOB_MESSAGE_UPDATE.FAILURE,
  payload: { error }
});

// Job Message Remove ==========================================================
export const jobMessageRemoveRequest = (id, message) => ({
  type: types.JOB_MESSAGE_REMOVE.REQUEST,
  payload: { id, message }
});
export const jobMessageRemoveSuccess = () => ({
  type: types.JOB_MESSAGE_REMOVE.SUCCESS
});
export const jobMessageRemoveFailure = (error) => ({
  type: types.JOB_MESSAGE_REMOVE.FAILURE,
  payload: { error }
});