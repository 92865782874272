import React from 'react';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';

const OptionResetButton = (props) => {
  const { label, reset, onClick } = props;
  
  const cls = classNames({
    'btn': true,
    'btn-block': true,
    'btn-sm': true,
    'btn-default': reset === false,
    'btn-success': reset === true
  })
  
  return (
    <Button
      bsClass={cls}
      onClick={onClick}>
      {label}
    </Button>
  )  
}

export default OptionResetButton;