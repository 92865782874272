import { schema } from 'normalizr';
import { serviceSchema } from './core';
import { customerSchema } from './customers';
import { jobSchema } from './jobs';
import { userSchema } from './users';

export const quoteSchema = new schema.Entity("quotes", {
  customer: customerSchema,
  service: serviceSchema,
  job: jobSchema,
});

export const unsupportedOrderSchema = new schema.Entity("unsupportedOrders", {
  customer: customerSchema,
  service: serviceSchema,
  ae: userSchema,
})