import { connect } from 'react-redux';
import { getTranslatorOptions } from 'selectors/options';
import SelectOptionField from './SelectOptionField';


const mapStateToProps = (state) => {
  return {
    name: 'translator',
    label: 'Translator',
    options: getTranslatorOptions(state),
  }
}

export default connect(
  mapStateToProps,
)(SelectOptionField);

