import * as types from 'actionTypes/auth';
import API from 'services/AuthApi';

const initialState = {
  isAuthenticated: API.hasAuthToken(),
  isRefreshed: false,
  me: null,
}

export default function (state = initialState, action) {
  switch (action.type) {

    case types.AUTHORIZE:
      return authorize(state, action);

    case types.REFRESH.SUCCESS:
      return refresh(state, action);

    case types.REFRESH.FAILURE:
    case types.LOGOUT:
      return logout(state, action);

    default:
      return state;
  }
}

const authorize = (state, action) => {
  const { me } = action.payload;
  return {
    ...state,
    me,
    isAuthenticated: true,
  }
}

const refresh = (state, action) => {
  return { 
    ...state,
    isRefreshed: true
  };
}

const logout = (state, action) => {
  return {
    ...state,
    me: null,
    isAuthenticated: false,
    isRefreshed: false,
  };
}
