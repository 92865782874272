import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { USER_ACCOUNT_FORM } from 'misc/constants';
import { updateRequest, removeRequest } from 'actions/users';
import { getGroupOptions } from 'selectors/options';
import { isSuperuser } from 'selectors/auth';
import { getUserById } from 'selectors/users';
import { getUserAccountFormInitialValues } from 'selectors/forms';
import { isUserAccountLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import UserAccountForm from './UserAccountForm';


class UserAccountContainer extends PureRenderComponent {

  handleUpdate = (values) => {
    this.props.updateRequest(this.props.form, this.props.userId, values);
  }

  handleRemove = () => {
    this.props.removeRequest(this.props.user);
  }
  
  render() {
    return (
      <UserAccountForm
        {...this.props}
        handleUpdate={this.handleUpdate}
        handleRemove={this.handleRemove} />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.userId;
  const form = `${USER_ACCOUNT_FORM}/${id}`;
  return {
    form,
    initialValues: getUserAccountFormInitialValues(state, id),
    user: getUserById(state, id),
    groupOptions: getGroupOptions(state),
    isLoading: isUserAccountLoading(state, id),
    isSuperuser: isSuperuser(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    updateRequest,
    removeRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAccountContainer)
