import React from 'react';
import { Box } from 'reactjs-admin-lte';
import TooltipIconButton from 'components/core/TooltipIconButton';
import DocumentTable from 'components/documents/DocumentTable';


const JobReference = (props) => {

  const {
    title,
    isLoading,
    files,
    showUploadModal,
    handleDownload,
    handleRemove,
    uploadable,
    removable,
    sizeVisible
  } = props;

  return (
    <Box
      className="box-widget"
      loading={isLoading}>
      <Box.Header border={true}>
        <Box.Title>{title}</Box.Title>
        <Box.Tools>
          { uploadable &&
            <TooltipIconButton
              id="upload-new-reference"
              className="btn-box-tool"
              icon="upload"
              description="Upload Job Specific Reference"
              onClick={showUploadModal} />
          }
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <DocumentTable
          documents={files}
          handleDownload={handleDownload}
          removable={removable}
          sizeVisible={sizeVisible}
          handleRemove={handleRemove} />
      </Box.Body>
    </Box>
  )
}


JobReference.defaultProps = {
  title: 'Job Specific References',
  uploadable: false,
  removable: false
}

export default JobReference;
