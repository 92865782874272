import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getSoftwareByUserId } from 'selectors/users';
import {
  softwareCreateRequest,
  softwareListRequest,
  softwareDeleteRequest
} from 'actions/users';
import { isUserSoftwareLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import UserSoftware from './presenter';


class UserSoftwareContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.softwareListRequest(this.props.userId);
  }

  handleChange = (softwareId, applicationId, checked) => {
    const { userId } = this.props;
    checked
      ? this.props.softwareCreateRequest(userId, { application: applicationId })
      : this.props.softwareDeleteRequest(userId, softwareId)
  }

  render() {
    return (
      <UserSoftware
        {...this.props}
        handleChange={this.handleChange} />
    )
  }
}

const mapStateToProps = (state, props) => {
  const id = props.userId;
  return {
    isLoading: isUserSoftwareLoading(state, id),
    software: getSoftwareByUserId(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    softwareCreateRequest,
    softwareListRequest,
    softwareDeleteRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSoftwareContainer)
