import React from 'react';
import Logo from 'assets/images/logo.svg';

const TaLoader = (props) => {

  const { isLoading } = props;

  return isLoading
    ? (
      <div className="overlay-wrapper">
        <div className="overlay">
          <div className="splash-loader"></div>
          <img className="splash-logo" src={Logo} alt="logo"/>
        </div>
      </div>
    ) : null;

};

export default TaLoader;
