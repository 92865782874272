import { v4 as uuidv4 } from 'uuid';
import orderBy from 'lodash.orderby';
import numeral from 'numeral';
import moment from 'moment';
import {
  tabConfig,
  ASSIGNMENT_ROLE_VALUE_MAP,
  DEFAULT_TABLE_ROW_HEIGHT,
  NESTED_TABLE_ROW_HEIGHT
 } from './config';


export const getTabConfigByName = (name) => {
  return tabConfig[name];
}

export const getDetailTabConfigByName = (name, id, desc, params) => {
  const {
    id: defualtId,
    title: defatultTitle,
    ...rest
  } = tabConfig[name];

  return {
    ...rest,
    id: `${defualtId}/${id}`,
    title: `${defatultTitle}: ${desc}`,
    params
  }
}

export const createAsyncActionTypes = (base) => {
  return ['REQUEST', 'SUCCESS', 'FAILURE'].reduce((acc, type) => {
    acc[type] = `${base}_${type}`
    return acc
  }, {});
}

export const createCreateAction = (type) => ({
  requestFn: (form, values) => ({ type: type.REQUEST, payload: { form, values } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

export const createListAction = (type) => ({
  requestFn: () => ({ type: type.REQUEST }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

export const createRetrieveAction = (type) => ({
  requestFn: (id) => ({ type: type.REQUEST, payload: { id } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

export const createUpdateAction = (type) => ({
  requestFn: (form, id, values) => ({ type: type.REQUEST, payload: { form, id, values } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

export const createPatchAction = (type) => ({
  requestFn: (id, values) => ({ type: type.REQUEST, payload: { id, values } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

export const createSearchAction = (type) => ({
  requestFn: (params) => ({ type: type.REQUEST, payload: { params } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

export const createRemoveAction = (type) => ({
  requestFn: (item) => ({ type: type.REQUEST, payload: { item } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

export const createMonthlyListAction = (type) => ({
  requestFn: (currentMonth) => ({ type: type.REQUEST, payload: { currentMonth } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

export const humanizeBytes = (bytes) => {
  return numeral(bytes).format('0b')
}

export const createMarkup = (html) => {
  return {__html: html};
}


export const getRowHeight = (params) => {
  return params.node.rowPinned
    ? DEFAULT_TABLE_ROW_HEIGHT
    : NESTED_TABLE_ROW_HEIGHT
}


export const saveFile = (data, fileName, contentType) => {
  const blob = new Blob([data], { type: contentType });
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(blob, fileName);
  } else if (window.URL || window.webkitURL) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = fileName;
    a.click();
  }
}

export const getFieldTotalCount = (rows, field) => {
  if (rows === null || rows === undefined) return 0;
  return rows.reduce((a, b) => a + b[field], 0);
}

export const getNotificationOptions = (title, message, autoDismiss = 3) => {
  return {
    uid: uuidv4(),
    title: title,
    message: message,
    position: 'tr',
    autoDismiss: autoDismiss,
  }
}

export const deadlineComparator = (a, b) => {
  if (a === null) return 1;
  if (b === null) return -1;
  if (a.deadline === null) return 1;
  if (b.deadline === null) return -1;
  if (a.deadline === b.deadline) return 0
  return a.deadline < b.deadline ? -1 : 1
}

export const getUsersByGroup = (userEntities, groupEntities, groupName) => {
  const groups = Object.values(groupEntities);
  const users = Object.values(userEntities);
  if (groups && users) {
    const group = groups.find(group => group.name === groupName);
    const filteredUsers = users.filter(user => (
      user.isActive === true &&
      user.groups &&
      user.groups.includes(group.id)
    ))
    return orderBy(filteredUsers, user => user.username);
  } else {
    return [];
  }
}

export const getUserOptions = (users) => {
  return users.map(user => ({
    label: user.username,
    value: user.id,
    meta: {
      username: user.username,
      name: user.name
    },
  }));
}

export const parseAssignmentValues = (values) => {
  const formData = { ...values };
  Object.keys(ASSIGNMENT_ROLE_VALUE_MAP).forEach(role => {
    const data = formData[role];
    if (!data || (data && !data.assignee)) {
      delete formData[role]
    }
  });
  return formData;
}

export const createFormDataValues = (values) => {
  const formData = new FormData()
  Object.entries(values).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        formData.append(key + '[]', v)
      })
    } else {
      formData.append(key, value)
    }
  })
  return formData;
}

export const splitExt = (filename) => {
  return filename.split(/(?=\.[^.]+$)/);
}

export const language2key = (language) => {
  let key = language.toLowerCase();
  key = key.replace('-', '_');
  return key;
}

export const isInDateRange = (assignmentStart, assignmentEnd, calStart, calEnd) => {
  var aStart = moment(assignmentStart);
  var aEnd = moment(assignmentEnd);
  var cStart = moment(calStart).subtract(1, 'day');
  var cEnd = moment(calEnd).add(1, 'day');
  return (
      cStart.isBetween(aStart, aEnd) ||
      cEnd.isBetween(aStart, aEnd) ||
      aStart.isBetween(cStart, cEnd) ||
      aEnd.isBetween(cStart, cEnd)
  )
}
export function reorderArray(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}