import React from 'react';
import { monthFormatter, currencyFormatter } from 'misc/cellFormatters';
import ListTable from 'components/core/ListTable';


const SalesTargetTable = (props) => {

  const { result, aeUsers, editable, handleCreate, handlePatch } = props;

  const handleCellEdit = (event) => {
    const { data, colDef: { field }, value } = event;
    const key = field.replace('.price', '');
    const row = data[key];
    if (value !== undefined && value !== null && row) {
      const month = data['month'];
      const user = row.userId;
      const price = row.price;
      if (row.targetId) {
        handlePatch(row.targetId, { price, user, month })
      } else {
        handleCreate({ price, user, month });
      }
    }
  }

  const columnDefs = [{
    headerName: '',
    field: 'month',
    width: 100,
    pinned: 'left',
    suppressMovable: true,
    suppressSorting: true,
    suppressFilter: true,
    valueFormatter: monthFormatter
  }];

  aeUsers.forEach(user => {
    columnDefs.push({
      field: `${user.username}.price`,
      headerName: user.name,
      width: 100,
      minWidth: 60,
      lockPinned: true,
      suppressFilter: true,
      suppressSorting: true,
      editable: editable,
      valueFormatter: currencyFormatter,
      valueParser: (params) => {
        const num = Number(params.newValue);
        return num ? num : 0;
      },
      cellClass: (params) => {
        const row = params.data[user.username];
        return row && row.isAchieved
          ? 'text-green salestarget-meet'
          : null
      }
    })
  });

  return (
    <ListTable
      columnDefs={columnDefs}
      rowData={result}
      wrapperHeight="81vh"
      onCellEditingStopped={handleCellEdit} />
  );
}

export default SalesTargetTable;
