import React from 'react';
import classNames from 'classnames';
import moment from 'moment';


const DateFormatter = (props) => {
  
  const {
    datetime,
    isStrict,
    format,
  } = props;
  
  const cls = classNames({
    'text-danger': isStrict
  });

  const formatted = moment(datetime).format(format);

  return (
    <span className={cls}>
      {formatted}
    </span>
  )
}

DateFormatter.defaultProps = {
  isStrict: false,
  format: 'YYYY-MM-DD HH:mm'
}

export default DateFormatter;