import * as types from 'actionTypes/core';

// Confirm =====================================================================
export const confirmYes = () => ({
  type: types.CONFIRM_YES
})
export const confirmNo = () => ({
  type: types.CONFIRM_NO
})


export const serverError = (message) => ({
  type: types.SERVER_ERROR,
  payload: { message }
})

export const setDownloadProgress = (progress) => ({
  type: types.SET_DOWNLOAD_PROGRESS,
  payload: { progress }
})