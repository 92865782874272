import React from 'react'
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, formValueSelector, change, reset } from 'redux-form';
import { connectModal } from 'redux-modal';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import * as validators from 'misc/validators';
import { WORDLIST_UPLOAD_MODAL, WORDLIST_UPLOAD_FORM } from 'misc/constants';
import { isWordListUploadModalLoading } from 'selectors/loading';
import { XLSX_TYPE, LANGUAGE_OPTIONS } from 'misc/config';
import Spinner from 'components/core/Spinner';
import CharField from 'components/core/CharField';
import TextField from 'components/core/TextField';
import ClientAsyncSelectField from 'components/core/ClientAsyncSelectField';
import SelectField from 'components/core/SelectField';
import SingleDropzoneField from 'components/core/SingleDropzoneField';
import PureRenderComponent from 'components/core/PureRenderComponent';


class WordListUploadModal extends PureRenderComponent {

  componentDidUpdate(prevProps) {
    if (this.props.file && this.props.file !== prevProps.file) {
      const { file } = this.props;
      this.props.change('name', file.name)
    }
  }

  handleCancel = () => {
    this.props.change('file', null);
  }

  renderFiles = () => {
    return this.props.file && (
      <li>
        <a>
          {this.props.file.name}
          <span className="pull-right" style={{ marginTop: '-2px' }}>
            <Button bsStyle="danger" onClick={this.handleCancel} bsSize="xs">
              <FontAwesome name="ban" className="mr-5" />Cancel
            </Button>
          </span>
        </a>
      </li>
    )
  }

  hide = () => {
    this.props.handleHide()
    this.props.reset(WORDLIST_UPLOAD_FORM);
  }

  render() {
    const {
      show,
      handleSubmit,
      handleAddWordList,
      error,
      isLoading
    } = this.props

    return (
      <Modal
        show={show}
        onHide={this.hide}
        animation={true}
        backdrop="static">
        <Form
          noValidate
          autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title>Upload Client WordList</Modal.Title>
          </Modal.Header>
          <Modal.Body className="file-upload-modal no-padding">
          <Field
            name="file"
            component={SingleDropzoneField}
            accept={XLSX_TYPE}
            validate={[validators.required]}
            isRequired={true} />
          <ul className="nav nav-pills nav-stacked">
            {this.renderFiles()}
          </ul>
          { error && 
            <div className="m-5">
              <Alert
                bsStyle="danger"
                className="mb-0">
                <ul className="pl-15">
                  <li>{error}</li>
                </ul>
              </Alert>
            </div>
          }
          <div className="p-15">
            <Field
              name="name"
              label="Name"
              type="text"
              validate={[
                validators.required,
                validators.maxLength100
              ]}
              isRequired={true}
              component={CharField.Labeled} />
            <Field
              name="client"
              label="Client"
              validate={[
                validators.required,
              ]}
              isRequired={true}
              component={ClientAsyncSelectField.Labeled} />
            <Field
              name="notes"
              label="Notes"
              component={TextField.Labeled} />
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pull-left"
              bsSize="sm"
              type="button"
              onClick={this.hide}>
              <FontAwesome name="close" className="mr-5" />Close
            </Button>
            <Button
              bsStyle="primary"
              bsSize="sm"
              type="button"
              onClick={handleSubmit(values => handleAddWordList(values))}>
              <FontAwesome name="paper-plane-o" className="mr-5" />Submit
            </Button>
          </Modal.Footer>
          <Spinner isLoading={isLoading} />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const formSelector = formValueSelector(WORDLIST_UPLOAD_FORM);
  const file = formSelector(state, 'file');
  return {
    file,
    isLoading: isWordListUploadModalLoading(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    change,
    reset,
  }, dispatch)
}


WordListUploadModal = connectModal({
  name: WORDLIST_UPLOAD_MODAL
})(WordListUploadModal);

WordListUploadModal = reduxForm({
  form: WORDLIST_UPLOAD_FORM
})(WordListUploadModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WordListUploadModal);
