
import { createAsyncActionTypes } from 'misc/utils';

const PREFIX = 'quotes';

export const LIST = createAsyncActionTypes(`${PREFIX}/LIST`);
export const RETRIEVE = createAsyncActionTypes(`${PREFIX}/RETRIEVE`);
export const BATCH_DELETE = createAsyncActionTypes(`${PREFIX}/BATCH_DELETE`);


export const UNSUPPORTED_ORDER_LIST = createAsyncActionTypes(`${PREFIX}/UNSUPPORTED_ORDER_LIST`);
export const UNSUPPORTED_ORDER_RETRIEVE = createAsyncActionTypes(`${PREFIX}/UNSUPPORTED_ORDER_RETRIEVE`);