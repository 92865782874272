import React from "react";
import './TaToolbar.css';

export const TaToolbar = (props) => {

    let classNames = ['ta-toolbar'];
    if (props.className) {
        classNames.push(props.className);
    }
    if (props.wrap) {
        classNames.push('ta-toolbar--wrap');
    }

    return (
        <div className={classNames.join(' ')}>{props.children}</div>
    )
};
