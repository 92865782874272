import React from "react";
import * as PropTypes from "prop-types";
import editorStyles from "components/mht/MHTEditor/TaMhtEditor.module.css";
import {TaInputText} from "components/taUi/taInputText";
import {TaInputDropdown} from "components/taUi/taInputDropdown/taInputDropdown";
import {TaButton} from "components/taUi/taButton/taButton";

export class TaMhtEditorWidgetFilter extends React.PureComponent {

  static propTypes = {
    search: PropTypes.object.isRequired,
    filter: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.object.isRequired]),
    defaultSearch: PropTypes.func.isRequired,
    onResetRequest: PropTypes.func.isRequired,
    onSearchRequest: PropTypes.func.isRequired,
    onFilterRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      reset: 0,
      pasted: '',
      string: '',
      context: props.search.context,
      isValid: false
    };

    this.onSearchInput = (event, context) => {
      let oldState = {...this.state};
      let newState = {...this.state};
      if (
        event.name === 'paste'
      ) {
        newState.pasted = event.data.value;
      } else if (
        event.name === 'change'
      ) {
        let string = event.data.value;
        if (oldState.pasted){
          string = oldState.pasted.trim();
          newState.reset = Date.now();
          newState.pasted = '';
        }
        const stringLength = string.length;
        const isValid = (stringLength > 0);
        newState.string = string;
        newState.context = context;
        newState.isValid = isValid;
      } else if (event.name === 'escape') {
        newState = props.defaultSearch();
      }

      this.setState(newState, () => {
        if(
          newState.string === '' &&
          !!oldState.string &&
          newState.string !== oldState.string
        ){
          props.onSearchRequest(newState)
        }
      });
    };

    this.onSearchContextChange = (event) => {
      this.setState({
        context: event.data.value
      });
    };

    this.onSearchFilterChange = (event) => {
      let newFilter = false;
      if (event.data.value) {
        const value = event.data.value.split('|');
        newFilter = {
          property: value[0],
          value: value[1]
        };
      }
      props.onFilterRequest(newFilter);
    };

  }

  componentWillUnmount() {
    this.props.onResetRequest()
  }

  render() {

    const {
      search,
      filter
    } = this.props;

    const searchOptions = [
      {
        label: 'in Source',
        value: 'source'
      },
      {
        label: 'in Target',
        value: 'target'
      }
    ];

    const filterStatusOptions = [
      {
        label: 'Show All',
        value: ''
      },
      {
        label: 'Confirmed',
        value: 'filter|confirmed'
      },
      {
        label: 'Not Confirmed',
        value: 'filter|not_confirmed'
      },
      {
        label: 'Locked',
        value: 'filter|locked'
      },
      {
        label: 'Not Locked',
        value: 'filter|not_locked'
      },
      {
        label: 'Totra',
        value: 'filter|totra'
      },
      {
        label: 'Not Totra',
        value: 'filter|not_totra'
      },
      {
        label: 'Has Comment',
        value: 'filter|has_comment'
      },
      {
        label: 'Has No Comment',
        value: 'filter|has_no_comment'
      }
    ];

    return (
      <React.Fragment>
        <form
          className={editorStyles.listTool__search}
          onSubmit={(event)=> {
            event.preventDefault();
            this.props.onSearchRequest(this.state)
          }}
        >
          <TaInputText
            key={this.state.reset}
            value={this.state.string}
            name="search"
            autofocus={!!this.state.reset}
            onEvent={(event) => this.onSearchInput(event, search.context)}
            placeholder="Search"
            clear={true}
            isActive={search.isValid}
            className={editorStyles.listTool__searchText}
          />
          <TaInputDropdown
            name={'context'}
            options={searchOptions}
            onEvent={(event) => this.onSearchContextChange(event)}
            value={search.context}
            className={editorStyles.listTool__searchTarget}
          />
          <TaButton
            type={'submit'}
            icon={'search'}
            className={editorStyles.listTool__searchButton}
          />
        </form>
        <div
          className={editorStyles.listTool__filter}
        >
          <TaInputDropdown
            name={'status'}
            options={filterStatusOptions}
            onEvent={(event) => this.onSearchFilterChange(event)}
            icon={'filter_list'}
            isActive={!!(filter)}
          />
        </div>
      </React.Fragment>
    );
  }
}
