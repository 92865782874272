import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { USER_CREATE_FORM } from 'misc/constants';
import { createRequest } from 'actions/users';
import { isUserCreateLoading } from 'selectors/loading';
import { getGroupOptions } from 'selectors/options';
import PureRenderComponent from 'components/core/PureRenderComponent';
import UserCreate from './presenter';


class UserCreateContainer extends PureRenderComponent {
  
  handleCreate = (values) => {
    this.props.createRequest(this.props.form, values);
  }

  render() {
    return (
      <UserCreate
        {...this.props}
        handleCreate={this.handleCreate} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: USER_CREATE_FORM,
    isLoading: isUserCreateLoading(state),
    groupOptions: getGroupOptions(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    createRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCreateContainer);

