import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import orderBy from 'lodash.orderby'
import { getEntities } from 'selectors/entities';
import { userSchema } from 'schemas/users';

export const isAuthenticated = (state) => state.auth.isAuthenticated;
export const isRefreshed = (state) => state.auth.isRefreshed;
export const getMyUserId = (state) => state.auth.me;

const hasGroup = (groups, name) => {
  return groups.findIndex(group => group.name === name) !== -1
}

export const getMe = createSelector(
  [getMyUserId, getEntities],
  (id, entities) => denormalize(id, userSchema, entities)
)

export const getMyGroups = createSelector(
  [getMe], (me) => {
    const groups = me ? me.groups : [];
    return orderBy(groups, 'id');
  }
)

export const isSuperuser = createSelector(
  [getMe], (me) => me && me.isSuperuser
)

export const isAE = createSelector(
  [getMyGroups], (groups) => hasGroup(groups, 'AE')
);

export const isCoordinator = createSelector(
  [getMyGroups], (groups) => hasGroup(groups, 'Coordinator')
);

export const isAccounting = createSelector(
  [getMyGroups], (groups) => hasGroup(groups, 'Accounting')
)

export const isToTra = createSelector(
  [getMyGroups], (groups) => hasGroup(groups, 'ToTra')
);

export const isTranslator = createSelector(
  [getMyGroups], (groups) => hasGroup(groups, 'Translator')
);

export const isEditor = createSelector(
  [getMyGroups], (groups) => hasGroup(groups, 'Editor')
);

export const isChecker = createSelector(
  [getMyGroups], (groups) => hasGroup(groups, 'Checker')
);

export const isFinalEditor = createSelector(
  [getMyGroups], (groups) => hasGroup(groups, 'Final Editor')
);

export const isSecondFinalEditor = createSelector(
  [getMyGroups], (groups) => hasGroup(groups, 'Second Final Editor')
);

export const isDtp = createSelector(
  [getMyGroups], (groups) => hasGroup(groups, 'DTP')
);

export const isSecondDtp = createSelector(
  [getMyGroups], (groups) => hasGroup(groups, 'Second DTP')
);

export const isSalesSupport = createSelector(
  [getMyGroups], (groups) => hasGroup(groups, 'Sales Support')
);

export const isManager = createSelector(
  [isAE, isSalesSupport, isCoordinator],
  (isAE, isSalesSupport, isCoordinator) => isAE || isSalesSupport || isCoordinator
);

export const isEmployee = createSelector(
  [
    isToTra,
    isTranslator,
    isEditor,
    isChecker,
    isFinalEditor,
    isSecondFinalEditor,
    isDtp,
    isSecondDtp
  ],
  (
    isToTra,
    isTranslator,
    isEditor,
    isChecker,
    isFinalEditor,
    isSecondFinalEditor,
    isDtp,
    isSecondDtp
  ) => (
    isToTra ||
    isTranslator ||
    isEditor ||
    isChecker ||
    isFinalEditor ||
    isSecondFinalEditor ||
    isDtp ||
    isSecondDtp
  )
);

export const isAuthorizedEmployee = createSelector(
  [
    isEditor,
    isChecker,
    isFinalEditor,
    isSecondFinalEditor,
    isDtp,
    isSecondDtp,
    isToTra
  ], (
    isEditor,
    isChecker,
    isFinalEditor,
    isSecondFinalEditor,
    isDtp,
    isSecondDtp,
    isToTra
  ) => (
    isToTra ||
    isEditor ||
    isChecker ||
    isFinalEditor ||
    isSecondFinalEditor ||
    isDtp ||
    isSecondDtp
  )
)

export const isSkillRequired = createSelector(
  [
    isTranslator,
    isEditor,
    isChecker,
    isFinalEditor,
    isSecondFinalEditor,
  ],
  (
    isTranslator,
    isEditor,
    isChecker,
    isFinalEditor,
    isSecondFinalEditor,
  ) => (
    isTranslator ||
    isEditor ||
    isChecker ||
    isFinalEditor ||
    isSecondFinalEditor
  )
)