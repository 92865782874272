import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import { initialize } from 'redux-form';
import { JOB_INVOICE_MODAL, JOB_INVOICE_FORM } from 'misc/constants';
import { retrieveRequest, memberListRequest, invoiceListRequest } from 'actions/jobs/managers';
import { createRequest, updateRequest, printRequest } from 'actions/invoices';
import { isJobDetailLoading, isJobMemberListLoading, isJobInvoiceListLoading } from 'selectors/loading';
import { getJobInvoicesById } from 'selectors/lists';
import { getJobValueById } from 'selectors/jobs';
import { getJobDeliveryMemberOptionsByJobId } from 'selectors/options';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobDeliveryDetail from './presenter';



class JobDeliveryDetailContainer extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = {
      selectedJobs: [],
      selectedInvoice: 0,
    }
  }

  componentDidMount() {
    this.props.retrieveRequest(this.props.id);
    this.props.memberListRequest(this.props.id);
    this.props.invoiceListRequest(this.props.id);
  }

  getAvailableMembers = () => {
    return this.props.memberOptions.filter(member => !member.disabled)
  }

  isJobSelected = () => {
    return this.state.selectedJobs.length > 0;
  }

  isAllJobSelected = () => {
    const availableMembers = this.getAvailableMembers()
    return availableMembers.length > 0 && availableMembers.length === this.state.selectedJobs.length;
  }

  handleJobSelect = (checked, value) => {
    const newValue = [...this.state.selectedJobs];
    checked
      ? newValue.push(value)
      : newValue.splice(newValue.indexOf(value), 1)
    this.setState({ selectedJobs: newValue });
  }

  handleAllJobSelect = (e) => {
    const { checked } = e.target;
    let newValue;
    const availableMembers = this.getAvailableMembers();
    checked
      ? newValue = availableMembers
      : newValue = []
    this.setState({ selectedJobs: newValue });
  }

  getSelectedInvoice = () => {
    return this.props.invoices[this.state.selectedInvoice];
  }

  handleInvoiceSelect = (key) => {
    this.setState({ selectedInvoice: key });
  }

  _handleInvoiceCreate = (values, finished) => {
    const formData = { ...values, finished }
    this.props.createRequest(JOB_INVOICE_FORM, formData);
    this.setState({ selectedJobs: [] })
  }

  _handleInvoiceEdit = (values, finished) => {
    const formData = { ...values, finished }
    const selectedInvoice = this.getSelectedInvoice();
    this.props.updateRequest(JOB_INVOICE_FORM, selectedInvoice.id, formData);
  }

  // create new invoice and finish job(s)
  handleInvoiceCreate = (values) => {
    this._handleInvoiceCreate(values, true)
  }

  // create new invoice
  handleInvoiceSave = (values) => {
    this._handleInvoiceCreate(values, false)
  }

  // update invoice and finish job(s)
  handleInvoiceEdit = (values) => {
    this._handleInvoiceEdit(values, true)
  }

  // update invoice
  handleInvoiceUpdate = (values) => {
    this._handleInvoiceEdit(values, false)
  }

  handleInvoicePrint = () => {
    const selectedInvoice = this.getSelectedInvoice();
    this.props.printRequest(selectedInvoice.id);
  }

  showInvoiceAddModal = () => {
    this.props.show(JOB_INVOICE_MODAL, {
      title: 'Create Invoice',
      memberOptions: this.props.memberOptions,
      handleFinish: this.handleInvoiceCreate,
      handleSave: this.handleInvoiceSave
    });

    this.props.initialize(JOB_INVOICE_FORM, {
      jobs: this.state.selectedJobs.map(job => job.value)
    });
  }

  showInvoiceEditModal = () => {
    this.props.show(JOB_INVOICE_MODAL, {
      title: 'Edit Invoice',
      memberOptions: this.props.memberOptions,
      handleFinish: this.handleInvoiceEdit,
      handleSave: this.handleInvoiceUpdate
    });
    const selectedInvoice = this.getSelectedInvoice();
    this.props.initialize(JOB_INVOICE_FORM, {
      ...selectedInvoice,
      jobs: selectedInvoice.jobs.map(job => job.id),
    });
  }

  render() {
    return (
      <JobDeliveryDetail
        {...this.props}
        selectedJobs={this.state.selectedJobs}
        selectedInvoice={this.state.selectedInvoice}
        isJobSelected={this.isJobSelected}
        isAllJobSelected={this.isAllJobSelected}
        handleJobSelect={this.handleJobSelect}
        handleAllJobSelect={this.handleAllJobSelect}
        handleInvoiceSelect={this.handleInvoiceSelect}
        handleInvoicePrint={this.handleInvoicePrint}
        showInvoiceAddModal={this.showInvoiceAddModal}
        showInvoiceEditModal={this.showInvoiceEditModal} />
    );
  }
}


const mapStateToProps = (state, props) => {
  const id = props.params.id;
  return {
    id,
    job: getJobValueById(state, id),
    memberOptions: getJobDeliveryMemberOptionsByJobId(state, id),
    isLoading: isJobDetailLoading(state, id),
    isJobMemberListLoading: isJobMemberListLoading(state, id),
    isJobInvoiceListLoading: isJobInvoiceListLoading(state, id),
    invoices: getJobInvoicesById(state, id)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    initialize,
    retrieveRequest,
    memberListRequest,
    invoiceListRequest,
    createRequest,
    updateRequest,
    printRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobDeliveryDetailContainer);
