import merge from 'lodash.merge';
import omit from 'lodash.omit';
import * as types from 'actionTypes/entities';

const initialState = {

  // core
  countries: {},
  applications: {},
  parentIndustries: {},
  industries: {},
  parentFields: {},
  fields: {},
  parentCategories: {},
  categories: {},
  services: {},

  // users
  users: {},
  profiles: {},
  groups: {},

  specialties: {},
  focus: {},
  software: {},
  schedules: {},

  translationSkills: {},
  editSkills: {},
  checkSkills: {},
  finalEditSkills: {},
  secondFinalEditSkills: {},


  // clients
  clients: {},
  preferableUsers: {},
  ngUsers: {},
  websites: {},

  // jobs
  jobs: {},
  assignments: {},
  payments: {},
  templates: {},

  // documents
  sourcefiles: {},
  references: {},
  jobCommonReferences: {},
  clientReferences: {},
  projects: {},

  // accounting
  salesTargets: {},

  // mht
  mht: {},
  wordlists: {},
  wordlistItems: {},
  glossaries: {},
  glossaryItmes: {},
  memories: {},
  memorySearches: {},
  pendingMht: {},
  analysis: {},

  // customers
  customers: {},
  quotes: {},
  quoteSourcefiles: {},
  targetfiles: {},
  unsupportedOrders: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.MERGE:
      return mergeState(state, action);
    case types.REMOVE:
      return remove(state, action);
    case types.SYNC:
      return sync(state, action);
    case types.UPDATE:
      return update(state, action);
    default:
      return state;
  }
}

const mergeState = (state, action) => {
  const { entities } = action.payload;
  return merge({}, state, entities);
}

const remove = (state, action) => {
  const { name, id } = action.payload;
  return {
    ...state,
    [name]: omit(state[name], id)
  }
}

const sync = (state, action) => {
  const { name, id, entity } = action.payload;
  return {
    ...state,
    [name]: {
      ...state[name],
      [id]: entity
    }
  }
}

const update = (state, action) => {
  const { name, id, value } = action.payload;
  const newValue = { ...state[name][id], ...value };
  return {
    ...state,
    [name]: {
      ...state[name],
      [id]: newValue
    }
  }
}