import React from 'react';
import { connect } from 'react-redux';
import { Form, Button, Row, Col } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { Field, reduxForm } from 'redux-form';
import { Box } from 'reactjs-admin-lte';
import * as validators from 'misc/validators';
import { getMemoryServiceOptions } from 'selectors/options';
import PureRenderComponent from 'components/core/PureRenderComponent';
import CharField from 'components/core/CharField';
import SelectField from 'components/core/SelectField';
import CheckboxField from 'components/core/CheckboxField';
import JobNumberAsyncSelectField from 'components/core/JobNumberAsyncSelectField';
import ClientAsyncSelectField from 'components/core/ClientAsyncSelectField';


class TranslationMemorySearchForm extends PureRenderComponent {
  render() {

    const {
      serviceOptions,
      handleReset,
      handleSubmit,
      handleSearch,
      showSaveModal,
      service,
    } = this.props;

    return (
      <Form
        horizontal
        noValidate
        autoComplete="off"
        className="form-condensed">
        <Box className="box-widget">
          <Box.Header border={true}>
            <Box.Title>
              <FontAwesome name="filter" />
            </Box.Title>
            <Box.Tools>
              <Box.CollapsedToggleButton />
            </Box.Tools>
          </Box.Header>
          <Box.Body>
            <Row>
              <Col lg={4}>
                <Field
                  name="service"
                  label="Service"
                  validate={[validators.required]}
                  isRequired={true}
                  options={serviceOptions}
                  component={SelectField.Horizontal} />
                <Field
                  name="keyword"
                  label="Keyword"
                  component={CharField.Horizontal} />
              </Col>
              <Col lg={4}>
                <Field
                  name="client"
                  label="Client"
                  component={ClientAsyncSelectField.Horizontal} />
                <Field
                  name="penaltyOnly"
                  label="Penalty Only"
                  component={CheckboxField.Horizontal} />
              </Col>
              <Col lg={4}>
                <Field
                  name="jobs"
                  label="Job"
                  multi={true}
                  component={JobNumberAsyncSelectField.Horizontal} />
                <Field
                  name="excludePenalty"
                  label="Exclude Penalty"
                  component={CheckboxField.Horizontal} />
              </Col>
            </Row>
          </Box.Body>
          <Box.Footer>
            <Button
              bsStyle="primary"
              bsSize="sm"
              type="button"
              className="pull-left"
              disabled={!service}
              onClick={handleSubmit(values => handleSearch(values))}>
              <FontAwesome name="search" className="mr-5" />Search
            </Button>
            <Button
              bsStyle="default"
              bsSize="sm"
              type="button"
              className="ml-10 pull-left"
              onClick={handleReset}>
              <FontAwesome name="undo" className="mr-5" />Reset
            </Button>
            <Button
              bsStyle="success"
              bsSize="sm"
              type="button"
              className="ml-10 pull-right"
              disabled={!service}
              onClick={showSaveModal}>
              <FontAwesome name="save" className="mr-5" />Save
            </Button>
          </Box.Footer>
        </Box>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceOptions: getMemoryServiceOptions(state),
  }
}

TranslationMemorySearchForm = reduxForm()(TranslationMemorySearchForm)

export default connect(
  mapStateToProps,
)(TranslationMemorySearchForm);