import { createAsyncActionTypes } from 'misc/utils';

const PREFIX = 'jobs/managers';

// Create ======================================================================
export const CREATE = createAsyncActionTypes(`${PREFIX}/CREATE`);
export const DRAFT = createAsyncActionTypes(`${PREFIX}/DRAFT`);

export const DUPLICATE_CREATE = createAsyncActionTypes(`${PREFIX}/DUPLICATE_CREATE`);
export const DUPLICATE_DRAFT = createAsyncActionTypes(`${PREFIX}/DUPLICATE_DRAFT`);

export const TEMPLATE_BY_CREATE = createAsyncActionTypes(`${PREFIX}/TEMPLATE_BY_CREATE`);
export const TEMPLATE_BY_DRAFT = createAsyncActionTypes(`${PREFIX}/TEMPLATE_BY_DRAFT`);

export const SPLIT_CREATE = createAsyncActionTypes(`${PREFIX}/SPLIT_CREATE`);
export const SPLIT_DRAFT = createAsyncActionTypes(`${PREFIX}/SPLIT_DRAFT`);

export const ISSUE = createAsyncActionTypes(`${PREFIX}/ISSUE`);
export const SPLIT_ISSUE = createAsyncActionTypes(`${PREFIX}/SPLIT_ISSUE`);
export const EDIT = createAsyncActionTypes(`${PREFIX}/EDIT`);

export const RETRIEVE = createAsyncActionTypes(`${PREFIX}/RETRIEVE`);
export const UPDATE = createAsyncActionTypes(`${PREFIX}/UPDATE`);
export const PATCH = createAsyncActionTypes(`${PREFIX}/PATCH`);
export const REMOVE = createAsyncActionTypes(`${PREFIX}/REMOVE`);
export const CANCEL = createAsyncActionTypes(`${PREFIX}/CANCEL`);

export const REMOVE_DRAFT = createAsyncActionTypes(`${PREFIX}/REMOVE_DRAFT`);
export const REMOVE_PENDING = createAsyncActionTypes(`${PREFIX}/REMOVE_PENDING`);

export const DRAFT_TO_PENDING = createAsyncActionTypes(`${PREFIX}/DRAFT_TO_PENDING`);
export const DRAFT_UPDATE = createAsyncActionTypes(`${PREFIX}/DRAFT_UPDATE`);

export const QUICK_EDIT = createAsyncActionTypes(`${PREFIX}/QUICK_EDIT`);

// Schedule ====================================================================
export const DAILY_SCHEDULE = createAsyncActionTypes(`${PREFIX}/DAILY_SCHEDULE`);
export const FUTURE_SCHEDULE = createAsyncActionTypes(`${PREFIX}/FUTURE_SCHEDULE`);

export const MEMBER_LIST = createAsyncActionTypes(`${PREFIX}/MEMBER_LIST`);
export const REARRANGE = createAsyncActionTypes(`${PREFIX}/REARRANGE`);
export const FETCH_LATEST_NUMBER = createAsyncActionTypes(`${PREFIX}/FETCH_LATEST_NUMBER`);

// List ========================================================================
// export const WORKING_LIST = createAsyncActionTypes(`${PREFIX}/WORKING_LIST`);
export const DRAFT_LIST = createAsyncActionTypes(`${PREFIX}/DRAFT_LIST`);
export const PENDING_LIST = createAsyncActionTypes(`${PREFIX}/PENDING_LIST`);
export const PREPARING_LIST = createAsyncActionTypes(`${PREFIX}/PREPARING_LIST`);
export const DELIVERY_LIST = createAsyncActionTypes(`${PREFIX}/DELIVERY_LIST`);
export const TOTRA_LIST = createAsyncActionTypes(`${PREFIX}/TOTRA_LIST`);
export const RNQ_LIST = createAsyncActionTypes(`${PREFIX}/RNQ_LIST`);

export const JOB_SEARCH = createAsyncActionTypes(`${PREFIX}/JOB_SEARCH`);
export const PAYMENT_SEARCH = createAsyncActionTypes(`${PREFIX}/PAYMENT_SEARCH`);

export const SUGGEST_ASSIGNMENT = createAsyncActionTypes(`${PREFIX}/SUGGEST_ASSIGNMENT`);
export const REFRESH_ASSIGNMENT = createAsyncActionTypes(`${PREFIX}/REFRESH_ASSIGNMENT`);

export const ASSIGNMENT_FINISH = createAsyncActionTypes(`${PREFIX}/ASSIGNMENT_FINISH`);
export const ASSIGNMENT_PAYMENT_CHECK = createAsyncActionTypes(`${PREFIX}/ASSIGNMENT_PAYMENT_CHECK`);

export const INVOICE_LIST = createAsyncActionTypes(`${PREFIX}/INVOICE_LIST`);

export const SALES_REPORT = createAsyncActionTypes(`${PREFIX}/SALES_REPORT`);
export const COUNT_REPORT = createAsyncActionTypes(`${PREFIX}/COUNT_REPORT`);

export const PRINT = createAsyncActionTypes(`${PREFIX}/PRINT`);
export const PROCESS = createAsyncActionTypes(`${PREFIX}/PROCESS`);

export const SET_QUICK_EDIT = `${PREFIX}/SET_QUICK_EDIT`;
export const SET_ASSIGNEE_OPTIONS = `${PREFIX}/SET_ASSIGNEE_OPTIONS`;
export const RESET_ASSIGNEE_OPTIONS = `${PREFIX}/RESET_ASSIGNEE_OPTIONS`;


export const AUTOCOMPLETE = createAsyncActionTypes(`${PREFIX}/AUTOCOMPLETE`);

export const BULK_VERIFY = createAsyncActionTypes(`${PREFIX}/BULK_VERIFY`);

export const MHT_JOB_LIST = createAsyncActionTypes(`${PREFIX}/MHT_JOB_LIST`);
export const MHT_JOB_RETRIEVE = createAsyncActionTypes(`${PREFIX}/MHT_JOB_RETRIEVE`);
export const MHT_JOB_MESSAGE_LIST = createAsyncActionTypes(`${PREFIX}/MHT_JOB_MESSAGE_LIST`);
export const MHT_JOB_MESSAGE_CREATE = createAsyncActionTypes(`${PREFIX}/MHT_JOB_MESSAGE_CREATE`);
export const MHT_JOB_MESSAGE_READ = createAsyncActionTypes(`${PREFIX}/MHT_JOB_MESSAGE_READ`);
export const MHT_JOB_MESSAGE_BATCH_READ = createAsyncActionTypes(`${PREFIX}/MHT_JOB_MESSAGE_BATCH_READ`);
export const MHT_JOB_DELIVERY = createAsyncActionTypes(`${PREFIX}/MHT_JOB_DELIVERY`);
export const MHT_JOB_REDELIVERY = createAsyncActionTypes(`${PREFIX}/MHT_JOB_REDELIVERY`);
export const MHT_INVOICE_DOWNLOAD = createAsyncActionTypes(`${PREFIX}/MHT_INVOICE_DOWNLOAD`);
export const QUOTE_SOURCEFILE_LIST = createAsyncActionTypes(`${PREFIX}/QUOTE_SOURCEFILE_LIST`);