import React from 'react'
import { Modal, Carousel } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MHT_FILE_UPLOAD_MODAL } from 'misc/constants';
import {
  DOCX_TYPE,
  XLSX_TYPE,
  PPTX_TYPE,
  DEFAULT_MEMORY_SUGGEST_RATIO,
  SEARCH_METHOD_OPTIONS
} from 'misc/config';
import { listRequest as wordlistRequest } from 'actions/wordlists';
import MHTFileUpload from './MHTFileUpload';
import MHTMemoryReferenceSetting from './MHTMemoryReferenceSetting';
import MHTTermBaseSetting from './MHTTermBaseSetting';
import MHTAutoLockSetting from './MHTAutoLockSetting';
import MHTGoogleTranslateSetting from './MHTGoogleTranslateSetting';
import MHTAITranslateSetting from './MHTAITranslateSetting';

const documentOptions = {
  includeDocumentHeader: false,
  includeDocumentFooter: false,
  // includeDocumentComment: false,
  includeDocumentHyperlink: false,
  // includeDocumentAltText: false,
  // includeDocumentEmbeddedSheet: false,
}

const sheetOptions = {
  includeSheetHeader: false,
  includeSheetFooter: false,
  // includeSheetComment: false,
  includeSheetHyperlink: false,
  // includeSheetAltText: false,
  // includeSheetHiddenContent: false,
  includeSheetName: false,
}

const slideOptions = {
  includeSlide: true,
  // includeSlideHeader: false,
  includeSlideFooter: false,
  // includeSlideComment: false,
  includeSlideHyperlink: false,
  // includeSlideAltText: false,
  // includeSlideHiddenContent: false,
  includeSlideNotes: false,
  // includeSlideMaster: false,
  // includeSlideEmbeddedSheet: false,
}

const initialFormDataState = {
  // SourceFile
  name: '',
  file: null,
  job: null,
  jobGroup: null,
  // document option
  ...documentOptions,
  // sheet option
  ...sheetOptions,
  // slide option
  ...slideOptions,

  // MHT setting
  includeTentative: true,
  requireAutoLock: true,
  // useGoogleTranslate: false,
  useAITranslate: "no",
  excludePenalty: true,
  searchMethod: SEARCH_METHOD_OPTIONS[0].value,
  memorySuggestRatio: DEFAULT_MEMORY_SUGGEST_RATIO,
  referenceParentCategories: [],
  referenceCategories: [],
  referenceWordlists: [],
  referenceAdditionalWordlists: [],
  referenceJobs: [],
  referenceClients: [],
}

class MHTFileUploadModal extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      activeCarouselItemIndex: 0,
      errors: [],
      warning: [],
      formData: initialFormDataState,
    }
  }

  componentDidMount() {
    const { job } = this.props;
    const clientId = job.client.id;
    this.props.wordlistRequest({ page: 1, client: clientId });
    this.setState({
      formData: {
        ...this.state.formData,
        job: job.id,
        jobGroup: job.group,
        referenceClients: [clientId],
      }
    })
  }

  setDefaultWordlistSetting = (options) => {
    const values = options.map(option => option.value);
    this.setState({
      formData: {
        ...this.state.formData,
        referenceWordlists: values
      }
    })
  }

  resetFormDataByKey = (key, resetValue) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: resetValue
      }
    });
  }

  handleFormChange = (key, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: value
      }
    })
  }

  handleFormArrayChange = (key, value) => {
    const newValue = value ? value.split(',') : []
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: newValue
      }
    })
  }

  handleCancel = () => {
    const { job } = this.props;
    this.setState({
      formData: {
        ...initialFormDataState,
        job: job.id,
        referenceClients: [job.client.id],
        referenceWordlists: this.state.formData.referenceWordlists,
        referenceCategories: this.state.formData.referenceCategories,
        referenceParentCategories: this.state.formData.referenceParentCategories
      }
    });
  }

  getDefaultTrueOptions = (options) => {
    const results = {};
    for (let key of Object.keys(options)) {
      results[key] = true;
    }
    return results;
  }

  handleDropSuccess = (acceptedFiles) => {
    const file = acceptedFiles[0];

    let options = {}

    switch (file.type) {
      case DOCX_TYPE:
        options = this.getDefaultTrueOptions(documentOptions);
        break;
      case XLSX_TYPE:
        options = this.getDefaultTrueOptions(sheetOptions);
        break;
      case PPTX_TYPE:
        options = this.getDefaultTrueOptions(slideOptions);
        break;
    }

    this.setState({
      formData: {
        ...this.state.formData,
        ...options,
        name: file.name,
        file
      }
    });
  }

  handleSubmit = () => {
    this.props.handleSetupMHT(this.state.formData);
  }

  handleSlide = (index) => {
    this.setState({ activeCarouselItemIndex: index })
  }

  render() {
    const {
      show,
      handleHide,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Carousel
          slide={false}
          controls={false}
          indicators={true}
          interval={null}
          prevIcon={null}
          nextIcon={null}
          activeIndex={this.state.activeCarouselItemIndex}>
          <Carousel.Item index={0}>
            <MHTFileUpload
              file={this.state.formData.file}
              handleCancel={this.handleCancel}
              formData={this.state.formData}
              handleDropSuccess={this.handleDropSuccess}
              handleFormChange={this.handleFormChange}
              handleSlide={this.handleSlide} />
          </Carousel.Item>
          <Carousel.Item index={1}>
            <MHTMemoryReferenceSetting
              clients={this.state.formData.referenceClients}
              jobs={this.state.formData.referenceJobs}
              memorySuggestRatio={this.state.formData.memorySuggestRatio}
              includeTentative={this.state.formData.includeTentative}
              jobGroup={this.state.formData.jobGroup}
              excludePenalty={this.state.formData.excludePenalty}
              searchMethod={this.state.formData.searchMethod}
              service={this.props.job && this.props.job.service && this.props.job.service.id}
              handleSlide={this.handleSlide}
              handleFormChange={this.handleFormChange}
              handleFormArrayChange={this.handleFormArrayChange} />
          </Carousel.Item>
          <Carousel.Item index={2}>
            <MHTTermBaseSetting
              file={this.state.formData.file}
              client={this.props.job && this.props.job.client && this.props.job.client.id}
              referenceParentCategories={this.state.formData.referenceParentCategories}
              referenceCategories={this.state.formData.referenceCategories}
              referenceWordlists={this.state.formData.referenceWordlists}
              referenceAdditionalWordlists={this.state.formData.referenceAdditionalWordlists}
              setDefaultWordlistSetting={this.setDefaultWordlistSetting}
              resetFormDataByKey={this.resetFormDataByKey}
              handleSlide={this.handleSlide}
              handleFormArrayChange={this.handleFormArrayChange} />
          </Carousel.Item>
          <Carousel.Item index={3}>
            <MHTAutoLockSetting
              requireAutoLock={this.state.formData.requireAutoLock}
              handleFormChange={this.handleFormChange}
              handleSlide={this.handleSlide} />
          </Carousel.Item>
          <Carousel.Item index={4}>
            {/* <MHTGoogleTranslateSetting
              useGoogleTranslate={this.state.formData.useGoogleTranslate}
              handleFormChange={this.handleFormChange}
              handleSlide={this.handleSlide}
              handleSubmit={this.handleSubmit} /> */}
            <MHTAITranslateSetting
              useAITranslate={this.state.formData.useAITranslate}
              handleFormChange={this.handleFormChange}
              handleSlide={this.handleSlide}
              handleSubmit={this.handleSubmit} />
          </Carousel.Item>
        </Carousel>
      </Modal>
    );
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    wordlistRequest
  }, disptach)
}


MHTFileUploadModal = connectModal({ name: MHT_FILE_UPLOAD_MODAL })(MHTFileUploadModal);
export default connect(
  null,
  mapDispatchToProps
)(MHTFileUploadModal);