import React from 'react';
import { connectModal } from 'redux-modal';
import FontAwesome from 'react-fontawesome';
import isEmpty from 'lodash.isempty';
import { camelizeKeys } from 'humps';
import { Modal, Button, Grid, Row, Col } from 'react-bootstrap';
import { MHT_ANALYSIS_MODAL } from 'misc/constants';
import API from 'services/MHTApi';
import PureRenderComponent from 'components/core/PureRenderComponent';
import Spinner from 'components/core/Spinner';
import MHTAnalysisTable from './MHTAnalysisTable';
import MHTAnalysisChart from './MHTAnalysisChart';

class MHTAnalysisModal extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      analysis: {}
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ isLoading: true })
    API.retrieveAnalysis(this.props.id)
      .then(response => {
        this.setState({ analysis: camelizeKeys(response.data) });
      }).then(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {

    const {
      show,
      handleHide,
      service
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static"
        bsSize="lg">
        <Modal.Header closeButton>
          <Modal.Title>Word Count</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {!isEmpty(this.state.analysis) && (
          <Grid fluid>
            <Row>
              <Col xs={5} md={5} lg={5}>
                <MHTAnalysisTable service={service} analysis={this.state.analysis} />
              </Col>
              <Col xs={7} md={7} lg={7}>
                <MHTAnalysisChart service={service} analysis={this.state.analysis} />
              </Col>
            </Row>
          </Grid>
        )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pull-left"
            bsSize="sm"
            type="button"
            onClick={handleHide}>
            <FontAwesome name="close" className="mr-5" />Close
          </Button>
        </Modal.Footer>
        <Spinner isLoading={this.state.isLoading} />
      </Modal>
    );
  }
}

MHTAnalysisModal = connectModal({
  name: MHT_ANALYSIS_MODAL
})(MHTAnalysisModal);

export default MHTAnalysisModal;
