import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Nav, Navbar, NavItem, Button, InputGroup, FormControl } from 'react-bootstrap';
import FilterBar from 'components/core/FilterBar';
import ClientAsyncSelect from 'components/core/ClientAsyncSelect'

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.client === nextProps.client &&
    prevProps.keyword === nextProps.keyword
  );
}

const WordListFilter = React.memo(({
  client,
  keyword,
  handleSubmit,
  handleClientChange,
  handleKeywordChange
 }) => {
  return (
    <FilterBar>
      <Nav>
        <NavItem disabled>Client</NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <ClientAsyncSelect
          value={client}
          clearable={true}
          placeholder=""
          onChange={handleClientChange}
          style={{ width: 200 }} />
      </Navbar.Form>
      <Navbar.Form pullRight>
        <InputGroup bsSize="sm">
          <FormControl
            value={keyword}
            onChange={(e) => handleKeywordChange(e.target.value)}
            onKeyPress={(e) => {
              e.key === 'Enter' && handleSubmit()
            }}
            type="text"
            style={{ width: 200 }}
            bsSize="sm" />
          <InputGroup.Button>
            <Button
              bsSize="sm"
              onClick={handleSubmit}>
              <FontAwesome name="search" />
            </Button>
          </InputGroup.Button>
        </InputGroup>
      </Navbar.Form>
    </FilterBar>
  );
}, areEqual);

export default WordListFilter;
