import React from 'react';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import Select from 'components/core/Select';
import FilterBar from 'components/core/FilterBar';


const JobPreparingListFilter = (props) => {
  
  const {
    coordinator,
    coordinatorOptions,
    handleCoordinatorChange
  } = props;
  
  return (
    <FilterBar>
      <Nav>
        <NavItem disabled>Coordinator</NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <Select
          value={coordinator}
          clearable={true}
          placeholder=""
          options={coordinatorOptions}
          onChange={handleCoordinatorChange}
          style={{ width: 200 }} />
      </Navbar.Form>
    </FilterBar>
  );
}

export default JobPreparingListFilter;
