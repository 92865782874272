import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { Box } from 'reactjs-admin-lte';
import { Field, reduxForm } from 'redux-form';
import * as validators from 'misc/validators';
import CharField from 'components/core/CharField';
import SelectField from 'components/core/SelectField';
import DateRangeFormField from 'components/core/DateRangeFormField';


const booleanOptions = [
  { label: 'True', value: true },
  { label: 'False', value: false }
]


const PaymentSearchForm = (props) => {
  
  const {
    roleOptions,
    employeeOptions,
    handleSearch,
    handleSubmit,
    handleReset,
  } = props;
  
  return (
    <Form
      horizontal
      noValidate
      autoComplete="off"
      className="form-condensed">
      <Box className="box-widget">
        <Box.Header border={true}>
          <Box.Title>
            <FontAwesome name="filter" />
          </Box.Title>
          <Box.Tools>
            <Box.CollapsedToggleButton />
          </Box.Tools>
        </Box.Header>
        <Box.Body>
          <Row>
            <Col lg={4}>
              <DateRangeFormField
                label="Created"
                startValidate={[validators.required]}
                endValidate={[validators.required]} />
              <Field
                name="number"
                label="Number"
                type="text"
                component={CharField.Horizontal} />
            </Col>
            <Col lg={4}>
              <Field
                name="role"
                label="Role"
                clearable={false}
                validate={[validators.required]}
                options={roleOptions}
                component={SelectField.Horizontal} />
              <Field
                name="employee"
                label="Employee"
                validate={[validators.required]}
                options={employeeOptions}
                component={SelectField.Horizontal} />
            </Col>
            <Col lg={4}>
              <Field
                name="isPaid"
                label="Paid"
                options={booleanOptions}
                component={SelectField.Horizontal} />
            </Col>
          </Row>
        </Box.Body>
        <Box.Footer>
          <Button
            bsStyle="primary"
            bsSize="sm"
            type="button"
            onClick={handleSubmit(values => handleSearch(values))}>
            <FontAwesome name="search" className="mr-5" />Search
          </Button>
          <Button
            bsStyle="default"
            bsSize="sm"
            className="ml-10"
            type="button"
            onClick={handleReset}>
            <FontAwesome name="undo" className="mr-5" />Reset
          </Button>
        </Box.Footer>
      </Box>
    </Form>
  )
}

export default reduxForm()(PaymentSearchForm);

