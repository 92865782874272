import React from "react";
import PropTypes from "prop-types";
import './taFormMessage.css';

export const TaFormMessages = (props) => {

  const {
    className,
    messages
  } = props;

  const baseClass = 'ta-form-message';

  let classNames = [baseClass+'s'];
  if (className) {
    classNames.push(className);
  }

  return (
    <React.Fragment children={(messages && messages.length) ? <ul className={classNames.join(' ')}>
        {messages.map((message, index) => {
            return (
              <li key={index} className={[baseClass, baseClass+'--'+message.type].join(' ')}>
                  {message.message}
              </li>
            );
        })}
    </ul> : null}/>
  );
};

TaFormMessages.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.oneOf(['info', 'success', 'error']).isRequired,
        message: PropTypes.string.isRequired
    })).isRequired
};
TaFormMessages.defaultProps = {
    messages: []
};

