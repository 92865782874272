import React from 'react';
import FontAwesome from 'react-fontawesome';
import { FormGroup, FormControl, InputGroup, HelpBlock } from 'react-bootstrap';


const IconInputGroupField = (props) => {

  const { icon, input, type, placeholder } = props;
  const { invalid, touched, error } = props.meta
  const hasError = (touched && invalid) ? 'error' : null;

  const helpBlock = hasError
    ? <HelpBlock>{error}</HelpBlock>
    : null
  
  return (
    <FormGroup validationState={hasError}>
      <InputGroup>
        <InputGroup.Addon>
          <FontAwesome name={icon} />
        </InputGroup.Addon>
        <FormControl
          {...input}
          type={type}
          placeholder={placeholder} />
      </InputGroup>
      {helpBlock}
    </FormGroup>
  )
}

export default IconInputGroupField;