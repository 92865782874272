import { delay } from 'redux-saga';
import { takeLatest, call, put, fork, all, select } from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import { show, hide } from 'redux-modal';
import { change, stopSubmit } from 'redux-form';
import API from 'services/jobs/ManagerApi';
import * as schemas from 'schemas';
import * as actionTypes from 'actionTypes/jobs/managers';
import * as actions from 'actions/jobs/managers';
import * as entityActions from 'actions/entities';
import * as listActions from 'actions/lists';
import { setDownloadProgress } from 'actions/core';
import * as constants from 'misc/constants';
import * as coreSagas from 'sagas/core';
import { addOrSelectTab, closeTab } from 'actions/globalTabs';
import { closeCurrentTab } from 'sagas/globalTabs';
import { setLoading } from 'actions/loading';
import * as filterSelectors from 'selectors/filters';
import { saveFile } from 'misc/utils';
import { getDetailTabConfigByName } from 'misc/utils';


function* _list(action, api, listName, loadingKey, sucessAction, failureAction) {
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(api);
    const normalized = normalize(camelizeKeys(response.data), [schemas.jobSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setResult(listName,normalized.result));
    yield put(sucessAction());
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(failureAction(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Retrieve ====================================================================
function* retrieve(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.JOB_DETAIL}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.retrieve, id);
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    const assignments = normalized.entities.assignments;
    if (assignments && Object.keys(assignments).length > 0) {
      yield all(Object.values(assignments).map(assignment => {
        return put(listActions.setNestedResult(
          constants.ASSIGNMENT_PROJECT,
          assignment.id,
          assignment.projects
        ));
      }));
    }
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.retrieveFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Patch =======================================================================
function* patch(action) {
  const { id, values } = action.payload;
  try {
    const response = yield call(API.patch, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield fork(coreSagas.successNotification, 'Job updated successfully.');
  } catch (error) {
    yield put(actions.patchFailure(error));
  }
}

// Daily Schedule ==============================================================
function* dailySchdule(action) {
  yield put(setLoading(constants.JOB_DAILY_SCHEDULE, true));
  try {
    const { params } = action.payload;
    const { currentDate } = params;
    const response = yield call(API.dailySchedule, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data), [schemas.jobSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setDailyResult(constants.JOB_DAILY_SCHEDULE, currentDate, normalized.result));
    yield put(setLoading(constants.JOB_DAILY_SCHEDULE, false));
  } catch (error) {
    yield put(actions.dailyScheduleFailure(error));
    yield put(setLoading(constants.JOB_DAILY_SCHEDULE, false));
  }
}

// Daily Schedule ==============================================================
function* futureSchdule(action) {
  yield put(setLoading(constants.JOB_FUTURE_SCHEDULE, true));
  try {
    const { params } = action.payload;
    const { currentDate } = params;
    const response = yield call(API.futureSchedule, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data), [schemas.jobSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setDailyResult(constants.JOB_FUTURE_SCHEDULE, currentDate, normalized.result));
    yield put(setLoading(constants.JOB_FUTURE_SCHEDULE, false));
  } catch (error) {
    yield put(actions.futureScheduleFailure(error));
    yield put(setLoading(constants.JOB_FUTURE_SCHEDULE, false));
  }
}


// Rearrange ===================================================================
function* rearrange(action) {
  yield put(setLoading(constants.JOB_DAILY_SCHEDULE, true));
  try {
    const { ids } = action.payload;
    yield call(API.rearrange, ids);
    const currentDate = yield select(filterSelectors.getJobScheduleCurrentDate);
    const viewAs = yield select(filterSelectors.getJobScheduleViewAs);
    const deadline = yield select(filterSelectors.getJobScheduleDeadline);
    const assignee = yield select(filterSelectors.getJobScheduleAssignee);
    yield put(actions.dailyScheduleRequest({ currentDate, viewAs, deadline, assignee }));
    yield fork(coreSagas.successNotification, 'Jobs reordered successfully.');
  } catch (error) {
    yield put(actions.rearrangeFailure());
    yield put(setLoading(constants.JOB_DAILY_SCHEDULE, false));
  }
}

// Fetch Latest Job Number =====================================================
function* fetchLatestNumber(action) {
  const { form, type } = action.payload;
  try {
    const response = yield call(API.fetchLatestNumber, { type });
    const data = camelizeKeys(response.data);
    yield put(actions.fetchLatestNumberSuccess());
    yield put(change(form, 'baseNumber', data.baseNumber));
  } catch (error) {
    yield put(actions.fetchLatestNumberFailure(error));
  }
}

// Member List =================================================================
function* memberList(action) {
  const { jobId } = action.payload;
  const loadingKey = `${constants.JOB_MEMBER_LIST}/${jobId}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.memberList, jobId);
    const normalized = normalize(camelizeKeys(response.data), [schemas.jobSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.memberListFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Job Search ==================================================================
function* search(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.JOB_SEARCH, true));
  try {
    const response = yield call(API.search, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data.results), [schemas.jobSchema]);
    const totalCount = response.data.count ? response.data.count : 1;
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setPaginationResult(
      constants.JOB_SEARCH,
      params.page,
      normalized.result,
      totalCount
    ));
    yield put(setLoading(constants.JOB_SEARCH, false));
  } catch (error) {
    yield put(actions.jobSearchFailure(error));
    yield put(setLoading(constants.JOB_SEARCH, false));
  }
}

// Draft List ==================================================================
function* draftList(action) {
  yield fork(
    _list,
    action,
    API.draftList,
    constants.JOB_DRAFT_LIST,
    constants.JOB_DRAFT_LIST,
    actions.draftListSuccess,
    actions.draftListFailure
  )
}

// Working List ================================================================
// function* workingList(action) {
//   yield fork(
//     _list,
//     action,
//     API.workingList,
//     constants.WORKING_LIST,
//     constants.WORKING_LIST,
//     actions.workingListSuccess,
//     actions.workingListFailure
//   )
// }

// Delivery List ===============================================================
function* deliveryList(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.DELIVERY_LIST, true));
  try {
    const response = yield call(API.deliveryList, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data.results), [schemas.jobSchema]);
    const totalCount = response.data.count ? response.data.count : 1;
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setPaginationResult(
      constants.DELIVERY_LIST,
      params.page,
      normalized.result,
      totalCount
    ));
    yield put(setLoading(constants.DELIVERY_LIST, false));
  } catch (error) {
    yield put(actions.deliveryListFailure(error));
    yield put(setLoading(constants.DELIVERY_LIST, false));
  // yield fork(
  //   _list,
  //   action,
  //   API.deliveryList,
  //   constants.DELIVERY_LIST,
  //   constants.DELIVERY_LIST,
  //   actions.deliveryListSuccess,
  //   actions.deliveryListFailure
  // )
  }
}

// Pending List ================================================================
function* pendingList(action) {
  yield fork(
    _list,
    action,
    API.pendingList,
    constants.PENDING_LIST,
    constants.PENDING_LIST,
    actions.pendingListSuccess,
    actions.pendingListFailure
  )
}

// Preparing List ==============================================================
function* preparingList(action) {
  yield put(setLoading(constants.PREPARING_LIST, true));
  const { params } = action.payload;
  try {
    const response = yield call(API.preparingList, params);
    const normalized = normalize(camelizeKeys(response.data), [schemas.jobSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setResult(constants.PREPARING_LIST, normalized.result));
    yield put(setLoading(constants.PREPARING_LIST, false));
  } catch (error) {
    yield put(actions.preparingListFailure(error));
    yield put(setLoading(constants.PREPARING_LIST, false));
  }
}

// ToTra List ==================================================================
function* totraList(action) {
  yield fork(
    _list,
    action,
    API.totraList,
    constants.TOTRA_LIST,
    constants.TOTRA_LIST,
    actions.totraListSuccess,
    actions.totraListFailure
  )
}

// RnQ List ==================================================================
function* rnqList(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.RNQ_LIST, true));
  try {
    const response = yield call(API.rnqList, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data.results), [schemas.jobSchema]);
    const totalCount = response.data.count ? response.data.count : 1;
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setPaginationResult(
      constants.RNQ_LIST,
      params.page,
      normalized.result,
      totalCount
    ));
    yield put(setLoading(constants.RNQ_LIST, false));
  } catch (error) {
    yield put(actions.rnqListFailure(error));
    yield put(setLoading(constants.RNQ_LIST, false));
  }
}

// Create ======================================================================
function* create(action) {
  const { values } = action.payload;
  yield put(setLoading(constants.JOB_CREATE, true));
  try {
    const response = yield call(API.pendingCreate, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addItem(constants.PENDING_LIST, normalized.result));
    yield fork(closeCurrentTab)
    yield fork(coreSagas.successNotification, 'Pending job created successfully.');
    yield put(setLoading(constants.JOB_CREATE, false));
  } catch (error) {
    yield put(actions.createFailure(error));
    yield put(setLoading(constants.JOB_CREATE, false));
  }
}

// Draft =======================================================================
function* draft(action) {
  const { values } = action.payload;
  yield put(setLoading(constants.JOB_CREATE, true));
  try {
    const response = yield call(API.pendingDraft, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addItem(constants.JOB_DRAFT_LIST, normalized.result));
    yield fork(closeCurrentTab)
    yield fork(coreSagas.successNotification, 'Job draft created successfully.');
    yield put(setLoading(constants.JOB_CREATE, false));
  } catch (error) {
    yield put(actions.draftFailure(error));
    yield put(setLoading(constants.JOB_CREATE, false));
  }
}

// duplicate, templateBy =======================================================
function* _createBy(action, api, listName, loadingKey, failureAction, successMessage) {
  const { form, values } = action.payload;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(api, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addItem(listName, normalized.result));
    yield fork(closeCurrentTab)
    yield fork(coreSagas.successNotification, successMessage);
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    if (error.response.status === 400) {
      if (error.response.data.branch) {
        yield put(stopSubmit(form, { branch: 'Sorry, that number already exists' }));
      }
    }
    yield put(failureAction(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Duplicate create ============================================================
function* duplicateCreate(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.JOB_DUPLICATE}/${id}`;
  yield fork(
    _createBy,
    action,
    API.pendingCreate,
    constants.PENDING_LIST,
    loadingKey,
    actions.duplicateCreateFailure,
    'Pending job created successfully.'
  )
}

// Duplicate draft =============================================================
function* duplicateDraft(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.JOB_DUPLICATE}/${id}`;
  yield fork(
    _createBy,
    action,
    API.pendingDraft,
    constants.JOB_DRAFT_LIST,
    loadingKey,
    actions.duplicateDraftFailure,
    'Job draft created successfully.'
  )
}

// Template by create ============================================================
function* templateByCreate(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.JOB_CREATE_BY_TEMPLATE}/${id}`;
  yield fork(
    _createBy,
    action,
    API.pendingCreate,
    constants.PENDING_LIST,
    loadingKey,
    actions.templateByCreateFailure,
    'Pending job created successfully.'
  );
}

// Template by draft =============================================================
function* templateByDraft(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.JOB_CREATE_BY_TEMPLATE}/${id}`;
  yield fork(
    _createBy,
    action,
    API.pendingDraft,
    constants.JOB_DRAFT_LIST,
    loadingKey,
    actions.templateByDraftFailure,
    'Job draft created successfully.'
  );
}

// Split create =============================================================
function* splitCreate(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.JOB_SPLIT}/${id}`;
  yield fork(
    _createBy,
    action,
    API.splitCreate,
    constants.PENDING_LIST,
    loadingKey,
    actions.splitCreateFailure,
    'Pending job created successfully.'
  );
}
// Split by draft =============================================================
function* splitDraft(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.JOB_SPLIT}/${id}`;
  yield fork(
    _createBy,
    action,
    API.splitDraft,
    constants.JOB_DRAFT_LIST,
    loadingKey,
    actions.splitDraftFailure,
    'Job draft created successfully.'
  );
}

// Draft Update ================================================================
function* draftUpdate(action) {
  const { form, id, values } = action.payload;
  const loadingKey = `${constants.JOB_DRAFT_EDIT}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.draftUpdate, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield fork(coreSagas.successNotification, 'Job draft updated successfully.');
    yield fork(closeCurrentTab)
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.draftUpdateFailure(error));
    if (error.response.status === 400) {
      if (error.response.data.base_number) {
        yield put(stopSubmit(form, { base_number: 'That base number is already taken.' }));
      }
      if (error.response.data.branch) {
        yield put(stopSubmit(form, { branch: 'That issue number is already taken.' }))
      }
    }
    yield put(setLoading(loadingKey, false));
  }
}

// Draft To Pending ============================================================
function* draftToPending(action) {
  const { form, id, values } = action.payload;
  const loadingKey = `${constants.JOB_DRAFT_EDIT}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.draftToPending, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.removeItem(constants.JOB_DRAFT_LIST, normalized.result));
    yield put(listActions.addItem(constants.PENDING_LIST, normalized.result));
    // yield put(listActions.addItem(constants.WORKING_LIST, normalized.result));
    yield fork(closeCurrentTab)
    yield fork(coreSagas.successNotification, 'Pending job created successfully.');
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.draftToPendingFailure(error));
    if (error.response.status === 400) {
      if (error.response.data.base_number) {
        yield put(stopSubmit(form, { base_number: 'That base number is already taken.' }));
      }
      if (error.response.data.branch) {
        yield put(stopSubmit(form, { branch: 'That issue number is already taken.' }))
      }
    }
    yield put(setLoading(loadingKey, false));
  }
}


// Split Issue =================================================================
function* splitIssue(action) {
  const { form, id, values } = action.payload;
  const loadingKey = `${constants.JOB_SPLIT_ISSUE}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.splitIssue, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addItem(constants.PREPARING_LIST, normalized.result));
    yield fork(closeCurrentTab)
    yield fork(coreSagas.successNotification, 'Job issued successfully.');
    const　tabConfig = getDetailTabConfigByName(
      constants.JOB_DETAIL,
      response.data.id,
      response.data.number,
      { id: response.data.id }
    )
    yield put(addOrSelectTab(
      tabConfig['id'],
      tabConfig['title'],
      tabConfig['componentPath'],
      tabConfig['componentName'],
      tabConfig['params'],
      tabConfig['allowRefresh'])
    )
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.splitIssueFailure(error));
    if (error.response.status === 400) {
      if (error.response.data.base_number) {
        yield put(stopSubmit(form, { base_number: 'That base number is already taken.' }));
      }
      if (error.response.data.branch) {
        yield put(stopSubmit(form, { branch: 'That issue number is already taken.' }))
      }
    }
    yield put(setLoading(loadingKey, false));
  }
}

// Issue =======================================================================
function* issue(action) {
  const { form, id, values } = action.payload;
  const loadingKey = `${constants.JOB_ISSUE}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.issue, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.removeItem(constants.PENDING_LIST, normalized.result));
    yield put(listActions.addItem(constants.PREPARING_LIST, normalized.result));
    yield fork(closeCurrentTab)
    yield fork(coreSagas.successNotification, 'Job issued successfully.');
    const　tabConfig = getDetailTabConfigByName(
      constants.JOB_DETAIL,
      response.data.id,
      response.data.number,
      { id: response.data.id }
    )
    yield put(addOrSelectTab(
      tabConfig['id'],
      tabConfig['title'],
      tabConfig['componentPath'],
      tabConfig['componentName'],
      tabConfig['params'],
      tabConfig['allowRefresh'])
    )
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.issueFailure(error));
    if (error.response.status === 400) {
      if (error.response.data.base_number) {
        yield put(stopSubmit(form, { base_number: 'That base number is already taken.' }));
      }
      if (error.response.data.branch) {
        yield put(stopSubmit(form, { branch: 'That issue number is already taken.' }))
      }
    }
    yield put(setLoading(loadingKey, false));
  }
}

// Edit ========================================================================
function* edit(action) {
  const { form, id, values } = action.payload;
  const loadingKey = `${constants.JOB_EDIT}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.edit, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield fork(closeCurrentTab)
    const　tabConfig = getDetailTabConfigByName(
      constants.JOB_DETAIL,
      response.data.id,
      response.data.number,
      { id: response.data.id }
    )
    yield put(addOrSelectTab(
      tabConfig['id'],
      tabConfig['title'],
      tabConfig['componentPath'],
      tabConfig['componentName'],
      tabConfig['params'],
      tabConfig['allowRefresh'])
    )
    yield fork(coreSagas.successNotification, 'Job updated successfully.');
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.editFailure(error));
    if (error.response.status === 400) {
      if (error.response.data.base_number) {
        yield put(stopSubmit(form, { base_number: 'That base number is already taken.' }));
      }
      if (error.response.data.branch) {
        yield put(stopSubmit(form, { branch: 'That issue number is already taken.' }))
      }
    }
    yield put(setLoading(loadingKey, false));
  }
}

function* paymentSearch(action) {
  yield put(setLoading(constants.PAYMENT_SEARCH, true))
  try {
    const { params } = action.payload;
    const response = yield call(API.paymentSearch, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data), [schemas.paymentSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setResult(constants.PAYMENT_SEARCH, normalized.result));
    yield put(setLoading(constants.PAYMENT_SEARCH, false))
  } catch (error) {
    yield put(actions.paymentSearchFailure(error));
    yield put(setLoading(constants.PAYMENT_SEARCH, false))
  }
}

// Remove ======================================================================
function* remove(action) {
  const { item } = action.payload;
  const title = item.number || item.id;
  const message = `Are you sure to delete ${title} ?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      yield call(API.remove, item.id);
      yield put(listActions.removeItem(constants.PREPARING_LIST, item.id));
      yield put(listActions.removeItem(constants.TOTRA_LIST, item.id));
      yield fork(closeCurrentTab);
      yield put(closeTab(`${constants.JOB_DETAIL}/${item.id}`));
      yield fork(coreSagas.successNotification, 'Job deleted successfuly.');
      yield put(entityActions.remove('jobs', item.id));
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.removeFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

// Remove Draft ================================================================
function* removeDraft(action) {
  const { item } = action.payload;
  const title = item.number || item.id;
  const message = `Are you sure to delete ${title} ?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      yield call(API.remove, item.id);
      yield put(listActions.removeItem(constants.JOB_DRAFT_LIST, item.id));
      yield fork(closeCurrentTab);
      yield fork(coreSagas.successNotification, 'Draft Job deleted successfuly.');
      yield put(entityActions.remove('jobs', item.id));
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.removeFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

// Remove Pending ==============================================================
function* removePending(action) {
  const { item } = action.payload;
  const title = item.number || item.id;
  const message = `Are you sure to delete ${title} ?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      yield call(API.remove, item.id);
      yield put(listActions.removeItem(constants.PENDING_LIST, item.id));
      yield put(listActions.removePaginationItem(constants.MHT_JOB_LIST, item.id));
      yield fork(closeCurrentTab);
      yield fork(coreSagas.successNotification, 'Pending Job deleted successfuly.');
      yield put(entityActions.remove('jobs', item.id));
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.removeFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

// Cancel ======================================================================
function* cancel(action) {
  const { item } = action.payload;
  const message = `Are you sure to cancel ${item.number} ?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      const response = yield call(API.cancel, item.id);
      const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
      yield put(entityActions.merge(normalized.entities));
      yield fork(coreSagas.successNotification, 'Job canceled successfully.');
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.cancelFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

// Print =======================================================================
function* print(action) {
  const { id } = action.payload;
  try {
    yield call(API.print, id);
  } catch (error) {
    yield put(actions.printFailure(error));
  }
}

// Process =====================================================================
function* process(action) {
  yield put(setLoading(constants.JOB_PROCESS_MODAL, true));
  const { id, values } = action.payload
  try {
    const response = yield call(API.process, id, decamelizeKeys(values))
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(actions.processSuccess());
    yield fork(coreSagas.successNotification, 'Job processed successfully.');
    yield put(hide(constants.JOB_PROCESS_MODAL));
    yield put(setLoading(constants.JOB_PROCESS_MODAL, false));
  } catch (error) {
    yield put(actions.processFailure(error));
    yield put(setLoading(constants.JOB_PROCESS_MODAL, false));
  }
}

// Quick edit ==================================================================
function* quickEdit(action) {
  const { id, values, form } = action.payload;
  yield put(setLoading(form, true));
  try {
    const response = yield call(API.edit, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield fork(coreSagas.successNotification, 'Job updated successfully.');
    yield put(setLoading(form, false));
  } catch (error) {
    yield put(actions.quickEditFailure(error));
    yield put(setLoading(form, false));
  }
}

// Invoice List ================================================================
function* invoiceList(action) {
  const { id } = action.payload;
  const loadKey = `${constants.JOB_INVOICE_LIST}/${id}`;
  yield put(setLoading(loadKey, true));
  try {
    const response = yield call(API.invoiceList, id);
    const normalized = normalize(camelizeKeys(response.data), [schemas.invoiceSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setNestedResult(constants.JOB_INVOICE_LIST, id, normalized.result));
    yield put(setLoading(loadKey, false));
  } catch (error) {
    yield put(actions.invoiceListFailure(error));
    yield put(setLoading(loadKey, false));
  }
}


const prepareSuggestParams = (values) => {
  const params = {};
  params['level'] = values.level;
  params['client'] = values.client;
  params['service'] = values.service;
  params['industry'] = values.industry;
  params['field'] = values.field;
  params['target_count'] = values.targetCount;
  params['deadline_date'] = values.deadlineDate;
  params['deadline_time'] = values.deadlineTime;
  params['additional_count'] = values.additionalCount;
  params['additional_count_roles'] = values.additionalCountRoles;
  params['require_dtp'] = values.requireDtp;
  params['require_totra'] = values.requireTotra;
  return params;
}

function* suggestAssignment(action) {
  const { form, values } = action.payload;
  yield put(setLoading(form, true));
  try {
    const params = prepareSuggestParams(values);
    const response = yield call(API.suggestAssignment, params);
    const data = camelizeKeys(response.data);
    const suggestOptions = {};
    Object.keys(data).forEach(key => {
      suggestOptions[key] = data[key]['options'];
    });
    yield put(actions.setAssigneeOptions(form, suggestOptions));
    yield all(Object.keys(data).map(key => (
      put(change(form, key, data[key]['value']))
    )));
    const suggestErrors = Object.keys(data).filter(key => data[key]['error']);
    yield all(suggestErrors.map(key => (
      fork(coreSagas.errorNotification, data[key]['error'])
    )))

    yield put(setLoading(form, false));
  } catch (error) {
    yield put(actions.suggestAssignmentFailure(error));
    yield put(setLoading(form, false));
  }
}

function* refreshAssignment(action) {
  const { form, values } = action.payload;
  yield put(setLoading(form, true));
  try {
    const params = prepareSuggestParams(values);
    const response = yield call(API.suggestAssignment, params);
    const data = camelizeKeys(response.data);
    const suggestOptions = {};
    Object.keys(data).forEach(key => {
      suggestOptions[key] = data[key]['options'];
    });
    yield put(actions.setAssigneeOptions(form, suggestOptions));

    const suggestErrors = Object.keys(data).filter(key => data[key]['error']);
    yield all(suggestErrors.map(key => (
      fork(coreSagas.errorNotification, data[key]['error'])
    )))

    yield put(setLoading(form, false));
  } catch (error) {
    yield put(actions.suggestAssignmentFailure(error));
    yield put(setLoading(form, false));
  }
}

function* assignmentFinish(action) {
  const { assignment } = action.payload;
  const message = `Are you sure to finish ${assignment.role.name} assignment ?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      const response = yield call(API.assignmentPatch, assignment.id, { status: 'Finished' });
      const normalized = normalize(camelizeKeys(response.data), schemas.assignmentSchema);
      yield put(entityActions.merge(normalized.entities));
      yield fork(coreSagas.successNotification, 'Assignment finished successfully.');
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.assignmentFinishFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}


function* assignmentPaymentCheck(action) {
  const { id, values } = action.payload;
  try {
    const response = yield call(API.assignmentPatch, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.paymentSchema);
    yield put(entityActions.merge(normalized.entities));
  } catch (error) {
    yield put(actions.assignmentPaymentCheckFailure(error));
  }
}


// Auto complete ===============================================================
function* autocomplete(action) {
  const { q } = action.payload;
  yield put(setLoading(constants.JOB_NUMBER_AUTO_COMPLETE, true));
  yield call(delay, 700);
  try {
    const response = yield call(API.autocomplete, { q });
    const normalized = normalize(camelizeKeys(response.data), [schemas.jobSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(constants.JOB_NUMBER_AUTO_COMPLETE, false));
  } catch (error) {
    yield put(actions.autocompleteFailure(error));
    yield put(setLoading(constants.JOB_NUMBER_AUTO_COMPLETE, false));
  }
}

// Bulk verify =============================================================
function* bulkVerify(action) {
  const { values } = action.payload;
  const jobCount = values.jobs.length;
  const title = jobCount > 1 ? `${jobCount} jobs` : `${jobCount} job`;
  const message = `Are you sure to verify ${title} ?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      yield call(API.bulkVerify, values);
      
      yield all(values.jobs.map(id => (
        put(listActions.removePaginationItem(constants.RNQ_LIST, id))
      )));
      yield fork(coreSagas.successNotification, 'Job verified successfully.');
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.bulkVerfiyFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

// Quote Source File List ========================================================
function* quoteSourcefileList(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.QUOTE_SOURCEFILE}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.quoteSourcefileList, id);
    const normalized = normalize(camelizeKeys(response.data), [schemas.quoteSourcefileSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setNestedResult(constants.QUOTE_SOURCEFILE, id, normalized.result));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.quoteSourcefileListFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// MHT Job List ===============================================================
function* mhtJobList(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.MHT_JOB_LIST, true));
  try {
    const response = yield call(API.mhtJobList, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data.results), [schemas.jobSchema]);
    const totalCount = response.data.count ? response.data.count : 1;
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setPaginationResult(
      constants.MHT_JOB_LIST,
      params.page,
      normalized.result,
      totalCount
    ));
    yield put(setLoading(constants.MHT_JOB_LIST, false));
  } catch (error) {
    yield put(actions.mhtJobListFailure(error));
    yield put(setLoading(constants.MHT_JOB_LIST, false));
  }
}

// MHT Job Retrieve ===============================================================
function* mhtJobRetrieve(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.MHT_JOB_DETAIL}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.mhtJobRetrieve, id);
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.retrieveFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

function* mhtJobMessageList(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.MHT_JOB_MESSAGE}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.mhtJobMessageList, id);
    const normalized = normalize(camelizeKeys(response.data), [schemas.mhtJobMessageSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setNestedResult(constants.MHT_JOB_MESSAGE, id, normalized.result));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.mhtJobMessageListFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

function* mhtJobMessageCreate(action) {
  yield put(setLoading(constants.MHT_JOB_MESSAGE_MODAL, true));
  const { values } = action.payload;
  try {
    const response = yield call(API.mhtJobMessageCreate, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.mhtJobMessageSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addNestedItem(constants.MHT_JOB_MESSAGE, values.job, normalized.result));
    yield put(hide(constants.MHT_JOB_MESSAGE_MODAL));
    yield put(setLoading(constants.MHT_JOB_MESSAGE_MODAL, false));
  } catch (error) {
    yield put(actions.mhtJobMessageCreateFailure(error));
    yield put(setLoading(constants.MHT_JOB_MESSAGE_MODAL, false));
  }
}

function* mhtJobMessageRead(action) {
  const { id } = action.payload;
  const message = `Are you sure to read messages ?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true));
      const response = yield call(API.mhtJobMessageRead, id);
      const normalized = normalize(camelizeKeys(response.data), schemas.mhtJobMessageSchema);
      yield put(entityActions.merge(normalized.entities));
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false));
    } catch (error) {
      yield put(actions.mhtJobMessageReadFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

function* mhtJobMessageBatchRead(action) {
  const { values } = action.payload;
  const message = `Are you sure to read all messages ?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      const response = yield call(API.mhtJobMessageBatchRead, values);
      const normalized = normalize(camelizeKeys(response.data), [schemas.mhtJobMessageSchema]);
      yield put(entityActions.merge(normalized.entities));
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false));
    } catch (error) {
      yield put(actions.mhtJobMessageBatchReadFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false));
    }
  }
}

function* mhtJobDelivery(action) {
  const { id } = action.payload;
  const message = `Are you sure to deliver?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      const response = yield call(API.mhtJobDelivery, id)
      const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
      yield put(entityActions.merge(normalized.entities));
      yield put(actions.mhtJobDeliverySuccess());
      yield fork(coreSagas.successNotification, 'Job delivered successfully.');
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.mhtJobDeliveryFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

function* mhtJobRedelivery(action) {
  const { id } = action.payload;
  const message = `Are you sure to redeliver?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      yield call(API.mhtJobRedelivery, id)
      yield put(actions.mhtJobRedeliverySuccess());
      yield fork(coreSagas.successNotification, 'Job redelivered successfully.');
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.mhtJobRedeliveryFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

function* mhtInvoiceDownload(action) {
  yield put(show(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  const { values } = action.payload;
  try {
    const [downloadPromise, chan] = yield call(
      coreSagas.createDownloader,
      API.mhtInvoiceDownload,
      values
    );
    yield fork(coreSagas.watchDownloadProgress, chan);
    const response = yield call(() => downloadPromise);
    const filename = `invoice.pdf`;
    yield call(saveFile, response.data, filename, 'application/pdf');
    yield delay(1000);
    yield put(hide(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
    yield put(hide(constants.MHT_INVOICE_DOWNLOAD_MODAL))
  } catch (error) {
    yield put(actions.mhtInvoiceDownloadFailure(error));
  } finally {
    yield put(setDownloadProgress(0));
  }
}

function* watchRetrieve() {
  yield takeLatest(actionTypes.RETRIEVE.REQUEST, retrieve);
}
function* watchPatch() {
  yield takeLatest(actionTypes.PATCH.REQUEST, patch);
}

function* watchDailySchedule() {
  yield takeLatest(actionTypes.DAILY_SCHEDULE.REQUEST, dailySchdule);
}
function* watchFutureSchedule() {
  yield takeLatest(actionTypes.FUTURE_SCHEDULE.REQUEST, futureSchdule);
}
function* watchRearrange() {
  yield takeLatest(actionTypes.REARRANGE.REQUEST, rearrange);
}
function* watchFetchLatestNumber() {
  yield takeLatest(actionTypes.FETCH_LATEST_NUMBER.REQUEST, fetchLatestNumber);
}
function* watchMemberList() {
  yield takeLatest(actionTypes.MEMBER_LIST.REQUEST, memberList);
}
function* watchSearch()  {
  yield takeLatest(actionTypes.JOB_SEARCH.REQUEST, search);
}
function* watchDraftList() {
  yield takeLatest(actionTypes.DRAFT_LIST.REQUEST, draftList);
}
// function* watchWorkingList() {
//   yield takeLatest(actionTypes.WORKING_LIST.REQUEST, workingList);
// }
function* watchDeliveryList() {
  yield takeLatest(actionTypes.DELIVERY_LIST.REQUEST, deliveryList);
}
function* watchPendingList() {
  yield takeLatest(actionTypes.PENDING_LIST.REQUEST, pendingList);
}
function* watchPreparingList() {
  yield takeLatest(actionTypes.PREPARING_LIST.REQUEST, preparingList);
}
function* watchToTraList() {
  yield takeLatest(actionTypes.TOTRA_LIST.REQUEST, totraList);
}
function* watchRnQList() {
  yield takeLatest(actionTypes.RNQ_LIST.REQUEST, rnqList);
}
function* watchCreate() {
  yield takeLatest(actionTypes.CREATE.REQUEST, create);
}
function* watchDraft() {
  yield takeLatest(actionTypes.DRAFT.REQUEST, draft);
}
function* watchDuplicateCreate() {
  yield takeLatest(actionTypes.DUPLICATE_CREATE.REQUEST, duplicateCreate);
}
function* watchDuplicateDraft() {
  yield takeLatest(actionTypes.DUPLICATE_DRAFT.REQUEST, duplicateDraft);
}
function* watchTemplateByCreate() {
  yield takeLatest(actionTypes.TEMPLATE_BY_CREATE.REQUEST, templateByCreate);
}
function* watchTemplateByDraft() {
  yield takeLatest(actionTypes.TEMPLATE_BY_DRAFT.REQUEST, templateByDraft);
}
function* watchSplitCreate() {
  yield takeLatest(actionTypes.SPLIT_CREATE.REQUEST, splitCreate);
}
function* watchSplitDraft() {
  yield takeLatest(actionTypes.SPLIT_DRAFT.REQUEST, splitDraft);
}
function* watchSplitIssue() {
  yield takeLatest(actionTypes.SPLIT_ISSUE.REQUEST, splitIssue);
}
function* watchDraftUpdate() {
  yield takeLatest(actionTypes.DRAFT_UPDATE.REQUEST, draftUpdate);
}
function* watchDraftToPending() {
  yield takeLatest(actionTypes.DRAFT_TO_PENDING.REQUEST, draftToPending);
}
function* watchIssue() {
  yield takeLatest(actionTypes.ISSUE.REQUEST, issue);
}
function* watchEdit() {
  yield takeLatest(actionTypes.EDIT.REQUEST, edit);
}
function* watchQuickEdit() {
  yield takeLatest(actionTypes.QUICK_EDIT.REQUEST, quickEdit);
}
function* watchCancel() {
  yield takeLatest(actionTypes.CANCEL.REQUEST, cancel);
}
function* watchRemove() {
  yield takeLatest(actionTypes.REMOVE.REQUEST, remove);
}
function* watchRemoveDraft() {
  yield takeLatest(actionTypes.REMOVE_DRAFT.REQUEST, removeDraft);
}
function* watchRemovePending() {
  yield takeLatest(actionTypes.REMOVE_PENDING.REQUEST, removePending);
}
function* watchPrint() {
  yield takeLatest(actionTypes.PRINT.REQUEST, print);
}
function* watchProcess() {
  yield takeLatest(actionTypes.PROCESS.REQUEST, process);
}
function* watchInvoiceList() {
  yield takeLatest(actionTypes.INVOICE_LIST.REQUEST, invoiceList);
}
function* watchPaymentSearch() {
  yield takeLatest(actionTypes.PAYMENT_SEARCH.REQUEST, paymentSearch);
}
function* watchSuggestAssignment() {
  yield takeLatest(actionTypes.SUGGEST_ASSIGNMENT.REQUEST, suggestAssignment);
}
function* watchRefreshAssignment() {
  yield takeLatest(actionTypes.REFRESH_ASSIGNMENT.REQUEST, refreshAssignment);
}
function* watchAssignmentFinish() {
  yield takeLatest(actionTypes.ASSIGNMENT_FINISH.REQUEST, assignmentFinish);
}
function* watchAssignmentPaymentCheck() {
  yield takeLatest(actionTypes.ASSIGNMENT_PAYMENT_CHECK.REQUEST, assignmentPaymentCheck);
}
function* watchAutoComplete() {
  yield takeLatest(actionTypes.AUTOCOMPLETE.REQUEST, autocomplete);
}
function* watchBulkVerify() {
  yield takeLatest(actionTypes.BULK_VERIFY.REQUEST, bulkVerify);
}
function* watchQuoteSourefileList() {
  yield takeLatest(actionTypes.QUOTE_SOURCEFILE_LIST.REQUEST, quoteSourcefileList);
}

function* watchMHTJobList() {
  yield takeLatest(actionTypes.MHT_JOB_LIST.REQUEST, mhtJobList);
}
function* watchMHTJobRertrieve() {
  yield takeLatest(actionTypes.MHT_JOB_RETRIEVE.REQUEST, mhtJobRetrieve);
}
function* watchMHTJobMessageList() {
  yield takeLatest(actionTypes.MHT_JOB_MESSAGE_LIST.REQUEST, mhtJobMessageList);
}
function* watchMHTJobMessageCreate() {
  yield takeLatest(actionTypes.MHT_JOB_MESSAGE_CREATE.REQUEST, mhtJobMessageCreate);
}
function* watchMHTJobMessageRead() {
  yield takeLatest(actionTypes.MHT_JOB_MESSAGE_READ.REQUEST, mhtJobMessageRead);
}
function* watchMHTJobMessageBatchRead() {
  yield takeLatest(actionTypes.MHT_JOB_MESSAGE_BATCH_READ.REQUEST, mhtJobMessageBatchRead);
}
function* watchMHTJobDelivery() {
  yield takeLatest(actionTypes.MHT_JOB_DELIVERY.REQUEST, mhtJobDelivery);
}
function* watchMHTJobRedelivery() {
  yield takeLatest(actionTypes.MHT_JOB_REDELIVERY.REQUEST, mhtJobRedelivery);
}
function* watchMHTInvoiceDownload() {
  yield takeLatest(actionTypes.MHT_INVOICE_DOWNLOAD.REQUEST, mhtInvoiceDownload);
}



export {
  watchRetrieve,
  watchPatch,
  watchDailySchedule,
  watchFutureSchedule,
  watchRearrange,
  watchFetchLatestNumber,
  watchMemberList,
  watchSearch,
  watchDraftList,
  // watchWorkingList,
  watchDeliveryList,
  watchPendingList,
  watchPreparingList,
  watchToTraList,
  watchRnQList,
  watchCreate,
  watchDraft,
  watchDuplicateCreate,
  watchDuplicateDraft,
  watchTemplateByCreate,
  watchTemplateByDraft,
  watchSplitCreate,
  watchSplitDraft,
  watchSplitIssue,
  watchDraftUpdate,
  watchDraftToPending,
  watchIssue,
  watchEdit,
  watchQuickEdit,
  watchCancel,
  watchRemove,
  watchRemoveDraft,
  watchRemovePending,
  watchPrint,
  watchProcess,
  watchInvoiceList,
  watchPaymentSearch,
  watchSuggestAssignment,
  watchRefreshAssignment,
  watchAssignmentFinish,
  watchAssignmentPaymentCheck,
  watchAutoComplete,
  watchBulkVerify,
  watchMHTJobList,
  watchMHTJobRertrieve,
  watchMHTJobMessageList,
  watchMHTJobMessageCreate,
  watchMHTJobMessageRead,
  watchMHTJobMessageBatchRead,
  watchMHTJobDelivery,
  watchMHTJobRedelivery,
  watchMHTInvoiceDownload,
  watchQuoteSourefileList,
}