import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { JOB_DRAFT_EDIT_FORM } from 'misc/constants';
import { retrieveRequest, removeDraftRequest, draftUpdateRequest, draftToPendingRequest } from 'actions/jobs/managers';
import { isJobDraftEditLoading } from 'selectors/loading';
import { getJobById } from 'selectors/jobs';
import { getJobDraftEditFormInitialValuesById } from 'selectors/forms';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobDraftEdit from './presenter';


class JobDraftEditContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.retrieveRequest(this.props.id);
  }

  handleCreate = (values) => {
    this.props.draftToPendingRequest(this.props.form, this.props.id, values);
  }

  handleUpdate = (values) => {
    this.props.draftUpdateRequest(this.props.form, this.props.id, values);
  }

  handleRemove = () => {
    this.props.removeDraftRequest(this.props.job);
  }

  render() {
    return (
      <JobDraftEdit
        {...this.props}
        handleCreate={this.handleCreate}
        handleUpdate={this.handleUpdate}
        handleRemove={this.handleRemove} />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.params;
  const form = `${JOB_DRAFT_EDIT_FORM}/${id}`;
  const job = getJobById(state, id);
  const isSplit = job ? !!job.number : false;
  const formSelector = formValueSelector(form);
  const baseNumber = formSelector(state, 'baseNumber');
  return {
    id,
    form,
    job,
    isSplit,
    baseNumber,
    initialValues: getJobDraftEditFormInitialValuesById(state, id),
    isLoading: isJobDraftEditLoading(state, id)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    retrieveRequest,
    removeDraftRequest,
    draftUpdateRequest,
    draftToPendingRequest
  }, disptach)
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDraftEditContainer);
