import React from "react";
import * as PropTypes from 'prop-types';
import './taTable.css';

export class TaTableCellGroup extends React.PureComponent {

  render() {
    const {
      children,
      className,
      ...attributes
    } = this.props;

    const baseClass = 'ta-table-cellGroup';
    let classNames = [baseClass];
    if (className) {
      classNames.push(className);
    }

    return (<div className={classNames.join(' ')} {...attributes} children={children}/>);
  }

}
