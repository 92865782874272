import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Button, Dropdown, MenuItem } from 'react-bootstrap';
import JobDetailMemberSelect from './JobDetailMemberSelect';


const JobDetailTools = (props) => {

  const {
    id,
    isAE,
    isCoordinator,
    jobType,
    manuallyProcessOptions,
    handlePrint,
    handleProcess,
    getNextStatus,
    showJobTemplateModal,
    handleTabOpen,
    handleDuplicateTabOpen,
    handleSplitTabOpen,
    handleSplitIssueTabOpen,
    handleEditTabOpen,
  } = props;
  
  return (
    <ul className="job-detail-tools list-unstyled list-inline pull-right">
      <li>
        <JobDetailMemberSelect
          jobId={id}
          handleTabOpen={handleTabOpen} />
      </li>
      <li>
        <Button
          bsSize="sm"
          onClick={handlePrint}>
          <FontAwesome name="print" className="mr-5"/>Print
        </Button>
      </li>
      { isAE &&
        <li>
          <button
            className="btn btn-sm bg-black"
            onClick={showJobTemplateModal}>
            <FontAwesome name="plus-circle" className="mr-5"/>Template
          </button>
        </li>
      }
      { isAE &&
        <li>
          <button
            className="btn bg-purple btn-sm"
            onClick={handleDuplicateTabOpen}>
            <FontAwesome name="copy" className="mr-5"/>Duplicate
          </button>
        </li>
      }
      <li>
        <Dropdown id="split-button">
          <Button bsStyle="primary" bsSize="small">
            <FontAwesome name="code-fork" className="mr-5"/>Split
          </Button>
          <Dropdown.Toggle bsStyle="primary" bsSize="small" />
          <Dropdown.Menu>
            { isAE && jobType !== 'TT' &&
              <MenuItem
                eventKey="1"
                onClick={handleSplitTabOpen}>
                Create Pending Job
              </MenuItem>
            }
            { isCoordinator &&
              <MenuItem
                eventKey="2"
                onClick={handleSplitIssueTabOpen}>
                Issue Job
              </MenuItem>
            }
          </Dropdown.Menu>
        </Dropdown>
      </li>
      { isCoordinator &&
        <li>
          <button
            className="btn btn-sm bg-navy"
            onClick={handleEditTabOpen}>
            <FontAwesome name="edit" className="mr-5"/>Edit
          </button>
        </li>
      }
      <li>
        <Dropdown id="manually-process">
          <Button bsStyle="danger" bsSize="small">
            <FontAwesome name="forward" className="mr-5"/>Manually Process
          </Button>
          <Dropdown.Toggle bsStyle="danger"  bsSize="small" />
          <Dropdown.Menu>
            { manuallyProcessOptions.map(option => (
              <MenuItem
                key={option.value}
                eventKey={option.value}
                onClick={() => handleProcess(option)}>
                {option.label}
              </MenuItem>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </li>
      { isCoordinator &&
        <li>
          <button
            className="btn btn-sm bg-olive"
            onClick={() => handleProcess(getNextStatus())}>
            <FontAwesome name="play" className="mr-5"/>Process
          </button>
        </li>
      }
    </ul>
  );
}

export default JobDetailTools;