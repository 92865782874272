import React from 'react';
import { Tab } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import EmployeeJobScheduleTable from './EmployeeJobScheduleTable';


const EmployeeJobScheduleTabPane = (props) => {

  const {
    eventKey,
    myUserId,
    jobs,
    isLoading,
    handleTabOpen
  } = props;
  
  return (
    <Tab.Pane eventKey={eventKey}>
      <Box
        className="box-widget"
        loading={isLoading}>
        <Box.Body
          noPadding={true}>
          <EmployeeJobScheduleTable
            jobs={jobs}
            myUserId={myUserId}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
      </Box>
    </Tab.Pane>
  );
}

export default EmployeeJobScheduleTabPane;
