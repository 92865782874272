import React from 'react';
import {Checkbox} from 'react-bootstrap';

import {TaModal, TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import MHTApi from "services/MHTApi";

import {TaButton} from "components/taUi/taButton/taButton";
import {TaFormField} from "components/taUi/taFormField/taFormField";
import {TaFormGroup} from "components/taUi/taFormGroup/taFormGroup";
import * as PropTypes from "prop-types";
import {TaFormMessages} from "components/taUi/taFormMessage/taFormMessage";

class TaMhtEditorPreTranslateModalContent extends React.Component {

  static propTypes = {
    mhtId: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onError: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      formMessages: [],
      formData: { include_tentative: true },
    };
  }

  componentDidMount() {
    this.formClear();
  }

  onCancel = () => {
    this.formClear();
    if (typeof this.props.onCancel === 'function') {
      this.props.onCancel();
    }
  };

  onConfirm = (response) => {
    this.formClear();
    if (typeof this.props.onConfirm === 'function') {
      this.props.onConfirm(response.data);
    }
  };

  onError = (error) => {

    let message = 'Error';

    if (
      error.response &&
      error.response.data &&
      error.response.data.file
    ) {
      message += ': Please format the content of the Excel file according to specifications. Use the file generated by "Export Bilingual File" as a reference';
    } else if (error.message) {
      message += ': ' + error.message;
    }

    this.setState({
      errors: [{type: 'error', message: message}],
      isSaving: false
    });
    if (typeof this.props.onError === 'function') {
      this.props.onError();
    }
  };

  formClear = () => {
    this.setState({
      errors: [],
      isSaving: false,
      isDirty: false,
      formData: {
        include_tentative: true
      },
    });
  };

  onSubmit = () => {
    this.setState({
      isSaving: true
    }, () => {
      MHTApi
        .preTranslate(this.props.mhtId, this.state.formData)
        .then(this.onConfirm)
        .catch(this.onError);
    });
  };

  onOptionChange = (key, value) => {
    this.setState({
      isDirty: true,
      formData: {
        [key]: value
      }
    });
  };

  render() {

    const {
      formData,
      isSaving
    } = this.state;

    const {
      onCancel
    } = this.props;

    return (
      <React.Fragment>
        <TaModalHeader
          label={'Pre Translate'}
          onCancel={onCancel}
        />
        <TaModalContent>
          <TaFormGroup>
            <TaFormField label="include tentative">
              <Checkbox
                name="include_tentative"
                value={formData.include_tentative}
                checked={formData.include_tentative}
                onChange={e => this.onOptionChange('include_tentative', e.target.checked)}>
                include tentative
              </Checkbox>
            </TaFormField>
          </TaFormGroup>
          <TaFormMessages messages={this.state.errors}/>
        </TaModalContent>
        <TaModalFooter>
          <TaButton
            type="reset"
            icon={'clear'}
            label={'Cancel'}
            onClick={() => onCancel()}
          />
          <TaButton
            type="submit"
            icon={'check'}
            label={'Submit'}
            onClick={() => this.onSubmit()}
            isLoading={isSaving}
          />
        </TaModalFooter>
      </React.Fragment>
    );
  }
}


class TaMhtEditorPreTranslateModal extends React.Component {

  static propTypes = {
    mhtId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onError: PropTypes.func
  };

  render() {

    const {
      mhtId,
      isOpen,
      onCancel,
      onConfirm,
      onError
    } = this.props;

    return (
      <TaModal
        isOpen={isOpen}
        onCancel={onCancel}
      >
        {(!!isOpen) && (
          <TaMhtEditorPreTranslateModalContent
            mhtId={mhtId}
            onCancel={onCancel}
            onConfirm={onConfirm}
            onError={onError}
          />
        )}
      </TaModal>
    );
  }

}

export default TaMhtEditorPreTranslateModal;
