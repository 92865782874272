import * as types from 'actionTypes/jobs/employees';

// Employee Job Schedule Action Creator ========================================
const createJobScheduleAction = (type) => {
  return {
    requestFn: (currentDate) => ({ type: type.REQUEST, payload: { currentDate } }),
    successFn: () => ({ type: type.SUCCESS }),
    failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
  }
}

// Employee Current Jobs Action Creator ========================================
const createCurrentJobsAction = (type) => {
  return {
    requestFn: () => ({ type: type.REQUEST }),
    successFn: () => ({ type: type.SUCCESS }),
    failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
  }
}

// Employee Job Retrieve Action Creator ========================================
const createRetrieveAction = (type) => {
  return {
    requestFn: (id) => ({ type: type.REQUEST, payload: { id } }),
    successFn: () => ({ type: type.SUCCESS }),
    failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
  }
}

// Employee Job Daily Schedule =================================================
const employeeJobDailySchedule = createJobScheduleAction(types.EMPLOYEE_JOB_DAILY_SCHEDULE);
export const employeeJobDailyScheduleRequest = employeeJobDailySchedule.requestFn;
export const employeeJobDailyScheduleSuccess = employeeJobDailySchedule.successFn;
export const employeeJobDailyScheduleFailure = employeeJobDailySchedule.failureFn;

// Employee Job Future Schedule =================================================
const employeeJobFutureSchedule = createJobScheduleAction(types.EMPLOYEE_JOB_FUTURE_SCHEDULE);
export const employeeJobFutureScheduleRequest = employeeJobFutureSchedule.requestFn;
export const employeeJobFutureScheduleSuccess = employeeJobFutureSchedule.successFn;
export const employeeJobFutureScheduleFailure = employeeJobFutureSchedule.failureFn;

// ToTra Current Jobs ==========================================================
const totraCurrentJobs = createCurrentJobsAction(types.TOTRA_CURRENT_JOBS);
export const totraCurrentJobsRequest = totraCurrentJobs.requestFn;
export const totraCurrentJobsSuccess = totraCurrentJobs.successFn;
export const totraCurrentJobsFailure = totraCurrentJobs.failureFn;

// ToTra Job Retrieve ==========================================================
const totraJobRetrieve = createRetrieveAction(types.TOTRA_JOB_RETRIEVE);
export const totraJobRetrieveRequest = totraJobRetrieve.requestFn;
export const totraJobRetrieveSuccess = totraJobRetrieve.successFn;
export const totraJobRetrieveFailure = totraJobRetrieve.failureFn;

// Translator Current Jobs =====================================================
const translatorCurrentJobs = createCurrentJobsAction(types.TRANSLATOR_CURRENT_JOBS);
export const translatorCurrentJobsRequest = translatorCurrentJobs.requestFn;
export const translatorCurrentJobsSuccess = translatorCurrentJobs.successFn;
export const translatorCurrentJobsFailure = translatorCurrentJobs.failureFn;

// Translator Job Retrieve =====================================================
const translatorJobRetrieve = createRetrieveAction(types.TRANSLATOR_JOB_RETRIEVE);
export const translatorJobRetrieveRequest = translatorJobRetrieve.requestFn;
export const translatorJobRetrieveSuccess = translatorJobRetrieve.successFn;
export const translatorJobRetrieveFailure = translatorJobRetrieve.failureFn;

// Editor Current Jobs =========================================================
const editorCurrentJobs = createCurrentJobsAction(types.EDITOR_CURRENT_JOBS);
export const editorCurrentJobsRequest = editorCurrentJobs.requestFn;
export const editorCurrentJobsSuccess = editorCurrentJobs.successFn;
export const editorCurrentJobsFailure = editorCurrentJobs.failureFn;

// Editor Job Retrieve =========================================================
const editorJobRetrieve = createRetrieveAction(types.EDITOR_JOB_RETRIEVE);
export const editorJobRetrieveRequest = editorJobRetrieve.requestFn;
export const editorJobRetrieveSuccess = editorJobRetrieve.successFn;
export const editorJobRetrieveFailure = editorJobRetrieve.failureFn;

// Checker Current Jobs ========================================================
const checkerCurrentJobs = createCurrentJobsAction(types.CHECKER_CURRENT_JOBS);
export const checkerCurrentJobsRequest = checkerCurrentJobs.requestFn;
export const checkerCurrentJobsSuccess = checkerCurrentJobs.successFn;
export const checkerCurrentJobsFailure = checkerCurrentJobs.failureFn;

// Checker Job Retrieve ========================================================
const checkerJobRetrieve = createRetrieveAction(types.CHECKER_JOB_RETRIEVE);
export const checkerJobRetrieveRequest = checkerJobRetrieve.requestFn;
export const checkerJobRetrieveSuccess = checkerJobRetrieve.successFn;
export const checkerJobRetrieveFailure = checkerJobRetrieve.failureFn;

// Final Editor Current Jobs ===================================================
const finalEditorCurrentJobs = createCurrentJobsAction(types.FINAL_EDITOR_CURRENT_JOBS);
export const finalEditorCurrentJobsRequest = finalEditorCurrentJobs.requestFn;
export const finalEditorCurrentJobsSuccess = finalEditorCurrentJobs.successFn;
export const finalEditorCurrentJobsFailure = finalEditorCurrentJobs.failureFn;

// Final Editor Job Retrieve ===================================================
const finalEditorJobRetrieve = createRetrieveAction(types.FINAL_EDITOR_JOB_RETRIEVE);
export const finalEditorJobRetrieveRequest = finalEditorJobRetrieve.requestFn;
export const finalEditorJobRetrieveSuccess = finalEditorJobRetrieve.successFn;
export const finalEditorJobRetrieveFailure = finalEditorJobRetrieve.failureFn;

// Second Final Editor Current Jobs ============================================
const secondFinalEditorCurrentJobs = createCurrentJobsAction(types.SECOND_FINAL_EDITOR_CURRENT_JOBS);
export const secondFinalEditorCurrentJobsRequest = secondFinalEditorCurrentJobs.requestFn;
export const secondFinalEditorCurrentJobsSuccess = secondFinalEditorCurrentJobs.successFn;
export const secondFinalEditorCurrentJobsFailure = secondFinalEditorCurrentJobs.failureFn;

// Second Final Editor Job Retrieve ============================================
const secondFinalEditorJobRetrieve = createRetrieveAction(types.SECOND_FINAL_EDITOR_JOB_RETRIEVE);
export const secondFinalEditorJobRetrieveRequest = secondFinalEditorJobRetrieve.requestFn;
export const secondFinalEditorJobRetrieveSuccess = secondFinalEditorJobRetrieve.successFn;
export const secondFinalEditorJobRetrieveFailure = secondFinalEditorJobRetrieve.failureFn;

// DTP Current Jobs ============================================================
const dtpCurrentJobs = createCurrentJobsAction(types.DTP_CURRENT_JOBS);
export const dtpCurrentJobsRequest = dtpCurrentJobs.requestFn;
export const dtpCurrentJobsSuccess = dtpCurrentJobs.successFn;
export const dtpCurrentJobsFailure = dtpCurrentJobs.failureFn;

// DTP Job Retrieve ============================================================
const dtpJobRetrieve = createRetrieveAction(types.DTP_JOB_RETRIEVE);
export const dtpJobRetrieveRequest = dtpJobRetrieve.requestFn;
export const dtpJobRetrieveSuccess = dtpJobRetrieve.successFn;
export const dtpJobRetrieveFailure = dtpJobRetrieve.failureFn;

// Second DTP Current Jobs =====================================================
const secondDtpCurrentJobs = createCurrentJobsAction(types.SECOND_DTP_CURRENT_JOBS);
export const secondDtpCurrentJobsRequest = secondDtpCurrentJobs.requestFn;
export const secondDtpCurrentJobsSuccess = secondDtpCurrentJobs.successFn;
export const secondDtpCurrentJobsFailure = secondDtpCurrentJobs.failureFn;

// Second DTP Job Retrieve =====================================================
const secondDtpJobRetrieve = createRetrieveAction(types.SECOND_DTP_JOB_RETRIEVE);
export const secondDtpJobRetrieveRequest = secondDtpJobRetrieve.requestFn;
export const secondDtpJobRetrieveSuccess = secondDtpJobRetrieve.successFn;
export const secondDtpJobRetrieveFailure = secondDtpJobRetrieve.failureFn;

// Translator Report Count =====================================================
export const reportCountRequest = (id, values) => ({
  type: types.REPORT_COUNT.REQUEST,
  payload: { id, values }
});
export const reportCountSuccess = () => ({
  type: types.REPORT_COUNT.SUCCESS,
});
export const reportCountFailure = (error) => ({
  type: types.REPORT_COUNT.FAILURE,
  payload: { error }
});

export const historySearchRequest = (params) => ({
  type: types.HISTORY_SEARCH.REQUEST,
  payload: { params }
});
export const historySearchSuccess = () => ({
  type: types.HISTORY_SEARCH.SUCCESS,
});
export const historySearchFailure = (error) => ({
  type: types.HISTORY_SEARCH.FAILURE,
  payload: { error }
});

// Print =======================================================================
export const printRequest = (id) => ({
  type: types.PRINT.REQUEST,
  payload: { id }
});

export const printSuccess = () => ({
  type: types.PRINT.SUCCESS,
});

export const printFailure = (error) => ({
  type: types.PRINT.FAILURE,
  payload: { error }
});

// Employee Job Start ==========================================================
export const employeeJobStartRequest = (id, values) => ({
  type: types.EMPLOYEE_JOB_START.REQUEST,
  payload: { id, values }
})
export const employeeJobStartSuccess = () => ({
  type: types.EMPLOYEE_JOB_START.SUCCESS
})
export const employeeJobStartFailure = (error) => ({
  type: types.EMPLOYEE_JOB_START.FAILURE,
  payload: { error }
})

// Employee Job Finish =========================================================
export const employeeJobFinishRequest = (assignment) => ({
  type: types.EMPLOYEE_JOB_FINISH.REQUEST,
  payload: { assignment }
})
export const employeeJobFinishSuccess = () => ({
  type: types.EMPLOYEE_JOB_FINISH.SUCCESS
})
export const employeeJobFinishFailure = (error) => ({
  type: types.EMPLOYEE_JOB_FINISH.FAILURE,
  payload: { error }
})

export const historyCsvExportRequest = (params) => ({
  type: types.HISTORY_CSV_EXPORT.REQUEST,
  payload: { params }
});
export const historyCsvExportSuccess = () => ({
  type: types.HISTORY_CSV_EXPORT.SUCCESS,
});
export const historyCsvExportFailure = (error) => ({
  type: types.HISTORY_CSV_EXPORT.FAILURE,
  payload: { error }
});


export const allReferenceDownloadRequest = (id, number) => ({
  type: types.ALL_REFERENCE_DOWNLOAD.REQUEST,
  payload: { id, number }
});
export const allReferenceDownloadSuccess = () => ({
  type: types.ALL_REFERENCE_DOWNLOAD.SUCCESS,
});
export const allReferenceDownloadFailure = (error) => ({
  type: types.ALL_REFERENCE_DOWNLOAD.FAILURE,
  payload: { error }
});

export const dtpBulkFinishRequest = (values) => ({
  type: types.DTP_BULK_FINISH.REQUEST,
  payload: { values }
});
export const dtpBulkFinishSuccess = () => ({
  type: types.DTP_BULK_FINISH.SUCCESS,
});
export const dtpBulkFinishFailure = (error) => ({
  type: types.DTP_BULK_FINISH.FAILURE,
  payload: { error }
});

export const secondDtpBulkFinishRequest = (values) => ({
  type: types.SECOND_DTP_BULK_FINISH.REQUEST,
  payload: { values }
});
export const secondDtpBulkFinishSuccess = () => ({
  type: types.SECOND_DTP_BULK_FINISH.SUCCESS,
});
export const secondDtpBulkFinishFailure = (error) => ({
  type: types.SECOND_DTP_BULK_FINISH.FAILURE,
  payload: { error }
});

export const setProcessed = (id, processed) => ({
  type: types.SET_PROCESSED,
  payload: { id, processed }
})

