import React from 'react';
import { Field } from 'redux-form';
import { Box } from 'reactjs-admin-lte';
import * as validators from 'misc/validators';
import CharField from 'components/core/CharField';

const TemplateEditNameField = (props) => {
  
  const {
    isLoading,
  } = props;
  
  return (
    <Box
      className="box-widget"
      loading={isLoading}>
      <Box.Header border={true}>
        <Box.Title>Template Information</Box.Title>
        <Box.Tools>
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body>
        <Field
          name="name"
          label="Template Name"
          validate={[
            validators.required,
            validators.maxLength150
          ]}
          isRequired={true}
          component={CharField.Labeled} />
      </Box.Body>
    </Box>
  )
}

export default TemplateEditNameField;