import * as types from 'actionTypes/settings';

const initialState = {
  location: '/manager',
  viewAs: '',
}


export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_SKIN:
      return setSkin(state, action);
    case types.SET_VIEW_AS:
      return setViewAs(state, action);
    case types.SET_LOCATION:
      return setLocation(state, action);
    default:
      return state;
  }
}

const setSkin = (state, action) => {
  const { skin } = action.payload;
  return {
    ...state,
    skin
  }
}

const setViewAs = (state, action) => {
  const { viewAs } = action.payload;
  return {
    ...state,
    viewAs
  }
}

const setLocation = (state, action) => {
  const { location } = action.payload;
  return {
    ...state,
    location
  }
}