import { takeLatest, call, fork, put, all } from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import { hide } from 'redux-modal';
import API from 'services/QuoteApi';
import * as schemas from 'schemas';
import * as actionTypes from 'actionTypes/quotes';
import * as actions from 'actions/quotes';
import * as entityActions from 'actions/entities';
import * as listActions from 'actions/lists';
import * as constants from 'misc/constants';
import * as coreSagas from 'sagas/core';
import { setLoading } from 'actions/loading';


// List ================================================================
function* list(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.QUOTE_LIST, true));
  try {
    const response = yield call(API.list, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data.results), [schemas.quoteSchema]);
    const totalCount = response.data.count ? response.data.count : 1;
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setPaginationResult(
      constants.QUOTE_LIST,
      params.page,
      normalized.result,
      totalCount
    ));
    yield put(setLoading(constants.QUOTE_LIST, false));
  } catch (error) {
    yield put(actions.listFailure(error));
    yield put(setLoading(constants.QUOTE_LIST, false));
  }
}

// Retrieve ====================================================================
function* retrieve(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.QUOTE_DETAIL}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.retrieve, id);
    const normalized = normalize(camelizeKeys(response.data), schemas.quoteSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.retrieveFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

function* batchDelete(action) {
  const message = `Are you sure to delete selected Quote?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    const { values } = action.payload;
    yield put(setLoading(constants.CONFIRM_MODAL, true))
    try {
      yield call(API.batchDelete, decamelizeKeys(values));
      yield all(values.items.map(id => put(listActions.removePaginationItem(constants.QUOTE_LIST, id))));
      yield fork(coreSagas.successNotification, 'Quote deleted successfuly.');
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.batchDeleteFailure(error));
      yield put(setLoading(constants.GLOSSARY_LIST, false));
    }
  }
}

// Unsupported Order List
function* unsupportedOrderList(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.UNSUPPORTED_ORDER_LIST, true));
  try {
    const response = yield call(API.unsupportedOrderList, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data.results), [schemas.unsupportedOrderSchema]);
    const totalCount = response.data.count ? response.data.count : 1;
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setPaginationResult(
      constants.UNSUPPORTED_ORDER_LIST,
      params.page,
      normalized.result,
      totalCount
    ));
    yield put(setLoading(constants.UNSUPPORTED_ORDER_LIST, false));
  } catch (error) {
    yield put(actions.unsupportedOrderListFailure(error));
    yield put(setLoading(constants.UNSUPPORTED_ORDER_LIST, false));
  }
}

function* unsupportedOrderRetrieve(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.UNSUPPORTED_ORDER_DETAIL}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.unsupportedOrderRetrieve, id);
    const normalized = normalize(camelizeKeys(response.data), schemas.unsupportedOrderSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.unsupportedOrderRetrieveFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}


function* watchList() {
  yield takeLatest(actionTypes.LIST.REQUEST, list);
}

function* watchRetrieve() {
  yield takeLatest(actionTypes.RETRIEVE.REQUEST, retrieve);
}
function* watchBatchDelete() {
  yield takeLatest(actionTypes.BATCH_DELETE.REQUEST, batchDelete);
}

function* watchUnsupportedOrderList() {
  yield takeLatest(actionTypes.UNSUPPORTED_ORDER_LIST.REQUEST, unsupportedOrderList);
}
function* watchUnsupportedOrderRetrieve() {
  yield takeLatest(actionTypes.UNSUPPORTED_ORDER_RETRIEVE.REQUEST, unsupportedOrderRetrieve);
}


export {
  watchList,
  watchRetrieve,
  watchBatchDelete,
  watchUnsupportedOrderList,
  watchUnsupportedOrderRetrieve,
}