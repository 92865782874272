import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Box } from 'reactjs-admin-lte';
import { Table, Tabs, Tab } from 'react-bootstrap';
import DateFormatter from 'components/core/DateFormatter';


const booleanData = (value) => {
  const className = value ? 'text-success' : 'text-danger';
  const iconName = value ? 'check' : 'times';
  return <FontAwesome name={iconName} className={className} />
}

const JobDeliveryDetailInvoiceInfo = (props) => {

  const {
    invoices,
    isLoading,
    selectedInvoice,
    handleInvoiceSelect,
  } = props;

  return (
    <Tabs
      activeKey={selectedInvoice}
      onSelect={(key) => handleInvoiceSelect(key)}
      animation={false}
      mountOnEnter={true}
      id="job-invoice-tabs"
      className="nav-tabs-custom">
      {invoices.map((invoice, index) => (
        <Tab
          eventKey={index}
          title={`Invoice #${invoice.seqNo}`}
          key={invoice.id}>
          <Box
            className='box-widget'
            loading={isLoading}>
            <Box.Body noPadding={true}>
              <Table className="table-layout-fixed">
                <tbody>
                  <tr>
                    <th>Includes Jobs</th>
                    <td colSpan={3}>
                      {invoice.jobs.map(job => <div key={job.id}>{job.number}</div>)}
                    </td>
                  </tr>
                  <tr><th>Final Quote</th><td colSpan={3}>{invoice.invoice}</td></tr>
                  <tr><th>Printed</th><td colSpan={3}>{booleanData(invoice.printed)}</td></tr>
                  <tr>
                    <th>Created</th>
                    <td colSpan={3}>
                      <DateFormatter datetime={invoice.created} />
                    </td>
                  </tr>
                  <tr><th>Accounting Info</th><td colSpan={3}><p className="br">{invoice.notes}</p></td></tr>
                </tbody>
              </Table>
            </Box.Body>
          </Box>
        </Tab>
      ))}
    </Tabs>
  );
}

export default JobDeliveryDetailInvoiceInfo;