import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getEditSkillsByUserId } from 'selectors/users';
import {
  editSkillListRequest,
  editSkillCreateRequest,
  editSkillUpdateRequest,
} from 'actions/users'
import { isUserEditSkillLoading } from 'selectors/loading';
import UserSkillHOC from './UserSkillHOC';
import UserSkill from './presenter';


const mapStateToProps = (state, props) => {
  const id = props.userId;
  return {
    isLoading: isUserEditSkillLoading(state, id),
    skills: getEditSkillsByUserId(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest: editSkillListRequest,
    createRequest: editSkillCreateRequest,
    updateRequest: editSkillUpdateRequest,
  }, disptach)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSkillHOC(UserSkill))
