import React from "react";
import * as PropTypes from "prop-types";
import MHTApi from "services/MHTApi";
import WordListApi from "services/WordListApi";
import Notifications from 'react-notification-system-redux';
import {getNotificationOptions, language2key} from 'misc/utils';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {TaTableRow} from "components/taUi/taTable/taTableRow";
import {TaTableCell} from "components/taUi/taTable/taTableCell";
import {TaTable} from "components/taUi/taTable/taTable";
import {TaTableBody} from "components/taUi/taTable/taTableBody";
import {TaTableFooter} from "components/taUi/taTable/taTableFooter";
import {TaButton} from "components/taUi/taButton/taButton";
import {TaModal, TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import {TaFormGroup} from "components/taUi/taFormGroup/taFormGroup";
import {TaFormField} from "components/taUi/taFormField/taFormField";
import {TaInputText} from "components/taUi/taInputText";
import {TaInputCheckbox} from "components/taUi/taInputCheckbox/taInputCheckbox";
import {MESSAGE_RECEIVER_ROLE_MAP} from 'misc/config';
import {TaIcon} from "components/taUi/taIcon";
import {TaTableCellGroup} from "components/taUi/taTable/taTableCellGroup";
import {TaText} from "components/taUi/taText/taText";
import {getMessageReceiverOptions} from "selectors/options";
import styles from "components/mht/MHTEditor/TaMhtEditor.module.css";
import termStyles from "./TaMhtEditorTerms.module.css";
import {TaInputGroup} from "../../taUi/taInputGroup/taInputGroup";
import {convertHtmlToText} from "./textTools";

class TaMhtEditorTerms extends React.PureComponent {

  static propTypes = {
    mhtId: PropTypes.string.isRequired,
    segmentId: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
    triggerModal: PropTypes.number,
    onUpdate: PropTypes.func.isRequired,
    getSelectedSegment: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loadKey: 0,
      items: [],
      isLoading: false,
      showModal: false,
      term: {}
    };

    this.loadData = (segmentId) => {
      if (segmentId) {
        this.setState({
          isLoading: true
        }, () => {
          MHTApi
            .listSegmentTerms(segmentId)
            .then(response => {
              this.setState({
                loadKey: Date.now(),
                items: response.data,
                isLoading: false
              });
            })
            .catch(error => {
              console.error('listSegmentTerms - error', error);
            });
        });
      } else {
        this.setState({
          loadKey: Date.now(),
          items: [],
          isLoading: false
        });
      }
    };

    this.userCanAdd = () => {
      return true;
    };

    this.openEditModal = (row) => {
      if (
        this.userCanAdd() &&
        !this.state.showModal
      ) {
        this.setState({
          showModal: 'edit',
          term: row
        });
      }
    };

    this.openCreateModal = () => {
      if (
        this.userCanAdd() &&
        !this.state.showModal
      ) {
        this.setState({
          showModal: 'create'
        });
      }
    };

    this.openDeleteModal = (row) => {
      if (
        this.userCanAdd() &&
        !this.state.showModal
      ) {
        this.setState({
          showModal: 'delete',
          term: row
        });
      }
    };

    this.closeModal = () => {
      this.setState({
        showModal: false,
        term: {}
      });
    };

    this.onSave = (type, newItem) => {
      const isUpdate = (type === 'edit');
      let items = [...this.state.items];

      if (isUpdate) {
        items = items.map(item => {
          return (item.id === newItem.id) ? newItem : item;
        })
      } else {
        items.unshift(newItem);
      }

      this.setState({
        loadKey: Date.now(),
        items: items
      }, () => {
        this.closeModal();
        props.onUpdate();
      })
    }

    this.onDelete = (termId) => {
      let items = this.state.items.filter(item => {
        return (item.id !== termId);
      });
      this.setState({
        loadKey: Date.now(),
        items: items,
      }, () => {
        this.closeModal();
        props.onUpdate();
      })
    }

  }

  componentDidMount() {
    const segmentId = this.props.segmentId;
    if (segmentId) {
      this.loadData(segmentId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const props = this.props;
    if (props.segmentId !== prevProps.segmentId) {
      this.loadData(props.segmentId);
    }
    if (
      props.triggerModal !== prevProps.triggerModal
    ) {
      this.openCreateModal();
    }
  }

  render() {

    const state = this.state;
    const props = this.props;
    const sourceLanguage = language2key(props.source || '');
    const targetLanguage = language2key(props.target || '');
    const itemCount = state.items.length;
    const canAdd = this.userCanAdd();
    const cellWidthAction = 90;

    const showEditModal = (state.showModal && state.showModal !== 'delete');
    const showDeleteModal = (state.showModal === 'delete');

    return (
      <React.Fragment>

        <TaTable size={'full'} scroll={'y'}>
          <TaTableBody flex={'auto'}>
            {
              props.segmentId ? (
                props.isLoading ? (
                  <TaTableRow>
                    <TaTableCell color={'light'}>
                      Loading ...
                    </TaTableCell>
                  </TaTableRow>
                ) : (
                  itemCount ? (
                    state.items.map((row, rowIndex) => {
                      return (
                        <TaTableRow key={rowIndex} className={termStyles.row}>
                          <TaTableCellGroup>
                            <TaTableCell
                              flex={'none'}
                              color={'light'}
                              style={{textAlign: 'center', width: '48px'}}
                            >
                              <TaIcon name={(row.is_pending) ? 'watch_later' : 'done'}
                                      color={(row.is_pending) ? 'light' : 'confirm'}
                                      size={'small'}
                                      style={{marginTop: '3px'}}
                              />
                            </TaTableCell>
                            <TaTableCellGroup>
                              <TaTableCell>
                                <TaText
                                  className={styles.rowMeta}
                                  color={'light'}
                                >{row[sourceLanguage]}</TaText>
                                <TaText>{row[targetLanguage]}</TaText>
                              </TaTableCell>
                              <TaTableCell width={cellWidthAction}>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    justifyContent: 'space-between',
                                    width: '72px'
                                  }}
                                >
                                  <TaButton
                                    icon={'edit'}
                                    tooltip={'Edit Term'}
                                    disabled={!row.is_pending}
                                    onClick={() => this.openEditModal(row)}
                                  />
                                  <TaButton
                                    icon={'delete'}
                                    tooltip={'Delete Term'}
                                    disabled={!row.is_pending}
                                    onClick={() => this.openDeleteModal(row)}
                                  />
                                </div>
                              </TaTableCell>
                            </TaTableCellGroup>
                          </TaTableCellGroup>
                          {
                            <div
                              className={termStyles.details}
                            >Type: {row.type}
                            </div>
                          }
                        </TaTableRow>
                      );
                    })
                  ) : (
                    <TaTableRow>
                      <TaTableCell color={'light'}>
                        No matching terms found.
                      </TaTableCell>
                    </TaTableRow>
                  )
                )
              ) : (
                <TaTableRow>
                  <TaTableCell color={'light'}>
                    Please select a segment
                  </TaTableCell>
                </TaTableRow>
              )
            }
          </TaTableBody>
          {
            (canAdd) && (
              <TaTableFooter look={'card'}>
                <TaTableRow>
                  <TaTableCell>
                    <TaButton
                      icon={'add_circle'}
                      label="Add Term"
                      onClick={() => this.openCreateModal('add')}
                    />
                  </TaTableCell>
                </TaTableRow>
              </TaTableFooter>
            )
          }
        </TaTable>

        <TaModal
          isOpen={showEditModal || showDeleteModal}
          onCancel={this.closeModal}
        >
          {(!!showEditModal) && (
            <TaMhtEditorTermsEditModal
              segmentId={props.segmentId}
              mhtId={props.mhtId}
              userRole={props.userRole}
              type={state.showModal}
              term={state.term}
              getSelectedSegment={props.getSelectedSegment}
              source={sourceLanguage}
              target={targetLanguage}
              onCancel={this.closeModal}
              onSave={this.onSave}
            />
          )}
          {(!!showDeleteModal) && (
            <TaMhtEditorTermsDeleteModal
              term={state.term}
              source={sourceLanguage}
              target={targetLanguage}
              onCancel={this.closeModal}
              onSave={this.onDelete}
            />
          )}
        </TaModal>

      </React.Fragment>
    );
  }
}

class TaMhtEditorTermsEditModal extends React.PureComponent {

  static propTypes = {
    mhtId: PropTypes.string.isRequired,
    segmentId: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    term: PropTypes.object,
    source: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    getSelectedSegment: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.getInitialRoles = () => {
      const {receiverOptions, userRole} = props;
      switch (userRole) {
        case 'totra':
        case 'dtp':
        case 'secondDtp':
          return receiverOptions
            .filter(role => (
              role.label === 'Coordinator' ||
              role.label === 'AE' ||
              role.label === 'DTP' ||
              role.label === 'Second DTP' ||
              role.label === 'ToTra'
            )).map(role => role.value);

        case 'finalEditor':
        case 'secondFinalEditor':
          return receiverOptions
            .filter(role => (
              role.label === 'Coordinator' ||
              role.label === 'AE' ||
              role.label === 'Final Editor' ||
              role.label === 'Second Final Editor'
            )).map(role => role.value);

        case 'checker':
          return receiverOptions
            .filter(role => (
              role.label !== 'Translator'
            )).map(role => role.value);

        case 'editor':
          return receiverOptions
            .filter(role => (
              role.label !== 'Translator'
            )).map(role => role.value);

        case 'translator':
          return receiverOptions.map(role => role.value);

        default:
          return receiverOptions
            .filter(role => (
              role.label === 'Coordinator' ||
              role.label === 'AE'
            ))
            .map(role => role.value);
      }
    };

    this.state = {
      loadKey: 0,
      input: {},
      inputIsValid: false,
      notify: [...this.getInitialRoles()],
      isSaving: false
    };

    this.onInputEvent = (event, rerender) => {

      let input = {...this.state.input};

      input[event.data.property] = event.data.value;

      let newState = {
        input: input,
        inputIsValid: (
          !!input.source &&
          !!input.target
        )
      }

      if (rerender) {
        newState.loadKey = Date.now();
      }

      this.setState(newState);

    };

    this.copy = (property) => {
      const segment = this.props.getSelectedSegment()
      if (segment && segment.id) {
        let value = '';
        if (property === 'target') {
          if (segment[property + '_html']) {
            value = convertHtmlToText(segment[property + '_html']);
          }
        } else {
          value = segment[property + '_text'] || '';
        }
        this.onInputEvent(
          {
            data: {
              property: property,
              value: value
            }
          },
          true)
      }
    }

    this.saveTerm = () => {
      const state = this.state;
      const type = props.type;
      const isUpdate = (type === 'edit');
      const id = (isUpdate) ? props.term.id : false;

      this.setState({
        isSaving: true
      }, () => {

        let value = {
          roles: state.notify,
          sender_role: MESSAGE_RECEIVER_ROLE_MAP[props.userRole]
        };

        const sourceLanguage = language2key(props.source);
        const targetLanguage = language2key(props.target);
        value[sourceLanguage] = state.input.source;
        value[targetLanguage] = state.input.target;

        const note = (isUpdate) ? "Term updated successfully." : "New term added successfully.";

        const callApi = (isUpdate) ?
          WordListApi.updateItem(id, value) :
          MHTApi.addTerm(props.mhtId, value);

        callApi
          .then((response) => {
            this.setState({
              isSaving: false
            }, () => {
              props.onSave(type, response.data);
              props.showSuccessNotification(getNotificationOptions('Success', note));
            });

          });
      });

    };

    this.toggleNotifyRole = (event) => {
      if (event.name === 'change') {

        const value = event.data.property;
        let notify = this.state.notify;

        if (value && notify.includes(value)) {
          notify = notify.filter(role => {
            return (role !== event.data.property)
          });
        } else {
          notify.push(value);
        }

        this.setState({
          loadKey: Date.now(),
          notify: notify
        });

      }

    };

  }


  componentDidMount() {
    const props = this.props;

    const newInput = {
      source: props.term[props.source] || '',
      target: props.term[props.target] || ''
    }

    if (this.props.type === 'edit') {
      this.setState({
        loadKey: Date.now(),
        input: newInput
      })
    }

  }

  render() {

    const {
      type,
      source,
      target,
      onCancel,
      segmentId,
      receiverOptions
    } = this.props;
    const state = this.state;

    return (
      <React.Fragment>
        <TaModalHeader
          label={(type === 'create' ? 'Add' : 'Edit') + ' Term'}
          onCancel={onCancel}
        />
        <TaModalContent
          scroll={'auto'}
          key={state.loadKey}
        >
          <TaFormGroup>
            <TaFormField
              label={source}
              isRequired={true}
            >
              {(!!segmentId) ? (
                <TaInputGroup>
                  <TaInputText
                    name="source"
                    value={state.input.source}
                    onEvent={(event) => this.onInputEvent(event)}
                    autofocus={true}
                  />
                  <TaButton
                    type="button"
                    label={'Copy Source'}
                    onClick={() => this.copy('source')}
                  />
                </TaInputGroup>
              ) : (
                <TaInputText
                  name="source"
                  value={state.input.source}
                  onEvent={(event) => this.onInputEvent(event)}
                  autofocus={true}
                />
              )}
            </TaFormField>
            <TaFormField
              label={target}
              isRequired={true}
            >
              {(!!segmentId) ? (
                <TaInputGroup>
                  <TaInputText
                    name="target"
                    value={state.input.target}
                    onEvent={(event) => this.onInputEvent(event)}
                  />
                  <TaButton
                    type="button"
                    label={'Copy Target'}
                    onClick={() => this.copy('target')}
                  />
                </TaInputGroup>
              ) : (
                <TaInputText
                  name="target"
                  value={state.input.target}
                  onEvent={(event) => this.onInputEvent(event)}
                />
              )}
            </TaFormField>
            <TaFormField
              label="Send To"
            >{
              receiverOptions.map((option) => {
                const isChecked = (state.notify.includes(option.value));
                return <TaInputCheckbox
                  key={option.value}
                  name={option.value}
                  label={option.label}
                  value={isChecked}
                  disabled={option.disabled}
                  onEvent={this.toggleNotifyRole}/>;
              })
            }</TaFormField>
          </TaFormGroup>
        </TaModalContent>
        <TaModalFooter>
          <TaButton
            type="reset"
            icon={'clear'}
            label={'Cancel'}
            onClick={onCancel}
          />
          <TaButton
            type="submit"
            icon={'check'}
            label={'Save'}
            iconAlign={'right'}
            isLoading={state.isSaving}
            disabled={!state.inputIsValid}
            onClick={() => this.saveTerm()}
          />
        </TaModalFooter>
      </React.Fragment>

    )

  }

}

class TaMhtEditorTermsDeleteModal extends React.PureComponent {

  static propTypes = {
    term: PropTypes.object.isRequired,
    source: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      notify: [],
      isSaving: false
    };

    this.deleteTerm = () => {
      const id = (props.term.id);

      this.setState({
        isSaving: true
      }, () => {

        WordListApi
          .deleteItem(id)
          .then((response) => {
            this.setState({
              isSaving: false
            }, () => {
              props.onSave(id);
            });
            props.showSuccessNotification(getNotificationOptions('Success', 'Term deleted successfully.'));
          });

      });

    };
  }

  render() {

    const state = this.state;
    const {
      term,
      source,
      target,
      onCancel
    } = this.props;

    return (
      <React.Fragment>
        <TaModalHeader label={'Delete Term'} onCancel={onCancel}/>
        <TaModalContent scroll={'auto'}>
          <TaFormGroup>
            <TaFormField
              label={'Are you sure you want to delete this term?'}
            >{term[source]} / {term[target]}</TaFormField>
          </TaFormGroup>
        </TaModalContent>
        <TaModalFooter>
          <TaButton
            type="reset"
            icon={'clear'}
            label={'Cancel'}
            onClick={onCancel}
          />
          <TaButton
            type="submit"
            icon={'delete'}
            label={'Delete'}
            iconAlign={'right'}
            isLoading={state.isSaving}
            onClick={() => this.deleteTerm()}
          />
        </TaModalFooter>
      </React.Fragment>
    )
  }

}

const mapStateToPropsEditModal = (state, ownProps) => {
  return {
    receiverOptions: getMessageReceiverOptions(state)
  };
};
const mapStateToPropsDeleteModal = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showSuccessNotification: Notifications.success
  }, dispatch);
};

TaMhtEditorTermsEditModal = connect(
  mapStateToPropsEditModal,
  mapDispatchToProps
)(TaMhtEditorTermsEditModal);

TaMhtEditorTermsDeleteModal = connect(
  mapStateToPropsDeleteModal,
  mapDispatchToProps
)(TaMhtEditorTermsDeleteModal);

export {
  TaMhtEditorTerms
};