import React from 'react';
import { JOB_ISSUE } from 'misc/constants';
import { datetimeFormatter, dateFormatter } from 'misc/cellFormatters';
import { NumberOrIdLinkFieldCellRenderer } from 'misc/cellRenderers';
import ListTable from 'components/core/ListTable';
import PureRenderComponent from 'components/core/PureRenderComponent';


class JobPendingListTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'No./ID',
          field: 'id',
          pinned: 'left',
          cellClass: 'ag-jobNumber',
          width: 140,
          cellRendererFramework: NumberOrIdLinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: JOB_ISSUE,
          },
        },
        {
          headerName: 'Client',
          field: 'client.name',
          width: 200,
        },
        {
          headerName: 'Deadline',
          field: 'deadline',
          width: 150,
          valueFormatter: datetimeFormatter
        },
        {
          headerName: 'AE Subject',
          field: 'aeSubject',
          width: 300,
        },
        {
          headerName: 'Type',
          field: 'type',
          width: 65,
        },
        {
          headerName: 'Service',
          field: 'service.name',
          width: 80,
        },
        {
          headerName: 'Lv',
          field: 'level',
          width: 40,
        },
        {
          headerName: 'Receive Date',
          field: 'receiveDate',
          width: 110,
          valueFormatter: dateFormatter,
        },
        {
          headerName: 'Target',
          field: 'targetCount',
          width: 110,
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'AE',
          field: 'ae.name',
          width: 80,
        },
        {
          headerName: 'Creator',
          field: 'creator.name',
          width: 80,
        },
        {
          headerName: 'Coordinator',
          field: 'coordinator.name',
          width: 120,
        }
      ]
    }
  }

  getRowClass = (params) => {
    if (params.data.isUrgent) {
      return 'ag-row-urgent';
    }
  }

  render() {
    return (
      <ListTable
        columnDefs={this.state.columnDefs}
        rowData={this.props.jobs}
        getRowClass={this.getRowClass}
        deltaRowDataMode={true}
        getRowNodeId={data => data.id} />
    )
  }
}

export default JobPendingListTable;
