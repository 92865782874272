import React from 'react';
import VirtualizedSelect from 'react-virtualized-select';


const Select = (props) => {
  const {
    value,
    options,
    onChange,
    clearable,
    disabled,
    searchable,
    className,
    isLoading,
    simpleValue,
    style,
    multi,
    placeholder
  } = props;

  return (
    <VirtualizedSelect
      simpleValue={simpleValue}
      clearable={clearable}
      disabled={disabled}
      isLoading={isLoading}
      value={value}
      multi={multi}
      searchable={searchable}
      onChange={onChange}
      options={options}
      className={className}
      placeholder={placeholder}
      style={style} />
  );
}

Select.defaultProps = {
  isLoading: false,
  simpleValue: true,
}

export default Select;