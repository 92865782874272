import axios from 'axios';

const AuthApi = {
  login: (values) => {
    return axios.post(`login/`, values);
  },
  refresh: (token) => {
    return axios.post(`refresh/`, { token });
  },
  passwordChange: (values) => {
    return axios.post('password/', values);
  },
  getAuthToken: ()  => {
    return localStorage.getItem('token');
  },
  hasAuthToken: () => {
    return !!AuthApi.getAuthToken();
  },
  setAuthToken: (token)  => {
    localStorage.setItem('token', token);
    axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  },
  removeAuthToken: ()  => {
    localStorage.setItem('token', '');
  }
}

export default AuthApi;
