import React from 'react';
import { MainSidebar } from 'reactjs-admin-lte';
import MainSidebarMenuTreeView from './MainSidebarMenuTreeView';
import { getTabConfigByName } from 'misc/utils';
import * as constants from 'misc/constants';

const EmployeeSidebar = (props) => {
  const {
    currentTab,
    handleTabOpen,
    isAuthorizedEmployee,
    isToTra,
    isTranslator,
    isEditor,
    isChecker,
    isFinalEditor,
    isSecondFinalEditor,
    isDtp,
    isSecondDtp,
    isSuperuser,
  } = props;

  const handleClick = (tabName) => {
    const tabConfig = getTabConfigByName(tabName);
    handleTabOpen(tabConfig)
  }

  return (
    <MainSidebar>
      <MainSidebar.Menu>
        <MainSidebar.Menu.Header>NAVIGATION</MainSidebar.Menu.Header>

        { isAuthorizedEmployee &&
          <MainSidebar.Menu.Item
            title="Daily Schedule"
            iconName="calendar-check-o"
            active={currentTab === constants.EMPLOYEE_JOB_SCHEDULE}
            onClick={() => handleClick(constants.EMPLOYEE_JOB_SCHEDULE)} />
        }

        <MainSidebarMenuTreeView
          title="Current jobs"
          iconName="files-o"
          active={[
            constants.TOTRA_CURRENT_JOBS,
            constants.TRANSLATOR_CURRENT_JOBS,
            constants.EDITOR_CURRENT_JOBS,
            constants.CHECKER_CURRENT_JOBS,
            constants.FINAL_EDITOR_CURRENT_JOBS,
            constants.SECOND_FINAL_EDITOR_CURRENT_JOBS,
            constants.DTP_CURRENT_JOBS,
            constants.SECOND_DTP_CURRENT_JOBS
          ].includes(currentTab)}>

          { isToTra &&
            <MainSidebar.Menu.Item
              title="ToTra"
              iconName="circle-o"
              active={currentTab === constants.TOTRA_CURRENT_JOBS}
              onClick={() => handleClick(constants.TOTRA_CURRENT_JOBS)} />
          }

          { isTranslator &&
            <MainSidebar.Menu.Item
              title="Translator"
              iconName="circle-o"
              active={currentTab === constants.TRANSLATOR_CURRENT_JOBS}
              onClick={() => handleClick(constants.TRANSLATOR_CURRENT_JOBS)} />
          }

          { isEditor &&
            <MainSidebar.Menu.Item
              title="Editor"
              iconName="circle-o"
              active={currentTab === constants.EDITOR_CURRENT_JOBS}
              onClick={() => handleClick(constants.EDITOR_CURRENT_JOBS)} />
          }

          { isChecker &&
            <MainSidebar.Menu.Item
              title="Checker"
              iconName="circle-o"
              active={currentTab === constants.CHECKER_CURRENT_JOBS}
              onClick={() => handleClick(constants.CHECKER_CURRENT_JOBS)} />
          }

          { isFinalEditor &&
            <MainSidebar.Menu.Item
              title="FE"
              iconName="circle-o"
              active={currentTab === constants.FINAL_EDITOR_CURRENT_JOBS}
              onClick={() => handleClick(constants.FINAL_EDITOR_CURRENT_JOBS)} />
          }

          { isSecondFinalEditor &&
            <MainSidebar.Menu.Item
              title="2nd FE"
              iconName="circle-o"
              active={currentTab === constants.SECOND_FINAL_EDITOR_CURRENT_JOBS}
              onClick={() => handleClick(constants.SECOND_FINAL_EDITOR_CURRENT_JOBS)} />
          }

          { isDtp &&
            <MainSidebar.Menu.Item
              title="DTP"
              iconName="circle-o"
              active={currentTab === constants.DTP_CURRENT_JOBS}
              onClick={() => handleClick(constants.DTP_CURRENT_JOBS)} />
          }

          { isSecondDtp &&
            <MainSidebar.Menu.Item
              title="2nd DTP"
              iconName="circle-o"
              active={currentTab === constants.SECOND_DTP_CURRENT_JOBS}
              onClick={() => handleClick(constants.SECOND_DTP_CURRENT_JOBS)} />
          }
        </MainSidebarMenuTreeView>

        <MainSidebar.Menu.Item
          title="Job History"
          iconName="history"
          active={currentTab === constants.EMPLOYEE_JOB_HISTORY}
          onClick={() => handleClick(constants.EMPLOYEE_JOB_HISTORY)} />

        { isSuperuser &&
          <MainSidebarMenuTreeView
            title="Sales"
            iconName="briefcase"
            active={[
              constants.SALES_REPORT,
              constants.SALES_TARGET,
            ].includes(currentTab)}>

            <MainSidebar.Menu.Item
                title="Sales & MHT Report"
                iconName="circle-o"
                active={currentTab === constants.SALES_REPORT}
                onClick={() => handleClick(constants.SALES_REPORT)} />

              <MainSidebar.Menu.Item
                title="Sales Target"
                iconName="circle-o"
                active={currentTab === constants.SALES_TARGET}
                onClick={() => handleClick(constants.SALES_TARGET)} />

              <MainSidebar.Menu.Item
                title="Invoice List"
                iconName="circle-o"
                active={currentTab === constants.INVOICE_LIST}
                onClick={() => handleClick(constants.INVOICE_LIST)} />
          </MainSidebarMenuTreeView>
        }
        <MainSidebar.Menu.Header>SETTINGS</MainSidebar.Menu.Header>
        <MainSidebar.Menu.Item
          title="Profile"
          iconName="user-circle-o"
          active={currentTab === constants.PROFILE}
          onClick={() => handleClick(constants.PROFILE)} />

      </MainSidebar.Menu>
    </MainSidebar>
  )
}

export default EmployeeSidebar;
