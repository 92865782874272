import * as types from 'actionTypes/jobs/managers';
import * as utils from 'misc/utils';

export const createCreateByAction = (type) => ({
  requestFn: (id, form, values) => ({ type: type.REQUEST, payload: { id, form, values } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

// Pending Create ==============================================================
const create = utils.createCreateAction(types.CREATE);
export const createRequest = create.requestFn;
export const createSuccess = create.successFn;
export const createFailure = create.failureFn;

// Pending Draft ===============================================================
const draft = utils.createCreateAction(types.DRAFT);
export const draftRequest = draft.requestFn;
export const draftSuccess = draft.successFn;
export const draftFailure = draft.failureFn;

// Duplicate Create ============================================================
const duplicateCreate = createCreateByAction(types.DUPLICATE_CREATE);
export const duplicateCreateRequest = duplicateCreate.requestFn;
export const duplicateCreateSuccess = duplicateCreate.successFn;
export const duplicateCreateFailure = duplicateCreate.failureFn;

// Duplicate draft =============================================================
const duplicateDraft = createCreateByAction(types.DUPLICATE_DRAFT);
export const duplicateDraftRequest = duplicateDraft.requestFn;
export const duplicateDraftSuccess = duplicateDraft.successFn;
export const duplicateDraftFailure = duplicateDraft.failureFn;

// Template by create ==========================================================
const templateByCreate = createCreateByAction(types.TEMPLATE_BY_CREATE);
export const templateByCreateRequest = templateByCreate.requestFn;
export const templateByCreateSuccess = templateByCreate.successFn;
export const templateByCreateFailure = templateByCreate.failureFn;

// Template by draft ===========================================================
const templateByDraft = createCreateByAction(types.TEMPLATE_BY_DRAFT);
export const templateByDraftRequest = templateByDraft.requestFn;
export const templateByDraftSuccess = templateByDraft.successFn;
export const templateByDraftFailure = templateByDraft.failureFn;

// Split Create ================================================================
const splitCreate = createCreateByAction(types.SPLIT_CREATE);
export const splitCreateRequest = splitCreate.requestFn;
export const splitCreateSuccess = splitCreate.successFn;
export const splitCreateFailure = splitCreate.failureFn;

// Split Draft ===============================================================
const splitDraft = createCreateByAction(types.SPLIT_DRAFT);
export const splitDraftRequest = splitDraft.requestFn;
export const splitDraftSuccess = splitDraft.successFn;
export const splitDraftFailure = splitDraft.failureFn;

// Issue =======================================================================
const issue = utils.createUpdateAction(types.ISSUE);
export const issueRequest = issue.requestFn;
export const issueSuccess = issue.successFn;
export const issueFailure = issue.failureFn;

// Split issue =======================================================================
const splitIssue = utils.createUpdateAction(types.SPLIT_ISSUE);
export const splitIssueRequest = splitIssue.requestFn;
export const splitIssueSuccess = splitIssue.successFn;
export const splitIssueFailure = splitIssue.failureFn;


// Edtot =======================================================================
const edit = utils.createUpdateAction(types.EDIT);
export const editRequest = edit.requestFn;
export const editSuccess = edit.successFn;
export const editFailure = edit.failureFn;


// Quick Edit ==================================================================
const quickEdit = utils.createUpdateAction(types.QUICK_EDIT);
export const quickEditRequest = quickEdit.requestFn;
export const quickEditSuccess = quickEdit.successFn;
export const quickEditFailure = quickEdit.failureFn;

const retrieve = utils.createRetrieveAction(types.RETRIEVE);
export const retrieveRequest = retrieve.requestFn;
export const retrieveSuccess = retrieve.successFn;
export const retrieveFailure = retrieve.failureFn;

const update = utils.createUpdateAction(types.UPDATE);
export const updateRequest = update.requestFn;
export const updateSuccess = update.successFn;
export const updateFailure = update.failureFn;

// Draft Update ================================================================
const draftUpdate = utils.createUpdateAction(types.DRAFT_UPDATE);
export const draftUpdateRequest = draftUpdate.requestFn;
export const draftUpdateSuccess = draftUpdate.successFn;
export const draftUpdateFailure = draftUpdate.failureFn;

// Draft To Pending ============================================================
const draftToPending = utils.createUpdateAction(types.DRAFT_TO_PENDING);
export const draftToPendingRequest = draftToPending.requestFn;
export const draftToPendingSuccess = draftToPending.successFn;
export const draftToPendingFailure = draftToPending.failureFn;

const patch = utils.createPatchAction(types.PATCH);
export const patchRequest = patch.requestFn;
export const patchSuccess = patch.successFn;
export const patchFailure = patch.failureFn;

const remove = utils.createRemoveAction(types.REMOVE);
export const removeRequest = remove.requestFn;
export const removeSuccess = remove.successFn;
export const removeFailure = remove.failureFn;

const removeDraft = utils.createRemoveAction(types.REMOVE_DRAFT);
export const removeDraftRequest = removeDraft.requestFn;
export const removeDraftSuccess = removeDraft.successFn;
export const removeDraftFailure = removeDraft.failureFn;

const removePending = utils.createRemoveAction(types.REMOVE_PENDING);
export const removePendingRequest = removePending.requestFn;
export const removePendingSuccess = removePending.successFn;
export const removePendingFailure = removePending.failureFn;

const cancel = utils.createRemoveAction(types.CANCEL);
export const cancelRequest = cancel.requestFn;
export const cancelSuccess = cancel.successFn;
export const cancelFailure = cancel.failureFn;

// Daily Schedule ==============================================================
export const dailyScheduleRequest = (params) => ({
  type: types.DAILY_SCHEDULE.REQUEST,
  payload: { params }
});

export const dailyScheduleSuccess = () => ({
  type: types.DAILY_SCHEDULE.SUCCESS
});

export const dailyScheduleFailure = (error) => ({
  type: types.DAILY_SCHEDULE.FAILURE,
  payload: { error }
});

// Future Schedule =============================================================
export const futureScheduleRequest = (params) => ({
  type: types.FUTURE_SCHEDULE.REQUEST,
  payload: { params }
});

export const futureScheduleSuccess = () => ({
  type: types.FUTURE_SCHEDULE.SUCCESS
});

export const futureScheduleFailure = (error) => ({
  type: types.FUTURE_SCHEDULE.FAILURE,
  payload: { error }
});

// Member List =================================================================
export const memberListRequest = (jobId) => ({
  type: types.MEMBER_LIST.REQUEST,
  payload: { jobId }
});

export const memberListSuccess = () => ({
  type: types.MEMBER_LIST.SUCCESS
});

export const memberListFailure = (error) => ({
  type: types.MEMBER_LIST.FAILURE,
  payload: { error }
});

// Rearrange ===================================================================
export const rearrangeRequest = (ids) => ({
  type: types.REARRANGE.REQUEST,
  payload: { ids }
});

export const rearrangeSuccess = () => ({
  type: types.REARRANGE.SUCCESS
});

export const rearrangeFailure = (error) => ({
  type: types.REARRANGE.FAILURE,
  payload: { error }
});

// Fetch Latest Number =========================================================
export const fetchLatestNumberRequest = (form, type) => ({
  type: types.FETCH_LATEST_NUMBER.REQUEST,
  payload: { form, type }
})
export const fetchLatestNumberSuccess = () => ({
  type: types.FETCH_LATEST_NUMBER.SUCCESS
})
export const fetchLatestNumberFailure = (error) => ({
  type: types.FETCH_LATEST_NUMBER.FAILURE,
  payload: { error }
})

// Job Search ==================================================================
export const jobSearchRequest = (params) => ({
  type: types.JOB_SEARCH.REQUEST,
  payload: { params }
});

export const jobSearchSuccess = () => ({
  type: types.JOB_SEARCH.SUCCESS
});

export const jobSearchFailure = (error) => ({
  type: types.JOB_SEARCH.FAILURE,
  payload: { error }
});

// Draft List ================================================================
const draftList = utils.createListAction(types.DRAFT_LIST);
export const draftListRequest = draftList.requestFn;
export const draftListSuccess = draftList.successFn;
export const draftListFailure = draftList.failureFn;

// Working List ================================================================
// const workingList = utils.createListAction(types.WORKING_LIST);
// export const workingListRequest = workingList.requestFn;
// export const workingListSuccess = workingList.successFn;
// export const workingListFailure = workingList.failureFn;

// Delivery List ===============================================================
export const deliveryListRequest = (params) => ({
  type: types.DELIVERY_LIST.REQUEST,
  payload: { params }
});

export const deliveryListSuccess = () => ({
  type: types.DELIVERY_LIST.SUCCESS
});

export const deliveryListFailure = (error) => ({
  type: types.DELIVERY_LIST.FAILURE,
  payload: { error }
});

// Pending List ================================================================
const pendingList = utils.createListAction(types.PENDING_LIST);
export const pendingListRequest = pendingList.requestFn;
export const pendingListSuccess = pendingList.successFn;
export const pendingListFailure = pendingList.failureFn;

// Preparing List ==============================================================
const preparingList = utils.createSearchAction(types.PREPARING_LIST);
export const preparingListRequest = preparingList.requestFn;
export const preparingListSuccess = preparingList.successFn;
export const preparingListFailure = preparingList.failureFn;

// ToTra List ==================================================================
const totraList = utils.createListAction(types.TOTRA_LIST);
export const totraListRequest = totraList.requestFn;
export const totraListSuccess = totraList.successFn;
export const totraListFailure = totraList.failureFn;

// RnQ List ==================================================================
export const rnqListRequest = (params) => ({
  type: types.RNQ_LIST.REQUEST,
  payload: { params }
});

export const rnqListSuccess = () => ({
  type: types.RNQ_LIST.SUCCESS
});

export const rnqListFailure = (error) => ({
  type: types.RNQ_LIST.FAILURE,
  payload: { error }
});


// Payment Search ==============================================================
export const paymentSearchRequest = (params) => ({
  type: types.PAYMENT_SEARCH.REQUEST,
  payload: { params }
});

export const paymentSearchSuccess = () => ({
  type: types.PAYMENT_SEARCH.SUCCESS
});

export const paymentSearchFailure = (error) => ({
  type: types.PAYMENT_SEARCH.FAILURE,
  payload: { error }
});

// Print =======================================================================
export const printRequest = (id) => ({
  type: types.PRINT.REQUEST,
  payload: { id }
});

export const printSuccess = () => ({
  type: types.PRINT.SUCCESS,
});

export const printFailure = (error) => ({
  type: types.PRINT.FAILURE,
  payload: { error }
});

// Process =====================================================================
export const processRequest = (id, values) => ({
  type: types.PROCESS.REQUEST,
  payload: { id, values }
})
export const processSuccess = () => ({
  type: types.PROCESS.SUCCESS
})
export const processFailure = (error) => ({
  type: types.PROCESS.FAILURE,
  payload: { error }
})

export const setQuickEdit = (name, id) => ({
  type: types.SET_QUICK_EDIT,
  payload: { name, id }
});

// Invoice List ================================================================
export const invoiceListRequest = (id) => ({
  type: types.INVOICE_LIST.REQUEST,
  payload: { id }
});
export const invoiceListSuccess = () => ({
  type: types.INVOICE_LIST.SUCCESS
});
export const invoiceListFailure = (error) => ({
  type: types.INVOICE_LIST.FAILURE,
  payload: { error }
});


export const suggestAssignmentRequest = (form, values) => ({
  type: types.SUGGEST_ASSIGNMENT.REQUEST,
  payload: { form, values }
});
export const suggestAssignmentSuccess = () => ({
  type: types.SUGGEST_ASSIGNMENT.SUCCESS,
});
export const suggestAssignmentFailure = (error) => ({
  type: types.SUGGEST_ASSIGNMENT.FAILURE,
  payload: { error }
});

export const refreshAssignmentRequest = (form, values) => ({
  type: types.REFRESH_ASSIGNMENT.REQUEST,
  payload: { form, values }
});
export const refreshAssignmentSuccess = () => ({
  type: types.REFRESH_ASSIGNMENT.SUCCESS,
});
export const refreshAssignmentFailure = (error) => ({
  type: types.REFRESH_ASSIGNMENT.FAILURE,
  payload: { error }
});

export const assignmentFinishRequest = (assignment) => ({
  type: types.ASSIGNMENT_FINISH.REQUEST,
  payload: { assignment }
});
export const assignmentFinishSuccess = () => ({
  type: types.ASSIGNMENT_FINISH.SUCCESS,
});
export const assignmentFinishFailure = (error) => ({
  type: types.ASSIGNMENT_FINISH.FAILURE,
  payload: { error }
});

export const assignmentPaymentCheckRequest = (id, values) => ({
  type: types.ASSIGNMENT_PAYMENT_CHECK.REQUEST,
  payload: { id, values }
});
export const assignmentPaymentCheckSuccess = () => ({
  type: types.ASSIGNMENT_PAYMENT_CHECK.SUCCESS,
});
export const assignmentPaymentCheckFailure = (error) => ({
  type: types.ASSIGNMENT_PAYMENT_CHECK.FAILURE,
  payload: { error }
});

export const setAssigneeOptions = (name, options) => {
  return {
    type: types.SET_ASSIGNEE_OPTIONS,
    payload: { name, options }
  }
}

export const resetAssigneeOptions = (name) => {
  return {
    type: types.RESET_ASSIGNEE_OPTIONS,
    payload: { name }
  }
}

// Autocomplete ================================================================
export const autocompleteRequest = (q) => ({
  type: types.AUTOCOMPLETE.REQUEST,
  payload: { q }
});

export const autocompleteSuccess = () => ({
  type: types.AUTOCOMPLETE.SUCCESS
});

export const autocompleteFailure = (error) => ({
  type: types.AUTOCOMPLETE.FAILURE,
  payload: { error }
});

export const bulkVerfiyRequest = (values) => ({
  type: types.BULK_VERIFY.REQUEST,
  payload: { values }
});
export const bulkVerfiySuccess = () => ({
  type: types.BULK_VERIFY.SUCCESS,
});
export const bulkVerfiyFailure = (error) => ({
  type: types.BULK_VERIFY.FAILURE,
  payload: { error }
});

// MHT Job List ===============================================================
export const mhtJobListRequest = (params) => ({
  type: types.MHT_JOB_LIST.REQUEST,
  payload: { params }
});

export const mhtJobListSuccess = () => ({
  type: types.MHT_JOB_LIST.SUCCESS
});

export const mhtJobListFailure = (error) => ({
  type: types.MHT_JOB_LIST.FAILURE,
  payload: { error }
});

// MHT Job Retrieve =============================================================
export const mhtJobRetrieveRequest = (id) => ({
  type: types.MHT_JOB_RETRIEVE.REQUEST,
  payload: { id }
});

export const mhtJobRetrieveSuccess = () => ({
  type: types.MHT_JOB_RETRIEVE.SUCCESS
});

export const mhtJobRetrieveFailure = (error) => ({
  type: types.MHT_JOB_RETRIEVE.FAILURE,
  payload: { error }
});

export const mhtJobMessageListRequest = (id) => ({
  type: types.MHT_JOB_MESSAGE_LIST.REQUEST,
  payload: { id }
});

export const mhtJobMessageListSuccess = () => ({
  type: types.MHT_JOB_MESSAGE_LIST.SUCCESS
});

export const mhtJobMessageListFailure = (error) => ({
  type: types.MHT_JOB_MESSAGE_LIST.FAILURE,
  payload: { error }
});

export const mhtJobMessageCreateRequest = (values) => ({
  type: types.MHT_JOB_MESSAGE_CREATE.REQUEST,
  payload: { values }
});

export const mhtJobMessageCreateSuccess = () => ({
  type: types.MHT_JOB_MESSAGE_CREATE.SUCCESS
});

export const mhtJobMessageCreateFailure = (error) => ({
  type: types.MHT_JOB_MESSAGE_CREATE.FAILURE,
  payload: { error }
});

export const mhtJobMessageReadRequest = (id) => ({
  type: types.MHT_JOB_MESSAGE_READ.REQUEST,
  payload: { id }
});

export const mhtJobMessageReadSuccess = () => ({
  type: types.MHT_JOB_MESSAGE_READ.SUCCESS
});

export const mhtJobMessageReadFailure = (error) => ({
  type: types.MHT_JOB_MESSAGE_READ.FAILURE,
  payload: { error }
});

export const mhtJobMessageBatchReadRequest = (values) => ({
  type: types.MHT_JOB_MESSAGE_BATCH_READ.REQUEST,
  payload: { values }
});

export const mhtJobMessageBatchReadSuccess = () => ({
  type: types.MHT_JOB_MESSAGE_BATCH_READ.SUCCESS
});

export const mhtJobMessageBatchReadFailure = (error) => ({
  type: types.MHT_JOB_MESSAGE_BATCH_READ.FAILURE,
  payload: { error }
});

export const mhtJobDeliveryRequest = (id) => ({
  type: types.MHT_JOB_DELIVERY.REQUEST,
  payload: { id }
});

export const mhtJobDeliverySuccess = () => ({
  type: types.MHT_JOB_DELIVERY.SUCCESS
});

export const mhtJobDeliveryFailure = (error) => ({
  type: types.MHT_JOB_DELIVERY.FAILURE,
  payload: { error }
});

export const mhtJobRedeliveryRequest = (id) => ({
  type: types.MHT_JOB_REDELIVERY.REQUEST,
  payload: { id }
});

export const mhtJobRedeliverySuccess = () => ({
  type: types.MHT_JOB_REDELIVERY.SUCCESS
});

export const mhtJobRedeliveryFailure = (error) => ({
  type: types.MHT_JOB_REDELIVERY.FAILURE,
  payload: { error }
});

export const mhtInvoiceDownloadRequest = (values) => ({
  type: types.MHT_INVOICE_DOWNLOAD.REQUEST,
  payload: { values }
});

export const mhtInvoiceDownloadSuccess = () => ({
  type: types.MHT_INVOICE_DOWNLOAD.SUCCESS
});

export const mhtInvoiceDownloadFailure = (error) => ({
  type: types.MHT_INVOICE_DOWNLOAD.FAILURE,
  payload: { error }
});


export const quoteSourcefileListRequest = (id) => ({
  type: types.QUOTE_SOURCEFILE_LIST.REQUEST,
  payload: { id }
});
export const quoteSourcefileListSuccess = () => ({
  type: types.QUOTE_SOURCEFILE_LIST.SUCCESS
});
export const quoteSourcefileListFailure = (error) => ({
  type: types.QUOTE_SOURCEFILE_LIST.FAILURE,
  payload: { error }
});