import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Field, reduxForm } from 'redux-form';
import { Table, Button, Form } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import UserScheduleField from './UserScheduleField';


const UserSchedule = (props) => {
  
  const {
    handleUpdateOrCreate,
    handleSubmit,
    collapsed,
    isLoading,
    hasHeader,
  } = props;
  
  return (
    <Form
      noValidate
      autoComplete="off">
      <Box
        className="box-widget mb-0"
        loading={isLoading}
        collapsed={collapsed}>
        { hasHeader &&
          <Box.Header border={true}>
            <Box.Title>Schedule</Box.Title>
            <Box.Tools>
              <Box.CollapsedToggleButton />
            </Box.Tools>
          </Box.Header>
        }
        <Box.Body noPadding={true}>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Start</th>
                <th>End</th>
                <th className="text-center">Available</th>
              </tr>
            </thead>
            <tbody>
              <Field
                name="sunday"
                label="Sunday"
                component={UserScheduleField} />
              <Field
                name="monday"
                label="Monday"
                component={UserScheduleField} />
              <Field
                name="tuesday"
                label="Tuesday"
                component={UserScheduleField} />
              <Field
                name="wednesday"
                label="Wednesday"
                component={UserScheduleField} />
              <Field
                name="thursday"
                label="Thursday"
                component={UserScheduleField} />
              <Field
                name="friday"
                label="Friday"
                component={UserScheduleField} />
              <Field
                name="saturday"
                label="Saturday"
                component={UserScheduleField} />
            </tbody>
          </Table>
        </Box.Body>
        <Box.Footer>
          <Button
            bsStyle="success"
            type="button"
            bsSize="sm"
            onClick={handleSubmit(values => handleUpdateOrCreate(values))}>
            <FontAwesome name="refresh" className="mr-5" />Update
          </Button>
        </Box.Footer>
      </Box>
    </Form>
  );
}

UserSchedule.defaultProps = {
  collapsed: false,
  hasHeader: false
}


export default reduxForm({
  enableReinitialize: true
})(UserSchedule);
