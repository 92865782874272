import * as types from 'actionTypes/loading';
import * as constants from 'misc/constants';

const initialState = {

  // Manager ===================================================================
  // Jobs ======================================================================
  [constants.JOB_DAILY_SCHEDULE]: false,
  [constants.JOB_FUTURE_SCHEDULE]: false,
  [constants.JOB_SEARCH]: false,
  [constants.JOB_CREATE]: false,
  [constants.TEMPLATE_CREATE_MODAL]: false,
  [constants.TEMPLATE_LIST]: false,
  [constants.JOB_DRAFT_LIST]: false,
  // [constants.WORKING_LIST]: false,
  [constants.DELIVERY_LIST]: false,
  [constants.PREPARING_LIST]: false,
  [constants.PENDING_LIST]: false,
  [constants.TOTRA_LIST]: false,
  [constants.JOB_PROCESS_MODAL]: false,
  // Clients ===================================================================
  [constants.CLIENT_CREATE]: false,
  [constants.CLIENT_LIST]: false,
  [constants.CLIENT_AUTO_COMPLETE]: false,
  [constants.CLIENT_USER_PREFERENCE_MODAL]: false,
  [constants.CLIENT_WEBSITE_MODAL]: false,
  // Users =====================================================================
  [constants.USER_CREATE]: false,
  [constants.USER_LIST]: false,
  [constants.USER_AVATAR_UPLOAD_MODAL]: false,
  // Accounting ================================================================
  [constants.PAYMENT_SEARCH]: false,
  [constants.SALES_REPORT]: false,
  [constants.NUMBER_REPORT]: false,
  [constants.SALES_TARGET]: false,


  // Employee ==================================================================
  // Jobs ======================================================================
  [constants.EMPLOYEE_JOB_DAILY_SCHEDULE]: false,
  [constants.EMPLOYEE_JOB_FUTURE_SCHEDULE]: false,
  [constants.TOTRA_CURRENT_JOBS]: false,
  [constants.TRANSLATOR_CURRENT_JOBS]: false,
  [constants.EDITOR_CURRENT_JOBS]: false,
  [constants.CHECKER_CURRENT_JOBS]: false,
  [constants.FINAL_EDITOR_CURRENT_JOBS]: false,
  [constants.SECOND_FINAL_EDITOR_CURRENT_JOBS]: false,
  [constants.DTP_CURRENT_JOBS]: false,
  [constants.SECOND_DTP_CURRENT_JOBS]: false,
  [constants.EMPLOYEE_JOB_HISTORY]: false,
  [constants.EMPLOYEE_JOB_FINISH_MODAL]: false,

  // Common ====================================================================
  // Auth ======================================================================
  [constants.LOGIN]: false,
  [constants.REFRESH]: true,
  [constants.PASSWORD_CHANGE_MODAL]: false,

  [constants.CONFIRM_MODAL]: false,
  [constants.MULTIPLE_FILE_UPLOAD_MODAL]: false,
  [constants.SINGLE_FILE_UPLOAD_MODAL]: false,

  [constants.CUSTOMER_LIST]: false,
  [constants.QUOTE_LIST]: false,
}


export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_LOADING:
      return setLoading(state, action);
    default:
      return state;
  }
}

const setLoading = (state, action) => {
  const { name, isLoading } = action.payload;
  return {
    ...state,
    [name]: isLoading
  };
}