import { connect } from 'react-redux';
import { getServiceOptions } from 'selectors/options';
import SelectOptionField from './SelectOptionField';


const mapStateToProps = (state) => {
  return {
    name: 'service',
    label: 'Service',
    options: getServiceOptions(state),
  }
}

export default connect(
  mapStateToProps,
)(SelectOptionField);