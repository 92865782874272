import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { draftListRequest } from 'actions/jobs/managers';
import { getDraftJobs } from 'selectors/lists';
import { isDraftListLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobDraftList from './presenter';


class JobDraftListContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.draftListRequest();
  }
  render() {
    return <JobDraftList {...this.props} />
  }
}

const mapStateToProps = (state) => {
  return {
    jobs: getDraftJobs(state),
    isLoading: isDraftListLoading(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    draftListRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobDraftListContainer);
