import React from 'react';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { DEFAULT_DATETIME_FORMAT } from 'misc/config';


const JobAssignmentTableRow = (props) => {

  const {
    idx,
    document,
    handleDownload,
    handleRemove,
    removable,
  } = props;


  const filename = document.name;
  const fileModified = moment(document.modified).format(DEFAULT_DATETIME_FORMAT);

  const filenameSection = document.stored
    ? <a onClick={() => handleDownload(document)}>{filename}</a>
    : <span>{filename}</span>

  return (
    <tr>
      <td>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`filename-${idx}`}>
              {filename}<br />{fileModified}
            </Tooltip>
          }>
          {filenameSection}
        </OverlayTrigger>
      </td>
      { removable && (
        <td className="text-right" style={{width:'40px'}}>
          <Button
            type="button"
            bsSize="xs"
            bsStyle="danger"
            onClick={() => handleRemove(document)}>
              <FontAwesome name="trash" />
          </Button>
        </td>
      )}
    </tr>
  )
}

export default JobAssignmentTableRow;
