import * as types from 'actionTypes/bootstrap';

// Manager =====================================================================
export const managerBootstrapRequest = () => ({
  type: types.MANAGER_BOOTSTRAP.REQUEST
});
export const managerBootstrapSuccess = () => ({
  type: types.MANAGER_BOOTSTRAP.SUCCESS
});
export const managerBootstrapFailure = (error) => ({
  type: types.MANAGER_BOOTSTRAP.FAILURE,
  payload: { error }
});


// Employee ====================================================================
export const employeeBootstrapRequest = () => ({
  type: types.EMPLOYEE_BOOTSTRAP.REQUEST
});
export const employeeBootstrapSuccess = () => ({
  type: types.EMPLOYEE_BOOTSTRAP.SUCCESS
});
export const employeeBootstrapFailure = (error) => ({
  type: types.EMPLOYEE_BOOTSTRAP.FAILURE,
  payload: { error }
});
