import React from "react";
import {TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";
import {Form, Radio, Modal} from "react-bootstrap";
import {change, reduxForm, reset} from "redux-form";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
  TRANSLATION_MEMORY_DETAIL_EXPORT_FORM,
  TRANSLATION_MEMORY_DETAIL_EXPORT_MODAL
} from "misc/constants";
import {connectModal} from "redux-modal";
import Field from "redux-form/lib/Field";

class TranslationMemoryExportModal extends React.Component {

  hide = () => {
    this.props.reset(TRANSLATION_MEMORY_DETAIL_EXPORT_FORM);
    this.props.handleHide();
  };

  render() {

    const {
      show,
      handleSubmit,
      onSubmit,
    } = this.props;

    const RadioField = ({ input, meta, ...rest }) => (
      <Radio
        {...input}
        {...rest}
        checked={input.value === rest.value}
      />
    );

    return (
      <Modal
        show={show}
        onHide={this.hide}
        animation={true}
        backdrop="static">
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(values => {
            onSubmit(values)
            this.props.reset(TRANSLATION_MEMORY_DETAIL_EXPORT_FORM);
          })}
          onReset={this.hide}
        >
          <TaModalHeader
            label={'Export Translation Memory'}
            onCancel={this.hide}
          />
          <TaModalContent>
            <div className="p-15">
              <Field
                name="fileFormat"
                component={RadioField}
                props={{value: "excel"}}
              >
                Microsoft Excel (.xlsx)
              </Field>
              <Field
                name="fileFormat"
                component={RadioField}
                props={{ value: "tmx" }}
              >
                Translation Memory (.tmx)
              </Field>
            </div>
          </TaModalContent>
          <TaModalFooter>
            <TaButton
              type="reset"
              icon={'clear'}
              label={'Cancel'}
            />
            <TaButton
              type="submit"
              icon={'check'}
              iconAlign={'right'}
              label={'Export'}
            />
          </TaModalFooter>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const initialValues = (
    state.modal &&
    state.modal[TRANSLATION_MEMORY_DETAIL_EXPORT_FORM] &&
    state.modal[TRANSLATION_MEMORY_DETAIL_EXPORT_MODAL].props &&
    state.modal[TRANSLATION_MEMORY_DETAIL_EXPORT_MODAL].props.initialValues
  ) ? state.modal[TRANSLATION_MEMORY_DETAIL_EXPORT_MODAL].props.initialValues : {
    fileFormat: 'excel'
  };
  return {
    initialValues: initialValues,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    change,
    reset
  }, dispatch);
};

TranslationMemoryExportModal = connectModal({
  name: TRANSLATION_MEMORY_DETAIL_EXPORT_MODAL
})(TranslationMemoryExportModal);

TranslationMemoryExportModal = reduxForm({
  form: TRANSLATION_MEMORY_DETAIL_EXPORT_FORM,
  enableReinitialize: false
})(TranslationMemoryExportModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationMemoryExportModal);
