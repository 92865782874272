import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import { JOB_TYPE_OPTIONS } from 'misc/config';
import * as validators from 'misc/validators';
import Callout from 'components/core/Callout';
import CharField from 'components/core/CharField';
import SelectField from 'components/core/SelectField';
import CheckboxField from 'components/core/CheckboxField';
import JobBaseNumberInputField from 'components/jobs/managers/JobBaseNumberInputField';
import JobBasicInfoFields from 'components/jobs/managers/JobBasicInfoFields';
import JobAssignmentFields from 'components/jobs/managers/JobAssignmentFields';
import JobNotesField from 'components/jobs/managers/JobNotesField';
import JobAdditionalCountRolesField from 'components/jobs/managers/JobAdditionalCountRolesField';
import { AESelectOptionField, CoordinatorSelectOptionField } from 'components/core/SelectOptionField';
import JobSourcefile from 'components/jobs/common/JobSourcefile';
import JobReference from 'components/jobs/common/JobReference';
import JobCommonReference from 'components/jobs/common/JobCommonReference';
import ClientCommonReference from 'components/clients/ClientCommonReference'


const JobEditForm = (props) => {

  const {
    id,
    form,
    job,
    isLoading,
    initialValues,
    invalid,
    isSuperuser,
    isSplit,
    isMHJob,
    client,
    formValues,
    submitFailed,
    handleSubmit,
    handleEdit,
    handleRemove
  } = props;

  const numberField = (
    <Row>
      <Col lg={4}>
        <Field
          name="type"
          label="Type"
          options={JOB_TYPE_OPTIONS}
          validate={[validators.required]}
          disabled={true}
          isRequired={true}
          component={SelectField.Labeled} />
      </Col>
      <Col lg={8}>
        <Field
          name="baseNumber"
          label="Base number"
          validate={[
            validators.required,
            validators.numeric,
            validators.minLength7,
            validators.maxLength8
          ]}
          isRequired={true}
          disabled={true}
          type="text"
          bsSize="sm"
          formName={form}
          component={JobBaseNumberInputField.Labeled} />
      </Col>
      <Col lg={12}>
        <Field
          name="branch"
          label="Split No."
          validate={[
            validators.alphanumeric,
            validators.maxLength246
          ]}
          type="text"
          component={CharField.Labeled} />
      </Col>
    </Row>
  )

  const subjectField = (
    <Row>
      <Col lg={12}>
        <Field
          name="aeSubject"
          label="AE Subject"
          type="text"
          validate={[validators.maxLength150]}
          component={CharField.Labeled} />
      </Col>
      <Col lg={12}>
        <Field
          name="subject"
          label="Subject"
          type="text"
          validate={[
            validators.required,
            validators.maxLength150
          ]}
          isRequired={true}
          component={CharField.Labeled} />
      </Col>
    </Row>
  )

  const memoField = (
    <Row>
      <Col lg={12}>
        <Field
          name="memo"
          label="Memo"
          type="text"
          validate={[validators.maxLength150]}
          component={CharField.Labeled} />
      </Col>
    </Row>
  )

  const userField = (
    <Row>
      <Col lg={6}>
        <AESelectOptionField
          isRequired={true}
          validate={[validators.required]} />
      </Col>
      <Col lg={6}>
        <CoordinatorSelectOptionField
          isRequired={true}
          validate={[validators.required]} />
      </Col>
    </Row>
  )

  const additionalCountRolesField = (
    <JobAdditionalCountRolesField formName={form} />
  )

  const memoryConfirmedParamsField = (
    (job.aeMemoryConfirmed && job.coordinatorMemoryConfirmed)
    ? (
      <React.Fragment>
        <Field
          className="mt-5 mb-10"
          name="aeMemoryConfirmed"
          label="Memory confirmed by AE"
          component={CheckboxField.Labeled} />
        <Field
          className="mt-5 mb-10"
          name="coordinatorMemoryConfirmed"
          label="Memory confirmed by Coordinator"
          component={CheckboxField.Labeled} />
      </React.Fragment>
    )
    : null
  )


  return (
    <Form
      noValidate
      autoComplete="off">
      <JobBasicInfoFields
        form={form}
        isLoading={isLoading}
        numberField={numberField}
        subjectField={subjectField}
        memoField={memoField}
        additionalCountRolesField={additionalCountRolesField}
        userField={userField}
        memoryConfirmedParamsField={memoryConfirmedParamsField} />
      <JobAssignmentFields
        form={props.form}
        initialValues={initialValues}
        requireAssignChangeNotification={true}
        formValues={formValues}
        handleSubmit={handleSubmit} />
      <JobNotesField
        name="instruction"
        title="Instruction"
        isLoading={isLoading}
        isRequired={!isMHJob} />
      <JobNotesField
        name="notes"
        title="Job notes"
        isLoading={isLoading} />
      <JobSourcefile
        jobId={id}
        uploadable={true}
        removable={job && job.status === 'Job Created'} />
      <JobReference
        jobId={id}
        uploadable={true}
        removable={true} />
      <JobCommonReference
        jobGroup={job && job.group}
        uploadable={true}
        removable={true} />
      <ClientCommonReference
        title="Client Common References"
        jobId={id}
        clientId={client}
        usable={true}
        uploadable={true} />
      <Callout
        invalid={invalid}
        submitFailed={submitFailed} />
      <Button
        type="button"
        bsSize="sm"
        bsStyle="success"
        onClick={handleSubmit(values => handleEdit(values))}>
        <FontAwesome name="refresh" className="mr-5" />Update
      </Button>
      { isSuperuser &&
        <Button
          type="button"
          bsSize="sm"
          className="ml-10"
          bsStyle="danger"
          disabled={!isSplit}
          onClick={handleRemove}>
          <FontAwesome name="trash" className="mr-5" />Delete
        </Button>
      }
    </Form>
  )
}

export default reduxForm({
  enableReinitialize: true,
  validate: validators.jobFormFullValidate
})(JobEditForm)
