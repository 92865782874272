import React from 'react';
import moment from 'moment';
import JobAssignment from 'components/jobs/common/JobAssignment';


const JobWorkFlow = (props) => {

  const {
    myRole,
    myDeadline,
    assignments,
    executable,
    uploadable,
    removable,
    isLoading,
    handleJobStart
  } = props;

  const isEditorOrChecker = (assignment) => {
    return (myRole === 'Editor' || myRole === 'Checker') &&
    (
      moment(assignment.deadline).isSameOrAfter(myDeadline) ||
      assignment.role.name === 'ToTra'
    )
  }

  return (
    <section className="job-work-flow">
      {assignments.map(assignment => {
        return isEditorOrChecker(assignment)
          ? null
          : <JobAssignment
              key={assignment.id}
              assignmentId={assignment.id}
              assignment={assignment}
              handleJobStart={handleJobStart}
              executable={executable}
              uploadable={uploadable}
              removable={removable}
              isLoading={isLoading} />
      })}
    </section>
  );
}

JobWorkFlow.defaultProps = {
  executable: false,
  uploadable: false,
  removable: false
}

export default JobWorkFlow;
