import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Navbar, Nav, NavItem, InputGroup, FormControl, Button } from 'react-bootstrap';
import Select from 'components/core/Select';
import FilterBar from 'components/core/FilterBar';


const UserListFilter = (props) => {
  
  const {
    keyword,
    service,
    serviceOptions,
    group,
    groupOptions,
    handleServiceChange,
    handleGroupChange,
    handleSubmit,
    handleKeywordChange
  } = props;
  
  return (
    <FilterBar>
      <Nav>
        <NavItem disabled>Group</NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <Select
          value={group}
          clearable={true}
          placeholder=""
          options={groupOptions}
          onChange={handleGroupChange}
          style={{ width: 200 }} />
      </Navbar.Form>
      <Nav>
        <NavItem disabled>Service</NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <Select
          value={service}
          clearable={true}
          placeholder=""
          options={serviceOptions}
          onChange={handleServiceChange}
          style={{ width: 200 }} />
      </Navbar.Form>
      <Navbar.Form pullRight>
        <InputGroup bsSize="sm">
          <FormControl
            value={keyword}
            onChange={(e) => handleKeywordChange(e.target.value)}
            onKeyPress={(e) => {
              e.key === 'Enter' && handleSubmit()
            }}
            type="text"
            style={{ width: 200 }}
            bsSize="sm" />
          <InputGroup.Button>
            <Button
              bsSize="sm"
              onClick={handleSubmit}>
              <FontAwesome name="search" />
            </Button>
          </InputGroup.Button>
        </InputGroup>
      </Navbar.Form>
    </FilterBar>
  );
}

export default UserListFilter;
