import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isUserCheckSkillLoading } from 'selectors/loading';
import { getCheckSkillsByUserId } from 'selectors/users';
import {
    checkSkillListRequest,
    checkSkillCreateRequest,
    checkSkillUpdateRequest,
} from 'actions/users'
import UserSkillHOC from './UserSkillHOC';
import UserSkill from './presenter';


const mapStateToProps = (state, props) => {
  const id = props.userId;
  return {
    isLoading: isUserCheckSkillLoading(state, id),
    skills: getCheckSkillsByUserId(state, id)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest: checkSkillListRequest,
    createRequest: checkSkillCreateRequest,
    updateRequest: checkSkillUpdateRequest,
  }, disptach)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSkillHOC(UserSkill))
