import React from 'react';
import { Box } from 'reactjs-admin-lte';
import TooltipIconButton from 'components/core/TooltipIconButton';
import DocumentTable from 'components/documents/DocumentTable';


const JobClientCommonReference = (props) => {

  const {
    isLoading,
    files,
    title,
    jobId,
    uploadable,
    usable,
    showUploadModal,
    handleDownload,
    handleUse,
  } = props;

  return (
    <Box
      className="box-widget"
      loading={isLoading}>
      <Box.Header border={true}>
        <Box.Title>{title}</Box.Title>
        <Box.Tools>
          { uploadable &&
            <TooltipIconButton
              id="upload-new-reference"
              className="btn-box-tool"
              icon="upload"
              description="Upload Client Reference"
              onClick={showUploadModal} />
          }
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <DocumentTable
          documents={files}
          handleDownload={handleDownload}
          usable={usable}
          handleUse={handleUse}
          jobId={jobId} />
      </Box.Body>
    </Box>
  )
}

JobClientCommonReference.defaultProps = {
  title: 'Client Common References',
  uploadable: false,
  usable: false,
}


export default JobClientCommonReference;
