import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import DateFormatter from 'components/core/DateFormatter'
import UserAvatar from 'components/users/UserAvatar';


const ShortRoleMap = {
  'Translator': 'Tra',
  'Editor': 'Edi',
  'Checker': 'Che',
  'Final Editor': 'FE1',
  'Second Final Editor': 'FE2',
  'DTP': 'DTP1',
  'Second DTP': 'DTP2',
  'ToTra': 'ToTra',
  'Coordinator': 'Coordi',
  'AE': 'AE',
}

const JobMessageListItem = (props) => {
  const {
    message,
    myUserId,
    showMessageEditModal,
    handleRemove,
    isCoordinator
  } = props;

  const cls = classNames({
    message: true,
    'mht-message': message.isMht
  })

  return (
    <div className={cls}>
      <div className="message-header">
        <div className="user-block">
          <UserAvatar
            className="img-circle img-responsive"
            url={message.sender.avatar} />
          <span className="username">
            {message.sender.username}
            <span className="message-meta pull-right">
              <DateFormatter datetime={message.created} />
            </span>
          </span>
          <span className="description">
            {message.roles.map(role => (
              <span
                key={role.id}
                className="label label-default label-group label-receiver mr-3 mb-3">
                {ShortRoleMap[role.name]}
              </span>
            ))}
          </span>
        </div>
      </div>
      <div className="message-body">
        <p className="br">{message.body}</p>
      </div>
      { (myUserId === message.sender.id || isCoordinator) && (
        <div className="message-footer clearfix">
          <Button
            className="btn btn-xs btn-default pull-left"
            onClick={() => showMessageEditModal(message)}>
            <FontAwesome name="edit" className="mr-5" />Edit
          </Button>
          <Button
            className="btn btn-xs btn-danger bg-red pull-right"
            onClick={() => handleRemove(message)}>
            <FontAwesome name="trash" className="mr-5" />Delete
          </Button>
        </div>
      ) }
    </div>
  )
}


export default JobMessageListItem;