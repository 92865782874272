import React from "react";
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './TaTooltip.module.css'
import delay from "../util/delay";

export class TaTooltip extends React.PureComponent {

  constructor(props) {
    super(props);
    this.portal = this.getPortal('tooltips');
    this.setTooltipRef = element => {
      this.tooltip = element;
    };
    this.state = {};
    this.delay = undefined;
  }

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (this.delay) {
      this.delay.cancel();
      this.delay = undefined;
    }
  }

  getPortal = (id) => {
    let portal = document.getElementById(id);
    if (!portal) {
      portal = document.createElement('div');
      portal.setAttribute('id', id);
      document.body.appendChild(portal);
    }
    return portal;
  }

  show = (currentTarget) => {
    const tooltipRect = this.tooltip.getBoundingClientRect();
    const triggerRect = currentTarget.getBoundingClientRect();
    const modify = ((tooltipRect.width - triggerRect.width) / 2)
    this.setState({
      top: (triggerRect.top + triggerRect.height + window.pageYOffset) + 'px',
      left: (triggerRect.left - modify + window.pageXOffset) + 'px',
      opacity: 1,
      zIndex: 1000
    })
  }

  hide = (e) => {
    this.cancel();
    this.setState({
      opacity: 0,
      zIndex: -1000
    })
  }

  onEnter = (event) => {
    this.cancel();
    this.delay = delay(event.currentTarget);
    this.delay.promise.then(this.show);
  }

  render() {

    const toolTip = (
      <div
        ref={this.setTooltipRef}
        style={this.state}
        className={styles.tooltip}
      >
        <div className={styles.arrowUp}/>
        <div className={styles.bubble} children={this.props.value}/>
      </div>
    );

    return <React.Fragment>
      <div
        onMouseEnter={this.onEnter}
        onMouseLeave={this.hide}
        children={this.props.children}
        className={this.props.className}
      />
      {
        ReactDOM.createPortal(
          toolTip,
          this.portal
        )
      }
    </React.Fragment>
  }
}

TaTooltip.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
};
