import { createAsyncActionTypes } from 'misc/utils';

const PREFIX = 'employees';

// Employee Job Schedule =======================================================
export const EMPLOYEE_JOB_DAILY_SCHEDULE = createAsyncActionTypes(`${PREFIX}/EMPLOYEE_JOB_DAILY_SCHEDULE`);
export const EMPLOYEE_JOB_FUTURE_SCHEDULE = createAsyncActionTypes(`${PREFIX}/EMPLOYEE_JOB_FUTURE_SCHEDULE`);

// Employee Current Jobs =======================================================
export const TOTRA_CURRENT_JOBS = createAsyncActionTypes(`${PREFIX}/TOTRA_CURRENT_JOBS`);
export const TRANSLATOR_CURRENT_JOBS = createAsyncActionTypes(`${PREFIX}/TRANSLATOR_CURRENT_JOBS`);
export const EDITOR_CURRENT_JOBS = createAsyncActionTypes(`${PREFIX}/EDITOR_CURRENT_JOBS`);
export const CHECKER_CURRENT_JOBS = createAsyncActionTypes(`${PREFIX}/CHECKER_CURRENT_JOBS`);
export const FINAL_EDITOR_CURRENT_JOBS = createAsyncActionTypes(`${PREFIX}/FINAL_EDITOR_CURRENT_JOBS`);
export const SECOND_FINAL_EDITOR_CURRENT_JOBS = createAsyncActionTypes(`${PREFIX}/SECOND_FINAL_EDITOR_CURRENT_JOBS`);
export const DTP_CURRENT_JOBS = createAsyncActionTypes(`${PREFIX}/DTP_CURRENT_JOBS`);
export const SECOND_DTP_CURRENT_JOBS = createAsyncActionTypes(`${PREFIX}/SECOND_DTP_CURRENT_JOBS`);

// Employee Job Retrieve =======================================================
export const TOTRA_JOB_RETRIEVE = createAsyncActionTypes(`${PREFIX}/TOTRA_JOB_RETRIEVE`);
export const TRANSLATOR_JOB_RETRIEVE = createAsyncActionTypes(`${PREFIX}/TRANSLATOR_JOB_RETRIEVE`);
export const EDITOR_JOB_RETRIEVE = createAsyncActionTypes(`${PREFIX}/EDITOR_JOB_RETRIEVE`);
export const CHECKER_JOB_RETRIEVE = createAsyncActionTypes(`${PREFIX}/CHECKER_JOB_RETRIEVE`);
export const FINAL_EDITOR_JOB_RETRIEVE = createAsyncActionTypes(`${PREFIX}/FINAL_EDITOR_JOB_RETRIEVE`);
export const SECOND_FINAL_EDITOR_JOB_RETRIEVE = createAsyncActionTypes(`${PREFIX}/SECOND_FINAL_EDITOR_JOB_RETRIEVE`);
export const DTP_JOB_RETRIEVE = createAsyncActionTypes(`${PREFIX}/DTP_JOB_RETRIEVE`);
export const SECOND_DTP_JOB_RETRIEVE = createAsyncActionTypes(`${PREFIX}/SECOND_DTP_JOB_RETRIEVE`);

export const EMPLOYEE_JOB_START = createAsyncActionTypes(`${PREFIX}/EMPLOYEE_JOB_START`);
export const EMPLOYEE_JOB_FINISH = createAsyncActionTypes(`${PREFIX}/EMPLOYEE_JOB_FINISH`);
export const REPORT_COUNT = createAsyncActionTypes(`${PREFIX}/REPORT_COUNT`);

// Employee History
export const HISTORY_SEARCH = createAsyncActionTypes(`${PREFIX}/HISTORY_SEARCH`);
export const PRINT = createAsyncActionTypes(`${PREFIX}/PRINT`);
export const HISTORY_CSV_EXPORT = createAsyncActionTypes(`${PREFIX}/HISTORY_CSV_EXPORT`);

export const ALL_REFERENCE_DOWNLOAD = createAsyncActionTypes(`${PREFIX}/ALL_REFERENCE_DOWNLOAD`);

export const DTP_BULK_FINISH = createAsyncActionTypes(`${PREFIX}/DTP_BULK_FINISH`);
export const SECOND_DTP_BULK_FINISH = createAsyncActionTypes(`${PREFIX}/SECOND_DTP_BULK_FINISH`);

export const SET_PROCESSED = `${PREFIX}/SET_PROCESSED`;