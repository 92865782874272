import React from 'react';
import classNames from 'classnames';
import { Box } from 'reactjs-admin-lte';
import TooltipIconButton from 'components/core/TooltipIconButton';
import DocumentTable from 'components/documents/DocumentTable';


const JobSourcefile = (props) => {

  const {
    title,
    isLoading,
    files,
    hasFile,
    hasMHT,
    handleDownload,
    showUploadModal,
    showUploadMHTModal,
    handleRemove,
    highlight,
    uploadable,
    isMHTClickable,
    isMHTCreatable,
    handleOpenMHT,
    removable
  } = props;

  const cls = classNames({
    'box-widget': true,
    'box-success': highlight,
    'box-solid': highlight,
  });

  return (
    <Box
      className={cls}
      loading={isLoading}>
      <Box.Header border={true}>
        <Box.Title>{title}</Box.Title>
        <Box.Tools>
          { uploadable &&
            <React.Fragment>
              <TooltipIconButton
                id="upload-new-source-file"
                className="btn-box-tool"
                icon="upload"
                description="Upload Source File"
                onClick={showUploadModal} />
              <TooltipIconButton
                id="upload-new-source-file-for-mht"
                className="btn-box-tool"
                icon="laptop"
                disabled={hasMHT() || hasFile() || !isMHTCreatable}
                description="Upload Source File for MHT"
                onClick={showUploadMHTModal} />
            </React.Fragment>
          }
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <DocumentTable
          documents={files}
          handleDownload={handleDownload}
          isMHTClickable={isMHTClickable}
          handleOpenMHT={handleOpenMHT}
          removable={removable}
          handleRemove={handleRemove} />
      </Box.Body>
    </Box>
  )
}

JobSourcefile.defaultProps = {
  title: 'Source Files',
  highlight: false,
  uploadable: false,
  removable: false
}

export default JobSourcefile;
