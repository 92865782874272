import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import configureStore from 'store/configureStore';
import configureDefault from 'misc/configureDefault';
import rootSaga from 'sagas';
import App from 'components/App';

const { store, persistor } = configureStore();
store.runSaga(rootSaga);
configureDefault(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
