import React from "react";
import * as PropTypes from 'prop-types';
import './taTab.css';

export const TaTab = (props) => {

  const baseClass = 'ta-tab';

  const {
    className,
    scroll,
    flex,
    ...attributes
  } = props;

  let classNames = [baseClass];

  if (scroll === 'auto') {
    classNames.push(baseClass + '--scroll');
  } else if (scroll) {
    classNames.push(baseClass + '--scroll-' + scroll);
  }

  if (flex === 'auto') {
    classNames.push(baseClass + '--flex');
  } else if (flex && scroll !== 'hidden') {
    if (typeof attributes.style === 'undefined') {
      attributes.style = {};
    }
    attributes.style.flex = flex;
  }

  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')}>
      {props.children}
    </div>
  )

};
TaTab.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  badge: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};