import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { Box } from 'reactjs-admin-lte';
import { Field, reduxForm } from 'redux-form';
import CharField from 'components/core/CharField';
import ClientAsyncSelectField from 'components/core/ClientAsyncSelectField'
import DateRangeFormField from 'components/core/DateRangeFormField';
import {
  AESelectOptionField,
  CoordinatorSelectOptionField,
  ServiceSelectOptionField,
  IndustrySelectOptionField,
  FieldSelectOptionField,
  TranslatorSelectOptionField,
  EditorSelectOptionField,
  CheckerSelectOptionField,
} from 'components/core/SelectOptionField';
import { JOB_STATUS_OPTIONS } from 'misc/config';
import SelectField from 'components/core/SelectField';
import CheckboxField from 'components/core/CheckboxField';


const JobSearchForm = (props) => {

  const {
    handleSearch,
    handleReset,
    handleSubmit,
  } = props;

  return (
    <Form
      horizontal
      noValidate
      className="form-condensed">

      <Box className="box-widget">
        <Box.Header border={true}>
          <Box.Title>
            <FontAwesome name="filter" />
          </Box.Title>
          <Box.Tools>
            <Box.CollapsedToggleButton />
          </Box.Tools>
        </Box.Header>
        <Box.Body>
          <Row>
            <Col lg={4}>
              <DateRangeFormField label="Created" />
              <Field
                name="number"
                label="Number"
                type="text"
                condensed={true}
                component={CharField.Horizontal} />
              <Field
                name="keyword"
                label="Keyword"
                type="text"
                component={CharField.Horizontal} />
              <CoordinatorSelectOptionField type="Horizontal" />
            </Col>
            <Col lg={4}>
              <ServiceSelectOptionField type="Horizontal" />
              <Field
                name="client"
                label="Client"
                component={ClientAsyncSelectField.Horizontal} />
              {/* <IndustrySelectOptionField type="Horizontal" />
              <FieldSelectOptionField type="Horizontal" /> */}
              <Field
                name="status"
                label="Status"
                options={JOB_STATUS_OPTIONS}
                component={SelectField.Horizontal} />
              <AESelectOptionField type="Horizontal" />
            </Col>
            <Col lg={4}>
              <TranslatorSelectOptionField type="Horizontal" />
              <EditorSelectOptionField type="Horizontal" />
              <CheckerSelectOptionField type="Horizontal" />
              <Field
                className="mt-5 mb-10"
                name="mht"
                label="MHT"
                component={CheckboxField.Horizontal} />
            </Col>
          </Row>
        </Box.Body>
        <Box.Footer>
          <Button
            bsStyle="primary"
            bsSize="sm"
            type="submit"
            onClick={handleSubmit(values => handleSearch(values))}>
            <FontAwesome name="search" className="mr-5" />Search
          </Button>
          <Button
            bsStyle="default"
            bsSize="sm"
            type="button"
            className="ml-10"
            onClick={handleReset}>
            <FontAwesome name="undo" className="mr-5" />Reset
          </Button>
        </Box.Footer>
      </Box>
    </Form>
  )
}

export default reduxForm()(JobSearchForm);