import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Form, Button } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import IconInputGroupField from 'components/core/IconInputGroupField';
import * as validators from 'misc/validators';

const LoginFom = (props) => {
  
  const {
    error,
    handleSubmit,
  } = props;
  
  return (
    <Form
      noValidate
      onSubmit={handleSubmit}>
      <Field
        type="text"
        name="username"
        icon="user"
        placeholder="Username"
        validate={[validators.required]}
        component={IconInputGroupField} />

      <Field
        type="password"
        name="password"
        icon="lock"
        placeholder="Password"
        validate={[validators.required]}
        component={IconInputGroupField} />
      
      { error &&
        <div className="callout callout-danger">
          <p>{error}</p>
        </div>
      }

      <Button
        bsStyle="primary"
        block
        type="submit">
        <FontAwesome name="paper-plane-o" className="mr-5" />LOGIN
      </Button>
    </Form>
  );    
}


export default reduxForm()(LoginFom);