import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import { TRANSLATION_MEMORY_UPLOAD_MODAL } from 'misc/constants';
import { isMemorySearchListLoading } from 'selectors/loading';
import {
  getMemorySearches,
  getMemorySearchListCurrentPage,
  getMemorySearchListPageSize
} from 'selectors/lists';
import {
  searchListRequest,
  uploadRequest,
  searchDeleteRequest
} from 'actions/memories';
import TranslationMemoryList from './presenter';


class TranslationMemoryListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    }
  }

  componentDidMount() {
    const params = { page: this.props.currentPage };
    this.props.searchListRequest(params);
  }

  handlePageSelect = (page) => {
    const params = { page };
    this.props.searchListRequest(params);
  }

  handleUpload = (values) => {
    this.props.uploadRequest(values);
  }

  showTranslationMemoryUploadModal = () => {
    this.props.show(TRANSLATION_MEMORY_UPLOAD_MODAL, {
      handleAddTranslationMemory: this.handleUpload
    })
  }

  handleSelect = (e) => {
    const { checked, value } = e.target;
    const newValue = [...this.state.selected];
    checked
      ? newValue.push(value)
      : newValue.splice(newValue.indexOf(value), 1)
    this.setState({ selected: newValue });
  }

  handleDelete = () => {
    this.props.searchDeleteRequest({ items: this.state.selected });
    this.setState({ selected: [] })

  }

  render() {
    return (
      <TranslationMemoryList
        {...this.props}
        selected={this.state.selected}
        handleSelect={this.handleSelect}
        handleDelete={this.handleDelete}
        showTranslationMemoryUploadModal={this.showTranslationMemoryUploadModal}
        handlePageSelect={this.handlePageSelect} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    memorySearches: getMemorySearches(state),
    isLoading: isMemorySearchListLoading(state),
    pageSize: getMemorySearchListPageSize(state),
    currentPage: getMemorySearchListCurrentPage(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    searchListRequest,
    uploadRequest,
    searchDeleteRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationMemoryListContainer);