import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translatorJobRetrieveRequest } from 'actions/jobs/employees';
import { isTranslatorJobDetailLoading } from 'selectors/loading';
import EmployeeJobDetailHOC from './EmployeeJobDetailHOC';
import JobDetail from './presenter';


const mapStateToProps = (state, props) => {
  const { id } = props.params;
  return {
    id,
    roleKey: 'translator',
    isLoading: isTranslatorJobDetailLoading(state, id)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    retrieveRequest: translatorJobRetrieveRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeJobDetailHOC(JobDetail));
