import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import { ProgressBar } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import { getDetailTabConfigByName } from 'misc/utils';
import { MHT_SETUP_PROGRESS_MODAL, MHT_EDITOR } from 'misc/constants';
import CoreApi from 'services/CoreApi';
import PureRenderComponent from 'components/core/PureRenderComponent';
import { addOrSelectTab } from 'actions/globalTabs';


class MHTSetupProgressModal extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      progressDone: false,
      description: "Uploading..."
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {

      if (this.props.task_id) {
        CoreApi.checkTaskProgress(this.props.task_id)
          .then(response => {
            if (response.data.state === "PROGRESS") {
              const { current, total, desc } = response.data.details;
              let progress = Math.floor((current / total) * 100);
              progress = Math.max(this.state.progress, progress);
              this.setState({ progress, description: desc });
            } else if (response.data.state === "SUCCESS") {
              this.setState({ progress: 100, progressDone: true, description: "Done." });
              clearInterval(this.intervalId);
            }
          })
          .catch(error => {
            this.setState({ progress: 0, description: error.response.data.detail, progressDone: true });
            clearInterval(this.intervalId);
          })
      } else {
        const localProgress = Math.max(this.state.progress, this.state.progress + 1)
        this.setState({ progress: localProgress });
      }
    }, 1000);
  }

  componentWillUnmount(){
    clearInterval(this.intervalId);
  }

  handleOpenMHT = () => {
    const { mhtId, service, client, job } = this.props;
    const tabParams = {
      id: mhtId,
      stored: true,
      role: 'coordinator',
      service: service,
      client: client,
     };
    const tabConfig = getDetailTabConfigByName(
      MHT_EDITOR,
      mhtId,
      job,
      tabParams
    );
    this.props.addOrSelectTab(
        tabConfig['id'],
        tabConfig['title'],
        tabConfig['componentPath'],
        tabConfig['componentName'],
        tabConfig['params'],
        tabConfig['allowRefresh'],
        tabConfig['requireCloseConfirm']
    )
    this.props.handleHide();
  }

  render() {
    const {
      show,
      handleHide,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Modal.Header>
          <Modal.Title>MHT Setup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{this.state.description}</p>
          <ProgressBar
            className="mb-0"
            active
            label={`${this.state.progress}%`}
            now={this.state.progress} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pull-left"
            bsSize="sm"
            type="button"
            disabled={!this.state.progressDone}
            onClick={handleHide}>
            <FontAwesome name="close" className="mr-5" />Close
          </Button>
          <Button
            bsStyle="primary"
            bsSize="sm"
            type="button"
            disabled={!this.state.progressDone}
            onClick={this.handleOpenMHT}>
            <FontAwesome name="paper-plane-o" className="mr-5" />Open MHT
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    addOrSelectTab,
  }, disptach)
}

MHTSetupProgressModal = connectModal({
  name: MHT_SETUP_PROGRESS_MODAL
})(MHTSetupProgressModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MHTSetupProgressModal);
