import React from "react";
import {TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";
import {Form, Modal} from "react-bootstrap";
import {change, Field, reduxForm, reset} from "redux-form";
import {LANGUAGE_OPTIONS} from "misc/config";
import * as validators from "misc/validators";
import SelectField from "components/core/SelectField";
import ClientAsyncSelectField from "components/core/ClientAsyncSelectField";
import TextField from "components/core/TextField";
import {connect} from "react-redux";
import {isWordListDetailSettingsModalLoading} from "selectors/loading";
import {bindActionCreators} from "redux";
import {
  WORDLIST_DETAIL_SETTINGS_FORM,
  WORDLIST_DETAIL_SETTINGS_MODAL,
} from "misc/constants";
import Spinner from "components/core/Spinner";
import {connectModal} from "redux-modal";
import {TaFormFieldText} from "components/taUi/taFormField/taFormFieldText";

class WordListSettingsModal extends React.Component {

  hide = () => {
    this.props.handleHide();
    this.props.reset(WORDLIST_DETAIL_SETTINGS_FORM);
  };

  render() {
    const {
      show,
      handleSubmit,
      onSubmit,
      isLoading
    } = this.props;
    return (
      <Modal
        show={show}
        onHide={this.hide}
        animation={true}
        backdrop="static"
      >
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit((values => onSubmit(values)))}
          onReset={this.hide}
        >
          <TaModalHeader
            label={'Edit Client WordList'}
            onCancel={this.hide}
          />
          <TaModalContent>
            <Spinner isLoading={isLoading} />
            <div className="p-15">
              <Field
                name="name"
                label="Name"
                type="text"
                validate={[
                  validators.required,
                  validators.maxLength100
                ]}
                isRequired={true}
                component={TaFormFieldText}/>
              <Field
                name="client"
                label="Client"
                validate={[
                  validators.required
                ]}
                isRequired={true}
                component={ClientAsyncSelectField.Labeled}/>
              <Field
                name="notes"
                label="Notes"
                component={TextField.Labeled}/>
            </div>
          </TaModalContent>
          <TaModalFooter>
            <TaButton
              type="reset"
              icon={'clear'}
              label={'Cancel'}
            />
            <TaButton
              type="submit"
              icon={'check'}
              label={'Save'}
              iconAlign={'right'}
            />
          </TaModalFooter>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const initialValues = (
    state.modal &&
    state.modal[WORDLIST_DETAIL_SETTINGS_MODAL] &&
    state.modal[WORDLIST_DETAIL_SETTINGS_MODAL].props &&
    state.modal[WORDLIST_DETAIL_SETTINGS_MODAL].props.initialValues
  ) ? state.modal[WORDLIST_DETAIL_SETTINGS_MODAL].props.initialValues : {};

  return {
    initialValues: initialValues,
    isLoading: isWordListDetailSettingsModalLoading(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    change,
    reset
  }, dispatch);
};

WordListSettingsModal = connectModal({
  name: WORDLIST_DETAIL_SETTINGS_MODAL
})(WordListSettingsModal);

WordListSettingsModal = reduxForm({
  form: WORDLIST_DETAIL_SETTINGS_FORM,
  enableReinitialize: true
})(WordListSettingsModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WordListSettingsModal);