import React from 'react';
import classNames from 'classnames';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';
import { Checkbox } from 'react-bootstrap';
import PureRenderComponent from 'components/core/PureRenderComponent';
import { EMPLOYEE_STATUS_MAP } from 'misc/config';
import { getDetailTabConfigByName } from 'misc/utils';


export class LinkFieldCellRenderer extends PureRenderComponent {
  render() {
    const { value, data, colDef } = this.props;
    const { tab, handleTabOpen, params = {} } = colDef.cellRendererParams;
    if (value && data && data.id) {
      const id = data.id;
      const tabParams = { ...params, id };
      const tabConfig = getDetailTabConfigByName(tab, id, value, tabParams);
      return <a onClick={() => handleTabOpen(tabConfig)}>{value}</a>
    }
    return null;
  }
}


export class MHTLinkFieldCellRenderer extends PureRenderComponent {
  render() {
    const { value, data, colDef } = this.props;
    const { tab, handleTabOpen, params = {} } = colDef.cellRendererParams;
    if (value && data && data.id) {
      const id = data.id;
      const tabParams = {
        ...params,
        id,
        service: data.job.service,
        client: data.job.client,
        role: 'coordinator'
       };
      const tabConfig = getDetailTabConfigByName(tab, id, value, tabParams);
      return <a onClick={() => handleTabOpen(tabConfig)}>{value}</a>
    }
    return null;
  }
}

export class NumberOrIdLinkFieldCellRenderer extends PureRenderComponent {
  render() {
    const { data, colDef } = this.props;
    const { tab, handleTabOpen } = colDef.cellRendererParams;

    if (data) {
      const { id, number } = data;
      const title = number ? number : id;
      const tabConfig = getDetailTabConfigByName(tab, id, title, { id: id });
      return <a onClick={() => handleTabOpen(tabConfig)}>{title}</a>
    }
    return null;
  }
}

export class NumberLinkOrIdFieldCellRenderer extends PureRenderComponent {
  render() {
    const { data, colDef } = this.props;
    const { tab, handleTabOpen } = colDef.cellRendererParams;

    if (data) {
      const { id, number } = data;
      const title = number ? number : id;
      if (data.status !== 'Job Pending') {
        const tabConfig = getDetailTabConfigByName(tab, id, title, { id: id });
        return <a onClick={() => handleTabOpen(tabConfig)}>{title}</a>
      }
      return <span>{title}</span>
    }
    return null;
  }
}


export class BooleanFieldCellRenderer extends PureRenderComponent {
  render() {
    const { value } = this.props;
    const cls = classNames({
      'text-success': value === true,
      'text-danger': value === false,
    });
    const iconName = value === true ? 'check' : 'times';

    return (
      <FontAwesome
        name={iconName}
        className={cls}
        tag="i" />
    );
  }
}

export class MultipleFieldCellRenderer extends PureRenderComponent {
  render() {
    const { value, colDef } = this.props;
    const { key } = colDef.cellRendererParams;
    if (value && key) {
      const texts = value.map(v => {
        if (v) return v[key];
      });
      return <span>{texts.join(', ')}</span>
    }
    return null;
  }
}


const DeadlineInRow = (props) => {
  const { deadline, strictDeadline } = props;
  const cls = classNames({
    'deadline-in-row': true,
    'text-danger': strictDeadline
  });
  return (
    <div className={cls}>
      <div className="deadline-in-row-day">{moment(deadline).format('DD')}</div>
      <div className="deadline-in-row-sep">|</div> 
      <div className="deadline-in-row-time">{moment(deadline).format('HH:mm')}</div>
    </div>
  )
}

export class ClientFieldCellRenderer extends PureRenderComponent {
  render() {
    const { value, data } = this.props;
    const cls = classNames({ 'text-danger': data.level === 'L1' })
    return value
      ? (
        <div className="detailed-cell">
          <div className={cls}>{value}</div>
          <DeadlineInRow
            deadline={data.deadline}
            strictDeadline={data.strictDeadline} />
        </div>
      )
      : null
  }
}

export class AssignmentFieldCellRenderer extends PureRenderComponent {
  render() {
    const { value, data, colDef } = this.props;
    const { roleKey } = colDef.cellRendererParams;
    if (value && data && data[roleKey]) {
      const assignment = data[roleKey];
      return (
        <div className="detailed-cell">
          <div>{value}</div>
          <DeadlineInRow
            deadline={assignment.deadline}
            strictDeadline={assignment.strictDeadline} />
        </div>
      )
    } else {
      return null;
    }
  }
}

export class CheckboxCellRenderer extends PureRenderComponent {
  render() {
    const { value, data, colDef } = this.props;
    const { handleCheck } = colDef.cellRendererParams;
    return data && data.id
      ? (
        <Checkbox
          checked={value}
          value={data.id}
          onChange={handleCheck}
          className="mt-5 mb-5" />
      ) : null
  }
}

export class LinkedAssignmentFieldCellRenderer extends PureRenderComponent {
  render() {
    const { value, data, colDef } = this.props;
    const {
      roleKey,
      userId,
      tab,
      sentStatus,
      nowStatus,
      handleTabOpen
    } = colDef.cellRendererParams;

    if (value && data && data[roleKey]) {
      const assignment = data[roleKey];

      const isMyAssign = assignment.assignee.id === userId;
      const isWorkingAssign = data.status === sentStatus || data.status === nowStatus;

      let userInfo;
      let tabConfig;

      if (isMyAssign && isWorkingAssign) {
        tabConfig = getDetailTabConfigByName(tab, data.id, data.number, { id: data.id, readOnly: false });
        userInfo = <a onClick={() => handleTabOpen(tabConfig)}><strong>{value}</strong></a>
      } else if (isMyAssign && !isWorkingAssign) {
        tabConfig = getDetailTabConfigByName(tab, data.id, data.number, { id: data.id, readOnly: true });
        userInfo = <u onClick={() => handleTabOpen(tabConfig)}>{value}</u>
      } else {
        userInfo = <span>{value}</span>
      }

      return (
        <div className="detailed-cell">
          <div>{userInfo}</div>
          <DeadlineInRow
            deadline={assignment.deadline}
            strictDeadline={assignment.strictDeadline} />
        </div>
      )
    }
    return null;
  }
}

export class EmployeeCurrentJobsStatusCellRenderer extends PureRenderComponent {
  render() {
    const { value, data, colDef } = this.props;
    const { sentToStatus } = colDef.cellRendererParams;
    const cls = classNames({
      "text-danger": data.status === sentToStatus
    });
    return <span><strong className={cls}>{value}</strong></span>
  }
}

export class EmployeeScheduleStatusCellRenderer extends PureRenderComponent {
  render() {
    const { value, data, colDef } = this.props;
    const { userId } = colDef.cellRendererParams;
    const roleKeys = Object.keys(EMPLOYEE_STATUS_MAP)
    const hasComingAssignments = roleKeys
      .map(role => data[role])
      .filter(assignment => (
        assignment &&
        assignment.assignee &&
        assignment.assignee.id === userId &&
        data.status.startsWith('Sent')
      )).some(assignment => {
        return data.status.includes(assignment.role.name)
      })
    const cls = classNames({
      "text-danger": hasComingAssignments
    });

    return <span><strong className={cls}>{value}</strong></span>
  }
}

export class ScheduleStatusCellRenderer extends PureRenderComponent {
  render() {
    const { value = '', data } = this.props;
    const cls = classNames({
      "text-danger": (
        value === 'Job Created' ||
        (value.includes('Validating') && value !== 'AE Validating')
      ),
      "text-info": value.startsWith('Sent'),
      "text-pink": value === 'AE Validating',
    });
    return <span><strong className={cls}>{value}</strong></span>
  }
}