import React from 'react';
import { Box } from 'reactjs-admin-lte';
import DateFormatter from 'components/core/DateFormatter'
import UserAvatar from './UserAvatar';
import UserAvatarUploadButton from './UserAvatarUploadButton';


const UserInfo = (props) => {

  const {
    isLoading,
    avatar,
    username,
    dateJoined,
    userId
  } = props;

  return (
    <Box
      className="box-widget"
      loading={isLoading}>
      <Box.Body className="box-profile">
        <UserAvatar
          className="profile-user-img img-responsive img-circle"
          url={avatar} />
        <h3 className="profile-username text-center">{username}</h3>
        <p className="text-center text-muted">
          <span className="mr-5">Member since</span>
          <DateFormatter datetime={dateJoined} format="MMM. YYYY" />
        </p>
        <UserAvatarUploadButton userId={userId} />
      </Box.Body>
    </Box>
  )
}

export default UserInfo;
