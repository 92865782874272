import React from 'react';
import { Box } from 'reactjs-admin-lte';
import TooltipIconButton from 'components/core/TooltipIconButton';
import DocumentTable from 'components/documents/DocumentTable';

const EmployeeAssignmentProject = (props) => {

  const {
    title,
    isLoading,
    projects,
    handleDownload,
    handleRemove,
    showUploadModal
  } = props;

  return (
    <Box
      className="box-success box-solid"
      loading={isLoading}>
      <Box.Header border={true}>
        <Box.Title>{title}</Box.Title>
        <Box.Tools>
          <TooltipIconButton
            id="upload-new-project"
            className="btn-box-tool"
            icon="upload"
            description="Upload Completed File"
            onClick={showUploadModal} />
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <DocumentTable
          documents={projects}
          handleDownload={handleDownload}
          removable={true}
          handleRemove={handleRemove} />
      </Box.Body>
    </Box>
  )
}

EmployeeAssignmentProject.defaultProps = {
  title: 'Completed Files'
}

export default EmployeeAssignmentProject;
