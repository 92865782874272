import React from 'react';
import { Button } from 'react-bootstrap';
import { TEMPLATE_EDIT, JOB_CREATE_BY_TEMPLATE } from 'misc/constants';
import { LinkFieldCellRenderer } from 'misc/cellRenderers';
import { getDetailTabConfigByName } from 'misc/utils';
import ListTable from 'components/core/ListTable';
import PureRenderComponent from 'components/core/PureRenderComponent';


const ActionFieldCellRenderer = (props) => {
  const { data, colDef } = props;
  const { tab, handleTabOpen } = colDef.cellRendererParams;
  if (data) {
    const { id, name } = data;
    const tabConfig = getDetailTabConfigByName(tab, id, name, { id: id });
    return (
      <Button
        type="button"
        bsSize="xs"
        bsStyle="default"
        onClick={() => handleTabOpen(tabConfig)}>
        New job
      </Button>
    );
  }
  return null;
}

class TemplateListTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'Name',
          field: 'name',
          pinned: 'left',
          width: 145,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: TEMPLATE_EDIT,
          },
        },
        {
          headerName: 'Client',
          field: 'client.name',
          width: 180,
        },
        {
          headerName: 'AE Subject',
          field: 'aeSubject',
          width: 300,
        },
        {
          headerName: 'Type',
          field: 'type',
          width: 65,
        },
        {
          headerName: 'Service',
          field: 'service.name',
          width: 80,
        },
        {
          headerName: 'Industry',
          field: 'industry.name',
          width: 200,
        },
        {
          headerName: 'Field',
          field: 'field.name',
          width: 140,
        },
        {
          headerName: 'AE',
          field: 'ae.name',
          width: 90,
        },
        {
          headerName: 'Creator',
          field: 'creator.name',
          width: 90,
        },
        {
          headerName: 'Action',
          field: '',
          width: 80,
          pinned: 'right',
          suppressMovable: true,
          suppressSorting: true,
          suppressFilter: true,
          suppressMenu: true,
          cellRendererFramework: ActionFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: JOB_CREATE_BY_TEMPLATE,
          },
        }
      ]
    }
  }

  render() {
    return (
      <ListTable
        rowData={this.props.templates}
        columnDefs={this.state.columnDefs}
        deltaRowDataMode={true}
        getRowNodeId={data => data.id} />
    );
  }
}

export default TemplateListTable;
