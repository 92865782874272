import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Box } from 'reactjs-admin-lte';
import { Table } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import Rating from 'react-rating';
import VirtualizedSelect from 'react-virtualized-select';
import { isUserTranslationSkillLoading } from 'selectors/loading';
import { getTranslationSkillsByUserId } from 'selectors/users';
import {
    translationSkillListRequest,
    translationSkillCreateRequest,
    translationSkillUpdateRequest,
} from 'actions/users'
import UserSkillHOC from './UserSkillHOC';

const selectStyle = { width: 100 };
const speedOptions = [
  { label: '2.5', value: "2.5" },
  { label: '2.4', value: "2.4" },
  { label: '2.3', value: "2.3" },
  { label: '2.2', value: "2.2" },
  { label: '2.1', value: "2.1" },
  { label: '2.0', value: "2.0" },
  { label: '1.9', value: "1.9" },
  { label: '1.8', value: "1.8" },
  { label: '1.7', value: "1.7" },
  { label: '1.6', value: "1.6" },
  { label: '1.5', value: "1.5" },
  { label: '1.4', value: "1.4" },
  { label: '1.3', value: "1.3" },
  { label: '1.2', value: "1.2" },
  { label: '1.1', value: "1.1" },
  { label: '1.0', value: "1.0" },
  { label: '0.9', value: "0.9" },
  { label: '0.8', value: "0.8" },
  { label: '0.7', value: "0.7" },
  { label: '0.6', value: "0.6" },
  { label: '0.5', value: "0.5" },
  { label: '0.4', value: "0.4" },
  { label: '0.3', value: "0.3" },
  { label: '0.2', value: "0.2" },
  { label: '0.1', value: "0.1" },
]


const UserSkill = (props) => {

  const {
    isLoading,
    skills,
    handleChange,
  } = props;

  const renderRow = () => {
    return skills.map(row => {
      return (
        <React.Fragment key={row.type}>
          <tr className="active">
            <td><strong>{row.type}</strong></td>
            <td></td>
            <td></td>
          </tr>
          {row.children.map(child => (
            <tr key={child.serviceId}>
              <td>{child.serviceName}</td>
              <td>
              <VirtualizedSelect
                value={child.speed}
                simpleValue={true}
                options={speedOptions}
                onChange={(value) => handleChange(
                  child.skillId,
                  child.serviceId,
                  child.level,
                  value
                )}
                clearable={false}
                style={selectStyle}
                placeholder="" />
              </td>
              <td>
                <FontAwesome
                  name="ban"
                  className="mr-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleChange(
                    child.skillId,
                    child.serviceId,
                    0
                  )} />
                <Rating
                  emptySymbol="fa fa-star-o"
                  fullSymbol="fa fa-star"
                  initialRating={child.level}
                  onChange={rate => handleChange(
                    child.skillId,
                    child.serviceId,
                    rate
                  )} />
                </td>
            </tr>
          ))}
      </React.Fragment>
    )});
  }

  return (
    <Box
      className="box-widget mb-0"
      loading={isLoading}>
      <Box.Body noPadding={true}>
        <Table>
          <thead>
            <tr>
              <th>Service</th>
              <th width="20%">Speed</th>
              <th width="20%">Level</th>
            </tr>
          </thead>
          <tbody>
            {renderRow()}
          </tbody>
        </Table>
      </Box.Body>
    </Box>
  )
}


const mapStateToProps = (state, props) => {
  const id = props.userId;
  return {
    isLoading: isUserTranslationSkillLoading(state, id),
    skills: getTranslationSkillsByUserId(state, id)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest: translationSkillListRequest,
    createRequest: translationSkillCreateRequest,
    updateRequest: translationSkillUpdateRequest,
  }, disptach)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSkillHOC(UserSkill))
