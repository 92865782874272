import React from "react";
import * as PropTypes from "prop-types";
import MHTApi from "services/MHTApi";
import {TaTableRow} from "components/taUi/taTable/taTableRow";
import {TaTableIndexCell} from "components/taUi/taTable/taTableIndexCell";
import {TaTableCell} from "components/taUi/taTable/taTableCell";
import {TaTable} from "components/taUi/taTable/taTable";
import {TaTableBody} from "components/taUi/taTable/taTableBody";
import {TaText} from "components/taUi/taText/taText";
import DateFormatter from "components/core/DateFormatter";

import style from "./TaMhtEditor.module.css";
import revisionStyles from './TaMhtEditorRevisions.module.css';

export class TaMhtEditorRevisions extends React.PureComponent {

  static propTypes = {
    mhtId: PropTypes.string.isRequired,
    segmentId: PropTypes.string.isRequired
  };

  constructor() {
    super();

    this.state = {
      loadKey: 0,
      items: [],
      isLoading: false
    };

  }

  componentDidMount() {
    const segmentId = this.props.segmentId;
    if (segmentId) {
      this.loadData(segmentId);
    }
  }

  componentDidUpdate(prevProps, prevState) {

    const segmentId = this.props.segmentId;
    if (segmentId !== prevProps.segmentId) {
      this.loadData(segmentId);
    }

  }

  loadData = (segmentId) => {

    if (segmentId) {
      this.setState({
        isLoading: true
      }, () => {
        MHTApi
          .listSegmentRevisions(segmentId)
          .then(response => {
            this.setState({
              loadKey: Date.now(),
              items: response.data,
              isLoading: false
            });
          })
          .catch(error => {
            console.error('TaMhtEditorRevisions - error', error);
          });
      });
    } else {
      this.setState({
        loadKey: Date.now(),
        items: [],
        isLoading: false
      });
    }

  };

  render() {

    const state = this.state;
    const props = this.props;
    const itemCount = state.items.length;

    const cellWidthIndex = 30;

    return (
      <React.Fragment>
        <TaTable size={'full'} scroll={'y'}>
          {
            // props.segmentId && (
            //   <TaTableHeader>
            //     <TaTableRow>
            //       <TaTableCell
            //         width={cellWidthIndex}
            //         color="light"
            //       >
            //         #
            //       </TaTableCell>
            //       <TaTableCell
            //         color="light">Target</TaTableCell>
            //       {/*<TaTableCell*/}
            //       {/*  width={'25%'}*/}
            //       {/*  color="light">User</TaTableCell>*/}
            //       <TaTableCell
            //         width={'25%'}
            //         color="light">Modified</TaTableCell>
            //     </TaTableRow>
            //   </TaTableHeader>
            // )
          }
          <TaTableBody>
            {
              props.segmentId ? (
                props.isLoading ? (
                  <TaTableRow>
                    <TaTableCell color={'light'}>
                      Loading ...
                    </TaTableCell>
                  </TaTableRow>
                ) : (
                  itemCount ? (
                    state.items.map((row, rowIndex) => {
                      return (
                        <TaTableRow key={rowIndex}>
                          <TaTableIndexCell
                            width={cellWidthIndex}
                            rowIndex={rowIndex}
                            rowCount={itemCount}
                          />
                          <TaTableCell>
                            <TaText
                              className={style.rowMeta}
                              color={'light'}
                            ><DateFormatter datetime={row.modified} />, {row.user.username}</TaText>
                            <div dangerouslySetInnerHTML={{__html: row.record}} className={revisionStyles.text}/>
                          </TaTableCell>
                          {/*<TaTableCell width={'25%'}>*/}
                          {/*  <TaText>{row.user.username}</TaText><br/>*/}
                          {/*  <TaText color={'light'}><DateFormatter datetime={row.modified} /></TaText>*/}
                          {/*</TaTableCell>*/}
                        </TaTableRow>
                      );
                    })
                  ) : (
                    <TaTableRow>
                      <TaTableCell color={'light'}>
                        No revisions.
                      </TaTableCell>
                    </TaTableRow>
                  )
                )
              ) : (
                <TaTableRow>
                  <TaTableCell color={'light'}>
                    Please select a segment.
                  </TaTableCell>
                </TaTableRow>
              )
            }
          </TaTableBody>
        </TaTable>
      </React.Fragment>
    );
  }
}
