import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { Content } from 'reactjs-admin-lte';
import { StickyContainer, Sticky } from 'react-sticky';
import JobQuickEdit from 'components/jobs/managers/JobQuickEdit';
import JobScheduleFilter from './JobScheduleFilter';
import JobScheduleTab from './JobScheduleTab';
import JobScheduleTabPane from './JobScheduleTabPane';

const JobSchedule = (props) => {

  const {
    quickEditName,
    currentDate,
    currentTab,
    toEnJobs,
    toJpJobs,
    toOtherJobs,
    futureJobs,
    isDailyLoading,
    isFutureLoading,
    deadline,
    viewAs,
    viewAsOptions,
    handleDateChange,
    handleDeadlineChange,
    handleViewAsChange,
    handlePrev,
    handleNext,
    isCoordinator,
    isEmployeeSelected,
    isFESchedule,
    handleTabSelect,
    assignee,
    assigneeOptions,
    handleAssigneeChange,
    handleRowDrag,
    handleTabOpen,
    handleSelectionChange,
  } = props;

  return (
    <StickyContainer>
      <Content.Body>
        <JobScheduleFilter
          currentDate={currentDate}
          handlePrev={handlePrev}
          handleNext={handleNext}
          handleDateChange={handleDateChange}
          viewAs={viewAs}
          handleViewAsChange={handleViewAsChange}
          deadline={deadline}
          handleDeadlineChange={handleDeadlineChange}
          viewAsOptions={viewAsOptions}
          isEmployeeSelected={isEmployeeSelected}
          assignee={assignee}
          assigneeOptions={assigneeOptions}
          handleAssigneeChange={handleAssigneeChange} />
        <Tab.Container
          id="job-schedule-tab"
          activeKey={currentTab}
          onSelect={handleTabSelect}>
          <div>
            <Nav bsStyle="tabs">
              <JobScheduleTab
                eventKey="toEn"
                title="To En"
                jobs={toEnJobs}
                isLoading={isDailyLoading} />
              <JobScheduleTab
                eventKey="toJp"
                title="To Jp"
                jobs={toJpJobs}
                isLoading={isDailyLoading} />
              <JobScheduleTab
                eventKey="toOther"
                title="To Other"
                jobs={toOtherJobs}
                isLoading={isDailyLoading} />
              <JobScheduleTab
                eventKey="future"
                title="Future"
                jobs={futureJobs}
                isLoading={isFutureLoading} />
            </Nav>
            <Tab.Content animation={false}>
              <JobScheduleTabPane
                eventKey="toEn"
                rowDrag={isFESchedule}
                handleRowDrag={handleRowDrag}
                viewAs={viewAs}
                jobs={toEnJobs}
                isLoading={isDailyLoading}
                handleTabOpen={handleTabOpen}
                handleSelectionChange={handleSelectionChange} />
              <JobScheduleTabPane
                eventKey="toJp"
                rowDrag={isFESchedule}
                handleRowDrag={handleRowDrag}
                viewAs={viewAs}
                jobs={toJpJobs}
                isLoading={isDailyLoading}
                handleTabOpen={handleTabOpen}
                handleSelectionChange={handleSelectionChange} />
              <JobScheduleTabPane
                eventKey="toOther"
                rowDrag={isFESchedule}
                handleRowDrag={handleRowDrag}
                viewAs={viewAs}
                jobs={toOtherJobs}
                isLoading={isDailyLoading}
                handleTabOpen={handleTabOpen}
                handleSelectionChange={handleSelectionChange} />
              <JobScheduleTabPane
                eventKey="future"
                rowDrag={isFESchedule}
                handleRowDrag={handleRowDrag}
                viewAs={viewAs}
                jobs={futureJobs}
                isLoading={isFutureLoading}
                handleTabOpen={handleTabOpen}
                handleSelectionChange={handleSelectionChange} />
            </Tab.Content>
          </div>
        </Tab.Container>
        { isCoordinator &&
          <Sticky topOffset={-100}>
            {({ style }) => (
              <div style={{
                ...style,
                top: 'inherit',
                bottom: 0,
                zIndex: 10
              }}>
                <JobQuickEdit name={quickEditName} />
              </div>
            )}
          </Sticky>
        }
      </Content.Body>
    </StickyContainer>
  )
}

export default JobSchedule;