import React from 'react';
import FontAwesome from 'react-fontawesome';

const UserAboutItem = (props) => {
  
  const {
    icon,
    title,
    children
  } = props;
  
  return (
    <div>
      <strong className="user-about-item-title">
        <FontAwesome
          name={icon}
          className="mr-5" />
        {title}
      </strong>
      {children}
    </div>
  );
}

export default UserAboutItem;
