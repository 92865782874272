import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';
import { initialize } from 'redux-form';
import { MULTIPLE_FILE_UPLOAD_MODAL, FILE_UPDATE_MODAL, FILE_UPDATE_FORM } from 'misc/constants';
import { commonReferenceListRequest } from 'actions/clients';
import {
  clientCommonReferenceUploadRequest,
  clientCommonReferenceUpdateRequest,
  clientCommonReferenceDownloadRequest,
  clientCommonReferenceUseRequest,
  clientCommonReferenceRemoveRequest
} from 'actions/documents';
import { isClientCommonReferenceLoading } from 'selectors/loading';
import { getClientCommonReferencesById } from 'selectors/lists';
import PureRenderComponent from 'components/core/PureRenderComponent';
import ClientCommonReference from './presenter';


class ClientCommonReferenceContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.clientId &&
    this.props.commonReferenceListRequest(this.props.clientId);
  }

  componentDidUpdate(prevProps) {
    if (this.props.clientId && this.props.clientId !== prevProps.clientId) {
      this.props.commonReferenceListRequest(this.props.clientId);
    }
  }
  
  showUploadModal = () => {
    this.props.show(MULTIPLE_FILE_UPLOAD_MODAL, {
      title: 'Client Reference',
      handleUpload: this.handleUpload
    })
  }
  
  showUpdateModal = (value) => {
    this.props.show(FILE_UPDATE_MODAL, {
      title: 'Client Reference',
      filename: value.name,
      handleUpdate: this.handleUpdate
    });
    this.props.initialize(FILE_UPDATE_FORM, value);
  }
  
  handleUpload = (files) => {
    this.props.clientCommonReferenceUploadRequest(
      this.props.clientId,
      files,
      this.props.jobId
    );
  }

  handleDownload = (file) => {
    this.props.clientCommonReferenceDownloadRequest(file);
  }
  
  handleUpdate = (values) => {
    this.props.clientCommonReferenceUpdateRequest(values.id, values);
  }
  
  handleRemove = (file) => {
    this.props.clientCommonReferenceRemoveRequest(this.props.clientId, file)
  }

  handleUse = (value, checked) => {
    this.props.clientCommonReferenceUseRequest(value, {
      job: this.props.jobId,
      use: checked
    });
  }

  render() {
    return (
      <ClientCommonReference
        {...this.props}
        showUploadModal={this.showUploadModal}
        showUpdateModal={this.showUpdateModal}
        handleUpdate={this.handleUpdate}
        handleDownload={this.handleDownload}
        handleUse={this.handleUse}
        handleRemove={this.handleRemove} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const id = props.clientId;
  return {
    isLoading: isClientCommonReferenceLoading(state, id),
    files: getClientCommonReferencesById(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    initialize,
    commonReferenceListRequest,
    clientCommonReferenceUploadRequest,
    clientCommonReferenceUpdateRequest,
    clientCommonReferenceDownloadRequest,
    clientCommonReferenceUseRequest,
    clientCommonReferenceRemoveRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientCommonReferenceContainer)
