function chunkArray(array, chunkSize) {

  let chunked_arr = [];
  let copied = [...array]; // ES6 destructuring
  const numOfChild = Math.ceil(copied.length / chunkSize); // Round up to the nearest integer
  for (let i = 0; i < numOfChild; i++) {
    chunked_arr.push(copied.splice(0, chunkSize));
  }
  return chunked_arr;

}
export default chunkArray;
