import React from "react";
import * as PropTypes from "prop-types";
import MHTApi from "services/MHTApi";
import {TaTableRow} from "components/taUi/taTable/taTableRow";
import {TaTableCell} from "components/taUi/taTable/taTableCell";
import {TaTable} from "components/taUi/taTable/taTable";
import {TaTableBody} from "components/taUi/taTable/taTableBody";
import {TaButton} from "components/taUi/taButton/taButton";
import {TaIcon} from "components/taUi/taIcon";
import {TaText} from "components/taUi/taText/taText";
import memoryStyles from './TaMhtEditorMemories.module.css';

export class TaMhtEditorMemories extends React.PureComponent {

  static propTypes = {
    mhtId: PropTypes.string.isRequired,
    segmentId: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    onEvent: PropTypes.func
  };

  constructor() {
    super();
    this.state = {
      loadKey: 0,
      items: [],
      isLoading: false
    };
  }

  componentDidMount() {
    const segmentId = this.props.segmentId;
    if (segmentId) {
      this.loadData(segmentId);
    }
  }

  componentDidUpdate(prevProps, prevState) {

    const segmentId = this.props.segmentId;
    if (segmentId !== prevProps.segmentId) {
      this.loadData(segmentId);
    }

  }

  loadData = (segmentId) => {

    if (segmentId) {
      this.setState({
        isLoading: true
      }, () => {
        MHTApi
          .listSegmentMemories(segmentId)
          .then(response => {
            this.setState({
              loadKey: Date.now(),
              items: response.data,
              isLoading: false
            });
            // this.setState({
            //   loadKey: Date.now(),
            //   items: [...response.data, {
            //     diff: '<p>This has &lt;brackets&gt;</p>',
            //     source: 'gaga',
            //     target: 'This has <brackets> hello bold text.'
            //   }],
            //   isLoading: false
            // });
          })
          .catch(error => {
            console.error('TaMhtEditorMemories - error', error);
          });
      });
    } else {
      this.setState({
        loadKey: Date.now(),
        items: [],
        isLoading: false
      });
    }

  };

  applyMemory = (row) => {
    this.props.onEvent({
      name: 'apply',
      data: row
    });
  };

  renderRowAttributes = (row) => {

    let jsxArray = [];

    if (row.client && row.client.name) {
      jsxArray.push(<dt key={'client_dt'}>Client</dt>);
      jsxArray.push(<dd key={'client_dd'} children={row.client.name}/>);
    }

    if (row.jobs && row.jobs.length > 0) {
      jsxArray.push(<dt key={'job_dt'}>Jobs</dt>);
      const numbers = row.jobs.map(job => job.number).join(', ');
      jsxArray.push(<dd key={`job_dd`} children={numbers} />);
    }

    if (row.is_tentative && row.job_number) {
      jsxArray.push(<dt key={'job_number_dt'}>Job Number</dt>);
      jsxArray.push(<dd key={'job_number_dd'} children={row.job_number}/>);
    }

    if (row.is_tentative && row.role) {
      jsxArray.push(<dt key={'role_dt'}>Role</dt>);
      jsxArray.push(<dd key={'role_dd'} children={row.role.name}/>);
    }

    // if (row.source) {
    //   jsxArray.push(<dt key={'source_dt'}>Source</dt>);
    //   jsxArray.push(<dd key={'source_dd'} children={row.source}/>);
    // }

    return jsxArray;
  };

  // escapeString = (unsafeText) => {
  //   // const unsafeText = "<p>Beware of <span>the</span> <leopard></p>";
  //
  //   let div = document.createElement('div');
  //   div.appendChild(document.createTextNode(unsafeText));
  //
  //   let safeText = div.innerHTML;
  //
  //   const regex = /&lt;([/]*)(p|span[^&]*?)&gt;/g;
  //   safeText = safeText.replace(regex, '<$1$2>')
  //
  //   // console.log('safeText',safeText)
  //
  //   return safeText;
  // }

  render() {

    const state = this.state;
    const props = this.props;
    const itemCount = state.items.length;


    return (
      <React.Fragment>
        <TaTable size={'full'} scroll={'y'}>
          {/*{*/}
          {/*  (!props.isLoading && itemCount > 0) && (*/}
          {/*    <TaTableColumns>*/}
          {/*      <TaTableColumn*/}
          {/*        style={{width: '48px', flex: '0 0 48px'}}*/}
          {/*      />*/}
          {/*      <TaTableColumns>*/}
          {/*        <TaTableColumn/>*/}
          {/*      </TaTableColumns>*/}
          {/*    </TaTableColumns>*/}
          {/*  )*/}
          {/*}*/}
          <TaTableBody>
            {
              props.segmentId ? (
                props.isLoading ? (
                  <TaTableRow>
                    <TaTableCell color={'light'}>
                      Loading ...
                    </TaTableCell>
                  </TaTableRow>
                ) : (
                  itemCount ? (
                    state.items.map((row, rowIndex) => {
                      const ratioText = '' + Math.floor(row.ratio) + '%';
                      const ratioColor = (row.ratio > 66) ? 'confirm' : (row.ratio > 33) ? 'warn' : 'alert';
                      const attributes = this.renderRowAttributes(row);
                      return (
                        <TaTableRow key={rowIndex}>
                          <TaTableCell
                            // flex={'none'}
                            color={'light'}
                            className={memoryStyles.cellIcon}
                          >
                            <TaText color={ratioColor}>{ratioText}</TaText><br/>
                            {(row.is_penalty) && (
                              <TaIcon name={'warning'}
                                      color={'alert'}
                                      size={'small'}/>
                            )}
                            {(row.is_tentative) && (
                              <TaIcon name={'emoji_objects'}
                                      color={'light'}
                                      size={'small'}/>
                            )}
                            {(row.is_pending) && (
                              <TaIcon name={'watch_later'}
                                      color={'light'}
                                      size={'small'}/>
                            )}
                          </TaTableCell>
                          <TaTableCell
                            className={memoryStyles.cellMain}
                          >
                            <div
                              className={memoryStyles.diff}
                              dangerouslySetInnerHTML={{__html: row.diff}}
                              // hildren={this.escapeString(row.diff)}
                            />
                            <TaText
                              color={'light'}
                              // dangerouslySetInnerHTML={{__html: row.target}}
                              children={row.target}
                            />
                            {
                              (!!attributes.length) && (
                                <details className={memoryStyles.details}>
                                  <summary><span>Attributes</span></summary>
                                  <dl children={attributes}/>
                                </details>
                              )
                            }
                          </TaTableCell>
                          <TaTableCell
                            color={'light'}
                            className={memoryStyles.cellButton}
                          >
                            <TaButton
                              icon={'save_alt'}
                              tooltip={'Apply'}
                              onClick={() => this.applyMemory(row)}
                            />
                          </TaTableCell>
                        </TaTableRow>
                      );
                    })
                  ) : (
                    <TaTableRow>
                      <TaTableCell color={'light'}>
                        No matching memory found.
                      </TaTableCell>
                    </TaTableRow>
                  )
                )
              ) : (
                <TaTableRow>
                  <TaTableCell color={'light'}>
                    Please select a segment.
                  </TaTableCell>
                </TaTableRow>
              )
            }
          </TaTableBody>
        </TaTable>
      </React.Fragment>
    );
  }
}
