import { takeLatest, call, put, fork } from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import { hide } from 'redux-modal';
import API from 'services/TemplateApi';
import { templateSchema } from 'schemas/templates';
import * as constants from 'misc/constants';
import * as config from 'misc/config';
import * as coreSagas from 'sagas/core';
import { closeCurrentTab } from 'sagas/globalTabs';
import * as actionTypes from 'actionTypes/templates';
import * as actions from 'actions/templates';
import * as entityActions from 'actions/entities';
import * as listActions from 'actions/lists';
import { setLoading } from 'actions/loading';



// Create ======================================================================
function* create(action) {
  yield put(setLoading(constants.TEMPLATE_CREATE_MODAL, true));
  const { values } = action.payload;
  try {
    const response = yield call(API.create, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), templateSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addItem(constants.TEMPLATE_LIST, normalized.result));
    yield put(hide(constants.TEMPLATE_CREATE_MODAL));
    yield fork(coreSagas.successNotification, config.TEMPLATE_CREATE_SUCCESS_MESSAGE);
    yield put(setLoading(constants.TEMPLATE_CREATE_MODAL, false));
  } catch (error) {
    yield put(actions.createFailure(error));
    yield put(setLoading(constants.TEMPLATE_CREATE_MODAL, false));
  }
}

// List ========================================================================
function* list(action) {
  yield put(setLoading(constants.TEMPLATE_LIST, true));
  try {
    const response = yield call(API.list);
    const normalized = normalize(camelizeKeys(response.data), [templateSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setResult(constants.TEMPLATE_LIST, normalized.result));
    yield put(setLoading(constants.TEMPLATE_LIST, false));
  } catch (error) {
    yield put(actions.listFailure(error));
    yield put(setLoading(constants.TEMPLATE_LIST, false));
  }
}

// Retrieve ====================================================================
function* retrieve(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.TEMPLATE_DETAIL}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.retrieve, id);
    const normalized = normalize(camelizeKeys(response.data), templateSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.retrieveFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Update ======================================================================
function* update(action) {
  const { id, values } = action.payload;
  const loadingKey = `${constants.TEMPLATE_EDIT}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.update, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), templateSchema);
    yield put(entityActions.merge(normalized.entities));
    yield fork(closeCurrentTab);
    yield fork(coreSagas.successNotification, config.TEMPLATE_UPDATE_SUCCESS_MESSAGE);
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.updateFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Remove ======================================================================
function* remove(action) {
  const { item } = action.payload;
  const confirmed = yield call(coreSagas.confirm, `Are you sure to delete ${item.name} ?`);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      yield call(API.remove, item.id);
      yield put(listActions.removeItem(constants.TEMPLATE_LIST, item.id));
      yield put(entityActions.remove('templates', item.id));
      yield fork(closeCurrentTab);
      yield fork(coreSagas.successNotification, config.TEMPLATE_REMOVE_SUCCESS_MESSAGE);
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.removeFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

// Watch =======================================================================
function* watchCreate() {
  yield takeLatest(actionTypes.CREATE.REQUEST, create);
}
function* watchList() {
  yield takeLatest(actionTypes.LIST.REQUEST, list);
}
function* watchRetrieve() {
  yield takeLatest(actionTypes.RETRIEVE.REQUEST, retrieve);
}
function* watchUpdate() {
  yield takeLatest(actionTypes.UPDATE.REQUEST, update);
}
function* watchRemove() {
  yield takeLatest(actionTypes.REMOVE.REQUEST, remove);
}

export {
  watchCreate,
  watchList,
  watchRetrieve,
  watchUpdate,
  watchRemove
}