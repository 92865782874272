import * as types from 'actionTypes/tabs';

export const selectTab = (name, tab) => ({
  type: types.SELECT_TAB,
  payload: { name, tab }
});

export const selectNestedTab = (name, id, tab) => ({
  type: types.SELECT_NESTED_TAB,
  payload: { name, id, tab }
});