import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'misc/config';
import { getDetailTabConfigByName } from 'misc/utils';
import { CALENDAR, CALENDAR_FORM, JOB_DETAIL } from 'misc/constants';
import { setTabTitle } from 'actions/globalTabs';
import { calendarRequest } from 'actions/calendars';
import { setFilter } from 'actions/filters';
import { selectNestedTab } from 'actions/tabs';
import { reset, getFormValues } from 'redux-form';
import { isCalendarLoading } from 'selectors/loading';
import { getCalendarEvents } from 'selectors/calendars';
import { getAssigneeGroupOptions } from 'selectors/options';
import { getCalendarVisibleRange, getCalendarGroup } from 'selectors/filters';
import PureRenderComponent from 'components/core/PureRenderComponent';
import Calendar from './presenter';


class CalendarContainer extends PureRenderComponent {

  componentDidMount() {
    const { start, end } = this.props.visibleRange;
    const params = { start, end, group: this.props.group }
    this.changeTabTitle(start, end)
    this.props.calendarRequest(params);
  }

  handlePrev = () => {
    const { start, end } = this.props.visibleRange;
    const _start = moment(start).subtract(7, 'days').format(DEFAULT_DATE_FORMAT)
    const _end = moment(end).subtract(7, 'days').format(DEFAULT_DATE_FORMAT)
    this.handleWeekChange(_start, _end);
  }

  handleNext = () => {
    const { start, end } = this.props.visibleRange;
    const _start = moment(start).add(7, 'days').format(DEFAULT_DATE_FORMAT);
    const _end = moment(end).add(7, 'days').format(DEFAULT_DATE_FORMAT);
    this.handleWeekChange(_start, _end);
  }

  handleWeekChange = (start, end) => {
    this.props.setFilter(CALENDAR, 'visibleRange', { start, end });
    const params = { start, end, group: this.props.group }
    this.changeTabTitle(start, end)
    this.props.calendarRequest(params);
  }

  handleGroupChange = (group) => {
    this.props.setFilter(CALENDAR, 'group', group);
    const { start, end } = this.props.visibleRange;
    const params = { start, end, group };
    this.props.calendarRequest(params);
  }

  changeTabTitle = (start, end) => {
    const _start = moment(start).format('MM-DD')
    const _end = moment(end).subtract(1, 'days').format('MM-DD')
    this.props.setTabTitle(CALENDAR, `${CALENDAR}: ${_start} - ${_end}`)
  }

  handleEventClick = (event) => {
    switch (event.type) {
      case 'assignment':
        this.handleJobOpen(event)
        break;
      default:
        return;
    }
  }

  handleJobOpen = (event) => {
    const tabConfig = getDetailTabConfigByName(
      JOB_DETAIL,
      event.jobId,
      event.jobNumber,
      { id: event.jobId }
    );
    this.props.handleTabOpen(tabConfig);
  }

  render() {
    return (
      <Calendar
        {...this.props}
        handlePrev={this.handlePrev}
        handleNext={this.handleNext}
        handleEventClick={this.handleEventClick}
        handleGroupChange={this.handleGroupChange} />
    );
  }
}

const mapStateToProps = (state) => {
  const form = CALENDAR_FORM;
  const formValues = getFormValues(form)(state);
  return {
    form,
    formValues,
    isLoading: isCalendarLoading(state),
    visibleRange: getCalendarVisibleRange(state),
    events: getCalendarEvents(state),
    group: getCalendarGroup(state),
    groupOptions: getAssigneeGroupOptions(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    reset,
    calendarRequest,
    setFilter,
    selectNestedTab,
    setTabTitle
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarContainer);