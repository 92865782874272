import React from 'react';
import { Field } from 'redux-form';
import * as validators from 'misc/validators';
import { Box } from 'reactjs-admin-lte';
import EditorField from 'components/core/EditorField';


const JobNotesField = (props) => {
  
  const {
    isRequired,
    isLoading,
    title,
    name,
    collapsed
  } = props;
  
  const getValidator = () => {
    return isRequired
      ? [validators.required]
      : []
  }

  return (
    <Box
      className="box-widget"
      loading={isLoading}
      collapsed={collapsed}>
      <Box.Header border={true}>
        <Box.Title>{title}</Box.Title>
        <Box.Tools>
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <Field
          name={name}
          validate={getValidator()}
          component={EditorField} />
      </Box.Body>
    </Box>
  )
}

JobNotesField.defaultProps = {
  collapsed: false
}

export default JobNotesField;