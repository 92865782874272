import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { EMPLOYEE_STATUS_MAP } from 'misc/config';
import { editorCurrentJobsRequest } from 'actions/jobs/employees';
import { getEditorCurrentJobs } from 'selectors/lists';
import { isEditorCurrentJobsLoading } from 'selectors/loading';
import EmployeeCurrentJobsHOC from './EmployeeCurrentJobsHOC';
import EmployeeCurrentJobs from './presenter'


const mapStateToProps = (state) => {
  const roleKey = 'editor';
  return {
    roleKey,
    sentToStatus: EMPLOYEE_STATUS_MAP[roleKey].sent,
    jobs: getEditorCurrentJobs(state),
    isLoading: isEditorCurrentJobsLoading(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest: editorCurrentJobsRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeCurrentJobsHOC(EmployeeCurrentJobs));
