import { takeLatest, call, put } from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { normalize } from 'normalizr';

import API from 'services/AccountingApi';
import * as constants from 'misc/constants';
import * as schemas from 'schemas/accounting';
import * as actions from 'actions/accounting';
import * as listActions from 'actions/lists';
import * as entityActions from 'actions/entities';
import * as actionTypes from 'actionTypes/accounting';
import { setLoading } from 'actions/loading';


function* salesTargetList(action) {
  const { currentYear } = action.payload;
  yield put(setLoading(constants.SALES_TARGET, true));
  try {
    const response = yield call(API.salesTargetList, decamelizeKeys({ currentYear }));
    const normalized = normalize(camelizeKeys(response.data), [schemas.salesTargetSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setYearlyResult(constants.SALES_TARGET, currentYear, normalized.result));
    yield put(setLoading(constants.SALES_TARGET, false));
  } catch (error) {
    yield put(actions.salesTargetListFailure(error));
    yield put(setLoading(constants.SALES_TARGET, false));
  }
}

function* salesTargetCreate(action) {
  const { currentYear, values } = action.payload;
  try {
    const response = yield call(API.salesTargetCreate, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.salesTargetSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addYearlyItem(constants.SALES_TARGET, currentYear, normalized.result));
  } catch (error) {
    yield put(actions.salesTargetCreateFailure(error));
  }
}

function* salesTargetPatch(action) {
  const { id, values } = action.payload;
  try {
    const response = yield call(API.salesTargetPatch, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.salesTargetSchema);
    yield put(entityActions.merge(normalized.entities));
  } catch (error) {
    yield put(actions.salesTargetPatchFailure(error));
  }
}

// Sales Report ================================================================
function* salesReport(action) {
  yield put(setLoading(constants.SALES_REPORT, true));
  const { currentMonth } = action.payload;
  try {
    const response = yield call(API.salesReport, decamelizeKeys({ currentMonth }));
    yield put(listActions.setReportResult(
      constants.SALES_REPORT,
      currentMonth,
      response.data.quotation_results,
      response.data.quotation_total,
      response.data.count_results,
      response.data.count_total,
      response.data.target,
      response.data.progress
    ));
    yield put(setLoading(constants.SALES_REPORT, false));
  } catch (error) {
    yield put(actions.salesReportFailure(error));
    yield put(setLoading(constants.SALES_REPORT, false));
  }
}

function* watchSalesTargetList() {
  yield takeLatest(actionTypes.SALES_TARGET_LIST.REQUEST, salesTargetList);
}
function* watchSalesTargetCreate() {
  yield takeLatest(actionTypes.SALES_TARGET_CREATE.REQUEST, salesTargetCreate)
}
function* watchSalesTargetPatch() {
  yield takeLatest(actionTypes.SALES_TARGET_PATCH.REQUEST, salesTargetPatch)
}
function* watchSalesReport() {
  yield takeLatest(actionTypes.SALES_REPORT.REQUEST, salesReport);
}

export {
  watchSalesTargetList,
  watchSalesTargetCreate,
  watchSalesTargetPatch,
  watchSalesReport
}