import React from 'react';
import FontAwesome from 'react-fontawesome';


const Spinner = (props) => {
  
  const { isLoading } = props;
  
  return isLoading
    ? (
      <div className="overlay-wrapper">
        <div className="overlay">
          <FontAwesome name="refresh" spin />
        </div>
      </div>
    ) : null;
}

export default Spinner