import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Navbar,Nav, NavItem } from 'react-bootstrap';
import FilterBar from 'components/core/FilterBar';
import DatePicker from 'components/core/DatePicker';


const EmployeeJobScheduleFilter = (props) => {

  const {
    currentDate,
    handlePrev,
    handleNext,
    handleDateChange
  } = props;

  return (
    <FilterBar>
      <Nav>
        <NavItem onClick={handlePrev}>
          <FontAwesome name="chevron-left" className="mr-10" />Prev Day
        </NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <DatePicker
          currentDate={currentDate}
          handleDateChange={handleDateChange} />
      </Navbar.Form>
      <Nav>
        <NavItem onClick={handleNext}>
          Next Day<FontAwesome name="chevron-right" className="ml-10" />
        </NavItem>
      </Nav>
    </FilterBar>
  );
}

export default EmployeeJobScheduleFilter;
