import React from 'react';
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import { CLIENT_USER_PREFERENCE_MODAL } from 'misc/constants';
import { getAvailableUserPreferenceOptions } from 'selectors/options';
import PureRenderComponent from 'components/core/PureRenderComponent';

const ClientUserPreferenceHOC = (WrappedComponent) => {

  return class extends PureRenderComponent {

    componentDidMount() {
      this.props.listRequest(this.props.clientId);
    }

    handleAdd = (values) => {
      const formData = { ...values, client: this.props.clientId };
      this.props.addRequest(this.props.clientId, formData);
    }

    handleRemove = (preference) => {
      this.props.removeRequest(preference);
    }

    showAddModal = () => {
      this.props.show(CLIENT_USER_PREFERENCE_MODAL, {
        title: this.props.title,
        options: this.props.userPreferenceOptions,
        handleAddUser: this.handleAdd
      })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          showAddModal={this.showAddModal}
          handleRemove={this.handleRemove} />
      );
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    userPreferenceOptions: getAvailableUserPreferenceOptions(state, props.clientId)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
  }, disptach)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  ClientUserPreferenceHOC
);