import * as types from 'actionTypes/layout';

const initialState = {
  skin: 'blue',
}


export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_SKIN:
      return setSkin(state, action);
    default:
      return state;
  }
}

const setSkin = (state, action) => {
  const { skin } = action.payload;
  return {
    ...state,
    skin
  }
}