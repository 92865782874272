import React from "react";
import * as PropTypes from "prop-types";
import {TaTable} from "components/taUi/taTable/taTable";
import {TaTableHeader} from "components/taUi/taTable/taTableHead";
import {TaTableRow} from "components/taUi/taTable/taTableRow";
import {TaTableCell} from "components/taUi/taTable/taTableCell";
import {TaTableCellGroup} from "components/taUi/taTable/taTableCellGroup";
import {TaTableBody} from "components/taUi/taTable/taTableBody";
import TaLoader from "components/taUi/taLoader";
import {TaTableFooter} from "components/taUi/taTable/taTableFooter";
import editorStyles from "components/mht/MHTEditor/TaMhtEditor.module.css";
import {TaTableColumns} from "components/taUi/taTable/taTableColumns";
import {TaTableColumn} from "components/taUi/taTable/taTableColumn";
import {TaTableRows} from "components/taUi/taTable/taTableRows";
import {TaMhtEditorSegmentsGroup} from "components/mht/MHTEditor/TaMhtEditorSegmentsGroup";
import MHTApi from "services/MHTApi";

export class TaMhtEditorSegments extends React.Component {

  static propTypes = {
    userRole: PropTypes.string.isRequired,
    sourceLanguage: PropTypes.string.isRequired,
    targetLanguage: PropTypes.string.isRequired,
    items: PropTypes.object.isRequired, // todo: shape
    itemsChunkSize: PropTypes.number.isRequired,
    filteredItems: PropTypes.object.isRequired, // todo: shape
    mutations: PropTypes.object.isRequired,
    comments: PropTypes.object.isRequired,

    isLoading: PropTypes.bool.isRequired,
    isEditSourceEnabled: PropTypes.bool.isRequired,

    timeLoaded: PropTypes.number.isRequired,
    timeMutated: PropTypes.number.isRequired,
    timeSearched: PropTypes.number.isRequired,
    timeFiltered: PropTypes.number.isRequired,
    timeCommented: PropTypes.number.isRequired,

    selectedSegment: PropTypes.object.isRequired,

    pickSegmentProperty: PropTypes.func.isRequired,

    onRowSelect: PropTypes.func.isRequired,
    onSourceFocus: PropTypes.func.isRequired,
    onSourceBlur: PropTypes.func.isRequired,
    onSourceChange: PropTypes.func.isRequired,
    onTargetFocus: PropTypes.func.isRequired,
    onTargetChange: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.selectedSegment.time !== nextProps.selectedSegment.time ||
      this.props.isLoading !== nextProps.isLoading ||
      this.props.timeLoaded !== nextProps.timeLoaded ||
      this.props.timeMutated !== nextProps.timeMutated ||
      this.props.timeSearched !== nextProps.timeSearched ||
      this.props.timeFiltered !== nextProps.timeFiltered ||
      this.props.timeCommented !== nextProps.timeCommented ||
      this.props.isEditSourceEnabled !== nextProps.isEditSourceEnabled ||
      this.props.highlight.time !== nextProps.highlight.time
      // ||
      // this.state.scrollTop !== nextState.scrollTop
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      // this.state.scrollTop &&
      (
        this.props.timeSearched !== prevProps.timeSearched ||
        this.props.timeFiltered !== prevProps.timeFiltered
      )
    ) {
      this.wrapperRef.current.scrollTo(0, 0);
    }
  }

  getBackgroundColor = (userRole, isLocked, isTotra, isConfirmed) => {
    const {
      ae,
      coordinator,
      totra,
      translator,
      checker,
      editor,
      finalEditor,
      secondFinalEditor,
      dtp,
      secondDtp
    } = MHTApi.userRoles;

    const grey = '#dde4e6';
    const green = '#e8f5e9';
    const normal = 'white';

    let color;

    switch (userRole) {
      case ae:
      case coordinator:
      case totra:
      case checker:
      case dtp:
      case secondDtp:
        color = (isLocked) ? grey : (isTotra) ? green : normal;
        break;
      case translator:
        color = (isLocked || isTotra) ? grey : normal;
        break;
      case editor:
      case finalEditor:
      case secondFinalEditor:
        color = (isLocked) ? grey : (isTotra) ? green : normal;
        break;
      default:
        color = normal;
    }

    return color;
  };

  chunk = (array, size) => {
    const chunked_arr = [];
    let copied = [...array]; // ES6 destructuring
    const numOfChild = Math.ceil(copied.length / size); // Round up to the nearest integer
    for (let i = 0; i < numOfChild; i++) {
      chunked_arr.push(copied.splice(0, size));
    }
    return chunked_arr;
  };

  render() {

    const {
      sourceLanguage,
      targetLanguage,
      isLoading,
      items,
      itemsChunkSize,
      filteredItems,
      ...props
    } = this.props;

    const cellWidthIndex = 48;
    const cellWidthStatus = 120;
    const cellGroupWidth = 'calc(100% - ' + (cellWidthIndex + cellWidthStatus) + 'px)';

    const totalRowCount = items.count;
    const groups = (props.timeFiltered + props.timeSearched) ? filteredItems.chunks : items.chunks;
    const rowCount = (props.timeFiltered + props.timeSearched) ? filteredItems.count : items.count;

    return (
      <React.Fragment>
        <TaTable
          className={editorStyles.listTable}
        >
          <TaTableHeader>
            <TaTableRow>
              <TaTableCell
                width={cellWidthIndex}
                color={'light'
                }>#</TaTableCell>
              <TaTableCellGroup
                style={{width: cellGroupWidth}}
              >
                <TaTableCell>
                  Source: <span>{sourceLanguage}</span>
                </TaTableCell>
                <TaTableCell>
                  Target: <span>{targetLanguage}</span>
                </TaTableCell>
              </TaTableCellGroup>
              <TaTableCell
                width={cellWidthStatus}
              >Status
              </TaTableCell>
            </TaTableRow>
            <TaTableColumns
              className={editorStyles.columns}
            >
              <TaTableColumn
                style={{width: cellWidthIndex + 'px', flex: '0 0 ' + cellWidthIndex + 'px'}}
              />
              <TaTableColumns
                style={{width: cellGroupWidth}}
              >
                <TaTableColumn/>
                <TaTableColumn/>
              </TaTableColumns>
              <TaTableColumn
                style={{width: cellWidthStatus + 'px', flex: '0 0 ' + cellWidthStatus + 'px'}}
              />
            </TaTableColumns>
          </TaTableHeader>
          <TaTableBody>
            <TaLoader isLoading={isLoading}/>
            <TaTableRows
              scroll={'y'}
              // onScroll={(event) => this.onscroll(event)}
              ref={this.wrapperRef}
              style={{backgroundColor: '#f5f6f7'}}
            >
              {
                groups.map((rows, groupIndex) => <TaMhtEditorSegmentsGroup
                  key={rows.join()}
                  index={groupIndex}
                  chunkSize={itemsChunkSize}
                  // scrollTop={this.state.scrollTop}
                  // parentHeight={this.state.clientHeight}
                  getBackgroundColor={this.getBackgroundColor}
                  items={rows}
                  data={items.data}
                  cellWidthIndex={cellWidthIndex}
                  cellWidthStatus={cellWidthStatus}
                  cellGroupWidth={cellGroupWidth}
                  scrollContainerRef={this.wrapperRef.current}
                  {...props}
                />)
              }
              <TaTableColumns
                className={editorStyles.columns}
              >
                <TaTableColumn
                  style={{width: cellWidthIndex + 'px', flex: '0 0 ' + cellWidthIndex + 'px'}}
                />
                <TaTableColumns
                  style={{width: cellGroupWidth}}
                >
                  <TaTableColumn/>
                  <TaTableColumn/>
                </TaTableColumns>
                <TaTableColumn
                  style={{width: cellWidthStatus + 'px', flex: '0 0 ' + cellWidthStatus + 'px'}}
                />
              </TaTableColumns>
            </TaTableRows>

          </TaTableBody>
          <TaTableFooter>
            <TaTableRow>
              <TaTableCell>{
                (totalRowCount > rowCount) ?
                  (
                    <span><b>Filtered: </b>{rowCount} of {totalRowCount} segments</span>
                  ) : (
                    <span>{totalRowCount} segments</span>
                  )
              }</TaTableCell>
            </TaTableRow>
          </TaTableFooter>
        </TaTable>

      </React.Fragment>
    );
  }
}
