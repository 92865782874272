import axios from 'axios';
import qs from 'qs';

const MemoryApi = {
  search: (params) => {
    return axios.get(`memories/search/`, {
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, {arrayFormat: 'repeat'});
      }
    });
  },
  searchList: (params) => {
    return axios.get(`memories/conditions/`, {params});
  },
  searchCreate: (values) => {
    return axios.post(`memories/conditions/`, values);
  },
  searchRetrieve: (id) => {
    return axios.get(`memories/conditions/${id}/`);
  },
  searchUpdate: (id, values) => {
    return axios.put(`memories/conditions/${id}/`, values);
  },
  searchItemList: (id, page, term) => {

    let path = `memories/conditions/${id}/items/`;

    path += '?';
    path += 'page=';
    path += (page) ? page : 1;

    if (term) {
      path += '&';
      path += 'term=';
      path +=  encodeURIComponent(term);
    }

    return axios.get(path);
  },
  importItems: (id, values) => {
    const formData = new FormData();
    formData.append('file', values.file);
    if (values.jobs) {
      formData.append('jobs', values.jobs);
    }
    return axios.post(`memories/conditions/${id}/import/`, formData);
  },
  exportTmx: (id, onDownloadProgress) => {
    return axios({
      url: `memories/conditions/${id}/export/tmx/`,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress
    });
  },
  exportExcel: (id, onDownloadProgress) => {
    return axios({
      url: `memories/conditions/${id}/export/excel/`,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress
    });
  },
  searchClear: (id, jobs) => {
    return axios.post(`memories/conditions/${id}/clear/`,{
      jobs: jobs || []
    });
  },
  searchDelete: (values) => {
    return axios.post(`memories/conditions/batch-delete/`, values);
  },
  updateAllAttributes: (id, values) => {

    /*
    values = {
      categories: [id, id],
      tags: [id, id]
    };
    */

    return axios.post(`memories/conditions/${id}/attributes/`, values);
  },
  upload: (values) => {
    const formData = new FormData();
    formData.append('file', values.file);
    formData.append('service', values.service);
    formData.append('client', values.client);
    if (values.jobs) {
      values.jobs.forEach(j => {
        formData.append('jobs', j);
      });
    }
    if (values.registered) {
      formData.append('registered', values.registered);
    }
    formData.append('is_penalty', values.isPenalty || false);
    formData.append('add_list', values.addList || false);
    formData.append('list_name', values.listName);
    return axios.post(`memories/upload/`, formData);
  },

  deleteItems: (serviceId, items) => {
    return axios.post(`memories/batch-delete/`, {
      service: serviceId,
      items: items,
    });
  },

  saveItem: (serviceId, item, forceMerge) => {
    return this.saveItems(serviceId,[item], forceMerge);
  },
  saveItems: (serviceId, items, forceMerge) => {
    return axios.post(`memories/batch-save/`, {
      service: serviceId,
      items: items,
      force_merge: !!forceMerge
    });
  },
};

export default MemoryApi;
