import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT, EMPLOYEE_SCHEDULE_REFRESH_INTERVAL } from 'misc/config';
import { selectTab } from 'actions/tabs';
import { setFilter } from 'actions/filters';
import { getMyUserId } from 'selectors/auth';
import { isEmployeeJobDailyScheduleLoading, isEmployeeJobFutureScheduleLoading } from 'selectors/loading';
import { getEmployeeJobScheduleCurrentTab } from 'selectors/tabs';
import { getEmployeeJobScheduleCurrentDate } from 'selectors/filters';
import { getEmployeeJobDailySchedule, getEmployeeJobFutureSchedule } from 'selectors/lists';
import { employeeJobDailyScheduleRequest, employeeJobFutureScheduleRequest } from 'actions/jobs/employees';
import { EMPLOYEE_JOB_SCHEDULE } from 'misc/constants';
import PureRenderComponent from 'components/core/PureRenderComponent';
import EmployeeJobSchedule from './presenter';


class EmployeeJobScheduleContainer extends PureRenderComponent {

  componentDidMount() {
    this.polling();
    this.interval = setInterval(this.polling, EMPLOYEE_SCHEDULE_REFRESH_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  polling = () => {
    const { currentDate } = this.props;
    this.fetchSchedule(currentDate);
  }

  fetchSchedule = (formattedDate) => {
    this.props.employeeJobDailyScheduleRequest(formattedDate);
    this.props.employeeJobFutureScheduleRequest(formattedDate);
  }

  handlePrev = () => {
    const { currentDate } = this.props;
    const prev = moment(currentDate, DEFAULT_DATE_FORMAT).subtract(1, 'days');
    this.handleDateChange(prev)
  }
  
  handleNext = () => {
    const { currentDate } = this.props;
    const next = moment(currentDate, DEFAULT_DATE_FORMAT).add(1, 'days');
    this.handleDateChange(next)
  }

  handleDateChange = (date) => {
    const currentDate = moment(date).format(DEFAULT_DATE_FORMAT);
    this.props.setFilter(EMPLOYEE_JOB_SCHEDULE, 'currentDate', currentDate);
    this.fetchSchedule(currentDate);
  }

  handleTabSelect = (tab) => {
    this.props.selectTab(EMPLOYEE_JOB_SCHEDULE, tab);
  }

  render() {
    return (
      <EmployeeJobSchedule
        {...this.props}
        handlePrev={this.handlePrev}
        handleNext={this.handleNext}
        handleDateChange={this.handleDateChange}
        handleTabSelect={this.handleTabSelect} />
    );
  }
}


const mapStateToProps = (state) => {
  return {
    myUserId: getMyUserId(state),
    isDailyJobLoading: isEmployeeJobDailyScheduleLoading(state),
    isFutureJobLoading: isEmployeeJobFutureScheduleLoading(state),
    dailyJobs: getEmployeeJobDailySchedule(state),
    futureJobs: getEmployeeJobFutureSchedule(state),
    currentTab: getEmployeeJobScheduleCurrentTab(state),
    currentDate: getEmployeeJobScheduleCurrentDate(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    employeeJobDailyScheduleRequest,
    employeeJobFutureScheduleRequest,
    selectTab,
    setFilter
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeJobScheduleContainer)