import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import { JOB_TYPE_OPTIONS } from 'misc/config';
import FontAwesome from 'react-fontawesome';
import * as validators from 'misc/validators';
import Callout from 'components/core/Callout';
import CharField from 'components/core/CharField';
import SelectField from 'components/core/SelectField';
import JobBaseNumberInputField from 'components/jobs/managers/JobBaseNumberInputField';
import JobBasicInfoFields from 'components/jobs/managers/JobBasicInfoFields';
import JobNotesField from 'components/jobs/managers/JobNotesField';
import JobAdditionalCountRolesField from 'components/jobs/managers/JobAdditionalCountRolesField';
import { AESelectOptionField, CoordinatorSelectOptionField } from 'components/core/SelectOptionField';
import JobSourcefile from 'components/jobs/common/JobSourcefile';
import JobReference from 'components/jobs/common/JobReference';
import JobClientCommonReference from 'components/jobs/managers/JobClientCommonReference';


const JobIssueForm = (props) => {

  const {
    id,
    form,
    isLoading,
    invalid,
    client,
    isSplit,
    isMHJob,
    baseNumber,
    type,
    submitFailed,
    handleSubmit,
    handleIssue,
    handleRemove,
  } = props;

  const typeField = (
    <Field
      name="type"
      label="Type"
      options={JOB_TYPE_OPTIONS}
      validate={[validators.required]}
      disabled={!!baseNumber}
      isRequired={true}
      component={SelectField.Labeled} />
  )

  const baseNumberField = (
    <Field
      name="baseNumber"
      label="Base number"
      validate={[
        validators.required,
        validators.numeric,
        validators.minLength7,
        validators.maxLength8
      ]}
      isRequired={true}
      disabled={isSplit || type === 'TT' || type === 'MH'}
      type="text"
      bsSize="sm"
      formName={form}
      component={JobBaseNumberInputField.Labeled} />
  )

  const branchField = (
    <Field
      name="branch"
      label="Split No."
      validate={[
        validators.required,
        validators.alphanumeric,
        validators.maxLength246
      ]}
      isRequired={true}
      type="text"
      component={CharField.Labeled} />
  )

  const numberField = isSplit
    ? (
      <Row>
        <Col lg={4}>{typeField}</Col>
        <Col lg={8}>{baseNumberField}</Col>
        <Col lg={12}>{branchField}</Col>
      </Row>
    )
    : (
      <Row>
        <Col lg={4}>{typeField}</Col>
        <Col lg={8}>{baseNumberField}</Col>
      </Row>
    )

  const subjectField = (
    <Row>
      <Col lg={12}>
        <Field
          name="aeSubject"
          label="AE Subject"
          type="text"
          validate={[validators.maxLength150]}
          component={CharField.Labeled} />
      </Col>
      <Col lg={12}>
        <Field
          name="subject"
          label="Subject"
          type="text"
          isRequired={true}
          validate={[
            validators.required,
            validators.maxLength150
          ]}
          component={CharField.Labeled} />
      </Col>
    </Row>
  )

  const memoField = (
    <Row>
      <Col lg={12}>
        <Field
          name="memo"
          label="Memo"
          type="text"
          validate={[validators.maxLength150]}
          component={CharField.Labeled} />
      </Col>
    </Row>
  )

  const userField = (
    <Row>
      <Col lg={6}>
        <AESelectOptionField
          isRequired={true}
          validate={[validators.required]} />
      </Col>
      <Col lg={6}>
        <CoordinatorSelectOptionField
          isRequired={true}
          validate={[validators.required]} />
      </Col>
    </Row>
  )

  const additionalCountRolesField = (
    <JobAdditionalCountRolesField formName={form} />
  )

  return (
    <Form
      noValidate
      autoComplete="off">
      <Row>
        <Col lg={6}>
          <JobBasicInfoFields
            form={form}
            isLoading={isLoading}
            numberField={numberField}
            subjectField={subjectField}
            memoField={memoField}
            additionalCountRolesField={additionalCountRolesField}
            userField={userField} />
        </Col>
        <Col lg={6}>
          <JobNotesField
            name="instruction"
            title="Instruction"
            isLoading={isLoading}
            isRequired={!isMHJob} />
          <JobNotesField
            name="notes"
            title="Job notes"
            isLoading={isLoading} />
          <JobSourcefile
            jobId={id}
            uploadable={true}
            removable={true} />
          <JobReference
            jobId={id}
            uploadable={true}
            removable={true} />
          <JobClientCommonReference
            clientId={client} />
        </Col>
      </Row>
      <Callout
        invalid={invalid}
        submitFailed={submitFailed} />
      <Button
        type="button"
        bsStyle="primary"
        bsSize="sm"
        onClick={handleSubmit(values => handleIssue(values))}>
        <FontAwesome name="paper-plane-o" className="mr-5" />Issue
      </Button>
      <Button
        type="button"
        bsSize="sm"
        bsStyle="danger"
        className="ml-10"
        onClick={handleRemove}>
        <FontAwesome name="trash" className="mr-5" />Delete
      </Button>
    </Form>
  )
}

export default reduxForm({
  enableReinitialize: true,
  validate: validators.jobFormValidate
})(JobIssueForm);
