import React from 'react';
import { Content } from 'reactjs-admin-lte';
import UserCreateForm from './UserCreateForm';


const UserCreate = (props) => {
  
  const {
    form,
    isLoading,
    groupOptions,
    handleCreate,
  } = props;
  
  return (
    <Content.Body>
      <UserCreateForm
        form={form}
        isLoading={isLoading}
        groupOptions={groupOptions}
        onSubmit={handleCreate} />
    </Content.Body>
  );
}

export default UserCreate;