import React from 'react';
import FontAwesome from 'react-fontawesome';
import classNames from 'classnames';
import PureRenderComponent from 'components/core/PureRenderComponent';


class MainSidebarMenuTreeView extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = { open: true };
  }

  toggleMenu = () => {
    this.setState(prevState => ({
        open: !prevState.open
    }));
  }

  render() {
    
    const {
      title,
      iconName,
      children
    } = this.props;
    
    const cls = classNames({
      'treeview': true,
      'active': this.props.active,
      'menu-open': this.state.open
    })

    return (
      <li className={cls}>
        <a onClick={this.toggleMenu}>
          <FontAwesome name={iconName} tag="i" />
          <span>{title}</span>
          <span className="pull-right-container">
            <FontAwesome name="angle-left" className="pull-right" />
          </span>
        </a>
        <ul className="treeview-menu" style={{ display: this.state.open ? 'block' : 'none' }}>
          {children}
        </ul>
      </li>
    )
  }
}

export default MainSidebarMenuTreeView;
