import plainTextToHtml from "components/taUi/util/plainTextToHtml";

export default function handleClipboard(editor) {

  //const clipboardPlugin = editor.plugins.get('Clipboard');
  const editingView = editor.editing.view;

  editingView.document.on('clipboardInput', (event, data) => {

    if (editor.isReadOnly) {
      return;
    }

    const dataTransfer = data.dataTransfer;

    let content = plainTextToHtml(dataTransfer.getData('text/plain'));
    // console.log('handleClipboard - content - 1', content);

    // replace all 3 types of line breaks with a space
    content = content.replace(/(\r\n|\n|\r)/gm," ");

    // replace non-break-space with a space
    content = content.replace(/(&nbsp;|&#160;)/g," ");

    // remove <p> tags
    content = content.replace(/<p[^>]*?>(.*?)<\/p>/gms, '$1 '); // '$1</br>'

    //Replace all double white spaces with single spaces
    content = content.replace(/\s+/g," ");

    //Replace white space after Japanese dot
    content = content.replace(/。 /g,"。");

    // Remove spaces from start and end of string.
    content = content.trim();

    // console.log('handleClipboard - content - 2', content);

    data.content = editor.data.htmlProcessor.toView(content);

  });

}


