import React from 'react';
import { Box } from 'reactjs-admin-lte';
import TooltipIconButton from 'components/core/TooltipIconButton';
import DocumentTable from 'components/documents/DocumentTable';


const ClientCommonReference = (props) => {
  const {
    title,
    files,
    isLoading,
    uploadable,
    updatable,
    removable,
    usable,
    jobId,
    showUploadModal,
    showUpdateModal,
    handleDownload,
    handleUse,
    handleRemove
  } = props
  
  return (
    <Box
      className="box-widget"
      loading={isLoading}>
      <Box.Header border={true}>
        <Box.Title>{title}</Box.Title>
        <Box.Tools>
          { uploadable &&
            <TooltipIconButton
              id="upload-new-reference"
              className="btn-box-tool"
              icon="upload"
              description="Upload Client Reference"
              onClick={showUploadModal} />
          }
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <DocumentTable
          documents={files}
          updatable={updatable}
          removable={removable}
          usable={usable}
          jobId={jobId}
          handleDownload={handleDownload}
          handleUse={handleUse}
          showUpdateModal={showUpdateModal}
          handleRemove={handleRemove} />
      </Box.Body>
    </Box>
    )
}


ClientCommonReference.defaultProps = {
  title: 'References',
  uploadable: false,
  updatable: false,
  removable: false,
  usable: false,
}

export default ClientCommonReference;