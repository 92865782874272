import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Form, FormGroup, ControlLabel, Button } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import * as validators from 'misc/validators';
import { JOB_LEVEL_OPTIONS } from 'misc/config';
import CharField from 'components/core/CharField';
import SelectField from 'components/core/SelectField';
import ClientAsyncSelectField from 'components/core/ClientAsyncSelectField';
import DatePickerField from 'components/core/DatePickerField';
import TimeField from 'components/core/TimeField';
import CheckboxField from 'components/core/CheckboxField';
import CheckboxGroupField from 'components/core/CheckboxGroupField';
import {
  ServiceSelectOptionField,
  AESelectOptionField,
  CoordinatorSelectOptionField,
} from 'components/core/SelectOptionField';
import JobQuickEditAssignmentField from './JobQuickEditAssignmentField';


const JobQuickEditForm = (props) => {

  const {
    isLoading,
    handleEdit,
    handleSubmit,
    totraOptions,
    translatorOptions,
    editorOptions,
    checkerOptions,
    finalEditorOptions,
    secondFinalEditorOptions,
    dtpOptions,
    secondDtpOptions,
    assigneeGroupOptions,
    requireNotification,
    handleTrash
  } = props;

  return (
    <Form
      noValidate
      horizontal
      autoComplete="off">
      <Box
        className="mb-0 quick-edit-box"
        loading={isLoading}
        collapsed={true}>
        <Box.Header border={true}>
          <Box.Title>Quick Edit</Box.Title>
          <Box.Tools>
            <Box.CollapsedToggleButton />
          </Box.Tools>
        </Box.Header>
        <Box.Body>
          <Row>
            <Col lg={3}>
              <Field
                name="number"
                label="No."
                validate={[validators.required]}
                isRequired={true}
                disabled={true}
                component={CharField.Horizontal} />
              <ServiceSelectOptionField
                type="Horizontal"
                validate={[validators.required]}
                isRequired={true} />
              <Field
                name="level"
                label="Level"
                validate={[validators.required]}
                isRequired={true}
                options={JOB_LEVEL_OPTIONS}
                component={SelectField.Horizontal} />
              <Field
                name="subject"
                label="Subject"
                type="text"
                validate={[
                  validators.required,
                  validators.maxLength150
                ]}
                isRequired={true}
                component={CharField.Horizontal} />
              <Field
                name="memo"
                label="Memo"
                type="text"
                validate={[validators.maxLength150]}
                component={CharField.Horizontal} />
              <Field
                name="targetCount"
                label="Target"
                type="text"
                validate={[validators.required]}
                isRequired={true}
                component={CharField.Horizontal} />
              <Field
                name="additionalCount"
                label="Add"
                type="text"
                validate={[validators.required]}
                isRequired={true}
                component={CharField.Horizontal} />
              <AESelectOptionField
                type="Horizontal"
                validate={[validators.required]}
                isRequired={true} />
              <CoordinatorSelectOptionField
                label="Coordi"
                type="Horizontal"
                validate={[validators.required]}
                isRequired={true} />
            </Col>
            <Col lg={9}>
              <Row>
                <FormGroup>
                  <Col componentClass={ControlLabel} lg={1}>Client</Col>
                  <Col lg={2}>
                    <Field
                      name="client"
                      validate={[validators.required]}
                      component={ClientAsyncSelectField} />
                  </Col>
                  <Col lg={2}>
                    <Field
                      name="deadlineDate"
                      validate={[validators.required]}
                      component={DatePickerField} />
                  </Col>
                  <Col lg={1}>
                    <Field
                      name="deadlineTime"
                      validate={[validators.required]}
                      component={TimeField} />
                  </Col>
                  <Col lg={1} lgOffset={4}>
                    <Field
                      name="strictDeadline"
                      label="Strict"
                      component={CheckboxField.Labeled} />
                  </Col>
                </FormGroup>
              </Row>
              <JobQuickEditAssignmentField
                name="totra"
                label="ToTra"
                options={totraOptions}
                handleTrash={handleTrash} />
              <JobQuickEditAssignmentField
                name="translator"
                label="Tra"
                options={translatorOptions}
                handleTrash={handleTrash} />
              <JobQuickEditAssignmentField
                name="editor"
                label="Edi"
                options={editorOptions}
                handleTrash={handleTrash} />
              <JobQuickEditAssignmentField
                name="checker"
                label="Che"
                options={checkerOptions}
                handleTrash={handleTrash} />
              <JobQuickEditAssignmentField
                name="finalEditor"
                label="FE1"
                options={finalEditorOptions}
                handleTrash={handleTrash} />
              <JobQuickEditAssignmentField
                name="secondFinalEditor"
                label="FE2"
                options={secondFinalEditorOptions}
                handleTrash={handleTrash} />
              <JobQuickEditAssignmentField
                name="dtp"
                label="DTP1"
                options={dtpOptions}
                handleTrash={handleTrash} />
              <JobQuickEditAssignmentField
                name="secondDtp"
                label="DTP2"
                options={secondDtpOptions}
                handleTrash={handleTrash} />
            </Col>
          </Row>
          { requireNotification() &&
            <div className="p-10 quick-edit-notification">
              <Field
                name="notifications"
                label="Send Deadline Change Email Notification to"
                options={assigneeGroupOptions}
                component={CheckboxGroupField.Labeled} />
            </div>
          }
        </Box.Body>
        <Box.Footer>
          <Button
            bsStyle="success"
            bsSize="sm"
            type="button"
            onClick={handleSubmit(values => handleEdit(values))}>
            <FontAwesome name="refresh" className="mr-5" />Update
          </Button>
        </Box.Footer>
      </Box>
    </Form>
  )
}

export default reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate: validators.jobFormFullValidate
})(JobQuickEditForm);
