import React from 'react';
import { Navbar } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';


const FilterBar = (props) => {
  return (
    <Navbar inverse fluid>
      <Navbar.Header>
        <Navbar.Brand className="disabled">
          <FontAwesome name="filter" />
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        {props.children}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default FilterBar;
