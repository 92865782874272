import React from 'react'
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Form, Field, reduxForm } from 'redux-form';
import { Button, Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import * as validators from 'misc/validators';
import { TEMPLATE_CREATE_FORM, TEMPLATE_CREATE_MODAL } from 'misc/constants';
import { isTemplateCreateModalLoading } from 'selectors/loading';
import { createRequest } from 'actions/templates';
import CharField from 'components/core/CharField';
import Spinner from 'components/core/Spinner';
import PureRenderComponent from 'components/core/PureRenderComponent';


class TemplateCreateModal extends PureRenderComponent {

  handleSubmit = (values) => {
    const job = this.props.job;
    this.props.createRequest(TEMPLATE_CREATE_FORM, {
      ...values,
      type: job.type,
      aeSubject: job.aeSubject,
      subject: job.subject,
      service: job.service,
      level: job.level,
      ae: job.ae,
      client: job.client,
      industry: job.industry,
      field: job.field,
      requireDtp: job.requireDtp,
      requireTotra: job.requireTotra,
      strictDeadline: job.strictDeadline,
      futureJob: job.futureJob,
      isUrgent: job.isUrgent,
      instruction: job.instruction,
      notes: job.notes,
    });
  }

  render() {

    const {
      show,
      isLoading,
      handleHide,
      handleSubmit,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Job Template</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              name="name"
              label="Template Name"
              type="text"
              validate={[validators.required]}
              isRequired={true}
              component={CharField.Labeled} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pull-left"
              bsSize="sm"
              type="button"
              onClick={handleHide}>
              <FontAwesome name="close" className="mr-5" />Close
            </Button>
            <Button
              bsStyle="primary"
              bsSize="sm"
              type="submit">
              <FontAwesome name="paper-plane-o" className="mr-5" />Submit
            </Button>
          </Modal.Footer>
          <Spinner isLoading={isLoading} />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: isTemplateCreateModalLoading(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    createRequest
  }, disptach)
}

TemplateCreateModal = reduxForm({ form: TEMPLATE_CREATE_FORM })(TemplateCreateModal);
TemplateCreateModal = connectModal({ name: TEMPLATE_CREATE_MODAL })(TemplateCreateModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateCreateModal);
