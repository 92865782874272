import React from "react";
import PropTypes from 'prop-types';
import './style.css';

export const TaIcon = (props) => {
    let classNames = ['ta-icon'];
    let {
        size,
        color,
        className,
        isActive,
      ...attributes
    } = props;

    if (className) {
        classNames.push(className);
    }
    if (size) {
        classNames.push('ta-icon--size-' + size);
    }
    if (color) {
        classNames.push('ta-icon--color-' + color);
    }
    if (isActive) {
        classNames.push('ta-icon--active')
    }

    return (<div className={classNames.join(' ')} {...attributes}><i className={'material-icons'}>{props.name}</i></div>)
};
TaIcon.propTypes = {
    name: PropTypes.string.isRequired,
    isActive:  PropTypes.bool
};
