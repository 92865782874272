import { takeLatest, call, put, fork } from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { hide } from 'redux-modal';
import { stopSubmit } from 'redux-form';
import { normalize } from 'normalizr';
import API from 'services/CustomerApi';
import * as actions from 'actions/customers';
import * as schemas from 'schemas/customers';
import * as actionTypes from 'actionTypes/customers';
import * as entityActions from 'actions/entities';
import * as listActions from 'actions/lists';
import * as coreSagas from 'sagas/core';
import * as constants from 'misc/constants';
import { closeCurrentTab } from 'sagas/globalTabs';
import { setLoading } from 'actions/loading';

function* list(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.CUSTOMER_LIST, true));
  try {
    const response = yield call(API.list, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data.results), [schemas.customerSchema]);
    const totalCount = response.data.count ? response.data.count : 1;
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setPaginationResult(
      constants.CUSTOMER_LIST,
      params.page,
      normalized.result,
      totalCount
    ));
    yield put(setLoading(constants.CUSTOMER_LIST, false));
  } catch (error) {
    yield put(actions.listFailure(error));
    yield put(setLoading(constants.CUSTOMER_LIST, false));
  }
}

// Create ======================================================================
function* create(action) {
  const { form, values } = action.payload;
  yield put(setLoading(constants.CUSTOMER_CREATE, true));
  try {
    const response = yield call(API.create, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.customerSchema);
    yield put(entityActions.merge(normalized.entities));
    yield fork(closeCurrentTab);
    yield fork(coreSagas.successNotification, 'Customer created successfully.');
    yield put(setLoading(constants.CUSTOMER_CREATE, false));
  } catch (error) {
    if (error.response.status === 400 && error.response.data.username && error.response.data.username.length > 0) {
      yield put(stopSubmit(form, { username: error.response.data.username[0] }));
    }
    yield put(setLoading(constants.CUSTOMER_CREATE, false));
    yield put(actions.createFailure(error));
  }
}

// Retrieve ====================================================================
function* retrieve(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.CUSTOMER_DETAIL}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.retrieve, id);
    const normalized = normalize(camelizeKeys(response.data), schemas.customerSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.retrieveFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Update ======================================================================
function* update(action) {
  const { id, values } = action.payload;
  const loadingKey = `${constants.CUSTOMER_DETAIL}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.update, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.customerSchema);
    yield put(entityActions.merge(normalized.entities));
    yield fork(coreSagas.successNotification, 'Customer information updated successfully.');
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.updateFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Remove ======================================================================
function* remove(action) {
  const { item } = action.payload;
  const message = `Are you sure to delete ${item.name} ?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      yield call(API.remove, item.id);
      yield put(listActions.removePaginationItem(constants.CUSTOMER_LIST, item.id));
      yield fork(closeCurrentTab);
      yield fork(coreSagas.successNotification, 'Customer deleted successfuly.');

      yield put(entityActions.remove('customers', item.id));
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.removeFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}


function* watchCreate() {
  yield takeLatest(actionTypes.CREATE.REQUEST, create);
}
function* watchList() {
  yield takeLatest(actionTypes.LIST.REQUEST, list);
}
function* watchRetrieve() {
  yield takeLatest(actionTypes.RETRIEVE.REQUEST, retrieve);
}
function* watchUpdate() {
  yield takeLatest(actionTypes.UPDATE.REQUEST, update);
}
function* watchRemove() {
  yield takeLatest(actionTypes.REMOVE.REQUEST, remove);
}


export {
  watchCreate,
  watchList,
  watchRetrieve,
  watchUpdate,
  watchRemove,
}