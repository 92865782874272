import React from 'react';
import { Checkbox } from 'react-bootstrap';
import { LinkFieldCellRenderer, EmployeeCurrentJobsStatusCellRenderer } from 'misc/cellRenderers';
import { getFieldTotalCount } from 'misc/utils';
import { getTabByRole } from 'misc/config';
import { datetimeFormatter } from 'misc/cellFormatters';
import ListTable from 'components/core/ListTable';
import PureRenderComponent from 'components/core/PureRenderComponent';


const CheckboxCellRenderer = (props) => {
  const { data, colDef } = props;
  const { handleSelect, selected } = colDef.cellRendererParams;
  return data && data.id
    ? (
      <Checkbox
        checked={selected.indexOf(data.id) !== -1}
        value={data.id}
        onChange={handleSelect}
        className="mt-5 mb-5" />
    ) : null
}

class CheckerCurrentJobsTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      targetSum: 0,
      additionalSum: 0,
    }
  }

  getColumnDefs = () => {
    const  {
      roleKey,
      handleTabOpen,
      allowBulkFinish,
      selected,
      handleSelect
     } = this.props;
    const columnDefs = []

    if (allowBulkFinish) {
      columnDefs.push({
        headerName: '',
        field: '',
        suppressFilter: true,
        pinned: 'left',
        width: 30,
        cellRendererFramework: CheckboxCellRenderer,
        cellRendererParams: {
          selected: selected,
          handleSelect: handleSelect
        },
      })
    }

    columnDefs.push({
      headerName: 'No.',
      field: 'number',
      pinned: 'left',
      cellClass: 'ag-jobNumber',
      width: 140,
      cellRendererFramework: LinkFieldCellRenderer,
      cellRendererParams: {
        handleTabOpen,
        tab: getTabByRole(roleKey),
      }
    });

    columnDefs.push({
      headerName: 'Client',
      field: 'client.name',
      width: 250,
    });
    columnDefs.push({
      headerName: 'Deadline',
      field: `${roleKey}.deadline`,
      width: 140,
      valueFormatter: datetimeFormatter,
    });
    columnDefs.push({
      headerName: 'Target',
      field: 'targetCount',
      filter: 'agNumberColumnFilter',
      width: 90,
    });
    columnDefs.push({
      headerName: 'Add',
      field: 'additionalCount',
      filter: 'agNumberColumnFilter',
      width: 80,
    });
    columnDefs.push({
      headerName: 'Subject',
      field: 'subject',
      width: 300,
    });
    columnDefs.push({
        headerName: 'Memo',
        field: 'memo',
        width: 300,
    });
    columnDefs.push({
      headerName: 'Service',
      field: 'service.name',
      width: 80,
    });
    columnDefs.push({
      headerName: 'Lv',
      field: 'level',
      width: 40,
    });
    columnDefs.push({
      headerName: 'Coordinator',
      field: 'coordinator.name',
      width: 120,
    });
    columnDefs.push({
      headerName: 'Status',
      field: 'status',
      width: 140,
      pinned: 'right',
      cellClass: 'statusRow',
      cellRendererFramework: EmployeeCurrentJobsStatusCellRenderer,
      cellRendererParams: {
        sentToStatus: this.props.sentToStatus,
      }
    });

    return columnDefs;
  }


  componentDidMount() {
    this.setState({
      targetSum: getFieldTotalCount(this.props.jobs, 'targetCount'),
      additionalSum: getFieldTotalCount(this.props.jobs, 'additionalCount')
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.jobs !== this.props.jobs) {
      this.setState({
        targetSum: getFieldTotalCount(this.props.jobs, 'targetCount'),
        additionalSum: getFieldTotalCount(this.props.jobs, 'additionalCount')
      });
    }
  }

  getPinnedBottomRowData = () => ([{
    'targetCount': this.state.targetSum,
    'additionalCount': this.state.additionalSum,
  }])

  handleFilterChanged = (event) => {
    let targetSum = 0;
    let additionalSum = 0
    event.api.forEachNodeAfterFilter(node => {
      targetSum += node.data.targetCount;
      additionalSum += node.data.additionalCount;
    });
    this.setState({ targetSum, additionalSum });
  }

  render() {

    const wrapperHeight = this.props.allowBulkFinish ? '83vh' : '87vh';

    return (
      <ListTable
        wrapperHeight={wrapperHeight}
        columnDefs={this.getColumnDefs()}
        rowData={this.props.jobs}
        deltaRowDataMode={true}
        onFilterChanged={this.handleFilterChanged}
        getRowNodeId={data => data.id}
        pinnedBottomRowData={this.getPinnedBottomRowData()} />
    )
  }
}

export default CheckerCurrentJobsTable;