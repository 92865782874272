import React from "react";
import {TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";
import {Form, Modal} from "react-bootstrap";
import {change, Field, reduxForm, reset} from "redux-form";
import * as validators from "misc/validators";
import CharField from "components/core/CharField";
import CheckboxField from 'components/core/CheckboxField';
import SelectField from "components/core/SelectField";
import {connect} from "react-redux";
import {isTranslationMemorySettingsModalLoading, isWordListDetailSettingsModalLoading} from "selectors/loading";
import {bindActionCreators} from "redux";
import {
  TRANSLATION_MEMORY_DETAIL_SETTINGS_FORM,
  TRANSLATION_MEMORY_DETAIL_SETTINGS_MODAL,
} from "misc/constants";
import Spinner from "components/core/Spinner";
import {connectModal} from "redux-modal";
import ClientAsyncSelectField from "components/core/ClientAsyncSelectField";
import JobNumberAsyncSelectField from "components/core/JobNumberAsyncSelectField";
import {getMemoryServiceOptions} from "selectors/options";

class TranslationMemorySettingsModal extends React.Component {

  hide = () => {
    this.props.handleHide();
    this.props.reset(TRANSLATION_MEMORY_DETAIL_SETTINGS_FORM);
  };

  render() {
    const {
      show,
      handleSubmit,
      onSubmit,
      isLoading,
      serviceOptions,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={this.hide}
        animation={true}
        backdrop="static"
      >
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit((values => onSubmit(values)))}
          onReset={this.hide}
        >
          <TaModalHeader
            label={'Translation Memory Settings'}
            onCancel={this.hide}
          />
          <TaModalContent>
            <div className="p-15">
              <Field
                name="name"
                label="List Name"
                type="textr"
                validate={[
                  validators.required,
                ]}
                isRequired={true}
                component={CharField.Labeled} />
              <Field
                name="service"
                label="Service"
                validate={[
                  validators.required,
                ]}
                isRequired={true}
                options={serviceOptions}
                component={SelectField.Labeled} />
              <Field
                name="client"
                label="Client"
                component={ClientAsyncSelectField.Labeled} />
              <Field
                name="jobs"
                label="Job"
                multi={true}
                component={JobNumberAsyncSelectField.Labeled} />
              <Field
                name="keyword"
                label="Keyword"
                type="textr"
                component={CharField.Labeled} />
              <Field
                name="excludePenalty"
                label="exclude penalty"
                component={CheckboxField.Labeled} />
              <Field
                name="penaltyOnly"
                label="penalty only"
                component={CheckboxField.Labeled} />
            </div>
            <Spinner isLoading={isLoading} />
          </TaModalContent>
          <TaModalFooter>
            <TaButton
              type="reset"
              icon={'clear'}
              label={'Cancel'}
            />
            <TaButton
              type="submit"
              icon={'check'}
              label={'Save'}
              iconAlign={'right'}
            />
          </TaModalFooter>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const initialValues = (
    state.modal &&
    state.modal[TRANSLATION_MEMORY_DETAIL_SETTINGS_MODAL] &&
    state.modal[TRANSLATION_MEMORY_DETAIL_SETTINGS_MODAL].props &&
    state.modal[TRANSLATION_MEMORY_DETAIL_SETTINGS_MODAL].props.initialValues
  ) ? state.modal[TRANSLATION_MEMORY_DETAIL_SETTINGS_MODAL].props.initialValues : {};

  return {
    initialValues: initialValues,
    serviceOptions: getMemoryServiceOptions(state),
    isLoading: isTranslationMemorySettingsModalLoading(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    change,
    reset
  }, dispatch);
};

TranslationMemorySettingsModal = connectModal({
  name: TRANSLATION_MEMORY_DETAIL_SETTINGS_MODAL
})(TranslationMemorySettingsModal);

TranslationMemorySettingsModal = reduxForm({
  form: TRANSLATION_MEMORY_DETAIL_SETTINGS_FORM,
  enableReinitialize: true
})(TranslationMemorySettingsModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationMemorySettingsModal);
