import axios from 'axios';

const UserApi = {
  list: (params) => {
    return axios.get(`users/`, { params });
  },
  create: (values) => {
    return axios.post(`users/register/`, values);
  },
  retrieve: (id) => {
    return axios.get(`users/${id}/`);
  },
  update: (id, values) => {
    return axios.put(`users/${id}/`, values);
  },
  remove: (id) => {
    return axios.delete(`users/${id}/`);
  },
  profileRetrieve: (id) => {
    return axios.get(`users/${id}/profile/`);
  },
  profileUpdate: (id, values) => {
    return axios.put(`users/${id}/profile/`, values);
  },
  avatarUpload: (id, avatar) => {
    return axios.patch(`users/${id}/avatar/`, { avatar });
  },
  salesTargetList: (params) => {
    return axios.get(`sales/target/`, { params });
  },
  salesTargetCreate: (values) => {
    return axios.post(`sales/target/`, values);
  },
  salesTargetPatch: (id, values) => {
    return axios.patch(`sales/target/${id}/`, values);
  },

  // Translation Skill =========================================================
  translationSkillList: (userId) => {
    return axios.get(`users/${userId}/skills/translation/`);
  },
  translationSkillCreate: (userId, values) => {
    return axios.post(`users/${userId}/skills/translation/`, values);
  },
  translationSkillUpdate: (userId, id, values) => {
    return axios.put(`users/${userId}/skills/translation/${id}/`, values);
  },
  // Edit Skill ================================================================
  editSkillList: (userId) => {
    return axios.get(`users/${userId}/skills/edit/`);
  },
  editSkillCreate: (userId, values) => {
    return axios.post(`users/${userId}/skills/edit/`, values);
  },
  editSkillUpdate: (userId, id, values) => {
    return axios.put(`users/${userId}/skills/edit/${id}/`, values);
  },
  // Check Skill ===============================================================
  checkSkillList: (userId) => {
    return axios.get(`users/${userId}/skills/check/`);
  },
  checkSkillCreate: (userId, values) => {
    return axios.post(`users/${userId}/skills/check/`, values);
  },
  checkSkillUpdate: (userId, id, values) => {
    return axios.put(`users/${userId}/skills/check/${id}/`, values);
  },
  // Final Edit Skill ==========================================================
  finalEditSkillList: (userId) => {
    return axios.get(`users/${userId}/skills/final-edit/`);
  },
  finalEditSkillCreate: (userId, values) => {
    return axios.post(`users/${userId}/skills/final-edit/`, values);
  },
  finalEditSkillUpdate: (userId, id, values) => {
    return axios.put(`users/${userId}/skills/final-edit/${id}/`, values);
  },
  // Second Final Edit Skill ===================================================
  secondFinalEditSkillList: (userId) => {
    return axios.get(`users/${userId}/skills/second-final-edit/`);
  },
  secondFinalEditSkillCreate: (userId, values) => {
    return axios.post(`users/${userId}/skills/second-final-edit/`, values);
  },
  secondFinalEditSkillUpdate: (userId, id, values) => {
    return axios.put(`users/${userId}/skills/second-final-edit/${id}/`, values);
  },
  // Focus =====================================================================
  focusCreate: (userId, values) => {
    return axios.post(`users/${userId}/focus/`, values);
  },
  focusList: (userId) => {
    return axios.get(`users/${userId}/focus/`);
  },
  focusUpdate: (userId, id, values) => {
    return axios.patch(`users/${userId}/focus/${id}/`, values);
  },
  // Specialty =================================================================
  specialtyCreate: (userId, values) => {
    return axios.post(`users/${userId}/specialties/`, values);
  },
  specialtyList: (userId) => {
    return axios.get(`users/${userId}/specialties/`);
  },
  specialtyUpdate: (userId, id, values) => {
    return axios.patch(`users/${userId}/specialties/${id}/`, values);
  },
  // Schedule ==================================================================
  scheduleList: (userId) => {
    return axios.get(`users/${userId}/schedules/`);
  },
  scheduleUpdateOrCreate: (userId, values) => {
    return axios.post(`users/${userId}/schedules/`, values);
  },
  softwareList: (userId) => {
    return axios.get(`users/${userId}/software/`);
  },
  softwareCreate: (userId, values) => {
    return axios.post(`users/${userId}/software/`, values);
  },
  softwareDelete: (userId, softwareId) => {
    return axios.delete(`users/${userId}/software/${softwareId}/`);
  }
}

export default UserApi;
