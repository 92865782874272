import React from "react";
import * as PropTypes from 'prop-types';
import {TaFormField} from "components/taUi/taFormField/taFormField";
import {TaInputText} from "components/taUi/taInputText";

export const TaFormFieldText = (props) => {
  return (
    <TaFormField {...props}>
      <TaInputText {...props.input}/>
    </TaFormField>
  );
};