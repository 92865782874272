import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { pendingListRequest } from 'actions/jobs/managers';
import { getPendingJobs } from 'selectors/lists';
import { isPendingListLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobPendingList from './presenter';


class JobPendingListContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.pendingListRequest();
  }
  
  render() {
    return <JobPendingList {...this.props} />
  }
}


const mapStateToProps = (state) => {
  return {
    jobs: getPendingJobs(state),
    isLoading: isPendingListLoading(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    pendingListRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobPendingListContainer);
