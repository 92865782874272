import React from 'react';
import { Content, Box } from 'reactjs-admin-lte';
import JobPreparingListFilter from './JobPreparingListFilter'
import JobPreparingListTable from './JobPreparingListTable'


const JobPreparingList = (props) => {

  const {
    me,
    jobs,
    isLoading,
    coordinator,
    coordinatorOptions,
    handleCoordinatorChange,
    handleTabOpen
  } = props;

  return (
    <Content.Body>
      <JobPreparingListFilter
        coordinator={coordinator}
        coordinatorOptions={coordinatorOptions}
        handleCoordinatorChange={handleCoordinatorChange} />
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body noPadding={true}>
          <JobPreparingListTable
            me={me}
            jobs={jobs}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
      </Box>
    </Content.Body>
  );
}

export default JobPreparingList;
