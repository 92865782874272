import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import { reducer as modalReducer } from 'redux-modal';
import { reducer as notificationReducer } from 'react-notification-system-redux';
import authReducer from './auth';
import entityReducer from './entities';
import coreReducer from './core';
import globalTabReducer from './globalTabs';
import tabReducer from './tabs';
import filterReducer from './filters';
import listReducer from './lists';
import jobReducer from './jobs';
import loadingReducer from './loading';
import settingReducer from './settings';
import layoutReducer from './layout';
import calendarReducer from './calendars';
import wordListUiReducer from "./wordListUi";
import glossaryUiReducer from "./glossaryUi";

const rootReducer = combineReducers({
  form: formReducer,
  modal: modalReducer,
  notifications: notificationReducer,
  core: coreReducer,
  entities: entityReducer,
  auth: authReducer,
  globalTabs: globalTabReducer,
  tabs: tabReducer,
  filters: filterReducer,
  lists: listReducer,
  jobs: jobReducer,
  loading: loadingReducer,
  settings: settingReducer,
  layout: layoutReducer,
  calendars: calendarReducer,
  wordListUi: wordListUiReducer,
  glossaryUi: glossaryUiReducer
});

export default rootReducer;