import moment from 'moment';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { userSchema, assignmentSchema } from 'schemas';
import { isInDateRange } from 'misc/utils';
import { getEntities } from 'selectors/entities';
import { getCalendarVisibleRange } from 'selectors/filters';

const getAssignmentStyleClass = (assignment) => {
  if (assignment.status !== 'Finished' && moment().isAfter(assignment.deadline)) {
    return 'event-assignment event-assignment-expired';
  } else if (assignment.status === 'Created') {
    return 'event-assignment event-assignment-created';
  } else if (assignment.status === 'Working') {
    return 'event-assignment event-assignment-working';
  } else if (assignment.status === 'Finished') {
    return 'event-assignment event-assignment-finished';
  } else {
    return 'event-assignment'
  }
}

const getAssignmentEvents = (assignments) => {
  return assignments.map(assignment => {
    let title = `${assignment.jobNumber}`;
    const className = getAssignmentStyleClass(assignment);
    return {
      title,
      className,
      id: assignment.id,
      jobId: assignment.job,
      jobNumber: assignment.jobNumber,
      start: assignment.start,
      end: assignment.end,
      role: assignment.role,
      type: 'assignment'
    }
  });
}

const getCalendarUsers = state => state.calendars.calendar.users;
const getCalendarAssignments = state => state.calendars.calendar.assignments;


export const getCalendarEvents = createSelector(
  [
    getCalendarUsers, getCalendarAssignments,
    getCalendarVisibleRange, getEntities
  ],
  (
    userIds, assignmentIds,
    visibleRange, entities
  ) => {
    const { start, end } = visibleRange;
    const users = denormalize(userIds, [userSchema], entities);
    const assignments = denormalize(assignmentIds, [assignmentSchema], entities);
    return users.map(user => {

      const userAssignments = assignments.filter(assignment => (
        assignment &&
        assignment.assignee.id === user.id &&
        isInDateRange(assignment.start, assignment.end, start, end)
      ));
      const assignmentEvents = getAssignmentEvents(userAssignments);
      return {
        userId: user.id,
        username: user.username,
        userAvatar: user.avatar,
        isFreelance: user.isFreelance,
        isNative: user.isNative,
        events: [
          ...assignmentEvents,
        ]
      }
    });
  }
)