import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  DEFAULT_TABLE_WRAPPER_HEIGHT,
  DEFAULT_TABLE_ROW_HEIGHT
} from 'misc/config';



const ListTable = (props) => {

  const {
    wrapperHeight,
    ...rest
  } = props;

  return (
    <div
      className="ag-theme-balham"
      style={{ height: wrapperHeight, width: '100%' }}>
      <AgGridReact {...rest} />
    </div>
  );
}

ListTable.defaultProps = {
  wrapperHeight: DEFAULT_TABLE_WRAPPER_HEIGHT,
  rowHeight: DEFAULT_TABLE_ROW_HEIGHT,
  enableSorting: true,
  enableColResize: true,
  animateRows: true,
  enableFilter: true,
  suppressCellSelection: true,
  suppressLoadingOverlay: true,
}


export default ListTable;
