import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Table, Button } from 'react-bootstrap';
import EmptyRow from 'components/core/EmptyRow';


const ClientWebsiteTableRow = ({ website, handleRemove }) => (
  <tr>
    <td><a href={website.url} target="_blank">{website.url}</a></td>
    <td className="text-center">
      <Button
        bsSize="xs"
        bsStyle="danger"
        onClick={() => handleRemove(website)}>
        <FontAwesome name="trash" />
      </Button>
    </td>
  </tr>
)

const ClientWebsiteTable = (props) => {

  const {
    websites,
    handleRemove
  } = props;

  return (
    <Table
      striped
      hover
      className="table-layout-fixed">
      <thead>
        <tr>
          <th>URL</th>
          <th className="text-center" width="100px">Action</th>
        </tr>
      </thead>
      <tbody>
      { websites && websites.length > 0
          ? websites.map(website => (
              <ClientWebsiteTableRow
                key={website.id}
                website={website}
                handleRemove={handleRemove} />
            ))
          : <EmptyRow colSpan={2} />
        }
      </tbody>
    </Table>
  );
}

export default ClientWebsiteTable;
