import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PREPARING_LIST } from 'misc/constants';
import { preparingListRequest } from 'actions/jobs/managers';
import { setFilter } from 'actions/filters';
import { getMyUserId } from 'selectors/auth';
import { getPreparingJobs } from 'selectors/lists';
import { isPreparingListLoading } from 'selectors/loading';
import { getCoordinatorOptions } from 'selectors/options';
import { getPreparingListCoodrinator } from 'selectors/filters';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobPreparingList from './presenter';


class JobPreparingListContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.setFilter(PREPARING_LIST, 'coordinator', this.props.myUserId);
    this.props.preparingListRequest({ user: this.props.myUserId });
  }

  handleCoordinatorChange = (coordinator) => {
    this.props.setFilter(PREPARING_LIST, 'coordinator', coordinator)
    this.props.preparingListRequest({ user: coordinator });
  }

  render() {
    return (
      <JobPreparingList
        {...this.props}
        handleCoordinatorChange={this.handleCoordinatorChange} />
    );
  }
}


const mapStateToProps = (state) => {
  return {
    myUserId: getMyUserId(state),
    jobs: getPreparingJobs(state),
    isLoading: isPreparingListLoading(state),
    coordinator: getPreparingListCoodrinator(state),
    coordinatorOptions: getCoordinatorOptions(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    preparingListRequest,
    setFilter,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobPreparingListContainer);
