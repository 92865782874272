import React from 'react';
import FontAwesome from 'react-fontawesome';
import { connectModal } from 'redux-modal';
import { Button, Modal } from 'react-bootstrap';
import { EMPLOYEE_MHT_JOB_FINISH_MODAL } from 'misc/constants';
import EmployeeJobFinishSuccess from 'components/jobs/employees/EmployeeJobFinishModal/EmployeeJobFinishSuccess';
import PureRenderComponent from 'components/core/PureRenderComponent';


class EmployeeMHTJobFinishModalContainer extends PureRenderComponent {
  render() {
    const {
      show,
      handleHide
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        bsSize="large"
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Process job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmployeeJobFinishSuccess />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pull-left"
            bsSize="sm"
            type="button"
            onClick={handleHide}>
            <FontAwesome name="close" className="mr-5" />Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connectModal({
  name: EMPLOYEE_MHT_JOB_FINISH_MODAL
})(EmployeeMHTJobFinishModalContainer);