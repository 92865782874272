import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Nav, NavItem } from 'react-bootstrap';
import ComponentSlider from "@kapost/react-component-slider";


const renderLeftArrow = () => <FontAwesome name="caret-left" />
const renderRightArrow = () => <FontAwesome name="caret-right" />

const GlobalTabs = (props) => {

  const {
    tabs,
    onSelect,
    activeKey,
    handleTabClose,
    handleOpenTabCloseConfirm
  } = props;

  const handleCloseClick = (e, tab) => {
    if (tab.requireCloseConfirm) {
      handleOpenTabCloseConfirm(e, tab)
    } else {
      handleTabClose(e, tab.id);
    }
  }

  return (
    <ComponentSlider
      renderLeftArrow={renderLeftArrow}
      renderRightArrow={renderRightArrow}>
      <Nav bsStyle="tabs">
        {tabs.map(tab => (
          <NavItem
            key={tab.key}
            eventKey={tab.id}
            active={tab.id === activeKey}
            onSelect={onSelect}>
            <span className="mr-10">{tab.title}</span>
            <FontAwesome
              name="times-circle"
              onClick={(e) => handleCloseClick(e, tab)} />
          </NavItem>
        ))}
      </Nav>
    </ComponentSlider>
  );
}

export default GlobalTabs;
