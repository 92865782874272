import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';
import { MULTIPLE_FILE_UPLOAD_MODAL } from 'misc/constants';
import { jobCommonReferenceListRequest } from 'actions/jobs/common';
import {
  jobCommonReferenceDownloadRequest,
  jobCommonReferenceUploadRequest,
  jobCommonReferenceRemoveRequest
} from 'actions/documents/'
import { isJobCommonReferenceListLoading } from 'selectors/loading';
import { getJobCommonReferencesByGroup } from 'selectors/lists';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobCommonReference from './presenter';


class JobCommonReferenceContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.jobGroup &&
    this.props.jobCommonReferenceListRequest(this.props.jobGroup);
  }

  showUploadModal = () => {
    this.props.show(MULTIPLE_FILE_UPLOAD_MODAL, {
      title: 'Job Common Reference',
      handleUpload: this.handleUpload
    })
  }

  handleDownload = (file) => {
    this.props.jobCommonReferenceDownloadRequest(file);
  }
  
  handleUpload = (files) => {
    this.props.jobCommonReferenceUploadRequest(this.props.jobGroup, files);
  }
  
  handleRemove = (file) => {
    this.props.jobCommonReferenceRemoveRequest(this.props.jobGroup, file)
  }

  render() {
    return (
      <JobCommonReference
        {...this.props}
        showUploadModal={this.showUploadModal}
        handleDownload={this.handleDownload}
        handleUpload={this.handleUpload}
        handleRemove={this.handleRemove} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const group = props.jobGroup;
  return {
    isLoading: isJobCommonReferenceListLoading(state, group),
    files: getJobCommonReferencesByGroup(state, group),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    jobCommonReferenceListRequest,
    jobCommonReferenceDownloadRequest,
    jobCommonReferenceUploadRequest,
    jobCommonReferenceRemoveRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobCommonReferenceContainer)
