import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Content } from 'reactjs-admin-lte';
import JobWorkFlow from 'components/jobs/common/JobWorkFlow';
import JobMessage from 'components/jobs/common/JobMessage';
import JobSourcefile from 'components/jobs/common/JobSourcefile';
import JobReference from 'components/jobs/common/JobReference';
import JobCommonReference from 'components/jobs/common/JobCommonReference';
import JobNote from 'components/jobs/common/JobNote';
import ClientCommonReference from 'components/clients/ClientCommonReference'
import JobDetailBasicInfo from './JobDetailBasicInfo';
import JobDetailTools from './JobDetailTools';


const JobDetail = (props) => {

  const {
    id,
    // job,
    isCoordinator,
    isAE,
    myUserId,
    // assignments,
    isLoading,
    isMHTCreatable,
    handlePrint,
    handleProcess,
    handleDuplicateTabOpen,
    handleSplitTabOpen,
    handleSplitIssueTabOpen,
    handleEditTabOpen,
    handleTabOpen,
    showJobTemplateModal,
    manuallyProcessOptions,
    getNextStatus,
    roleKey
  } = props;

  const job = Object.assign({}, props.job);
  const assignments = [].concat(props.assignments);

  return (
    <section className="job-detail">
      <Content.Header>
        <h1 className="clearfix">
          {job.number}
          <JobDetailTools
            id={id}
            isAE={isAE}
            jobType={job.type}
            isCoordinator={isCoordinator}
            handlePrint={handlePrint}
            handleProcess={handleProcess}
            handleTabOpen={handleTabOpen}
            handleDuplicateTabOpen={handleDuplicateTabOpen}
            handleSplitTabOpen={handleSplitTabOpen}
            handleSplitIssueTabOpen={handleSplitIssueTabOpen}
            handleEditTabOpen={handleEditTabOpen}
            manuallyProcessOptions={manuallyProcessOptions}
            showJobTemplateModal={showJobTemplateModal}
            getNextStatus={getNextStatus} />
        </h1>
      </Content.Header>
      <Content.Body>
        <Row>
          <Col lg={9}>
            <JobDetailBasicInfo
              job={job}
              isLoading={isLoading} />
            <JobNote
              collapsed={false}
              isLoading={isLoading}
              title="Job Notes"
              content={job.notes} />
            <JobNote
              collapsed={true}
              isLoading={isLoading}
              title="Instruction"
              content={job.instruction} />
            <JobWorkFlow
              assignments={assignments}
              isLoading={isLoading}
              uploadable={isCoordinator}
              removable={isCoordinator}
              executable={isCoordinator} />
            <Row>
              <Col lg={6}>
                <JobSourcefile
                  jobId={id}
                  job={job}
                  roleKey={roleKey}
                  isMHTClickable={true}
                  isMHTCreatable={isMHTCreatable}
                  highlight={isCoordinator}
                  uploadable={isCoordinator}
                  removable={isCoordinator && job.status === 'Job Created'}
                  handleTabOpen={handleTabOpen} />
                <ClientCommonReference
                  title="Client Common References"
                  jobId={id}
                  clientId={job.client && job.client.id}
                  usable={isCoordinator}
                  uploadable={isCoordinator} />
              </Col>
              <Col lg={6}>
                <JobReference
                  jobId={id}
                  uploadable={isCoordinator}
                  removable={isCoordinator} />
                <JobCommonReference
                  jobGroup={job.group}
                  uploadable={isCoordinator}
                  removable={isCoordinator} />
              </Col>
            </Row>
          </Col>
          <Col lg={3}>
            <JobMessage
              jobId={id}
              roleKey={isCoordinator ? 'coordinator' : 'ae'}
              isReceiverHidden={!isCoordinator}
              myUserId={myUserId}
              isCoordinator={isCoordinator} />
          </Col>
        </Row>
      </Content.Body>
    </section>
  )
}

export default JobDetail;