import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { retrieveRequest } from 'actions/quotes';
import { getQuoteValueById } from 'selectors/quotes';
import { isQuoteRetrieveLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import QuoteDetail from './presenter';


class QuoteDetailContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.retrieveRequest(this.props.id);
  }

  render() {
    return (
      <QuoteDetail {...this.props} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const id = props.params.id;
  return {
    id,
    quote: getQuoteValueById(state, id),
    isLoading: isQuoteRetrieveLoading(state, id),
  }
}
  
const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    retrieveRequest
  }, disptach)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuoteDetailContainer);