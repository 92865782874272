import {createSelector} from "reselect";
import {getId} from "./core";
import {getInitialState} from "../reducers/glossaryUi";

const _getAllLists = state => state.glossaryUi || {};

export const getGlossarySettings = createSelector(
  [_getAllLists, getId],
  (allListSettings, currentPage) => {
    return allListSettings[currentPage] || getInitialState()
  }
)

export const getGlossaryColumns = createSelector(
  [getGlossarySettings],
  (settings) => settings.columns || {}
)

export const getGlossaryUpdated = createSelector(
  [getGlossarySettings],
  (settings) => settings.updated || 0
)