import React from "react";
import * as PropTypes from 'prop-types';
import './taTable.css';

const auto = 'auto';

export const TaTable = (props) => {

  const {
    className,
    flex,
    size,
    scroll,
    look,
    ...attributes
  } = props;


  const baseClass = 'ta-table';
  let classNames = [baseClass];

  if (scroll === auto) {
    classNames.push(baseClass + '--scroll');
  } else if (scroll && size !== 'hidden') {
    classNames.push(baseClass + '--scroll_' + scroll);
  }

  if (flex === auto) {
    classNames.push(baseClass + '--flex');
  } else if (flex) {
    classNames.push(baseClass + '--flex-' + flex);
  }

  if (size && size !== auto) {
    classNames.push(baseClass + '--size-' + size);
  }

  if (look && look !== auto) {
    classNames.push(baseClass + '--' + props.look);
  }

  if (className) {
    classNames.push(className);
  }

  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <div className={classNames.join(' ')} {...attributes}/>
  )

};

TaTable.propTypes = {
  size: PropTypes.oneOf(['full', auto]),
  scroll: PropTypes.oneOf(['x', 'y', auto, 'hidden']),
  flex: PropTypes.oneOfType([
    PropTypes.oneOf([auto]),
    PropTypes.number
  ]),
};
