import { delay } from 'redux-saga';
import { takeLatest, call, put, fork } from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { hide } from 'redux-modal';
import { reset, stopSubmit } from 'redux-form';
import { normalize } from 'normalizr';
import API from 'services/ClientApi';
import * as actions from 'actions/clients';
import * as schemas from 'schemas/clients';
import * as actionTypes from 'actionTypes/clients';
import * as entityActions from 'actions/entities';
import * as listActions from 'actions/lists';
import * as coreSagas from 'sagas/core';
import * as constants from 'misc/constants';
import { closeCurrentTab } from 'sagas/globalTabs';
import { clientReferenceSchema } from 'schemas/documents';
import { wordlistSchema } from 'schemas/wordlists';
import { setLoading } from 'actions/loading';

function* list(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.CLIENT_LIST, true));
  try {
    const response = yield call(API.list, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data.results), [schemas.clientSchema]);
    const totalCount = response.data.count ? response.data.count : 1;
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setPaginationResult(
      constants.CLIENT_LIST,
      params.page,
      normalized.result,
      totalCount
    ));
    yield put(setLoading(constants.CLIENT_LIST, false));
  } catch (error) {
    yield put(actions.listFailure(error));
    yield put(setLoading(constants.CLIENT_LIST, false));
  }
}

// Create ======================================================================
function* create(action) {
  const { form, values } = action.payload;
  yield put(setLoading(constants.CLIENT_CREATE, true));
  try {
    const response = yield call(API.create, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.clientSchema);
    yield put(entityActions.merge(normalized.entities));
    yield fork(closeCurrentTab);
    yield fork(coreSagas.successNotification, 'Client created successfully.');
    yield put(setLoading(constants.CLIENT_CREATE, false));
  } catch (error) {
    yield put(actions.createFailure(error));
    if (error.response.status === 400) {
      if (error.response.data.name) {
        yield put(stopSubmit(form, { name: 'Client with this short name already exists.' }));
      }
    }
    yield put(setLoading(constants.CLIENT_CREATE, false));
  }
}

// Retrieve ====================================================================
function* retrieve(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.CLIENT_DETAIL}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.retrieve, id);
    const normalized = normalize(camelizeKeys(response.data), schemas.clientSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.retrieveFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Update ======================================================================
function* update(action) {
  const { form, id, values } = action.payload;
  const loadingKey = `${constants.CLIENT_EDIT}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.update, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.clientSchema);
    yield put(entityActions.merge(normalized.entities));
    yield fork(coreSagas.successNotification, 'Client information updated successfully.');
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.updateFailure(error));
    if (error.response.status === 400) {
      if (error.response.data.name) {
        yield put(stopSubmit(form, { name: 'Client with this short name already exists.' }));
      }
    }
    yield put(setLoading(loadingKey, false));
  }
}

// Remove ======================================================================
function* remove(action) {
  const { item } = action.payload;
  const message = `Are you sure to delete ${item.name} ?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      yield call(API.remove, item.id);
      yield put(listActions.removePaginationItem(constants.CLIENT_LIST, item.id));
      yield fork(closeCurrentTab);
      yield fork(coreSagas.successNotification, 'Client deleted successfuly.');

      yield put(entityActions.remove('clients', item.id));
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.removeFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

// Auto complete ===============================================================
function* autocomplete(action) {
  const { q } = action.payload;
  yield put(setLoading(constants.CLIENT_AUTO_COMPLETE, true));
  yield call(delay, 700);
  try {
    const response = yield call(API.autocomplete, { q });
    const normalized = normalize(camelizeKeys(response.data), [schemas.clientSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(constants.CLIENT_AUTO_COMPLETE, false));
  } catch (error) {
    yield put(actions.autocompleteFailure(error));
    yield put(setLoading(constants.CLIENT_AUTO_COMPLETE, false));
  }
}

// Preferable user list ========================================================
function* preferableUserList(action) {
  const { clientId } = action.payload;
  const loadingKey = `${constants.CLIENT_PREFERABLE_USER}/${clientId}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.preferableUserList, clientId);
    const normalized = normalize(camelizeKeys(response.data), [schemas.preferableUserSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setNestedResult(constants.CLIENT_PREFERABLE_USER, clientId, normalized.result))
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.preferableUserListFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}


// Preferable user add =========================================================
function* preferableUserAdd(action) {
  yield put(setLoading(constants.CLIENT_USER_PREFERENCE_MODAL, true));
  try {
    const { clientId, values } = action.payload;
    const response = yield call(API.preferableUserAdd, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.preferableUserSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addNestedItem(
      constants.CLIENT_PREFERABLE_USER,
      clientId,
      normalized.result
    ));
    yield put(hide(constants.CLIENT_USER_PREFERENCE_MODAL));
    yield put(reset(constants.CLIENT_USER_PREFERENCE_FORM));
    yield fork(coreSagas.successNotification, 'Preferable user added successfully.');
    yield put(setLoading(constants.CLIENT_USER_PREFERENCE_MODAL, false));
  } catch (error) {
    yield put(actions.preferableUserAddFailure(error));
    if (error.response.status === 400) {
      if (error.response.data.non_field_errors) {
        yield put(stopSubmit(
          constants.CLIENT_USER_PREFERENCE_FORM,
          { user: 'Sorry, that user already exists.' }
        ));
      }
    }
    yield put(setLoading(constants.CLIENT_USER_PREFERENCE_MODAL, false));
  }
}

// Preferable user remove ======================================================
function* preferableUserRemove(action) {
  const { preference } = action.payload;
  const message = `Are you sure to delete ${preference.user.username} ?`
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true));
      yield call(API.preferableUserRemove, preference.id);
      yield put(listActions.removeNestedItem(
        constants.CLIENT_PREFERABLE_USER,
        preference.client.id,
        preference.id
      ));
      yield put(entityActions.remove('preferableUsers', preference.id));
      yield put(hide(constants.CONFIRM_MODAL));
      yield fork(coreSagas.successNotification, 'Preferable user deleted successfully.');
      yield put(setLoading(constants.CONFIRM_MODAL, false));
    } catch (error) {
      yield put(actions.preferableUserRemoveFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false));
    }
  }
}

// NG user list ================================================================
function* ngUserList(action) {
  const { clientId } = action.payload;
  const loadingKey = `${constants.CLIENT_NG_USER}/${clientId}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.ngUserList, clientId);
    const normalized = normalize(camelizeKeys(response.data), [schemas.ngUserSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setNestedResult(
      constants.CLIENT_NG_USER,
      clientId,
      normalized.result
    ))
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.ngUserListFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}


// NG user add =================================================================
function* ngUserAdd(action) {
  yield put(setLoading(constants.CLIENT_USER_PREFERENCE_MODAL, true));
  try {
    const { clientId, values } = action.payload;
    const response = yield call(API.ngUserAdd, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.ngUserSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addNestedItem(
      constants.CLIENT_NG_USER,
      clientId,
      normalized.result
    ));
    yield put(hide(constants.CLIENT_USER_PREFERENCE_MODAL));
    yield put(reset(constants.CLIENT_USER_PREFERENCE_FORM));
    yield fork(coreSagas.successNotification, 'NG user added successfully.');
    yield put(setLoading(constants.CLIENT_USER_PREFERENCE_MODAL, false));
  } catch (error) {
    yield put(actions.ngUserAddFailure(error));
    if (error.response.status === 400) {
      if (error.response.data.non_field_errors) {
        yield put(stopSubmit(
          constants.CLIENT_USER_PREFERENCE_FORM,
          { user: 'Sorry, that user already exists.' }
        ));
      }
    }
    yield put(setLoading(constants.CLIENT_USER_PREFERENCE_MODAL, false));
  }
}

// NG user remove ======================================================
function* ngUserRemove(action) {
  const { preference } = action.payload;
  const message = `Are you sure to delete ${preference.user.username} ?`
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true));
      yield call(API.ngUserRemove, preference.id);
      yield put(listActions.removeNestedItem(
        constants.CLIENT_NG_USER,
        preference.client.id,
        preference.id
      ));
      yield put(entityActions.remove('ngUsers', preference.id));
      yield put(hide(constants.CONFIRM_MODAL));
      yield fork(coreSagas.successNotification, 'NG user removed successfully.');
      yield put(setLoading(constants.CONFIRM_MODAL, false));
    } catch (error) {
      yield put(actions.ngUserRemoveFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false));
    }
  }
}

// Website list ========================================================
function* websiteList(action) {
  const { clientId } = action.payload;
  const loadingKey = `${constants.CLIENT_WEBSITE}/${clientId}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.websiteList, clientId);
    const normalized = normalize(camelizeKeys(response.data), [schemas.websiteSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setNestedResult(constants.CLIENT_WEBSITE, clientId, normalized.result))
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.websiteListFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Website add =================================================================
function* websiteAdd(action) {
  yield put(setLoading(constants.CLIENT_WEBSITE_MODAL, true));
  try {
    const { clientId, values } = action.payload;
    const response = yield call(API.websiteAdd, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.websiteSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addNestedItem(
      constants.CLIENT_WEBSITE,
      clientId,
      normalized.result
    ));
    yield put(hide(constants.CLIENT_WEBSITE_MODAL));
    yield put(reset(constants.CLIENT_WEBSITE_FORM));
    yield fork(coreSagas.successNotification, 'Website added successfully.');
    yield put(setLoading(constants.CLIENT_WEBSITE_MODAL, false));
  } catch (error) {
    yield put(actions.websiteAddFailure(error));
    if (error.response.status === 400) {
      if (error.response.data.non_field_errors) {
        yield put(stopSubmit(
          constants.CLIENT_WEBSITE_FORM,
          { url: 'Sorry, that URL already exists.' }
        ));
      }
    }
    yield put(setLoading(constants.CLIENT_WEBSITE_MODAL, false));
  }
}

// Website remove ========================================================
function* websiteRemove(action) {
  const { website } = action.payload;
  const message = `Are you sure to delete ${website.url} ?`
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true));
      yield call(API.websiteRemove, website.id);
      yield put(listActions.removeNestedItem(
        constants.CLIENT_WEBSITE,
        website.client.id,
        website.id
      ));
      yield put(entityActions.remove('websites', website.id));
      yield put(hide(constants.CONFIRM_MODAL));
      yield fork(coreSagas.successNotification, 'Website removed successfully.');
      yield put(setLoading(constants.CONFIRM_MODAL, false));
    } catch (error) {
      yield put(actions.websiteRemoveFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false));
    }
  }
}


// Common Reference List =======================================================
function* commonReferenceList(action) {
  const { clientId } = action.payload;
  const loadingKey = `${constants.CLIENT_COMMON_REFERENCE}/${clientId}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.commonReferenceList, clientId);
    const normalized = normalize(camelizeKeys(response.data), [clientReferenceSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setNestedResult(
      constants.CLIENT_COMMON_REFERENCE,
      clientId,
      normalized.result
    ));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.commonReferenceListFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// WordList ====================================================================
function* wordlist(action) {
  const { clientId } = action.payload;
  try {
    const response = yield call(API.wordlist, clientId);
    const normalized = normalize(camelizeKeys(response.data), [wordlistSchema]);
    yield put(entityActions.merge(normalized.entities));
  } catch (error) {
    yield put(actions.wordlistFailure(error));
  }
}

function* watchCreate() {
  yield takeLatest(actionTypes.CREATE.REQUEST, create);
}
function* watchList() {
  yield takeLatest(actionTypes.LIST.REQUEST, list);
}
function* watchRetrieve() {
  yield takeLatest(actionTypes.RETRIEVE.REQUEST, retrieve);
}
function* watchUpdate() {
  yield takeLatest(actionTypes.UPDATE.REQUEST, update);
}
function* watchRemove() {
  yield takeLatest(actionTypes.REMOVE.REQUEST, remove);
}
function* watchAutoComplete() {
  yield takeLatest(actionTypes.AUTOCOMPLETE.REQUEST, autocomplete);
}
function* watchPreferableUserList() {
  yield takeLatest(actionTypes.PREFERABLE_USER_LIST.REQUEST, preferableUserList);
}
function* watchPreferableUserAdd() {
  yield takeLatest(actionTypes.PREFERABLE_USER_ADD.REQUEST, preferableUserAdd);
}
function* watchPreferableUserRemove() {
  yield takeLatest(actionTypes.PREFERABLE_USER_REMOVE.REQUEST, preferableUserRemove);
}
function* watchNgUserList() {
  yield takeLatest(actionTypes.NG_USER_LIST.REQUEST, ngUserList);
}
function* watchNgUserAdd() {
  yield takeLatest(actionTypes.NG_USER_ADD.REQUEST, ngUserAdd);
}
function* watchNgUserRemove() {
  yield takeLatest(actionTypes.NG_USER_REMOVE.REQUEST, ngUserRemove);
}
function* watchWebsiteList() {
  yield takeLatest(actionTypes.WEBSITE_LIST.REQUEST, websiteList);
}
function* watchWebsiteAdd() {
  yield takeLatest(actionTypes.WEBSITE_ADD.REQUEST, websiteAdd);
}
function* watchWebsiteRemove() {
  yield takeLatest(actionTypes.WEBSITE_REMOVE.REQUEST, websiteRemove);
}
function* watchCommonReferenceList() {
  yield takeLatest(actionTypes.COMMON_REFERENCE_LIST.REQUEST, commonReferenceList);
}
function* watchWordList() {
  yield takeLatest(actionTypes.WORDLIST.REQUEST, wordlist);
}


export {
  watchCreate,
  watchList,
  watchRetrieve,
  watchUpdate,
  watchRemove,
  watchAutoComplete,
  watchPreferableUserList,
  watchPreferableUserAdd,
  watchPreferableUserRemove,
  watchNgUserList,
  watchNgUserAdd,
  watchNgUserRemove,
  watchWebsiteList,
  watchWebsiteAdd,
  watchWebsiteRemove,
  watchCommonReferenceList,
  watchWordList
}