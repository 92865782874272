import {
  SET_LANGUAGE_COLUMNS,
  SET_LANGUAGE_COLUMN,
  SET_DEFAULT_LANGUAGE_COLUMNS,
  RESET_COLUMN
} from "../actionTypes/glossaryUi";
import {LANGUAGE_OPTIONS} from "../misc/config";

const initialLanguageState = {
  "visible": true,
  "order": 0
}

const initialVisibleLanguageList = ['ja-JP', 'en-US']

export function getInitialState() {
  let state = {
    updated: 0,
    columns: {}
  }
  LANGUAGE_OPTIONS.forEach(lang => {
    state.columns[lang.key] = {
      ...initialLanguageState,
      visible: initialVisibleLanguageList.includes(lang.value)
    };
  })
  return state
}

export default function (state = getInitialState(), action) {
  switch (action.type) {
    case SET_LANGUAGE_COLUMNS:
      return setLanguageColumns(state, action);
    case SET_LANGUAGE_COLUMN:
      return setLanguageColumn(state, action);
    case SET_DEFAULT_LANGUAGE_COLUMNS:
      return {};
    case RESET_COLUMN:
      return resetColmun(state, action);
    default:
      return state;
  }
}

const setLanguageColumns = (state, action) => {
  const {
    glossaryKey,
    columns
  } = action.payload;


  let newState = {
    ...state
  }
  newState[glossaryKey] = {
    ...state[glossaryKey] || getInitialState(),
    columns: columns || getInitialState().columns,
    updated: Date.now()
  };
  return newState
}

const setLanguageColumn = (state, action) => {
  const {
    glossaryKey,
    languageKey,
    column
  } = action.payload;
  let newState = {
    ...state
  }
  newState[glossaryKey] = {
    ...state[glossaryKey] || getInitialState(),
    updated: Date.now()
  };
  newState[glossaryKey].columns = {
    ...state[glossaryKey].columns,
    [languageKey]: column || {...initialLanguageState}
  }
  return newState
}

const resetColmun = (state, action) => {
  const { glossaryKey } = action.payload;
  const { [glossaryKey]: _, ...newState } = state;
  return newState
}