import React from 'react';
import { Content, Box } from 'reactjs-admin-lte';
import Pagination from 'components/core/Pagination';
import CustomerListFilter from './CustomerListFilter';
import CustomerListTable from './CustomerListTable';


const CustomerList = (props) => {

  const {
    customers,
    pageSize,
    currentPage,
    isLoading,
    keyword,
    ae,
    aeOptions,
    client,
    handleAEChange,
    handleClientChange,
    handleKeywordChange,
    handleSubmit,
    handleTabOpen,
    handlePageSelect
  } = props;

  console.log(customers)

  return (
    <Content.Body>
      <CustomerListFilter
        keyword={keyword}
        ae={ae}
        aeOptions={aeOptions}
        client={client}
        handleAEChange={handleAEChange}
        handleClientChange={handleClientChange}
        handleKeywordChange={handleKeywordChange}
        handleSubmit={handleSubmit} />
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body noPadding={true}>
          <CustomerListTable
            customers={customers}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
        <Box.Footer>
          <Pagination
            currentPage={currentPage}
            totalPages={pageSize}
            onChange={handlePageSelect} />
        </Box.Footer>
      </Box>
    </Content.Body>
  )
}

export default CustomerList;