import React from 'react';
import { Content } from 'reactjs-admin-lte';
import JobDraftEditForm from './JobDraftEditForm';

const JobDraftEdit = (props) => {
  return (
    <Content.Body>
      <JobDraftEditForm {...props} />
    </Content.Body>
  );
}

export default JobDraftEdit;