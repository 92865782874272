import React from 'react';
import { Box } from 'reactjs-admin-lte';
import { Table } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import Rating from 'react-rating';

const UserSkill = (props) => {

  const {
    isLoading,
    skills,
    handleChange,
    hasHeader,
  } = props;

  const renderRow = () => {
    return skills.map(row => {
      return (
        <React.Fragment key={row.type}>
          <tr className="active">
            <td><strong>{row.type}</strong></td>
            <td></td>
          </tr>
          {row.children.map(child => (
            <tr key={child.serviceId}>
              <td>{child.serviceName}</td>
              <td>
                <FontAwesome
                  name="ban"
                  className="mr-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleChange(
                    child.skillId,
                    child.serviceId,
                    0
                  )} />
                <Rating
                  emptySymbol="fa fa-star-o"
                  fullSymbol="fa fa-star"
                  initialRating={child.level}
                  onChange={rate => handleChange(
                    child.skillId,
                    child.serviceId,
                    rate
                  )} />
                </td>
            </tr>
          ))}
      </React.Fragment>
    )});
  }

  return (
    <Box
      className="box-widget mb-0"
      loading={isLoading}>
      { hasHeader &&
        <Box.Header border={true}>
          <Box.Title>Skill</Box.Title>
          <Box.Tools>
            <Box.CollapsedToggleButton />
          </Box.Tools>
        </Box.Header>
      }
      <Box.Body noPadding={true}>
        <Table>
          <thead>
            <tr>
              <th>Service</th>
              <th width="20%">Level</th>
            </tr>
          </thead>
          <tbody>
            {renderRow()}
          </tbody>
        </Table>
      </Box.Body>
    </Box>
  )
}

export default UserSkill;