import React from 'react';
import { NavItem } from 'react-bootstrap';
import CountTabTitle from 'components/core/CountTabTitle';


const InvoiceListTab = (props) => {
  
  const {
    eventKey,
    activeKey,
    onSelect,
    title,
    isLoading,
    invoices
  } = props;

  return (
    <NavItem
      active={eventKey === activeKey}
      eventKey={eventKey}
      onSelect={onSelect}>
      <CountTabTitle
        title={title}
        isLoading={isLoading}
        count={invoices.length} />
    </NavItem>
  );
}

export default InvoiceListTab;
