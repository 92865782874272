import React from 'react';
import PureRenderComponent from 'components/core/PureRenderComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getTermsPageSize,
  getTermsCurrentPage,
  getTerms
} from 'selectors/lists';
import { isTermSearchLoading } from 'selectors/loading';
import { setTabTitle } from 'actions/globalTabs';
import { searchRequest } from 'actions/terms';
import { clearPaginationResult } from 'actions/lists';
import { TERM_SEARCH } from 'misc/constants';
import TermSearch from './presenter';


class TermSearchContainer extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = { keyword: '' }
  }

  componentWillUnmount() {
    this.props.clearPaginationResult(TERM_SEARCH)
  }

  handleKeywordChange = (keyword) => {
    this.setState({ keyword })
  }

  handlePageSelect = (page) => {
    const params = { keyword: this.state.keyword, page };
    this.props.searchRequest(params);
  }

  handleSubmit = () => {
    this.props.setTabTitle(TERM_SEARCH, `Term Result: ${this.state.keyword}`);
    this.props.searchRequest({
      keyword: this.state.keyword,
      page: 1
     });
  }

  render() {
    return (
      <TermSearch
        {...this.props}
        keyword={this.state.keyword}
        handleSubmit={this.handleSubmit}
        handlePageSelect={this.handlePageSelect}
        handleKeywordChange={this.handleKeywordChange}
        handleDetailTabOpen={this.handleDetailTabOpen} />
    )
  }
}

const mapStateToProps = (state) => {
  const { terms } = getTerms(state)
  return {
    terms,
    pageSize: getTermsPageSize(state),
    currentPage: getTermsCurrentPage(state),
    isLoading: isTermSearchLoading(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    setTabTitle,
    searchRequest,
    clearPaginationResult
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermSearchContainer);
