import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Content } from 'reactjs-admin-lte';
import JobMessage from 'components/jobs/common/JobMessage';
import JobEditForm from './JobEditForm';


const JobEdit = (props) => {
  const { 
    id,
    myUserId,
  } = props;
  
  return (
    <Content.Body>
      <Row>
        <Col lg={9}><JobEditForm {...props} /></Col>
        <Col lg={3}>
        <JobMessage
          jobId={id}
          roleKey="coordinator"
          isCoordinator={true}
          isReceiverHidden={false}
          myUserId={myUserId} />
        </Col>
      </Row>
    </Content.Body>
  );
}

export default JobEdit;
