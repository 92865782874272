import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { quoteSchema, unsupportedOrderSchema } from 'schemas/quotes';
import { getEntities } from 'selectors/entities';
import * as coreSelectors from 'selectors/core';


export const getQuoteValueById = createSelector(
  [coreSelectors.getId, getEntities],
  (id, entities) => denormalize(id, quoteSchema, entities)
)


export const getUnsupportedOrderValueById = createSelector(
  [coreSelectors.getId, getEntities],
  (id, entities) => denormalize(id, unsupportedOrderSchema, entities)
)

