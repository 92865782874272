import React from 'react';
import { Content } from 'reactjs-admin-lte';
import JobSplitIssueForm from './JobSplitIssueForm';


const JobSplitIssue = (props) => {  
  return (
    <Content.Body>
      <JobSplitIssueForm {...props} />
    </Content.Body>
  );
}

export default JobSplitIssue;