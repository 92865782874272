import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { show } from 'redux-modal';
import { getDetailTabConfigByName } from 'misc/utils';
import {
  TEMPLATE_CREATE_MODAL, JOB_PROCESS_MODAL, JOB_DUPLICATE,
  JOB_SPLIT, JOB_SPLIT_ISSUE, JOB_EDIT
} from 'misc/constants';
import { ASSIGNMENT_ROLE_KEY_MAP, EMPLOYEE_STATUS_MAP } from 'misc/config';
import { retrieveRequest, memberListRequest, printRequest, cancelRequest } from 'actions/jobs/managers';
import { getJobById, getJobValueById, getAssignmentsByJobId } from 'selectors/jobs';
import { isJobDetailLoading } from 'selectors/loading';
import { isCoordinator, isAE, getMyUserId } from 'selectors/auth';
import { getJobManullyProcessOptionsByJobId} from 'selectors/options';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobDetail from './presenter';

class JobDetailContainer extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = { selectedMember: null };
  }

  componentDidMount() {
    this.props.retrieveRequest(this.props.id);
  }

  handlePrint = () => {
    this.props.printRequest(this.props.id);
  }

  handleProcess = (status) => {
    if (status.value === 'Job Canceled') {
      this.props.cancelRequest(this.props.job);
    } else {
      this.showProcessModal(status);
    }
  }

  getNextStatus = () => {
    const currentStatus = this.props.job.status;
    const newAssignments = this.props.assignments.filter(
      assignment => assignment.status === 'Created'
    );

    const defaultStatus = {
      label: 'AE Validating',
      value: 'AE Validating',
      meta: null
    }

    if (newAssignments.length === 0) {
      return defaultStatus;
    }

    const li = newAssignments.length;

    for (let i = 0; i < li; i++) {
      const role = newAssignments[i]['role']['name'];
      const roleKey = ASSIGNMENT_ROLE_KEY_MAP[role];
      if (currentStatus.includes(role)) {
        continue;
      }
      const status = EMPLOYEE_STATUS_MAP[roleKey];
      return {
        label: status.sent,
        value: status.sent,
        meta: roleKey
      }
    }
    return defaultStatus;
  }

  showProcessModal = (status) => {
    const params = this.prepareProcessParams(status);
    this.props.show(JOB_PROCESS_MODAL, params);
  }

  prepareProcessParams = (status) => {
    const sentTo = status.value.startsWith('Sent To');
    const validate = status.value.startsWith('Validating');

    const { job } = this.props;
    const params = {
      jobId: job.id,
      status: status.value,
      showAssignment: false
    };

    if (sentTo || validate) {
      const assignment = job[status.meta];
      params['assignmentId'] = assignment.id;

      if (sentTo) {
        const includesAdd = job.additionalCountRoles.findIndex(
          role => role.id === assignment.role.id
        ) !== -1;

        params['showAssignment'] = true;
        params['assigneeName'] = assignment.assignee.username;
        params['assigneeEmail'] = assignment.assignee.email;
        params['deadline'] = assignment.deadline;
        params['isStrictDeadline'] = assignment.strictDeadline;
        params['sourceCount'] = job.sourceCount;
        params['targetCount'] = job.targetCount;
        params['additionalCount'] = includesAdd ? job.additionalCount : 0;
      }
    }
    return params;
  }

  showJobTemplateModal = () => {
    this.props.show(TEMPLATE_CREATE_MODAL, {
      job: this.props.jobFormValue
    })
  }

  getTabConfig = (tab) => {
    const tabConfig = getDetailTabConfigByName(
      tab,
      this.props.id,
      this.props.job.number,
      { id: this.props.id }
    );
    return tabConfig;
  }

  handleDuplicateTabOpen = () => {
    const tabConfig = this.getTabConfig(JOB_DUPLICATE);
    this.props.handleTabOpen(tabConfig)
  }

  handleSplitTabOpen = () => {
    const tabConfig = this.getTabConfig(JOB_SPLIT);
    this.props.handleTabOpen(tabConfig)
  }
  handleSplitIssueTabOpen = () => {
    const tabConfig = this.getTabConfig(JOB_SPLIT_ISSUE);
    this.props.handleTabOpen(tabConfig)
  }

  handleEditTabOpen = () => {
    const tabConfig = this.getTabConfig(JOB_EDIT);
    this.props.handleTabOpen(tabConfig)
  }

  getRoleKey = () => {
    if (this.props.isCoordinator) {
      return 'coordinator'
    } else if (this.props.isAE) {
      return 'ae'
    } else {
      return
    }
  }

  render() {
    const roleKey = this.getRoleKey()
    const isMHTCreatable = (
      this.props.job &&
      this.props.job.service &&
      this.props.job.service.isMhtApproved
    )

    return (
      <JobDetail
        {...this.props}
        roleKey={roleKey}
        isMHTCreatable={isMHTCreatable}
        handlePrint={this.handlePrint}
        showProcessModal={this.showProcessModal}
        showJobTemplateModal={this.showJobTemplateModal}
        handleProcess={this.handleProcess}
        handleManuallyProcess={this.handleManuallyProcess}
        handleDuplicateTabOpen={this.handleDuplicateTabOpen}
        handleSplitTabOpen={this.handleSplitTabOpen}
        handleSplitIssueTabOpen={this.handleSplitIssueTabOpen}
        handleEditTabOpen={this.handleEditTabOpen}
        getNextStatus={this.getNextStatus} />
    )
  }
}

const mapStateToProps = (state, props) => {
  const id = props.params.id;
  return {
    id,
    job: getJobValueById(state, id),
    isLoading: isJobDetailLoading(state, id),
    jobFormValue: getJobById(state, id),
    assignments: getAssignmentsByJobId(state, id),
    manuallyProcessOptions: getJobManullyProcessOptionsByJobId(state, id),
    isCoordinator: isCoordinator(state),
    isAE: isAE(state),
    myUserId: getMyUserId(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    retrieveRequest,
    cancelRequest,
    memberListRequest,
    printRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobDetailContainer);
