import React from 'react';
import { Field } from 'redux-form';
import { SCHEDULE_TIMES } from 'misc/config'
import SelectField from 'components/core/SelectField';
import CheckboxField from 'components/core/CheckboxField';


const UserScheduleField = (props) => {

  const {
    label,
    input,
  } = props;
  
  return (
    <tr>
      <td>{label}</td>
      <td>
        <Field
          name={`${input.name}.start`}
          options={SCHEDULE_TIMES}
          component={SelectField} />
      </td>
      <td>
        <Field
          name={`${input.name}.end`}
          options={SCHEDULE_TIMES}
          component={SelectField} />
      </td>
      <td className="text-center">
        <Field
          name={`${input.name}.isAvailable`}
          component={CheckboxField} />
      </td>
    </tr>
  )
}

export default UserScheduleField;