import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { retrieveRequest, updateRequest } from 'actions/customers';
import { getCustomerEditFormInitialValues } from 'selectors/forms';
import { CUSTOMER_EDIT_FORM } from 'misc/constants';
import PureRenderComponent from 'components/core/PureRenderComponent';
import CustomerDetail from './presenter';


class CustomerDetailContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.retrieveRequest(this.props.id);
  }
  
  handleUpdate = (values) => {
    this.props.updateRequest(
      this.props.form,
      this.props.id,
      values,
    )
  }

  render() {
    return (
      <CustomerDetail
        {...this.props}
        handleUpdate={this.handleUpdate} />
    )
  }
}

const mapStateToProps = (state, props) => {
  const id = props.params.id;
  const form = `${CUSTOMER_EDIT_FORM}/${id}`;
  return {
    id,
    form,
    initialValues: getCustomerEditFormInitialValues(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    retrieveRequest,
    updateRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDetailContainer);
