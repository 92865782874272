function delay(payload, time) {

  let timeout;
  let promise = new Promise((resolve, reject) => {
    timeout = setTimeout(() => {
      resolve(payload);
    }, time || 1000);
  });

  return {
    promise:promise,
    cancel:function(){clearTimeout(timeout );} //return a canceller as well
  }

}
export default delay;
