import React from "react";
import * as PropTypes from 'prop-types';
import './taTable.css';

export const TaTableRows = React.forwardRef((props, ref) => {

  const {
    scroll,
    ...attributes
  } = props;

  const baseClass = 'ta-table-body__rows';
  let classNames = [baseClass];
  if (scroll && scroll !== 'hidden') {
    classNames.push(baseClass+'--scroll-'+scroll)
  }

  return (
    <div
      ref={ref}
      className={classNames.join(' ')}
      {...attributes}
    />
  )
});
TaTableRows.propTypes = {
  scroll: PropTypes.oneOf(['x', 'y', 'auto', 'hidden'])
};
