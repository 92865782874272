import { createSelector } from 'reselect';

export const getTabs = state => state.globalTabs.tabs;
export const getCurrentTab = state => state.globalTabs.currentTab;

export const getCurrentTabParams = createSelector(
  [getTabs, getCurrentTab],
  (tabs, currentTab) => {
    const tab = tabs.find(tab => tab.id === currentTab);
    return (tab && tab.params) ? tab.params : {}
  }
)

export const isRefreshableTab = createSelector(
  [getTabs, getCurrentTab],
  (tabs, currentTab) => {
    const tab = tabs.find(tab => tab.id === currentTab);
    return tab && tab.allowRefresh
  }
)


export const isConfirmRequiredTab = createSelector(
  [getTabs, getCurrentTab],
  (tabs, currentTab) => {
    const tab = tabs.find(tab => tab.id === currentTab);
    return tab && tab.requireCloseConfirm
  }
)