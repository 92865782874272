import { createSelector } from 'reselect';
import * as constants from 'misc/constants';

// Job Schedule ================================================================
export const getJobScheduleCurrentDate = state => (
  state.filters[constants.JOB_SCHEDULE].currentDate
)
export const getJobScheduleViewAs = state => (
  state.settings.viewAs
)
export const getJobScheduleDeadline = state => (
  state.filters[constants.JOB_SCHEDULE].deadline
)
export const getJobScheduleAssignee = state => (
  state.filters[constants.JOB_SCHEDULE].assignee
)
export const isEmployeeSelected = createSelector(
  [getJobScheduleDeadline],
  (deadline) => ['translator', 'editor', 'checker', 'totra', 'dtp', 'second_dtp'].includes(deadline)
)
export const isFESchedule = createSelector(
  [getJobScheduleViewAs, getJobScheduleDeadline],
  (viewAs, deadline) => (
    viewAs === 'coordinator' &&
    deadline === 'final_editor'
  )
)

// Calendar ====================================================================
export const getCalendarVisibleRange = state => (
  state.filters[constants.CALENDAR].visibleRange
)
export const getCalendarGroup = state => (
  state.filters[constants.CALENDAR].group
)

// Client List =================================================================
export const getClientListKeyword = state => (
  state.filters[constants.CLIENT_LIST].keyword
)

// User List ====================================================================
export const getUserListKeyword = state => (
  state.filters[constants.USER_LIST].keyword
)
export const getUserListGroup = state => (
  state.filters[constants.USER_LIST].group
)
export const getUserListService = state => (
  state.filters[constants.USER_LIST].service
)

// Preparing List ==============================================================
export const getPreparingListCoodrinator = state => (
  state.filters[constants.PREPARING_LIST].coordinator
)

// Employee Job Schedule =======================================================
export const getEmployeeJobScheduleCurrentDate = state => (
  state.filters[constants.EMPLOYEE_JOB_SCHEDULE].currentDate
)

// Sales Report ================================================================
export const getSalesReportCurrentMonth = state => (
  state.filters[constants.SALES_REPORT].currentMonth
)

export const getInvoiceListCurrentMonth = state => (
  state.filters[constants.INVOICE_LIST].currentMonth
)

// Sales Target
export const getSalesTargetCurrentYear = state => (
  state.filters[constants.SALES_TARGET].currentYear
)

// WordList ====================================================================
export const getWordListClient = state => (
  state.filters[constants.WORDLIST].client
)
export const getWordListKeyword = state => (
  state.filters[constants.WORDLIST].keyword
)

// Glossary ====================================================================
export const getGlossaryCategory = state => (
  state.filters[constants.GLOSSARY_LIST].category
)