import React from 'react';
import { LinkFieldCellRenderer, EmployeeCurrentJobsStatusCellRenderer } from 'misc/cellRenderers';
import { getFieldTotalCount } from 'misc/utils';
import { TRANSLATOR_JOB_DETAIL } from 'misc/constants';
import { datetimeFormatter } from 'misc/cellFormatters';
import ListTable from 'components/core/ListTable';
import PureRenderComponent from 'components/core/PureRenderComponent';



class TranslatorCurrentJobsTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      sourceSum: 0,
      targetSum: 0,
      columnDefs: [
        {
          headerName: 'No.',
          field: 'number',
          pinned: 'left',
          cellClass: 'ag-jobNumber',
          width: 140,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: TRANSLATOR_JOB_DETAIL,
          },
        },
        {
          headerName: 'Deadline',
          field: 'translator.deadline',
          width: 140,
          valueFormatter: datetimeFormatter,
        },
        {
          headerName: 'Source',
          field: 'sourceCount',
          filter: 'agNumberColumnFilter',
          width: 90,
        },
        {
          headerName: 'Target',
          field: 'targetCount',
          filter: 'agNumberColumnFilter',
          width: 90,
        },
        {
          headerName: 'Subject',
          field: 'subject',
          width: 350,
        },
        {
          headerName: 'Service',
          field: 'service.name',
          width: 80,
        },
        {
          headerName: 'Lv',
          field: 'level',
          width: 40,
        },
        {
          headerName: 'Coordinator',
          field: 'coordinator.name',
          width: 120,
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 140,
          pinned: 'right',
          cellRendererFramework: EmployeeCurrentJobsStatusCellRenderer,
          cellRendererParams: {
            sentToStatus: this.props.sentToStatus,
          },
        }
      ]
    }
  }

  componentDidMount() {
    this.setState({
      sourceSum: getFieldTotalCount(this.props.jobs, 'sourceCount'),
      targetSum: getFieldTotalCount(this.props.jobs, 'targetCount'),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.jobs !== this.props.jobs) {
      this.setState({
        sourceSum: getFieldTotalCount(this.props.jobs, 'sourceCount'),
        targetSum: getFieldTotalCount(this.props.jobs, 'targetCount'),
      })
    }
  }

  getPinnedBottomRowData = () => ([{
    'sourceCount': this.state.sourceSum,
    'targetCount': this.state.targetSum,
  }])

  handleFilterChanged = (event) => {
    let sourceSum = 0;
    let targetSum = 0;
    event.api.forEachNodeAfterFilter(node => {
      sourceSum += node.data.sourceCount;
      targetSum += node.data.targetCount;
    });
    this.setState({ sourceSum, targetSum });
  }

  render() {
    return (
      <ListTable
        columnDefs={this.state.columnDefs}
        rowData={this.props.jobs}
        deltaRowDataMode={true}
        onFilterChanged={this.handleFilterChanged}
        getRowNodeId={data => data.id}
        pinnedBottomRowData={this.getPinnedBottomRowData()} />
    )
  }
}


export default TranslatorCurrentJobsTable;
