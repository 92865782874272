import React from "react";
import * as PropTypes from "prop-types";
import {TaPane} from "components/taUi/taPane/taPane";
import editorStyles from "components/mht/MHTEditor/TaMhtEditor.module.css";
import {TaToolbar} from "components/taUi/taToolbar/taToolbar";
import {TaMenu} from "components/taUi/taMenu/taMenu";
import {TaButton} from "components/taUi/taButton/taButton";
import MHTApi from "services/MHTApi";
import {TaMenuItem} from "../../taUi/taMenu/taMenuItem";

export class TaMhtEditorToolbar extends React.PureComponent {

  static propTypes = {
    userRole: PropTypes.string.isRequired,
    fileStored: PropTypes.bool.isRequired,
    selectionCount: PropTypes.number.isRequired,
    lockedSelectionCount: PropTypes.number.isRequired,
    mutationCount: PropTypes.number.isRequired,
    isEditSource: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    sourceHasFocus: PropTypes.bool.isRequired,
    onToolClick: PropTypes.func.isRequired,
    saveSegments: PropTypes.func.isRequired
  };

  // constructor(props) {
  //   super(props);
  // }
  // componentDidMount() {
  // }
  // componentDidUpdate(prevProps, prevState) {
  // }
  // componentWillUnmount() {
  // }

  render() {

    const {
      status,
      userRole,
      fileStored,
      selectionCount,
      lockedSelectionCount,
      mutationCount,
      isEditSource,
      isSaving,
      sourceHasFocus,
      sourceHasSelection,
      onToolClick,
      saveSegments
    } = this.props;

    const userCanDo = (action) => MHTApi.userRoleCanDo(userRole, action) || null;
    const userRoleCanDoAnyOf = (actions) => MHTApi.userRoleCanDoAnyOf(userRole, actions) || null;

    return (
      <TaPane className={editorStyles.page__tools}>
        <TaToolbar
          wrap={true}
        >
          {
            userRoleCanDoAnyOf([
              'tmSettings',
              'termSettings'
            ]) && (
              <TaMenu
                icon={'settings'}
              >
                {userCanDo('tmSettings') && (
                  <TaMenuItem
                    name={'tmSettings'}
                    children={'Translation Memory Settings'}
                    callback={onToolClick}
                  />
                )}
                {userCanDo('termSettings') && (
                  <TaMenuItem
                    name={'termSettings'}
                    children={'Term Base Settings'}
                    callback={onToolClick}
                  />
                )}
              </TaMenu>
            )}
          {userRoleCanDoAnyOf([
            'sourceFileDownload',
            'commentsDownload',
            'targetFileDownload',
            'bilingualFileDownloadExcel',
            'bilingualFileDownloadTmx',
            'bilingualFileUpload',
            'preTranslate',
            'postTranslate',
            'analyzeFile',
            'reCommitTm'
          ]) && (
            <TaMenu
              icon={'build'}
            >
              {userCanDo('sourceFileDownload') && (
                <TaMenuItem
                  name={'sourceFileDownload'}
                  children={'Download Source File'}
                  callback={onToolClick}
                  disabled={!fileStored}
                />
              )}
              {userCanDo('targetFileDownload') && (
                  <TaMenuItem
                      name={'targetFileDownload'}
                      children={'Download Target File'}
                      callback={onToolClick}
                      disabled={!fileStored}
                  />
              )}
              {userCanDo('bilingualFileDownloadTmx') && (
                  <TaMenuItem
                      name={'bilingualFileDownloadTmx'}
                      children={'Download Bilingual File (TMX)'}
                      callback={onToolClick}
                  />
              )}
              {userCanDo('bilingualFileDownloadExcel') && (
                  <TaMenuItem
                      name={'bilingualFileDownloadExcel'}
                      children={'Download Bilingual File (Excel)'}
                      callback={onToolClick}
                  />
              )}
              {userCanDo('bilingualFileUpload') && (
                  <TaMenuItem
                      name={'bilingualFileUpload'}
                      children={'Upload Bilingual File'}
                      callback={onToolClick}
                  />
              )}
              {userCanDo('commentsDownload') && (
                  <TaMenuItem
                      name={'commentsDownload'}
                      children={'Download Comments'}
                      callback={onToolClick}
                  />
              )}
              {userCanDo('replaceTerms') && (
                  <TaMenuItem
                      name={'replaceTerms'}
                      children={'Replace Term'}
                      callback={onToolClick}
                  />
              )}
              {userCanDo('preTranslate') && (
                  <TaMenuItem
                      name={'preTranslate'}
                      children={'Pre-Translate'}
                      callback={onToolClick}
                  />
              )}
              {userCanDo('postTranslate') && (
                  <TaMenuItem
                      name={'postTranslate'}
                      children={'Post-Translate'}
                      callback={onToolClick}
                  />
              )}
              {userCanDo('analyzeFile') && (
                  <TaMenuItem
                      name={'analyzeFile'}
                      children={'Analyze File'}
                      disabled={(
                          status === 'Invoicing Now' ||
                          status === 'Job Finished'
                      )}
                      callback={onToolClick}
                  />
              )}
              {(
                userCanDo('reCommitTm') &&
                (
                    status === 'Invoicing Now' ||
                    status === 'Job Finished'
                )
              ) && (
                  <TaMenuItem
                      name={'reCommitTm'}
                      children={'Re-commit Translation Memory'}
                      callback={onToolClick}
                  />
              )}
              {userCanDo('confirm') && (
                  <TaMenuItem
                      name={'finish'}
                      children={'Finish MHT job'}
                      callback={onToolClick}
                  />
              )}
            </TaMenu>
          )}
          {(userCanDo('stats')) && (
            <TaButton
              icon={'pie_chart'}
              tooltip={'Count'}
              onClick={() => onToolClick('stats')}
            />
          )}
          {(userCanDo('preview')) && (
            <TaButton
              icon={'visibility'}
              tooltip={'Preview'}
              onClick={() => onToolClick('preview')}
              disabled={!fileStored}
            />
          )}
          {(userCanDo('editSource')) && (
            <TaButton
              icon={'edit'}
              tooltip={(!!isEditSource) ? 'Disable Source Editing' : 'Enable Source Editing'}
              onClick={() => onToolClick('editSource')}
              active={!!isEditSource}
            />
          )}
          {(userCanDo('copy')) && (
            <TaButton
              icon={'text_rotation_none'}
              tooltip={'Copy'}
              onClick={() => onToolClick('copy')}
              disabled={!selectionCount || lockedSelectionCount}
            />
          )}
          {(userCanDo('split')) && (
            <TaButton
              icon={'call_split'}
              tooltip={'Split'}
              onClick={() => onToolClick('split')}
              disabled={!sourceHasFocus || sourceHasSelection}
            />
          )}
          {(userCanDo('merge')) && (
            <TaButton
              icon={'call_merge'}
              tooltip={'Merge'}
              onClick={() => onToolClick('merge')}
              disabled={selectionCount < 2 || lockedSelectionCount}
            />
          )}
          {(userCanDo('lock')) && (
            <TaButton
              icon={'lock'}
              tooltip={'Lock'}
              onClick={() => onToolClick('lock')}
              disabled={!selectionCount}
            />
          )}
          {(userCanDo('confirm')) && (
            <TaButton
              icon={'thumb_up'}
              tooltip={'Confirm'}
              onClick={() => onToolClick('confirm')}
              disabled={!selectionCount || lockedSelectionCount}
            />
          )}
          {(userCanDo('totra')) && (
            <TaButton
              icon={'translate'}
              tooltip={'ToTra'}
              onClick={() => onToolClick('totra')}
              disabled={!selectionCount || lockedSelectionCount}
            />
          )}
          {(userCanDo('machineTranslateSegment')) && (
              <TaButton
                  icon={'save_alt'}
                  tooltip={'Machine Translate Segments'}
                  onClick={() => onToolClick('machineTranslateSegments')}
                  disabled={!selectionCount || lockedSelectionCount}
              />
          )}
          <div className={editorStyles.toolSave}>
            <TaButton
              type="submit"
              icon={'check'}
              label={'Save'}
              disabled={mutationCount === 0}
              onClick={() => saveSegments()}
              isLoading={isSaving}
            />
          </div>
        </TaToolbar>
      </TaPane>
    );

  }
}
