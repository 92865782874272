import React from 'react';
import { Content } from 'reactjs-admin-lte';
import { Tab, Nav } from 'react-bootstrap';
import JobToTraListTab from './JobToTraListTab';
import JobToTraListTabPane from './JobToTraListTabPane';


const JobToTraList = (props) => {
  
  const {
    validatingJobs,
    sentToJobs,
    isLoading,
    currentTab,
    handleTabSelect,
    handleTabOpen
  } = props;
  
  return (
    <Content.Body>
      <Tab.Container
        id="job-totra-list-tab"
        activeKey={currentTab}
        onSelect={handleTabSelect}>
        <div>
          <Nav bsStyle="tabs">
            <JobToTraListTab
              eventKey="validating"
              title="Validating"
              isLoading={isLoading}
              jobs={validatingJobs} />
            <JobToTraListTab
              eventKey="sentTo"
              title="Sent To"
              isLoading={isLoading}
              jobs={sentToJobs} />
          </Nav>
          <Tab.Content animation={false}>
            <JobToTraListTabPane
              eventKey="validating"
              isLoading={isLoading}
              handleTabOpen={handleTabOpen}
              jobs={validatingJobs} />
            <JobToTraListTabPane
              eventKey="sentTo"
              isLoading={isLoading}
              handleTabOpen={handleTabOpen}
              jobs={sentToJobs} />
          </Tab.Content>
        </div>
      </Tab.Container>
    </Content.Body>
  );
}

export default JobToTraList;
