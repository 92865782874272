import * as types from 'actionTypes/globalTabs';


const initialState = {
  tabs: [],
  currentTab: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    // Tabs
    case types.ADD_TAB:
      return addTab(state, action);

    case types.SELECT_TAB:
      return selectTab(state, action);

    case types.CLOSE_TAB:
      return closeTab(state, action);

    case types.REFRESH_TAB:
      return refreshTab(state, action);

    case types.CLEAR_TABS:
      return clearTabs(state, action);

    case types.SET_TAB_TITLE:
      return setTabTitle(state, action);

    case types.SET_CONFIRM_REQUIRE:
      return setConfirmRequire(state, action);

    default:
      return state;
  }
}

const addTab = (state, action) => {
  const newTab = action.payload;
  return {
    ...state,
    currentTab: newTab.id,
    tabs: [
      ...state.tabs,
      newTab
    ]
  }
}

const selectTab = (state, action) => {
  const { id } = action.payload;
  return {
    ...state,
    currentTab: id
  }
}

const closeTab = (state, action) => {
  const { id } = action.payload;
  const restTabs = state.tabs.filter(tab => tab.id !== id);
  return {
    ...state,
    currentTab: null,
    tabs: restTabs
  }
}

const refreshTab = (state, action) => {
  const { key } = action.payload;
  return {
    ...state,
    tabs: state.tabs.map((tab, idx) => {
      if (tab.id !== state.currentTab) return tab;
      return { ...tab, key }
    })
  }
}

const clearTabs = (state, action) => {
  return {
    ...state,
    tabs: [],
    currentTab: null
  }
}

const setTabTitle = (state, action) => {
  const { id, title } = action.payload;
  return {
    ...state,
    tabs: state.tabs.map((tab) => {
      if (tab.id !== id) return tab;
      return {
        ...tab,
        title
      }
    })
  }
}

const setConfirmRequire = (state, action) => {
  const { id, flag } = action.payload;
  return {
    ...state,
    tabs: state.tabs.map((tab) => {
      if (tab.id !== id) return tab;
      return {
        ...tab,
        requireCloseConfirm: !!(flag)
      }
    })
  }
}
