import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { Content } from 'reactjs-admin-lte';
import UserInfo from 'components/users/UserInfo';
import UserAbout from 'components/users/UserAbout';
import UserProfile from 'components/users/UserProfile';
import UserSoftware from 'components/users/UserSoftware';
import UserDetailTab from 'components/users/UserDetail/UserDetailTab';
import UserDetailTabPane from 'components/users/UserDetail/UserDetailTabPane';


const Profile = (props) => {
  
  const {
    me,
    groups,
    isEmployee,
    currentTab,
    handleTabSelect,
    showPasswordChangeModal,
  } = props;
    
  return (
    <Content.Body>
      <Row>
        <Col lg={3}>
          <UserInfo
            userId={me.id}
            username={me.username}
            dateJoined={me.dateJoined}
            avatar={me.avatar} />
          <UserAbout
            name={me.name}
            email={me.email}
            groups={groups} />
          <button
            type="button"
            className="btn btn-block bg-purple btn"
            onClick={showPasswordChangeModal}>
            <FontAwesome name="key" className="mr-5"/>Change Password
          </button>
        </Col>
        <Col lg={9}>
          <Tab.Container
            id={`user-profile-${me.id}-tab`}
            activeKey={currentTab}
            onSelect={handleTabSelect}>
            <div className="border-tab-content-page">
              <Nav bsStyle="tabs">
                <UserDetailTab
                  eventKey="profile"
                  title="Profile" />
                { isEmployee &&
                  <UserDetailTab
                    eventKey="software"
                    title="Software" />
                }
              </Nav>
              <Tab.Content animation={false}>
                <UserDetailTabPane eventKey="profile">
                  <UserProfile userId={me.id} />
                </UserDetailTabPane>
                { isEmployee &&
                  <UserDetailTabPane eventKey="software">
                    <UserSoftware userId={me.id} />
                  </UserDetailTabPane>
                }
              </Tab.Content>
            </div>
          </Tab.Container>
        </Col>
      </Row>
    </Content.Body>
  );
}

export default Profile;