import React from 'react';
import { SplitButton, MenuItem } from 'react-bootstrap';
import { Content, Box } from 'reactjs-admin-lte';
import TranslatorCurrentJobsTable from './TranslatorCurrentJobsTable';
import EmployeeCurrentJobsTable from './EmployeeCurrentJobsTable';
import CheckerCurrentJobsTable from './CheckerCurrentJobsTable';


const EmployeeCurrentJobs = (props) => {
  
  const {
    roleKey,
    sentToStatus,
    jobs,
    isLoading,
    allowBulkFinish,
    handleTabOpen,
    handleSelect,
    title,
    selected
  } = props;

  let TableComponent = EmployeeCurrentJobsTable;
  if (roleKey === 'translator') {
    TableComponent = TranslatorCurrentJobsTable
  } else if (roleKey === 'checker') {
    TableComponent = CheckerCurrentJobsTable
  }

  
  return (
    <Content.Body>
      { allowBulkFinish &&
        <div className="mb-10">
          <SplitButton
            bsStyle="primary"
            bsSize="sm"
            title="Change status"
            id="employee-status-change-btn"
            disabled={
              selected === undefined ||
              selected.length === 0
            }>
            <MenuItem eventKey="1" onClick={props.handleBulkFinish}>
              {title} Finish
            </MenuItem>
          </SplitButton>
        </div>
      }
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body
          noPadding={true}>
          <TableComponent
            jobs={jobs}
            roleKey={roleKey}
            sentToStatus={sentToStatus}
            allowBulkFinish={allowBulkFinish}
            selected={selected}
            handleSelect={handleSelect}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
      </Box>
    </Content.Body>
  );
}

EmployeeCurrentJobs.defaultProps = {
  allowBulkFinish: false,
}

export default EmployeeCurrentJobs;
