import React from 'react'
import Dropzone from 'react-dropzone';
import { Alert } from 'react-bootstrap';
import { MAX_FILE_SIZE, MIN_FILE_SIZE, LARGE_FILE_SIZE } from 'misc/config';
import { humanizeBytes } from 'misc/utils';
import PureRenderComponent from 'components/core/PureRenderComponent';

class SingleDropzoneField extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      warning: [],
      files: [],
    }
  }

  handleDropSuccess = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const warning = this.state.warning;
    if (file.size > LARGE_FILE_SIZE) {
      warning.push(`${file.name} (${humanizeBytes(file.size)}): Large file size (+${humanizeBytes(LARGE_FILE_SIZE)}), please allow additional time.`);
    }
    this.props.input.onChange(file);
    this.setState({ errors: [] });
  }

  handleDropFailure = (rejectedFiles) => {
    const errors = [];
    rejectedFiles.forEach(file => {
      if (file.size > MAX_FILE_SIZE) {
        errors.push(`${file.name} (${humanizeBytes(file.size)}): File exceeds Max size limit, ${humanizeBytes(MAX_FILE_SIZE)}. Please upload a smaller file.`);
      } else if (file.size < MIN_FILE_SIZE) {
        errors.push(`${file.name} (${humanizeBytes(file.size)}): It looks like an empty file!`);
      } else if (this.props.accept && file.type !== this.props.accept) {
        errors.push(`${file.name} (${humanizeBytes(file.size)}): This File type is not allowed to upload`);
      }
    })
    this.setState({ errors });
  }

  handleDismiss = (key) => {
    this.setState({ [key]: [] });
  }

  render() {

    const { meta: { touched, error, dirty } } = this.props;

    return (
      <div>
        <Dropzone
          name={this.props.name}
          accept={this.props.accept}
          onDropAccepted={this.handleDropSuccess}
          onDropRejected={this.handleDropFailure}
          className="file-upload"
          activeClassName="active"
          maxSize={MAX_FILE_SIZE}
          minSize={MIN_FILE_SIZE}
          multiple={false}>
          <p className="text-center">Drag file or click here to upload a file.<br />Max file upload size is <b>{humanizeBytes(MAX_FILE_SIZE)}</b></p>
        </Dropzone>
        { this.state.errors &&
          this.state.errors.length > 0 &&
            <div className="m-5">
              <Alert
                onDismiss={() => this.handleDismiss('errors')}
                bsStyle="danger"
                className="mb-0">
                <ul className="pl-15">
                  { this.state.errors.map((e, idx) => <li key={idx}>{e}</li>) }
                </ul>
              </Alert>
            </div>
        }
        { this.state.warning &&
          this.state.warning.length > 0 &&
          <div className="m-5">
            <Alert
              onDismiss={() => this.handleDismiss('warning')}
              bsStyle="warning"
              className="mb-0">
              <ul className="pl-15">
                { this.state.warning.map((e, idx) => <li key={idx}>{e}</li>) }
              </ul>
            </Alert>
          </div>
        }
        { dirty && touched && error &&
          <div className="m-5">
            <Alert
              bsStyle="danger"
              className="mb-0">
              <ul className="pl-15">
                <li>{error}</li>
              </ul>
            </Alert>
          </div>
        }
      </div>
    )
  }
}


export default SingleDropzoneField;
