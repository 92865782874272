import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { JOB_CREATE_FORM } from 'misc/constants';
import { isJobCreateLoading } from 'selectors/loading';
import { createRequest, draftRequest } from 'actions/jobs/managers';
import { getJobCreateFormInitialValues } from 'selectors/forms';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobCreate from './presenter';


class JobCreateContainer extends PureRenderComponent {
  
  handleCreate = (values) => {
    this.props.createRequest(this.props.form, values);
  }

  handleSaveAsDraft = (values) => {
    this.props.draftRequest(this.props.form, values);
  }
  
  render() {
    return (
      <JobCreate
        {...this.props}
        handleCreate={this.handleCreate}
        handleSaveAsDraft={this.handleSaveAsDraft} />
    )
  }
}

const mapStateToProps = (state) => {
  const form = JOB_CREATE_FORM;
  const formSelector = formValueSelector(form);
  const client = formSelector(state, 'client');
  return {
    form,
    client,
    isLoading: isJobCreateLoading(state),
    initialValues: getJobCreateFormInitialValues(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    createRequest,
    draftRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobCreateContainer);
