import React from 'react';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { Content } from 'reactjs-admin-lte';
import UserInfo from 'components/users/UserInfo';
import UserAbout from 'components/users/UserAbout';
import UserAccount from 'components/users/UserAccount';
import UserProfile from 'components/users/UserProfile';
import UserSkill from 'components/users/UserSkill';
import UserFocus from 'components/users/UserFocus';
import UserSpecialty from 'components/users/UserSpecialty';
import UserSchedule from 'components/users/UserSchedule';
import UserSoftware from 'components/users/UserSoftware';
import UserDetailTab from './UserDetailTab';
import UserDetailTabPane from './UserDetailTabPane';


const UserDetail = (props) => {

  const {
    id,
    user,
    isLoading,
    currentTab,
    handleTabSelect,
    isEmployeeUser,
    isSkillRequiredUser,
    hasTranslatorRole,
    hasEditorRole,
    hasCheckerRole,
    hasFinalEditorRole,
    hasSecondFinalEditorRole,
  } = props;

  return (
    <Content.Body>
      <Row>
        <Col lg={3}>
          <UserInfo
            userId={id}
            username={user.username}
            dateJoined={user.dateJoined}
            avatar={user.avatar}
            isLoading={isLoading} />
          <UserAbout
            name={user.name}
            email={user.email}
            groups={user.groups}
            isLoading={isLoading} />
        </Col>
        <Col lg={9}>
          <Tab.Container
            id={`user-detail-${id}-tab`}
            activeKey={currentTab}
            onSelect={handleTabSelect}>
            <div className="border-tab-content-page">
              <Nav bsStyle="tabs">
                <UserDetailTab
                  eventKey="account"
                  title="Account" />
                <UserDetailTab
                  eventKey="profile"
                  title="Profile" />
                { isEmployeeUser &&
                  <UserDetailTab
                    eventKey="focus"
                    title="Focus" />
                }
                { isEmployeeUser &&
                  <UserDetailTab
                    eventKey="specialty"
                    title="Specialties" />
                }
                { isSkillRequiredUser &&
                  <UserDetailTab
                    eventKey="skill"
                    title="Skills" />
                }
                {
                  isEmployeeUser &&
                  <UserDetailTab
                    eventKey="software"
                    title="Software" />
                }
                {
                  isEmployeeUser &&
                  <UserDetailTab
                    eventKey="schedule"
                    title="Schedule" />
                }
              </Nav>
              <Tab.Content animation={false} mountOnEnter={true} unmountOnExit={true}>
                <UserDetailTabPane eventKey="account">
                  <UserAccount userId={id} />
                </UserDetailTabPane>
                <UserDetailTabPane eventKey="profile">
                  <UserProfile userId={id} />
                </UserDetailTabPane>
                { isEmployeeUser &&
                  <UserDetailTabPane eventKey="focus">
                    <UserFocus userId={id} />
                  </UserDetailTabPane>
                }
                { isEmployeeUser &&
                  <UserDetailTabPane eventKey="specialty">
                    <UserSpecialty userId={id} />
                  </UserDetailTabPane>
                }
                { isSkillRequiredUser &&
                  <UserDetailTabPane eventKey="skill">
                    <UserSkill
                      userId={id}
                      hasTranslatorRole={hasTranslatorRole}
                      hasEditorRole={hasEditorRole}
                      hasCheckerRole={hasCheckerRole}
                      hasFinalEditorRole={hasFinalEditorRole}
                      hasSecondFinalEditorRole={hasSecondFinalEditorRole} />
                  </UserDetailTabPane>
                }
                { isEmployeeUser &&
                  <UserDetailTabPane eventKey="software">
                    <UserSoftware userId={id} />
                  </UserDetailTabPane>
                }
                { isEmployeeUser &&
                  <UserDetailTabPane eventKey="schedule">
                    <UserSchedule userId={id} />
                  </UserDetailTabPane>
                }
              </Tab.Content>
            </div>
          </Tab.Container>
        </Col>
      </Row>
    </Content.Body>
  );
}

export default UserDetail;
