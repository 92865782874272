import React from 'react'
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import * as validators from 'misc/validators';
import { CLIENT_WEBSITE_MODAL, CLIENT_WEBSITE_FORM } from 'misc/constants';
import { isClientWebsiteModalLoading } from 'selectors/loading';
import Spinner from 'components/core/Spinner';
import CharField from 'components/core/CharField';
import PureRenderComponent from 'components/core/PureRenderComponent';


class ClientWebsiteModal extends PureRenderComponent {
  
  render() {
    const {
      show,
      handleHide,
      handleSubmit,
      handleAddUrl,
      error,
      isLoading
    } = this.props

    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Form
          noValidate
          autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title>Add Website URL</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              name="url"
              label="URL"
              validate={[
                validators.required,
                validators.url,
              ]}
              isRequired={true}
              component={CharField.Labeled} />
            { error &&
              <div className="callout callout-danger">
                <p>{error}</p>
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pull-left"
              bsSize="sm"
              type="button"
              onClick={handleHide}>
              <FontAwesome name="close" className="mr-5" />Close
            </Button>
            <Button
              bsStyle="primary"
              bsSize="sm"
              type="button"
              onClick={handleSubmit(values => handleAddUrl(values))}>
              <FontAwesome name="paper-plane-o" className="mr-5" />Submit
            </Button>
          </Modal.Footer>
          <Spinner isLoading={isLoading} />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: isClientWebsiteModalLoading(state)
  }
}

ClientWebsiteModal = connectModal({
  name: CLIENT_WEBSITE_MODAL
})(ClientWebsiteModal);

ClientWebsiteModal = reduxForm({
  form: CLIENT_WEBSITE_FORM
})(ClientWebsiteModal);

export default connect(
  mapStateToProps
)(ClientWebsiteModal);
