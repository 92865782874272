import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { USER_PROFILE_FORM } from 'misc/constants';
import { profileRetrieveRequest, profileUpdateRequest } from 'actions/users';
import { getUserProfileFormInitialValues } from 'selectors/forms';
import { isUserProfileLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import UserProfileForm from './UserProfileForm'


class UserProfileContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.profileRetrieveRequest(this.props.userId);
  }

  handleSubmit = (values) => {
    this.props.profileUpdateRequest(
      this.props.form,
      this.props.userId,
      values
    );
  }
  
  render() {
    return (
      <UserProfileForm
        {...this.props}
        onSubmit={this.handleSubmit} />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.userId;
  const form = `${USER_PROFILE_FORM}/${id}`
  return {
    form,
    isLoading: isUserProfileLoading(state, id),
    initialValues: getUserProfileFormInitialValues(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    profileRetrieveRequest,
    profileUpdateRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileContainer);

