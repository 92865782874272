import React from 'react'
import { bindActionCreators } from 'redux';
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { employeeBootstrapRequest } from 'actions/bootstrap';
import { isEmployee } from 'selectors/auth';
import PureRenderComponent from 'components/core/PureRenderComponent';

class EmployeeRoute extends PureRenderComponent {

  componentDidMount() {
    this.props.employeeBootstrapRequest();
  }

  render() {
    return this.props.isEmployee
      ? <Route
          {...this.props}
          component={this.props.component} />
      : <Redirect to="/manager" />
  }
}

const mapStateToProps = (state) => {
  return {
    isEmployee: isEmployee(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    employeeBootstrapRequest
  }, disptach)
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeRoute)
