import {createSelector} from "reselect";
import {getId} from "./core";
import {getInitialState} from "../reducers/wordListUi";

const _getAllLists = state => state.wordListUi || {};

export const getListSettings = createSelector(
  [_getAllLists, getId],
  (allListSettings, currentPage) => {
    return allListSettings[currentPage] || getInitialState()
  }
)

export const getListSettingsColumns = createSelector(
  [getListSettings],
  (settings) => settings.columns || {}
)

export const getListSettingsUpdated = createSelector(
  [getListSettings],
  (settings) => settings.updated || 0
)