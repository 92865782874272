import React from "react";
import PropTypes from 'prop-types';
import './TaToolbar.css';

export const TaToolbarGroup = (props) => {

    let classNames = ['ta-toolbar__group'];
    let style;
    if (props.className) {
        classNames.push(props.className);
    }
    // if (props.flex) {
    //     style = {
    //         flex: '0 0 ' + props.flex,
    //         width: props.flex
    //     }
    // }

    return (
        <div className={classNames.join(' ')} style={style}>
            {props.children}
        </div>
    )
};
TaToolbarGroup.propTypes = {
    flex: PropTypes.string
};
