import axios from 'axios';

const QuoteApi = {
  list: (params) => {
    return axios.get(`quotes/manager/`, { params });
  },
  retrieve: (id) => {
    return axios.get(`quotes/manager/${id}/`);
  },
  batchDelete: (values) => {
    return axios.post(`quotes/batch-delete/`, values);
  },
  unsupportedOrderList: (params) => {
    return axios.get(`unsupported-orders/manager`, { params });
  },
  unsupportedOrderRetrieve: (id) => {
    return axios.get(`unsupported-orders/manager/${id}`);
  }
}

export default QuoteApi;