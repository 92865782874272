import React from 'react';
import { Table } from 'react-bootstrap';
import EmptyRow from 'components/core/EmptyRow';
import DocumentTableRow from './DocumentTableRow';


const DocumentTable = (props) => {

  const {
    documents,
    jobId,
    usable,
    handleUse,
    downloadable,
    handleDownload,
    removable,
    handleRemove,
    isMHTClickable,
    handleOpenMHT,
    sizeVisible,
    updatable,
    showUpdateModal,
    bordered
  } = props;

  const rowActionFlags = [updatable, removable, usable];
  const hasAction = rowActionFlags.some(f => f);
  const actionCount = rowActionFlags.filter(f => f).length;
  const actionWidth = `${70 * actionCount * 0.8}px`;

  return (
    <Table
      striped
      hover
      bordered={bordered}
      className="table-layout-fixed">
      <thead>
        <tr>
          <th width="100%">Name</th>
          { sizeVisible && <th width={80}>Size</th> }
          <th width={120}>Uploaded</th>
          { hasAction && <th width={actionWidth} className="text-center">Action</th> }
      </tr>
    </thead>
    <tbody>
      { documents && documents.length > 0
        ? documents.map((document, idx) => (
            <DocumentTableRow
                idx={idx}
                key={document.id}
                document={document}
                jobId={jobId}
                isMHTClickable={isMHTClickable}
                isMHT={!!document.mht}
                updatable={updatable}
                removable={removable}
                downloadable={downloadable}
                usable={usable}
                sizeVisible={sizeVisible}
                hasAction={hasAction}
                handleUse={handleUse}
                handleOpenMHT={handleOpenMHT}
                handleDownload={handleDownload}
                showUpdateModal={showUpdateModal}
                handleRemove={handleRemove} />
            ))
          : <EmptyRow colSpan={ hasAction ? 4 : 3 } />
        }
      </tbody>
    </Table>
  )
}

DocumentTable.defaultProps = {
  downloadable: true,
  updatable: false,
  removable: false,
  usable: false,
  bordered: false,
  sizeVisible: true,
}

export default DocumentTable;
