import * as types from 'actionTypes/tabs';
import * as constants from 'misc/constants';


const initialState = {
  [constants.JOB_SCHEDULE]: 'toEn',
  [constants.TOTRA_LIST]: 'validating',
  [constants.INVOICE_LIST]: 'ls',
  [constants.EMPLOYEE_JOB_SCHEDULE]: 'daily',
  [constants.USER_DETAIL]: {},
  [constants.SETTINGS_PROFILE]: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SELECT_TAB:
      return selectTab(state, action);
    case types.SELECT_NESTED_TAB:
      return selectNestedTab(state, action);
    default:
      return state;
  }
}

const selectTab = (state, action) => {
  const { name, tab } = action.payload;
  return {
    ...state,
    [name]: tab
  }
}

const selectNestedTab = (state, action) => {
  const { name, id, tab } = action.payload;
  return {
    ...state,
    [name]: {
      [id]: tab,
    }
  }
}