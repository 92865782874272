import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { Tab } from 'react-bootstrap';
import { logout } from 'actions/auth';
import { addOrSelectTab, selectTab, closeTab, confirmTabClose } from 'actions/globalTabs';
import { getTabs, getCurrentTab } from 'selectors/globalTabs';
import { getSkin } from 'selectors/layout';
import { getMe } from 'selectors/auth';
import PureRenderComponent from 'components/core/PureRenderComponent';



const SiteHOC = (WrappedComponent) => {
  return class extends PureRenderComponent {
    handleTabOpen = (tabConfig) => {
      this.props.addOrSelectTab(
        tabConfig['id'],
        tabConfig['title'],
        tabConfig['componentPath'],
        tabConfig['componentName'],
        tabConfig['params'],
        tabConfig['allowRefresh'],
        tabConfig['requireCloseConfirm']
      );
    }

    handleTabSelect = (eventKey) => {
      this.props.selectTab(eventKey);
    }

    handleTabClose = (e, id) => {
      e.stopPropagation();
      this.props.closeTab(id);
    }

    handleOpenTabCloseConfirm = (e, tab) => {
      e.stopPropagation();
      this.props.confirmTabClose(tab);
    }

    renderTabContent = (componentMap) => {
      return this.props.tabs.map(tab => {
        const { componentName, title, params } = tab;
        const Component = componentMap[componentName];
        return (
          <Tab.Pane
            key={tab.key}
            eventKey={tab.id}>
            <Component
              title={title}
              params={params}
              handleTabOpen={this.handleTabOpen} />
          </Tab.Pane>
        );
      })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleTabOpen={this.handleTabOpen}
          handleTabSelect={this.handleTabSelect}
          renderTabContent={this.renderTabContent}
          handleTabClose={this.handleTabClose}
          handleOpenTabCloseConfirm={this.handleOpenTabCloseConfirm} />
        );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    skin: getSkin(state),
    tabs: getTabs(state),
    currentTab: getCurrentTab(state),
    me: getMe(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addOrSelectTab,
    selectTab,
    closeTab,
    confirmTabClose,
    logout
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  SiteHOC
);