import { delay } from 'redux-saga';
import { takeLatest, call, put, fork, all } from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import { show, hide } from 'redux-modal';
import API from 'services/jobs/EmployeeApi';
import * as constants from 'misc/constants';
import * as config from 'misc/config';
import * as schemas from 'schemas/jobs';
import * as actions from 'actions/jobs/employees';
import * as actionTypes from 'actionTypes/jobs/employees';
import * as entityActions from 'actions/entities';
import * as listActions from 'actions/lists';
import * as coreActions from 'actions/core';
import * as coreSagas from 'sagas/core';
import { setLoading } from 'actions/loading';
import { saveFile } from 'misc/utils';


function* employeeJobSchedule(action, name, api, successAction, failureAction) {
  yield put(setLoading(name, true));
  const { currentDate } = action.payload;
  try {
    const response = yield call(api, decamelizeKeys({ currentDate }));
    const normalized = normalize(camelizeKeys(response.data), [schemas.jobSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setDailyResult(name, currentDate, normalized.result));
    yield put(successAction());
    yield put(setLoading(name, false));
  } catch (error) {
    yield put(failureAction(error));
    yield put(setLoading(name, false));
  }
}

function* employeeCurrentJobs(action, name, api, successAction, failureAction) {
  yield put(setLoading(name, true));
  try {
    const response = yield call(api);
    const normalized = normalize(camelizeKeys(response.data), [schemas.jobSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setResult(name, normalized.result));
    yield put(successAction());
    yield put(setLoading(name, false));
  } catch (error) {
    yield put(failureAction(error));
    yield put(setLoading(name, false));
  }
}

function* employeeJobRetrieve(action, name, api, successAction, failureAction) {
  const { id } = action.payload;
  const loadingKey = `${name}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(api, id);
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    
    const assignments = normalized.entities.assignments;
    if (assignments && Object.keys(assignments).length > 0) {
      yield all(Object.values(assignments).map(assignment => {
        return put(listActions.setNestedResult(
          constants.ASSIGNMENT_PROJECT,
          assignment.id,
          assignment.projects
        ));
      }));
    }
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(failureAction(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Employee Job Daily Schedule =================================================
function* employeeJobDailySchedule(action) {
  yield fork(
    employeeJobSchedule,
    action,
    constants.EMPLOYEE_JOB_DAILY_SCHEDULE,
    API.employeeJobDailySchedule,
    actions.employeeJobDailyScheduleSuccess,
    actions.employeeJobDailyScheduleFailure,
  );
}

// Employee Job Future Schedule ================================================
function* employeeJobFutureSchedule(action) {
  yield fork(
    employeeJobSchedule,
    action,
    constants.EMPLOYEE_JOB_FUTURE_SCHEDULE,
    API.employeeJobFutureSchedule,
    actions.employeeJobFutureScheduleSuccess,
    actions.employeeJobFutureScheduleFailure,
  );
}

// ToTra Current Jobs ==========================================================
function* totraCurrentJobs(action) {
  yield fork(
    employeeCurrentJobs,
    action,
    constants.TOTRA_CURRENT_JOBS,
    API.totraCurrentJobs,
    actions.totraCurrentJobsSuccess,
    actions.totraCurrentJobsFailure,
  );
}

// ToTra Job Retrieve ==========================================================
function* totraJobRetrieve(action) {
  yield fork(
    employeeJobRetrieve,
    action,
    constants.TOTRA_JOB_DETAIL,
    API.totraJobRetrieve,
    actions.totraJobRetrieveSuccess,
    actions.totraJobRetrieveFailure
  );
}

// Translator Current Jobs =====================================================
function* translatorCurrentJobs(action) {
  yield fork(
    employeeCurrentJobs,
    action,
    constants.TRANSLATOR_CURRENT_JOBS,
    API.translatorCurrentJobs,
    actions.translatorCurrentJobsSuccess,
    actions.translatorCurrentJobsFailure,
  );
}

// Translator Job Retrieve =====================================================
function* translatorJobRetrieve(action) {
  yield fork(
    employeeJobRetrieve,
    action,
    constants.TRANSLATOR_JOB_DETAIL,
    API.translatorJobRetrieve,
    actions.translatorJobRetrieveSuccess,
    actions.translatorJobRetrieveFailure
  );
}

// Editor Current Jobs =====================================================
function* editorCurrentJobs(action) {
  yield fork(
    employeeCurrentJobs,
    action,
    constants.EDITOR_CURRENT_JOBS,
    API.editorCurrentJobs,
    actions.editorCurrentJobsSuccess,
    actions.editorCurrentJobsFailure,
  );
}

// Editor Job Retrieve =====================================================
function* editorJobRetrieve(action) {
  yield fork(
    employeeJobRetrieve,
    action,
    constants.EDITOR_JOB_DETAIL,
    API.editorJobRetrieve,
    actions.editorJobRetrieveSuccess,
    actions.editorJobRetrieveFailure
  );
}

// Checker Current Jobs =====================================================
function* checkerCurrentJobs(action) {
  yield fork(
    employeeCurrentJobs,
    action,
    constants.CHECKER_CURRENT_JOBS,
    API.checkerCurrentJobs,
    actions.checkerCurrentJobsSuccess,
    actions.checkerCurrentJobsFailure,
  );
}

// Checker Job Retrieve =====================================================
function* checkerJobRetrieve(action) {
  yield fork(
    employeeJobRetrieve,
    action,
    constants.CHECKER_JOB_DETAIL,
    API.checkerJobRetrieve,
    actions.checkerJobRetrieveSuccess,
    actions.checkerJobRetrieveFailure
  );
}

// Final Editor Current Jobs ===================================================
function* finalEditorCurrentJobs(action) {
  yield fork(
    employeeCurrentJobs,
    action,
    constants.FINAL_EDITOR_CURRENT_JOBS,
    API.finalEditorCurrentJobs,
    actions.finalEditorCurrentJobsSuccess,
    actions.finalEditorCurrentJobsFailure,
  );
}

// Final Editor Job Retrieve ===================================================
function* finalEditorJobRetrieve(action) {
  yield fork(
    employeeJobRetrieve,
    action,
    constants.FINAL_EDITOR_JOB_DETAIL,
    API.finalEditorJobRetrieve,
    actions.finalEditorJobRetrieveSuccess,
    actions.finalEditorJobRetrieveFailure
  );
}

// Second Final Editor Current Jobs ============================================
function* secondFinalEditorCurrentJobs(action) {
  yield fork(
    employeeCurrentJobs,
    action,
    constants.SECOND_FINAL_EDITOR_CURRENT_JOBS,
    API.secondFinalEditorCurrentJobs,
    actions.secondFinalEditorCurrentJobsSuccess,
    actions.secondFinalEditorCurrentJobsFailure,
  );
}

// Second Final Editor Job Retrieve ============================================
function* secondFinalEditorJobRetrieve(action) {
  yield fork(
    employeeJobRetrieve,
    action,
    constants.SECOND_FINAL_EDITOR_JOB_DETAIL,
    API.secondFinalEditorJobRetrieve,
    actions.secondFinalEditorJobRetrieveSuccess,
    actions.secondFinalEditorJobRetrieveFailure
  );
}

// DTP Current Jobs ============================================================
function* dtpCurrentJobs(action) {
  yield fork(
    employeeCurrentJobs,
    action,
    constants.DTP_CURRENT_JOBS,
    API.dtpCurrentJobs,
    actions.dtpCurrentJobsSuccess,
    actions.dtpCurrentJobsFailure,
  );
}

// DTP Job Retrieve ============================================================
function* dtpJobRetrieve(action) {
  yield fork(
    employeeJobRetrieve,
    action,
    constants.DTP_JOB_DETAIL,
    API.dtpJobRetrieve,
    actions.dtpJobRetrieveSuccess,
    actions.dtpJobRetrieveFailure
  );
}

// Second DTP Current Jobs =====================================================
function* secondDtpCurrentJobs(action) {
  yield fork(
    employeeCurrentJobs,
    action,
    constants.SECOND_DTP_CURRENT_JOBS,
    API.secondDtpCurrentJobs,
    actions.secondDtpCurrentJobsSuccess,
    actions.secondDtpCurrentJobsFailure,
  );
}

// Second DTP Job Retrieve =====================================================
function* secondDtpJobRetrieve(action) {
  yield fork(
    employeeJobRetrieve,
    action,
    constants.SECOND_DTP_JOB_DETAIL,
    API.secondDtpJobRetrieve,
    actions.secondDtpJobRetrieveSuccess,
    actions.secondDtpJobRetrieveFailure
  );
}


// Bulk finish =============================================================
function* _bulkFinish(action, role, listName, failureAction) {
  const { values } = action.payload;
  const jobCount = values.jobs.length;
  const title = jobCount > 1 ? `${jobCount} jobs` : `${jobCount} job`;
  const message = `Are you sure to finish ${title} ?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      const response = yield call(API.bulkFinish, { ...values, role });
      const normalized = normalize(camelizeKeys(response.data), [schemas.jobSchema]);
      yield all(normalized.result.map(id => (
        put(listActions.removeItem(listName, id))
      )));
      yield fork(coreSagas.successNotification, 'Job finished successfully.');
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(failureAction(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

function* dtpBulkFinish(action) {
  yield fork(
    _bulkFinish,
    action,
    'DTP',
    constants.DTP_CURRENT_JOBS,
    actions.dtpBulkFinishFailure
  );
}

function* secondDtpBulkFinish(action) {
  yield fork(
    _bulkFinish,
    action,
    'Second DTP',
    constants.SECOND_DTP_CURRENT_JOBS,
    actions.secondDtpBulkFinishFailure
  );
}

// Report Count ================================================================
function* reportCount(action) {
  const { id, values } = action.payload;
  yield put(setLoading(constants.EMPLOYEE_JOB_REPORT_COUNT_MODAL, true));
  try {
    const response = yield call(API.reportCount, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield fork(coreSagas.successNotification, 'Count updated successfully.');
    yield put(hide(constants.EMPLOYEE_JOB_REPORT_COUNT_MODAL));
    yield put(setLoading(constants.EMPLOYEE_JOB_REPORT_COUNT_MODAL, false));
  } catch (error) {
    yield put(actions.reportCountFailure(error));
    yield put(setLoading(constants.EMPLOYEE_JOB_REPORT_COUNT_MODAL, false));
  }
}

// Print =======================================================================
function* print(action) {
  const { id } = action.payload;
  try {
    yield call(API.print, id);
  } catch (error) {
    yield put(actions.printFailure(error));
  }
}

function* employeeJobStart(action) {
  const { id, values } = action.payload;
  try {
    const response = yield call(API.employeeJobStart, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield fork(coreSagas.successNotification, 'Job status updated successfully.');
  } catch (error) {
    yield put(actions.employeeJobStartFailure(error));
  }
}

function* employeeJobFinish(action) {
  yield put(setLoading(constants.EMPLOYEE_JOB_FINISH_MODAL, true));
  const { assignment } = action.payload;
  const roleKey = config.ASSIGNMENT_ROLE_KEY_MAP[assignment.role.name];
  const { nextStatus, listName } = config.EMPLOYEE_PROCESS_MAP[roleKey];
  try {
    const response = yield call(
      API.employeeJobFinish,
      assignment.job,
      decamelizeKeys({
        assignment: assignment.id,
        status: nextStatus,
      })
    );
    const normalized = normalize(camelizeKeys(response.data), schemas.jobSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(actions.setProcessed(assignment.id, true));
    yield put(listActions.removeItem(listName, normalized.result));
    yield put(setLoading(constants.EMPLOYEE_JOB_FINISH_MODAL, false));
  } catch (error) {
    yield put(setLoading(constants.EMPLOYEE_JOB_FINISH_MODAL, false));
  }
}

// Job History =================================================================
function* historySearch(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.EMPLOYEE_JOB_HISTORY, true));
  try {
    const response = yield call(API.history, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data.results), [schemas.jobSchema]);
    const totalCount = response.data.count ? response.data.count : 1;
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setPaginationResult(
      constants.EMPLOYEE_JOB_HISTORY,
      params.page,
      normalized.result,
      totalCount
    ));
    yield put(setLoading(constants.EMPLOYEE_JOB_HISTORY, false));
  } catch (error) {
    yield put(actions.historySearchFailure(error));
    yield put(setLoading(constants.EMPLOYEE_JOB_HISTORY, false));
  }
}

function* historyCsvExport(action) {
  yield put(show(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  const { params } = action.payload;
  try {
    const [downloadPromise, chan] = yield call(
      coreSagas.createDownloader,
      API.historyCsvExport,
      params
    );
    yield fork(coreSagas.watchDownloadProgress, chan);
    const response = yield call(() => downloadPromise);
    yield call(saveFile, response.data, 'history.csv', 'text/csv');
    yield delay(1000);
    yield put(hide(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  } catch (error) {
    yield put(actions.historyCsvExportFailure(error));
  } finally {
    yield put(coreActions.setDownloadProgress(0));
  }
}

function* allReferenceDownload(action) {
  yield put(show(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  const { id, number } = action.payload;
  try {
    const [downloadPromise, chan] = yield call(
      coreSagas.createDownloader,
      API.allReferenceDownload,
      id
    );
    yield fork(coreSagas.watchDownloadProgress, chan);
    const response = yield call(() => downloadPromise);
    yield call(saveFile, response.data, `${number}.zip`, 'application/x-zip-compressed');
    yield delay(1000);
    yield put(hide(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  } catch (error) {
    yield put(actions.allReferenceDownloadFailure(error));
  } finally {
    yield put(coreActions.setDownloadProgress(0));
  }
}

// Job Schedule watch ==========================================================
function* watchEmployeeJobDailySchedule() {
  yield takeLatest(actionTypes.EMPLOYEE_JOB_DAILY_SCHEDULE.REQUEST, employeeJobDailySchedule);
}
function* watchEmployeeJobFutureSchedule() {
  yield takeLatest(actionTypes.EMPLOYEE_JOB_FUTURE_SCHEDULE.REQUEST, employeeJobFutureSchedule);
}

// ToTra watch =================================================================
function* watchTotraCurrentJobs() {
  yield takeLatest(actionTypes.TOTRA_CURRENT_JOBS.REQUEST, totraCurrentJobs);
}
function* watchToTraJobRetrieve() {
  yield takeLatest(actionTypes.TOTRA_JOB_RETRIEVE.REQUEST, totraJobRetrieve);
}

// Translator watch ============================================================
function* watchTranslatorCurrentJobs() {
  yield takeLatest(actionTypes.TRANSLATOR_CURRENT_JOBS.REQUEST, translatorCurrentJobs);
}
function* watchTranslatorJobRetrieve() {
  yield takeLatest(actionTypes.TRANSLATOR_JOB_RETRIEVE.REQUEST, translatorJobRetrieve);
}

// Editor watch ================================================================
function* watchEditorCurrentJobs() {
  yield takeLatest(actionTypes.EDITOR_CURRENT_JOBS.REQUEST, editorCurrentJobs);
}
function* watchEditorJobRetrieve() {
  yield takeLatest(actionTypes.EDITOR_JOB_RETRIEVE.REQUEST, editorJobRetrieve);
}

// Checker watch ===============================================================
function* watchCheckerCurrentJobs() {
  yield takeLatest(actionTypes.CHECKER_CURRENT_JOBS.REQUEST, checkerCurrentJobs);
}
function* watchCheckerJobRetrieve() {
  yield takeLatest(actionTypes.CHECKER_JOB_RETRIEVE.REQUEST, checkerJobRetrieve);
}

// FinalEditor watch ===========================================================
function* watchFinalEditorCurrentJobs() {
  yield takeLatest(actionTypes.FINAL_EDITOR_CURRENT_JOBS.REQUEST, finalEditorCurrentJobs);
}
function* watchFinalEditorJobRetrieve() {
  yield takeLatest(actionTypes.FINAL_EDITOR_JOB_RETRIEVE.REQUEST, finalEditorJobRetrieve);
}

// Second Final Editor watch ===================================================
function* watchSecondFinalEditorCurrentJobs() {
  yield takeLatest(actionTypes.SECOND_FINAL_EDITOR_CURRENT_JOBS.REQUEST, secondFinalEditorCurrentJobs);
}
function* watchSecondFinalEditorJobRetrieve() {
  yield takeLatest(actionTypes.SECOND_FINAL_EDITOR_JOB_RETRIEVE.REQUEST, secondFinalEditorJobRetrieve);
}

// DTP watch ===================================================================
function* watchDtpCurrentJobs() {
  yield takeLatest(actionTypes.DTP_CURRENT_JOBS.REQUEST, dtpCurrentJobs);
}
function* watchDtpJobRetrieve() {
  yield takeLatest(actionTypes.DTP_JOB_RETRIEVE.REQUEST, dtpJobRetrieve);
}

// Second DTP watch ============================================================
function* watchSecondDtpCurrentJobs() {
  yield takeLatest(actionTypes.SECOND_DTP_CURRENT_JOBS.REQUEST, secondDtpCurrentJobs);
}
function* watchSecondDtpJobRetrieve() {
  yield takeLatest(actionTypes.SECOND_DTP_JOB_RETRIEVE.REQUEST, secondDtpJobRetrieve);
}
function* watchEmployeeJobStart() {
  yield takeLatest(actionTypes.EMPLOYEE_JOB_START.REQUEST, employeeJobStart);
}
function* watchEmployeeJobFinish() {
  yield takeLatest(actionTypes.EMPLOYEE_JOB_FINISH.REQUEST, employeeJobFinish);
}
function* watchReportCount() {
  yield takeLatest(actionTypes.REPORT_COUNT.REQUEST, reportCount);
}
function* watchPrint() {
  yield takeLatest(actionTypes.PRINT.REQUEST, print);
}

// History watch ===============================================================
function* watchHistorySearch() {
  yield takeLatest(actionTypes.HISTORY_SEARCH.REQUEST, historySearch);
}
function* watchHistoryCsvExport() {
  yield takeLatest(actionTypes.HISTORY_CSV_EXPORT.REQUEST, historyCsvExport);
}
function* watchAllReferenceDownload() {
  yield takeLatest(actionTypes.ALL_REFERENCE_DOWNLOAD.REQUEST, allReferenceDownload);
}

function* watchDtpBulkFinish() {
  yield takeLatest(actionTypes.DTP_BULK_FINISH.REQUEST, dtpBulkFinish);
}
function* watchSecondDtpBulkFinish() {
  yield takeLatest(actionTypes.SECOND_DTP_BULK_FINISH.REQUEST, secondDtpBulkFinish);
}

export {
  // Job Schedule
  watchEmployeeJobDailySchedule,
  watchEmployeeJobFutureSchedule,
  // ToTra
  watchTotraCurrentJobs,
  watchToTraJobRetrieve,
  // Translator
  watchTranslatorCurrentJobs,
  watchTranslatorJobRetrieve,
  // Editor
  watchEditorCurrentJobs,
  watchEditorJobRetrieve,
  // Checker
  watchCheckerCurrentJobs,
  watchCheckerJobRetrieve,
  // Final Eidtor
  watchFinalEditorCurrentJobs,
  watchFinalEditorJobRetrieve,
  // Second Final Eidtor
  watchSecondFinalEditorCurrentJobs,
  watchSecondFinalEditorJobRetrieve,
  // DTP
  watchDtpCurrentJobs,
  watchDtpJobRetrieve,
  // Second DTP
  watchSecondDtpCurrentJobs,
  watchSecondDtpJobRetrieve,

  //  Report count
  watchReportCount,
  // Print
  watchPrint,
  watchEmployeeJobStart,
  watchEmployeeJobFinish,
  // History
  watchHistorySearch,
  watchHistoryCsvExport,
  watchAllReferenceDownload,

  watchDtpBulkFinish,
  watchSecondDtpBulkFinish
}