import React from "react";
import "./style.css";

export const TaInputGroup = (props) => {
  let {
    className,
    ...attributes
  } = props;

  let classNames = ['ta-input-group'];
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} {...attributes}>
      {props.children}
    </div>
  );

};
