import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import { Field, reduxForm } from 'redux-form';
import CharField from 'components/core/CharField';
import DateRangeFormField from 'components/core/DateRangeFormField';
import { CoordinatorSelectOptionField, ServiceSelectOptionField } from 'components/core/SelectOptionField';


const TranslatorJobHistoryForm = (props) => {
  
  const {
    handleSubmit,
    handleSearch,
    handleReset,
    handleCsvExport
  } = props;
  
  return (
    <Form
      horizontal
      noValidate
      autoComplete="off"
      className="form-condensed">
      <Box className="box-widget">
        <Box.Header border={true}>
          <Box.Title>
            <FontAwesome name="filter" />
          </Box.Title>
          <Box.Tools>
            <Box.CollapsedToggleButton />
          </Box.Tools>
        </Box.Header>
        <Box.Body>
          <Row>
            <Col lg={4}>
              <DateRangeFormField label="Deadline" />
              <Field
                name="number"
                label="Number"
                type="text"
                component={CharField.Horizontal} />
            </Col>
            <Col lg={4}>
              <ServiceSelectOptionField type="Horizontal" />
              <Field
                name="keyword"
                label="Keyword"
                type="text"
                component={CharField.Horizontal} />
            </Col>
            <Col lg={4}>
              <CoordinatorSelectOptionField type="Horizontal" />
            </Col>
          </Row>
        </Box.Body>
        <Box.Footer>
          <Button
            bsStyle="primary"
            bsSize="sm"
            type="button"
            onClick={handleSubmit(values => handleSearch(values))}>
            <FontAwesome name="search" className="mr-5" />Search
          </Button>
          <Button
            bsStyle="default"
            bsSize="sm"
            type="button"
            className="ml-10"
            onClick={handleReset}>
            <FontAwesome name="undo" className="mr-5" />Reset
          </Button>
          <button
            type="button"
            className="btn btn-sm bg-purple pull-right"
            onClick={handleSubmit(values => handleCsvExport(values))}>
            <FontAwesome name="share-square-o" className="mr-5" />Export to CSV
          </button>
        </Box.Footer>
      </Box>
    </Form>
  );
}

export default reduxForm()(TranslatorJobHistoryForm);

