import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Content } from 'reactjs-admin-lte';
import { EMPLOYEE_STATUS_MAP } from 'misc/config';
import JobWorkFlow from 'components/jobs/common/JobWorkFlow'
import JobMessage from 'components/jobs/common/JobMessage';
import JobSourcefile from 'components/jobs/common/JobSourcefile';
import EmployeeReference from 'components/jobs/employees/EmployeeReference';
import JobNote from 'components/jobs/common/JobNote';
import EmployeeAssignmentProject from '../EmployeeAssignmentProject';
import EmployeeJobDetailTools from './EmployeeJobDetailTools';
import EmployeeJobDetailBasicInfo from './EmployeeJobDetailBasicInfo';
import TranslatorJobDetailBasicInfo from './TranslatorJobDetailBasicInfo';


const JobDetail = (props) => {
  const {
    id,
    job,
    readOnly,
    proecessRequired,
    assignments,
    isLoading,
    roleKey,
    handlePrint,
    handleJobStart,
    handleTabOpen,
    handleDownloadAll,
    showReportCountModal
  } = props;

  const assignment = job[roleKey];
  const statuses = EMPLOYEE_STATUS_MAP[roleKey];
  const isWorkingAssignment = Object.values(statuses).includes(job.status);
  const isTranslatorJob = roleKey === 'translator';
  const isCheckerJob = roleKey === 'checker';
  const isWorkFlowVisible = !isTranslatorJob && !readOnly;

  const BasicInfo = isTranslatorJob
  ? (
    <TranslatorJobDetailBasicInfo
      job={job}
      assignment={assignment}
      isLoading={isLoading} />
  ) : (
    <EmployeeJobDetailBasicInfo
      job={job}
      isChecker={isCheckerJob}
      assignment={assignment}
      isLoading={isLoading} />
  )

  return (
    <div>
      <Content.Header>
        <h1>
          {job.number}
          <EmployeeJobDetailTools
            isTranslatorJob={isTranslatorJob}
            handlePrint={handlePrint}
            showReportCountModal={showReportCountModal} />
        </h1>
      </Content.Header>
      <Content.Body>
        <Row>
          <Col lg={9}>
            {BasicInfo}
            <JobNote
              collapsed={false}
              isLoading={isLoading}
              title="Job Notes"
              content={job.notes} />

            { isWorkFlowVisible &&
              <JobWorkFlow
                myRole={assignment && assignment.role.name}
                myDeadline={assignment && assignment.deadline}
                assignments={assignments}
                isLoading={isLoading}
                handleJobStart={handleJobStart} />
            }

            <Row>
              <Col lg={6}>
              <JobSourcefile
                jobId={id}
                job={job}
                assignment={assignment && assignment.id}
                isMHTClickable={isWorkingAssignment}
                roleKey={roleKey}
                handleJobStart={handleJobStart}
                handleTabOpen={handleTabOpen} />
              </Col>
              <Col lg={6}>
                { !readOnly &&
                  <EmployeeAssignmentProject
                    isLoading={isLoading}
                    assignmentId={assignment && assignment.id}
                    proecessRequired={proecessRequired} />
                }
              </Col>
            </Row>
            <EmployeeReference
              jobId={id}
              jobGroup={job && job.group}
              handleDownloadAll={handleDownloadAll} />
          </Col>
          <Col lg={3}>
            <JobMessage
              jobId={id}
              roleKey={roleKey} />
          </Col>
        </Row>
      </Content.Body>
    </div>
  )
}

export default JobDetail;
