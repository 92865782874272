import React from 'react';
import * as PropTypes from "prop-types";
import {
  TaModal,
  TaModalContent,
  TaModalContentMargin,
  TaModalFooter,
  TaModalHeader
} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";

class TaMhtEditorSaveWarningModalContent extends React.Component {

  static propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onDiscard: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onError: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      isSaving: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isSaving){
      this.setState({
        isSaving: false
      });
    }
  }

  onConfirm = (response) => {
    this.setState({
      isSaving: true
    }, () => {
      this.props.onConfirm();
    });
  };

  render() {

    const {
      onDiscard,
      onCancel,
      children
    } = this.props;

    return (
      <React.Fragment>
        <TaModalHeader
          onCancel={onCancel}
          label={'Unsaved Changes'}
          warning
        />
        <TaModalContent>
          <TaModalContentMargin>
            {(children) ? (children) : (
              <p>Would you like to save changes to this document before proceeding?</p>
            )}
          </TaModalContentMargin>
        </TaModalContent>
        <TaModalFooter>
          <TaButton
            type="reset"
            icon={'delete'}
            label={'Don\'t Save'}
            onClick={() => onDiscard()}
          />
          <div/>
          <TaButton
            type="reset"
            icon={'clear'}
            label={'Cancel'}
            onClick={onCancel}
          />
          <TaButton
            type="submit"
            icon={'check'}
            label={'Save'}
            onClick={() => this.onConfirm()}
            isLoading={this.state.isSaving}
          />
        </TaModalFooter>
      </React.Fragment>
    );
  }
}

class TaMhtEditorSaveWarningModal extends React.Component {

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onDiscard: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onError: PropTypes.func
  };

  render() {

    const {
      isOpen,
      onDiscard,
      onConfirm,
      onCancel,
      children
    } = this.props;

    return (
      <TaModal
        isOpen={isOpen}
        onCancel={onCancel}
      >
        {(!!isOpen) && (
          <TaMhtEditorSaveWarningModalContent
            onCancel={onCancel}
            onDiscard={onDiscard}
            onConfirm={onConfirm}
            children={children}
          />
        )}
      </TaModal>
    );
  }
}

export default TaMhtEditorSaveWarningModal;
