import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CUSTOMER_CREATE_FORM } from 'misc/constants';
import { createRequest } from 'actions/customers';
import { isCustomerCreateLoading } from 'selectors/loading';
import { getCustomerCreateFormInitialValues } from 'selectors/forms';
import PureRenderComponent from 'components/core/PureRenderComponent';
import ClientCreate from './presenter';


class CustomerCreateContainer extends PureRenderComponent {
  
  handleCreate = (values) => {
    this.props.createRequest(this.props.form, values);
  }

  render() {
    return (
      <ClientCreate
        {...this.props}
        handleCreate={this.handleCreate} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: CUSTOMER_CREATE_FORM,
    isLoading: isCustomerCreateLoading(state),
    initialValues: getCustomerCreateFormInitialValues(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    createRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerCreateContainer);

