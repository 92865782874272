import { takeLatest, call, put } from 'redux-saga/effects';
import { camelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import API from 'services/TermApi';
import * as actions from 'actions/terms';
import * as GlossarySchemas from 'schemas/glossaries';
import * as WordListSchemas from 'schemas/wordlists';
import * as actionTypes from 'actionTypes/terms';
import * as entityActions from 'actions/entities';
import * as listActions from 'actions/lists';
import * as constants from 'misc/constants';
import { setLoading } from 'actions/loading';


function* search(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.TERM_SEARCH, true));
  try {
    const response = yield call(API.search, params);
    const { results, count } = response.data;
    const totalCount = count ? count : 1;
    const wordlistItems = results.filter(item => item.type === 'wordlist');
    const glossaryItems = results.filter(item => item.type === 'glossary');
    const normalizedGlossaryItems = normalize(
      camelizeKeys(glossaryItems),
      [GlossarySchemas.glossaryItemSchema]
    );
    const normalizedWordListItems = normalize(
      camelizeKeys(wordlistItems),
      [WordListSchemas.wordlistItemSchema]
    );
    yield put(entityActions.merge(normalizedGlossaryItems.entities));
    yield put(entityActions.merge(normalizedWordListItems.entities));
    yield put(listActions.setPaginationResult(
      constants.TERM_SEARCH,
      params.page,
      {
        wordlistItems: normalizedWordListItems.result,
        glossaryItems: normalizedGlossaryItems.result,
      },
      totalCount
    ))
    // yield put(listActions.setHits(constants.TERM_SEARCH, count));
    yield put(setLoading(constants.TERM_SEARCH, false));
  } catch (error) {
    yield put(actions.searchFailure(error));
    yield put(setLoading(constants.TERM_SEARCH, false));
  }
}

function* watchSearch() {
  yield takeLatest(actionTypes.SEARCH.REQUEST, search);
}

export {
  watchSearch
}