import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listRequest, batchDeleteRequest } from 'actions/quotes';
import { QUOTE_STATUS_OPTIONS } from 'misc/config';
import { getQuotes, getQuoteListPageSize, getQuoteListCurrentPage  } from 'selectors/lists';
import { isQuoteListLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import QuoteList from './presenter';


class QuoteListContainer extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = {
      status: null,
      selected: [],
    }
  }
  
  componentDidMount() {
    const params = { page: this.props.currentPage };
    this.props.listRequest(params);
  }

  handlePageSelect = (page) => {
    const params = { page, status: this.state.status }
    this.props.listRequest(params);
  }

  handleStatusChange = (value) => {
    this.setState({ status: value })
    const params = { page: 1, status: value };
    this.props.listRequest(params);
  }

  handleSelect = (e) => {
    const { checked, value } = e.target;
    const newValue = [...this.state.selected];
    checked
      ? newValue.push(value)
      : newValue.splice(newValue.indexOf(value), 1)
    this.setState({ selected: newValue });
  }

  handleDelete = () => {
    this.props.batchDeleteRequest({ items: this.state.selected });
    this.setState({ selected: [] });
  }
  
  render() {
    return <QuoteList
      {...this.props}
      selected={this.state.selected}
      status={this.state.status}
      handlePageSelect={this.handlePageSelect}
      handleSelect={this.handleSelect}
      handleDelete={this.handleDelete}
      handleStatusChange={this.handleStatusChange} />
  }
}


const mapStateToProps = (state) => {
  return {
    quotes: getQuotes(state),
    isLoading: isQuoteListLoading(state),
    pageSize: getQuoteListPageSize(state),
    currentPage: getQuoteListCurrentPage(state),
    statusOptions: QUOTE_STATUS_OPTIONS
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest,
    batchDeleteRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteListContainer);
