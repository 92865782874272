import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';
import { MULTIPLE_FILE_UPLOAD_MODAL } from 'misc/constants';
import { jobReferenceListRequest } from 'actions/jobs/common';
import {
  jobReferenceUploadRequest,
  jobReferenceRemoveRequest,
  referenceDownloadRequest,
} from 'actions/documents'
import { getJobReferencesById } from 'selectors/lists';
import { isJobReferenceListLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobReference from './presenter';


class JobReferenceContainer extends PureRenderComponent {
  
  componentDidMount() {
    this.props.jobReferenceListRequest(this.props.jobId);
  }

  showUploadModal = () => {
    this.props.show(MULTIPLE_FILE_UPLOAD_MODAL, {
      title: 'Job Specific Reference',
      handleUpload: this.handleUpload
    })
  }
  
  handleUpload = (files) => {
    this.props.jobReferenceUploadRequest(this.props.jobId, files);
  }

  handleDownload = (file) => {
    this.props.referenceDownloadRequest(file);
  }
  
  handleRemove = (file) => {
    this.props.jobReferenceRemoveRequest(this.props.jobId, file)
  }

  render() {
    return (
      <JobReference
        {...this.props}
        showUploadModal={this.showUploadModal}
        handleDownload={this.handleDownload}
        handleUpload={this.handleUpload}
        handleRemove={this.handleRemove} />
    )
  }
}

const mapStateToProps = (state, props) => {
  const id = props.jobId;
  return {
    files: getJobReferencesById(state, id),
    isLoading: isJobReferenceListLoading(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    jobReferenceListRequest,
    jobReferenceUploadRequest,
    jobReferenceRemoveRequest,
    referenceDownloadRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobReferenceContainer)
