import { delay } from 'redux-saga';
import {takeLatest, call, put, fork} from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import {hide, show} from 'redux-modal';
import { reset, stopSubmit } from 'redux-form';
import API from 'services/MemoryApi';
import * as actions from 'actions/memories';
import * as schemas from 'schemas/memories';
import * as actionTypes from 'actionTypes/memories';
import * as entityActions from 'actions/entities';
import * as listActions from 'actions/lists';
import * as constants from 'misc/constants';
import { setLoading } from 'actions/loading';
import {setTabTitle} from "actions/globalTabs";
import {tabConfig, XLSX_TYPE} from "misc/config";
import * as coreSagas from "sagas/core";
import {saveFile} from "misc/utils";
import {setDownloadProgress} from "actions/core";
import { successNotification } from 'sagas/core';


function* search(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.MEMORY_SEARCH, true));
  try {
    const response = yield call(API.search, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data.results), [schemas.memorySchema]);
    const totalCount = response.data.count ? response.data.count : 1;
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setPaginationResult(
      constants.MEMORY_SEARCH,
      params.page,
      normalized.result,
      totalCount
    ));
    yield put(setLoading(constants.MEMORY_SEARCH, false));
  } catch (error) {
    yield put(actions.searchFailure(error));
    yield put(setLoading(constants.MEMORY_SEARCH, false));
  }
}

function* count(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.MEMORY_COUNT, true));
  yield call(delay, 700);
  try {
    const response = yield call(API.getCount, decamelizeKeys(params));
    const count = response.data.count;
    yield put(listActions.setHits(constants.MEMORY_SEARCH, count));
    yield put(setLoading(constants.MEMORY_COUNT, false));
  } catch (error) {
    yield put(actions.countFailure(error));
    yield put(setLoading(constants.MEMORY_COUNT, false));
  }
}

function* searchList(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.MEMORY_SEARCH_LIST, true));
  try {
    const response = yield call(API.searchList, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data.results), [schemas.memorySearchSchema]);
    const totalCount = response.data.count ? response.data.count : 1;
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setPaginationResult(
      constants.MEMORY_SEARCH_LIST,
      params.page,
      normalized.result,
      totalCount
    ));
    yield put(setLoading(constants.MEMORY_SEARCH_LIST, false));
  } catch (error) {
    yield put(actions.searchListFailure(error));
    yield put(setLoading(constants.MEMORY_SEARCH_LIST, false));
  }
}

function* searchCreate(action) {
  const { values } = action.payload;
  yield put(setLoading(constants.TRANSLATION_MEMORY_SEARCH_SAVE_MODAL, true));
  try {
    const response = yield call(API.searchCreate, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.memorySearchSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.addPaginationItem(
      constants.MEMORY_SEARCH_LIST,
      normalized.result
    ));
    yield put(hide(constants.TRANSLATION_MEMORY_SEARCH_SAVE_MODAL, false));
    yield put(setLoading(constants.TRANSLATION_MEMORY_SEARCH_SAVE_MODAL, false));
    yield fork(successNotification, `${values.name} saved successfuly.`);
  } catch (error) {
    yield put(actions.searchCreateFailure(error));
    yield put(setLoading(constants.TRANSLATION_MEMORY_SEARCH_SAVE_MODAL, false));
  }
}

function* upload(action) {
  const { values } = action.payload;
  yield put(setLoading(constants.TRANSLATION_MEMORY_UPLOAD_MODAL, true));
  try {
    const response = yield call(API.upload, values);
    if (values.addList && response.data && response.data.search) {
      const normalized = normalize(camelizeKeys(response.data.search), schemas.memorySearchSchema);
      yield put(entityActions.merge(normalized.entities));
      yield put(listActions.addPaginationItem(
        constants.MEMORY_SEARCH_LIST,
        normalized.result
      ));
    }
    yield put(hide(constants.TRANSLATION_MEMORY_UPLOAD_MODAL));
    yield put(reset(constants.TRANSLATION_MEMORY_UPLOAD_FORM));
    yield put(setLoading(constants.TRANSLATION_MEMORY_UPLOAD_MODAL, false));
    yield fork(
      coreSagas.checkTaskProgress,
      response.data.task_id,
      'Memory registration in progress...',
      'Memory registration completed'
    );
  } catch (error) {
    yield put(actions.uploadFailure(error));
    if (error.response.status === 400) {
      yield put(stopSubmit(
        constants.TRANSLATION_MEMORY_UPLOAD_FORM,
        { _error: error.response.data.detail }
      ));
    }
    yield put(setLoading(constants.TRANSLATION_MEMORY_UPLOAD_MODAL, false));
  }
}

function* watchSearch() {
  yield takeLatest(actionTypes.SEARCH.REQUEST, search)
}

function* watchCount() {
  yield takeLatest(actionTypes.COUNT.REQUEST, count)
}

function* watchSearchList() {
  yield takeLatest(actionTypes.SEARCH_LIST.REQUEST, searchList);
}

function* watchSearchCreate() {
  yield takeLatest(actionTypes.SEARCH_CREATE.REQUEST, searchCreate);
}

function* watchUpload() {
  yield takeLatest(actionTypes.UPLOAD.REQUEST, upload);
}



function* searchUpdate(action) {
  const { values } = action.payload;
  yield put(setLoading(constants.TRANSLATION_MEMORY_DETAIL_SETTINGS_MODAL, true));
  try {
    const response = yield call(API.searchUpdate, values.id, decamelizeKeys(values.data));
    const normalized = normalize(camelizeKeys(response.data), schemas.memorySearchSchema);
    const tabId = constants.TRANSLATION_MEMORY_DETAIL + '/' + values.id;
    const data = normalized.entities['memorySearches'][values.id];
    yield put(entityActions.sync('memorySearches', values.id, data));
    // yield put(entityActions.merge(normalized.entities));
    yield put(setTabTitle(tabId, tabConfig[constants.TRANSLATION_MEMORY_DETAIL].title + ': ' + values.data.name));
    yield put(hide(constants.TRANSLATION_MEMORY_DETAIL_SETTINGS_MODAL));
    yield put(reset(constants.TRANSLATION_MEMORY_DETAIL_SETTINGS_FORM));
    yield put(setLoading(constants.TRANSLATION_MEMORY_DETAIL_SETTINGS_MODAL, false));
  } catch (error) {
    yield put(actions.searchUpdateFailure(error));
    yield put(setLoading(constants.TRANSLATION_MEMORY_DETAIL_SETTINGS_MODAL, false));
  }
}
function* watchSearchUpdate() {
  yield takeLatest(actionTypes.SEARCH_UPDATE.REQUEST, searchUpdate);
}

function* searchClear(action) {
  const { values } = action.payload;
  yield put(setLoading(constants.TRANSLATION_MEMORY_DETAIL_CLEAR_MODAL, true));
  try {
    const response = yield call(API.searchClear, values.id, decamelizeKeys(values.data));
    console.log('searchClear - response', response);
    // const normalized = normalize(camelizeKeys(response.data), schemas.memorySearchSchema);
    // yield put(entityActions.merge(normalized.entities));
    yield put(hide(constants.TRANSLATION_MEMORY_DETAIL_CLEAR_MODAL));
    yield put(setLoading(constants.TRANSLATION_MEMORY_DETAIL_CLEAR_MODAL, false));
  } catch (error) {
    yield put(actions.searchClearFailure(error));
    yield put(setLoading(constants.TRANSLATION_MEMORY_DETAIL_CLEAR_MODAL, false));
  }
}
function* watchSearchClear() {
  yield takeLatest(actionTypes.SEARCH_CLEAR.REQUEST, searchClear);
}


function* searchDelete(action) {
  const message = `Are you sure to delete selected Translation Memory List?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    const { values } = action.payload;
    yield put(setLoading(constants.CONFIRM_MODAL, true))
    try {
      yield call(API.searchDelete, decamelizeKeys(values));
      yield put(actions.searchListRequest({ page: 1 }))
      yield fork(coreSagas.successNotification, 'Translation Memory List deleted successfuly.');
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.searchDeleteFailure(error));
      yield put(setLoading(constants.MEMORY_SEARCH_LIST, false));
    }
  }
}
function* watchSearchDelete() {
  yield takeLatest(actionTypes.SEARCH_DELETE.REQUEST, searchDelete);
}

function* importTmx(action) {
  const { values } = action.payload;
  yield put(setLoading(constants.TRANSLATION_MEMORY_DETAIL_IMPORT_MODAL, true));
  try {
    // todo:
    // const response = yield call(API.upload, values);
    // if (values.addList && response.data) {
    //   const normalized = normalize(camelizeKeys(response.data), schemas.memorySearchSchema);
    //   yield put(entityActions.merge(normalized.entities));
    //   yield put(listActions.addPaginationItem(
    //     constants.MEMORY_SEARCH_LIST,
    //     normalized.result
    //   ));
    // }
    yield put(hide(constants.TRANSLATION_MEMORY_DETAIL_IMPORT_MODAL));
    yield put(reset(constants.TRANSLATION_MEMORY_DETAIL_IMPORT_FORM));
    yield put(setLoading(constants.TRANSLATION_MEMORY_DETAIL_IMPORT_MODAL, false));
  } catch (error) {
    yield put(actions.uploadFailure(error));
    yield put(setLoading(constants.TRANSLATION_MEMORY_DETAIL_IMPORT_MODAL, false));
  }
}
function* watchImportTmx() {
  yield takeLatest(actionTypes.SEARCH_IMPORT_TMX.REQUEST, importTmx);
}

function* exportExcel(action) {
  yield put(show(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  const { id, name } = action.payload;
  try {
    const [downloadPromise, chan] = yield call(
      coreSagas.createDownloader,
      API.exportExcel,
      id
    );
    yield fork(coreSagas.watchDownloadProgress, chan);
    const response = yield call(() => downloadPromise);
    yield call(saveFile, response.data, `${name}.xlsx`, XLSX_TYPE);
    yield put(actions.exportExcelSuccess());
    yield delay(1000);
    yield put(hide(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  } catch (error) {
    yield put(actions.exportExcelFailure(error));
  } finally {
    yield put(setDownloadProgress(0));
  }
}
function* watchExportExcel() {
  yield takeLatest(actionTypes.EXPORT_EXCEL.REQUEST, exportExcel);
}

function* exportTmx(action) {
  yield put(show(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  const { id, name } = action.payload;
  try {
    const [downloadPromise, chan] = yield call(
      coreSagas.createDownloader,
      API.exportTmx,
      id
    );
    yield fork(coreSagas.watchDownloadProgress, chan);
    const response = yield call(() => downloadPromise);
    yield call(saveFile, response.data, `${name}.tmx`, 'text/xml');
    yield put(actions.exportTmxSuccess());
    yield delay(1000);
    yield put(hide(constants.FILE_DOWNLOAD_PROGRESS_MODAL));
  } catch (error) {
    yield put(actions.exportTmxFailure(error));
  } finally {
    yield put(setDownloadProgress(0));
  }
}
function* watchExportTmx() {
  yield takeLatest(actionTypes.EXPORT_TMX.REQUEST, exportTmx);
}


function* itemUpdate(action) {
  const { values } = action.payload;
  yield put(setLoading(constants.TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL, true));
  try {

    // todo:
    // const response = yield call(API.searchUpdate, values.id, decamelizeKeys(values.data));
    // const normalized = normalize(camelizeKeys(response.data), schemas.memorySearchSchema);
    // yield put(entityActions.merge(normalized.entities));
    yield put(hide(constants.TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL));
    yield put(reset(constants.TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_FORM));
    yield put(setLoading(constants.TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL, false));
  } catch (error) {
    yield put(actions.searchUpdateFailure(error));
    yield put(setLoading(constants.TRANSLATION_MEMORY_DETAIL_ATTRIBUTES_MODAL, false));
  }
}
function* watchItemUpdate() {
  yield takeLatest(actionTypes.ITEM_UPDATE.REQUEST, itemUpdate);
}

export {
  watchSearch,
  watchCount,
  watchSearchList,
  watchSearchCreate,
  watchUpload,
  watchSearchUpdate,
  watchSearchClear,
  watchSearchDelete,
  watchImportTmx,
  watchExportExcel,
  watchExportTmx,
  watchItemUpdate
}