import React from 'react';
import { currencyFormatter } from 'misc/cellFormatters';
import { JOB_TYPE_OPTIONS } from 'misc/config';
import ListTable from 'components/core/ListTable';


const NumberReportTable = (props) => {

  const { results } = props;

  const columnDefs = [{
    headerName: '',
    field: 'title',
    pinned: 'left',
    width: 100,
    lockPinned: true,
    lockPosition: true,
    suppressMovable: true,
    suppressSorting: true,
    suppressFilter: true,
  }];

  JOB_TYPE_OPTIONS.forEach(option => {
    columnDefs.push({
      field: option.label,
      headerName: option.label,
      width: 50,
      suppressMovable: true,
      suppressSorting: true,
      suppressFilter: true,
      filter: 'agNumberColumnFilter',
      cellClass: 'ag-text-right',
      valueFormatter: currencyFormatter
    })
  });

  columnDefs.push({
    field: 'total',
    headerName: 'Total',
    cellClass: 'ag-text-right',
    width: 60,
    suppressMovable: true,
    suppressSorting: true,
    suppressFilter: true,
    pinned: 'right',
    lockPinned: true,
    valueFormatter: currencyFormatter
  });

  const getPinnedBottomRowData = () => {
    const { total } = props;
    return [total];
  }

  return (
    <ListTable
      wrapperHeight="81vh"
      columnDefs={columnDefs}
      pinnedBottomRowData={getPinnedBottomRowData()}
      rowData={results} />
  );
}

export default NumberReportTable;
