const PREFIX = 'lists';

// Falt List
export const SET_RESULT = `${PREFIX}/SET_RESULT`;
export const ADD_ITEM = `${PREFIX}/ADD_ITEM`;
export const REMOVE_ITEM = `${PREFIX}/REMOVE_ITEM`;
export const CLEAR_RESULT = `${PREFIX}/CLEAR_RESULT`;

// Nested List
export const SET_NESTED_RESULT = `${PREFIX}/SET_NESTED_RESULT`;
export const ADD_NESTED_ITEM = `${PREFIX}/ADD_NESTED_ITEM`;
export const ADD_NESTED_ITEMS = `${PREFIX}/ADD_NESTED_ITEMS`;
export const REMOVE_NESTED_ITEM = `${PREFIX}/REMOVE_NESTED_ITEM`;


export const SET_PAGINATION_RESULT = `${PREFIX}/SET_PAGINATION_RESULT`;
export const ADD_PAGINATION_ITEM = `${PREFIX}/ADD_PAGINATION_ITEM`;
export const REMOVE_PAGINATION_ITEM = `${PREFIX}/REMOVE_PAGINATION_ITEM`;
export const CLEAR_PAGINATION_RESULT = `${PREFIX}/CLEAR_PAGINATION_RESULT`;

// Daily List
export const SET_DAILY_RESULT = `${PREFIX}/SET_DAILY_RESULT`;

// Monthly List
export const SET_MONTHLY_RESULT = `${PREFIX}/SET_MONTHLY_RESULT`;

// Yearly list
export const SET_YEARLY_RESULT = `${PREFIX}/SET_YEARLY_RESULT`;
export const ADD_YEARLY_ITEM = `${PREFIX}/ADD_YEARLY_ITEM`;

// Report
export const SET_REPORT_RESULT = `${PREFIX}/SET_REPORT_RESULT`;
// Analysis Report
export const SET_ANALYSIS_REPORT_RESULT = `${PREFIX}/SET_ANALYSIS_REPORT_RESULT`;

export const SET_HITS = `${PREFIX}/SET_HITS`;