import * as types from 'actionTypes/invoices';
import * as utils from 'misc/utils';

// Create ======================================================================
const create = utils.createCreateAction(types.CREATE)
export const createRequest = create.requestFn;
export const createSuccess = create.successFn;
export const createFailure = create.failureFn;

// List ========================================================================
const list = utils.createMonthlyListAction(types.LIST);
export const listRequest = list.requestFn;
export const listSuccess = list.successFn;
export const listFailure = list.failureFn;

// Update ======================================================================
const update = utils.createUpdateAction(types.UPDATE)
export const updateRequest = update.requestFn;
export const updateSuccess = update.successFn;
export const updateFailure = update.failureFn;

// Patch =======================================================================
const patch = utils.createPatchAction(types.PATCH);
export const patchRequest = patch.requestFn;
export const patchSuccess = patch.successFn;
export const patchFailure = patch.failureFn;

// Print =======================================================================
export const printRequest = (id) => ({
  type: types.PRINT.REQUEST,
  payload: { id }
});

export const printSuccess = () => ({
  type: types.PRINT.SUCCESS,
});

export const printFailure = (error) => ({
  type: types.PRINT.FAILURE,
  payload: { error }
});