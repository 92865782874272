import React from 'react'
import FontAwesome from 'react-fontawesome';
import Dropzone from 'react-dropzone';
import { Button, Modal, Checkbox } from 'react-bootstrap';
import { MAX_FILE_SIZE, MIN_FILE_SIZE, DOCX_TYPE, XLSX_TYPE, PPTX_TYPE } from 'misc/config';
import { humanizeBytes } from 'misc/utils';

const MHTFileUpload = (props) => {

  const {
    file,
    formData,
    handleCancel,
    handleSlide,
    handleFormChange,
    handleDropSuccess
  } = props;

  const renderFile = () => {
    return file && (
      <li>
        <a>
          {file.name}
          <span className="pull-right" style={{ marginTop: '-2px' }}>
            <Button bsStyle="danger" onClick={handleCancel} bsSize="xs">
              <FontAwesome name="ban" className="mr-5" />Cancel
            </Button>
          </span>
        </a>
      </li>
    )
  }

  const renderOptions = () => {
    if (!file) return null;
    switch (file.type) {
      case DOCX_TYPE:
        return (
          <React.Fragment>
            <Checkbox
              checked={formData.includeDocumentHeader}
              onChange={e => handleFormChange('includeDocumentHeader', e.target.checked)}>Include header</Checkbox>
            <Checkbox
              checked={formData.includeDocumentFooter}
              onChange={e => handleFormChange('includeDocumentFooter', e.target.checked)}>Include footer</Checkbox>
            {/* <Checkbox
              checked={formData.includeDocumentComment}
              onChange={e => handleFormChange('includeDocumentComment', e.target.checked)}>Include comment</Checkbox> */}
            <Checkbox
              checked={formData.includeDocumentHyperlink}
              onChange={e => handleFormChange('includeDocumentHyperlink', e.target.checked)}>Include hyperlink</Checkbox>
            {/* <Checkbox
              checked={formData.includeDocumentAltText}
              onChange={e => handleFormChange('includeDocumentAltText', e.target.checked)}>Include alt text</Checkbox> */}
            {/* <Checkbox
              checked={formData.includeDocumentEmbeddedSheet}
              onChange={e => handleFormChange('includeDocumentEmbeddedSheet', e.target.checked)}>Include embedded sheet</Checkbox> */}
          </React.Fragment>
        )
      case XLSX_TYPE:
        return (
          <React.Fragment>
            <Checkbox
              checked={formData.includeSheetHeader}
              onChange={e => handleFormChange('includeSheetHeader', e.target.checked)}>Include header</Checkbox>
            <Checkbox
              checked={formData.includeSheetFooter}
              onChange={e => handleFormChange('includeSheetFooter', e.target.checked)}>Include footer</Checkbox>
            {/* <Checkbox
              checked={formData.includeSheetComment}
              onChange={e => handleFormChange('includeSheetComment', e.target.checked)}>Include comment</Checkbox> */}
            <Checkbox
              checked={formData.includeSheetHyperlink}
              onChange={e => handleFormChange('includeSheetHyperlink', e.target.checked)}>Include hyperlink</Checkbox>
            {/* <Checkbox
              checked={formData.includeSheetAltText}
              onChange={e => handleFormChange('includeSheetAltText', e.target.checked)}>Include alt text</Checkbox> */}
            {/* <Checkbox
              checked={formData.includeSheetHiddenContent}
              onChange={e => handleFormChange('includeSheetHiddenContent', e.target.checked)}>Include hidden content</Checkbox> */}
            <Checkbox
              checked={formData.includeSheetName}
              onChange={e => handleFormChange('includeSheetName', e.target.checked)}>Include sheet name</Checkbox>
          </React.Fragment>
        )
      case PPTX_TYPE:
        return (
          <React.Fragment>
            <Checkbox
              checked={formData.includeSlide}
              onChange={e => handleFormChange('includeSlide', e.target.checked)}>Include slide</Checkbox>
            {/* <Checkbox
              checked={formData.includeSlideHeader}
              onChange={e => handleFormChange('includeSlideHeader', e.target.checked)}>Include header</Checkbox> */}
            <Checkbox
              checked={formData.includeSlideFooter}
              onChange={e => handleFormChange('includeSlideFooter', e.target.checked)}>Include footer</Checkbox>
            {/* <Checkbox
              checked={formData.includeSlideComment}
              onChange={e => handleFormChange('includeSlideComment', e.target.checked)}>Include comment</Checkbox> */}
            <Checkbox
              checked={formData.includeSlideHyperlink}
              onChange={e => handleFormChange('includeSlideHyperlink', e.target.checked)}>Include hyperlink</Checkbox>
            {/* <Checkbox
              checked={formData.includeSlideAltText}
              onChange={e => handleFormChange('includeSlideAltText', e.target.checked)}>Include alt text</Checkbox> */}
            {/* <Checkbox
              checked={formData.includeSlideHiddenContent}
              onChange={e => handleFormChange('includeSlideHiddenContent', e.target.checked)}>Include hidden content</Checkbox> */}
            <Checkbox
              checked={formData.includeSlideNotes}
              onChange={e => handleFormChange('includeSlideNotes', e.target.checked)}>Include notes</Checkbox>
            {/* <Checkbox
              checked={formData.includeSlideMaster}
              onChange={e => handleFormChange('includeSlideMaster', e.target.checked)}>Include master</Checkbox> */}
            {/* <Checkbox
              checked={formData.includeSlideEmbeddedSheet}
              onChange={e => handleFormChange('includeSlideEmbeddedSheet', e.target.checked)}>Include embedded sheet</Checkbox> */}
          </React.Fragment>
        )
      default:
        return null;
    }
  }

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title>Upload Source File for MHT</Modal.Title>
      </Modal.Header>
      <Modal.Body className="file-upload-modal no-padding">
        <Dropzone
          name="document"
          accept={`${DOCX_TYPE}, ${XLSX_TYPE}, ${PPTX_TYPE}`}
          onDropAccepted={handleDropSuccess}
          onDropRejected={() => {}}
          className="file-upload"
          activeClassName="active"
          maxSize={MAX_FILE_SIZE}
          minSize={MIN_FILE_SIZE}
          multiple={false}>
          <p className="text-center">Drag file or click here to upload a file.<br />Max file upload size is <b>{humanizeBytes(MAX_FILE_SIZE)}</b></p>
        </Dropzone>
        <ul className="nav nav-pills nav-stacked">
          {renderFile()}
        </ul>
        <div className="ml-10 mr-10">
          {renderOptions()}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="primary"
          bsSize="sm"
          disabled={!file}
          onClick={() => handleSlide(1)}
          type="button">
          Next<FontAwesome name="angle-right" className="ml-5" />
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
}

export default MHTFileUpload;