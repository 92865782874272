import React from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { reset, change, getFormValues } from 'redux-form';
import { JOB_SEARCH, JOB_SEARCH_FORM } from 'misc/constants';
import { jobSearchRequest, setQuickEdit } from 'actions/jobs/managers';
import { getJobSearchFormInitialValues } from 'selectors/forms';
import { getSearchJobs, getJobSearchPageSize, getJobSearchCurrentPage, } from 'selectors/lists';
import { clearPaginationResult } from 'actions/lists';
import { isJobSearchLoading } from 'selectors/loading';
import { isCoordinator } from 'selectors/auth';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobSearch from './presenter';


class JobSearchContainer extends PureRenderComponent {

  componentWillUnmount() {
    this.handleReset();
    this.props.clearPaginationResult(JOB_SEARCH)
  }

  handleSearch = (values) => {
    const formData = { ...values, page: 1 };
    this.props.jobSearchRequest(formData);
  }

  handlePageSelect = (page) => {
    const formData = { ...this.props.formValues, page }
    this.props.jobSearchRequest(formData);
  }
  
  handleReset = () => {
    this.props.reset(this.props.form);
  }

  handleSelectionChange = (event) => {
    const selectedRows = event.api.getSelectedRows();
    selectedRows.length > 0 &&
    this.props.setQuickEdit(JOB_SEARCH, selectedRows[0].id);
  }
  
  render() {
    return (
      <JobSearch
        {...this.props}
        quickEditName={JOB_SEARCH}
        handleReset={this.handleReset}
        handleSearch={this.handleSearch}
        handlePageSelect={this.handlePageSelect}
        handleSelectionChange={this.handleSelectionChange} />
    );
  }
}

const mapStateToProps = (state) => {
  const form = JOB_SEARCH_FORM;
  const formValues = getFormValues(form)(state);
  return {
    form,
    formValues,
    jobs: getSearchJobs(state),
    isLoading: isJobSearchLoading(state),
    initialValues: getJobSearchFormInitialValues(state),
    isCoordinator: isCoordinator(state),
    pageSize: getJobSearchPageSize(state),
    currentPage: getJobSearchCurrentPage(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    reset,
    change,
    jobSearchRequest,
    setQuickEdit,
    clearPaginationResult
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobSearchContainer);