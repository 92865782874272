import * as constants from './constants';

export const SERVICE_TYPES = [
  'Translation',
  'NativeCheck',
  'CrossCheck',
  'Request and Question',
  'Tape transcription',
  'Interpreting'
]
export const PREVIEW_VIEWER_URL = 'https://docs.google.com/gview?url='

export const DEFAULT_YEAR_FORMAT = 'YYYY';
export const DEFAULT_MONTH_FORMAT = 'YYYY-MM';
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'

export const DEFAULT_MEMORY_SUGGEST_RATIO = 80;

export const MEMORY_SUGGEST_RATIOS = [
  { label: '80%', value: 80 },
  { label: '60%', value: 60 },
]

export const PER_PAGE_ITEM = 50;

// File upload =================================================================
export const MAX_FILE_SIZE = 50000000;
export const MIN_FILE_SIZE = 1;
export const LARGE_FILE_SIZE = 30000000;

// ag grid =====================================================================
export const DEFAULT_TABLE_WRAPPER_HEIGHT = '87vh';
export const DEFAULT_TABLE_ROW_HEIGHT = 30;
export const NESTED_TABLE_ROW_HEIGHT = 42;

// Auto Refresh ================================================================
export const EMPLOYEE_SCHEDULE_REFRESH_INTERVAL = 1000 * 60 * 5;


// Notification Messages =======================================================
export const TEMPLATE_CREATE_SUCCESS_MESSAGE = 'Template created successfully.';
export const TEMPLATE_UPDATE_SUCCESS_MESSAGE = 'Template updated successfully.';
export const TEMPLATE_REMOVE_SUCCESS_MESSAGE = 'Template deleted successfully.';

export const DOCX_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const XLSX_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const PPTX_TYPE = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
export const TEXT_TYPE = 'plain/text'

// Options =====================================================================
export const JOB_SCHEDULE_DEADLINES = [
  { label: 'All FE Deadline', value: 'final_editor'},
  { label: 'DTP Deadline', value: 'dtp'},
  { label: '2nd DTP Deadline', value: 'second_dtp'},
  { label: 'Client Deadline', value: 'client'},
  { label: 'Translator Deadline', value: 'translator'},
  { label: 'Editor Deadline', value: 'editor'},
  { label: 'Checker Deadline', value: 'checker'},
  { label: 'ToTra Deadline', value: 'totra'},
]

export const JOB_TYPE_OPTIONS = [
  { label: 'LS', value: 'LS' },
  { label: 'MD', value: 'MD' },
  { label: 'IR', value: 'IR' },
  { label: 'TR', value: 'TR' },
  { label: 'TT', value: 'TT' },
  { label: 'NT', value: 'NT' },
  { label: 'MH', value: 'MH' }
]

export const JOB_LEVEL_OPTIONS = [
  { label: 'L1', value: 'L1' },
  { label: 'L2', value: 'L2' },
  { label: 'L3', value: 'L3' },
  { label: 'L4', value: 'L4' }
]

export const JOB_STATUS_OPTIONS = [
  { label: "Job Created", value: "Job Created" },
  { label: "Sent To ToTra", value: "Sent To ToTra" },
  { label: "ToTra Now", value: "ToTra Now" },
  { label: "Validating ToTra", value: "Validating ToTra" },
  { label: "Sent To Translator", value: "Sent To Translator" },
  { label: "Translating Now", value: "Translating Now" },
  { label: "Validating Translation", value: "Validating Translation" },
  { label: "Sent To Editor", value: "Sent To Editor" },
  { label: "Editing Now", value: "Editing Now" },
  { label: "Validating Editing", value: "Validating Editing" },
  { label: "Sent To Checker", value: "Sent To Checker" },
  { label: "Checking Now", value: "Checking Now" },
  { label: "Validating Checking", value: "Validating Checking" },
  { label: "Sent To Final Editor", value: "Sent To Final Editor" },
  { label: "Final Editing Now", value: "Final Editing Now" },
  { label: "Validating Final Editing", value: "Validating Final Editing" },
  { label: "Sent To Second Final Editor", value: "Sent To Second Final Editor" },
  { label: "Second Final Editing Now", value: "Second Final Editing Now" },
  { label: "Validating Second Final Editing", value: "Validating Second Final Editing" },
  { label: "Sent To DTP", value: "Sent To DTP" },
  { label: "DTP Now", value: "DTP Now" },
  { label: "Validating DTP", value: "Validating DTP" },
  { label: "Sent To Second DTP", value: "Sent To Second DTP" },
  { label: "Second DTP Now", value: "Second DTP Now" },
  { label: "Validating Second DTP", value: "Validating Second DTP" },
  { label: "AE Validating", value: "AE Validating" },
  { label: "Invoicing Now", value: "Invoicing Now" },
  { label: "Job Finished", value: "Job Finished" },
  { label: "Job Canceled", value: "Job Canceled" },
]

export const WEEKDAYS = [
  { label: 'Sunday', value: 0, key: 'sunday' },
  { label: 'Monday', value: 1, key: 'monday' },
  { label: 'Tuesday', value: 2, key: 'tuesday' },
  { label: 'Wednesday', value: 3, key: 'wednesday' },
  { label: 'Thursday', value: 4, key: 'thursday' },
  { label: 'Friday', value: 5, key: 'friday' },
  { label: 'Saturday', value: 6, key: 'saturday' },
]

export const SCHEDULE_TIMES = [
  { label: "06:00", value: "06:00:00" },
  { label: "06:30", value: "06:30:00" },
  { label: "07:00", value: "07:00:00" },
  { label: "07:30", value: "07:30:00" },
  { label: "08:00", value: "08:00:00" },
  { label: "08:30", value: "08:30:00" },
  { label: "09:00", value: "09:00:00" },
  { label: "09:30", value: "09:30:00" },
  { label: "10:00", value: "10:00:00" },
  { label: "10:30", value: "10:30:00" },
  { label: "11:00", value: "11:00:00" },
  { label: "11:30", value: "11:30:00" },
  { label: "12:00", value: "12:00:00" },
  { label: "12:30", value: "12:30:00" },
  { label: "13:00", value: "13:00:00" },
  { label: "13:30", value: "13:30:00" },
  { label: "14:00", value: "14:00:00" },
  { label: "14:30", value: "14:30:00" },
  { label: "15:00", value: "15:00:00" },
  { label: "15:30", value: "15:30:00" },
  { label: "16:00", value: "16:00:00" },
  { label: "16:30", value: "16:30:00" },
  { label: "17:00", value: "17:00:00" },
  { label: "17:30", value: "17:30:00" },
  { label: "18:00", value: "18:00:00" },
  { label: "18:30", value: "18:30:00" },
  { label: "19:00", value: "19:00:00" },
  { label: "19:30", value: "19:30:00" },
  { label: "20:00", value: "20:00:00" },
  { label: "20:30", value: "20:30:00" },
  { label: "21:00", value: "21:00:00" },
  { label: "21:30", value: "21:30:00" },
  { label: "22:00", value: "22:00:00" },
  { label: "22:30", value: "22:30:00" },
  { label: "23:00", value: "23:00:00" },
  { label: "23:30", value: "23:30:00" },
  { label: "00:00", value: "00:00:00" },
  { label: "00:30", value: "00:30:00" },
  { label: "01:00", value: "01:00:00" },
  { label: "01:30", value: "01:30:00" },
  { label: "02:00", value: "02:00:00" },
  { label: "02:30", value: "02:30:00" },
  { label: "03:00", value: "03:00:00" },
  { label: "03:30", value: "03:30:00" },
  { label: "04:00", value: "04:00:00" },
  { label: "04:30", value: "04:30:00" },
  { label: "05:00", value: "05:00:00" },
  { label: "05:30", value: "05:30:00" },
]

export const SEARCH_METHOD_OPTIONS = [
  { label: 'Priority', 'value': 'priority' },
  { label: 'Filter', 'value': 'filter' }
]


export const getTabByRole = (roleKey) => {
  const tabMap = {
    'totra': constants.TOTRA_JOB_DETAIL,
    'translator': constants.TRANSLATOR_JOB_DETAIL,
    'editor': constants.EDITOR_JOB_DETAIL,
    'checker': constants.CHECKER_JOB_DETAIL,
    'finalEditor': constants.FINAL_EDITOR_JOB_DETAIL,
    'secondFinalEditor': constants.SECOND_FINAL_EDITOR_JOB_DETAIL,
    'dtp': constants.DTP_JOB_DETAIL,
    'secondDtp': constants.SECOND_DTP_JOB_DETAIL
  }
  return tabMap[roleKey];
}

const generateEmployeeStatus = (who, ing, noun) => {
  return {
    sent: `Sent To ${who}`,
    now: `${ing} Now`,
    validate: `Validating ${noun}`
  }
}

export const ASSIGNMENT_ROLE_KEY_MAP = {
  'ToTra': 'totra',
  'Translator': 'translator',
  'Editor': 'editor',
  'Checker': 'checker',
  'Final Editor': 'finalEditor',
  'Second Final Editor': 'secondFinalEditor',
  'DTP': 'dtp',
  'Second DTP': 'secondDtp',
}

export const ASSIGNMENT_ROLE_VALUE_MAP = {
  'totra': 'ToTra',
  'translator': 'Translator',
  'editor': 'Editor',
  'checker': 'Checker',
  'finalEditor': 'Final Editor',
  'secondFinalEditor': 'Second Final Editor',
  'dtp': 'DTP',
  'secondDtp': 'Second DTP',
}

export const MESSAGE_RECEIVER_ROLE_MAP = {
  'totra': 'ToTra',
  'translator': 'Translator',
  'editor': 'Editor',
  'checker': 'Checker',
  'finalEditor': 'Final Editor',
  'secondFinalEditor': 'Second Final Editor',
  'dtp': 'DTP',
  'secondDtp': 'Second DTP',
  'coordinator': 'Coordinator',
  'ae': 'AE',
}

export const EMPLOYEE_CURRENT_JOBS_MAP = {
  'totra': constants.TOTRA_CURRENT_JOBS,
  'translator': constants.TRANSLATOR_CURRENT_JOBS,
  'editor': constants.EDITOR_CURRENT_JOBS,
  'checker': constants.CHECKER_CURRENT_JOBS,
  'finalEditor': constants.FINAL_EDITOR_CURRENT_JOBS,
  'secondFinalEditor': constants.SECOND_FINAL_EDITOR_CURRENT_JOBS,
  'dtp': constants.DTP_CURRENT_JOBS,
  'secondDtp': constants.SECOND_DTP_CURRENT_JOBS,
}

export const EMPLOYEE_STATUS_MAP = {
  'totra': generateEmployeeStatus('ToTra', 'ToTra', 'ToTra'),
  'translator': generateEmployeeStatus('Translator', 'Translating', 'Translation'),
  'editor': generateEmployeeStatus('Editor', 'Editing', 'Editing'),
  'checker': generateEmployeeStatus('Checker', 'Checking', 'Checking'),
  'finalEditor': generateEmployeeStatus('Final Editor', 'Final Editing', 'Final Editing'),
  'secondFinalEditor': generateEmployeeStatus('Second Final Editor', 'Second Final Editing', 'Second Final Editing'),
  'dtp': generateEmployeeStatus('DTP', 'DTP', 'DTP'),
  'secondDtp': generateEmployeeStatus('Second DTP', 'Second DTP', 'Second DTP'),
}

const generateEmployeeProcess = (role) => {
  return {
    nextStatus: EMPLOYEE_STATUS_MAP[role].validate,
    listName: EMPLOYEE_CURRENT_JOBS_MAP[role]
  }
}

export const EMPLOYEE_PROCESS_MAP = {
  totra: generateEmployeeProcess('totra'),
  translator: generateEmployeeProcess('translator'),
  editor: generateEmployeeProcess('editor'),
  checker: generateEmployeeProcess('checker'),
  finalEditor: generateEmployeeProcess('finalEditor'),
  secondFinalEditor: generateEmployeeProcess('secondFinalEditor'),
  dtp: generateEmployeeProcess('dtp'),
  secondDtp: generateEmployeeProcess('secondDtp'),
}

export const tabConfig = {

  // Calendar ==================================================================
  [constants.CALENDAR]: {
    id: constants.CALENDAR,
    title: 'Calendar',
    componentName: constants.CALENDAR,
    componentPath: 'calendars/Calendar',
  },

  // Clients ===================================================================
  [constants.CLIENT_CREATE]: {
    id: constants.CLIENT_CREATE,
    title: 'Add New Client',
    componentName: constants.CLIENT_CREATE,
    componentPath: 'clients/ClientCreate',
  },

  [constants.CLIENT_LIST]: {
    id: constants.CLIENT_LIST,
    title: 'Client List',
    componentName: constants.CLIENT_LIST,
    componentPath: 'clients/ClientList',
    allowRefresh: true
  },

  [constants.CLIENT_DETAIL]: {
    id: constants.CLIENT_DETAIL,
    title: 'Client Detail',
    componentName: constants.CLIENT_DETAIL,
    componentPath: 'clients/ClientDetail',
  },

  // Users =====================================================================
  [constants.USER_LIST]: {
    id: constants.USER_LIST,
    title: 'User List',
    componentName: constants.USER_LIST,
    componentPath: 'users/UserList',
    allowRefresh: true
  },

  [constants.USER_CREATE]: {
    id: constants.USER_CREATE,
    title: 'Add New User',
    componentName: constants.USER_CREATE,
    componentPath: 'users/UserCreate',
  },

  [constants.USER_DETAIL]: {
    id: constants.USER_DETAIL,
    title: 'User Detail',
    componentName: constants.USER_DETAIL,
    componentPath: 'users/UserDetail',
  },

  // Manager Jobs ==============================================================
  [constants.JOB_CREATE]: {
    id: constants.JOB_CREATE,
    title: 'New Job',
    componentName: constants.JOB_CREATE,
    componentPath: 'jobs/managers/JobCreate',
  },

  [constants.JOB_DUPLICATE]: {
    id: constants.JOB_DUPLICATE,
    title: 'Duplicate Job',
    componentName: constants.JOB_DUPLICATE,
    componentPath: 'jobs/managers/JobDuplicate',
  },

  [constants.JOB_SPLIT]: {
    id: constants.JOB_SPLIT,
    title: 'Split Job',
    componentName: constants.JOB_SPLIT,
    componentPath: 'jobs/managers/JobSplit',
  },

  [constants.JOB_CREATE_BY_TEMPLATE]: {
    id: constants.JOB_CREATE_BY_TEMPLATE,
    title: 'New Job',
    componentName: constants.JOB_CREATE_BY_TEMPLATE,
    componentPath: 'jobs/managers/JobCreateByTemplate',
  },

  [constants.JOB_ISSUE]: {
    id: constants.JOB_ISSUE,
    title: 'Job Issue',
    componentName: constants.JOB_ISSUE,
    componentPath: 'jobs/managers/JobIssue',
  },

  [constants.JOB_SPLIT_ISSUE]: {
    id: constants.JOB_SPLIT_ISSUE,
    title: 'Job Split Issue',
    componentName: constants.JOB_SPLIT_ISSUE,
    componentPath: 'jobs/managers/JobSplitIssue',
  },

  [constants.JOB_EDIT]: {
    id: constants.JOB_EDIT,
    title: 'Job Edit',
    componentName: constants.JOB_EDIT,
    componentPath: 'jobs/managers/JobEdit',
  },

  [constants.JOB_SCHEDULE]: {
    id: constants.JOB_SCHEDULE,
    title: 'Daily Schedule',
    componentName: constants.JOB_SCHEDULE,
    componentPath: 'jobs/managers/JobSchedule',
    allowRefresh: true
  },

  [constants.JOB_SEARCH]: {
    id: constants.JOB_SEARCH,
    title: 'Job Search',
    componentName: constants.JOB_SEARCH,
    componentPath: 'jobs/managers/JobSearch',
  },

  [constants.JOB_DETAIL]: {
    id: constants.JOB_DETAIL,
    title: 'Job Detail',
    componentName: constants.JOB_DETAIL,
    componentPath: 'jobs/managers/JobDetail',
    allowRefresh: true
  },

  [constants.JOB_DRAFT_LIST]: {
    id: constants.JOB_DRAFT_LIST,
    title: 'Draft List',
    componentName: constants.JOB_DRAFT_LIST,
    componentPath: 'jobs/managers/JobDraftList',
    allowRefresh: true
  },

  [constants.JOB_DRAFT_EDIT]: {
    id: constants.JOB_DRAFT_EDIT,
    title: 'Draft Edit',
    componentName: constants.JOB_DRAFT_EDIT,
    componentPath: 'jobs/managers/JobDraftEdit',
  },

  // [constants.WORKING_LIST]: {
  //   id: constants.WORKING_LIST,
  //   title: 'Working List',
  //   componentName: constants.WORKING_LIST,
  //   componentPath: 'jobs/managers/JobWorkingList',
  //   allowRefresh: true
  // },

  [constants.DELIVERY_LIST]: {
    id: constants.DELIVERY_LIST,
    title: 'Delivery List',
    componentName: constants.DELIVERY_LIST,
    componentPath: 'jobs/managers/JobDeliveryList',
    allowRefresh: true
  },

  [constants.DELIVERY_DETAIL]: {
    id: constants.DELIVERY_DETAIL,
    title: 'Delivery Detail',
    componentName: constants.DELIVERY_DETAIL,
    componentPath: 'jobs/managers/JobDeliveryDetail',
  },

  [constants.PENDING_LIST]: {
    id: constants.PENDING_LIST,
    title: 'Pending List',
    componentName: constants.PENDING_LIST,
    componentPath: 'jobs/managers/JobPendingList',
    allowRefresh: true
  },

  [constants.PREPARING_LIST]: {
    id: constants.PREPARING_LIST,
    title: 'Preparing List',
    componentName: constants.PREPARING_LIST,
    componentPath: 'jobs/managers/JobPreparingList',
    allowRefresh: true
  },

  [constants.TOTRA_LIST]: {
    id: constants.TOTRA_LIST,
    title: 'ToTra List',
    componentName: constants.TOTRA_LIST,
    componentPath: 'jobs/managers/JobToTraList',
    allowRefresh: true
  },

  [constants.RNQ_LIST]: {
    id: constants.RNQ_LIST,
    title: 'RnQ List',
    componentName: constants.RNQ_LIST,
    componentPath: 'jobs/managers/JobRnQList',
    allowRefresh: true
  },

  // Templates =================================================================
  [constants.TEMPLATE_LIST]: {
    id: constants.TEMPLATE_LIST,
    title: 'Template List',
    componentName: constants.TEMPLATE_LIST,
    componentPath: 'templates/TemplateList',
    allowRefresh: true
  },

  [constants.TEMPLATE_EDIT]: {
    id: constants.TEMPLATE_EDIT,
    title: 'Template Edit',
    componentName: constants.TEMPLATE_EDIT,
    componentPath: 'templates/TemplateEdit',
  },

  // Invoices ==================================================================
  [constants.INVOICE_LIST]: {
    id: constants.INVOICE_LIST,
    title: 'Invoice List',
    componentName: constants.INVOICE_LIST,
    componentPath: 'invoices/InvoiceList',
    allowRefresh: true
  },

  // Payment Search ============================================================
  [constants.PAYMENT_SEARCH]: {
    id: constants.PAYMENT_SEARCH,
    title: 'Payment Search',
    componentName: constants.PAYMENT_SEARCH,
    componentPath: 'jobs/managers/PaymentSearch',
  },

  // Report ====================================================================
  [constants.SALES_REPORT]: {
    id: constants.SALES_REPORT,
    title: 'Sales & MHT Report',
    componentName: constants.SALES_REPORT,
    componentPath: 'accounting/SalesReport',
    allowRefresh: true
  },

  [constants.SALES_TARGET]: {
    id: constants.SALES_TARGET,
    title: 'Sales Target',
    componentName: constants.SALES_TARGET,
    componentPath: 'accounting/SalesTarget',
    allowRefresh: true
  },

  // MHT =======================================================================
  [constants.MHT_EDITOR]: {
    id: constants.MHT_EDITOR,
    title: 'MHT',
    componentName: constants.MHT_EDITOR,
    componentPath: 'mht/MHTEditor',
    allowRefresh: false,
  },
  [constants.TERM_SEARCH]: {
    id: constants.TERM_SEARCH,
    title: 'Term Search',
    componentName: constants.TERM_SEARCH,
    componentPath: 'mht/TermSearch',
    allowRefresh: true,
  },
  [constants.WORDLIST]: {
    id: constants.WORDLIST,
    title: 'Client WordList',
    componentName: constants.WORDLIST,
    componentPath: 'mht/WordList',
    allowRefresh: true
  },
  [constants.WORDLIST_DETAIL]: {
    id: constants.WORDLIST_DETAIL,
    title: 'WordList Editor',
    componentName: constants.WORDLIST_DETAIL,
    componentPath: 'mht/WordListDetail',
  },

  [constants.GLOSSARY_LIST]: {
    id: constants.GLOSSARY_LIST,
    title: 'Category',
    componentName: constants.GLOSSARY_LIST,
    componentPath: 'mht/Glossary',
    allowRefresh: true
  },
  [constants.GLOSSARY_DETAIL]: {
    id: constants.GLOSSARY_DETAIL,
    title: 'Glossary Editor',
    componentName: constants.GLOSSARY_DETAIL,
    componentPath: 'mht/GlossaryDetail',
  },
  [constants.TRANSLATION_MEMORY_SEARCH]: {
    id: constants.TRANSLATION_MEMORY_SEARCH,
    title: 'Translation Memory Search',
    componentName: constants.TRANSLATION_MEMORY_SEARCH,
    componentPath: 'mht/TranslationMemorySearch',
    allowRefresh: false
  },

  [constants.TRANSLATION_MEMORY_LIST]: {
    id: constants.TRANSLATION_MEMORY_LIST,
    title: 'Translation Memory List',
    componentName: constants.TRANSLATION_MEMORY_LIST,
    componentPath: 'mht/TranslationMemoryList',
    allowRefresh: true
  },

  [constants.TRANSLATION_MEMORY_DETAIL]: {
    id: constants.TRANSLATION_MEMORY_DETAIL,
    title: 'TM Editor',
    componentName: constants.TRANSLATION_MEMORY_DETAIL,
    componentPath: 'mht/TranslationMemoryDetail',
  },
  // Employee Jobs =============================================================
  [constants.EMPLOYEE_JOB_SCHEDULE]: {
    id: constants.EMPLOYEE_JOB_SCHEDULE,
    title: 'Daily Schedule',
    componentName: constants.EMPLOYEE_JOB_SCHEDULE,
    componentPath: 'jobs/employees/EmployeeJobSchedule',
    allowRefresh: true
  },

  [constants.TOTRA_CURRENT_JOBS]: {
    id: constants.TOTRA_CURRENT_JOBS,
    title: 'ToTra Current Jobs',
    componentName: constants.TOTRA_CURRENT_JOBS,
    componentPath: 'jobs/employees/EmployeeCurrentJobs/ToTraCurrentJobs',
    allowRefresh: true
  },

  [constants.TOTRA_JOB_DETAIL]: {
    id: constants.TOTRA_JOB_DETAIL,
    title: 'ToTra Job',
    componentName: constants.TOTRA_JOB_DETAIL,
    componentPath: 'jobs/employees/EmployeeJobDetail/ToTraJobDetail',
    allowRefresh: true
  },

  [constants.TRANSLATOR_CURRENT_JOBS]: {
    id: constants.TRANSLATOR_CURRENT_JOBS,
    title: 'Translator Current Jobs',
    componentName: constants.TRANSLATOR_CURRENT_JOBS,
    componentPath: 'jobs/employees/EmployeeCurrentJobs/TranslatorCurrentJobs',
    allowRefresh: true
  },

  [constants.TRANSLATOR_JOB_DETAIL]: {
    id: constants.TRANSLATOR_JOB_DETAIL,
    title: 'Translator Job',
    componentName: constants.TRANSLATOR_JOB_DETAIL,
    componentPath: 'jobs/employees/EmployeeJobDetail/TranslatorJobDetail',
    allowRefresh: true
  },

  [constants.EDITOR_CURRENT_JOBS]: {
    id: constants.EDITOR_CURRENT_JOBS,
    title: 'Editor Current Jobs',
    componentName: constants.EDITOR_CURRENT_JOBS,
    componentPath: 'jobs/employees/EmployeeCurrentJobs/EditorCurrentJobs',
    allowRefresh: true
  },

  [constants.EDITOR_JOB_DETAIL]: {
    id: constants.EDITOR_JOB_DETAIL,
    title: 'Editor Job',
    componentName: constants.EDITOR_JOB_DETAIL,
    componentPath: 'jobs/employees/EmployeeJobDetail/EditorJobDetail',
    allowRefresh: true
  },

  [constants.CHECKER_CURRENT_JOBS]: {
    id: constants.CHECKER_CURRENT_JOBS,
    title: 'Checker Current Jobs',
    componentName: constants.CHECKER_CURRENT_JOBS,
    componentPath: 'jobs/employees/EmployeeCurrentJobs/CheckerCurrentJobs',
    allowRefresh: true
  },

  [constants.CHECKER_JOB_DETAIL]: {
    id: constants.CHECKER_JOB_DETAIL,
    title: 'Checker Job',
    componentName: constants.CHECKER_JOB_DETAIL,
    componentPath: 'jobs/employees/EmployeeJobDetail/CheckerJobDetail',
    allowRefresh: true
  },

  [constants.FINAL_EDITOR_CURRENT_JOBS]: {
    id: constants.FINAL_EDITOR_CURRENT_JOBS,
    title: 'FE Current Jobs',
    componentName: constants.FINAL_EDITOR_CURRENT_JOBS,
    componentPath: 'jobs/employees/EmployeeCurrentJobs/FinalEditorCurrentJobs',
    allowRefresh: true
  },

  [constants.FINAL_EDITOR_JOB_DETAIL]: {
    id: constants.FINAL_EDITOR_JOB_DETAIL,
    title: 'FE Job',
    componentName: constants.FINAL_EDITOR_JOB_DETAIL,
    componentPath: 'jobs/employees/EmployeeJobDetail/FinalEditorJobDetail',
    allowRefresh: true
  },

  [constants.SECOND_FINAL_EDITOR_CURRENT_JOBS]: {
    id: constants.SECOND_FINAL_EDITOR_CURRENT_JOBS,
    title: '2nd FE Current Jobs',
    componentName: constants.SECOND_FINAL_EDITOR_CURRENT_JOBS,
    componentPath: 'jobs/employees/EmployeeCurrentJobs/SecondFinalEditorCurrentJobs',
    allowRefresh: true
  },

  [constants.SECOND_FINAL_EDITOR_JOB_DETAIL]: {
    id: constants.SECOND_FINAL_EDITOR_JOB_DETAIL,
    title: '2nd FE Job',
    componentName: constants.SECOND_FINAL_EDITOR_JOB_DETAIL,
    componentPath: 'jobs/employees/EmployeeJobDetail/SecondFinalEditorJobDetail',
    allowRefresh: true
  },

  [constants.DTP_CURRENT_JOBS]: {
    id: constants.DTP_CURRENT_JOBS,
    title: 'DTP Current Jobs',
    componentName: constants.DTP_CURRENT_JOBS,
    componentPath: 'jobs/employees/EmployeeCurrentJobs/DtpCurrentJobs',
    allowRefresh: true
  },
  [constants.DTP_JOB_DETAIL]: {
    id: constants.DTP_JOB_DETAIL,
    title: 'DTP Job',
    componentName: constants.DTP_JOB_DETAIL,
    componentPath: 'jobs/employees/EmployeeJobDetail/DJobDetail',
    allowRefresh: true
  },

  [constants.SECOND_DTP_CURRENT_JOBS]: {
    id: constants.SECOND_DTP_CURRENT_JOBS,
    title: '2nd DTP Current Jobs',
    componentName: constants.SECOND_DTP_CURRENT_JOBS,
    componentPath: 'jobs/employees/EmployeeCurrentJobs/SecondDtpCurrentJobs',
    allowRefresh: true
  },
  [constants.SECOND_DTP_JOB_DETAIL]: {
    id: constants.SECOND_DTP_JOB_DETAIL,
    title: '2nd DTP Job',
    componentName: constants.SECOND_DTP_JOB_DETAIL,
    componentPath: 'jobs/employees/EmployeeJobDetail/SecondDtpJobDetail',
    allowRefresh: true
  },


  [constants.EMPLOYEE_JOB_HISTORY]: {
    id: constants.EMPLOYEE_JOB_HISTORY,
    title: 'Job History',
    componentName: constants.EMPLOYEE_JOB_HISTORY,
    componentPath: 'jobs/employees/EmployeeJobHistory',
  },

  // Profile ===================================================================
  [constants.PROFILE]: {
    id: constants.PROFILE,
    title: 'Profile',
    componentName: constants.PROFILE,
    componentPath: 'settings/Profile',
  },

  // Customers ==================================================================
  [constants.CUSTOMER_CREATE]: {
    id: constants.CUSTOMER_CREATE,
    title: 'Add New Customer',
    componentName: constants.CUSTOMER_CREATE,
    componentPath: 'customers/CustomerCreate',
  },

  [constants.CUSTOMER_LIST]: {
    id: constants.CUSTOMER_LIST,
    title: 'Customer List',
    componentName: constants.CUSTOMER_LIST,
    componentPath: 'customers/CustomerList',
    allowRefresh: true
  },

  [constants.CUSTOMER_DETAIL]: {
    id: constants.CUSTOMER_DETAIL,
    title: 'Customer Detail',
    componentName: constants.CUSTOMER_DETAIL,
    componentPath: 'customers/CustomerDetail',
  },
  // Quotes ==================================================================
  [constants.QUOTE_LIST]: {
    id: constants.QUOTE_LIST,
    title: 'Quote List',
    componentName: constants.QUOTE_LIST,
    componentPath: 'quotes/QuoteList',
    allowRefresh: true
  },
  [constants.QUOTE_DETAIL]: {
    id: constants.QUOTE_DETAIL,
    title: 'Quote Detail',
    componentName: constants.QUOTE_DETAIL,
    componentPath: 'quotes/QuoteDetail',
    allowRefresh: true
  },
  // Unsupported Order ===============================================================
  [constants.UNSUPPORTED_ORDER_LIST]: {
    id: constants.UNSUPPORTED_ORDER_LIST,
    title: 'Unsupported Order List',
    componentName: constants.UNSUPPORTED_ORDER_LIST,
    componentPath: 'quotes/UnsupportedOrderList',
    allowRefresh: true
  },
  [constants.UNSUPPORTED_ORDER_DETAIL]: {
    id: constants.UNSUPPORTED_ORDER_DETAIL,
    title: 'Unsupported Order Detail',
    componentName: constants.UNSUPPORTED_ORDER_DETAIL,
    componentPath: 'quotes/UnsupportedOrderDetail',
    allowRefresh: true
  },
  // MHT Delivery  ==================================================================
  [constants.MHT_JOB_LIST]: {
    id: constants.MHT_JOB_LIST,
    title: 'MH Job List',
    componentName: constants.MHT_JOB_LIST,
    componentPath: 'jobs/managers/MHTJobList',
    allowRefresh: true
  },
  [constants.MHT_JOB_DETAIL]: {
    id: constants.MHT_JOB_DETAIL,
    title: 'MH Job Detail',
    componentName: constants.MHT_JOB_DETAIL,
    componentPath: 'jobs/managers/MHTJobDetail',
    allowRefresh: true
  },
}

export const LANGUAGE_OPTIONS = [
  { label: 'ja-JP', value: 'ja-JP', key: 'ja_jp', alias: 'JP' },
  { label: 'en-US', value: 'en-US', key: 'en_us', alias: 'EN' },
  { label: 'zh-CN', value: 'zh-CN', key: 'zh_cn', alias: 'CS' },
  { label: 'zh-TW', value: 'zh-TW', key: 'zh_tw', alias: 'CT' },
  { label: 'zh-HK', value: 'zh-HK', key: 'zh_hk', alias: 'HK' },
  { label: 'ko-KR', value: 'ko-KR', key: 'ko_kr', alias: 'KO' },
  { label: 'de-DE', value: 'de-DE', key: 'de_de', alias: 'GE' },
  { label: 'fr-FR', value: 'fr-FR', key: 'fr_fr', alias: 'FR' },
  { label: 'it-IT', value: 'it-IT', key: 'it_it', alias: 'IT' },
  { label: 'ru-RU', value: 'ru-RU', key: 'ru_ru', alias: 'RU' },
  { label: 'es-ES', value: 'es-ES', key: 'es_es', alias: 'SPE' },
  { label: 'es-419', value: 'es-419', key: 'es_419', alias: 'SPS' },
  { label: 'pt-PT', value: 'pt-PT', key: 'pt_pt', alias: 'PTE' },
  { label: 'pt-BR', value: 'pt-BR', key: 'pt_br', alias: 'PTS' },
  { label: 'tr-TR', value: 'tr-TR', key: 'tr_tr', alias: 'TR' },
  { label: 'ar001', value: 'ar-001', key: 'ar_001', alias: 'AR' },
  { label: 'nl-NL', value: 'nl-NL', key: 'nl_nl', alias: 'NL' },
  { label: 'ca-ES', value: 'ca-ES', key: 'ca_es', alias: 'CAT' },
  { label: 'pl-PL', value: 'pl-PL', key: 'pl_pl', alias: 'PL' },
  { label: 'sv-SE', value: 'sv-SE', key: 'sv_se', alias: 'SW' },
  { label: 'cs_CZ', value: 'cs_CZ', key: 'cs_cz', alias: 'CZ' },
  { label: 'fi-FI', value: 'fi-FI', key: 'fi_fi', alias: 'FIM' },
  { label: 'nb-NO', value: 'nb-NO', key: 'nb_no', alias: 'NO' },
  { label: 'uk-UA', value: 'uk-UA', key: 'uk_ua', alias: 'UK' },
  { label: 'da-DK', value: 'da-DK', key: 'da_dk', alias: 'DK' },
  { label: 'ro-RO', value: 'ro-RO', key: 'ro_ro', alias: 'RO' },
  { label: 'el-GR', value: 'el-GR', key: 'el_gr', alias: 'GR' },
  { label: 'hu-HU', value: 'hu-HU', key: 'hu_hu', alias: 'HU' },
  { label: 'sk-SK', value: 'sk-SK', key: 'sk_sk', alias: 'SK' },
  { label: 'th-TH', value: 'th-TH', key: 'th_th', alias: 'TH' },
  { label: 'hi-IN', value: 'hi-IN', key: 'hi_in', alias: 'HI' },
  { label: 'ms-MY', value: 'ms-MY', key: 'ms_my', alias: 'MY' },
  { label: 'my-MM', value: 'my-MM', key: 'my_mm', alias: 'MM' },
  { label: 'id-ID', value: 'id-ID', key: 'id_id', alias: 'ID' },
  { label: 'tl-X-SDL', value: 'tl-X-SDL', key: 'tl_x_sdl', alias: 'TL' },
  { label: 'ne-NP', value: 'ne-NP', key: 'ne_np', alias: 'NE' },
  { label: 'si-LK', value: 'si-LK', key: 'si_lk', alias: 'SI' },
  { label: 'vi-VN', value: 'vi-VN', key: 'vi_vn', alias: 'VI' },
  { label: 'mn-MN', value: 'mn-MN', key: 'mn_mn', alias: 'MN' },
]

export const QUOTE_STATUS_OPTIONS = [
  { label: 'Created', value: 'Created' },
  { label: 'Ordered', value: 'Ordered' },
]