import React from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { reset, change, getFormValues } from 'redux-form';
import { show } from 'redux-modal';
import { searchRequest } from 'actions/memories';
import { getMemories, getMemorySearchCurrentPage, getMemorySearchPageSize } from 'selectors/lists';
import { clearPaginationResult } from 'actions/lists';
import { isMemorySearchLoading } from 'selectors/loading';
import {
  MEMORY_SEARCH,
  TRANSLATION_MEMORY_SEARCH_FORM,
  TRANSLATION_MEMORY_SEARCH_SAVE_MODAL
} from 'misc/constants';
import PureRenderComponent from 'components/core/PureRenderComponent';
import TranslationMemorySearch from './presenter';

class TranslationMemorySearchContainer extends PureRenderComponent {

  componentWillUnmount() {
    this.handleReset();
    this.props.clearPaginationResult(MEMORY_SEARCH)
  }

  handleReset = () => {
    this.props.reset(this.props.form);
  }

  showSaveModal = () => {
    this.props.show(TRANSLATION_MEMORY_SEARCH_SAVE_MODAL, {
      formValues: this.props.formValues
    })
  }

  handleSearch = (values) => {
    const params = { ...values, page: 1 };
    this.props.searchRequest(params);
  }

  handlePageSelect = (page) => {
    const params = { ...this.props.formValues, page };
    this.props.searchRequest(params);
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <TranslationMemorySearch
        {...this.props}
        showSaveModal={this.showSaveModal}
        handleReset={this.handleReset}
        handleSearch={this.handleSearch}
        handlePageSelect={this.handlePageSelect} />
    )
  }
}

const mapStateToProps = (state) => {
  const form = TRANSLATION_MEMORY_SEARCH_FORM;
  const formValues = getFormValues(form)(state);
  const service = formValues && formValues.service;
  return {
    form,
    formValues,
    service,
    isLoading: isMemorySearchLoading(state),
    memories: getMemories(state),
    pageSize: getMemorySearchPageSize(state),
    currentPage: getMemorySearchCurrentPage(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    reset,
    change,
    show,
    searchRequest,
    clearPaginationResult,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationMemorySearchContainer);
