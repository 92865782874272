import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Button } from 'react-bootstrap';
import { Content, Box } from 'reactjs-admin-lte';
import Pagination from 'components/core/Pagination';
import TranslationMemoryListTable from './TranslationMemoryListTable';


const TranslationMemoryList = (props) => {

  const {
    memorySearches,
    showTranslationMemoryUploadModal,
    isLoading,
    currentPage,
    pageSize,
    selected,
    handleSelect,
    handleDelete,
    handlePageSelect,
    handleTabOpen
  } = props;

  return (
    <Content.Body>
      <div className="clearfix">
        <Button
          bsStyle="danger"
          bsSize="sm"
          type="button"
          className="mb-10 pull-right"
          disabled={selected.length === 0}
          onClick={handleDelete}>
          <FontAwesome name="times-circle" className="mr-5" />Delete
        </Button>
      </div>
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body noPadding={true}>
          <TranslationMemoryListTable
            handleSelect={handleSelect}
            handleTabOpen={handleTabOpen}
            memorySearches={memorySearches} />
        </Box.Body>
        <Box.Footer>
        <Button
          onClick={showTranslationMemoryUploadModal}
          bsStyle="primary">
          <FontAwesome name="plus" className="mr-5"/>Add New Translation Memory
        </Button>
        <Pagination
          currentPage={currentPage}
          totalPages={pageSize}
          onChange={handlePageSelect} />
        </Box.Footer>
      </Box>
    </Content.Body>
  )
}

export default TranslationMemoryList;