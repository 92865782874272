import isEmpty from 'lodash.isempty';
import keyBy from 'lodash.keyby'
import groupBy from 'lodash.groupby';
import merge from 'lodash.merge';
import { createSelector } from 'reselect';
import { getAEUsers } from 'selectors/users';
import { getSalesTargetCurrentYear } from 'selectors/filters';
import { getCurrentYearSalesTargets } from 'selectors/lists';


export const getSalesTargets = createSelector(
  [getCurrentYearSalesTargets, getSalesTargetCurrentYear, getAEUsers],
  (salesTargets, currentYear, users) => {
    const months = Array.from({ length: 12 }, (v, k) => {
      const month = k + 1 + '';
      const day = '01'
      return `${currentYear}-${month.padStart(2, 0)}-${day}`;
    })
    const dummy = months.map(month => {
      const dummyTmp = users
        .filter(user => !isEmpty(user))
        .map(user => ({
          price: 0,
          targetId: null,
          isAchieved: false,
          userId: user.id,
          username: user.username,
      }));
      return {
        month,
        ...keyBy(dummyTmp, 'username')
      }
    });
    const monthlySalesTarget = groupBy(salesTargets, 'month');
    const real = Object.keys(monthlySalesTarget).map(month => {

      const realTmp = monthlySalesTarget[month]
        .filter(st => !isEmpty(st.user))
        .map(st => ({
          price: st.price,
          targetId: st.id,
          isAchieved: st.isAchieved,
          userId: st.user.id,
          username: st.user.username,
        }));
      
      return {
        month,
        ...keyBy(realTmp, 'username')
      }
    });

    return Object.values(merge(
      keyBy(dummy, 'month'),
      keyBy(real, 'month')
    ))
  }
)


