import { schema } from 'normalizr';
import {
  countrySchema,
  applicationSchema,
  industrySchema,
  fieldSchema,
  serviceSchema
} from './core';

const groupSchema = new schema.Entity('groups');

const profileSchema = new schema.Entity(
  'profiles',
  { country: countrySchema },
  { idAttribute: 'user' }
);

const userSchema = new schema.Entity('users', {
  groups: [groupSchema],
  profile: profileSchema,
});

const softwareSchema = new schema.Entity('software', {
  user: userSchema,
  application: applicationSchema,
});

const focusSchema = new schema.Entity('focus', {
  user: userSchema,
  industry: industrySchema,
});

const scheduleSchema = new schema.Entity('schedules', {
  user: userSchema,
});

const specialtySchema = new schema.Entity('specialties', {
  user: userSchema,
  field: fieldSchema,
});

const translationSkillSchema = new schema.Entity('translationSkills', {
  user: userSchema,
  service: serviceSchema,
});

const editSkillSchema = new schema.Entity('editSkills', {
  user: userSchema,
  service: serviceSchema,
});

const checkSkillSchema = new schema.Entity('checkSkills', {
  user: userSchema,
  service: serviceSchema,
});

const finalEditSkillSchema = new schema.Entity('finalEditSkills', {
  user: userSchema,
  service: serviceSchema,
});

const secondFinalEditSkillSchema = new schema.Entity('secondFinalEditSkills', {
  user: userSchema,
  service: serviceSchema,
});

export {
  groupSchema,
  profileSchema,
  userSchema,
  softwareSchema,
  focusSchema,
  scheduleSchema,
  specialtySchema,
  translationSkillSchema,
  editSkillSchema,
  checkSkillSchema,
  finalEditSkillSchema,
  secondFinalEditSkillSchema
}
