import React from 'react';
import { Table } from 'react-bootstrap';
import EmptyRow from 'components/core/EmptyRow';
import JobAssignmentTableRow from './JobAssignmentTableRow';


const JobAssignmentTable = (props) => {

  const {
    documents,
    removable,
    handleRemove,
    handleDownload
  } = props;

  return (
    <Table
      striped
      hover
      className="table-layout-fixed mb-0">
      <tbody>
        { documents && documents.length > 0
          ? documents.map((document, idx) => (
            <JobAssignmentTableRow
              idx={idx}
              key={document.id}
              document={document}
              handleDownload={handleDownload}
              removable={removable}
              handleRemove={handleRemove} />
            ))
          : <EmptyRow colSpan={ removable ? 2 : 1 } />
        }
      </tbody>
    </Table>
  );
}

JobAssignmentTable.defaultProps = {
  removable: false,
}

export default JobAssignmentTable;