import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { EMPLOYEE_STATUS_MAP } from 'misc/config';
import { translatorCurrentJobsRequest } from 'actions/jobs/employees';
import { isTranslatorCurrentJobsLoading } from 'selectors/loading';
import { getTranslatorCurrentJobs } from 'selectors/lists';
import EmployeeCurrentJobsHOC from './EmployeeCurrentJobsHOC';
import EmployeeCurrentJobs from './presenter'


const mapStateToProps = (state) => {
  const roleKey = 'translator';
  return {
    roleKey,
    sentToStatus: EMPLOYEE_STATUS_MAP[roleKey].sent,
    isLoading: isTranslatorCurrentJobsLoading(state),
    jobs: getTranslatorCurrentJobs(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest: translatorCurrentJobsRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeCurrentJobsHOC(EmployeeCurrentJobs));
