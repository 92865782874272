import React from 'react';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import FieldHOC from 'components/core/FieldHOC';

const LabeledField = (props) => {
  const {
    label,
    hasError,
    bsSize,
    requiredMark,
    helpBlock,
    children
  } = props;


  return (
    <FormGroup
      validationState={hasError}
      bsSize={bsSize}>
      <ControlLabel>
        {label}
        {requiredMark}
      </ControlLabel>
      {children}
      {helpBlock}
    </FormGroup>
  )
}

LabeledField.defaultProps = {
  bsSize: 'sm',
}

export default FieldHOC(LabeledField);
