import React from 'react';
import DocumentTable from 'components/documents/DocumentTable';


const EmployeeJobFinishConfirm = (props) => {
  
  const {
    message,
    documents
  } = props;
  
  return (
    <div>
      <p>{message}</p>
      <DocumentTable
        downloadable={false}
        bordered={true}
        documents={documents} />
    </div>
  )
}

export default EmployeeJobFinishConfirm