import React from "react";
import * as PropTypes from "prop-types";
import MHTApi from "services/MHTApi";
import {TaTableRow} from "components/taUi/taTable/taTableRow";
import {TaTableIndexCell} from "components/taUi/taTable/taTableIndexCell";
import {TaTableCell} from "components/taUi/taTable/taTableCell";
import {TaTable} from "components/taUi/taTable/taTable";
import {TaTableBody} from "components/taUi/taTable/taTableBody";
import {TaTableFooter} from "components/taUi/taTable/taTableFooter";
import {TaButton} from "components/taUi/taButton/taButton";
import {TaModal, TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import {TaFormField} from "components/taUi/taFormField/taFormField";
import {TaFormGroup} from "components/taUi/taFormGroup/taFormGroup";
import {TaInputTextarea} from "components/taUi/taInputTextarea";
import {TaText} from "components/taUi/taText/taText";
import DateFormatter from "components/core/DateFormatter";

import styles from './TaMhtEditor.module.css';

export class TaMhtEditorComments extends React.PureComponent {

  static propTypes = {
    mhtId: PropTypes.string.isRequired,
    segmentId: PropTypes.string.isRequired,
    comments: PropTypes.array.isRequired,
    onEvent: PropTypes.func.isRequired,
    triggerModal: PropTypes.number
  };

  constructor() {
    super();

    this.defaultState = () => {
      return {
        loadKey: Date.now(),
        items: [],
        selectedComment: '',
        inputValue: '',
        isLoading: false,
        isSaving: false,
        showCreateModal: false,
        showEditModal: false,
        showDeleteModal: false,
        isSavingModal: false
      };
    };

    this.state = this.defaultState();

  }

  componentDidUpdate(prevProps, prevState) {
    const props = this.props;
    if (props.triggerModal !== prevProps.triggerModal) {
      this.openCreateModal();
    }
  }

  openEditModal = (comment) => {
    this.setState({
      showEditModal: true,
      selectedComment: comment,
      inputValue: comment.body
    });
  };
  openCreateModal = () => {
    if (!this.state.showEditModal) {
      this.setState({
        showCreateModal: true,
        inputValue: ''
      });
    }
  };
  openDeleteModal = (comment) => {
    this.setState({
      showDeleteModal: true,
      selectedComment: comment,
      inputValue: comment.body
    });
  };
  closeModal = () => {
    this.setState({
      showEditModal: false,
      showCreateModal: false,
      showDeleteModal: false,
      selectedComment: false
    });
  };

  onInputEvent = (event) => {
    if (event.name !== 'enter') {
      this.setState({
        inputValue: event.data.value
      });
    }
  };

  saveComment = () => {
    const props = this.props
    const state = this.state
    const segmentId = (state.showCreateModal) ? props.segmentId : state.selectedComment.segment;
    if (segmentId) {
      this.setState({
        isSavingModal: true
      }, () => {
        const comment = state.selectedComment;
        const isUpdate = (comment && comment.id);
        const value = state.inputValue;
        const save = (isUpdate) ?
          MHTApi.updateSegmentComment(comment.id, value) :
          MHTApi.addSegmentComment(segmentId, value);
        save.then((response) => {
          this.setState({
            isSavingModal: false
          }, () => {
            props.onEvent({
              name: (isUpdate) ? 'updated' : 'created',
              segment: segmentId,
              comment: response.data
            });
            this.closeModal();
          });
        });
      });
    }
  };

  deleteComment = () => {
    const comment = this.state.selectedComment;
    const segmentId = comment.segment;
    if (segmentId) {
      this.setState({
        isSavingModal: true
      }, () => {
        MHTApi
          .deleteSegmentComment(comment.id)
          .then((response) => {
            this.setState({
              isSavingModal: false
            }, () => {
              this.props.onEvent({
                name: 'deleted',
                segment: segmentId,
                comment: comment
              });
              this.closeModal();
            });
          });
      });
    }
  };

  render() {

    const state = this.state;
    const props = this.props;
    const cellWidthIndex = 30;
    const cellWidthAction = 90;

    const segmentId = props.segmentId;

    const items = (
      segmentId &&
      props.comments
    ) ? props.comments.filter(item => item.segment === segmentId) : (props.comments) ? props.comments : [];
    const itemCount = items.length;

    return (
      <React.Fragment>
        <TaTable size={'full'} scroll={'y'}>
          <TaTableBody flex={'auto'}>
            {
              itemCount ? (
                items.map((row, rowIndex) => {
                  const userName = (typeof row.user !== 'undefined' && row.user.username) ? ', ' + row.user.username : '';
                  return (
                    <TaTableRow key={row.id}>
                      <TaTableCell
                        width={cellWidthIndex}
                      >
                        <TaText color={'light'}>{row.segment_order}</TaText>
                      </TaTableCell>
                      <TaTableCell
                        className={styles.rowLongText}
                      >
                        <TaText
                          className={styles.rowMeta}
                          color={'light'}
                        ><DateFormatter datetime={row.modified} />{userName}</TaText>
                        <TaText className="br">{row.body}</TaText>
                      </TaTableCell>
                      <TaTableCell width={cellWidthAction}>
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            justifyContent: 'space-between',
                            width: '72px'
                          }}
                        >
                          <TaButton
                            icon={'edit'}
                            tooltip={'Edit Comment'}
                            onClick={() => this.openEditModal(row)}
                          />
                          <TaButton
                            icon={'delete'}
                            tooltip={'Delete Comment'}
                            onClick={() => this.openDeleteModal(row)}
                          />
                        </div>
                      </TaTableCell>
                    </TaTableRow>
                  );
                })
              ) : (
                <TaTableRow>
                  <TaTableCell color={'light'}>{
                    segmentId ? (
                      <TaText>No comments for selected segment.</TaText>
                    ) : (
                      <TaText>No comment.</TaText>
                    )
                  }
                  </TaTableCell>
                </TaTableRow>
              )
            }
          </TaTableBody>
          <TaTableFooter look={'card'}>
            <TaTableRow>
              <TaTableCell>
                <TaButton
                  icon={'add_circle'}
                  label="Add Comment"
                  onClick={this.openCreateModal}
                  disabled={!segmentId}
                />
              </TaTableCell>
            </TaTableRow>
          </TaTableFooter>
        </TaTable>
        <TaModal
          isOpen={state.showCreateModal}
          onCancel={this.closeModal}>
          <TaModalHeader label={'Add Comment'} onCancel={this.closeModal}/>
          <TaModalContent scroll={'auto'}>
            <TaFormGroup>
              <TaFormField
                label={'Comment'}
                isRequired={true}
              >
                <TaInputTextarea
                  name="body"
                  value={state.inputValue}
                  invalid={!(state.inputValue)}
                  onEvent={(event) => this.onInputEvent(event)}
                  autofocus={true}
                />
              </TaFormField>
            </TaFormGroup>
          </TaModalContent>
          <TaModalFooter>
            <TaButton
              type="reset"
              icon={'clear'}
              label={'Cancel'}
              onClick={this.closeModal}
            />
            <TaButton
              type="submit"
              icon={'check'}
              label={'Save'}
              iconAlign={'right'}
              onClick={() => this.saveComment()}
            />
          </TaModalFooter>
        </TaModal>
        <TaModal
          isOpen={state.showEditModal}
          onCancel={this.closeModal}>
          <TaModalHeader label={'Edit Comment'} onCancel={this.closeModal}/>
          <TaModalContent scroll={'auto'}>
            <TaFormGroup>
              <TaFormField
                label={'Comment'}
                isRequired={true}
              >
                <TaInputTextarea
                  name="body"
                  value={state.inputValue}
                  invalid={!(state.inputValue)}
                  onEvent={(event) => this.onInputEvent(event)}
                />
              </TaFormField>
            </TaFormGroup>
          </TaModalContent>
          <TaModalFooter>
            <TaButton
              type="reset"
              icon={'clear'}
              label={'Cancel'}
              onClick={this.closeModal}
            />
            <TaButton
              type="submit"
              icon={'check'}
              label={'Save'}
              iconAlign={'right'}
              isLoading={state.isSavingModal}
              onClick={() => this.saveComment()}
            />
          </TaModalFooter>
        </TaModal>
        <TaModal
          isOpen={state.showDeleteModal}
          onCancel={this.closeModal}>
          <TaModalHeader label={'Delete Comment'} onCancel={this.closeModal}/>
          <TaModalContent scroll={'auto'}>
            <TaFormGroup>
              <TaFormField
                label={'Are you sure you want to delete this comment?'}
              >{state.inputValue}</TaFormField>
            </TaFormGroup>
          </TaModalContent>
          <TaModalFooter>
            <TaButton
              type="reset"
              icon={'clear'}
              label={'Cancel'}
              onClick={this.closeModal}
            />
            <TaButton
              type="submit"
              icon={'delete'}
              label={'Delete'}
              iconAlign={'right'}
              isLoading={state.isSavingModal}
              onClick={() => this.deleteComment()}
            />
          </TaModalFooter>
        </TaModal>
      </React.Fragment>
    );
  }
}
