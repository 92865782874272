import React from 'react';
import classNames from 'classnames';
import moment from "moment";
import { default as ReactDatePicker }  from 'react-datepicker';
import LabeledField from 'components/core/LabeledField';



const DatePickerField = (props) => {

  const {
    input,
    hasError,
    className
  } = props;

  const handleChange = (date) => {
    date
    ? input.onChange(date.format('YYYY-MM-DD'))
    : input.onChange(null)
  }

  const cls = classNames({
    'form-control': true,
    'input-sm': true,
    'has-error': hasError
  }, className)

  return (
    <ReactDatePicker
      dateFormat="YYYY-MM-DD"
      selected={
        input.value
          ? moment(input.value)
          : null
      }
      onBlur={() => input.onBlur()}
      onChange={handleChange}
      todayButton="Today"
      className={cls} />
  )
}

const LabeledDatePickerField = (props) => {
  return (
    <LabeledField {...props}>
      <DatePickerField {...props} />
    </LabeledField>
  );
}

const InlineDatePickerField = (props) => {
  const { invalid, touched } = props.meta
  const hasError = touched && invalid;
  return (
    <DatePickerField
      {...props}
      hasError={hasError} />
  );
}

DatePickerField.Labeled = LabeledDatePickerField;
DatePickerField.Inline = InlineDatePickerField;

export default DatePickerField;
