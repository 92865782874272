import React from 'react';
import PureRenderComponent from 'components/core/PureRenderComponent';


const UserSkillHOC = (WrappedComponent) => {

  return class extends PureRenderComponent {

    componentDidMount() {
      this.props.listRequest(this.props.userId);
    }

    handleChange = (id, service, level, speed = null) => {
      const { userId } = this.props;
      const _level = level || 0;
      const values = { level: _level, service };
      if (speed) {
        values.speed = speed;
      }
      id
        ? this.props.updateRequest(userId, id, values)
        : this.props.createRequest(userId, values)
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleChange={this.handleChange} />
      );
    }
  }
}

export default UserSkillHOC;