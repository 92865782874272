import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Box } from 'reactjs-admin-lte';
import { Table, ButtonToolbar, DropdownButton, MenuItem } from 'react-bootstrap';
import { getDetailTabConfigByName } from 'misc/utils';
import { DELIVERY_DETAIL } from 'misc/constants';
import DateFormatter from 'components/core/DateFormatter'


const JobDeliveryDetailJobInfo = (props) => {
  
  const {
    isLoading,
    job,
    memberOptions,
    handleTabOpen
  } = props;

  const handleMemberTabOpen = (member) => {
    const { label, value } = member;
    const tabConfig = getDetailTabConfigByName(
      DELIVERY_DETAIL,
      value,
      label,
      { id: value }
    );
    handleTabOpen(tabConfig);
  }
  
  return (
    <Box
      className="box-widget"
      loading={isLoading}>
      <Box.Header>
        <Box.Title>{job && job.number}</Box.Title>
        <Box.Tools>
          <ButtonToolbar>
            <DropdownButton
              bsStyle="box-tool"
              title={<FontAwesome name="bars" />}
              noCaret
              id="dropdown-no-caret"
              pullRight>
              {memberOptions.map(member => (
                <MenuItem
                  key={member.value}
                  eventKey={member.value}
                  onClick={() => handleMemberTabOpen(member)}>
                  {member.label}
                </MenuItem>
              ))}
            </DropdownButton>
          </ButtonToolbar>
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <Table className="table-layout-fixed">
          <tbody>
            <tr><th>Number</th><td colSpan={3}>{job && job.number}</td></tr>
            <tr><th>AE Subject</th><td colSpan={3}>{job && job.aeSubject}</td></tr>
            <tr><th>Service</th><td colSpan={3}>{job && job.service && job.service.name}</td></tr>
            <tr>
              <th>Deadline</th>
              <td colSpan={3}><DateFormatter datetime={job && job.deadline} /></td>
            </tr>
            <tr><th>Client</th><td colSpan={3}>{job && job.client && job.client.name}</td></tr>
            <tr><th>AE</th><td colSpan={3}>{job && job.ae && job.ae.username}</td></tr>
          </tbody>
        </Table>
      </Box.Body>
    </Box>
  );
}

export default JobDeliveryDetailJobInfo;