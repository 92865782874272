import React from 'react';
import FontAwesome from 'react-fontawesome';

const CountTabTitle = (props) => {
  
  const {
    isLoading,
    title,
    count
  } = props;
  
  return (
    <div>
      <span>{title}</span>
      <span className="label label-default ml-5">
        { isLoading
          ? <FontAwesome name="refresh" spin />
          : count
        }
      </span>
    </div>
  );
}

export default CountTabTitle