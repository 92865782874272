import React from 'react';
import classNames from 'classnames';
import UserAvatar from 'components/users/UserAvatar'


const CalendarUserInfo = (props) => {

  const {
    isAvailable,
    userAvatar,
    username,
  } = props;

  const cls = classNames({
    'user-image': true,
    'img-circle': true,
    'user-avatar': true,
    'available-now': isAvailable
  })

  return (
    <a className="user-profile-link">
      <UserAvatar className={cls} url={userAvatar} />
      <div className="username">
        {username}
      </div>
    </a>
  )
}

export default CalendarUserInfo