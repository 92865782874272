import React from 'react';
import Calendar from 'components/core/Calendar';
import CalendarUser from './CalendarUser';


const CalendarRow = (props) => {

  const {
    data,
    hasColumnHeader,
    visibleRange,
    handleTabOpen,
    onEventClick,
    selectNestedTab
  } = props;

  return (
    <div className="calendar-content clearfix">
      <CalendarUser
        id={data.userId}
        hasColumnHeader={hasColumnHeader}
        username={data.username}
        userAvatar={data.userAvatar}
        isFreelance={data.isFreelance}
        handleTabOpen={handleTabOpen}
        selectNestedTab={selectNestedTab} />
      <Calendar
        defaultView="basic"
        displayEventTime={true}
        displayEventEnd={true}
        clickable={true}
        eventLimit={false}
        header={false}
        timeFormat="HH:mm"
        allDaySlot={false}
        columnHeader={hasColumnHeader}
        onEventClick={onEventClick}
        visibleRange={visibleRange}
        events={data.events} />
    </div>
  );
}

export default CalendarRow;