import React from 'react';
import { Tooltip, PieChart, Pie, Cell, Legend } from 'recharts';

const REPEAT_COLOR = '#00bfff';
const LOCK_COLOR = "#c0c0c0";
const FRESH_COLOR = '#ff0000';
const PERFECT_MATCH_COLOR = '#dda0dd';
const GENERAL_MATCH_COLOR = '#ffa500';
const CLOSE_MATCH_COLOR = '#90ee90'; 
const FUZZY_MATCH_COLOR = '#008000';
const TERM_MATCH_COLOR = '#0000ff';


const getDataFromAnalysis = (analysis) => {
  const data = [];

  const repeat = {}
  repeat['name'] = 'Repeat';
  repeat['color'] = REPEAT_COLOR
  repeat['value'] = analysis.repeatCount;
  data.push(repeat)

  const lock = {}
  lock['name'] = 'Lock';
  lock['color'] = LOCK_COLOR
  lock['value'] = analysis.autoLockCount;
  data.push(lock)

  const perfectMatch = {}
  perfectMatch['name'] = '100% (Client) match';
  perfectMatch['color'] = PERFECT_MATCH_COLOR;
  perfectMatch['value'] = analysis.memoryPerfectMatchCount;
  data.push(perfectMatch)

  const generalMatch = {}
  generalMatch['name'] = '100% (General) match';
  generalMatch['color'] = GENERAL_MATCH_COLOR;
  generalMatch['value'] = analysis.memoryGeneralMatchCount;
  data.push(generalMatch)

  const closeMatch = {}
  closeMatch['name'] = 'Close match';
  closeMatch['color'] = CLOSE_MATCH_COLOR;
  closeMatch['value'] = analysis.memoryCloseMatchCount;
  data.push(closeMatch);

  const fuzzyMatch = {}
  fuzzyMatch['name'] = 'Fuzzy match';
  fuzzyMatch['color'] = FUZZY_MATCH_COLOR;
  fuzzyMatch['value'] = analysis.memoryFuzzyMatchCount;
  data.push(fuzzyMatch);

  const termMatch = {}
  termMatch['name'] = 'Term match';
  termMatch['color'] = TERM_MATCH_COLOR;
  termMatch['value'] = analysis.termMatchCount
  data.push(termMatch);

  const fresh = {}
  fresh['name'] = 'New';
  fresh['color'] = FRESH_COLOR
  fresh['value'] = analysis.newCount
  data.push(fresh)

  return data
}

const MHTAnalysisChart = (props) => {

  const { analysis } = props;
  const data = getDataFromAnalysis(analysis);

  return (
    <PieChart width={450} height={300}>
      <Pie
        dataKey="value"
        isAnimationActive={false}
        data={data}
        outerRadius={130}
        innerRadius={70}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      <Tooltip />
      <Legend
        verticalAlign="top"
        align="right"
        layout="vertical"
        margin={{ top: 0, left: 10, right: 0, bottom: 0 }} />
    </PieChart>
  )
}

export default MHTAnalysisChart;