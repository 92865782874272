import React from 'react';
import { Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { Field } from 'redux-form';
import DatePickerField from 'components/core/DatePickerField';

const DateRangeFormField = (props) => {

  const {
    label,
    bsSize,
    startName,
    startValidate,
    endName,
    endValidate
  } = props;

  return (
    <FormGroup bsSize={bsSize}>
      <Col
        componentClass={ControlLabel}
        lg={3}>
        {label}
      </Col>
      <Col lg={9}>
        <div className="date-range-form-field">
          <Field
            bsSize={bsSize}
            name={startName}
            validate={startValidate}
            component={DatePickerField.Inline} />
          <div className="date-range-form-field-sep">-</div>
          <Field
            bsSize={bsSize}
            name={endName}
            validate={endValidate}
            component={DatePickerField.Inline} />
        </div>
      </Col>
    </FormGroup>
  );
}

DateRangeFormField.defaultProps = {
  label: 'Deadline',
  startName: 'start',
  endName: 'end',
  bsSize: 'sm',
}

export default DateRangeFormField;
