import React from 'react';
import { USER_DETAIL } from 'misc/constants';
import { datetimeFormatter } from 'misc/cellFormatters';
import { BooleanFieldCellRenderer, LinkFieldCellRenderer } from 'misc/cellRenderers';
import ListTable from 'components/core/ListTable';
import PureRenderComponent from 'components/core/PureRenderComponent';


class UserListTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'Username',
          field: 'username',
          width: 190,
          cellRendererFramework: LinkFieldCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
            tab: USER_DETAIL,
          },
        },
        {
          headerName: 'Short Name',
          field: 'name',
          width: 120,
        },
        {
          headerName: 'Email',
          field: 'email',
          width: 200,
        },
        {
          headerName: 'Active',
          field: 'isActive',
          suppressFilter: true,
          cellRendererFramework: BooleanFieldCellRenderer,
          width: 90,
        },
        {
          headerName: 'Native',
          field: 'isNative',
          suppressFilter: true,
          cellRendererFramework: BooleanFieldCellRenderer,
          width: 90,
        },
        {
          headerName: 'Freelance',
          field: 'isFreelance',
          suppressFilter: true,
          cellRendererFramework: BooleanFieldCellRenderer,
          width: 110,
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: datetimeFormatter,
          width: 150,
        },
        {
          headerName: 'Modified',
          field: 'modified',
          valueFormatter: datetimeFormatter,
          width: 150,
        },
      ]
    }
  }

  render() {
    return (
      <ListTable
        wrapperHeight="75vh"
        columnDefs={this.state.columnDefs}
        rowData={this.props.users}
        deltaRowDataMode={true}
        getRowNodeId={data => data.id} />
    );
  }
}

export default UserListTable;
