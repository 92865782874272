import axios from 'axios';

const CustomerApi = {
  create: (values) => {
    return axios.post('customers/', values);
  },
  list: (params) => {
    return axios.get('customers/', { params });
  },
  retrieve: (id) => {
    return axios.get(`customers/${id}/`);
  },
  update: (id, values) => {
    return axios.put(`customers/${id}/`, values);
  },
  remove: (id) => {
    return axios.delete(`customers/${id}/`);
  },
}

export default CustomerApi;
