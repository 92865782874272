import React from 'react';
import { FormGroup, ControlLabel, Col } from 'react-bootstrap';
import FieldHOC from 'components/core/FieldHOC';

const HorizontalField = (props) => {
  const {
    label,
    hasError,
    bsSize,
    requiredMark,
    children
  } = props;

  return (
    <FormGroup
      validationState={hasError}
      bsSize={bsSize}>
      <Col componentClass={ControlLabel} lg={3}>
        {label}
        {requiredMark}
      </Col>
      <Col lg={9}>
        {children}
      </Col>
    </FormGroup>
  )
}

HorizontalField.defaultProps = {
  bsSize: 'sm',
}

export default FieldHOC(HorizontalField);
