import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { Content, Box } from 'reactjs-admin-lte';
import QuoteDetailTable from './QuoteDetailTable';
import QuoteDetailChart from './QuoteDetailChart';

const QuoteDetail = (props) => {
  const { quote, isLoading } = props;
  return (
    <div>
      <Content.Body>
        <Box className="box-widget mb-10" loading={isLoading}>
          <Box.Body noPadding={true}>
            <Table striped bordered className="mb-0">
              <tbody>
                <tr>
                  <th width="25%">Created</th>
                  <td>{quote.created}</td>
                </tr>
                <tr>
                  <th width="25%">Job</th>
                  <td>{quote.job && quote.job.number}</td>
                </tr>
                <tr>
                  <th width="25%">Service</th>
                  <td>{quote.service.name}</td>
                </tr>
                <tr>
                  <th width="25%">Level</th>
                  <td>{quote.level}</td>
                </tr>
                <tr>
                  <th width="25%">Customer</th>
                  <td>{quote.customer.username}</td>
                </tr>
                <tr>
                  <th width="25%">Client</th>
                  <td>{quote.customer.client.name}</td>
                </tr>
                <tr>
                  <th width="25%">Deadline</th>
                  <td>{quote.deliveryTime}</td>
                </tr>
                <tr>
                  <th width="25%">Count</th>
                  <td>{quote.totalCount}</td>
                </tr>
                <tr>
                  <th width="25%">Subtotal (Original)</th>
                  <td>{quote.originalSubTotal}</td>
                </tr>
                <tr>
                  <th width="25%"><span className="text-danger">MHT Discount</span></th>
                  <td><span className="text-danger">- {quote.discount}</span></td>
                </tr>
                <tr>
                  <th width="25%">Subtotal{ quote.minimumCharge ? ' (Minimum Charge)': null }</th>
                  <td>{quote.subTotal}</td>
                </tr>
                <tr>
                  <th width="25%">Management Fee</th>
                  <td>{quote.managementFee}</td>
                </tr>
                <tr>
                  <th width="25%">Consumption Tax ({quote.taxRate * 100}%)</th>
                  <td>{quote.managementFee}</td>
                </tr>
                <tr>
                  <th width="25%">Total</th>
                  <td>{quote.totalAmount}</td>
                </tr>
              </tbody>
            </Table>
          </Box.Body>
        </Box>
        {
          quote &&
          quote.files && 
          quote.files.map(detail => (
            <Box
              key={detail.id}
              className="box-widget"
              loading={isLoading}>
              <Box.Header>
                <Box.Title>{detail.name}</Box.Title>
                <Box.Tools>
                  <Box.CollapsedToggleButton />
                </Box.Tools>
              </Box.Header>
              <Box.Body noPadding={true}>
                <Row>
                  <Col lg={7}>
                    <QuoteDetailTable quote={quote} detail={detail} />
                  </Col>
                  <Col lg={5}>
                    <QuoteDetailChart detail={detail} />
                  </Col>
                </Row>
              </Box.Body>
            </Box>
          ))
      }
      </Content.Body>
    </div>
  );
}

export default QuoteDetail;