import React from 'react';
import { Checkbox } from 'react-bootstrap';
import HorizontalField from 'components/core/HorizontalField';

const CheckboxField = (props) => {
  
  const {
    input,
    className,
    children,
  } = props;
  
  return (
    <Checkbox
      {...input}
      checked={input.value === true}
      className={className}>
      {children}
    </Checkbox>
  );
}

const LabeledCheckboxField = (props) => {
  const { label } = props;
  return (
    <CheckboxField {...props}>
      {label}
    </CheckboxField>
  )
}

const HorizontalCheckboxField = (props) => {
  return (
    <HorizontalField {...props}>
      <CheckboxField {...props} />
    </HorizontalField>
  )
}

CheckboxField.Labeled = LabeledCheckboxField;
CheckboxField.Horizontal = HorizontalCheckboxField

export default CheckboxField
