import * as types from 'actionTypes/glossaries';
import * as utils from 'misc/utils';


// List ========================================================================
const list = utils.createSearchAction(types.LIST);
export const listRequest = list.requestFn;
export const listSuccess = list.successFn;
export const listFailure = list.failureFn;

// Retrieve ====================================================================
const retrieve = utils.createRetrieveAction(types.RETRIEVE);
export const retrieveRequest = retrieve.requestFn;
export const retrieveSuccess = retrieve.successFn;
export const retrieveFailure = retrieve.failureFn;

// List All ====================================================================
export const listAllRequest = () => ({
  type: types.LIST_ALL.REQUEST,
});
export const listAllSuccess = () => ({
  type: types.LIST_ALL.SUCCESS,
});

export const listAllFailure = (error) => ({
  type: types.LIST_ALL.FAILURE,
  payload: { error }
});


// Update ======================================================================
export const updateRequest = (values) => ({
  type: types.UPDATE.REQUEST,
  payload: { values }
});
export const updateSuccess = (values) => ({
  type: types.UPDATE.SUCCESS,
  payload: { values }
});

export const updateFailure = (error) => ({
  type: types.UPDATE.FAILURE,
  payload: { error }
});

// Batch Delete ================================================================
export const batchDeleteRequest = (values) => ({
  type: types.BATCH_DELETE.REQUEST,
  payload: { values }
});
export const batchDeleteSuccess = (values) => ({
  type: types.BATCH_DELETE.SUCCESS,
  payload: { values }
});

export const batchDeleteFailure = (error) => ({
  type: types.BATCH_DELETE.FAILURE,
  payload: { error }
});


// Upload ======================================================================
export const uploadRequest = (values) => ({
  type: types.UPLOAD.REQUEST,
  payload: { values }
});

export const uploadSuccess = () => ({
  type: types.UPLOAD.SUCCESS
});

export const uploadFailure = (error) => ({
  type: types.UPLOAD.FAILURE,
  payload: { error }
});


export const exportExcelRequest = (id, name, colKeys) => ({
  type: types.EXPORT_EXCEL.REQUEST,
  payload: { id, name, colKeys }
})
export const exportExcelSuccess = (id) => ({
  type: types.EXPORT_EXCEL.SUCCESS,
})
export const exportExcelFailure = (error) => ({
  type: types.EXPORT_EXCEL.FAILURE,
  payload: { error }
})