import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Button, Modal, Radio } from 'react-bootstrap';

const MHTAITranslateSetting = (props) => {

  const {
    useAITranslate,
    handleFormChange,
    handleSlide,
    handleSubmit
  } = props;

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title>AI Translate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Do you want to use AI Translate?</p>
        <Radio
          checked={useAITranslate === "google"}
          onChange={() => handleFormChange('useAITranslate', "google")}>Google Translate
        </Radio>
        <Radio
          checked={useAITranslate === "deepl"}
          onChange={() => handleFormChange('useAITranslate', "deepl")}>DeepL
        </Radio>
        <Radio
          checked={useAITranslate === "no"}
          onChange={() => handleFormChange('useAITranslate', "no")}>No
        </Radio>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="pull-left"
          bsStyle="default"
          bsSize="sm"
          onClick={() => handleSlide(3)}
          type="button">
          <FontAwesome name="angle-left" className="mr-5" />Back
        </Button>
        <Button
          bsStyle="primary"
          bsSize="sm"
          onClick={handleSubmit}
          type="button">
          <FontAwesome name="paper-plane-o" className="mr-5" />Submit
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
}

export default MHTAITranslateSetting;