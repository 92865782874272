
import axios from 'axios';

const TemplateApi = {
  create: (values) => {
    return axios.post(`templates/`, values);
  },
  list: () => {
    return axios.get(`templates/`);
  },
  retrieve: (id) => {
    return axios.get(`templates/${id}/`);
  },
  update: (id, values) => {
    return axios.put(`templates/${id}/`, values);
  },
  remove: (id) => {
    return axios.delete(`templates/${id}/`);
  }
}

export default TemplateApi;