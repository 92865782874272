import { createAsyncActionTypes } from 'misc/utils';

const PREFIX = 'glossaries';

export const LIST = createAsyncActionTypes(`${PREFIX}/LIST`);
export const LIST_ALL = createAsyncActionTypes(`${PREFIX}/LIST_ALL`);
export const RETRIEVE = createAsyncActionTypes(`${PREFIX}/RETRIEVE`);
export const UPDATE = createAsyncActionTypes(`${PREFIX}/UPDATE`);
export const BATCH_DELETE = createAsyncActionTypes(`${PREFIX}/BATCH_DELETE`);

export const UPLOAD = createAsyncActionTypes(`${PREFIX}/UPLOAD`);
export const EXPORT_EXCEL = createAsyncActionTypes(`${PREFIX}/EXPORT_EXCEL`);