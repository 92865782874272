import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { show } from 'redux-modal'
import { SETTINGS_PROFILE, PASSWORD_CHANGE_MODAL } from 'misc/constants';
import { getMe, getMyGroups, isEmployee } from 'selectors/auth';
import { selectNestedTab } from 'actions/tabs';
import { getSettingProfileCurrentTab } from 'selectors/tabs';
import PureRenderComponent from 'components/core/PureRenderComponent';
import Profile from './presenter';


class ProfileContainer extends PureRenderComponent {

  showPasswordChangeModal = () => {
    this.props.show(PASSWORD_CHANGE_MODAL);
  }

  handleTabSelect = (eventKey) => {
    this.props.selectNestedTab(SETTINGS_PROFILE, this.props.me.id, eventKey);
  }
  
  render() {
    return (
      <Profile
        {...this.props}
        showPasswordChangeModal={this.showPasswordChangeModal}
        handleTabSelect={this.handleTabSelect} />
    )
  }
}

const mapStateToProps = (state) => {
  const me = getMe(state)
  return {
    me,
    groups: getMyGroups(state),
    isEmployee: isEmployee(state),
    currentTab: getSettingProfileCurrentTab(state, me.id),
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    show,
    selectNestedTab
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
