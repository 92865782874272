import React from 'react';
import { Tab } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import InvoiceListTable from './InvoiceListTable';


const InvoiceListTabPane = (props) => {
  const {
    eventKey,
    isLoading,
    invoices,
    handleCheck,
    handleTabOpen,
  } = props;

  return (
    <Tab.Pane eventKey={eventKey}>
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body noPadding={true}>
          <InvoiceListTable
            invoices={invoices}
            handleCheck={handleCheck}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
      </Box>
    </Tab.Pane>
  )
}

export default InvoiceListTabPane;
