import React from 'react';
import { Content, Box } from 'reactjs-admin-lte';
import TemplateListTable from './TemplateListTable';

const TemplateList = (props) => {
  
  const {
    templates,
    isLoading,
    handleTabOpen
  } = props;
  
  return (
    <Content.Body>
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body
          noPadding={true}>
          <TemplateListTable
            templates={templates}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
      </Box>
    </Content.Body>
  )
}

export default TemplateList;