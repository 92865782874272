import * as types from 'actionTypes/entities';

export const merge = (entities) => ({
  type: types.MERGE,
  payload: { entities }
});

export const remove = (name, id) => ({
  type: types.REMOVE,
  payload: { name, id }
})

export const sync = (name, id, entity) => ({
  type: types.SYNC,
  payload: { name, id, entity }
});

export const update = (name, id, value) => ({
  type: types.UPDATE,
  payload: { name, id, value }
});