import React from "react";
import * as PropTypes from "prop-types";
import MHTApi from "services/MHTApi";
import {TaTableRow} from "components/taUi/taTable/taTableRow";
import {TaTableCell} from "components/taUi/taTable/taTableCell";
import {TaText} from "components/taUi/taText/taText";
import {TaTableCellGroup} from "components/taUi/taTable/taTableCellGroup";
import {TaIcon} from "components/taUi/taIcon";

import segmentStyles from "components/mht/MHTEditor/TaMhtEditorSegment.module.css";
import {TaMhtEditorSegmentSource} from "./TaMhtEditorSegmentSource";
import {TaMhtEditorSegmentTarget} from "./TaMhtEditorSegmentTarget";
import {calculateRgbBrightness, convertToRGB} from "./colorTools";

export class TaMhtEditorSegment extends React.Component {

  static propTypes = {
    userRole: PropTypes.string.isRequired,
    rowIndex: PropTypes.number.isRequired,
    segment: PropTypes.object.isRequired,

    onRowSelect: PropTypes.func.isRequired,
    onSourceFocus: PropTypes.func.isRequired,
    onSourceBlur: PropTypes.func.isRequired,
    onSourceChange: PropTypes.func.isRequired,
    onTargetFocus: PropTypes.func.isRequired,
    onTargetBlur: PropTypes.func,
    onTargetChange: PropTypes.func.isRequired,

    pickSegmentProperty: PropTypes.func.isRequired,
    getBackgroundColor: PropTypes.func.isRequired,

    hasComment: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isEditSourceEnabled: PropTypes.bool.isRequired,

    timeMutated: PropTypes.number.isRequired,
    timeUpdated: PropTypes.string.isRequired,

    cellWidthIndex: PropTypes.number.isRequired,
    cellWidthStatus: PropTypes.number.isRequired,
    cellGroupWidth: PropTypes.string.isRequired
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.timeLoaded !== nextProps.timeLoaded ||
      this.props.timeMutated !== nextProps.timeMutated ||
      this.props.timeUpdated !== nextProps.timeUpdated ||
      this.props.hasComment !== nextProps.hasComment ||
      this.props.isSelected !== nextProps.isSelected ||
      this.props.isEditSourceEnabled !== nextProps.isEditSourceEnabled ||
      this.props.hasHighlight !== nextProps.hasHighlight ||
      this.props.isActiveHighlight !== nextProps.isActiveHighlight ||
      this.props.highlightKey !== nextProps.highlightKey
    );
  }

  isEmptyString(string){

    // More powerful
    // var div = document.createElement("div");
    // div.innerHTML = html;
    // var text = div.textContent || div.innerText || "";

    const stripped = string.replace(/&nbsp;|<p>|<\/p>/gi, function (x) {
      return x.trim();
    });

    return !!stripped.length;

  }

  handlePassiveString(string, hasHighlight, highlighted, highlightKey) {

    let content = (hasHighlight) ? highlighted.string : string;

    if (content) {
      // handle bright colors
      const className = 'ta-bright-text';
      const regexpColor = /<span[^>]*?style=[^>]*?["\s;]color:\s?([a-zA-Z0-9\s#\(,\)-]*?);[^>]*?["][^>]*?>(\s*?\S*?.*?)<\/span>/img;
      content = content.replaceAll(
        regexpColor,
        (match, value) => {
          let returnValue = match;
          if (
            calculateRgbBrightness(convertToRGB(value.trim())) > 220
          ) {
            const regexpClass = /class="([a-zA-Z0-9\s-_]*?)"/;
            if (match.match(regexpClass)) {
              returnValue = match.replace(regexpClass, (cMatch, cVal) => {
                return 'class="' + className + ' ' + cVal + '"';
              });
            } else {
              returnValue = match.replace('<span ', '<span class="ta-bright-text" ');
            }
          }
          return returnValue;
        });
    }

    if (!!highlightKey) {
      const markString = '<mark data-key="' + highlightKey + '"';
      content = content.replace(markString, markString + ' class="' + segmentStyles.markFocus + '"')
    }

    return content;

  }

  render() {

    const {
      userRole,
      rowIndex,
      segment,

      isEditSourceEnabled,
      isSelected,
      hasComment,

      cellWidthIndex,
      cellWidthStatus,
      cellGroupWidth,

      onRowSelect,
      pickSegmentProperty,
      getBackgroundColor,

      onSourceFocus,
      onSourceBlur,
      onSourceChange,

      onTargetFocus,
      onTargetChange,

      hasHighlight,
      isActiveHighlight,
      highlighted,
      highlightKey

    } = this.props;

    const segmentId = segment.id;

    const status = pickSegmentProperty(segment, 'status');
    const isLocked = pickSegmentProperty(segment, 'is_locked');
    const isTotra = pickSegmentProperty(segment, 'is_totra');

    const isConfirmed = MHTApi.isSegmentConfirmed(userRole, status);
    let rowClassNames = [];
    if (hasHighlight) {
      rowClassNames.push(segmentStyles.hasHighlight)
      if (isActiveHighlight) {
        rowClassNames.push(segmentStyles.activeHighlight)
      }
    }

    const rowStyle = (isSelected) ? {} : {
      backgroundColor: getBackgroundColor(userRole, isLocked, isTotra, isConfirmed)
    };

    const canConfirm = MHTApi.userRoleCanToggleSegmentStatus(userRole, isLocked, isTotra);
    const canSplit = (!isLocked && MHTApi.userRoleCanDo(userRole, 'split'));
    const canEditSource = (!isLocked && isEditSourceEnabled);
    const canEditTarget = (!isLocked) ? MHTApi.userRoleCanEditSegmentTarget(userRole, status) : false;

    return (
      <TaTableRow
        id={segmentId}
        key={segmentId}
        rowIndex={rowIndex}
        isSelected={isSelected}
        style={rowStyle}
        className={(hasHighlight) ? rowClassNames.join(' ') : ''}
      >
        <TaTableCell
          width={cellWidthIndex}
          onClick={(event) => onRowSelect(event, segment, rowIndex)}
        >
          <TaText color={(hasHighlight) ? null : 'light'}>{segment.order}</TaText>
        </TaTableCell>
        <TaTableCellGroup
          style={{width: cellGroupWidth}}
        >
          <TaTableCell
            noWrapper={true}
            width={'50%'}
          >
            {
              (canSplit || canEditSource) ? (
                <TaMhtEditorSegmentSource
                  segment={segment}
                  html={pickSegmentProperty(segment, 'source_html')}
                  canEdit={canEditSource}
                  onFocus={onSourceFocus}
                  onBlur={onSourceBlur}
                  onChange={onSourceChange}
                  validateEmptyString={this.isEmptyString}
                  preprocessHtml={(html) => this.handlePassiveString(html)}
                />
              ) : (
                <div
                  className={'ta-ck--off'}
                  dangerouslySetInnerHTML={{__html: pickSegmentProperty(segment, 'source_html')}}
                />
              )
            }
          </TaTableCell>
          <TaTableCell
            noWrapper={true}
            width={'50%'}
          >
            {
              (canEditTarget) ? (
                  <TaMhtEditorSegmentTarget
                    tabindex={rowIndex}
                    segment={segment}
                    html={pickSegmentProperty(segment, 'target_html')}
                    canEdit={canEditSource}
                    onFocus={onTargetFocus}
                    onChange={onTargetChange}
                    validateEmptyString={this.isEmptyString}
                    highlightKey={highlightKey}
                    hasHighlight={hasHighlight}
                    preprocessHtml={(html) => this.handlePassiveString(html, hasHighlight, highlighted, highlightKey)}
                  />
              ) : (
                <div
                  className={'ta-ck--off'}
                  dangerouslySetInnerHTML={{__html: pickSegmentProperty(segment, 'target_html')}}
                />
              )
            }
          </TaTableCell>
        </TaTableCellGroup>
        <TaTableCell
          onClick={(event) => onRowSelect(event, segment, rowIndex)}
          width={cellWidthStatus}
        >
          <div
            className={segmentStyles.icons}
          >
            <div className={
              (segment.is_auto_locked) ? segmentStyles.iconText + ' ' + segmentStyles.iconActive : segmentStyles.iconText
            }>AT
            </div>
            <TaIcon name={(isConfirmed) ? 'thumb_up' : 'thumb_down'}
                    color={(canConfirm) ? (isConfirmed) ? 'confirm' : 'warn' : 'light'}
                    size={'small'}/>
            <TaIcon name={(isLocked) ? 'lock' : 'lock_open'}
                    color={(isLocked) ? 'active' : 'light'}
                    size={'small'}/>
            <TaIcon name="translate"
                    color={(isTotra) ? 'active' : 'light'}
                    size={'small'}/>
            <TaIcon name={(hasComment) ? 'chat' : 'chat_bubble_outline'}
                    color={(hasComment) ? 'active' : 'light'}
                    size={'small'}/>
          </div>
        </TaTableCell>
      </TaTableRow>
    );

  }
}
