import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Button, Row, Col } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import JobReference from 'components/jobs/common/JobReference';
import JobCommonReference from 'components/jobs/common/JobCommonReference';
import JobClientReference from 'components/jobs/common/JobClientReference';


const EmployeeReference = (props) => {

  const {
    jobId,
    jobGroup,
    handleDownloadAll
  } = props;

  return (
    <Box className="box-widget employee-reference-box">
      <Box.Header className="text-center">
        <Button bsStyle="success" onClick={handleDownloadAll}>
          <FontAwesome name="download" className="mr-5"/>
          Download All Reference Files
        </Button>
      </Box.Header>
      <Box.Body>
        <Row>
          <Col lg={4}>
            <JobClientReference
              jobId={jobId}
              sizeVisible={false} />
          </Col>
          <Col lg={4}>
            <JobCommonReference
              jobGroup={jobGroup}
              sizeVisible={false} />
          </Col>
          <Col lg={4}>
            <JobReference
              jobId={jobId}
              sizeVisible={false} />
          </Col>
        </Row>
      </Box.Body>
    </Box>
  );
}

export default EmployeeReference;
