import React from 'react';
import { Content } from 'reactjs-admin-lte';
import JobCreateForm from './JobCreateForm';


const JobCreate = (props) => {  
  return (
    <Content.Body>
      <JobCreateForm {...props} />
    </Content.Body>
  );
}

export default JobCreate;