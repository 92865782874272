import { fork, takeLatest, call, put } from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import { hide } from 'redux-modal';
import { reset, stopSubmit } from 'redux-form';

import API from 'services/UserApi';
import * as constants from 'misc/constants';
import * as actions from 'actions/users';
import * as schemas from 'schemas/users';
import * as actionTypes from 'actionTypes/users';
import * as entityActions from 'actions/entities';
import * as listActions from 'actions/lists';
import * as coreSagas from 'sagas/core';
import { closeCurrentTab } from 'sagas/globalTabs';
import { successNotification } from 'sagas/core';
import { setLoading } from 'actions/loading';



// User Attr List ==============================================================
function* _userAttrList(action, key, api, schema, successFn, failureFn) {
  const { userId } = action.payload;
  const loadingKey = `${key}/${userId}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(api, userId);
    const normalized = normalize(camelizeKeys(response.data), [schema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(failureFn(error));
    yield put(setLoading(loadingKey, false));
  }
}

// User Attr Create ============================================================
function* _userAttrCreate(action, api, schema, successFn, failureFn, successMessage) {
  const { userId, values } = action.payload;
  try {
    const response = yield call(api, userId, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schema);
    yield put(entityActions.merge(normalized.entities));
    yield put(successFn());
    yield fork(successNotification, successMessage)
  } catch (error) {
    yield put(failureFn(error));
  }
}

// User Attr Update ============================================================
function* _userAttrUpdate(action, api, schema, successFn, failureFn, successMessage) {
  const { userId, id, values } = action.payload;
  try {
    const response = yield call(api, userId, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schema);
    yield put(entityActions.merge(normalized.entities));
    yield put(successFn());
    yield fork(successNotification, successMessage)
  } catch (error) {
    yield put(failureFn(error));
  }
}


// List ========================================================================
function* list(action) {
  const { params } = action.payload;
  yield put(setLoading(constants.USER_LIST, true));
  try {
    const response = yield call(API.list, decamelizeKeys(params));
    const normalized = normalize(camelizeKeys(response.data.results), [schemas.userSchema]);
    const totalCount = response.data.count ? response.data.count : 1;
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setPaginationResult(
      constants.USER_LIST,
      params.page,
      normalized.result,
      totalCount
    ));
    yield put(setLoading(constants.USER_LIST, false));
  } catch (error) {
    yield put(actions.listFailure(error));
    yield put(setLoading(constants.USER_LIST, false));
  }
}

// Create ======================================================================
function* create(action) {
  yield put(setLoading(constants.USER_CREATE, true));
  const { form, values } = action.payload;
  try {
    const response = yield call(API.create, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), [schemas.userSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield fork(closeCurrentTab);
    yield fork(successNotification, 'User created successfuly.')
    yield put(reset(form))
    yield put(setLoading(constants.USER_CREATE, false));
  } catch (error) {
    if (error.response.status === 400) {
      if (error.response.data.username) {
        yield put(stopSubmit(form, { username: "A user with that username already exists." }));
      }
    }
    yield put(setLoading(constants.USER_CREATE, false));
    yield put(actions.createFailure());
  }
}

// Retrieve ====================================================================
function* retrieve(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.USER_DETAIL}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.retrieve, id);
    const normalized = normalize(camelizeKeys(response.data), schemas.userSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(setLoading(loadingKey, false));
  }
}

// Update ======================================================================
function* update(action) {
  const { form, id, values } = action.payload;
  const loadingKey = `${constants.USER_ACCOUNT}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.update, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.userSchema);
    yield put(entityActions.sync('users', id, normalized.entities.users[id]));
    yield fork(successNotification, 'User updated successfuly.')
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    if (error.response.status === 400) {
      if (error.response.data.username) {
        yield put(stopSubmit(form, { username: "A user with that username already exists." }));
      }
    }
    yield put(actions.updateFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Remove ======================================================================
function* remove(action) {
  const { item } = action.payload;
  const message = `Are you sure to delete ${item.username} ?`;
  const confirmed = yield call(coreSagas.confirm, message);
  if (confirmed) {
    try {
      yield put(setLoading(constants.CONFIRM_MODAL, true))
      yield call(API.remove, item.id);
      yield put(listActions.removePaginationItem(constants.USER_LIST, item.id));
      yield fork(closeCurrentTab);
      yield fork(successNotification, 'User removed successfuly.');

      yield put(entityActions.remove('users', item.id));
      yield put(hide(constants.CONFIRM_MODAL));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    } catch (error) {
      yield put(actions.removeFailure(error));
      yield put(setLoading(constants.CONFIRM_MODAL, false))
    }
  }
}

// Avatar Upload ===============================================================
function* avatarUpload(action) {
  yield put(setLoading(constants.USER_AVATAR_UPLOAD_MODAL, true));
  const { userId, avatar } = action.payload;
  try {
    const response = yield call(API.avatarUpload, userId, avatar);
    const normalized = normalize(camelizeKeys(response.data), schemas.userSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(constants.USER_AVATAR_UPLOAD_MODAL, false));
    yield put(hide(constants.USER_AVATAR_UPLOAD_MODAL));
  } catch (error) {
    yield put(actions.avatarUploadFailure(error));
    yield put(setLoading(constants.USER_AVATAR_UPLOAD_MODAL, false));
  }
}

// Profile Retrieve ============================================================
function* profileRetrieve(action) {
  const { id } = action.payload;
  const loadingKey = `${constants.USER_PROFILE}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.profileRetrieve, id);
    const normalized = normalize(camelizeKeys(response.data), schemas.profileSchema);
    yield put(entityActions.merge(normalized.entities));
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.profileRetrieveFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Profile Update ==============================================================
function* profileUpdate(action) {
  const { id, values } = action.payload;
  const loadingKey = `${constants.USER_PROFILE}/${id}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.profileUpdate, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), schemas.profileSchema);
    yield put(entityActions.merge(normalized.entities));
    yield fork(successNotification, 'Profile updated successfuly.')
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.profileUpdateFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Translation Skill List ======================================================
function* translationSkillList(action) {
  yield fork(
    _userAttrList,
    action,
    constants.USER_TRANSLATION_SKILL,
    API.translationSkillList,
    schemas.translationSkillSchema,
    actions.translationSkillListSuccess,
    actions.translationSkillListFailure,
  )
}
// Translation Skill Create ====================================================
function* translationSkillCreate(action) {
  yield fork(
    _userAttrCreate,
    action,
    API.translationSkillCreate,
    schemas.translationSkillSchema,
    actions.translationSkillCreateSuccess,
    actions.translationSkillCreateFailure,
    'Translation skill updated successfully.'
  );
}
// Translation Skill Update ====================================================
function* translationSkillUpdate(action) {
  yield fork(
    _userAttrUpdate,
    action,
    API.translationSkillUpdate,
    schemas.translationSkillSchema,
    actions.translationSkillUpdateSuccess,
    actions.translationSkillUpdateFailure,
    'Translation skill updated successfully.'
  );
}

function* editSkillList(action) {
  yield fork(
    _userAttrList,
    action,
    constants.USER_EDIT_SKILL,
    API.editSkillList,
    schemas.editSkillSchema,
    actions.editSkillListSuccess,
    actions.editSkillListFailure,
  )
}

// Edit Skill Create ===========================================================
function* editSkillCreate(action) {
  yield fork(
    _userAttrCreate,
    action,
    API.editSkillCreate,
    schemas.editSkillSchema,
    actions.editSkillCreateSuccess,
    actions.editSkillCreateFailure,
    'Edit skill updated successfully.'
  );
}
// Edit Skill Update ===========================================================
function* editSkillUpdate(action) {
  yield fork(
    _userAttrUpdate,
    action,
    API.editSkillUpdate,
    schemas.editSkillSchema,
    actions.editSkillUpdateSuccess,
    actions.editSkillUpdateFailure,
    'Edit skill updated successfully.'
  );
}

// Check Skill List ============================================================
function* checkSkillList(action) {
  yield fork(
    _userAttrList,
    action,
    constants.USER_CHECK_SKILL,
    API.checkSkillList,
    schemas.checkSkillSchema,
    actions.checkSkillListSuccess,
    actions.checkSkillListFailure,
  )
}

// Check Skill Create ==========================================================
function* checkSkillCreate(action) {
  yield fork(
    _userAttrCreate,
    action,
    API.checkSkillCreate,
    schemas.checkSkillSchema,
    actions.checkSkillCreateSuccess,
    actions.checkSkillCreateFailure,
    'Check skill updated successfully.'
  );
}
// Check Skill Update ==========================================================
function* checkSkillUpdate(action) {
  yield fork(
    _userAttrUpdate,
    action,
    API.checkSkillUpdate,
    schemas.checkSkillSchema,
    actions.checkSkillUpdateSuccess,
    actions.checkSkillUpdateFailure,
    'Check skill updated successfully.'
  );
}

// Final Edit Skill Create =====================================================
function* finalEditSkillList(action) {
  yield fork(
    _userAttrList,
    action,
    constants.USER_FINAL_EDIT_SKILL,
    API.finalEditSkillList,
    schemas.finalEditSkillSchema,
    actions.finalEditSkillListSuccess,
    actions.finalEditSkillListFailure,
  )
}

// Final Edit Skill Create =====================================================
function* finalEditSkillCreate(action) {
  yield fork(
    _userAttrCreate,
    action,
    API.finalEditSkillCreate,
    schemas.finalEditSkillSchema,
    actions.finalEditSkillCreateSuccess,
    actions.finalEditSkillCreateFailure,
    'Final Edit skill updated successfully.'
  );
}
// Final Edit Skill Update =====================================================
function* finalEditSkillUpdate(action) {
  yield fork(
    _userAttrUpdate,
    action,
    API.finalEditSkillUpdate,
    schemas.finalEditSkillSchema,
    actions.finalEditSkillUpdateSuccess,
    actions.finalEditSkillUpdateFailure,
    'Final Edit skill updated successfully.'
  );
}

function* secondFinalEditSkillList(action) {
  yield fork(
    _userAttrList,
    action,
    constants.USER_SECOND_FINAL_EDIT_SKILL,
    API.secondFinalEditSkillList,
    schemas.secondFinalEditSkillSchema,
    actions.secondFinalEditSkillListSuccess,
    actions.secondFinalEditSkillListFailure,
  )
}

// Second Final Edit Skill Create ==============================================
function* secondFinalEditSkillCreate(action) {
  yield fork(
    _userAttrCreate,
    action,
    API.secondFinalEditSkillCreate,
    schemas.secondFinalEditSkillSchema,
    actions.secondFinalEditSkillCreateSuccess,
    actions.secondFinalEditSkillCreateFailure,
    'Second Final Edit skill updated successfully.'
  );
}
// Second Final Edit Skill Update ==============================================
function* secondFinalEditSkillUpdate(action) {
  yield fork(
    _userAttrUpdate,
    action,
    API.secondFinalEditSkillUpdate,
    schemas.secondFinalEditSkillSchema,
    actions.secondFinalEditSkillUpdateSuccess,
    actions.secondFinalEditSkillUpdateFailure,
    'Second Final Edit skill updated successfully.'
  );
}

// Focus List ==================================================================
function* focusList(action) {
  yield fork(
    _userAttrList,
    action,
    constants.USER_FOCUS,
    API.focusList,
    schemas.focusSchema,
    actions.focusListSuccess,
    actions.focusListFailure,
  );
}

// Focus Create ================================================================
function* focusCreate(action) {
  yield fork(
    _userAttrCreate,
    action,
    API.focusCreate,
    schemas.focusSchema,
    actions.focusCreateSuccess,
    actions.focusCreateFailure,
    'Focus updated successfully.'
  );
}

// Focus Update ================================================================
function* focusUpdate(action) {
  yield fork(
    _userAttrUpdate,
    action,
    API.focusUpdate,
    schemas.focusSchema,
    actions.focusUpdateSuccess,
    actions.focusUpdateFailure,
    'Focus updated successfully.'
  );
}

// Specialty List ==============================================================
function* specialtyList(action) {
  yield fork(
    _userAttrList,
    action,
    constants.USER_SPECIALTY,
    API.specialtyList,
    schemas.specialtySchema,
    actions.specialtyListSuccess,
    actions.specialtyListFailure,
  );
}

// Specialty Create ============================================================
function* specialtyCreate(action) {
  yield fork(
    _userAttrCreate,
    action,
    API.specialtyCreate,
    schemas.specialtySchema,
    actions.specialtyCreateSuccess,
    actions.specialtyCreateFailure,
    'Specialty updated successfully.'
  );
}

// Specialty Update ============================================================
function* specialtyUpdate(action) {
  yield fork(
    _userAttrUpdate,
    action,
    API.specialtyUpdate,
    schemas.specialtySchema,
    actions.specialtyUpdateSuccess,
    actions.specialtyUpdateFailure,
    'Specialty updated successfully.'
  );
}

// Schedule List ===============================================================
function* scheduleList(action) {
  yield fork(
    _userAttrList,
    action,
    constants.USER_SCHEDULE,
    API.scheduleList,
    schemas.scheduleSchema,
    actions.scheduleListSuccess,
    actions.scheduleListFailure,
  );
}

// Schedule Update or Create ===================================================
function* scheduleUpdateOrCreate(action) {
  const { userId, values } = action.payload;
  const loadingKey = `${constants.USER_SCHEDULE}/${userId}`;
  yield put(setLoading(loadingKey, true));
  try {
    const response = yield call(API.scheduleUpdateOrCreate, userId, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), [schemas.scheduleSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield fork(successNotification, 'Schedule updated successfuly.')
    yield put(setLoading(loadingKey, false));
  } catch (error) {
    yield put(actions.scheduleUpdateOrCreateFailure(error));
    yield put(setLoading(loadingKey, false));
  }
}

// Software List ===============================================================
function* softwareList(action) {
  yield fork(
    _userAttrList,
    action,
    constants.USER_SOFTWARE,
    API.softwareList,
    schemas.softwareSchema,
    actions.softwareListSuccess,
    actions.softwareListFailure,
  );
}

// Software Create =============================================================
function* softwareCreate(action) {
  yield fork(
    _userAttrCreate,
    action,
    API.softwareCreate,
    schemas.softwareSchema,
    actions.softwareCreateSuccess,
    actions.softwareCreateFailure,
    'Software updated successfully.'
  );
}

// Software Delete =============================================================
function* softwareDelete(action) {
  const { userId, id } = action.payload;
  try {
    yield call(API.softwareDelete, userId, id);
    yield put(entityActions.remove('software', id));
    yield fork(successNotification, 'Software updated successfully.')
  } catch (error) {
    yield put(actions.softwareDeleteFailure(error));
  }
}

function* watchList() {
  yield takeLatest(actionTypes.LIST.REQUEST, list);
}
function* watchCreate() {
  yield takeLatest(actionTypes.CREATE.REQUEST, create);
}
function* watchRetrieve() {
  yield takeLatest(actionTypes.RETRIEVE.REQUEST, retrieve);
}
function* watchUpdate() {
  yield takeLatest(actionTypes.UPDATE.REQUEST, update);
}
function* watchRemove() {
  yield takeLatest(actionTypes.REMOVE.REQUEST, remove);
}

function* watchProfileRetrieve() {
  yield takeLatest(actionTypes.PROFILE_RETRIEVE.REQUEST, profileRetrieve);
}
function* watchProfileUpdate() {
  yield takeLatest(actionTypes.PROFILE_UPDATE.REQUEST, profileUpdate);
}

function* watchAvatarUpload() {
  yield takeLatest(actionTypes.AVATAR_UPLOAD.REQUEST, avatarUpload);
}

function* watchTranslationSkillList() {
  yield takeLatest(actionTypes.TRANSLATION_SKILL_LIST.REQUEST, translationSkillList);
}
function* watchTranslationSkillCreate() {
  yield takeLatest(actionTypes.TRANSLATION_SKILL_CREATE.REQUEST, translationSkillCreate);
}
function* watchTranslationSkillUpdate() {
  yield takeLatest(actionTypes.TRANSLATION_SKILL_UPDATE.REQUEST, translationSkillUpdate);
}

function* watchEditSkillList() {
  yield takeLatest(actionTypes.EDIT_SKILL_LIST.REQUEST, editSkillList);
}
function* watchEditSkillCreate() {
  yield takeLatest(actionTypes.EDIT_SKILL_CREATE.REQUEST, editSkillCreate);
}
function* watchEditSkillUpdate() {
  yield takeLatest(actionTypes.EDIT_SKILL_UPDATE.REQUEST, editSkillUpdate);
}

function* watchCheckSkillList() {
  yield takeLatest(actionTypes.CHECK_SKILL_LIST.REQUEST, checkSkillList);
}
function* watchCheckSkillCreate() {
  yield takeLatest(actionTypes.CHECK_SKILL_CREATE.REQUEST, checkSkillCreate);
}
function* watchCheckSkillUpdate() {
  yield takeLatest(actionTypes.CHECK_SKILL_UPDATE.REQUEST, checkSkillUpdate);
}

function* watchFinalEditSkillList() {
  yield takeLatest(actionTypes.FINAL_EDIT_SKILL_LIST.REQUEST, finalEditSkillList);
}
function* watchFinalEditSkillCreate() {
  yield takeLatest(actionTypes.FINAL_EDIT_SKILL_CREATE.REQUEST, finalEditSkillCreate);
}
function* watchFinalEditSkillUpdate() {
  yield takeLatest(actionTypes.FINAL_EDIT_SKILL_UPDATE.REQUEST, finalEditSkillUpdate)
}

function* watchSecondFinalEditSkillList() {
  yield takeLatest(actionTypes.SECOND_FINAL_EDIT_SKILL_LIST.REQUEST, secondFinalEditSkillList);
}
function* watchSecondFinalEditSkillCreate() {
  yield takeLatest(actionTypes.SECOND_FINAL_EDIT_SKILL_CREATE.REQUEST, secondFinalEditSkillCreate);
}
function* watchSecondFinalEditSkillUpdate() {
  yield takeLatest(actionTypes.SECOND_FINAL_EDIT_SKILL_UPDATE.REQUEST, secondFinalEditSkillUpdate)
}

function* watchFocusList() {
  yield takeLatest(actionTypes.FOCUS_LIST.REQUEST, focusList);
}
function* watchFocusCreate() {
  yield takeLatest(actionTypes.FOCUS_CREATE.REQUEST, focusCreate);
}
function* watchFocusUpdate() {
  yield takeLatest(actionTypes.FOCUS_UPDATE.REQUEST, focusUpdate)
}

function* watchSpecialtyList() {
  yield takeLatest(actionTypes.SPECIALTY_LIST.REQUEST, specialtyList);
}
function* watchSpecialtyCreate() {
  yield takeLatest(actionTypes.SPECIALTY_CREATE.REQUEST, specialtyCreate);
}
function* watchSpecialtyUpdate() {
  yield takeLatest(actionTypes.SPECIALTY_UPDATE.REQUEST, specialtyUpdate)
}

function* watchScheduleList() {
  yield takeLatest(actionTypes.SCHEDULE_LIST.REQUEST, scheduleList)
}
function* watchScheduleUpdateOrCreate() {
  yield takeLatest(actionTypes.SCHEDULE_UPDATE_OR_CREATE.REQUEST, scheduleUpdateOrCreate)
}

function* watchSoftwareList() {
  yield takeLatest(actionTypes.SOFTWARE_LIST.REQUEST, softwareList)
}
function* watchSoftwareCreate() {
  yield takeLatest(actionTypes.SOFTWARE_CREATE.REQUEST, softwareCreate);
}
function* watchSoftwareDelete() {
  yield takeLatest(actionTypes.SOFTWARE_DELETE.REQUEST, softwareDelete);
}

export {
  watchList,
  watchCreate,
  watchRetrieve,
  watchUpdate,
  watchRemove,

  watchAvatarUpload,
  watchProfileRetrieve,
  watchProfileUpdate,

  watchTranslationSkillList,
  watchTranslationSkillCreate,
  watchTranslationSkillUpdate,

  watchEditSkillList,
  watchEditSkillCreate,
  watchEditSkillUpdate,

  watchCheckSkillList,
  watchCheckSkillCreate,
  watchCheckSkillUpdate,

  watchFinalEditSkillList,
  watchFinalEditSkillCreate,
  watchFinalEditSkillUpdate,

  watchSecondFinalEditSkillList,
  watchSecondFinalEditSkillCreate,
  watchSecondFinalEditSkillUpdate,

  watchFocusList,
  watchFocusCreate,
  watchFocusUpdate,

  watchSpecialtyList,
  watchSpecialtyCreate,
  watchSpecialtyUpdate,

  watchScheduleList,
  watchScheduleUpdateOrCreate,

  watchSoftwareList,
  watchSoftwareCreate,
  watchSoftwareDelete
}