export const getEntities = state => state.entities;

export const getUserEntities = state => state.entities.users;
export const getProfileEntities = state => state.entities.profiles;
export const getGroupEntities = state => state.entities.groups;
export const getScheduleEntities = state => state.entities.schedules;
export const getSoftwareEntities = state => state.entities.software;
export const getApplicationEntities = state => state.entities.applications;
export const getSpecialtyEntities = state => state.entities.specialties;
export const getFocusEntities = state => state.entities.focus;

export const getJobEntities = state => state.entities.jobs;
export const getAssignmentEntities = state => state.entities.assignments;
export const getTemplateEntities = state => state.entities.templates;

export const getClientEntities = state => state.entities.clients;

export const getParentIndustryEntities = state => state.entities.parentIndustries;
export const getIndustryEntities = state => state.entities.industries;

export const getParentCategoryEntities = state => state.entities.parentCategories;
export const getCategoryEntities = state => state.entities.categories;

export const getParentFieldEntities = state => state.entities.parentFields;
export const getFieldEntities = state => state.entities.fields;


export const getServiceEntities = state => state.entities.services;
export const getCountryEntities = state => state.entities.countries;

export const getTranslationSkillEntities = state => state.entities.translationSkills;
export const getEditSkillEntities = state => state.entities.editSkills;
export const getCheckSkillEntities = state => state.entities.checkSkills;
export const getFinalEditSkillEntities = state => state.entities.finalEditSkills;
export const getSecondFinalEditSkillEntities = state => state.entities.secondFinalEditSkills;

export const getMHTEntities = state => state.entities.mht;
export const getWordListEntities = state => state.entities.wordlists;
export const getGlossaryEntities = state => state.entities.glossaries;

export const getMemorySearchEntities = state => state.entities.memorySearches;

export const getCustomerEntities = state => state.entities.customers;