import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { reduxForm } from 'redux-form';
import Callout from 'components/core/Callout';
import TemplateEditNameField from './TemplateEditNameField';
import TemplateEditBasicInfoFields from './TemplateEditBasicInfoFields';
import JobNotesField from 'components/jobs/managers/JobNotesField';


const TemplateEditForm = (props) => {
  
  const {
    form,
    isLoading,
    invalid,
    handleSubmit,
    handleUpdate,
    handleRemove,
    submitFailed,
  } = props;
  
  return (
    <Form
      noValidate
      autoComplete="off">
      <Row>
        <Col lg={6}>
          <TemplateEditNameField
            isLoading={isLoading} />
          <TemplateEditBasicInfoFields
            form={form}
            isLoading={isLoading} />
        </Col>
        <Col lg={6}>
          <JobNotesField
            name="instruction"
            title="Instruction"
            isLoading={isLoading}
            isRequired={true} />
          <JobNotesField
            name="notes"
            title="Job notes"
            isLoading={isLoading} />
        </Col>
      </Row>
      <Callout
        invalid={invalid}
        submitFailed={submitFailed} />
      <Button
        type="button"
        bsStyle="success"
        bsSize="sm"
        onClick={handleSubmit(values => handleUpdate(values))}>
        <FontAwesome name="refresh" className="mr-5" />Update
      </Button>
      <Button
        type="button"
        bsSize="sm"
        bsStyle="danger"
        className="ml-10"
        onClick={handleRemove}>
        <FontAwesome name="trash" className="mr-5" />Delete
      </Button>
    </Form>
  )
}

export default reduxForm({
  enableReinitialize: true,
})(TemplateEditForm);
