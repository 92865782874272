import React from 'react';
import PureRenderComponent from 'components/core/PureRenderComponent';
import { datetimeFormatter } from 'misc/cellFormatters';
import { WORDLIST_DETAIL, GLOSSARY_DETAIL } from 'misc/constants';
import { getDetailTabConfigByName } from 'misc/utils';
import ListTable from 'components/core/ListTable';

class TermParentNameCellRenderer extends PureRenderComponent {
  render() {
    const { data , colDef} = this.props;
    let id;
    let tabConfig;
    let title;
    const { handleTabOpen } = colDef.cellRendererParams;
    if (data && data.wordlist) {
      id = data.wordlist.id;
      title = `${data.wordlist.name}`;
      tabConfig = getDetailTabConfigByName(WORDLIST_DETAIL, id, title, { id });
      return <a onClick={() => handleTabOpen(tabConfig)}>{data.wordlist.name}</a>
    } else if (data && data.glossary) {
      id = data.glossary.id;
      title = `${data.glossary.name}`;
      tabConfig = getDetailTabConfigByName(GLOSSARY_DETAIL, id, title, { id });
      return <a onClick={() => handleTabOpen(tabConfig)}>{data.glossary.name}</a>
    }
    return null;
  }
}


class TermSearchTable extends PureRenderComponent {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: 'Name',
          cellRendererFramework: TermParentNameCellRenderer,
          cellRendererParams: {
            handleTabOpen: this.props.handleTabOpen,
          },
        },
        {
          headerName: 'ja-JP',
          field: 'jaJp',
          width: 300
        },
        {
          headerName: 'en-US',
          field: 'enUs',
          width: 300
        },
        {
          headerName: 'zh-CN',
          field: 'zhCn',
          width: 300
        },
        {
          headerName: 'zh-TW',
          field: 'zhTw',
          width: 300
        },
        {
          headerName: 'Client',
          field: 'client.name',
          width: 150
        },
        {
          headerName: 'Category',
          field: 'category.name',
          width: 120
        },
        {
          headerName: 'Created',
          field: 'created',
          valueFormatter: datetimeFormatter,
          width: 130
        },
        {
          headerName: 'Modified',
          field: 'modified',
          valueFormatter: datetimeFormatter,
          width: 130
        },
      ]
    }
  }
  render() {
    return (
      <ListTable
        wrapperHeight="74vh"
        columnDefs={this.state.columnDefs}
        rowData={this.props.terms} />
    );
  }
}

export default TermSearchTable;
