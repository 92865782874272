import React from 'react';
import { connectModal } from 'redux-modal';
import { Button, Modal } from 'react-bootstrap';
import { SERVER_ERROR_MODAL } from 'misc/constants';


const ServerErrorModal = (props) => {

  const { 
    show,
    handleHide,
    message,
  } = props;
  
  return (
    <Modal
      show={show}
      onHide={handleHide}
      animation={true}
      backdrop="static"
      className="modal-danger">
      <Modal.Header closeButton>
        <Modal.Title>Server Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="pull-right btn-outline"
          bsSize="sm"
          bsStyle="danger"
          type="button"
          onClick={handleHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default connectModal({ name: SERVER_ERROR_MODAL })(ServerErrorModal)

