import React from 'react';
import { Content, Box } from 'reactjs-admin-lte';
import Pagination from 'components/core/Pagination';
import TermSearchFilter from './TermSearchFilter';
import TermSearchTable from './TermSearchTable';

const TermSearch = (props) => {

  const {
    isLoading,
    keyword,
    handleTabOpen,
    terms,
    currentPage,
    pageSize,
    handlePageSelect,
    handleKeywordChange,
    handleSubmit,
  } = props;

  return (
    <Content.Body>
      <TermSearchFilter
        keyword={keyword}
        handleKeywordChange={handleKeywordChange}
        handleSubmit={handleSubmit} />
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body>
          <TermSearchTable
            terms={terms}
            handleTabOpen={handleTabOpen} />
        </Box.Body>
        <Box.Footer>
          <Pagination
            currentPage={currentPage}
            totalPages={pageSize}
            onChange={handlePageSelect} />
        </Box.Footer>
      </Box>
    </Content.Body>
  )
}

export default TermSearch;
