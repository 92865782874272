import React from 'react';
import { Box } from 'reactjs-admin-lte';
import { Table } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import Rating from 'react-rating';

const UserSpecialty = (props) => {

  const {
    isLoading,
    specialties,
    collapsed,
    hasHeader,
    handleChange
  } = props;

  const renderRow = () => {
    return specialties.map(row => {
      return (
        <React.Fragment key={row.id}>
          <tr className="active">
            <td><strong>{row.name}</strong></td>
            <td></td>
          </tr>
          {row.children.map(child => (
            <tr key={child.fieldId}>
              <td>{child.fieldName}</td>
              <td>
                <FontAwesome
                  name="ban"
                  className="mr-3"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleChange(
                    child.specialtyId,
                    child.fieldId,
                    0
                  )} />
                <Rating
                  emptySymbol="fa fa-star-o"
                  fullSymbol="fa fa-star"
                  initialRating={child.level}
                  onChange={rate => handleChange(
                    child.specialtyId,
                    child.fieldId,
                    rate
                  )} />
                </td>
            </tr>
          ))}
      </React.Fragment>
    )});
  }

  return (
    <Box
      className="box-widget"
      collapsed={collapsed}
      loading={isLoading}>
      { hasHeader &&
        <Box.Header border={true}>
          <Box.Title>Spcialty</Box.Title>
          <Box.Tools>
            <Box.CollapsedToggleButton />
          </Box.Tools>
        </Box.Header>
      }
      <Box.Body noPadding={true}>
        <Table>
          <thead>
            <tr>
              <th>Category</th>
              <th width="20%">Level</th>
            </tr>
          </thead>
          <tbody>
            {renderRow()}
          </tbody>
        </Table>
      </Box.Body>
    </Box>
  )
}

UserSpecialty.defaultProps = {
  hasHeader: false,
  collapsed: false
}

export default UserSpecialty;