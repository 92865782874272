import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, FormGroup, ControlLabel } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import * as validators from 'misc/validators';
import CharField from 'components/core/CharField';
import TextField from 'components/core/TextField';
import CheckboxField from 'components/core/CheckboxField';
import { IndustrySelectOptionField } from 'components/core/SelectOptionField';


const ClientEditForm = (props) => {
  
  const {
    isLoading,
    isSuperuser,
    handleSubmit,
    handleUpdate,
    handleRemove
  } = props;
  
  return (
    <Form
      noValidate
      autoComplete="off">
      <Box
        className="box-widget"
        loading={isLoading}>
        <Box.Header border={true}>
          <Box.Title>Information</Box.Title>
        </Box.Header>
        <Box.Body>
          <Field
            name="name"
            label="Name"
            type="text"
            validate={[
              validators.required,
              validators.alphanumeric,
              validators.maxLength100
            ]}
            isRequired={true}
            component={CharField.Labeled} />
          <Field
            name="fullNameEn"
            label="Full Name in English"
            type="text"
            validate={[
              validators.alphanumeric,
              validators.maxLength150
            ]}
            component={CharField.Labeled} />
          <Field
            name="fullNameJp"
            label="Full Name in Japanese"
            type="text"
            validate={[
              validators.maxLength150
            ]}
            component={CharField.Labeled} />
          <IndustrySelectOptionField />
          <Field
            name="notes"
            label="Notes"
            component={TextField.Labeled} />
          <FormGroup>
            <ControlLabel>Options</ControlLabel>
            <Field
              name="isActive"
              label="is Active"
              className="mt-3 mb-3"
              component={CheckboxField.Labeled} />
          </FormGroup>
        </Box.Body>
        <Box.Footer>
          <Button
            type="button"
            bsStyle="success"
            bsSize="sm"
            onClick={handleSubmit(values => handleUpdate(values))}>
            <FontAwesome name="refresh" className="mr-5" />Update
          </Button>
          { isSuperuser &&
            <Button
              type="button"
              bsStyle="danger"
              bsSize="sm"
              className="ml-10"
              onClick={handleRemove}>
              <FontAwesome name="trash" className="mr-5" />Delete
            </Button>
          }
        </Box.Footer>
      </Box>
    </Form>
  )
}

export default reduxForm({
  enableReinitialize: true
})(ClientEditForm);
