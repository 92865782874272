import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { WEEKDAYS } from 'misc/config';
import { USER_SCHEDULE_FORM } from 'misc/constants';
import { scheduleListRequest, scheduleUpdateOrCreateRequest } from 'actions/users';
import { isUserScheduleLoading } from 'selectors/loading';
import { getSchedulesByUserId } from 'selectors/users';
import PureRenderComponent from 'components/core/PureRenderComponent';
import UserSchedule from './presenter';



class UserScheduleContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.scheduleListRequest(this.props.userId);
  }

  handleUpdateOrCreate = (values) => {
    this.props.scheduleUpdateOrCreateRequest(
      this.props.form,
      this.props.userId,
      this.parseSchedules(values)
    );
  }
  
  parseSchedules = (values) => {
    const formData = { ...values };
    WEEKDAYS.forEach(w => {
      const weekObj = formData[w.key];
      if (!weekObj || (weekObj && (!weekObj.start || !weekObj.end))) {
        delete formData[w.key]
      }
    })
    return formData;
  }

  render() {
    return (
      <UserSchedule
        {...this.props}
        handleUpdateOrCreate={this.handleUpdateOrCreate} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const id = props.userId;
  const form = `${USER_SCHEDULE_FORM}/${id}`;
  return {
    form,
    initialValues: getSchedulesByUserId(state, id),
    isLoading: isUserScheduleLoading(state, id),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    scheduleListRequest,
    scheduleUpdateOrCreateRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserScheduleContainer);
