import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {connectModal} from 'redux-modal';
import {MHT_EXIT_CONFIRM_MODAL} from 'misc/constants';
import PureRenderComponent from 'components/core/PureRenderComponent';
import {closeTab, setConfirmRequire} from 'actions/globalTabs';
import {getCurrentTab} from "selectors/globalTabs";
import {
  TaModal,
  TaModalContent,
  TaModalContentMargin,
  TaModalFooter,
  TaModalHeader
} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";
import {TaFormMessages} from "components/taUi/taFormMessage/taFormMessage";

class MHTExitConfirmModal extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = {
      primaryIsLoading: false,
      secondaryIsLoading: false,
      tertiaryIsLoading: false
    };
  }

  closeTabAndMessage = () => {
    this.props.handleHide();
    this.props.setConfirmRequire(this.props.currentTab, false);
    this.props.closeTab(this.props.currentTab);
  };

  onExecute = (action) => {
    const props = this.props;
    let fn;
    switch (action) {
      case 'primary':
        fn = props.handlePrimaryAction
        break;
      case 'secondary':
        fn = props.handleSecondaryAction
        break;
      case 'tertiary':
        fn = props.handleTertiaryAction
        break;
    }
    const isSavingLoading = action + 'IsLoading';
    if (
      typeof fn === 'function'
    ) {
      const runHandleSave = fn();
      if (typeof runHandleSave.then !== 'function') {
        // close immediately
        this.closeTabAndMessage();
      } else {
        // close after promise
        this.setState({
          [isSavingLoading]: true
        }, () => {
          runHandleSave
            .then(response => {
              if (response) {
                this.closeTabAndMessage()
              } else {
                console.error('callback did not return a truthy value', response)
                this.setState({
                  [isSavingLoading]: false
                });
              }
            })
            .catch(error => {
              this.setState({
                [isSavingLoading]: false
              });
            });
        });
      }
    } else {
      this.closeTabAndMessage();
    }

  };

  render() {
    const {
      show,
      handleHide,
      title,
      body,
      error,
      primaryActionLabel,
      primaryActionIcon,
      secondaryActionLabel,
      secondaryActionIcon,
      tertiaryActionLabel,
      tertiaryActionIcon,
    } = this.props;

    return (
      <TaModal
        isOpen={show}
        onCancel={handleHide}
      >
        <TaModalHeader
          onCancel={handleHide}
          label={title}
          warning
        />
        <TaModalContent>
          <TaModalContentMargin>
            {body}
          </TaModalContentMargin>
          { error && (
            <TaFormMessages
              messages={[{ type: 'error', message: error }]}/>
          )}
        </TaModalContent>
        <TaModalFooter>
          <TaButton
            type="reset"
            // icon={'clear'}
            // iconAlign={'right'}
            label={'Cancel'}
            onClick={() => handleHide()}
          />
          <div/>
          {
            (tertiaryActionLabel) && (
              <TaButton
                icon={tertiaryActionIcon}
                label={tertiaryActionLabel}
                onClick={() => this.onExecute('tertiary')}
                isLoading={this.state.tertiaryIsLoading}
              />
            )
          }
          {
            (secondaryActionLabel) && (
              <TaButton
                icon={secondaryActionIcon}
                label={secondaryActionLabel}
                onClick={() => this.onExecute('secondary')}
                isLoading={this.state.secondaryIsLoading}
              />
            )
          }
          <TaButton
            type="submit"
            icon={primaryActionIcon}
            label={primaryActionLabel}
            onClick={() => this.onExecute('primary')}
            isLoading={this.state.primaryIsLoading}
          />
        </TaModalFooter>
      </TaModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentTab: getCurrentTab(state),
  };
};

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    closeTab,
    setConfirmRequire
  }, disptach);
};

MHTExitConfirmModal = connectModal({name: MHT_EXIT_CONFIRM_MODAL})(MHTExitConfirmModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MHTExitConfirmModal);

