import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  preferableUserListRequest,
  preferableUserAddRequest,
  preferableUserRemoveRequest
} from 'actions/clients';
import { getClientPreferableUsersById } from 'selectors/lists';
import { isClientPreferableUserLoading } from 'selectors/loading';
import ClientUserPreferenceHOC from './ClientUserPreferenceHOC';
import ClientUserPreference from './presenter';


const mapStateToProps = (state, props) => {
  const id = props.clientId;
  return {
    title: "Preferable User",
    preferences: getClientPreferableUsersById(state, id),
    isLoading: isClientPreferableUserLoading(state, id)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest: preferableUserListRequest,
    addRequest: preferableUserAddRequest,
    removeRequest: preferableUserRemoveRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientUserPreferenceHOC(ClientUserPreference));
