import React from "react";
import {TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";
import {Alert,Button, Form, Modal} from "react-bootstrap";
import {change, Field, formValueSelector, reduxForm, reset} from "redux-form";
import {connect} from "react-redux";
import {
  isWordListDetailImportModalLoading
} from "selectors/loading";
import {bindActionCreators} from "redux";
import {
  TRANSLATION_MEMORY_DETAIL_IMPORT_FORM,
  TRANSLATION_MEMORY_DETAIL_IMPORT_MODAL
} from "misc/constants";
import Spinner from "components/core/Spinner";
import {connectModal} from "redux-modal";
import SingleDropzoneField from "components/core/SingleDropzoneField";
import * as validators from "misc/validators";
import FontAwesome from "react-fontawesome";
import SelectField from "components/core/SelectField";
import JobNumberAsyncSelectField from "components/core/JobNumberAsyncSelectField";

class TranslationMemoryImportModal extends React.Component {

  componentDidMount() {
    this.props.reset(TRANSLATION_MEMORY_DETAIL_IMPORT_FORM)
  }

  componentDidUpdate(prevProps) {

    if (this.props.file && this.props.file !== prevProps.file) {
      const { file } = this.props;
      this.props.change('name', file.name)
    }
  }

  hide = () => {
    this.props.reset(TRANSLATION_MEMORY_DETAIL_IMPORT_FORM);
    this.props.handleHide();
  };

  handleCancel = () => {
    this.props.change('file', null);
  }

  renderFiles = () => {
    return this.props.file && (
      <li>
        <a>
          {this.props.file.name}
          <span className="pull-right" style={{ marginTop: '-2px' }}>
            <Button bsStyle="danger" onClick={this.handleCancel} bsSize="xs">
              <FontAwesome name="ban" className="mr-5" />Cancel
            </Button>
          </span>
        </a>
      </li>
    )
  }

  render() {
    const {
      show,
      handleSubmit,
      onSubmit,
      error,
      isLoading,
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={this.hide}
        animation={true}
        backdrop="static">
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit((values => onSubmit(values)))}
          onReset={this.hide}
        >
          <TaModalHeader
            label={'Import Translation Memory'}
            onCancel={this.hide}
          />
          <TaModalContent>
            <Field
              name="file"
              component={SingleDropzoneField}
              validate={[validators.required]}
              isRequired={true} />
            <ul className="nav nav-pills nav-stacked">
              {this.renderFiles()}
            </ul>
            { error &&
              <div className="m-5">
                <Alert
                  bsStyle="danger"
                  className="mb-0">
                  <ul className="pl-15">
                    <li>{error}</li>
                  </ul>
                </Alert>
              </div>
            }
            <div className="p-15">
              <Field
                name="jobs"
                label="Job"
                multi={true}
                component={JobNumberAsyncSelectField.Labeled} />
            </div>
            <Spinner isLoading={isLoading} />
          </TaModalContent>
          <TaModalFooter>
            <TaButton
              type="reset"
              icon={'clear'}
              label={'Cancel'}
            />
            <TaButton
              type="submit"
              icon={'check'}
              label={'Import'}
              iconAlign={'right'}
              disabled={!this.props.file}
            />
          </TaModalFooter>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const formSelector = formValueSelector(TRANSLATION_MEMORY_DETAIL_IMPORT_FORM);
  const file = formSelector(state, 'file');
  return {
    file: file,
    isLoading: isWordListDetailImportModalLoading(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    change,
    reset
  }, dispatch);
};

TranslationMemoryImportModal = connectModal({
  name: TRANSLATION_MEMORY_DETAIL_IMPORT_MODAL
})(TranslationMemoryImportModal);

TranslationMemoryImportModal = reduxForm({
  form: TRANSLATION_MEMORY_DETAIL_IMPORT_FORM,
  enableReinitialize: true
})(TranslationMemoryImportModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationMemoryImportModal);
