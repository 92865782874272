import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { default as ReactDatePicker } from 'react-datepicker';


const DatePicker = (props) => {
  
  const {
    currentDate,
    handleDateChange
  } = props;

  const cls = classNames({
    'form-control': true,
    'input-sm': true,
    'highlighted': true,
    'is-today': (
      currentDate &&
      moment(currentDate).isSame(moment(), 'day')
    )
  })

  return (
    <ReactDatePicker
      dateFormat="YYYY-MM-DD"
      selected={moment(currentDate)}
      onChange={handleDateChange}
      todayButton="Today"
      className={cls} />
  );
}


export default DatePicker;