import { connect } from 'react-redux';
import { getCheckerOptions } from 'selectors/options';
import SelectOptionField from './SelectOptionField';


const mapStateToProps = (state) => {
  return {
    name: 'checker',
    label: 'Checker',
    options: getCheckerOptions(state),
  }
}

export default connect(
  mapStateToProps,
)(SelectOptionField);

