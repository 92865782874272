import { takeLatest, call, put, fork, all } from 'redux-saga/effects';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { normalize } from 'normalizr';
import { hide } from 'redux-modal';
import API from 'services/InvoiceApi';
import { invoiceSchema } from 'schemas/invoices';
import * as actions from 'actions/invoices';
import * as actionTypes from 'actionTypes/invoices';
import * as entityActions from 'actions/entities';
import * as listActions from 'actions/lists';
import * as coreSagas from 'sagas/core';
import  { JOB_INVOICE_MODAL, JOB_INVOICE_LIST, INVOICE_LIST, DELIVERY_LIST } from 'misc/constants';
import { setLoading } from 'actions/loading';


// Create ======================================================================
function* create(action) {
  const { values } = action.payload;
  yield put(setLoading(JOB_INVOICE_MODAL, true));
  try {
    const response = yield call(API.create, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), invoiceSchema);
    yield put(entityActions.merge(normalized.entities));
    const jobs = response.data.jobs;
    yield all(jobs.map(id => put(entityActions.update('jobs', id, { invoice: response.data.id }))));
    yield all(jobs.map(id => put(listActions.addNestedItem(JOB_INVOICE_LIST, id, response.data.id))));
    yield put(hide(JOB_INVOICE_MODAL));
    if (values.finished) {
      yield all(response.data.jobs.map(id => put(
        listActions.removeItem(DELIVERY_LIST, id)
      )));
    }
    yield fork(coreSagas.successNotification, 'Invoice created successfully.');
    yield put(setLoading(JOB_INVOICE_MODAL, false));
  } catch (error) {
    yield put(actions.createFailure(error));
    yield put(setLoading(JOB_INVOICE_MODAL, false));
  }
}

// List ========================================================================
function* list(action) {
  yield put(setLoading(INVOICE_LIST, true));
  const { currentMonth } = action.payload;
  try {
    const response = yield call(API.list, decamelizeKeys({ currentMonth }));
    const normalized = normalize(camelizeKeys(response.data), [invoiceSchema]);
    yield put(entityActions.merge(normalized.entities));
    yield put(listActions.setMonthlyResult(INVOICE_LIST, currentMonth, normalized.result));
    yield put(setLoading(INVOICE_LIST, false));
  } catch (error) {
    yield put(actions.listFailure(error));
    yield put(setLoading(INVOICE_LIST, false));
  }
}


// Update ======================================================================
function* update(action) {
  const { id, values } = action.payload;
  yield put(setLoading(JOB_INVOICE_MODAL, true));
  try {
    const response = yield call(API.update, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), invoiceSchema);
    yield put(entityActions.merge(normalized.entities));
    const jobs = response.data.jobs;
    yield all(jobs.map(id => put(entityActions.update('jobs', id, { invoice: response.data.id }))));
    if (values.finished) {
      yield all(jobs.map(id => put(listActions.removeItem(DELIVERY_LIST, id))));
    }
    yield put(hide(JOB_INVOICE_MODAL));
    yield fork(coreSagas.successNotification, 'Invoice updated successfully.');
    yield put(setLoading(JOB_INVOICE_MODAL, false));
  } catch (error) {
    yield put(actions.updateFailure(error));
    yield put(setLoading(JOB_INVOICE_MODAL, false));
  }
}

// Patch =======================================================================
function* patch(action) {
  const { id, values } = action.payload;
  try {
    const response = yield call(API.patch, id, decamelizeKeys(values));
    const normalized = normalize(camelizeKeys(response.data), invoiceSchema);
    yield put(entityActions.merge(normalized.entities));
  } catch (error) {
    yield put(actions.patchFailure(error));
  }
}

// Print =======================================================================
function* print(action) {
  const { id } = action.payload;
  try {
    yield call(API.print, id);
    const response = yield call(API.patch, id, { printed: true });
    const normalized = normalize(camelizeKeys(response.data), invoiceSchema);
    yield put(entityActions.merge(normalized.entities));
  } catch (error) {
    yield put(actions.printFailure(error));
  }
}

function* watchCreate() {
  yield takeLatest(actionTypes.CREATE.REQUEST, create);
}
function* watchList() {
  yield takeLatest(actionTypes.LIST.REQUEST, list);
}
function* watchUpdate() {
  yield takeLatest(actionTypes.UPDATE.REQUEST, update);
}
function* watchPatch() {
  yield takeLatest(actionTypes.PATCH.REQUEST, patch);
}
function* watchPrint() {
  yield takeLatest(actionTypes.PRINT.REQUEST, print);
}

export {
  watchCreate,
  watchList,
  watchUpdate,
  watchPatch,
  watchPrint,
}