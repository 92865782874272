import { schema } from 'normalizr';
import { serviceSchema, industrySchema, fieldSchema } from './core'
import { userSchema, groupSchema } from './users';
import { clientSchema } from './clients';
import { projectSchema } from './documents';

export const assignmentSchema = new schema.Entity('assignments', {
  assignee: userSchema,
  role: groupSchema,
  projects: [projectSchema]
})

export const jobSchema = new schema.Entity('jobs', {
  service: serviceSchema,
  industry: industrySchema,
  field: fieldSchema,
  client: clientSchema,
  creator: userSchema,
  additionalCountRoles: [groupSchema],
  ae: userSchema,
  coordinator: userSchema,
  totra: assignmentSchema,
  translator: assignmentSchema,
  editor: assignmentSchema,
  checker: assignmentSchema,
  finalEditor: assignmentSchema,
  secondFinalEditor: assignmentSchema,
  dtp: assignmentSchema,
  secondDtp: assignmentSchema,
});

export const messageSchema = new schema.Entity('messages', {
  sender: userSchema,
  roles: [groupSchema],
})

export const paymentSchema = new schema.Entity('payments', {
  job: jobSchema
})

export const mhtJobMessageSchema = new schema.Entity('mhtMessages', {
  sender: userSchema,
})