import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { Content } from 'reactjs-admin-lte';
import JobDeliveryDetailTab from './JobDeliveryDetailTab';
import JobDeliveryDetailTabPane from './JobDeliveryDetailTabPane';
import JobDeliveryDetailJobNavigation from './JobDeliveryDetailJobNavigation';
import JobDeliveryDetailJobInfo from './JobDeliveryDetailJobInfo';
import JobDeliveryDetailInvoiceNavigation from './JobDeliveryDetailInvoiceNavigation';
import JobDeliveryDetailInvoiceInfo from './JobDeliveryDetailInvoiceInfo';


const JobDeliveryDetail = (props) => {
  
  const {
    job,
    invoices,
    isLoading,
    isJobMemberListLoading,
    isJobInvoiceListLoading,
    selectedJobs,
    selectedInvoice,
    memberOptions,
    isJobSelected,
    isAllJobSelected,
    handleTabOpen,
    handleJobSelect,
    handleAllJobSelect,
    handleInvoiceSelect,
    handleInvoicePrint,
    showInvoiceAddModal,
    showInvoiceEditModal,
  } = props;
  
  return (
    <Content.Body>
      <Tab.Container
        id="job-delivery-detail-tab"
        className="job-delivery-detail-tabs-container"
        defaultActiveKey={1}>
        <div>
          <Nav bsStyle="tabs">
            <JobDeliveryDetailTab
              eventKey={1}
              title={job && job.number} />
            <JobDeliveryDetailTab
              eventKey={2}
              title="Invoices"
              count={invoices.length}
              isLoading={isJobInvoiceListLoading}
              requireCount={true}
              disabled={invoices.length === 0} />
          </Nav>
          <Tab.Content animation={false}>
            <JobDeliveryDetailTabPane
              eventKey={1}
              navigation={
                <JobDeliveryDetailJobNavigation
                  isLoading={isJobMemberListLoading}
                  selectedJobs={selectedJobs}
                  memberOptions={memberOptions}
                  isJobSelected={isJobSelected}
                  isAllJobSelected={isAllJobSelected}
                  handleJobSelect={handleJobSelect}
                  handleAllJobSelect={handleAllJobSelect}
                  showInvoiceAddModal={showInvoiceAddModal} />
              }
              content={
                <JobDeliveryDetailJobInfo
                  job={job}
                  isLoading={isLoading}
                  handleTabOpen={handleTabOpen}
                  memberOptions={memberOptions} />
              }/>
              <JobDeliveryDetailTabPane
                eventKey={2}
                navigation={
                  <JobDeliveryDetailInvoiceNavigation
                    invoices={invoices}
                    isLoading={isJobInvoiceListLoading}
                    selectedInvoice={selectedInvoice}
                    handleInvoiceSelect={handleInvoiceSelect}
                    handleInvoicePrint={handleInvoicePrint}
                    showInvoiceEditModal={showInvoiceEditModal} />
                }
                content={
                  <JobDeliveryDetailInvoiceInfo
                    invoices={invoices}
                    isLoading={isJobInvoiceListLoading}
                    selectedInvoice={selectedInvoice}
                    handleInvoiceSelect={handleInvoiceSelect} />
                }
              />
          </Tab.Content>
        </div>
      </Tab.Container>
    </Content.Body>
  );
}

export default JobDeliveryDetail;
