import merge from 'lodash.merge';
import { SET_PROCESSED } from 'actionTypes/jobs/employees';
import {
  SET_QUICK_EDIT,
  SET_ASSIGNEE_OPTIONS,
  RESET_ASSIGNEE_OPTIONS
} from 'actionTypes/jobs/managers';

const initialState = {
  assignmentProcessed: {},
  assigneeOptions: {},
  quickEdit: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PROCESSED:
      return setProcessed(state, action)
    case SET_QUICK_EDIT:
      return setQuickEdit(state, action);
    case SET_ASSIGNEE_OPTIONS:
      return setAssigneeOptions(state, action);
    case RESET_ASSIGNEE_OPTIONS:
      return resetAssigneeOptions(state, action);
    default:
      return state;
  }
}


const setProcessed = (state, action) => {
  const { id, processed } = action.payload;
  return {
    ...state,
    assignmentProcessed: {
      ...state.assignmentProcessed,
      [id]: processed
    }
  }
}

const setQuickEdit = (state, action) => {
  const { name, id } = action.payload;
  return {
    ...state,
    quickEdit: {
      ...state.quickEdit,
      [name]: id
    }
  }
}

const setAssigneeOptions = (state, action) => {
  const { name, options } = action.payload;
  return {
    ...state,
    assigneeOptions: {
      ...state.assigneeOptions,
      [name]: merge({}, state.assigneeOptions[name], options)
    }
  }
}

const resetAssigneeOptions = (state, action) => {
  const { name } = action.payload;
  return {
    ...state,
    assigneeOptions: {
      ...state.assigneeOptions,
      [name]: {
        'totra': [],
        'translator': [],
        'editor': [],
        'checker': [],
        'finalEditor': [],
        'secondFinalEditor': [],
        'dtp': [],
        'secondDtp': []
      }
    }
  }
}