import React from "react";
import * as PropTypes from 'prop-types';
import './taTable.css';

export const TaTableHeader = (props) => {

  const baseClass = 'ta-table-header';
  let classNames = [baseClass];

  if (props.look && props.look !== 'auto') {
    classNames.push(baseClass + '--' + props.look);
  }
  if (props.className) {
    classNames.push(props.className);
  }
  return (
    <div className={classNames.join(' ')}>{props.children}</div>
  )

};
TaTableHeader.propTypes = {
};
