import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Form, Button } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { Box } from 'reactjs-admin-lte';
import * as validators from 'misc/validators';
import Callout from 'components/core/Callout';
import CharField from 'components/core/CharField';
import TextField from 'components/core/TextField';
import { IndustrySelectOptionField } from 'components/core/SelectOptionField';


const ClientCreateForm = (props) => {
  
  const {
    invalid,
    submitFailed,
    isLoading,
    handleSubmit,
    handleCreate
  } = props;
  
  return (
    <Form
      noValidate
      autoComplete="off">
      <Callout
        invalid={invalid}
        submitFailed={submitFailed} />
      <Box
        className="box-widget"
        loading={isLoading}>
        <Box.Body>
          <Field
            name="name"
            label="Name"
            type="text"
            validate={[
              validators.required,
              validators.alphanumeric,
              validators.maxLength100
            ]}
            isRequired={true}
            component={CharField.Labeled} />
          <Field
            name="fullNameEn"
            label="Full Name in English"
            type="text" 
            validate={[
              validators.alphanumeric,
              validators.maxLength150
            ]}
            component={CharField.Labeled} />
          <Field
            name="fullNameJp"
            label="Full Name in Japanese"
            type="text"
            validate={[
              validators.maxLength150
            ]}
            component={CharField.Labeled} />
          <IndustrySelectOptionField />
          <Field
            name="notes"
            label="Notes"
            component={TextField.Labeled} />
        </Box.Body>
        <Box.Footer>
          <Button
            type="button"
            bsStyle="primary"
            bsSize="sm"
            onClick={handleSubmit(values => handleCreate(values))}>
            <FontAwesome name="paper-plane-o" className="mr-5" />Submit
          </Button>
        </Box.Footer>
      </Box>
    </Form>
  )
}

export default reduxForm()(ClientCreateForm);
