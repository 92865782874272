import React from 'react';

const HeaderCustomMenu = (props) => (
  <div className="navbar-custom-menu">
    <ul className="nav navbar-nav">
      {props.children}
    </ul>
  </div>
)

export default HeaderCustomMenu;
