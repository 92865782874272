import React from 'react'
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import { ProgressBar } from 'react-bootstrap';
import { FILE_DOWNLOAD_PROGRESS_MODAL } from 'misc/constants';
import { getDownloadProgress } from 'selectors/core';
import PureRenderComponent from 'components/core/PureRenderComponent';


class FileDownloadProgressModal extends PureRenderComponent {

  render() {
    const {
      show,
      handleHide,
      progress
    } = this.props;
    
    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Downloading...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProgressBar
            active
            now={progress} />
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    progress: getDownloadProgress(state)
  }
}

FileDownloadProgressModal = connectModal({
  name: FILE_DOWNLOAD_PROGRESS_MODAL
})(FileDownloadProgressModal);

export default connect(
  mapStateToProps
)(FileDownloadProgressModal);

