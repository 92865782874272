import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ASSIGNMENT_ROLE_VALUE_MAP } from 'misc/config';
import { getFormValues, change } from 'redux-form';
import { JOB_QUICK_EDIT_FORM } from 'misc/constants';
import { quickEditRequest } from 'actions/jobs/managers';
import { parseAssignmentValues } from 'misc/utils';
import {
  getToTraOptions, getTranslatorOptions, getEditorOptions, getCheckerOptions,
  getFinalEditorOptions, getSecondFinalEditorOptions, getDtpOptions,
  getSecondDtpOptions, getAssigneeGroupOptions,
} from 'selectors/options';
import { getJobScheduleQuickEdit } from 'selectors/jobs';
import { getJobQuikEditFormInitialValues } from 'selectors/forms';
import { isJobQuickEditLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import JobQuickEditForm from './JobQuickEditForm';


class JobQuickEditContainer extends PureRenderComponent {

  handleTrash = (name) => {
    this.props.change(this.props.form, name, {});
  }

  handleEdit = (values) => {
    const formData = parseAssignmentValues(values);
    this.props.quickEditRequest(this.props.form, this.props.id, formData);
  }

  requireNotification = () => {
    return Object.keys(ASSIGNMENT_ROLE_VALUE_MAP).some(key => {
      const initialValue = this.props.initialValues && this.props.initialValues[key] ? this.props.initialValues[key] : {};
      const formValue = this.props.formValues && this.props.formValues[key] ? this.props.formValues[key] : {};
      return (
        (initialValue.startDate !== formValue.startDate) ||
        (initialValue.startTime !== formValue.startTime) ||
        (initialValue.endDate !== formValue.endDate) ||
        (initialValue.endTime !== formValue.endTime)
      )
    })
  }

  render() {
    return (
      <JobQuickEditForm
        {...this.props}
        handleEdit={this.handleEdit}
        handleTrash={this.handleTrash}
        requireNotification={this.requireNotification} />
    );
  }
}


const mapStateToProps = (state, props) => {
  const form = `${JOB_QUICK_EDIT_FORM}/${props.name}`;
  const initialValues = getJobQuikEditFormInitialValues(state, props.name);
  const formValues = getFormValues(form)(state);
  const id = getJobScheduleQuickEdit(state, props.name);
  return {
    id,
    form,
    formValues,
    initialValues,
    isLoading: isJobQuickEditLoading(state, form),
    totraOptions: getToTraOptions(state),
    translatorOptions: getTranslatorOptions(state),
    editorOptions: getEditorOptions(state),
    checkerOptions: getCheckerOptions(state),
    finalEditorOptions: getFinalEditorOptions(state),
    secondFinalEditorOptions: getSecondFinalEditorOptions(state),
    dtpOptions: getDtpOptions(state),
    secondDtpOptions: getSecondDtpOptions(state),
    assigneeGroupOptions: getAssigneeGroupOptions(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    change,
    quickEditRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobQuickEditContainer);
