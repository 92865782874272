import React from 'react'
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import * as validators from 'misc/validators';
import { CLIENT_USER_PREFERENCE_MODAL, CLIENT_USER_PREFERENCE_FORM } from 'misc/constants';
import { isClientUserPreferenceModalLoading } from 'selectors/loading';
import Spinner from 'components/core/Spinner';
import SelectField from 'components/core/SelectField';
import PureRenderComponent from 'components/core/PureRenderComponent';


class ClientUserPreferenceModal extends PureRenderComponent {
  
  render() {
    const {
      show,
      title,
      handleHide,
      handleSubmit,
      handleAddUser,
      error,
      options,
      isLoading
    } = this.props

    return (
      <Modal
        show={show}
        onHide={handleHide}
        animation={true}
        backdrop="static">
        <Form
          noValidate
          autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title>Add {title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              name="user"
              label="User"
              validate={[validators.required]}
              isRequired={true}
              options={options}
              component={SelectField.Labeled} />
            { error &&
              <div className="callout callout-danger">
                <p>{error}</p>
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pull-left"
              bsSize="sm"
              type="button"
              onClick={handleHide}>
              <FontAwesome name="close" className="mr-5" />Close
            </Button>
            <Button
              bsStyle="primary"
              bsSize="sm"
              type="button"
              onClick={handleSubmit(values => handleAddUser(values))}>
              <FontAwesome name="paper-plane-o" className="mr-5" />Submit
            </Button>
          </Modal.Footer>
          <Spinner isLoading={isLoading} />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: isClientUserPreferenceModalLoading(state)
  }
}

ClientUserPreferenceModal = connectModal({
  name: CLIENT_USER_PREFERENCE_MODAL
})(ClientUserPreferenceModal);

ClientUserPreferenceModal = reduxForm({
  form: CLIENT_USER_PREFERENCE_FORM
})(ClientUserPreferenceModal);

export default connect(
  mapStateToProps
)(ClientUserPreferenceModal);
