import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Navbar,Nav, NavItem } from 'react-bootstrap';
import { JOB_SCHEDULE_DEADLINES } from 'misc/config';
import FilterBar from 'components/core/FilterBar';
import Select from 'components/core/Select';
import DatePicker from 'components/core/DatePicker';


const JobScheduleFilter = (props) => {

  const {
    currentDate,
    handleDateChange,
    handlePrev,
    handleNext,
    viewAs,
    viewAsOptions,
    handleViewAsChange,
    deadline,
    handleDeadlineChange,
    isEmployeeSelected,
    assignee,
    assigneeOptions,
    handleAssigneeChange
  } = props;

  return (
    <FilterBar>
      <Nav>
        <NavItem onClick={handlePrev}>
          <FontAwesome name="chevron-left" className="mr-10" />Prev Day
        </NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <DatePicker
          currentDate={currentDate}
          handleDateChange={handleDateChange} />
      </Navbar.Form>
      <Nav>
        <NavItem onClick={handleNext}>
          Next Day<FontAwesome name="chevron-right" className="ml-10" />
        </NavItem>
      </Nav>
      <Nav>
        <NavItem disabled>View as</NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <Select
          value={viewAs}
          clearable={false}
          options={viewAsOptions}
          onChange={handleViewAsChange}
          style={{ width: 150 }} />
      </Navbar.Form>
      <Nav>
        <NavItem disabled>Deadline</NavItem>
      </Nav>
      <Navbar.Form pullLeft>
        <Select
          value={deadline}
          clearable={false}
          options={JOB_SCHEDULE_DEADLINES}
          onChange={handleDeadlineChange}
          style={{ width: 150 }} />
      </Navbar.Form>
      { isEmployeeSelected &&
        <Navbar.Form pullLeft>
          <Select
            value={assignee}
            options={assigneeOptions}
            onChange={handleAssigneeChange}
            className="ml-5"
            placeholder=""
            style={{ width: 170 }} />
        </Navbar.Form>
      }
    </FilterBar>
  );
}

export default JobScheduleFilter;
