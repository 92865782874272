import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Field, reduxForm } from 'redux-form';
import { Button, Form } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';
import CharField from 'components/core/CharField';
import TextField from 'components/core/TextField';
import { CountrySelectOptionField } from 'components/core/SelectOptionField';


const UserProfileForm = (props) => {
  
  const {
    handleSubmit,
    isLoading,
    hasHeader,
  } = props;
  
  return (
    <Form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}>
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        { hasHeader &&
          <Box.Header border={true}>
            <Box.Title>Profile</Box.Title>
            <Box.Tools>
              <Box.CollapsedToggleButton />
            </Box.Tools>
          </Box.Header>
        }
        <Box.Body>
          <Field
            name="tel"
            label="Tel"
            type="text"
            component={CharField.Labeled} />
          <Field
            name="mobile"
            label="Mobile"
            type="text"
            component={CharField.Labeled} />
          <Field
            name="zipcode"
            label="Zipcode"
            type="text"
            component={CharField.Labeled} />
          <Field
            name="address"
            label="Address"
            type="text"
            component={CharField.Labeled} />
          <CountrySelectOptionField />
          <Field
            name="emergencyContactName"
            label="Emergency Contact Name"
            type="text"
            component={CharField.Labeled} />
          <Field
            name="emergencyContactPhone"
            label="Emergency Contact Phone"
            type="text"
            component={CharField.Labeled} />
          <Field
            name="notes"
            label="Notes"
            component={TextField.Labeled} />
        </Box.Body>
        <Box.Footer>
          <Button bsStyle="success" bsSize="sm" type="submit">
            <FontAwesome name="refresh" className="mr-5" />Update
          </Button>
        </Box.Footer>
      </Box>
    </Form>
  );
}

export default reduxForm({
  enableReinitialize: true
})(UserProfileForm);
