import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';
import { MULTIPLE_FILE_UPLOAD_MODAL } from 'misc/constants';
import { sourcefileUploadRequest, sourcefileRemoveRequest, sourcefileDownloadRequest } from 'actions/documents'
import { getSourcefilesByForm } from 'selectors/lists';
import PureRenderComponent from 'components/core/PureRenderComponent';
import Sourcefile from './presenter';


class SourcefileContainer extends PureRenderComponent {

  showUploadModal = () => {
    this.props.show(MULTIPLE_FILE_UPLOAD_MODAL, {
      title: 'Source File',
      handleUpload: this.handleUpload
    })
  }

  handleUpload = (files) => {
    this.props.sourcefileUploadRequest(this.props.form, files);
  }

  handleDownload = (document) => {
    this.props.sourcefileDownloadRequest(document);
  }

  handleRemove = (document, idx) => {
    this.props.sourcefileRemoveRequest(this.props.form, document, idx);
  }

  render() {
    return (
      <Sourcefile
        {...this.props}
        showUploadModal={this.showUploadModal}
        handleDownload={this.handleDownload}
        handleRemove={this.handleRemove} />
    );
  }
}

const mapStateToProps = (state, props) => {
  const form = props.form;
  return {
    documents: getSourcefilesByForm(state, form, 'sourcefiles')
  }
}
const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    show,
    sourcefileUploadRequest,
    sourcefileDownloadRequest,
    sourcefileRemoveRequest
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SourcefileContainer)
