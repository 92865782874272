import React from 'react';
import { currencyFormatter, percentFormatter } from 'misc/cellFormatters';
import ListTable from 'components/core/ListTable';


const ValueCellRenderer = (props) => {
  if (props.data) {
    if (props.data.title === 'Progress') {
      return percentFormatter(props);
    } else {
      return currencyFormatter(props);
    }
  }
}

const SalesReportTable = (props) => {

  const { aeOptions, results } = props;

  const columnDefs = [{
    headerName: '',
    field: 'title',
    pinned: 'left',
    width: 100,
    lockPinned: true,
    lockPosition: true,
    suppressFilter: true,
    suppressSorting: true,
  }];

  aeOptions.forEach(option => {
    columnDefs.push({
      field: option.meta.username,
      headerName: option.meta.name,
      width: 100,
      lockPinned: true,
      suppressFilter: true,
      suppressSorting: true,
      cellClass: 'ag-text-right',
      cellRendererFramework: ValueCellRenderer,
      filter: 'agNumberColumnFilter',
    })
  });

  columnDefs.push({
    field: 'total',
    headerName: 'Total',
    width: 100,
    pinned: 'right',
    cellClass: 'ag-text-right',
    lockPinned: true,
    suppressFilter: true,
    suppressMovable: true,
    suppressSorting: true,
    cellRendererFramework: ValueCellRenderer,
  });


  const getPinnedBottomRowData = () => {
    const { total, target, progress } = props;
    return [total, target, progress];
  }

  return (
    <ListTable
      wrapperHeight="81vh"
      columnDefs={columnDefs}
      rowData={results}
      pinnedBottomRowData={getPinnedBottomRowData()} />
  );
}


export default SalesReportTable;
