import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Table } from 'react-bootstrap';
import styles from 'components/mht/TranslationMemorySearch/TranslationMemorySearch.module.css';
import DateFormatter from 'components/core/DateFormatter';

const NotFound = () => (
  <tr>
    <td colSpan={5} className="text-center">No Rows To Show.</td>
  </tr>
)

const renderAttributes = (item) => {
  const items = [];
  const attrKeys = ['client', 'jobs'];
  attrKeys.forEach(key => {
    if (item[key]) {
      const value = item[key];
      const title = (key === 'jobs') ? 'number' : 'name';
      if (Array.isArray(value)) {
        value.forEach(item => items.push(item[title]));
      } else {
        items.push(value[title]);
      }
    }
  });
  return (
    <div className={styles.expandSearchCell}>{items.join(', ')}</div>
  );
}

const areEqual = (prevProps, nextProps) => {
  return prevProps.memories === nextProps.memories;
}

const TranslationMemorySearchTable = React.memo((props) => {
  const { memories } = props;
  return (
    <Table striped bordered hover>
      <thead>
      <tr>
        <th width="40%">Source</th>
        <th width="40%">Target</th>
        <th width="3%">Penalty</th>
        <th width="5%">Registered Date</th>
        <th width="12%">Attributes</th>
      </tr>
      </thead>
      <tbody>
        {memories && Array.isArray(memories) && memories.length > 0
          ? memories.map((item) => {
            return (
              <tr key={item.id}>
                <td>{item.source}</td>
                <td>{item.target}</td>
                <td className="text-center">
                  {
                    item.isPenalty
                    ? <FontAwesome name="check" className="text-success" tag="i" />
                    : '-'
                  }
                </td>
                <td><DateFormatter datetime={item.registered} format="YYYY-MM-DD" /></td>
                <td>{renderAttributes(item)}</td>
              </tr>
            )
            })
          : <NotFound />
        }
      </tbody>
    </Table>
  );
}, areEqual)

export default TranslationMemorySearchTable;
