import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listRequest } from 'actions/templates';
import { getTemplates } from 'selectors/lists';
import { isTemplateListLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import TemplateList from './presenter';


class TemplateListContainer extends PureRenderComponent {

  componentDidMount() {
    this.props.listRequest();
  }

  render() {
    return <TemplateList {...this.props} />
  }
}

const mapStateToProps = (state) => {
  return {
    templates: getTemplates(state),
    isLoading: isTemplateListLoading(state)
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    listRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateListContainer);