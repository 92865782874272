import React from 'react';
import { Content, Box } from 'reactjs-admin-lte';
import CalendarRow from './CalendarRow';
import CalendarFilter from './CalendarFilter';


const Calendar = (props) => {
  const {
    isLoading,
    group,
    handlePrev,
    handleNext,
    visibleRange,
    groupOptions,
    events,
    handleGroupChange,
    handleTabOpen,
    handleEventClick,
    selectNestedTab,
  } = props;

  const renderCalendar = () => {
    if (events.length === 0) {
      return (
        <div
          className="text-center mt-10 mb-10">
            No Rows To Show
        </div>
      );
    }
    return events.map((data, idx) => (
      <div key={data.userId}>
        <CalendarRow
          data={data}
          hasColumnHeader={idx === 0}
          visibleRange={visibleRange}
          handleTabOpen={handleTabOpen}
          onEventClick={handleEventClick}
          selectNestedTab={selectNestedTab} />
      </div>
    ))
  }

  return (
    <Content.Body>
      <CalendarFilter
        group={group}
        visibleRange={visibleRange}
        groupOptions={groupOptions}
        handlePrev={handlePrev}
        handleNext={handleNext}
        handleGroupChange={handleGroupChange} />
      <Box
        className="box-widget mb-0"
        loading={isLoading}>
        <Box.Body noPadding={true}>
          {renderCalendar()}
        </Box.Body>
      </Box>
    </Content.Body>
  );
}

export default Calendar;