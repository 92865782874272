import React from 'react';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change, formValueSelector } from 'redux-form';
import { Button } from 'react-bootstrap';
import { FormControl, InputGroup } from 'react-bootstrap';
import { getServiceEntities } from 'selectors/entities';
import LabeledField from 'components/core/LabeledField';
import PureRenderComponent from 'components/core/PureRenderComponent';


class JobSourceCountInputField extends PureRenderComponent {

  isJ2E = () => {
    const { services, service } = this.props;
    return services[service] && (
      services[service]['src'] === 'ja-JP' &&
      services[service]['dst'] === 'en-US'
    )
  }

  isE2J = () => {
    const { services, service } = this.props;
    return services[service] && (
      services[service]['src'] === 'en-US' &&
      services[service]['dst'] === 'ja-JP'
    )
  }

  handleCount = () => {
    const { sourceCount } = this.props;
    let count = 0;
    if (this.isJ2E()) {
      count = sourceCount / 400 * 200;
    } else if (this.isE2J()) {
      count = sourceCount / 200 * 1.3 * 400;
    }
    count = Math.floor(count);
    this.props.change(this.props.formName, 'targetCount', count);
  }

  render() {
    
    const {
      input,
      bsSize,
    } = this.props;

    const disabled = (
      !this.props.sourceCount ||
      (!this.isE2J() && !this.isJ2E())
    )
    
    return (
      <InputGroup bsSize={bsSize}>
        <FormControl
          {...input}
          type="number"
          bsSize={bsSize} />
        <InputGroup.Button>
          <Button
            type="button"
            bsStyle="default"
            bsSize={bsSize}
            disabled={disabled}
            onClick={this.handleCount}>
            <FontAwesome name="magic" />
          </Button>
        </InputGroup.Button>
      </InputGroup>
    );
  }
}

const LabeledJobSourceCountInputField = (props) => {
  return (
    <LabeledField {...props}>
      <JobSourceCountInputField {...props} />
    </LabeledField>
  );
}

const mapStateToProps = (state, props) => {
  const form = props.formName;
  const formSelector = formValueSelector(form);
  const service = formSelector(state, 'service');
  const sourceCount = formSelector(state, 'sourceCount');
  return {
    service,
    sourceCount,
    services: getServiceEntities(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    change,
  }, dispatch)
}

JobSourceCountInputField = connect(
  mapStateToProps,
  mapDispatchToProps
)(JobSourceCountInputField)

JobSourceCountInputField.Labeled = LabeledJobSourceCountInputField;

export default JobSourceCountInputField;
