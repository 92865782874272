import React from "react";
import * as PropTypes from 'prop-types';
import 'components/taUi/taTabs/taTab.css';
import {TaTab} from "components/taUi/taTabs/taTab";
import {TaTabLabel} from "components/taUi/taTabs/taTabLabel";

export class TaTabs extends React.PureComponent {

  static propTypes = {
    onChange: PropTypes.func,
    // children: PropTypes.oneOfType([
    //   PropTypes.instanceOf(TaTab),
    //   PropTypes.arrayOf(PropTypes.instanceOf(TaTab))
    // ])
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    };

  }

  setTab = (index) => {
    this.setState({activeIndex: index});
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(index);
    }
  };

  render() {

    const state = this.state;
    const props = this.props;

    const baseClass = 'ta-tab-group';
    const {
      className,
      scroll,
      flex,
      spacing,
      background,
      ...attributes
    } = props;

    let classNames = [baseClass];

    if (scroll === 'auto') {
      classNames.push(baseClass + '--scroll');
    } else if (scroll) {
      classNames.push(baseClass + '--scroll-' + scroll);
    }

    if (spacing === 'compact') {
      classNames.push(baseClass + '--compact');
    }

    if (background === 'grey') {
      classNames.push(baseClass + '--grey');
    }

    if (flex === 'auto') {
      classNames.push(baseClass + '--flex');
    } else if (flex && scroll !== 'hidden') {
      if (typeof attributes.style === 'undefined') {
        attributes.style = {};
      }
      attributes.style.flex = flex;
    }

    if (className) {
      classNames.push(className);
    }

    return (
      <div className={classNames.join(' ')}
      >
        <header className={'ta-tab-group__head'}>
          {React.Children.map(this.props.children,
            (child, index) => {
              if (child && child.props) {
                const props = child.props;
                return <TaTabLabel
                  key={index}
                  isActive={(state.activeIndex === index)}
                  disabled={props.disabled}
                  label={props.label}
                  icon={props.icon}
                  badge={props.badge}
                  onClick={(event) => (props.onClick) ? props.onClick(index) : this.setTab(index)}
                />;
              } else {
                return null;
              }
            })}
        </header>
        <React.Fragment>
          {React.Children.map(this.props.children,
            (child, index) => {
              return (state.activeIndex === index) ? child : null;
            })}
        </React.Fragment>
      </div>
    );
  }
}
