import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mhtJobListRequest } from 'actions/jobs/managers';
import { getMHTJobs, getMHTJobListCurrentPage, getMHTJobListPageSize } from 'selectors/lists';
import { isMHTJobListLoading } from 'selectors/loading';
import PureRenderComponent from 'components/core/PureRenderComponent';
import MHTJobList from './presenter';


class MHTJobListContainer extends PureRenderComponent {

  constructor(props) {
    super(props);
    this.state = {
      client: '',
      keyword: '',
      status: '',
      messageState: null,
    }
  }

  componentDidMount() {
    const params = { page: this.props.currentPage };
    this.props.mhtJobListRequest(params);
  }

  handleMessageStateChange = (messageState) => {
    this.setState({ messageState })
    this.props.mhtJobListRequest({
      messageState,
      page: 1,
      client: this.state.client,
      keyword: this.state.keyword,
      status: this.state.status,
    });
  }

  handleClientChange = (client) => {
    this.setState({ client })
    this.props.mhtJobListRequest({
      client,
      page: 1,
      keyword: this.state.keyword,
      status: this.state.status,
      messageState: this.state.messageState,
    });
  }

  handleStatusChange = (status) => {
    this.setState({ status })
    this.props.mhtJobListRequest({
      status,
      page: 1,
      keyword: this.state.keyword,
      client: this.state.client,
      messageState: this.state.messageState,
    });
  }

  handleKeywordChange = (keyword) => {
    this.setState({ keyword })
  }

  handleSubmit = () => {
    const { client, keyword, status, messageState } = this.state;
    this.props.mhtJobListRequest({
      keyword,
      client,
      status,
      messageState,
      page: 1,
    });
  }

  handlePageSelect = (page) => {
    const params = { page }
    this.props.mhtJobListRequest(params);
  }

  render() {
    return (
      <MHTJobList
        {...this.props}
        status={this.state.status}
        client={this.state.client}
        keyword={this.state.keyword}
        messageState={this.state.messageState}
        handleStatusChange={this.handleStatusChange}
        handleClientChange={this.handleClientChange}
        handleKeywordChange={this.handleKeywordChange}
        handleMessageStateChange={this.handleMessageStateChange}
        handleSubmit={this.handleSubmit}
        handlePageSelect={this.handlePageSelect} />
    );
  }
}


const mapStateToProps = (state) => {
  return {
    isLoading: isMHTJobListLoading(state),
    jobs: getMHTJobs(state),
    pageSize: getMHTJobListPageSize(state),
    currentPage: getMHTJobListCurrentPage(state),
  }
}

const mapDispatchToProps = (disptach) => {
  return bindActionCreators({
    mhtJobListRequest,
  }, disptach)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MHTJobListContainer);
