import React from 'react';
import { Box } from 'reactjs-admin-lte';
import TooltipIconButton from 'components/core/TooltipIconButton';
import ClientWebsiteTable from './ClientWebsiteTable';


const ClientWebsite = (props) => {
  const {
    isLoading,
    websites,
    uploadable,
    updatable,
    removable,
    showAddModal,
    showUpdateModal,
    handleDownload,
    handleUse,
    handleRemove
  } = props
  
  return (
    <Box
      className="box-widget"
      loading={isLoading}>
      <Box.Header border={true}>
        <Box.Title>Website</Box.Title>
        <Box.Tools>
          <TooltipIconButton
            id="add-client-website"
            className="btn-box-tool"
            icon="edit"
            description="Add Website URL"
            onClick={showAddModal} />
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <ClientWebsiteTable
            websites={websites}
            handleRemove={handleRemove} />
      </Box.Body>
    </Box>
    )
}


export default ClientWebsite;