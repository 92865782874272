import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Table, Button } from 'react-bootstrap';
import { Box } from 'reactjs-admin-lte';


const MHTJobFiles = (props) => {
  const {
    files,
    isLoading,
    showUploadModal,
    downloadSourceFile,
    downloadTargetFile,
    removeTargetFile,
  } = props;
  return (
    <Box className="box-widget mb-10" loading={isLoading}>
      <Box.Header>
        <Box.Title>Files</Box.Title>
        <Box.Tools>
          <Box.CollapsedToggleButton />
        </Box.Tools>
      </Box.Header>
      <Box.Body noPadding={true}>
        <Table striped bordered className="mb-0">
          <thead>
            <tr>
              <th width="40%">Filename</th>
              <th width="60%">Target Files</th>
            </tr>
          </thead>
          <tbody>
            {files.map(s => (
              <tr key={s.id}>
                <td><a onClick={e => downloadSourceFile(e, s)}>{s.name}</a></td>
                <td>
                  {s.targetFiles.map(t => (
                    <div key={t.id} style={{ padding: '5px', borderBottom: 'solid 1px #eee' }}>
                      <a onClick={e => downloadTargetFile(e, t)}>{t.name}</a>
                      <Button
                        className="pull-right"
                        bsStyle="danger"
                        bsSize="xs"
                        type="button"
                        onClick={() => removeTargetFile(s.id, t)}>
                        <FontAwesome name="trash" />
                      </Button>
                    </div>
                  ))}
                  <div style={{ marginTop: '5px' }}>
                    <Button
                      bsStyle="success"
                      bsSize="sm"
                      type="button"
                      onClick={() => showUploadModal(s.id)}>
                      <FontAwesome name="upload" className="mr-5" />Upload
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Box.Body>
    </Box>
  )
}

export default MHTJobFiles;