import * as types from 'actionTypes/users';
import * as utils from 'misc/utils';


const createUserAttrCreateAction = (type) => ({
  requestFn: (userId, values) => ({ type: type.REQUEST, payload: { userId, values } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

const createUserAttrListAction = (type) => ({
  requestFn: (userId) => ({ type: type.REQUEST, payload: { userId } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

const createUserAttrUpdateAction = (type) => ({
  requestFn: (userId, id, values) => ({ type: type.REQUEST, payload: { userId, id, values } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})

const createUserAttrDeleteAction = (type) => ({
  requestFn: (userId, id) => ({ type: type.REQUEST, payload: { userId, id } }),
  successFn: () => ({ type: type.SUCCESS }),
  failureFn: (error) => ({ type: type.FAILURE, payload: { error } }),
})


// List ========================================================================
const list = utils.createSearchAction(types.LIST);
export const listRequest = list.requestFn;
export const listSuccess = list.successFn;
export const listFailure = list.failureFn;

// Create ========================================================================
const create = utils.createCreateAction(types.CREATE);
export const createRequest = create.requestFn;
export const createSuccess = create.successFn;
export const createFailure = create.failureFn;

// Retrieve ====================================================================
const retrieve = utils.createRetrieveAction(types.RETRIEVE);
export const retrieveRequest = retrieve.requestFn;
export const retrieveSuccess = retrieve.successFn;
export const retrieveFailure = retrieve.failureFn;

// Retrieve ====================================================================
const update = utils.createUpdateAction(types.UPDATE);
export const updateRequest = update.requestFn;
export const updateSuccess = update.successFn;
export const updateFailure = update.failureFn;

// Remove ======================================================================
const remove = utils.createRemoveAction(types.REMOVE);
export const removeRequest = remove.requestFn;
export const removeSuccess = remove.successFn;
export const removeFailure = remove.failureFn;

// Profile Retrieve ============================================================
const profileRetrieve = utils.createRetrieveAction(types.PROFILE_RETRIEVE);
export const profileRetrieveRequest = profileRetrieve.requestFn;
export const profileRetrieveSuccess = profileRetrieve.successFn;
export const profileRetrieveFailure = profileRetrieve.failureFn;

// Profile Update ==============================================================
const profileUpdate = utils.createUpdateAction(types.PROFILE_UPDATE);
export const profileUpdateRequest = profileUpdate.requestFn;
export const profileUpdateSuccess = profileUpdate.successFn;
export const profileUpdateFailure = profileUpdate.failureFn;


// Avatar Upload ===============================================================
export const avatarUploadRequest = (userId, avatar) => ({
  type: types.AVATAR_UPLOAD.REQUEST,
  payload: { userId, avatar }
});
export const avatarUploadSuccess = () => ({
  type: types.AVATAR_UPLOAD.SUCCESS
});
export const avatarUploadFailure = (error) => ({
  type: types.AVATAR_UPLOAD.FAILURE,
  payload: { error }
});


export const salesTargetListRequest = (currentYear) => ({
  type: types.SALES_TARGET_LIST.REQUEST,
  payload: { currentYear }
});

export const salesTargetListSuccess = () => ({
  type: types.SALES_TARGET_LIST.SUCCESS
});

export const salesTargetListFailure = (error) => ({
  type: types.SALES_TARGET_LIST.FAILURE,
  payload: { error }
});

export const salesTargetCreateRequest = (values) => ({
  type: types.SALES_TARGET_CREATE.REQUEST,
  payload: { values }
});
export const salesTargetCreateSuccess = () => ({
  type: types.SALES_TARGET_CREATE.SUCCESS
});
export const salesTargetCreateFailure = (error) => ({
  type: types.SALES_TARGET_CREATE.FAILURE,
  payload: { error }
});

export const salesTargetPatchRequest = (id, values) => ({
  type: types.SALES_TARGET_PATCH.REQUEST,
  payload: { id, values }
});
export const salesTargetPatchSuccess = () => ({
  type: types.SALES_TARGET_PATCH.SUCCESS
});
export const salesTargetPatchFailure = (error) => ({
  type: types.SALES_TARGET_PATCH.FAILURE,
  payload: { error }
});

// Translation Skill List ======================================================
const translationSkillCreate = createUserAttrCreateAction(types.TRANSLATION_SKILL_CREATE);
export const translationSkillCreateRequest = translationSkillCreate.requestFn;
export const translationSkillCreateSuccess = translationSkillCreate.successFn;
export const translationSkillCreateFailure = translationSkillCreate.failureFn;

const translationSkillList = createUserAttrListAction(types.TRANSLATION_SKILL_LIST);
export const translationSkillListRequest = translationSkillList.requestFn;
export const translationSkillListSuccess = translationSkillList.successFn;
export const translationSkillListFailure = translationSkillList.failureFn;

const translationSkillUpdate = createUserAttrUpdateAction(types.TRANSLATION_SKILL_UPDATE);
export const translationSkillUpdateRequest = translationSkillUpdate.requestFn;
export const translationSkillUpdateSuccess = translationSkillUpdate.successFn;
export const translationSkillUpdateFailure = translationSkillUpdate.failureFn;

// Edit Skill List ======================================================
const editSkillCreate = createUserAttrCreateAction(types.EDIT_SKILL_CREATE);
export const editSkillCreateRequest = editSkillCreate.requestFn;
export const editSkillCreateSuccess = editSkillCreate.successFn;
export const editSkillCreateFailure = editSkillCreate.failureFn;

const editSkillList = createUserAttrListAction(types.EDIT_SKILL_LIST);
export const editSkillListRequest = editSkillList.requestFn;
export const editSkillListSuccess = editSkillList.successFn;
export const editSkillListFailure = editSkillList.failureFn;

const editSkillUpdate = createUserAttrUpdateAction(types.EDIT_SKILL_UPDATE);
export const editSkillUpdateRequest = editSkillUpdate.requestFn;
export const editSkillUpdateSuccess = editSkillUpdate.successFn;
export const editSkillUpdateFailure = editSkillUpdate.failureFn;

// Check Skill List ======================================================
const checkSkillCreate = createUserAttrCreateAction(types.CHECK_SKILL_CREATE);
export const checkSkillCreateRequest = checkSkillCreate.requestFn;
export const checkSkillCreateSuccess = checkSkillCreate.successFn;
export const checkSkillCreateFailure = checkSkillCreate.failureFn;

const checkSkillList = createUserAttrListAction(types.CHECK_SKILL_LIST);
export const checkSkillListRequest = checkSkillList.requestFn;
export const checkSkillListSuccess = checkSkillList.successFn;
export const checkSkillListFailure = checkSkillList.failureFn;

const checkSkillUpdate = createUserAttrUpdateAction(types.CHECK_SKILL_UPDATE);
export const checkSkillUpdateRequest = checkSkillUpdate.requestFn;
export const checkSkillUpdateSuccess = checkSkillUpdate.successFn;
export const checkSkillUpdateFailure = checkSkillUpdate.failureFn;


// Final Edit Skill List =======================================================
const finalEditSkillCreate = createUserAttrCreateAction(types.FINAL_EDIT_SKILL_CREATE);
export const finalEditSkillCreateRequest = finalEditSkillCreate.requestFn;
export const finalEditSkillCreateSuccess = finalEditSkillCreate.successFn;
export const finalEditSkillCreateFailure = finalEditSkillCreate.failureFn;

const finalEditSkillList = createUserAttrListAction(types.FINAL_EDIT_SKILL_LIST);
export const finalEditSkillListRequest = finalEditSkillList.requestFn;
export const finalEditSkillListSuccess = finalEditSkillList.successFn;
export const finalEditSkillListFailure = finalEditSkillList.failureFn;

const finalEditSkillUpdate = createUserAttrUpdateAction(types.FINAL_EDIT_SKILL_UPDATE);
export const finalEditSkillUpdateRequest = finalEditSkillUpdate.requestFn;
export const finalEditSkillUpdateSuccess = finalEditSkillUpdate.successFn;
export const finalEditSkillUpdateFailure = finalEditSkillUpdate.failureFn;

// Second Final Edit Skill List ================================================
const secondFinalEditSkillCreate = createUserAttrCreateAction(types.SECOND_FINAL_EDIT_SKILL_CREATE);
export const secondFinalEditSkillCreateRequest = secondFinalEditSkillCreate.requestFn;
export const secondFinalEditSkillCreateSuccess = secondFinalEditSkillCreate.successFn;
export const secondFinalEditSkillCreateFailure = secondFinalEditSkillCreate.failureFn;

const secondFinalEditSkillList = createUserAttrListAction(types.SECOND_FINAL_EDIT_SKILL_LIST);
export const secondFinalEditSkillListRequest = secondFinalEditSkillList.requestFn;
export const secondFinalEditSkillListSuccess = secondFinalEditSkillList.successFn;
export const secondFinalEditSkillListFailure = secondFinalEditSkillList.failureFn;

const secondFinalEditSkillUpdate = createUserAttrUpdateAction(types.SECOND_FINAL_EDIT_SKILL_UPDATE);
export const secondFinalEditSkillUpdateRequest = secondFinalEditSkillUpdate.requestFn;
export const secondFinalEditSkillUpdateSuccess = secondFinalEditSkillUpdate.successFn;
export const secondFinalEditSkillUpdateFailure = secondFinalEditSkillUpdate.failureFn;

// Software Create =============================================================
const softwareCreate = createUserAttrCreateAction(types.SOFTWARE_CREATE);
export const softwareCreateRequest = softwareCreate.requestFn;
export const softwareCreateSuccess = softwareCreate.successFn;
export const softwareCreateFailure = softwareCreate.failureFn;

// Software List ===============================================================
const softwareList = createUserAttrListAction(types.SOFTWARE_LIST);
export const softwareListRequest = softwareList.requestFn;
export const softwareListSuccess = softwareList.successFn;
export const softwareListFailure = softwareList.failureFn;

// Software Delete =============================================================
const softwareDelete = createUserAttrDeleteAction(types.SOFTWARE_DELETE);
export const softwareDeleteRequest = softwareDelete.requestFn;
export const softwareDeleteSuccess = softwareDelete.successFn;
export const softwareDeleteFailure = softwareDelete.failureFn;

// Focus Create ================================================================
const focusCreate = createUserAttrCreateAction(types.FOCUS_CREATE);
export const focusCreateRequest = focusCreate.requestFn;
export const focusCreateSuccess = focusCreate.successFn;
export const focusCreateFailure = focusCreate.failureFn;

// Focus List ==================================================================
const focusList = createUserAttrListAction(types.FOCUS_LIST);
export const focusListRequest = focusList.requestFn;
export const focusListSuccess = focusList.successFn;
export const focusListFailure = focusList.failureFn;

// Focus Update ================================================================
const focusUpdate = createUserAttrUpdateAction(types.FOCUS_UPDATE);
export const focusUpdateRequest = focusUpdate.requestFn;
export const focusUpdateSuccess = focusUpdate.successFn;
export const focusUpdateFailure = focusUpdate.failureFn;

// Specialty Create ============================================================
const specialtyCreate = createUserAttrCreateAction(types.SPECIALTY_CREATE);
export const specialtyCreateRequest = specialtyCreate.requestFn;
export const specialtyCreateSuccess = specialtyCreate.successFn;
export const specialtyCreateFailure = specialtyCreate.failureFn;

// Specialty List ==============================================================
const specialtyList = createUserAttrListAction(types.SPECIALTY_LIST);
export const specialtyListRequest = specialtyList.requestFn;
export const specialtyListSuccess = specialtyList.successFn;
export const specialtyListFailure = specialtyList.failureFn;

// Specialty Update ============================================================
const specialtyUpdate = createUserAttrUpdateAction(types.SPECIALTY_UPDATE);
export const specialtyUpdateRequest = specialtyUpdate.requestFn;
export const specialtyUpdateSuccess = specialtyUpdate.successFn;
export const specialtyUpdateFailure = specialtyUpdate.failureFn;

// Schedule List ===============================================================
const scheduleList = createUserAttrListAction(types.SCHEDULE_LIST);
export const scheduleListRequest = scheduleList.requestFn;
export const scheduleListSuccess = scheduleList.successFn;
export const scheduleListFailure = scheduleList.failureFn;

export const scheduleUpdateOrCreateRequest = (form, userId, values) => ({
  type: types.SCHEDULE_UPDATE_OR_CREATE.REQUEST,
  payload: { form, userId, values }
})
export const scheduleUpdateOrCreateSuccess = () => ({
  type: types.SCHEDULE_UPDATE_OR_CREATE.SUCCESS,
})
export const scheduleUpdateOrCreateFailure = (error) => ({
  type: types.SCHEDULE_UPDATE_OR_CREATE.FAILURE,
  payload: { error }
})