import React from 'react';

const LabelGroup = (props) => {
  const { items } = props;
  return (
    <div>
      {items.map(item => (
        <span
          key={item.id}
          className="label label-default label-group mr-3 mb-3">
          {item.name}
        </span>
      ))}
    </div>
  );
}

export default LabelGroup;