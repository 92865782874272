import orderBy from 'lodash.orderby';
import groupBy from 'lodash.groupby';
import _filter from 'lodash.filter';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { userSchema, profileSchema } from 'schemas/users';
import { WEEKDAYS } from 'misc/config';
import { getUsersByGroup } from 'misc/utils';
import { SERVICE_TYPES } from 'misc/config';
import {
  getEntities,
  getUserEntities,
  getGroupEntities,
  getProfileEntities,
  getServiceEntities,
  getTranslationSkillEntities,
  getEditSkillEntities,
  getCheckSkillEntities,
  getFinalEditSkillEntities,
  getSecondFinalEditSkillEntities,
  getIndustryEntities,
  getParentIndustryEntities,
  getFieldEntities,
  getParentFieldEntities,
  getFocusEntities,
  getSpecialtyEntities,
  getScheduleEntities,
  getApplicationEntities,
  getSoftwareEntities
 } from 'selectors/entities';
import * as coreSelectors from 'selectors/core';


export const getUserById = createSelector(
  [coreSelectors.getId, getUserEntities],
  (id, entities) => entities[id]
)

export const getUserValueById = createSelector(
  [coreSelectors.getId, getEntities],
  (id, entities) => denormalize(id, userSchema, entities)
)

export const getProfileByUserId = createSelector(
  [coreSelectors.getId, getProfileEntities],
  (id, entities) => entities[id]
)

export const getProfileValueByUserId = createSelector(
  [coreSelectors.getId, getEntities],
  (id, entities) => denormalize(id, profileSchema, entities)
)

export const getAEUsers = createSelector(
  [getUserEntities, getGroupEntities],
  (userEntities, groupEntities) => {
    return getUsersByGroup(userEntities, groupEntities, 'AE');
  }
);

const _getUserSkills = (skillEntities, userId) => {
  return Object.keys(skillEntities)
               .map(key => skillEntities[key])
               .filter(skill => skill.user === userId);
}

const _getSkillsByUserId = (id, serviceEntities, skillEntities) => {
  const userSkills = _getUserSkills(skillEntities, id);
  const jobServices = _filter(serviceEntities, s => s.isJobApproved === true);
  const groupedServices = groupBy(jobServices, 'type');
  const results = SERVICE_TYPES.map(type => {
    const services = orderBy(groupedServices[type], ['order']);
    const children = services.map(service => {
      const skill = userSkills.find(skill => skill.service === service.id);
        return {
          serviceName: service.name,
          serviceId: service.id,
          skillId: skill ? skill.id: null,
          level: skill ? skill.level : 0,
        }
      })
      return {
        type: type,
        children: children
      }
    });
    return results;
}

export const getTranslationSkillsByUserId = createSelector(
  [coreSelectors.getId, getServiceEntities, getTranslationSkillEntities],
  (id, serviceEntities, skillEntities) => {
    const userSkills = _getUserSkills(skillEntities, id);
    const jobServices = _filter(serviceEntities, s => s.isJobApproved === true);
    const groupedServices = groupBy(jobServices, 'type');
    const results = ['Translation'].map(type => {
      const services = orderBy(groupedServices[type], ['order']);
      const children = services.map(service => {
        const skill = userSkills.find(skill => skill.service === service.id);
          return {
            serviceName: service.name,
            serviceId: service.id,
            skillId: skill ? skill.id: null,
            speed: skill ? skill.speed: null,
            level: skill ? skill.level : 0,
        }
      })
      return {
        type: type,
        children: children
      }
    });
    return results;
  }
);

export const getEditSkillsByUserId = createSelector(
  [coreSelectors.getId, getServiceEntities, getEditSkillEntities],
  _getSkillsByUserId
)

export const getCheckSkillsByUserId = createSelector(
  [coreSelectors.getId, getServiceEntities, getCheckSkillEntities],
  _getSkillsByUserId
)

export const getFinalEditSkillsByUserId = createSelector(
  [coreSelectors.getId, getServiceEntities, getFinalEditSkillEntities],
  _getSkillsByUserId
)

export const getSecondFinalEditSkillsByUserId = createSelector(
  [coreSelectors.getId, getServiceEntities, getSecondFinalEditSkillEntities],
  _getSkillsByUserId
)

export const getFocusByUserId = createSelector(
  [
    coreSelectors.getId,
    getIndustryEntities,
    getParentIndustryEntities,
    getFocusEntities
  ],
  (id, industryEntities, parentIndustryEntities, focusEntities) => {
    const userFocuses = Object.keys(focusEntities)
                            .map(key => focusEntities[key])
                            .filter(f => f.user === id);
    const results = Object.keys(parentIndustryEntities).map(parentId => {
      const parent = parentIndustryEntities[parentId];
      const children = parent.children.map(child => {
        const industry = industryEntities[child];
        const userFocus = userFocuses.find(focus => focus.industry === industry.id);
          return {
            industryId: industry.id,
            industryName: industry.name,
            focusId: userFocus ? userFocus.id : null,
            level: userFocus ? userFocus.level : 0,
          }
        });
      return {
        id: parent.id,
        name: parent.name,
        order: parent.order,
        children: children
      }
    });
    return orderBy(results, ['order']);
  }
)


export const getSpecialtiesByUserId = createSelector(
  [
    coreSelectors.getId,
    getFieldEntities,
    getParentFieldEntities,
    getSpecialtyEntities
  ],
  (id, fieldEntities, parentFieldEntities, specialtyEntities) => {
    const userSpecialties = Object.keys(specialtyEntities)
                                  .map(key => specialtyEntities[key])
                                  .filter(specialty => specialty.user === id);
    const results = Object.keys(parentFieldEntities).map(parentId => {
      const parent = parentFieldEntities[parentId];
      const children = parent.children.map(child => {
        const field = fieldEntities[child];
        const specialty = userSpecialties.find(specialty => specialty.field === field.id);
        return {
          fieldId: field.id,
          fieldName: field.name,
          specialtyId: specialty ? specialty.id : null,
          level: specialty ? specialty.level : 0,
        }
      });
      return {
        id: parent.id,
        name: parent.name,
        order: parent.order,
        children: children
      }
    });
    return orderBy(results, ['order'])
  }
)

export const getSoftwareByUserId = createSelector(
  [
    coreSelectors.getId,
    getSoftwareEntities,
    getApplicationEntities
  ],
  (id, softwareEntities, applicationEntities) => {
    const userSoftwares = Object.keys(softwareEntities)
                                .map(key => softwareEntities[key])
                                .filter(software => software.user === id);
    const applicationTypes = ['Document', 'Spreadsheet', 'Slide', 'Other'];
    const groupedApplications = groupBy(applicationEntities, 'type');
    const results = applicationTypes.map(type => {
      const applications = orderBy(groupedApplications[type], ['order']);
      const children = applications.map(app => {
        const software = userSoftwares.find(software => software.application === app.id);
        return {
          applicationName: app.name,
          applicationId: app.id,
          softwareId: software ? software.id: null,
        }
      })
      return {
        type: type,
        children: children
      }
    });
    return results;
  }
)

export const isEmployeeUser = createSelector(
  [getUserValueById],
  (user) => {
    return user && user.groups && user.groups.findIndex(group => {
      const g = group.name;
      return (
        g === 'ToTra' ||
        g === 'Translator' ||
        g === 'Editor' ||
        g === 'Checker' ||
        g === 'Final Editor' ||
        g === 'Second Final Editor' ||
        g === 'DTP' ||
        g === 'Second DTP'
      )
    }) !== -1
  }
)

export const isSkillRequiredUser = createSelector(
  [getUserValueById], (user) => {
    return user && user.groups && user.groups.findIndex(group => {
      const g = group.name;
      return (
        g === 'Translator' ||
        g === 'Editor' ||
        g === 'Checker' ||
        g === 'Final Editor' ||
        g === 'Second Final Editor'
      )
    }) !== -1
  }
)

const _hasRole = (user, roleName) => (
  user && user.groups && user.groups.findIndex(group => (
    group.name === roleName
  )) !== -1
)

export const hasTranslatorRole = createSelector(
  [getUserValueById], (user) => _hasRole(user, 'Translator')
)

export const hasEditorRole = createSelector(
  [getUserValueById], (user) => _hasRole(user, 'Editor')
)

export const hasCheckerRole = createSelector(
  [getUserValueById], (user) => _hasRole(user, 'Checker')
)

export const hasFinalEditorRole = createSelector(
  [getUserValueById], (user) => _hasRole(user, 'Final Editor')
)

export const hasSecondFinalEditorRole = createSelector(
  [getUserValueById], (user) => _hasRole(user, 'Second Final Editor')
)

export const getSchedulesByUserId = createSelector(
  [coreSelectors.getId, getScheduleEntities],
  (id, scheduleEntities) => {
    const userSchedules = Object.keys(scheduleEntities)
                                .map(key => scheduleEntities[key])
                                .filter(schedule => schedule.user === id);
    const response = {};
    WEEKDAYS.forEach(w => {
      const us = userSchedules.find(schedule => schedule.weekday === w.value);
      response[w.key] = us || {};
    })
    return response;
  }
)