import React from "react";
import {TaModalContent, TaModalFooter, TaModalHeader} from "components/taUi/taModal/taModal";
import {TaButton} from "components/taUi/taButton/taButton";
import {Form, Modal} from "react-bootstrap";
import {change, Field, reduxForm, reset} from "redux-form";
import * as validators from "misc/validators";
import SelectField from "components/core/SelectField";
import { getNestedCategoryOptions } from 'selectors/options';
import TextField from "components/core/TextField";
import {connect} from "react-redux";
import { isGlossaryDetailSettingsModalLoading } from "selectors/loading";
import {bindActionCreators} from "redux";
import {
  GLOSSARY_DETAIL_SETTINGS_FORM,
  GLOSSARY_DETAIL_SETTINGS_MODAL,
} from "misc/constants";
import Spinner from "components/core/Spinner";
import {connectModal} from "redux-modal";
import {TaFormFieldText} from "components/taUi/taFormField/taFormFieldText";


class GlossarySettingsModal extends React.Component {

  hide = () => {
    this.props.handleHide();
    this.props.reset(GLOSSARY_DETAIL_SETTINGS_FORM);
  };

  render() {
    const {
      show,
      handleSubmit,
      onSubmit,
      isLoading,
      categoryOptions
    } = this.props;

    return (
      <Modal
        show={show}
        onHide={this.hide}
        animation={true}
        backdrop="static"
      >
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit((values => onSubmit(values)))}
          onReset={this.hide}
        >
          <TaModalHeader
            label={'Edit Glossary'}
            onCancel={this.hide}
          />
          <TaModalContent>
            <Spinner isLoading={isLoading} />
            <div className="p-15">
              <Field
                name="name"
                label="Name"
                type="text"
                validate={[
                  validators.required,
                  validators.maxLength100
                ]}
                isRequired={true}
                component={TaFormFieldText}/>
              <Field
                name="category"
                label="Category"
                validate={[
                  validators.required,
                ]}
                isRequired={true}
                options={categoryOptions}
                component={SelectField.Labeled} />
              <Field
                name="notes"
                label="Notes"
                component={TextField.Labeled}/>
            </div>
          </TaModalContent>
          <TaModalFooter>
            <TaButton
              type="reset"
              icon={'clear'}
              label={'Cancel'}
            />
            <TaButton
              type="submit"
              icon={'check'}
              label={'Save'}
              iconAlign={'right'}
            />
          </TaModalFooter>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const initialValues = (
    state.modal &&
    state.modal[GLOSSARY_DETAIL_SETTINGS_MODAL] &&
    state.modal[GLOSSARY_DETAIL_SETTINGS_MODAL].props &&
    state.modal[GLOSSARY_DETAIL_SETTINGS_MODAL].props.initialValues
  ) ? state.modal[GLOSSARY_DETAIL_SETTINGS_MODAL].props.initialValues : {};

  return {
    initialValues: initialValues,
    isLoading: isGlossaryDetailSettingsModalLoading(state),
    categoryOptions: getNestedCategoryOptions(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    change,
    reset
  }, dispatch);
};

GlossarySettingsModal = connectModal({
  name: GLOSSARY_DETAIL_SETTINGS_MODAL
})(GlossarySettingsModal);

GlossarySettingsModal = reduxForm({
  form: GLOSSARY_DETAIL_SETTINGS_FORM,
  enableReinitialize: true
})(GlossarySettingsModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlossarySettingsModal);
