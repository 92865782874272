import React from 'react';
import { Content } from 'reactjs-admin-lte';
import ClientCreateForm from './ClientCreateForm';


const ClientCreate = (props) => {
  return (
    <Content.Body>
      <ClientCreateForm {...props} />
    </Content.Body>
  );
}

export default ClientCreate;