import React from 'react';
import classNames from 'classnames';
import DefaultAvatar from 'assets/images/default-avatar.png';

const UserAvatar = (props) => {
  const {
    className,
    url
  } = props;

  const cls = classNames(className);
  const basseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

  // hacky way!
  // because DRF returns different absolute/relative path in some API
  let src = DefaultAvatar;
  if (url && url.startsWith("http")) {
    src = url
  } else if (url) {
    src = `${basseUrl}${url}`;
  }
  return (
    <img
      className={cls}
      src={src}
      alt="avatar"
      onError={(e) => {
        e.target.src = DefaultAvatar;
      }} />
  );
}

export default UserAvatar;
