import React from 'react';
import { Field } from 'redux-form';
import FontAwesome from 'react-fontawesome';
import { Button } from 'react-bootstrap';
import SelectField from 'components/core/SelectField'
import DatePickerField from 'components/core/DatePickerField';
import TimeField from 'components/core/TimeField';
import CheckboxField from 'components/core/CheckboxField';
import OptionResetButton from './OptionResetButton';


const JobAssignmentField = (props) => {

  const {
    name,
    label,
    options,
    resetState,
    handleOptionReset,
    handleTrash
  } = props;

  return (
    <tr>
      <td>
        <OptionResetButton
          label={label}
          reset={resetState}
          onClick={() => handleOptionReset(name)} />
      </td>
       <td>
        <Field
          name={`${name}.assignee`}
          options={options}
          component={SelectField} />
      </td>
      <td>
        <Field
          name={`${name}.startDate`}
          component={DatePickerField.Inline} />
      </td>
      <td>
        <Field
          name={`${name}.startTime`}
          component={TimeField.Inline} />
      </td>
      <td>
        <Field
          name={`${name}.endDate`}
          component={DatePickerField.Inline} />
      </td>
      <td>
        <Field
          name={`${name}.endTime`}
          component={TimeField.Inline} />
      </td>
      <td className="text-center">
        <Field
          name={`${name}.strictDeadline`}
          component={CheckboxField} />
      </td>
      <td className="text-center">
        <Field
          name={`${name}.autoProcess`}
          component={CheckboxField} />
      </td>
      <td className="text-center">
        <Button
          bsSize="sm"
          onClick={() => handleTrash(name)}>
          <FontAwesome name="trash" />
        </Button>
      </td>
    </tr>
  )
}

export default JobAssignmentField;