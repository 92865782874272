import React from 'react';
import { Table } from 'react-bootstrap';

const percentRound = (num, digit) => {
  const digitVal = Math.pow(10, digit);
  let retVal;
  if (digitVal < 1) {
    retVal = Math.round(num * digitVal) * Math.pow(10, -1 * digit);
  } else {
    retVal = Math.round(num * digitVal) / digitVal;
  }
  return retVal;
}


const getCountRatioValue = (quote, key) => {
  const count = quote[key] || 0;
  const ratio = percentRound(((count / quote['count']) * 100), 2)
  return { count, ratio }
}

const QuoteDetailTable = (props) => {
  const { quote, detail } = props;
  
  const lockValue = getCountRatioValue(detail, "lockCount")
  const repeatValue = getCountRatioValue(detail, "repeatCount")
  const perfectMatchValue = getCountRatioValue(detail, "memoryPerfectMatchCount")
  const generalMatchValue = getCountRatioValue(detail, "memoryGeneralMatchCount")
  const closeMatchValue = getCountRatioValue(detail, "memoryCloseMatchCount")
  const fuzzyMatchValue = getCountRatioValue(detail, "memoryFuzzyMatchCount")
  const termMatchValue = getCountRatioValue(detail, "termMatchCount")
  const newValue = getCountRatioValue(detail, "newCount")
  const sumValue = getCountRatioValue(detail, "count")

  const discountUnitPrice = quote.discountUnitPrice
  
  return (
        <Table striped bordered className="mb-0">
          <thead>
            <tr>
              <th width="40%">Category</th>
              <th width="20%">Unit Price</th>
              <th width="15%">Count</th>
              <th width="15%">Amount</th>
              <th width="10%">%</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Repeat</td>
              <td>{discountUnitPrice.repeat}</td>
              <td>{repeatValue.count}</td>
              <td>{detail.repeatAmount}</td>
              <td>{repeatValue.ratio}%</td>
            </tr>
            <tr>
              <td>Lock</td>
              <td>{discountUnitPrice.lock}</td>
              <td>{lockValue.count}</td>
              <td>{detail.lockAmount}</td>
              <td>{lockValue.ratio}%</td>
            </tr>
            <tr>
              <td>Memory Perfect match</td>
              <td>{discountUnitPrice.memoryPerfectMatch}</td>
              <td>{perfectMatchValue.count}</td>
              <td>{detail.memoryPerfectMatchAmount}</td>
              <td>{perfectMatchValue.ratio}%</td>
            </tr>
            <tr>
              <td>Memory General match</td>
              <td>{discountUnitPrice.memoryGeneralMatch}</td>
              <td>{generalMatchValue.count}</td>
              <td>{detail.memoryGeneralMatchAmount}</td>
              <td>{generalMatchValue.ratio}%</td>
            </tr>
            <tr>
              <td>Memory Close match</td>
              <td>{discountUnitPrice.memoryCloseMatch}</td>
              <td>{closeMatchValue.count}</td>
              <td>{detail.memoryCloseMatchAmount}</td>
              <td>{closeMatchValue.ratio}%</td>
            </tr>
            <tr>
              <td>Memory Fuzzy match</td>
              <td>{discountUnitPrice.memoryFuzzyMatch}</td>
              <td>{fuzzyMatchValue.count}</td>
              <td>{detail.memoryFuzzyMatchAmount}</td>
              <td>{fuzzyMatchValue.ratio}%</td>
            </tr>
            <tr>
              <td>Term match</td>
              <td>{discountUnitPrice.termMatch}</td>
              <td>{termMatchValue.count}</td>
              <td>{detail.termMatchAmount}</td>
              <td>{termMatchValue.ratio}%</td>
            </tr>
            <tr>
              <td>New</td>
              <td>{discountUnitPrice.new}</td>
              <td>{newValue.count}</td>
              <td>{detail.newAmount}</td>
              <td>{newValue.ratio}%</td>
            </tr>
            <tr>
              <td>Total</td>
              <td></td>
              <td>{sumValue.count}</td>
              <td>{detail.amount}</td>
              <td>{sumValue.ratio}%</td>
            </tr>
            {/* <tr>
              <td>Layout</td>
              <td></td>
              <td></td>
              <td>{detail.layoutAmount}</td>
              <td></td>
            </tr> */}
          </tbody>
        </Table>
  )
}

export default QuoteDetailTable;