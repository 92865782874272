import React from 'react';
import LabeledSelectField from './LabeledSelectField';


const MultiSelectField = (props) => {

  const handleChange = (value) => {
    const valueList = value.split(',')
    const v = valueList.includes('') ? [] : valueList
    return props.input.onChange(v);
  }

  return (
    <LabeledSelectField
        {...props}
        multi={true}
        onChange={value => handleChange(value)} />
  );
}

export default MultiSelectField;